import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

export function PayBank() {

  const user: any = useSelector<RootState>(({auth}) => auth.user)
  const [appTime, setAppTime] = useState(moment().format('YYYYMMDDHHmmss'))
  const [totAmount, setTotAmount] = useState(0)
  const [canAmount, setCanAmount] = useState(0)
  const [notAmount, setNotAmount] = useState(0)
  // const [payMethod, setPayMethod] = useState('')
  // const [resCd, setResCd] = useState('')
  const [resMsg, setResMsg] = useState('')
  // const [error, setError] = useState('')

  const [items, setItems] = useState<any>([])

  // const [status, setStatus] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  // const [search, setSearch] = useState('')


  useEffect(() => {
    getQuery()
    getBanks('')
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getBanks('', page)
  }

  const getQuery = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/kcp/query`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setAppTime(result.appTime)
        setTotAmount(result.totAmount)
        setCanAmount(result.canAmount)
        setNotAmount(result.notAmount)
        // setPayMethod(result.payMethod)
        // setResCd(result.resCd)
        setResMsg(result.resMsg)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getBanks = (status: string, page = 1) => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/pay/bank?status=${status}&page=${page}&search=`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setItems(result.data)
        setCurrentPage(result.current_page)
        setPerPage(result.per_page)
        setTotal(result.total)
      }
      window.scrollTo(0, 0)
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>잔액 조회</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
        position: 'relative',
      }}>

        <h2>
          경남은행 ( 8003344334139 )
        </h2>
        <h3>
          조회 시간 : {moment(appTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}
        </h3>
        <h3>
          총 금액 : {Number(totAmount).toLocaleString('en')}
        </h3>
        <h3>
          출금가능금액 : {Number(canAmount).toLocaleString('en')}
        </h3>
        <h3>
          정지금액 : {Number(notAmount).toLocaleString('en')}
        </h3>
        <h3>
          {resMsg}
        </h3>

        {
          (user.admin >> 10) % 2 === 1 &&
          <label className='btn btn-primary' style={{position: 'absolute', top: 0, right: 0}} onClick={() => {
            window.location.href = '/pay/bank/remit'
          }}>
            송금하기
          </label>
        }

      </div>

      {
        items.map((item: any, index: number) => {
          return <div className='col-12' key={`${index}`}>
            <div className='card card-custom card-stretch shadow mb-5'>
              <div className='card-header'>
                <h1 className='card-title'>
                  {item.booking_id > 0 ? '정산 입금' : '직접 입금'}
                  {
                    item.status_code === 1 &&
                    <p style={{
                      marginLeft: 20,
                      fontWeight: 500,
                      fontSize: 14,
                      color: '#007BFF',
                      marginBottom: 0,
                    }}>
                      송금완료
                    </p>
                  }
                  {
                    item.status_code === 3 &&
                    <p style={{
                      marginLeft: 20,
                      fontWeight: 500,
                      fontSize: 14,
                      color: '#FF4B3E',
                      marginBottom: 0,
                    }}>
                      송금실패 (송금불능) (관리자에게 문의하세요)
                    </p>
                  }
                  {
                    (item.status_code === 0 || item.status_code === 6) &&
                    <p style={{
                      marginLeft: 20,
                      fontWeight: 500,
                      fontSize: 14,
                      color: '#1aa200',
                      marginBottom: 0,
                    }}>
                      처리중
                    </p>
                  }
                </h1>
              </div>
              <div className='card-body'>
                {
                  item.booking_id > 0 &&
                  <h3>
                    예약 번호 : {item.booking_id}
                  </h3>
                }
                <h3>
                  입금 금액 : {Number(item.va_mny).toLocaleString('en')}
                </h3>
                <h3>
                  은행명 : {item.va_bankname}
                </h3>
                <h3>
                  계좌번호 : {item.va_account}
                </h3>
                <h3>
                  보내는사람명 : {item.va_name}
                </h3>
                <h3>
                  처리일자 : {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                </h3>
                <h3>
                  거래일자 : {moment(item.trade_date, 'YYYYMMDD').format('YYYY-MM-DD')}
                </h3>
                <h3>
                  거래일련번호 : {item.trade_seq}
                </h3>
                <h3>
                  남은 금액 : {Number(item.bal_amount).toLocaleString('en')}
                </h3>
              </div>
            </div>
          </div>
        })
      }

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />
    </div>
  </>
}

