import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'

export function AdvertiseBToBGuestBannerAdd() {
  const [userId, setUserId] = useState('')
  const [advStart, setAdvStartDate] = useState('')
  const [advEnd, setAdvEndDate] = useState('')
  const [advPrice, setAdvPrice] = useState('')

  useEffect(() => {
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const add = () => {
    if (userId === '' || Number(userId) < 1) {
      toast.error('사용자 아이디를 확인해 주세요.')
      return
    }

    if (advStart === '') {
      toast.error('광고 시작일을 확인해주세요.')
      return
    }

    if (advEnd === '') {
      toast.error('광고 종료일을 확인해주세요.')
      return
    }

    if (advPrice === '') {
      toast.error('금액을 확인해주세요.')
      return
    }

    console.log(userId)

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/adv/btob/banner/add`, {
        userId,
        advStart,
        advEnd,
        advPrice,
        advType: 'G',
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          //window.location.href = `/advertise/main/banner`
          alert('성공')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>게스트향 배너 광고</PageTitle>
      <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
        <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
            <div style={{marginTop: 10, paddingBottom: 10}}>
              <div>
                <table style={{fontSize: '15px', width: '100%', lineHeight: 2}}>
                  <colgroup>
                    <col style={{width: '14%'}} />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr style={{fontSize: '15px'}}>
                      <td>사용자 번호</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='호스트 ID를 입력하세요.'
                          maxLength={15}
                          value={userId}
                          onChange={(e) => {
                            setUserId(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>광고 시작일</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 20990101'
                          maxLength={8}
                          value={advStart}
                          onChange={(e) => {
                            setAdvStartDate(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>광고 종료일</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 20990101'
                          maxLength={8}
                          value={advEnd}
                          onChange={(e) => {
                            setAdvEndDate(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>금액</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          maxLength={10}
                          value={advPrice}
                          onChange={(e) => {
                            setAdvPrice(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <label
                onClick={() => {
                  add()
                }}
                style={{marginLeft: 10}}
                className='btn btn-sm btn-primary'
              >
                등록하기
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
