import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import moment from 'moment'
import {useParams} from 'react-router-dom'

export function MarketingCouponDetail() {
  const {coupon_id} = useParams<{coupon_id: string}>()

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [coupon, setCoupon] = useState<any>(null)
  const [type, setType] = useState('')

  useEffect(() => {
    getCoupon()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getCouponUsers(page)
  }

  const getCoupon = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/coupon/${coupon_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setCoupon(result)

          console.log('type : ' + result.type)
          getCouponUsers(1, result.type)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCouponUsers = (page = 1, type = 'A') => {
    console.log(type)

    let url = `am/coupon/${coupon_id}/users`
    if ('C' === type) {
      url = `am/coupon/${coupon_id}/teams`
    }

    console.log(url)

    axios
      .get(`${process.env.REACT_APP_API2_URL}${url}?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCouponTeams = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/coupon/${coupon_id}/teams?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addUserIds = () => {
    const user_ids = prompt('사용자들 아이디를 입력하세요. ex) 1234,123,23456,235')
    if (user_ids !== null && user_ids.trim() !== '') {
      console.log('add', user_ids)
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/coupon/${coupon_id}/users/add`, {user_ids})
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            setType('A')
            getCouponUsers(1)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const addTeamIds = () => {
    const user_ids = prompt('팀 아이디를 입력하세요. ex) 1234,123,23456,235')
    if (user_ids !== null && user_ids.trim() !== '') {
      console.log('add', user_ids)
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/coupon/${coupon_id}/teams/add`, {user_ids})
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            setType('C')
            getCouponTeams(1)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>쿠폰</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {coupon !== null && coupon.coupon_type === 0 && coupon.type !== 'C' && (
          <label
            className={'btn btn-primary'}
            onClick={() => {
              addUserIds()
            }}
          >
            쿠폰 보내기
          </label>
        )}

        {coupon !== null && coupon.coupon_type === 0 && coupon.type === 'C' && (
          <label
            className={'btn btn-primary'}
            onClick={() => {
              addTeamIds()
            }}
          >
            쿠폰 보내기
          </label>
        )}

        {coupon !== null && (
          <div
            style={{
              width: '100%',
            }}
          >
            <h3>
              {coupon.title}
              <br />
              {coupon.coupon_code_name}
              <br />
              <br />
              할인율 :{coupon.discount_percent}%<br />
              {coupon.coupon_type === 0 && (
                <>
                  사용 기한 : {coupon.expire_date}
                  <br />
                  최대 할인 금액 : {coupon.max_discount_price.toLocaleString('en')}
                  <br />
                  최소 결제 금액 : {coupon.min_price.toLocaleString('en')}
                  <br />
                  사용량 : {coupon.used_count.toLocaleString('en')}/{coupon.count.toLocaleString('en')}
                  <br />
                  사용금액 : {coupon.total_discount_price.toLocaleString('en')}/{coupon.total_price.toLocaleString('en')}
                  <br />
                  적용 카테고리 : {coupon.place_category_type}
                  <br />
                </>
              )}
              {coupon.coupon_type === 1 && (
                <>
                  등록 기한 : {coupon.coupon_reg_date}
                  <br />
                  사용 기한 : {coupon.expire_date}
                  <br />
                  사용량 : {coupon.used_count.toLocaleString('en')}/{coupon.max_use_coupon_count.toLocaleString('en')}
                  <br />
                  사용금액 : {coupon.total_discount_price.toLocaleString('en')}/{coupon.total_price.toLocaleString('en')}
                  <br />
                  적용 카테고리 : {coupon.place_category_type}
                  <br />
                </>
              )}
            </h3>
          </div>
        )}

        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          {coupon !== null && coupon.type !== 'C' && (
            <div className='table-responsive'>
              <table className='table table-striped gy-7 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th className='min-w-100px'>사용자 아이디</th>
                    <th className='min-w-100px'>예약번호</th>
                    <th className='min-w-100px'>이름</th>
                    <th className='min-w-100px'>상태</th>
                    <th className='min-w-100px'>총 결제 금액</th>
                    <th className='min-w-100px'>총 할인 금액</th>
                    <th className='min-w-100px'>발급일</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item: any, index: number) => {
                    return [
                      <tr key={`${index}`}>
                        <td>{item.user_id}</td>
                        <td>{item.booking_id}</td>
                        <td>
                          {item.name}({item.real_name})
                        </td>
                        <td>{item.status}</td>
                        <td>{item.total_price.toLocaleString('en')}</td>
                        <td>{item.total_discount_price.toLocaleString('en')}</td>
                        <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
                      </tr>,
                    ]
                  })}
                </tbody>
              </table>
            </div>
          )}

          {coupon !== null && coupon.type === 'C' && (
            <div className='table-responsive'>
              <table className='table table-striped gy-7 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th className='min-w-100px'>팀 아이디</th>
                    <th className='min-w-100px'>예약번호</th>
                    <th className='min-w-100px'>이름</th>
                    <th className='min-w-100px'>상태</th>
                    <th className='min-w-100px'>총 결제 금액</th>
                    <th className='min-w-100px'>총 할인 금액</th>
                    <th className='min-w-100px'>발급일</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item: any, index: number) => {
                    return [
                      <tr key={`${index}`}>
                        <td>{item.team_id}</td>
                        <td>{item.booking_id}</td>
                        <td>{item.team_name}</td>
                        <td>{item.status}</td>
                        <td>{item.total_price.toLocaleString('en')}</td>
                        <td>{item.total_discount_price.toLocaleString('en')}</td>
                        <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
                      </tr>,
                    ]
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
      </div>
    </>
  )
}
