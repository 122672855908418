import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {toast} from 'react-toastify'

export function MarketingEventsDetailEditor() {
    const {marketingEventId} = useParams<{marketingEventId: string}>()
    const [info, setInfo] = useState({
      active: false,
      banner_show: false,
      sub_title_arrow: false,
      login: false,
      background_color: '#3c82fa',
      type: 'main',
      url: '',
      order: 0,
      version: 2,
      black: false,
      image_url: '',
      title: '',
      title_color: '#ffffff',
      sub_title: '',
      sub_title_color: '#ffffff',
      button_title: '',
      button_title_color: '#ffffff',
      button_background_color: '#195bfa',
      google_url: '',
      google_url_button_title: '',
      google_id_code: '',
      google_email_code: '',
      pc_images: [],
      images: [],
    })

    useEffect(() => {
      if (Number(marketingEventId) > 0) {
        getMarketingEventDetails()
      }
      return () => {
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getMarketingEventDetails = () => {
      axios
        .get(`${process.env.REACT_APP_API2_URL}marketing/marketing/event/new/${marketingEventId}`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            if(result === undefined){
              alert('존재하지 않는 이벤트입니다.');
              window.location.href = '/marketing/event/new'
            } else {

              const temp = result.info
              temp.images.forEach((image: any) => {
                if (image.url === undefined || image.url === null) {
                  image.url = ''
                }
                if (image.width === undefined) {
                  image.width = 100
                }
                if (image.padding_top === undefined) {
                  image.padding_top = 0
                }
                if (image.padding_bottom === undefined) {
                  image.padding_bottom = 0
                }
                if (image.padding_right === undefined) {
                  image.padding_right = 0
                }
                if (image.padding_left === undefined) {
                  image.padding_left = 0
                }
                if (image.login === undefined) {
                  image.login = 0
                }
                if (image.google === undefined) {
                  image.google = 0
                }
              })
              temp.pc_images?.forEach((image: any) => {
                if (image.url === undefined || image.url === null) {
                  image.url = ''
                }
                if (image.width === undefined) {
                  image.width = 100
                }
                if (image.padding_top === undefined) {
                  image.padding_top = 0
                }
                if (image.padding_bottom === undefined) {
                  image.padding_bottom = 0
                }
                if (image.padding_right === undefined) {
                  image.padding_right = 0
                }
                if (image.padding_left === undefined) {
                  image.padding_left = 0
                }
                if (image.login === undefined) {
                  image.login = 0
                }
                if (image.google === undefined) {
                  image.google = 0
                }
              })
              setInfo(temp)
              window.scrollTo(0, 0)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

  const handleFileInputEventImage = (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    return axios
      .post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error === '') {
          const temp = Object.assign({}, info)
          console.log(temp.pc_images)
          if (temp.pc_images === null) temp.pc_images = Object([])
          temp.pc_images.push({
            // @ts-ignore
            image_url: result.image_url,
            // @ts-ignore
            width: 100,
            // @ts-ignore
            padding_top: 0,
            // @ts-ignore
            padding_bottom: 0,
            // @ts-ignore
            padding_left: 0,
            // @ts-ignore
            padding_right: 0,
            // @ts-ignore
            url: '',
            // @ts-ignore
            login: 0,
            // @ts-ignore
            google: 0,
          })

          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFileInputEventMImage = (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    return axios
      .post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error === '') {
          const temp = Object.assign({}, info)
          temp.images.push({
            // @ts-ignore
            image_url: result.image_url,
            // @ts-ignore
            width: 100,
            // @ts-ignore
            padding_top: 0,
            // @ts-ignore
            padding_bottom: 0,
            // @ts-ignore
            padding_left: 0,
            // @ts-ignore
            padding_right: 0,
            // @ts-ignore
            url: '',
            // @ts-ignore
            login: 0,
            // @ts-ignore
            google: 0,
          })
          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

    const upsert = () => {
      console.log(info)
      if(info.title.trim() === '') {
        alert('제목을 입력하세요.');
        return;
      }

      if(info.pc_images.length === 0) {
        alert('PC 이미지를 등록하세요.');
        return;
      }

      if(info.images.length === 0) {
        alert('Mobile 이미지를 등록하세요.');
        return;
      }

      axios
        .put(`${process.env.REACT_APP_API2_URL}am/event/${marketingEventId}`, {
          advId: 0,
          info: info
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            console.log(result)
            //window.location.reload()
            if (Number(marketingEventId) > 0) {
              alert('수정되었습니다.');
            } else {
              alert('등록되었습니다.');
              //window.location.reload();
              window.location.href = '/marketing/event/new/'+result.id+'/editor'
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return (
      <>
        <PageTitle breadcrumbs={[]}>이벤트 페이지</PageTitle>
        <div style={{padding: '0px 20px', borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                기본 설정
              </h2>
              <div className="separator my-2" />
              <TextSub2 title={'제목'} subTitle={'최대 n자'} />
              <textarea className="form-control" rows={2} value={info.title}
                        onChange={(e) => {
                          const temp = Object.assign({}, info)
                          temp.title = e.target.value
                          setInfo(temp)
                        }}
              />
              <div className="separator my-5" />
              <label style={{marginRight: 20, fontWeight: 500, fontSize: 15, color: '#1B1D1F'}}>
                페이지 노출
                <br /><br />
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="layout-builder[layout][header][fixed][desktop]"
                  checked={info.active}
                  onChange={(event) => {
                    const temp = Object.assign({}, info)
                    temp.active = event.target.checked
                    setInfo(temp)
                  }}
                />
              </label>
            </div>
          </div>
        </div>

        <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className='card card-custom card-stretch mb-5'>
              <div className='card-header'>
                <h1 className='card-title'>이벤트 상세 (PC)</h1>
              </div>
              <div className='card-body'>
                {info.pc_images?.map((image: any, index: number) => {
                  return (
                    <div
                      style={{
                        position: 'relative',
                        width: `${image.width}%`,
                        paddingTop: image.padding_top,
                        paddingBottom: image.padding_bottom,
                        paddingRight: image.padding_right,
                        paddingLeft: image.padding_left,
                        border: '1px solid #DDD',
                      }}
                      key={index}
                    >
                      <img style={{width: '100%'}} src={image.image_url} onClick={() => {
                        window.open(image.url)
                      }} alt={''} />
                      <div style={{position: 'absolute', width: '100%', top: 0, marginTop: image.padding_top}}>
                        <div style={{position: 'absolute', top: 4, left: 4, opacity: 0.6, zIndex: 1, display: 'flex'}}>
                          <div style={{marginLeft: 4, width: 150, padding: 4, borderRadius: 4, backgroundColor: 'white'}}>
                            <p>링크</p>
                            <input type='text' className='form-control'
                                   value={image.url}
                                   onChange={(e) => {
                                     const temp = Object.assign({}, info)

                                     const temps = [...temp.pc_images]
                                     // @ts-ignore
                                     temps.splice(temps.indexOf(image), 1, image)
                                     image.url = e.target.value
                                     temp.pc_images = temps

                                     setInfo(temp)
                                   }}
                            />
                          </div>

                          <div style={{marginLeft: 4, width: 150, padding: 4, borderRadius: 4, backgroundColor: 'white'}}>
                            <p>로그인 필요</p>
                            <input className='form-check-input' type='checkbox' name='layout-builder[layout][header][fixed][desktop]'
                                   checked={image.login === 1}
                                   onChange={(e) => {
                                     const temp = Object.assign({}, info)

                                     const temps = [...temp.pc_images]
                                     // @ts-ignore
                                     temps.splice(temps.indexOf(image), 1, image)
                                     if(e.target.value) {
                                       image.login = 1
                                     } else {
                                       image.login = 0
                                     }
                                     temp.pc_images = temps
                                     setInfo(temp)
                                   }}
                            />
                          </div>
                        </div>

                        <label
                          onClick={() => {
                            if (window.confirm('삭제 하시겠습니까?')) {
                              const temp = Object.assign({}, info)
                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1)
                              temp.pc_images = temps
                              setInfo(temp)
                            }
                          }}
                          style={{position: 'absolute', top: 4, right: 4}}
                          className='btn btn-danger'
                        >
                          삭제
                        </label>

                        <label
                          onClick={() => {
                            const temp = Object.assign({}, info)
                            const temps = [...temp.pc_images]
                            // @ts-ignore
                            const index = temps.indexOf(image)
                            temps.splice(index, 1)
                            // @ts-ignore
                            temps.splice(index - 1, 0, image)
                            temp.pc_images = temps
                            setInfo(temp)
                          }}
                          style={{position: 'absolute', top: 4, right: 170}}
                          className='btn btn-primary'
                        >
                          위로
                        </label>
                        <label
                          onClick={() => {
                            const temp = Object.assign({}, info)
                            const temps = [...temp.pc_images]
                            // @ts-ignore
                            const index = temps.indexOf(image)
                            temps.splice(index, 1)
                            // @ts-ignore
                            temps.splice(index + 1, 0, image)
                            temp.pc_images = temps
                            setInfo(temp)
                          }}
                          style={{position: 'absolute', top: 4, right: 80}}
                          className='btn btn-primary'
                        >
                          아래로
                        </label>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='card-footer'>
                <input type='file' name='file' onChange={(e) => handleFileInputEventImage(e)} />
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className='card card-custom card-stretch mb-5'>
              <div className='card-header'>
                <h1 className='card-title'>이벤트 상세 (Mobile)</h1>
              </div>
              <div className='card-body'>
                {info.images?.map((image: any, index: number) => {
                  return (
                    <div
                      style={{
                        position: 'relative',
                        width: `${image.width}%`,
                        paddingTop: image.padding_top,
                        paddingBottom: image.padding_bottom,
                        paddingRight: image.padding_right,
                        paddingLeft: image.padding_left,
                        border: '1px solid #DDD',
                      }}
                      key={index}
                    >
                      <img style={{width: '100%'}} src={image.image_url} onClick={() => {
                        window.open(image.url)
                      }} alt={''} />
                      <div style={{position: 'absolute', width: '100%', top: 0, marginTop: image.padding_top}}>
                        <div style={{position: 'absolute', top: 4, left: 4, opacity: 0.6, zIndex: 1, display: 'flex'}}>
                          <div style={{marginLeft: 4, width: 150, padding: 4, borderRadius: 4, backgroundColor: 'white'}}>
                            <p>링크</p>
                            <input type='text' className='form-control'
                                   value={image.url}
                                   onChange={(e) => {
                                     const temp = Object.assign({}, info)

                                     const temps = [...temp.images]
                                     // @ts-ignore
                                     temps.splice(temps.indexOf(image), 1, image)
                                     image.url = e.target.value
                                     temp.images = temps

                                     setInfo(temp)
                                   }}
                            />
                          </div>

                          <div style={{marginLeft: 4, width: 150, padding: 4, borderRadius: 4, backgroundColor: 'white'}}>
                            <p>로그인 필요</p>
                            <input className='form-check-input' type='checkbox' name='layout-builder[layout][header][fixed][desktop]'
                                   checked={image.login === 1}
                                   onChange={(e) => {
                                     const temp = Object.assign({}, info)

                                     const temps = [...temp.images]
                                     // @ts-ignore
                                     temps.splice(temps.indexOf(image), 1, image)
                                     if(e.target.value) {
                                       image.login = 1
                                     } else {
                                       image.login = 0
                                     }
                                     temp.images = temps

                                     setInfo(temp)
                                   }}
                            />
                          </div>
                        </div>

                        <label
                          onClick={() => {
                            if (window.confirm('삭제 하시겠습니까?')) {
                              const temp = Object.assign({}, info)
                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1)
                              temp.images = temps
                              setInfo(temp)
                            }
                          }}
                          style={{position: 'absolute', top: 4, right: 4}}
                          className='btn btn-danger'
                        >
                          삭제
                        </label>

                        <label
                          onClick={() => {
                            const temp = Object.assign({}, info)
                            const temps = [...temp.images]
                            // @ts-ignore
                            const index = temps.indexOf(image)
                            temps.splice(index, 1)
                            // @ts-ignore
                            temps.splice(index - 1, 0, image)
                            temp.images = temps
                            setInfo(temp)
                          }}
                          style={{position: 'absolute', top: 4, right: 170}}
                          className='btn btn-primary'
                        >
                          위로
                        </label>
                        <label
                          onClick={() => {
                            const temp = Object.assign({}, info)
                            const temps = [...temp.images]
                            // @ts-ignore
                            const index = temps.indexOf(image)
                            temps.splice(index, 1)
                            // @ts-ignore
                            temps.splice(index + 1, 0, image)
                            temp.images = temps
                            setInfo(temp)
                          }}
                          style={{position: 'absolute', top: 4, right: 80}}
                          className='btn btn-primary'
                        >
                          아래로
                        </label>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='card-footer'>
                <input type='file' name='file' onChange={(e) => handleFileInputEventMImage(e)} />
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                구글 폼 옵션
              </h2>
              <div className="separator my-2" />
              <TextSub2 title={'구글 URL'} subTitle={''} />
              <input type="text" className="form-control"
                     style={{marginBottom: 10}}
                     value={info.google_url}
                     onChange={(e) => {
                       const temp = Object.assign({}, info)
                       temp.google_url = e.target.value
                       setInfo(temp)
                     }}
              />

              <div className="separator my-2" />
              <TextSub2 title={'구글 URL 버튼명'} subTitle={''} />
              <input type="text" className="form-control"
                     style={{marginBottom: 10}}
                     value={info.google_url_button_title}
                     onChange={(e) => {
                       const temp = Object.assign({}, info)
                       temp.google_url_button_title = e.target.value
                       setInfo(temp)
                     }}
              />

              <div className="separator my-2" />
              <TextSub2 title={'구글 ID Code'} subTitle={''} />
              <input type="text" className="form-control"
                     style={{marginBottom: 10}}
                     value={info.google_id_code}
                     onChange={(e) => {
                       const temp = Object.assign({}, info)
                       temp.google_id_code = e.target.value
                       setInfo(temp)
                     }}
              />

              <div className="separator my-2" />
              <TextSub2 title={'구글 Email Code'} subTitle={''} />
              <input type="text" className="form-control"
                     style={{marginBottom: 10}}
                     value={info.google_email_code}
                     onChange={(e) => {
                       const temp = Object.assign({}, info)
                       temp.google_email_code = e.target.value
                       setInfo(temp)
                     }}
              />
            </div>
          </div>
        </div>

        <div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 10}}>
            <label onClick={() => {
               upsert()
            }} className="btn btn-primary">
              저장하기
            </label>
          </div>
        </div>
      </>
    )
}
