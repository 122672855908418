import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub2} from '../../components/TextSub2'
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

export function SettingAgreeEdit() {

  const {agree_id} = useParams<{agree_id: string}>()

  const [agree, setAgree] = useState<any>(null)

  useEffect(() => {
    getAgree();
    return () => {
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getAgree = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/setting/agree/${agree_id}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setAgree(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const update = () => {
    console.log(agree)
    let str = '수정 하시겠습니까?';
    if (window.confirm(str)) {
      axios.put(`${process.env.REACT_APP_API2_URL}am/setting/agree`, {
        id: agree_id, title: agree.title, m_title: agree.m_title, body: agree.body, m_body: agree.m_body
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          alert("수정되었습니다.");
          window.location.href = `/setting/agree`;
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>약관 추가</PageTitle>

    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>

        <div className='card card-custom card-stretch shadow mb-5'>
          <div className='card-body'>
            {
              agree != null &&
                <>
                  <TextSub2 title={'약관 타이틀'} subTitle={'PC용'} />
                  <div>
                    <input type='text' className='form-control' placeholder='' value={agree.title} maxLength={50} onChange={(e) => {
                          const temp = Object.assign({}, agree)
                          temp.title = e.target.value
                          setAgree(temp);
                        }}
                    />
                  </div>
                  <div className='separator my-10' />
                  <TextSub2 title={'약관 내용'} subTitle={'PC용'} />
                  <div>
                    <textarea style={{width: '100%', height: 1000}} value={agree.body} onChange={(e) => {
                        const temp = Object.assign({}, agree)
                        temp.body = e.target.value
                        setAgree(temp);
                      }} />
                  </div>

                  <div className='separator my-10' />
                  <TextSub2 title={'모바일 약관 타이틀'} subTitle={'MOBILE 용'} />
                  <div>
                    <input type='text' className='form-control' placeholder='' value={agree.m_title} maxLength={50} onChange={(e) => {
                      const temp = Object.assign({}, agree)
                      temp.m_title = e.target.value
                      setAgree(temp);
                    }}
                    />
                  </div>
                  <div className='separator my-10' />
                  <TextSub2 title={'모바일 약관 내용'} subTitle={'MOBILE 용'} />
                  <div>
                    <textarea style={{width: '100%', height: 1000}} value={agree.m_body} onChange={(e) => {
                      const temp = Object.assign({}, agree)
                      temp.m_body = e.target.value
                      setAgree(temp);
                    }} />
                  </div>
                </>
            }
            </div>
        </div>
        <div className='card-footer' style={{display: 'flex', justifyContent: 'center', paddingTop: 35}}>
          <label onClick={() => {
            update();
          }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>수정하기</label>
        </div>
      </div>
    </div>
  </>
}

