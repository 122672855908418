import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import moment from 'moment'
import {toast} from 'react-toastify'
import DatePicker from 'react-datepicker'
import {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export function PayHostReport3() {
  const [date, setDate] = useState<Date | null>(new Date())

  const [items, setItems] = useState<any>([])

  const [loading, setLoading] = useState(false)

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    registerLocale('ko', ko)

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * 개인 원천 징수 3% 1000 -> 10 원 변경
   */
  const getMinTax = () => {
    return parseInt(moment(date).format('YYYYMM')) >= 202407 ? 10 : 1000
  }

  const getItems = useCallback(() => {
    setItems([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/pay/report?date=${moment(date).format('YYYY-MM')}`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          toast.error(error)
        } else {
          console.log(result)
          setItems([...result.cash.map((v: any) => ({...v, cash: true})), ...result.pay.map((v: any) => ({...v, cash: false}))])
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getItems()
  }, [getItems, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [
      {header: '#', key: 'id', width: 10},
      {header: '귀속년월', key: 'id1', width: 10},
      {header: '지급년월일', key: 'id2', width: 10},
      {header: '소득자명', key: 'id3', width: 10},
      {header: '주민등록번호', key: 'id17', width: 10},
      {header: '기본주소', key: 'id4', width: 10},
      {header: '상세주소', key: 'id5', width: 10},
      {header: '소득구분', key: 'id6', width: 10},
      {header: '영수일자', key: 'id7', width: 10},
      {header: '지급총액', key: 'id8', width: 10},
      {header: '세율(%)', key: 'id9', width: 10},
      {header: '소득세', key: 'id10', width: 10},
      {header: '지방소득세', key: 'id11', width: 10},
      {header: '내.외국인구분', key: 'id12', width: 10},
      {header: '연말정산', key: 'id13', width: 10},
      {header: '차인지급', key: 'id14', width: 10},
      {header: '지급액', key: 'id15', width: 10},
      {header: '캐시 여부', key: 'id16', width: 10},
    ]

    items.forEach((item: any) => {
      worksheet.addRow({
        id: item.cash ? item.id : item.booking_id,
        id1: moment(date).format('YYYY-MM'),
        id2: item.pay_day,
        id3: item.bank_owner,
        id4: '',
        id5: '',
        id6: '기타자영업',
        id7: item.pay_day,
        id8: item.price_host.toLocaleString('en'),
        id9: Math.floor(item.price_host * 0.03) >= getMinTax() ? '3%' : '',
        id10: Math.floor(item.price_host * 0.03) >= getMinTax() ? (Math.floor((item.price_host * 0.03) / 10) * 10).toLocaleString('en') : '',
        id11: Math.floor(item.price_host * 0.03) >= getMinTax() ? (Math.floor((Math.floor((item.price_host * 0.03) / 10) * 10 * 0.1) / 10) * 10).toLocaleString('en') : '',
        id12: Math.floor(item.price_host * 0.03) >= getMinTax() ? '' : '',
        id13: Math.floor(item.price_host * 0.03) >= getMinTax() ? '' : '',
        id14:
          Math.floor(item.price_host * 0.03) >= getMinTax()
            ? (item.price_host - (Math.floor((item.price_host * 0.03) / 10) * 10 + Math.floor((Math.floor((item.price_host * 0.03) / 10) * 10 * 0.1) / 10) * 10)).toLocaleString('en')
            : item.price_host.toLocaleString('en'),
        id15: item.pay.toLocaleString('en'),
        id16: item.cash ? 'Y' : 'N',
        id17: item.jumin.length === 13 ? `${item.jumin.substring(0, 6)}-${item.jumin.substring(6, 13)}` : item.jumin,
      })
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `정산결산표(개인)_${moment(date).format('YYYY-MM')}.xlsx`)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>지불 결산표(개인)</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: 20,
            }}
          >
            <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM' showMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
          </div>
          <label
            onClick={() => {
              download()
            }}
            className='btn btn-sm btn-secondary'
          >
            다운로드
          </label>
        </div>

        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          {loading ? (
            <div
              style={{
                width: '100%',
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </div>
          ) : (
            <div className='tab-content' id='myTabContent'>
              <div className='table-responsive'>
                <table className='table table-striped gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                      <th className='min-w-100px'>#</th>
                      <th className='min-w-100px'>귀속년월</th>
                      <th className='min-w-100px'>지급년월일</th>
                      <th className='min-w-100px'>소득자명</th>
                      <th className='min-w-100px'>기본주소</th>
                      <th className='min-w-100px'>상세주소</th>
                      <th className='min-w-100px'>소득구분</th>
                      <th className='min-w-100px'>영수일자</th>
                      <th className='min-w-100px'>지급총액</th>
                      <th className='min-w-100px'>세율(%)</th>
                      <th className='min-w-100px'>소득세</th>
                      <th className='min-w-100px'>지방소득세</th>
                      <th className='min-w-100px'>내.외국인구분</th>
                      <th className='min-w-100px'>연말정산</th>
                      <th className='min-w-100px'>차인지급</th>
                      <th className='min-w-100px'>지급액</th>
                      <th className='min-w-100px'>캐시 여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item: any, index: number) => {
                      return [
                        <tr key={`${index}`}>
                          <td>{item.cash ? item.id : item.booking_id}</td>
                          <td>{moment(date).format('YYYY-MM')}</td>
                          <td>{item.pay_day}</td>
                          <td>{item.bank_owner}</td>
                          <td />
                          <td />
                          <td>기타자영업</td>
                          <td>{item.pay_day}</td>
                          <td>{item.price_host.toLocaleString('en')}</td>

                          {Math.floor(item.price_host * 0.03) >= getMinTax() ? (
                            <>
                              <td>3%</td>
                              <td>{(Math.floor((item.price_host * 0.03) / 10) * 10).toLocaleString('en')}</td>
                              <td>{(Math.floor((Math.floor((item.price_host * 0.03) / 10) * 10 * 0.1) / 10) * 10).toLocaleString('en')}</td>
                              <td></td>
                              <td></td>
                              <td>
                                {(item.price_host - (Math.floor((item.price_host * 0.03) / 10) * 10 + Math.floor((Math.floor((item.price_host * 0.03) / 10) * 10 * 0.1) / 10) * 10)).toLocaleString(
                                  'en'
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{item.price_host.toLocaleString('en')}</td>
                            </>
                          )}
                          <td>{item.pay.toLocaleString('en')}</td>
                          <td>{item.cash ? 'Y' : 'N'}</td>
                        </tr>,
                      ]
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
