import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import {
  Button,
} from 'react-bootstrap'
import {toast} from 'react-toastify'

export function BookingPlaceCancel() {

  const {booking_id} = useParams<{booking_id: string}>()
  const [bookingInfo, setBookingInfo] = useState<any>(null)
  const [userType, setUserType] = useState(0)
  const [comment, setComment] = useState('')

  useEffect(() => {

    getBookingInfo()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const cancel = () => {
    if (window.confirm('취소 하시겠습니까?')) {
      axios.post(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/cancel`, {
        cancelled: userType === 0 ? 'guest' : 'host',
        cancelled_guest: userType === 0 ? comment : '',
        cancelled_host: userType === 1 ? comment : '',
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/booking/place/${booking_id}`
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  const getBookingInfo = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setBookingInfo(result)
        if (result.booking.status === 'cancelled' || result.booking.status === 'pending_cancelled') {
          window.location.href = `/booking/place/${booking_id}`
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 취소</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='col-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              {
                bookingInfo !== null &&
                <h1 className='card-title'>
                  예약 번호 : {bookingInfo.booking.id}
                </h1>
              }
            </div>
            <div className='card-body'>
              {
                bookingInfo !== null &&
                <>
                  <TextSub title={'장소 번호'} subTitle={bookingInfo.place.id} />
                  <TextSub title={'장소 제목'} subTitle={bookingInfo.place.title} />
                </>
              }

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'게스트'} subTitle={''} />
                </div>
                <div className='switch switch-icon'>
                  <div className='form-check form-check-custom form-check-solid form-switch mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={userType === 0}
                      onChange={() =>
                        setUserType(0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'호스트'} subTitle={''} />
                </div>
                <div className='switch switch-icon'>
                  <div className='form-check form-check-custom form-check-solid form-switch mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={userType === 1}
                      onChange={() =>
                        setUserType(1)
                      }
                    />
                  </div>
                </div>
              </div>

              <TextSub title={'취소 사유'} subTitle={''} />
              <div>
                <textarea
                  className='form-control'
                  rows={10}
                  placeholder='취소 사유를 작성해 주세요.'
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
                />
              </div>
            </div>
            {
              comment.trim() !== '' &&
              <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  variant='danger'
                  onClick={() => {
                    cancel()
                  }}>
                  취소 하기
                </Button>
              </div>
            }
          </div>
        </div>

      </div>

    </div>
  </>
}

