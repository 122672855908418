import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function UserTracking() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getTrackings()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getTrackings(page)
  }

  const getTrackings = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/log/actions?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>사용자 트래킹</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='table-responsive'>
          <table className='table table-striped gy-7 gs-7'>
            <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              <th className='min-w-100px'>ID</th>
              <th className='min-w-100px'>사용자</th>
              <th className='min-w-100px'>경로</th>
              <th className='min-w-100px'>액션</th>
              <th className='min-w-100px'>type</th>
              <th className='min-w-100px'>type1</th>
              <th className='min-w-100px'>type2</th>
              <th className='min-w-100px'>index</th>
              <th className='min-w-100px'>value</th>
              <th className='min-w-200px'>시간</th>
            </tr>
            </thead>
            <tbody>
            {
              items.map((item: any, index: number) => {
                return <>
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.user_id}</td>
                    <td>{item.path}</td>
                    <td>{item.action}</td>
                    <td>{item.type}</td>
                    <td>{item.type1}</td>
                    <td>{item.type2}</td>
                    <td>{item.index}</td>
                    <td>{item.value}</td>
                    <td>{item.created_at}</td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <label onClick={() => {
                        window.open(`/user/tracking/${item.user_id}`)
                      }} className='btn btn-primary'>보기</label>
                    </td>
                  </tr>
                </>
              })
            }
            </tbody>
          </table>
        </div>
      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />
    </div>
  </>
}

