import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function MarketingBtoBHostBanner() {
  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getBToBBanners()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getBToBBanners(page)
  }

  const getBToBBanners = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/btob/banner/H?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>호스트향 배너</PageTitle>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label
          className={'btn btn-primary'}
          onClick={() => {
            window.location.href = `/marketing/btob/banner/host/0`
          }}
        >
          메인 배너 만들기
        </label>

        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div>
            {items.map((item: any, index: number) => {
              return (
                <div className='col-12' key={index.toString()}>
                  <div className='card card-custom card-stretch shadow mb-5'>
                    <div className='card-header'>
                      <h1 className='card-title'>{item.id}.</h1>
                    </div>
                    <div className='card-body'>
                      <h3>PC 이미지</h3>
                      <div style={{position: 'relative', width: '100%', aspectRatio: `${1160 / 360}`, marginTop: 20}}>
                        <img src={item.image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                      </div>
                      <br />
                      <br />
                      <h3>Mobile 이미지</h3>
                      <div style={{position: 'relative', width: '50%', aspectRatio: `${375 / 240}`, marginTop: 20}}>
                        <img src={item.m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                      </div>

                      <div className='separator my-10' />
                      <h4>노출 : {item.active === 1 ? 'Y' : 'N'}</h4>
                    </div>
                    <div className='card-footer'>
                      <label
                        onClick={() => {
                          //window.open(`${process.env.REACT_APP_WEB_URL}banner/${item.id}`)
                        }}
                        className='btn btn-primary'
                      >
                        보기
                      </label>
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          window.location.href = `/marketing/btob/banner/host/${item.id}`
                        }}
                        className='btn btn-primary'
                      >
                        수정
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
      </div>
    </>
  )
}
