import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import ImagePath from '../../../commons/ImagePath'
import classNames from 'classnames'
import {isMobile} from 'react-device-detect'

export function ImageShowWait() {
    const {id} = useParams<{id: string}>()
    const [image, setImage] = useState<any>({
        id: 0,
        width: 0,
        height: 0,
        image_path: '',
        image_products: null,
    })

    const [position, setPosition] = useState(0)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getImage()
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function onScroll() {
        setPosition(window.scrollY)
    }

    const getImage = () => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/image/wait/${id}`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                console.log(result.image_products)
                if (error !== '') {
                } else {
                    result.image_products.forEach((product: any) => {
                        product.left = `${(product.x / product.width) * 100}%`
                        product.top = `${(product.y / product.height) * 100}%`
                    })
                    setImage(result)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [localMousePos, setLocalMousePos] = useState({x: 0, y: 0})
    const [localMousePosSize, setLocalMousePosSize] = useState({width: 0, height: 0})

    const handleMouseMove = (event: React.MouseEvent) => {
        // 👇 Get mouse position relative to element
        console.log(event)
        console.log(event.nativeEvent)
        const localX =
            event.nativeEvent.clientX -
            // @ts-ignore
            event.target.offsetLeft -
            // @ts-ignore
            event.target.offsetParent.offsetLeft -
            // @ts-ignore
            event.target.offsetParent.offsetParent.offsetLeft
        // @ts-ignore
        const localY =
            event.nativeEvent.clientY -
            // @ts-ignore
            event.target.offsetTop -
            // @ts-ignore
            event.target.offsetParent.offsetTop -
            // @ts-ignore
            event.target.offsetParent.offsetParent.offsetTop +
            position

        setLocalMousePos({x: localX, y: localY})
        // @ts-ignore
        setLocalMousePosSize({width: event.target.width, height: event.target.height})
    }

    const modify = (product: any) => {
        console.log('id : ' + product.id)

        console.log('x : ' + product.x)
        console.log('y : ' + product.y)

        console.log('width : ' + product.width)
        console.log('height : ' + product.height)

        console.log('type : place')
        console.log('equal : ' + product.equal)
        console.log('productLinkUrl : ' + product.product_link_url)
        console.log('keyword : ' + product.keyword)
        console.log('productLinkTitle : ' + product.product_link_title)
        console.log('productLinkThumbnail : ' + product.product_link_thumbnail)
        console.log('productLinkThumbnailImageId : ' + product.product_link_thumbnail_image_id)

        axios
            .put(`${process.env.REACT_APP_API2_URL}am/image/product`, {
                id: product.id,
                image_id: product.image_id,
                type: 'place',
                equal: product.equal,
                width: product.width,
                height: product.height,
                x: product.x,
                y: product.y,
                product_link_url: product.product_link_url,
                product_link_title: product.product_link_title,
                product_link_thumbnail: product.product_link_thumbnail,
                product_link_thumbnail_image_id: product.product_link_thumbnail_image_id,
                keyword: product.keyword,
            })
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    alert(error)
                } else {
                    alert('수정되었습니다.')
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const show = (productId = 0, show = 1) => {
        console.log('productId : ' + productId)
        console.log('show : ' + show)
        if (window.confirm('등록 완료 처리 하시겠습니까?')) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/image/product/show`, {
                    id: productId,
                    show: show,
                })
                .then(({data: {error, result}}) => {
                    console.log(error)
                    console.log(result)
                    if (error !== '') {
                        alert(error)
                    } else {
                        alert('등록 완료 처리 되었습니다.')
                        window.location.reload()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const del = (productId = 0) => {
        console.log('productId : ' + productId)

        axios
            .put(`${process.env.REACT_APP_API2_URL}am/image/${productId}/product/delete`, {
                id: productId,
            })
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    alert(error)
                } else {
                    alert('삭제되었습니다.')
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const imageSizeReload = (imageId = 0) => {
        console.log('imageId : ' + imageId)

        axios
            .get(`${process.env.REACT_APP_API2_URL}image/${imageId}/size/update`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    alert(error)
                } else {
                    alert('갱신되었습니다.')
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleItemFileInput = (e: any, product: any) => {
        setLoading(true)
        const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/interior`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file?.type,
                        },
                    })
                    .then((response) => {
                        console.log(path)
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'interior',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    let imageId = result.id
                                    let imagePath = result.image_path

                                    const temp = Object.assign({}, image)

                                    const temps = [...image.image_products]
                                    temps.splice(temps.indexOf(product), 1, product)
                                    product.product_link_thumbnail = imagePath
                                    product.product_link_thumbnail_image_id = imageId
                                    temp.image_products = temps
                                    setImage(temp)
                                    setLoading(false)
                                } else {
                                    console.log(error)
                                    setLoading(false)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        setLoading(false)
                    })
            } else {
                console.log(error)
                setLoading(false)
            }
        })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>이미지 등록 대기 상세</PageTitle>
            <div
                style={{
                    padding: 20,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                    <div className='col-12'>
                        <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                                <h1 className='card-title'>{id}</h1>
                            </div>
                            <div className='card-body'>
                                <div>
                                    {image.id > 0 && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                onMouseDown={handleMouseMove}
                                                style={{
                                                    position: 'relative',
                                                    border: '1px solid gray',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: image.width >= image.height * image.aspect_ratio ? '100%' : '50%',
                                                }}
                                            >
                                                <img style={{width: '100%'}} src={ImagePath.get(image.image_path, '2000x2000', 'inside')} alt={'장소 이미지'} />

                                                {image.width >= image.height * image.aspect_ratio ? (
                                                    <div
                                                        style={{
                                                            pointerEvents: 'none',
                                                            border: '3px solid red',
                                                            position: 'absolute',
                                                            height: '100%',
                                                            aspectRatio: `${image.aspect_ratio}`,
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            pointerEvents: 'none',
                                                            border: '3px solid red',
                                                            position: 'absolute',
                                                            width: '100%',
                                                            aspectRatio: `${image.aspect_ratio}`,
                                                        }}
                                                    ></div>
                                                )}

                                                {image.image_products != null &&
                                                    image.image_products.map((product: any, index: number) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    pointerEvents: 'none',
                                                                    position: 'absolute',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    top: product.top,
                                                                    left: product.left,
                                                                    width: 20,
                                                                    height: 20,
                                                                    marginTop: -10,
                                                                    marginLeft: -10,
                                                                    backgroundColor: 'white',
                                                                    borderRadius: 10,
                                                                }}
                                                                key={index}
                                                            >
                                                                {index + 1}
                                                            </div>
                                                        )
                                                    })}

                                                <div
                                                    style={{
                                                        pointerEvents: 'none',
                                                        position: 'absolute',
                                                        top: localMousePos.y,
                                                        left: localMousePos.x,
                                                        width: 20,
                                                        height: 20,
                                                        marginTop: -10,
                                                        marginLeft: -10,
                                                        backgroundColor: 'blue',
                                                        borderRadius: 10,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    )}
                                    <b>
                                        ({image.width}, {image.height}) ({localMousePosSize.width}, {localMousePosSize.height}) ({localMousePos.x}, {localMousePos.y})
                                        {(image.width < 0 || image.height < 0) && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <label
                                                    style={{marginTop: 10}}
                                                    onClick={() => {
                                                        imageSizeReload(image.id)
                                                    }}
                                                    className={classNames('btn btn-primary')}
                                                >
                                                    이미지 사이즈 갱신
                                                </label>
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                marginTop: 10,
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: isMobile ? 'column' : 'row',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    minWidth: 156,
                                                    fontWeight: 500,
                                                    fontSize: 15,
                                                    color: '#666970',
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                이미지 비율
                                            </p>
                                            <p
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: 15,
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                {image.aspect_ratio}
                                            </p>
                                        </div>
                                    </b>
                                </div>
                            </div>
                            <div className='card-footer'>
                                {image.image_products !== null &&
                                    image.image_products.map((product: any, index: number) => {
                                        return (
                                            <>
                                                <div className='card card-custom card-stretch shadow mb-5'>
                                                    <div className='card-header'>
                                                        <h1 className='card-title'>
                                                            {index + 1}. <label style={{color: 'red'}}>{product.delete === 1 ? '삭제' : ''}</label>
                                                        </h1>
                                                    </div>
                                                    <div className='card-body'>
                                                        <table style={{width: `100%`, lineHeight: 3}} key={index}>
                                                            <tbody>
                                                                <tr>
                                                                    <td width={'25%'}>
                                                                        <div
                                                                            className='form-check form-check-custom form-check-solid form-check-sm'
                                                                            style={{
                                                                                marginRight: 20,
                                                                                paddingBottom: 10,
                                                                            }}
                                                                        >
                                                                            <input
                                                                                className='form-check-input'
                                                                                type='radio'
                                                                                name={`product${index + 1}1`}
                                                                                value='1'
                                                                                checked={product.equal === 1}
                                                                                id={`check_${index + 1}1`}
                                                                                onChange={(e) => {
                                                                                    const temp = Object.assign({}, image)

                                                                                    const temps = [...image.image_products]
                                                                                    console.log(temps)
                                                                                    temps.splice(temps.indexOf(product), 1, product)
                                                                                    product.equal = 1
                                                                                    temp.image_products = temps
                                                                                    setImage(temp)
                                                                                }}
                                                                            />
                                                                            <label className='form-check-label' htmlFor={`check_${index + 1}1`}>
                                                                                동일 제품
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td width={'75%'}>
                                                                        <div
                                                                            className='form-check form-check-custom form-check-solid form-check-sm'
                                                                            style={{
                                                                                marginRight: 20,
                                                                                paddingBottom: 10,
                                                                            }}
                                                                        >
                                                                            <input
                                                                                className='form-check-input'
                                                                                type='radio'
                                                                                name={`product${index + 1}0`}
                                                                                value='0'
                                                                                checked={product.equal === 0}
                                                                                id={`check_${index + 1}0`}
                                                                                onChange={(e) => {
                                                                                    const temp = Object.assign({}, image)

                                                                                    const temps = [...image.image_products]
                                                                                    temps.splice(temps.indexOf(product), 1, product)
                                                                                    product.equal = 0
                                                                                    temp.image_products = temps
                                                                                    setImage(temp)
                                                                                }}
                                                                            />
                                                                            <label className='form-check-label' htmlFor={`check_${index + 1}0`}>
                                                                                비슷한 제품
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>제품 링크</td>
                                                                    <td
                                                                        style={{
                                                                            wordWrap: 'break-word',
                                                                            wordBreak: 'break-all',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            placeholder='링크 URL을 입력하세요'
                                                                            value={product.product_link_url}
                                                                            onChange={(e) => {
                                                                                const temp = Object.assign({}, image)

                                                                                const temps = [...image.image_products]
                                                                                temps.splice(temps.indexOf(product), 1, product)
                                                                                product.product_link_url = e.target.value
                                                                                temp.image_products = temps
                                                                                setImage(temp)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>키워드 (선택 사항 / 최대 10자/ 최대 5개)</td>
                                                                    <td>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            placeholder='태그를 입력하세요'
                                                                            value={product.keyword}
                                                                            onChange={(e) => {
                                                                                const temp = Object.assign({}, image)

                                                                                const temps = [...image.image_products]
                                                                                temps.splice(temps.indexOf(product), 1, product)
                                                                                product.keyword = e.target.value
                                                                                temp.image_products = temps
                                                                                setImage(temp)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>제품 링크 타이틀</td>
                                                                    <td
                                                                        style={{
                                                                            wordWrap: 'break-word',
                                                                            wordBreak: 'break-all',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            placeholder='제품 타이틀을 입력하세요'
                                                                            value={product.product_link_title}
                                                                            onChange={(e) => {
                                                                                const temp = Object.assign({}, image)

                                                                                const temps = [...image.image_products]
                                                                                temps.splice(temps.indexOf(product), 1, product)
                                                                                product.product_link_title = e.target.value
                                                                                temp.image_products = temps
                                                                                setImage(temp)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>제품 링크 썸네일 이미지 url</td>
                                                                    <td
                                                                        style={{
                                                                            wordWrap: 'break-word',
                                                                            wordBreak: 'break-all',
                                                                        }}
                                                                    >
                                                                        {product.product_link_thumbnail}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>제품 링크 썸네일 이미지</td>
                                                                    <td>
                                                                        {product.product_link_thumbnail_image_id !== 0 && (
                                                                            <>
                                                                                <img
                                                                                    src={ImagePath.get(product.product_link_thumbnail, '550x364')}
                                                                                    alt=''
                                                                                    style={{
                                                                                        width: 280,
                                                                                        height: 200,
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: 10,
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>제품 링크 썸네일 이미지 업로드</td>
                                                                    <td>
                                                                        <div style={{marginTop: 10}}>
                                                                            <input
                                                                                type='file'
                                                                                name='file'
                                                                                onChange={(e) => {
                                                                                    handleItemFileInput(e, product)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        {loading ? (
                                                            <div style={{width: '100%', height: 36.92, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                                    Loading...
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <label
                                                                    onClick={() => {
                                                                        modify(product)
                                                                    }}
                                                                    className='btn btn-sm btn-primary'
                                                                >
                                                                    수정
                                                                </label>
                                                                {product.delete === 0 && product.show === 2 && (
                                                                    <label
                                                                        style={{marginLeft: 10}}
                                                                        onClick={() => {
                                                                            show(product.id, 1)
                                                                        }}
                                                                        className='btn btn-sm btn-primary'
                                                                    >
                                                                        등록 완료
                                                                    </label>
                                                                )}
                                                                {product.delete === 0 && (
                                                                    <label
                                                                        style={{marginLeft: 10}}
                                                                        onClick={() => {
                                                                            del(product.id)
                                                                        }}
                                                                        className='btn btn-sm btn-danger'
                                                                    >
                                                                        삭제
                                                                    </label>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='separator my-10' />
                                            </>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
