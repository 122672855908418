import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {BookingFeedbackItem} from '../../components/BookingFeedbackItem'

export function BookingFeedback() {

  const [feedbacks, setFeedbacks] = useState<any>([])

  const [search, setSearch] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getFeedbacks()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getFeedbacks(page)
  }

  const onSearch = () => {
    getFeedbacks(1)
  }

  const getFeedbacks = (page = 1) => {
    setFeedbacks([])
    axios.get(`${process.env.REACT_APP_API2_URL}am/feedbacks?page=${page}&keyword=${search}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setFeedbacks(result.data)
        setCurrentPage(result.current_page)
        setPerPage(result.per_page)
        setTotal(result.total)
        window.scrollTo(0, 0)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const setBlind = (feedback: any) => {
    if (window.confirm('내용 숨김처리 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/feedback/${feedback.id}`, {
          blind: 1,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const items = [...feedbacks]
            items.splice(feedbacks.indexOf(feedback), 1, feedback)
            feedback.blind = 1
            setFeedbacks(items)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const setHide = (feedback: any) => {
    if (window.confirm('후기 삭제처리 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/feedback/${feedback.id}`, {
          hide: 1,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const items = [...feedbacks]
            items.splice(feedbacks.indexOf(feedback), 1, feedback)
            feedback.hide = 1
            setFeedbacks(items)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const deleteFeedback = (feedback: any) => {
    if (window.confirm('후기 재작성 하시겠습니까?')) {
      axios
        .delete(`${process.env.REACT_APP_API2_URL}am/feedback/${feedback.id}`)
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const items = [...feedbacks]
            items.splice(feedbacks.indexOf(feedback), 1)
            setFeedbacks(items)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 후기</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='예약 번호'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>

        {
          feedbacks.map((feedback: any, index: number) => {
            return <BookingFeedbackItem key={feedback.id.toString()} feedback={feedback} setHide={setHide} setBlind={setBlind} deleteFeedback={deleteFeedback} />
          })
        }

      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
  </>
}

