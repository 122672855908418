import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {toast} from 'react-toastify'

export function AdvertiseCancel() {
  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [places, setPlaces] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [nowPage, setNowPerPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [advTotalCount, setAdvTotalCount] = useState(0)
  const [cCount, setCCount] = useState(0)
  const [cwCount, setCwCount] = useState(0)

  useEffect(() => {
    getAdvCancelPlaces()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getAdvCancelPlaces(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getAdvCancelPlaces('', 1)
  }

  const handlePageChange = (page: number) => {
    getAdvCancelPlaces(status, page)
  }

  const getAdvCancelPlaces = (status = '', page = 1) => {
    setPlaces([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)
    setAdvTotalCount(0)
    setCCount(0)
    setCwCount(0)
    setNowPerPage(page)

    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/cancel/place?page=${page}&status=${status}&search=${search}`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          setPlaces(result.advertise.data)
          setCurrentPage(result.advertise.current_page)
          setPerPage(result.advertise.per_page)
          setTotal(result.advertise.total)
          setAdvTotalCount(result.advTotalCount)
          setCCount(result.advCCount)
          setCwCount(result.advCWCount)
          window.scrollTo(0, 0)
          console.log(places)
          // this.forceUpdate()
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const kcpCancel = (advCancelGroupId = 0) => {
    console.log('advCancelGroupId : ' + advCancelGroupId)
    if (window.confirm('카드 결제 환불 처리하시겠습니까?')) {
      const password = window.prompt('비밀번호를 적어주세요.', '')
      if (password === 'hour1234$$') {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/adv/${advCancelGroupId}/kcp/cancel`)
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              getAdvCancelPlaces(status, nowPage)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>취소/환불</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <select
            className='form-control'
            name='type'
            value={status}
            onChange={(e) => {
              onTab(e.target.value)
            }}
          >
            <option value={''}>모두보기 ({advTotalCount})</option>
            <option value={'CW'}>환불 대기중 ({cwCount})</option>
            <option value={'C'}>취소 완료 ({cCount})</option>
          </select>
          <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='광고 ID, 호스트 ID, 호스트 닉네임, 호스트 이름, 이메일, 휴대폰 번호, 장소 번호, 장소명'
              value={search}
              style={{flex: 1}}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
            />
            <label
              onClick={() => {
                onSearch()
              }}
              style={{width: 100}}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>
        </div>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {places.map((place: any, index: number) => {
              return (
                <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={index.toString()}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <h2>
                      [{place.advTypeDisplay}][{place.advStatusDisplay}]
                    </h2>
                  </div>

                  <div style={{paddingTop: 20, display: 'flex'}}>
                    <label
                      style={{fontSize: 18}}
                      onClick={() => {
                        if (place.advType === 'C') {
                          window.open(`/advertise/category/${place.advPlaceAdvertiseGroupId}/place`)
                        } else if (place.advType === 'N') {
                          window.open(`/advertise/network/${place.advPlaceAdvertiseGroupId}/place`)
                        }
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      광고 ID : {place.advPlaceAdvertiseGroupId}
                    </label>
                  </div>

                  <div style={{paddingTop: '15px'}}>
                    <table style={{fontSize: '15px', width: '100%'}}>
                      <colgroup>
                        <col style={{width: '10%'}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>호스트 닉네임</td>
                          <td>
                            {place.hostName} ({place.hostRealName})
                          </td>
                        </tr>
                        <tr>
                          <td>결제 수단</td>
                          <td>{place.usePayMethodDisplay}</td>
                        </tr>
                        <tr>
                          <td>취소 신청일</td>
                          <td>{place.advCancelledTime}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>취소 유형</td>
                          <td>{place.cancelTypeDisplay}</td>
                        </tr>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>신청 광고 일정</td>
                          <td>
                            {place.schedules.map((sc: any, index: number) => {
                              return (
                                <>
                                  <span key={index.toString()}>{sc.schedule_display}</span>
                                  <br />
                                </>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>취소 광고 일정</td>
                          <td>
                            {place.cancelSchedules.map((sc: any, index: number) => {
                              return (
                                <>
                                  <span key={index.toString()}>{sc.schedule_display}</span>
                                  <br />
                                </>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        {place.cancelType !== 'CANC' && (
                          <>
                            <tr>
                              <td>결제 금액</td>
                              <td>{Number(place.amount).toLocaleString()} (부가세 포함)</td>
                            </tr>
                            <tr>
                              <td>환불 금액</td>
                              <td>{place.cancelPrice.toLocaleString()} (부가세 포함)</td>
                            </tr>
                            <tr>
                              <td>환불 완료일</td>
                              <td>{place.refundAt}</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div style={{paddingTop: 20, display: 'flex'}}>
                    {(place.advKcpId !== 0 || place.advTossId !== 0 || place.advNaverpayId !== 0 || place.advkakaopayId !== 0) && place.isRefund === 0 && (
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          kcpCancel(place.advCancelGroupId)
                        }}
                        className='btn btn-sm btn-danger'
                      >
                        환불 하기
                      </label>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {places.length > 0 && (
          <div style={{marginTop: 20}}>
            <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
          </div>
        )}
      </div>
    </>
  )
}
