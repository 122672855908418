export default class ImagePath {
    static get(path: string, size: string, type = 'cover'): string {
        if (path?.startsWith('http')) {
            return path
        }
        return `${process.env.REACT_APP_AWS_IMG_URL}/${path}?s=${size}&t=${type}&q=80`
    }

    static getS3(path: string): string {
        return `${process.env.REACT_APP_AWS_S3_URL}/${path}`
    }
}
