import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import {isMobile} from 'react-device-detect'
import Modal from '../../components/Modal'

export function PlaceCategoryList() {

  const [items, setItems] = useState<any>([])
  const [subs, setSubs] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {

    getCategory()
    getFilterGroup()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCategory = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/category/filter`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getFilterGroup = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/category/group/filter`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setGroups(result)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const setFilterGroup = (item: any) => {
    console.log(item)
    if (window.confirm(`필터 그룹 ${item.filter_group} 으로 변경 하시겠습니까?`)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/category/mains`, {'id' : item.id, 'filter_group' : item.filter_group})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            console.log(item)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const setSubFilterGroupOrder = (item: any) => {
    console.log(item)
    if (window.confirm(`순서를 ${item.sub_order}으로 변경 하시겠습니까?`)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/category/subs/order`, {'id' : item.id, 'sub_order' : item.sub_order})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            setModalVisible(false)
            alert('변경되었습니다.');
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>카테고리 필터 그룹 관리</PageTitle>
    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }}>
      <div className="tab-content w-100" id="myTabContent">
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
            <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
            <tr>
              <th scope="col" className="min-w-30px"></th>
              <th scope="col" className="min-w-100px">카테고리명</th>
              <th scope="col" className="min-w-100px">일반/스튜디오</th>
              <th scope="col" className="min-w-100px">노출여부</th>
              <th scope="col" className="min-w-100px">정렬그룹</th>
              <th scope="col" className="min-w-30px"></th>
            </tr>
            </thead>
            <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
            {
              loading ?
                <tr style={{height: 98}}>
                  <td colSpan={44}>
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                    </div>
                  </td>
                </tr>
                :
                items !== null && items.map((item: any, index: number) => {
                  return (
                    <tr key={`${index}`} style={{height: 98}}>
                      <td></td>
                      <td
                        style={{minWidth: 40, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                        onClick={() => {
                          setSubs(item.subs)
                          setModalVisible(true)
                        }
                      }>{item.title}</td>
                      <td>{item.is_studio ? '스튜디오' : '일반'}</td>
                      <td>{item.show === 1 ? '노출' : '미노출'}</td>
                      <td>
                        <select
                          className='form-control'
                          name='type'
                          value={item.filter_group}
                          onChange={(e) => {
                            const temps = [...items]
                            temps.splice(temps.indexOf(item), 1, item)
                            item.filter_group = e.target.value
                            setItems(temps)
                            setFilterGroup(item)
                          }}
                        >
                          {
                            groups !== null && groups.map((group: any) => {
                              return <option value={group.id}>{group.name}({group.id})</option>
                            })
                          }
                        </select>
                      </td>
                      <td></td>
                    </tr>
                  )
                })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  {modalVisible && (
    <Modal
      visible={modalVisible}
      closable={true}
      maskClosable={true}
      onClose={() => {
        setModalVisible(false)
      }}
      className={''}
    >
      <div style={{height: '72vh', overflowY: 'auto'}}>
          <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
            <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
            <tr>
              <th scope="col" className="min-w-30px"></th>
              <th scope="col" className="min-w-100px">카테고리명</th>
              <th scope="col" className="min-w-100px">노출여부</th>
              <th scope="col" className="min-w-150px">정렬그룹순서</th>
              <th scope="col" className="min-w-100px">정렬그룹</th>
              <th scope="col" className="min-w-150px"></th>
              <th scope="col" className="min-w-30px"></th>
            </tr>
            </thead>
            <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
            {
              loading ?
                <tr style={{height: 98}}>
                  <td colSpan={44}>
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                    </div>
                  </td>
                </tr>
                :
                subs !== null && subs.map((item: any, index: number) => {
                  return (
                    <tr key={`${index}`} style={{height: 98}}>
                      <td></td>
                      <td>{item.title}</td>
                      <td>{item.show === 1 ? '노출' : '미노출'}</td>
                      <td>
                        <input type="text" className="form-control w-50" placeholder="" value={item.sub_order}
                               onChange={(e) => {
                                 const temps = [...subs]
                                 temps.splice(temps.indexOf(item), 1, item)
                                 item.sub_order = e.target.value
                                 setSubs(temps)
                               }}
                        />
                      </td>
                      <td>{item.main_category_filter_group}</td>
                      <td><button
                        style={{fontSize: 14, marginRight: 4}}
                        className='btn btn-sm btn-primary'
                        onClick={() => {
                          console.log(item)
                          setSubFilterGroupOrder(item)
                        }}
                      >
                        순서 변경
                      </button></td>
                      <td></td>
                    </tr>
                  )
                })
            }
            </tbody>
          </table>
      </div>
    </Modal>
  )}
  </>
}

