import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import moment from 'moment'
import {toast} from 'react-toastify'
import Modal from '../components/Modal'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import SwitchButton from '../../components/SwitchButton'

export function TeamDetail() {
  const [status, setStatus] = useState('1')

  const {team_id} = useParams<{team_id: string}>()
  const [team, setTeam] = useState<any>(null)
  const [modalPointVisible, setModalPointVisible] = useState(false)

  const [point, setPoint] = useState(0)
  const [pointComment, setPointComment] = useState('')
  const [pointExpire, setPointExpire] = useState('')

  const loginUser: any = useSelector<RootState>(({auth}) => auth.user)

  const [pointType, setPointType] = useState((loginUser.admin >> 10) % 2 !== 1 ? 'T' : '')
  useEffect(() => {
    getTeam()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getTeam = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/team/${team_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          setTeam(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const setUserPoint = (item: any, pointType = '', point = 0, pointComment = '', pointExpire = '') => {
    if (window.confirm('팀 포인트를 지급하시겠습니까?')) {
      console.log(item)
      console.log('pointType : ' + pointType)
      console.log('point : ' + point)
      console.log('pointComment : ' + pointComment)
      console.log('pointExpire : ' + pointExpire)
      if (item.team_delete === 1) {
        toast('삭제 된 팀입니다.')
        return false
      }

      if (pointType !== 'H' && pointType !== 'M' && pointType !== 'U' && pointType !== 'E' && pointType !== 'T') {
        toast('포인트 충전 타입을 확인하세요')
        return false
      }

      if (pointExpire.trim() !== '' && pointExpire.trim().length !== 8) {
        toast('소멸 예정일을 확인하세요')
        return false
      }
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/team/${item.id}/charge`, {type: pointType, point: point, comment: pointComment, expire: pointExpire})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>팀 상세</PageTitle>

      <div
        style={{
          padding: 10,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {team !== null && (
          <div
            style={{
              borderRadius: 20,
              backgroundColor: 'white',
              width: '100%',
            }}
          >
            <div
              style={{
                marginTop: 10,
                padding: 10,
                width: '100%',
                borderRadius: 10,
                border: '1px solid #DDD',
              }}
            >
              <div
                style={{
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottom: '1px solid #EEE',
                }}
              >
                <div>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <table style={{fontSize: '15px', width: '100%'}}>
                      <colgroup>
                        <col style={{width: '85%'}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td style={{fontWeight: 'bold'}}>
                            <h1>
                              {team.id}. {team.team_name}
                            </h1>
                            {team.team_delete === 1 && (
                              <p
                                style={{
                                  color: 'red',
                                }}
                              >
                                팀 삭제
                              </p>
                            )}
                          </td>
                          <td>
                            <img
                              src={team.team_profile_image_url}
                              alt=''
                              style={{
                                width: 100,
                                height: 100,
                                objectFit: 'cover',
                                borderRadius: 100,
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <table style={{fontSize: '15px', width: '100%'}}>
                    <colgroup>
                      <col style={{width: '25%'}} />
                      <col style={{width: 'auto'}} />
                    </colgroup>
                    <tbody>
                      <tr style={{fontSize: '19px'}}>
                        <td>팀 소개</td>
                        <td>{team.team_comment}</td>
                      </tr>
                      {team.team_company_url !== undefined && team.team_company_url !== null && team.team_company_url !== '' && (
                        <tr style={{fontSize: '19px'}}>
                          <td>사업자등록증</td>
                          <td>
                            사업자 인증 파일
                            <b
                              style={{
                                minWidth: 40,
                                paddingLeft: 10,
                                fontWeight: 500,
                                fontSize: 16,
                                color: '#007BFF',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                window.open(team.team_company_url)
                              }}
                            >
                              [보기]
                            </b>
                          </td>
                        </tr>
                      )}
                      <tr style={{fontSize: '19px'}}>
                        <td>팀리더</td>
                        <td>
                          {team.team_leader_user_id}. {team.user_name}
                          <b
                            style={{
                              minWidth: 40,
                              paddingLeft: 10,
                              fontWeight: 500,
                              fontSize: 16,
                              color: '#007BFF',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              window.open(`/user/${team.team_leader_user_id}`)
                            }}
                          >
                            [보기]
                          </b>
                        </td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>전화번호</td>
                        <td>{team.user_phone}</td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>팀리더 이메일</td>
                        <td>{team.user_email}</td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>멤버</td>
                        <td>{team.team_member_count}명</td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>총 결제금액</td>
                        <td>{team.team_booking_guest_price.toLocaleString('en')}원</td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>수수료</td>
                        <td>{team.team_booking_price_fee.toLocaleString('en')}원</td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>총 촬영 횟수</td>
                        <td>
                          {team.team_booking_completed_count} / {team.team_booking_completed_add_count}
                        </td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>포인트</td>
                        <td>{team.team_point.toLocaleString('en')}</td>
                      </tr>
                      <tr style={{fontSize: '19px'}}>
                        <td>포인트 소진</td>
                        <td>{team.team_booking_use_point.toLocaleString('en')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                style={{
                  marginTop: 20,
                }}
              >
                <SwitchButton id={team.team_leader_user_id}>Switch</SwitchButton>

                <label
                  style={{marginLeft: 10}}
                  onClick={() => {
                    setTeam(team)
                    setModalPointVisible(true)
                  }}
                  className='btn btn-sm btn-primary'
                >
                  포인트충전하기
                </label>
              </div>
            </div>

            <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '1' ? 'active' : '')} onClick={() => setStatus('1')}>
                  팀 정보 ({team.team_information_history_list !== undefined ? team.team_information_history_list.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '2' ? 'active' : '')} onClick={() => setStatus('2')}>
                  팀 리더 정보 ({team.team_leader_change_history_list !== undefined ? team.team_leader_change_history_list.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '3' ? 'active' : '')} onClick={() => setStatus('3')}>
                  팀원 현황 ({team.team_member_history_list !== undefined ? team.team_member_history_list.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '4' ? 'active' : '')} onClick={() => setStatus('4')}>
                  팀원 정보 ({team.team_member_list !== undefined ? team.team_member_list.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '5' ? 'active' : '')} onClick={() => setStatus('5')}>
                  모든 예약 내역 ({team.team_bookings !== undefined ? team.team_bookings.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '6' ? 'active' : '')} onClick={() => setStatus('6')}>
                  결제 예약 내역 ({team.team_paid_bookings !== undefined ? team.team_paid_bookings.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '7' ? 'active' : '')} onClick={() => setStatus('7')}>
                  포인트 내역 ({team.team_points !== undefined ? team.team_points.length : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '8' ? 'active' : '')} onClick={() => setStatus('8')}>
                  간편결제 ({team.team_auto_payment_cards !== undefined ? team.team_auto_payment_cards.length : 0})
                </label>
              </li>
            </ul>

            <div>
              {status === '1' && (
                <div>
                  {team.team_information_history_list !== undefined &&
                    team.team_information_history_list.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`1_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>회사명</td>
                                    <td>{item.team_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀소개</td>
                                    <td>{item.team_comment}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>사업자등록증</td>
                                    <td>{item.team_company_image_id}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '2' && (
                <div>
                  {team.team_leader_change_history_list !== undefined &&
                    team.team_leader_change_history_list.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`2_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 아이디</td>
                                    <td>{item.team_leader_user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 이메일</td>
                                    <td>{item.user_email}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 전화번호</td>
                                    <td>{item.user_phone}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 이름</td>
                                    <td>{item.user_real_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 변경</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '3' && (
                <div>
                  {team.team_member_history_list !== undefined &&
                    team.team_member_history_list.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`3_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.team_id}. {item.team_leader === 1 ? '리더' : '멤버'} <span style={{color: 'red'}}>{item.team_leave === 1 ? '(탈퇴)' : ''}</span>
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>멤버 아이디</td>
                                    <td>{item.user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 이메일</td>
                                    <td>{item.user_email}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 전화번호</td>
                                    <td>{item.user_phone}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 이름</td>
                                    <td>{item.user_real_name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '4' && (
                <div>
                  {team.team_member_list !== undefined &&
                    team.team_member_list.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`4_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.team_id}. {item.team_leader === 1 ? '리더' : '멤버'}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>멤버 아이디</td>
                                    <td>{item.user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 이메일</td>
                                    <td>{item.user_email}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 전화번호</td>
                                    <td>{item.user_phone}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>팀 리더 이름</td>
                                    <td>{item.user_real_name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '5' && (
                <div>
                  {team.team_bookings !== undefined &&
                    team.team_bookings.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`5_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.id}. {item.status_display}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>금액(부가세포함)</td>
                                    <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트</td>
                                    <td>{item.price_host.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>수수료</td>
                                    <td>{item.price_fee.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 번호</td>
                                    <td>{item.user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 이름</td>
                                    <td>{item.host_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 번호</td>
                                    <td>{item.guest_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 이름</td>
                                    <td>{item.guest_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약 일자</td>
                                    <td>
                                      {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ({item.hour}시간)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '6' && (
                <div>
                  {team.team_paid_bookings !== undefined &&
                    team.team_paid_bookings.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`6_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.id}. {item.status_display}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>금액(부가세포함)</td>
                                    <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트</td>
                                    <td>{item.price_host.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>수수료</td>
                                    <td>{item.price_fee.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 번호</td>
                                    <td>{item.user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 이름</td>
                                    <td>{item.host_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 번호</td>
                                    <td>{item.guest_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 이름</td>
                                    <td>{item.guest_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약 일자</td>
                                    <td>
                                      {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ({item.hour}시간)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '7' && (
                <div>
                  {team.team_points !== undefined &&
                    team.team_points.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`6_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약번호</td>
                                    <td>{item.booking_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>포인트</td>
                                    <td>{item.point.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>타입</td>
                                    <td>{item.type}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>내용</td>
                                    <td style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.comment}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>생성일자</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '8' && (
                <div>
                  {team.team_auto_payment_cards !== undefined &&
                    team.team_auto_payment_cards.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드명</td>
                                    <td>
                                      {item.card_name}
                                      {item.is_main_card === 1 && item.card_delete === 0 && <>&nbsp;(마지막 결제 카드)</>}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드정보</td>
                                    <td>{item.card_display_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드 상태</td>
                                    <td>{item.status}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드 등록 일자</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                  {item.card_delete === 1 && (
                                    <>
                                      <tr>
                                        <td style={{fontWeight: 'bold'}}>카드 삭제 일자</td>
                                        <td>{item.deleted_at}</td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        )}

        {modalPointVisible && (
          <Modal
            visible={modalPointVisible}
            closable={true}
            maskClosable={true}
            onClose={() => {
              setModalPointVisible(false)
            }}
            className={''}
          >
            <div>
              <div style={{marginTop: 26}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <table style={{fontSize: '15px', width: '100%'}}>
                    <colgroup>
                      <col className='col1' />
                      <col style={{width: 'auto'}} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>타 입</td>
                        <td>
                          <input
                            type='text'
                            style={{width: '100%'}}
                            disabled={(loginUser.admin >> 10) % 2 !== 1}
                            defaultValue={pointType}
                            onChange={(e) => {
                              setPointType(e.target.value)
                            }}
                          />
                          <span style={{fontSize: '13px', width: '100%'}}>T 테스트, H 임의 충전, M 마케팅 충전, SB 촬영 적립, I 추천 적립, B 직접 입금, U 사용, E 포인트 회수, W 출금.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>포 인 트</td>
                        <td>
                          <input
                            type='number'
                            style={{width: '100%'}}
                            onChange={(e) => {
                              setPoint(parseInt(e.target.value))
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>설 명</td>
                        <td>
                          <input
                            type='text'
                            style={{width: '100%'}}
                            onChange={(e) => {
                              setPointComment(e.target.value)
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>기 한</td>
                        <td>
                          <input
                            type='text'
                            style={{width: '100%'}}
                            placeholder='ex) 20220103'
                            onChange={(e) => {
                              setPointExpire(e.target.value)
                            }}
                          />
                          <span style={{fontSize: '13px', width: '100%'}}>소멸 예정일 ex) 20220103</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div className={'row'}>
                    <div style={{padding: 4, width: 'fit-content'}}>
                      <label
                        onClick={() => {
                          setUserPoint(team, pointType, point, pointComment, pointExpire)
                          setModalPointVisible(false)
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        충전하기
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}
