import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import ImagePath from "../../../commons/ImagePath";
import {useParams} from "react-router-dom";

export function UserPlaceFeedDetail() {

    const {feed_id} = useParams<{feed_id: string}>();

    const [item, setItem] = useState<any>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserPlaceFeed();
        return () => {
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUserPlaceFeed = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API2_URL}am/feed/${feed_id}/place`).then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
            } else {
              setItem(result)
              window.scrollTo(0, 0)
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>장소 사진, 영상 상세</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              {
                loading ?
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                    </div>
                    :
                    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                        <div className='col-12' >
                          <div className='card card-custom card-stretch shadow mb-5'>

                              <div className='card-header'>
                                  <h1 className='card-title'>
                                      장소 소식 ID : {item.id}
                                  </h1>
                                  <div style={{marginTop: 15}}>
                                      <label style={{marginLeft: 10}} onClick={() => {
                                          window.open(`${process.env.REACT_APP_WEB_URL}feed/${feed_id}/F`)
                                      }} className='btn btn-sm btn-primary'>웹에서 보기</label>
                                      <label style={{marginLeft: 10}} onClick={() => {
                                          window.location.href = `/feed/${item.id}/edit`;
                                      }} className='btn btn-sm btn-warning'>수정하기</label>
                                  </div>
                              </div>

                              <div className='card-body'>
                                  <table style={{fontSize: '15px', width: '100%'}}>
                                      <colgroup>
                                          <col className='col1'/>
                                          <col style={{ width: 'auto' }}/>
                                      </colgroup>
                                      <tbody style={{lineHeight: 2.5}}>
                                          <tr>
                                              <td>게시물 상태</td>
                                              <td style={{fontWeight: 500}}>{item.status === 'P' ? '게시 중' : (item.status === 'H' ? '숨김' : '삭제')}</td>
                                          </tr>
                                          <tr>
                                              <td>게시물 타입</td>
                                              <td style={{fontWeight: 500}}>{item.place_feed_type === 'I' ? '이미지' : (item.place_feed_type === 'V' ? '영상' : '유튜브')}</td>
                                          </tr>
                                          <tr>
                                              <td>게시물 등록일</td>
                                              <td style={{fontWeight: 500}}>{item.created_at}</td>
                                          </tr>
                                          <tr>
                                              <td>본문 내용</td>
                                              <td style={{fontWeight: 500}}>{item.content}</td>
                                          </tr>
                                          <tr>
                                              <td>태그</td>
                                              {
                                                  null !== item.tag &&
                                                  <td style={{fontWeight: 500}}>{item.tag}</td>
                                              }
                                          </tr>
                                          <tr>
                                              <td>장소번호</td>
                                              <td style={{fontWeight: 500}}>{item.place_id}
                                                  <span style={{minWidth: 40, paddingLeft: 5, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer'}}
                                                        onClick={() => {
                                                            window.open(`/place/${item.place_id}`)
                                                        }}
                                                  >보기</span>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  {
                                      item.place_feed_type === 'I' &&
                                      item.image.map((image: any) => {
                                        return (
                                            <>
                                                {
                                                    null !== image && null !== image.image_path &&
													<img src={ImagePath.get(image.image_path, '2000x2000', 'inside')} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' key={image.id.toString()}/>
                                                }
                                            </>
                                        )
                                      })
                                  }
                                  {
                                      item.place_feed_type === 'Y' &&
                                      <img src={item.youtube.image_maxres} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' />
                                  }
                                  {
                                      item.place_feed_type === 'V' &&
                                      <img src={ImagePath.get(item.video.thumbnail_path, '2000x2000', 'inside')} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' />
                                  }
                              </div>
                          </div>
                        </div>
                    </div>
              }
            </div>
        </>
    )
}

