import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";

export function AdvertiseNetworkScheduleEdit() {
  const {id} = useParams<{id: string}>()
  const [schedule, setSchedule] = useState<any>(null)

  useEffect(() => {
    getSchedule();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSchedule = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/${id}/schedule`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setSchedule(result)
      }
    }).catch((error) => {
      console.log(error)
    })

  }

  const update = () => {
    console.log(`${id}`)
    console.log(schedule)
    if (schedule.registerStartDate.length === 8 && schedule.advertiseStartDate.length === 8) {
      if (window.confirm('네크워크 광고 일정 수정 하시겠습니까?')) {
        axios.put(`${process.env.REACT_APP_API2_URL}am/adv/network/${id}/schedule`, {
          registerStartDate: schedule.registerStartDate
          , advertiseStartDate: schedule.advertiseStartDate
          , advertiseStartWeekNotification: schedule.advertiseStartWeekNotification
          , advertiseRegisterDeadlineNotification: schedule.advertiseRegisterDeadlineNotification
          , googleFormUrl: schedule.googleFormUrl
          , googleUserIdCode: schedule.googleUserIdCode
          , googlePhoneCode: schedule.googlePhoneCode
          , googleEmailCode: schedule.googleEmailCode
          , googlePlaceCode: schedule.googlePlaceCode
        }).then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.href = `/advertise/network/${id}/schedule`;
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    } else {
      toast.error('정보를 확인해 주세요.')
    }

  }

  return <>
    <PageTitle breadcrumbs={[]}>네크워크 광고 일정</PageTitle>
    <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
      {
          schedule !== null &&

          <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
              <div style={{marginTop: 10, paddingBottom: 10,}}>
                <div>
                  <table style={{fontSize: '15px', width: '100%'}}>
                    <colgroup>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: 'auto' }}/>
                    </colgroup>
                    <tbody>
                    <tr style={{fontSize: '15px'}}>
                      <td>접수 시작일</td>
                      <td>
                        <input type='text' className='form-control' placeholder='ex) 20990101' maxLength={8} value={schedule.registerStartDate}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.registerStartDate = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>광고 게재 시작일</td>
                      <td>
                        <input type='text' className='form-control' placeholder='ex) 20990101' maxLength={8} value={schedule.advertiseStartDate}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.advertiseStartDate = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>광고 시작 주차 공지</td>
                      <td>
                        <input type='text' className='form-control' placeholder='안내 페이지에 노출할 텍스트를 입력하세요. ex) 11/16(수) 주차' value={schedule.advertiseStartWeekNotification}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.advertiseStartWeekNotification = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>접수 마감일 공지</td>
                      <td>
                        <input type='text' className='form-control' placeholder='안내 페이지에 노출할 텍스트를 입력하세요. ex) 11/9(수) 17시' value={schedule.advertiseRegisterDeadlineNotification}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.advertiseRegisterDeadlineNotification = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글폼 URL</td>
                      <td>
                        <input type='text' className='form-control' placeholder='' value={schedule.googleFormUrl}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.googleFormUrl = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 회원 ID Code</td>
                      <td>
                        <input type='text' className='form-control' placeholder='' value={schedule.googleUserIdCode}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.googleUserIdCode = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 Phone Code</td>
                      <td>
                        <input type='text' className='form-control' placeholder='' value={schedule.googlePhoneCode}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.googlePhoneCode = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 Email Code</td>
                      <td>
                        <input type='text' className='form-control' placeholder='' value={schedule.googleEmailCode}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.googleEmailCode = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 Place Code</td>
                      <td>
                        <input type='text' className='form-control' placeholder='' value={schedule.googlePlaceCode}
                               onChange={(e) => {
                                 const temp = Object.assign({}, schedule)
                                 temp.googlePlaceCode = e.target.value
                                 setSchedule(temp)
                               }}/>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
                <label onClick={() => {
                  update();
                }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>저장하기</label>
              </div>
            </div>
          </div>
      }
    </div>
  </>
}

