import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import {TextSub2} from '../components/TextSub2'

export function Grey() {

  //const [grey, setGrey] = useState<any>(null)
  const [guestQuestionGrey, setGuestQuestionGrey] = useState<any>(null)
  const [guestBookingGrey, setGuestBookingGrey] = useState<any>(null)
  const [guestMessageGrey, setGuestMessageGrey] = useState<any>(null)
  const [guestUserChangeGrey, setGuestUserChangeGrey] = useState<any>(null)
  const [guestBookingCancelGrey, setGuestBookingCancelGrey] = useState<any>(null)

  const [hostPlaceGrey, setHostPlaceGrey] = useState<any>(null)
  const [hostAnswerGrey, setHostAnswerGrey] = useState<any>(null)
  const [hostMessageGrey, setHostMessageGrey] = useState<any>(null)
  const [hostHostChangeGrey, setHostHostChangeGrey] = useState<any>(null)
  const [hostBookingCancelGrey, setHostBookingCancelGrey] = useState<any>(null)
  const [hostAutoMessageGrey, setHostAutoMessageGrey] = useState<any>(null)

  useEffect(() => {

    getSetting()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSetting = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/setting`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
       //setGrey(result.grey)
        setGuestQuestionGrey(result.guest_question_grey)
        setGuestBookingGrey(result.guest_booking_grey)
        setGuestMessageGrey(result.guest_message_grey)
        setGuestUserChangeGrey(result.guest_user_change_grey)
        setGuestBookingCancelGrey(result.guest_booking_cancel_grey)

        setHostPlaceGrey(result.host_place_grey)
        setHostAnswerGrey(result.host_answer_grey)
        setHostMessageGrey(result.host_message_grey)
        setHostHostChangeGrey(result.host_host_change_grey)
        setHostBookingCancelGrey(result.host_booking_cancel_grey)
        setHostAutoMessageGrey(result.host_auto_message_grey)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const updateSetting = (setting: any) => {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/setting/${setting.key}`, {
        value: setting.value,
      })
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          toast('변경되었습니다.')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>설정</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <h1 className='card-title'>
          게스트 그레이 키워드 설정
        </h1>

        <TextSub2 title={'게스트 장소 문의 키워드'} subTitle={''} />
        {
            guestQuestionGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={guestQuestionGrey.value}
                  onChange={(e) => {
                    const temp = Object.assign({}, guestQuestionGrey)
                    temp.value = e.target.value
                    setGuestQuestionGrey(temp)
                  }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(guestQuestionGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'게스트 예약 신청 키워드'} subTitle={''} />
        {
            guestBookingGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={guestBookingGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, guestBookingGrey)
                          temp.value = e.target.value
                          setGuestBookingGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(guestBookingGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'게스트 예약 문의 키워드'} subTitle={''} />
        {
            guestMessageGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={guestMessageGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, guestMessageGrey)
                          temp.value = e.target.value
                          setGuestMessageGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(guestMessageGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'게스트 프로필 키워드'} subTitle={''} />
        {
            guestUserChangeGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={guestUserChangeGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, guestUserChangeGrey)
                          temp.value = e.target.value
                          setGuestUserChangeGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(guestUserChangeGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'게스트 예약 취소 키워드'} subTitle={''} />
        {
            guestBookingCancelGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={guestBookingCancelGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, guestBookingCancelGrey)
                          temp.value = e.target.value
                          setGuestBookingCancelGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(guestBookingCancelGrey)}} className='btn btn-primary'>수정하기</label>
        </div>
      </div>






      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <div className='separator my-10' />
        <h1 className='card-title'>호스트 그레이 키워드 설정</h1>

        <TextSub2 title={'호스트 장소 소개 키워드'} subTitle={''} />
        {
            hostPlaceGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={hostPlaceGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, hostPlaceGrey)
                          temp.value = e.target.value
                          setHostPlaceGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(hostPlaceGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'호스트 장소 문의 키워드'} subTitle={''} />
        {
            hostAnswerGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={hostAnswerGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, hostAnswerGrey)
                          temp.value = e.target.value
                          setHostAnswerGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(hostAnswerGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'호스트 예약 문의 키워드'} subTitle={''} />
        {
            hostMessageGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={hostMessageGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, hostMessageGrey)
                          temp.value = e.target.value
                          setHostMessageGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(hostMessageGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'호스트 프로필 키워드'} subTitle={''} />
        {
            hostHostChangeGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={hostHostChangeGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, hostHostChangeGrey)
                          temp.value = e.target.value
                          setHostHostChangeGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(hostHostChangeGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'호스트 예약 취소 키워드'} subTitle={''} />
        {
            hostBookingCancelGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={hostBookingCancelGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, hostBookingCancelGrey)
                          temp.value = e.target.value
                          setHostBookingCancelGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(hostBookingCancelGrey)}} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'호스트 자동 안내메시지 키워드'} subTitle={''} />
        {
            hostAutoMessageGrey !== null &&
            <div>
              <textarea className='form-control' rows={2} placeholder='' value={hostAutoMessageGrey.value}
                        onChange={(e) => {
                          const temp = Object.assign({}, hostAutoMessageGrey)
                          temp.value = e.target.value
                          setHostAutoMessageGrey(temp)
                        }}
              />
            </div>
        }
        <div style={{padding: 10}}>
          <label onClick={() => {updateSetting(hostAutoMessageGrey)}} className='btn btn-primary'>수정하기</label>
        </div>
      </div>

    </div>
  </>
}

