import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import Modal from '../../components/Modal'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {toast} from 'react-toastify'
import SwitchButton from '../../../components/SwitchButton'

export function TeamList() {
  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('')

  const [items, setItems] = useState<any>([])
  const [team, setTeam] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [modalPointVisible, setModalPointVisible] = useState(false)

  const [point, setPoint] = useState(0)
  const [pointComment, setPointComment] = useState('')
  const [pointExpire, setPointExpire] = useState('')

  const loginUser: any = useSelector<RootState>(({auth}) => auth.user)

  const [pointType, setPointType] = useState((loginUser.admin >> 10) % 2 !== 1 ? 'T' : '')

  useEffect(() => {
    getTeams()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getTeams(page)
  }

  const onSearch = () => {
    getTeams(1)
  }

  const getTeams = (page = 1) => {
    setItems([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/team?search=${search}&page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)

          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const setUserPoint = (item: any, pointType = '', point = 0, pointComment = '', pointExpire = '') => {
    if (window.confirm('팀 포인트를 지급하시겠습니까?')) {
      console.log(item)
      console.log('pointType : ' + pointType)
      console.log('point : ' + point)
      console.log('pointComment : ' + pointComment)
      console.log('pointExpire : ' + pointExpire)
      if (item.team_delete === 1) {
        toast('삭제 된 팀입니다.')
        return false
      }

      if (pointType !== 'H' && pointType !== 'M' && pointType !== 'U' && pointType !== 'E' && pointType !== 'T' && pointType !== 'B') {
        toast('포인트 충전 타입을 확인하세요')
        return false
      }

      if (pointExpire.trim() !== '' && pointExpire.trim().length !== 8) {
        toast('소멸 예정일을 확인하세요')
        return false
      }
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/team/${item.id}/charge`, {type: pointType, point: point, comment: pointComment, expire: pointExpire})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const teamPointUpdate = (team: any) => {
    if (window.confirm(`포인트 갱신 하시겠습니까?`)) {
      axios
        .get(`${process.env.REACT_APP_API2_URL}team/${team.id}/update/point`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>팀</PageTitle>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='팀번호, 회사명'
              value={search}
              style={{flex: 1}}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
            />
            <label
              onClick={() => {
                onSearch()
              }}
              style={{width: 100}}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>

          {loading ? (
            <div
              style={{
                width: '100%',
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
              }}
            >
              {items.map((item: any, index: number) => {
                return (
                  <div
                    style={{
                      marginTop: 30,
                      width: '100%',
                    }}
                    key={item.id.toString()}
                  >
                    <div
                      style={{
                        marginTop: 10,
                        padding: 10,
                        width: '100%',
                        borderRadius: 10,
                        border: '1px solid #DDD',
                      }}
                    >
                      <div
                        style={{
                          marginTop: 10,
                          paddingBottom: 10,
                          borderBottom: '1px solid #EEE',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            <table style={{fontSize: '15px', width: '100%'}}>
                              <colgroup>
                                <col style={{width: '85%'}} />
                                <col style={{width: 'auto'}} />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>
                                    <h1>
                                      {item.id}. {item.team_name}
                                    </h1>
                                    {item.team_delete === 1 && (
                                      <p
                                        style={{
                                          color: 'red',
                                        }}
                                      >
                                        팀 삭제
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <img
                                      src={item.team_profile_image_url}
                                      alt=''
                                      style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: 'cover',
                                        borderRadius: 100,
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <table style={{fontSize: '15px', width: '100%'}}>
                            <colgroup>
                              <col style={{width: '25%'}} />
                              <col style={{width: 'auto'}} />
                            </colgroup>
                            <tbody>
                              <tr style={{fontSize: '19px'}}>
                                <td>팀 소개</td>
                                <td>{item.team_comment}</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>멤버</td>
                                <td>{item.team_member_count}명</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>리더 결제대기</td>
                                <td>{item.team_leader_booking_paid_count}건</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>가상계좌 환불대기</td>
                                <td>{item.team_leader_booking_refund_count}건</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>팀 예약 전체</td>
                                <td>{item.team_booking_count}건</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>총 결제금액</td>
                                <td>{item.team_booking_guest_price.toLocaleString('en')}원</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>수수료</td>
                                <td>{item.team_booking_price_fee.toLocaleString('en')}</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>총 촬영 횟수</td>
                                <td>
                                  {item.team_booking_completed_count} / {item.team_booking_completed_add_count}
                                </td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>포인트</td>
                                <td>{item.team_point.toLocaleString('en')}</td>
                              </tr>
                              <tr style={{fontSize: '19px'}}>
                                <td>포인트 소진</td>
                                <td>{item.team_booking_use_point.toLocaleString('en')}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <SwitchButton id={item.team_leader_user_id}>Switch</SwitchButton>

                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            window.open(`/team/${item.id}`)
                          }}
                          className='btn btn-sm btn-primary'
                        >
                          자세히 보기
                        </label>

                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            setTeam(item)
                            setModalPointVisible(true)
                          }}
                          className='btn btn-sm btn-primary'
                        >
                          팀 포인트 충전하기
                        </label>

                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            setTeam(item)
                            teamPointUpdate(item)
                          }}
                          className='btn btn-sm btn-primary'
                        >
                          팀 포인트 갱신하기
                        </label>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>

        {modalPointVisible && (
          <Modal
            visible={modalPointVisible}
            closable={true}
            maskClosable={true}
            onClose={() => {
              setModalPointVisible(false)
            }}
            className={''}
          >
            <div>
              <div style={{marginTop: 26}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <table style={{fontSize: '15px', width: '100%'}}>
                    <colgroup>
                      <col className='col1' />
                      <col style={{width: 'auto'}} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>타 입</td>
                        <td>
                          <input
                            type='text'
                            style={{width: '100%'}}
                            disabled={(loginUser.admin >> 10) % 2 !== 1}
                            defaultValue={pointType}
                            onChange={(e) => {
                              setPointType(e.target.value)
                            }}
                          />
                          <span style={{fontSize: '13px', width: '100%'}}>T 테스트, H 임의 충전, M 마케팅 충전, SB 촬영 적립, I 추천 적립, B 직접 입금, U 사용, E 포인트 회수, W 출금.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>포 인 트</td>
                        <td>
                          <input
                            type='number'
                            style={{width: '100%'}}
                            onChange={(e) => {
                              setPoint(parseInt(e.target.value))
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>설 명</td>
                        <td>
                          <input
                            type='text'
                            style={{width: '100%'}}
                            onChange={(e) => {
                              setPointComment(e.target.value)
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>기 한</td>
                        <td>
                          <input
                            type='text'
                            style={{width: '100%'}}
                            placeholder='ex) 20220103'
                            onChange={(e) => {
                              setPointExpire(e.target.value)
                            }}
                          />
                          <span style={{fontSize: '13px', width: '100%'}}>소멸 예정일 ex) 20220103</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div className={'row'}>
                    <div style={{padding: 4, width: 'fit-content'}}>
                      <label
                        onClick={() => {
                          setUserPoint(team, pointType, point, pointComment, pointExpire)
                          setModalPointVisible(false)
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        충전하기
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
      </div>
    </>
  )
}
