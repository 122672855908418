import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub2} from '../components/TextSub2'
import {isMobile} from 'react-device-detect'
import Postcode from '@actbase/react-daum-postcode'
import Modal from '../components/Modal'
import {toast} from 'react-toastify'
import ImagePath from '../../commons/ImagePath'
import Dropzone from 'react-dropzone'

export function PlaceEdit() {
  const {place_id} = useParams<{place_id: string}>()

  const [place, setPlace] = useState<any>(null)

  const [editInfo, setEditInfo] = useState<any>(null)
  const [categorySubs, setCategorySubs] = useState<any>([])

  const [modalVisible, setModalVisible] = useState(false)

  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    getPlace()
    getEditInfo()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (place !== null && editInfo !== null) {
      editInfo.category_mains.some((category_main: any, index: number) => {
        if (place.category_main_id === category_main.id) {
          setCategorySubs(category_main.subs)
          return true
        }
        return false
      })
    }
  }, [place, editInfo])

  const getPlace = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          if (result.price_type === 1) {
            result.place_diff_amount.forEach((temp: any) => {
              if (temp.day === '0,6' || temp.day === '6,0') {
                result.price_max = temp.price
              } else {
                result.price_min = temp.price
              }
            })
          }
          result.title = result.o_place_title
          const images: any[] = []
          result.images.forEach((image: any) => {
            let url = image.image_url.replace('https://s3.hourplace.co.kr/', '')
            images.push({
              ...image,
              image_url: `${process.env.REACT_APP_AWS_IMG_URL}/${url}?s=550x364&t=cover&q=80`,
            })
          })
          result.images = images
          setPlace(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEditInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/edit/info`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setEditInfo(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const update = () => {
    if (place.status === 'hide') {
      if (!window.confirm('관리자 숨김 처리 하시겠어요?')) {
        return false
      }
    } else if (place.status === 'trash') {
      if (!window.confirm('삭제 처리 하시겠어요?')) {
        return false
      } else {
        axios
          .get(`${process.env.REACT_APP_API2_URL}am/place/${place_id}/booking/check/trash`)
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
              toast.error(error)
            } else {
              if(0 < result) {
                if (!window.confirm('진행 중인 예약 '+result+'건이 있습니다. 삭제 처리 하시겠어요?')) {
                  return false
                } else {
                  axios
                    .put(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`, {...place})
                    .then(({data: {error, result}}) => {
                      console.log(error)
                      console.log(result)
                      if (error !== '') {
                        toast.error(error)
                      } else {
                        window.location.href = `/place/${place_id}`
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }
              } else {
                axios
                  .put(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`, {...place})
                  .then(({data: {error, result}}) => {
                    console.log(error)
                    console.log(result)
                    if (error !== '') {
                      toast.error(error)
                    } else {
                      window.location.href = `/place/${place_id}`
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
        return false;
      }
    } else {
      if (place.images.length === 0) {
        place.photo_inp = 'inp_error'
        toast.error('장소 사진을 등록해 주세요')
        return false
      }

      if (place.place_group_id > 0 && place.group_title.length === 0) {
        toast.error('장소 그룹 제목을 입력해 주세요')
        return false
      }

      if (place.title.length === 0) {
        toast.error('장소 제목을 입력해 주세요')
        return false
      }

      if (place.description.length === 0) {
        toast.error('장소 소개를 입력해 주세요.')
        return false
      }

      if (place.area <= 0) {
        toast.error('면적을 입력해 주세요.')
        return false
      }

      if (place.floor.length === 0) {
        toast.error('해당 층을 선택해 주세요.')
        return false
      }

      if (place.people.length === 0) {
        toast.error('기본 인원을 선택해 주세요.')
        return false
      }

      if (place.parking.length === 0) {
        toast.error('주차 가능 대수를 선택해 주세요.')
        return false
      }

      if (place.address.length === 0) {
        toast.error('주소를 입력 주세요.')
        return false
      }

      if (place.use_rules.length === 0) {
        toast.error('장소 이용 규칙을 입력해 주세요.')
        return false
      }

      if (place.price_type === 0 && place.price < 1000) {
        toast.error('금액을 입력해 주세요.')
        return false
      }

      if (place.price_type === 1 && (place.price_min < 1000 || place.price_max < 1000)) {
        toast.error('금액을 입력해 주세요.')
        return false
      }

      if (place.price_type === 1) {
        place.place_diff_amount = [
          {
            title: '평일',
            day: '1,2,3,4,5',
            price: place.price_min,
            day_time_type_a_start: '',
            day_time_type_a_end: '',
            day_time_type_a_price: 0,
            day_time_type_b_start: '',
            day_time_type_b_end: '',
            day_time_type_b_price: 0,
            day_time_type_c_start: '',
            day_time_type_c_end: '',
            day_time_type_c_price: 0,
            is_holiday: 0,
            order: 1,
          },
          {
            title: '주말',
            day: '0,6',
            price: place.price_max,
            day_time_type_a_start: '',
            day_time_type_a_end: '',
            day_time_type_a_price: 0,
            day_time_type_b_start: '',
            day_time_type_b_end: '',
            day_time_type_b_price: 0,
            day_time_type_c_start: '',
            day_time_type_c_end: '',
            day_time_type_c_price: 0,
            is_holiday: 0,
            order: 2,
          },
        ]
      }
    }

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`, {...place})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/place/${place_id}`
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleItemFileInput = (file: any) => {
    //const file = e.target.files[0]

    setUploading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}upload/place`)
      .then(({data: {error, result}}) => {
        if (error === '') {
          const {path, url} = result
          axios
            .put(url, file, {
              headers: {
                'Content-Type': file?.type,
              },
            })
            .then((response) => {
              console.log(path)
              axios
                .post(`${process.env.REACT_APP_API2_URL}upload/place`, {
                  path,
                  url: ImagePath.getS3(path),
                })
                .then(({data: {error, result}}) => {
                  if (error === '') {
                    let image_id = result.id

                    let image = {
                      id: 0,
                      image_url: '',
                    }
                    image.id = image_id
                    image.image_url = result.image_url

                    const temp = {...place}
                    console.log()
                    //temp.images.splice(temp.image.length, 1)
                    temp.images.splice(temp.images.length + 1, 0, image)
                    setPlace(temp)

                    console.log(place)
                    setUploading(false)
                  } else {
                    setUploading(false)
                    console.log(error)
                  }
                })
                .catch((error) => {
                  setUploading(false)
                  console.log(error)
                })
            })
            .catch((error) => {
              setUploading(false)
              console.log(error)
            })
        } else {
          setUploading(false)
          console.log(error)
        }
      })
      .catch((error) => {
        setUploading(false)
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>장소 수정</PageTitle>

      <div
        style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {place !== null && (
          <div>
            <h1>
              {place.id}. {place.title}
            </h1>
          </div>
        )}

        {place !== null && (
          <div
            style={{
              padding: isMobile ? 0 : 20,
              borderRadius: 20,
              backgroundColor: 'white',
              width: '100%',
            }}
          >
            <TextSub2 title={'상태'} subTitle={''} />

            <div>
              <select
                className='form-select form-select-solid'
                name='type'
                value={place.status === 'hide' && place.inactive === 1 ? 'inactive' : place.status}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.status = e.target.value
                  setPlace(temp)
                }}
              >
                <option key={'publish'} value={'publish'}>
                  게시 중
                </option>
                <option key={'hide'} value={'hide'}>
                  숨김 (알림톡 발송)
                </option>
                <option key={'inactive'} value={'inactive'}>
                  미활동 숨김 (알림톡 발송)
                </option>
                <option key={'unpublish'} value={'unpublish'}>
                  관리자 숨김
                </option>
                <option key={'leave'} value={'leave'}>
                  탈퇴
                </option>
                <option key={'temporary'} value={'temporary'}>
                  작성중
                </option>
                <option key={'trash'} value={'trash'}>
                  삭제
                </option>
              </select>
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'장소 사진'} subTitle={'최대 20장까지 등록 가능합니다.'} />

            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {place.images.map((image: any, index: number) => {
                return (
                  <div
                    key={`place_image_${index}`}
                    style={{
                      width: 240,
                      height: 160,
                      margin: 8,
                      overflow: 'hidden',
                      border: '1px solid #DDD',
                      position: 'relative',
                    }}
                  >
                    {image !== null && image.image_url !== null && (
                      <img
                        src={image.image_url}
                        alt=''
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                    <div
                      style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: 28,
                        height: 28,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        if (index > 0) {
                          const temp = {...place}
                          temp.images.splice(index, 1)
                          temp.images.splice(index - 1, 0, image)
                          setPlace(temp)
                        }
                      }}
                    >
                      {index > 0 && <p style={{color: 'blue', margin: 0, fontSize: 20}}>{'<'}</p>}
                    </div>
                    <div
                      style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: 0,
                        left: 28,
                        width: 28,
                        height: 28,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        if (index < place.images.length - 1) {
                          const temp = {...place}
                          temp.images.splice(index, 1)
                          temp.images.splice(index + 1, 0, image)
                          setPlace(temp)
                        }
                      }}
                    >
                      {index < place.images.length - 1 && <p style={{color: 'blue', margin: 0, fontSize: 20}}>{'>'}</p>}
                    </div>

                    <div
                      style={{
                        borderTopLeftRadius: 10,
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        padding: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (place.images.length > 1) {
                          if (window.confirm('이미지를 삭제하시겠습니까?')) {
                            const temp = {...place}
                            temp.images.splice(temp.images.indexOf(image), 1)
                            setPlace(temp)
                          }
                        }
                      }}
                    >
                      <p style={{color: 'red', margin: 0}}>삭제</p>
                    </div>
                  </div>
                )
              })}
              {place.images.length < 20 && (
                <div
                  style={{
                    width: 240,
                    height: 160,
                    margin: 8,
                    overflow: 'hidden',
                    border: '1px solid #DDD',
                    position: 'relative',
                  }}
                >
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      console.log(acceptedFiles)
                      handleItemFileInput(acceptedFiles[0])
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section
                        className='container'
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#DDD',
                          borderRadius: 4,
                          cursor: 'pointer',
                          padding: 0,
                        }}
                      >
                        <div
                          {...getRootProps({className: 'dropzone'})}
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <input {...getInputProps()} />
                          <p style={{margin: 0}}>이미지를 드래그 하여 넣거나 클릭하세요.</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {uploading && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255,255,255,0.8)',
                      }}
                    >
                      <span className='spinner-border ' />
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'키워드'} subTitle={'최대 10개까지 입력 가능합니다.'} />

            <div>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={place.categories}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.categories = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'검색용 키워드'} subTitle={'최대 10개까지 입력 가능합니다.'} />

            <div>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={place.search_tag}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.search_tag = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            {place.place_group_id > 0 && (
              <>
                <div className='separator my-10' />
                <TextSub2 title={'장소 그룹 제목'} subTitle={''} />

                <div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    value={place.group_title}
                    onChange={(e) => {
                      const temp = Object.assign({}, place)
                      temp.group_title = e.target.value
                      setPlace(temp)
                    }}
                  />
                </div>
              </>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'장소 제목'} subTitle={''} />

            <div>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={place.title}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.title = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'장소 소개'} subTitle={''} />

            <div>
              <textarea
                style={{
                  width: '100%',
                  height: 200,
                }}
                value={place.description}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.description = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'장소 유형 메인'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.category_main_id}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.category_main_id = Number(e.target.value)
                    temp.category_sub_id = 0
                    setPlace(temp)
                  }}
                >
                  <option key={`category_main_0`} value={0}>
                    없음
                  </option>
                  {editInfo.category_mains.map((category_main: any, index: number) => {
                    return (
                      <option key={`category_main_${index}`} value={category_main.id}>
                        {category_main.title}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'장소 유형 서브'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.category_sub_id}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.category_sub_id = Number(e.target.value)
                    setPlace(temp)
                  }}
                >
                  <option key={`category_sub_0`} value={0}>
                    없음
                  </option>
                  {categorySubs.map((sub: any, index: number) => {
                    return (
                      <option key={`category_sub_${index}`} value={sub.id}>
                        {sub.title}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'면적(평)'} subTitle={''} />

            <div>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={place.area}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.area = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'해당 층'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.floor}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.floor = e.target.value
                    setPlace(temp)
                  }}
                >
                  <option value=''>선택해주세요</option>
                  {editInfo.floors.map((floor: any, index: number) => {
                    return (
                      <option key={`floor_${index}`} value={floor}>
                        {floor}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'기본 인원'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.people}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.people = e.target.value
                    setPlace(temp)
                  }}
                >
                  {editInfo.peoples.map((people: any, index: number) => {
                    return (
                      <option key={`people_${index}`} value={people}>
                        {people}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'주차 가능 대수'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.parking}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.parking = e.target.value
                    setPlace(temp)
                  }}
                >
                  {editInfo.parkings.map((parking: any, index: number) => {
                    return (
                      <option key={`parking_${index}`} value={parking}>
                        {parking}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'주소'} subTitle={'입력하신 주소는 게스트에게 노출되지 않으며 대략적인 위치만 지도에 보여집니다.'} />

            <div>
              <h4>{place.address}</h4>
              <label
                onClick={() => {
                  setModalVisible(true)
                }}
                style={{
                  width: 100,
                }}
                className='btn btn-dark'
              >
                주소찾기
              </label>
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'장소 지역'} subTitle={'주소에 맞춰서 변경해 주세요.'} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.local}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.local = e.target.value
                    setPlace(temp)
                  }}
                >
                  {editInfo.locals.map((local: any, index: number) => {
                    return (
                      <option key={`local_${index}`} value={local}>
                        {local}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'장소 이용 규칙'} subTitle={''} />

            <div>
              <textarea
                style={{
                  width: '100%',
                  height: 200,
                }}
                value={place.use_rules}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.use_rules = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'주변 정보'} subTitle={''} />

            <div>
              <textarea
                style={{
                  width: '100%',
                  height: 200,
                }}
                value={place.nearby_information}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.nearby_information = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'자동 전송 메시지'} subTitle={''} />

            <div>
              <textarea
                style={{
                  width: '100%',
                  height: 200,
                }}
                value={place.auto_send_info_message}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.auto_send_info_message = e.target.value
                  setPlace(temp)
                }}
              />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'요금제 타입'} subTitle={'체크시 차등'} />

            <input
              className='form-check-input'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              checked={place.price_type === 1}
              onChange={(event) => {
                const temp = Object.assign({}, place)
                temp.price_type = event.target.checked ? 1 : 0
                setPlace(temp)
              }}
            />

            {place.price_type === 0 ? (
              <div>
                <div className='separator my-10' />
                <TextSub2 title={'금액'} subTitle={''} />

                <div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    value={place.price}
                    onChange={(e) => {
                      const temp = Object.assign({}, place)
                      temp.price = e.target.value
                      setPlace(temp)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div style={{width: '50%', paddingRight: 20}}>
                  <div className='separator my-10' />
                  <TextSub2 title={'평일'} subTitle={''} />

                  <div>
                    <input
                      type='text'
                      className='form-control'
                      placeholder=''
                      value={place.price_min}
                      onChange={(e) => {
                        const temp = Object.assign({}, place)
                        temp.price_min = e.target.value
                        setPlace(temp)
                      }}
                    />
                  </div>
                </div>

                <div style={{width: '50%', paddingRight: 20}}>
                  <div className='separator my-10' />
                  <TextSub2 title={'주말'} subTitle={''} />

                  <div>
                    <input
                      type='text'
                      className='form-control'
                      placeholder=''
                      value={place.price_max}
                      onChange={(e) => {
                        const temp = Object.assign({}, place)
                        temp.price_max = e.target.value
                        setPlace(temp)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'할증 추가 인원 금액'} subTitle={''} />

            <div>
              <select
                className='form-select form-select-solid'
                name='type'
                value={place.price_extra}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.price_extra = e.target.value
                  setPlace(temp)
                }}
              >
                <option value={0}>0</option>
                <option value={5500}>5,500</option>
                <option value={11000}>11,000</option>
              </select>
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'최소 대여 시간'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.min_hour}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.min_hour = e.target.value
                    setPlace(temp)
                  }}
                >
                  {editInfo.times.map((time: any, index: number) => {
                    return (
                      <option key={`time_${index}`} value={time}>
                        {time}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'결제 방식'} subTitle={''} />
            <div>
              <select
                className='form-select form-select-solid'
                name='type'
                value={place.payments_type}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.payments_type = e.target.value
                  setPlace(temp)
                }}
                disabled={place.is_studio === 0}
              >
                <option key={`payments_type_0`} value={0}>
                  승인결제
                </option>
                <option key={`payments_type_1`} value={1}>
                  바로결제
                </option>
              </select>
            </div>

            <div
              style={{
                width: '100%',
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  padding: 10,
                }}
              >
                <label
                  onClick={() => {
                    update()
                  }}
                  className='btn btn-primary'
                >
                  수정하기
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <Postcode
            style={{width: '100%', height: '100%'}}
            jsOptions={{animation: true, hideMapBtn: true}}
            onSelected={(data) => {
              const temp = Object.assign({}, place)
              temp.address = data.address
              setPlace(temp)
              setModalVisible(false)
            }}
            onError={(error) => {}}
          />
        </Modal>
      )}
    </>
  )
}
