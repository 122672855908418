import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import ImagePath from '../../../commons/ImagePath'
import {isMobile} from 'react-device-detect'
import Modal from "../../components/Modal";
import Modal2 from '../../components/Modal2'
import {Button} from 'react-bootstrap'
import Dropzone from 'react-dropzone'

export function MarketingConceptCategoryEditor() {
  const {conceptCategoryId} = useParams<{conceptCategoryId: string}>()

  const [item, setItem] = useState<any>('')
  const [places, setPlaces] = useState<any>('')
  const [groupChangePlaces, setGroupChangePlaces] = useState<any>('')
  const [tempGroupChangePlaces, setTempGroupChangePlaces] = useState<any>('')
  const [imageChangePlaces, setImageChangePlaces] = useState<any>('')
  const [size, setSize] = useState('')
  const [groupModalVisible, setGroupModalVisible] = useState(false)
  const [imageModalVisible, setImageModalVisible] = useState(false)

  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    if (Number(conceptCategoryId) > 0) {
      getConceptCategory()
    }
    return () => {
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getConceptCategory = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/concept/category/${conceptCategoryId}`)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          setItem(result)
          if(result.concept_category_places_list.length !== 0){
            setPlaces(result.concept_category_places_list)
          }
          //setImages(result.images)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleItemFileInput = async (e: any) => {
    console.log(e)

    const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            console.log(response)
            console.log(path)
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  // main_vue.completeImage(file, response.data.result)

                  let imageId = result.id
                  let imagePath = result.image_path

                  console.log(result)
                  console.log(imageId)
                  console.log(imagePath)

                  setSize(result.width + 'x' + result.height)

                  const temp = Object.assign({}, item)
                  temp.main_image_path = imagePath
                  setItem(temp)
                } else {
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        // main_vue.failImage(file)
      }
    })
  }

  const handleItemFileInputPlace = async (file: any) => {

    setUploading(true)
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            console.log(response)
            console.log(path)
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  // main_vue.completeImage(file, response.data.result)

                  let imageId = result.id
                  let imagePath = result.image_path

                  console.log(result)
                  console.log(imageId)
                  console.log(imagePath)

                  const temp = Object.assign({}, imageChangePlaces)
                  imageChangePlaces.main_image_path = imagePath
                  setImageChangePlaces(temp)
                  alert('이미지가 변경되었습니다.')
                  setImageModalVisible(false)
                  setUploading(false)
                } else {
                  setUploading(false)
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                setUploading(false)
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            setUploading(false)
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        setUploading(false)
        // main_vue.failImage(file)
      }
    })
  }

  const upsert = () => {
     if(item.main_show === 1 && item.page_show === 0) {
      alert('페이지를 노출 처리 하세요.');
      return;
    }

    if(item.main_image_path === '') {
      alert('이미지를 등록하세요.');
      return;
    }

    console.log(conceptCategoryId)
    console.log(item)

    axios.post(`${process.env.REACT_APP_API2_URL}am/concept/category`, {
          concept_category_id: conceptCategoryId,
          title: item.title,
          order: item.order,
          main_show: item.main_show,
          page_show: item.page_show,
          main_image_path: item.main_image_path,
          concept_category_places_list: places,
        }
      ).then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            alert(error)
          } else {
            alert('등록되었습니다.');
            window.location.href = '/marketing/concept/category/'+result.id+'/editor'
          }
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const removePlace = (place: any) => {
    if (
      window.confirm(`${place.id}. ${place.title} 장소를 삭제하시겠습니까?`)
    ) {
      console.log('delete', place)
      axios.delete(`${process.env.REACT_APP_API2_URL}am/concept/category/place`, {
        data: {
          concept_category_id: conceptCategoryId,
          place_id: place.id,
        }
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          alert(error)
        } else {
          const items = [...places]
          items.splice(places.indexOf(place), 1)
          setPlaces(items)
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }
  }

    return (
      <>
          <PageTitle breadcrumbs={[]}>컨셉 카테고리</PageTitle>
          <div style={{padding: '0px 20px', borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                  <div className="card card-custom card-stretch mb-5">
                      <h2 className="card-title">
                          기본 설정
                      </h2>
                      <div className="separator my-2" />
                      <TextSub2 title={'제목'} subTitle={'최대 9자'} />
                      <input type="text" className="form-control" maxLength={9} value={item.title}
                             onChange={(e) => {
                                 const temp = Object.assign({}, item)
                                 temp.title = e.target.value
                                 setItem(temp)
                             }}
                      />
                      <div className="separator my-5" />
                      <TextSub2 title={'순서'} subTitle={'최대 99'} />
                      <input type="number" className="form-control" max={99} value={item.order}
                             onChange={(e) => {
                                 const temp = Object.assign({}, item)
                                 if(parseInt(e.target.value) > 99) {
                                     temp.order = 99
                                 } else {
                                    temp.order = e.target.value
                                 }
                                 setItem(temp)
                             }}
                      />

                      <div className="separator my-2" />
                      <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                          <div style={{width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center'}}>
                              <label style={{marginRight: 20, fontWeight: 500, fontSize: 15, color: '#1B1D1F'}}>
                                  메인홈 카드 노출
                                  <br /><br />
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="layout-builder[layout][header][fixed][desktop]"
                                    checked={item.main_show === 1}
                                    onChange={(event) => {
                                        const temp = Object.assign({}, item)
                                        if(event.target.checked) {
                                            temp.main_show = 1
                                            temp.page_show = 1
                                        } else {
                                            temp.main_show = 0
                                        }
                                        setItem(temp)
                                    }}
                                  />
                              </label>
                          </div>
                          <div style={{width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center'}}>
                              <label style={{marginRight: 20, fontWeight: 500, fontSize: 15, color: '#1B1D1F'}}>
                                  페이지 노출
                                  <br /><br />
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="layout-builder[layout][header][fixed][desktop]"
                                    checked={item.page_show === 1}
                                    onChange={(event) => {
                                        const temp = Object.assign({}, item)
                                        if(event.target.checked) {
                                            temp.page_show = 1
                                        } else {
                                            temp.page_show = 0
                                        }
                                        setItem(temp)
                                    }}
                                  />
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                  <div className="card card-custom card-stretch mb-5">
                      <h2 className="card-title">
                          메인홈 카드
                      </h2>
                      <div className="separator my-5" />
                      <TextSub2 title={'카드 이미지'} subTitle={'입력값이 없으면 카드 노출되지 않음'} />
                      <input
                        type='text'
                        className='form-control mb-5'
                        placeholder=''
                        value={item.main_image_path}
                        onChange={(e) => {
                            const temp = Object.assign({}, item)
                            temp.main_image_path = e.target.value
                            setItem(temp)
                        }}
                        required={true}
                      />
                      <input type='file' name='file' onChange={(e) => handleItemFileInput(e)} />
                      { item.main_image_path !== undefined && item.main_image_path !== '' &&
                          <div style={{position: 'relative', width: '100%', aspectRatio: `${1160 / 360}`, marginTop: 20}}>
                              <img src={ImagePath.get(item.main_image_path, size)} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                          </div>
                      }
                  </div>
              </div>
          </div>

          <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                <div className="card card-custom card-stretch mb-5">
                  <h2 className="card-title">
                    장소 추가
                  </h2>
                  <div className="separator my-5" />
                  <span className="pr-4">
                     <label
                       className={'btn btn-primary'}
                       onClick={() => {
                         const placeIdsStr = prompt('장소 번호를 입력하세요. ex) 1234, 123, 23456, 235')
                         if (placeIdsStr !== undefined && placeIdsStr !== null && placeIdsStr.trim() !== '') {
                           axios
                             .post(`${process.env.REACT_APP_API2_URL}am/concept/category/place/search`,{
                               conceptCategoryId: conceptCategoryId,
                               placeIdsStr: placeIdsStr
                             })
                             .then(({data: {error, result}}) => {
                               console.log(error, result)
                               if (error !== '') {
                               } else {
                                 if(result.length > 0){
                                   setPlaces([...places, ...result])
                                 }
                               }
                             })
                             .catch((error) => {
                               console.log(error)
                             })
                         }
                       }}
                     >
                      장소들 추가
                     </label>
                  </span>
                  <div style={{marginTop: 30, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {places !== null &&
                      places !== '' &&
                      places.map((place: any, index: number) => {
                        return (
                          <div className="col-lg-3, p-lg-2" style={{width: '272px'}} key={'place_' + index.toString()}>
                            <div className="card card-custom card-stretch shadow mb-5">
                              <div>
                                <img className="rounded" src={ImagePath.get(place.main_image_path, '271.33x180.89')}
                                     style={{height: '100%', width: '100%'}}
                                     alt="" />
                              </div>
                              <div className="p-5">
                                <p style={{fontSize: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                  {place.id}. {place.title}
                                </p>
                                <p style={{fontSize: 15}}>
                                  컨셉 카테고리 노출 순위: {place.concept_category_place_group !== '' ? place.concept_category_place_group : '없음'}
                                </p>
                              </div>
                              <div style={{paddingBottom: 10}}>
                                <label
                                  onClick={() => {
                                    window.open(`${process.env.REACT_APP_WEB_URL}place/${place.id}`)
                                  }}
                                  style={{marginLeft: 10, width: '45%', fontSize: 13}}
                                  className="btn btn-secondary"
                                >
                                  보기
                                </label>
                                <label
                                  onClick={() => {
                                    console.log(groupModalVisible)
                                    setGroupChangePlaces(place)
                                    setTempGroupChangePlaces(place)
                                    setGroupModalVisible(true)
                                  }}
                                  style={{marginLeft: 10, width: '45%', fontSize: 13}}
                                  className="btn btn-secondary"
                                >
                                  노출 순위 수정
                                </label>
                              </div>
                              <div style={{paddingBottom: 10}}>
                                <label
                                  onClick={() => {
                                    setImageChangePlaces(place)
                                    setImageModalVisible(true)
                                  }}
                                  style={{marginLeft: 10, width: '45%', fontSize: 13}}
                                  className="btn btn-primary"
                                >
                                  이미지
                                </label>
                                <label
                                  onClick={() => {
                                    removePlace(place)
                                  }}
                                  style={{marginLeft: 10, width: '45%', fontSize: 13}}
                                  className="btn btn-danger"
                                >
                                  삭제
                                </label>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
          </div>

        <div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 10}}>
            <label onClick={() => {
              upsert()
            }} className="btn btn-primary">
              저장하기
            </label>
          </div>
        </div>

        {groupModalVisible && (
          <Modal
            visible={groupModalVisible}
            closable={true}
            maskClosable={true}
            onClose={() => {
              setGroupModalVisible(false)
            }}
            className={''}
          >
            <p style={{fontSize:17, display: 'flex', flexDirection: 'column', fontWeight: 800, alignItems: 'center'}}>지정할 노출 순위를 입력하세요.</p>
            <div style={{padding: 20, width: '100%'}}>
              {
                <>
                  <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                    <input
                      type='text'
                      className='form-control mb-5'
                      placeholder=''
                      value={groupChangePlaces.concept_category_place_group}
                      onChange={(e) => {
                        const temp = Object.assign({}, groupChangePlaces)
                        temp.concept_category_place_group = e.target.value.toUpperCase();
                        setGroupChangePlaces(temp)
                      }}
                    />
                  </div>
                  <p style={{marginTop: -20, fontWeight: 500}}>
                    공란: 순위 없음, S: 최상위 노출, A: 상위 노출
                  </p>
                </>
              }
            </div>
            <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
              <label onClick={() => {
                console.log(groupChangePlaces.concept_category_place_group)
                if("S" !== groupChangePlaces.concept_category_place_group && "A" !== groupChangePlaces.concept_category_place_group && "" !== groupChangePlaces.concept_category_place_group ) {
                  alert('순위는 S, A만 가능합니다.')
                  groupChangePlaces.concept_category_place_group = "";
                  setGroupChangePlaces(groupChangePlaces)
                  return;
                }

                const temps = [...places]
                temps.splice(temps.indexOf(tempGroupChangePlaces), 1, groupChangePlaces)
                setPlaces(temps)
                setGroupModalVisible(false)
                setTempGroupChangePlaces(groupChangePlaces)
              }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>저장</label>
            </div>
          </Modal>
        )}

        {imageModalVisible && (
          <Modal2
            visible={imageModalVisible}
            closable={true}
            maskClosable={true}
            onClose={() => {
              setImageModalVisible(false)
            }}
            className={''}
          >
            <p style={{fontSize: 17, fontWeight: 800}}>장소 이미지 리스트</p>
            <div
              style={{
                width: '100%',
                height: 'auto',
                margin: 8,
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  console.log(acceptedFiles)
                  handleItemFileInputPlace(acceptedFiles[0])
                }}
              >
                {({getRootProps, getInputProps}) => (
                  <section
                    className='container'
                    style={{
                      width: 400,
                      height: 100,
                      backgroundColor: '#DDD',
                      borderRadius: 4,
                      cursor: 'pointer',
                      padding: 0,
                    }}
                  >
                    <div
                      {...getRootProps({className: 'dropzone'})}
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <input {...getInputProps()} />
                      <p style={{margin: 0}}>이미지를 드래그 하여 넣거나 클릭하세요.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {uploading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                  }}
                >
                  <span className='spinner-border ' />
                </div>
              )}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', maxWidth: '1200px', height: '84%', overflow: 'hidden', overflowY: 'auto'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
                {imageChangePlaces !== null &&
                  imageChangePlaces !== '' && imageChangePlaces.place_image_path_list !== null &&
                  imageChangePlaces.place_image_path_list.map((path: any, index: number) => {
                    return (
                      <div style={{position: 'relative', margin: 10, width: 220, height: 220}} key={index}>
                        <img src={ImagePath.get(path, "220x220")} alt='' style={{width: 220, height: 220, objectFit: 'cover'}} />
                        <Button size='sm' style={{position: 'absolute', right: 4, bottom: 4}} variant='primary'
                                onClick={() => {
                                  const temp = Object.assign({}, imageChangePlaces)
                                  imageChangePlaces.main_image_path = path
                                  setImageChangePlaces(temp)
                                  setImageModalVisible(false)
                                }}
                        >
                          메인 이미지로 변경
                        </Button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Modal2>
          )}
      </>
    )
}
