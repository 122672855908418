import React, {useEffect, useState} from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../../components/TextSub'
import Pagination from 'react-js-pagination'
import {toast} from 'react-toastify'
import ImagePath from '../../../../commons/ImagePath'
import Modal from '../../../components/Modal'

export function AdvertiseCategoryPlaceIndex() {
  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [places, setPlaces] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [advTotalCount, setAdvTotalCount] = useState(0)
  const [advWCount, setAdvWCount] = useState(0)
  const [advACount, setAdvACount] = useState(0)
  const [advICount, setAdvICount] = useState(0)
  const [advECount, setAdvECount] = useState(0)
  const [advRCount, setAdvRCount] = useState(0)
  const [advCCount, setAdvCCount] = useState(0)

  const [schedule, setSchedule] = useState<any>([])
  const [selectSchedule, setSelectSchedule] = useState<any>([])
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    getStartSchedule()
    getCategoryPlaces()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getCategoryPlaces(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getCategoryPlaces('', 1)
  }

  const handlePageChange = (page: number) => {
    getCategoryPlaces(status, page)
  }

  const getStartSchedule = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/category/ate/schedule`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setSchedule(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCategoryPlaces = (status = '', page = 1) => {
    setPlaces([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/category/place?page=${page}&status=${status}&search=${search}`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          setPlaces(result.advertise.data)
          setCurrentPage(result.advertise.current_page)
          setPerPage(result.advertise.per_page)
          setTotal(result.advertise.total)
          window.scrollTo(0, 0)
          console.log(places)
          // this.forceUpdate()
          getAdvCount()
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getAdvCount = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/category/place/count`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          setAdvTotalCount(result.advTotalCount)
          setAdvWCount(result.advWCount)
          setAdvACount(result.advACount)
          setAdvICount(result.advICount)
          setAdvECount(result.advECount)
          setAdvRCount(result.advRCount)
          setAdvCCount(result.advCCount)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const approved = (place: any) => {
    let id = place.advPlaceAdvertiseGroupId
    if (id === 0) {
      alert('그룹 아이디 값을 확인해주세요.')
      return
    }
    let str = '관리자 승인 처리하시겠습니까?'
    if (window.confirm(str)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/category/group/${id}/place/approved`, {advStatus: 'A'})
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            //getCategoryPlaces();
            console.log(result)
            const temps = [...places]
            temps.splice(temps.indexOf(place), 1, place)
            place.advStatus = 'A'
            place.advStatusDisplay = '관리자 승인 완료'
            place.advApprovedTime = result.advApprovedTime
            setPlaces(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const reject = (place: any) => {
    let id = place.advPlaceAdvertiseGroupId
    if (id === 0) {
      alert('그룹 아이디 값을 확인해주세요.')
      return
    }
    let str = '관리자 거절 처리하시겠습니까?'
    if (window.confirm(str)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/category/group/${id}/place/approved`, {advStatus: 'R'})
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            //getCategoryPlaces();
            console.log(result)
            const temps = [...places]
            temps.splice(temps.indexOf(place), 1, place)
            place.advStatus = 'R'
            place.advStatusDisplay = '승인 거절'
            setPlaces(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>카테고리 상단 광고</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label
          className={'btn btn-primary'}
          onClick={() => {
            setModalVisible(true)
          }}
        >
          무료 광고 등록하기
        </label>

        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <select
            className='form-control'
            name='type'
            value={status}
            onChange={(e) => {
              onTab(e.target.value)
            }}
          >
            <option value={''}>모두보기 ({advTotalCount})</option>
            <option value={'W'}>관리자 승인 대기중 ({advWCount})</option>
            <option value={'A'}>관리자 승인 완료 ({advACount})</option>
            <option value={'I'}>광고중 ({advICount})</option>
            <option value={'E'}>광고종료 ({advECount})</option>
            <option value={'R'}>승인거절 ({advRCount})</option>
            <option value={'C'}>광고취소 ({advCCount})</option>
          </select>
          <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='광고 ID, 호스트 ID, 호스트 닉네임, 호스트 이름, 이메일, 휴대폰 번호, 장소 번호, 장소명'
              value={search}
              style={{flex: 1}}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
            />
            <label
              onClick={() => {
                onSearch()
              }}
              style={{width: 100}}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>
        </div>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {places.map((place: any, index: number) => {
              return (
                <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={index.toString()}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <h2>
                      {place.advPlaceAdvertiseGroupId}. [{place.advStatusDisplay}]
                    </h2>
                  </div>

                  <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                    <div style={{width: 100, height: 100, position: 'relative'}}>
                      <img src={ImagePath.get(place.placeMainImagePath, '100x100')} alt='' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                      <div
                        style={{
                          position: 'absolute',
                          width: 30,
                          height: 30,
                          borderTopLeftRadius: 8,
                          bottom: 0,
                          right: 0,
                          backgroundColor: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h4 style={{margin: 0}}>{place.placeGrade}</h4>
                      </div>
                    </div>

                    <div style={{marginLeft: 10}}>
                      <LinesEllipsis text={`${place.placeId}. ${place.placeTitle}`} maxLine='2' ellipsis='...' trimRight basedOn='letters' style={{fontSize: 20, fontWeight: 'bold'}} />

                      <TextSub title={'장소 금액'} subTitle={`${place.placePriceGuestVat.toLocaleString()}`} marginTop={0} />
                      <TextSub title={'촬영 건수'} subTitle={`${place.placeShootCount.toLocaleString()} (${place.placeBookingCount.toLocaleString()})`} marginTop={-10} />
                      <TextSub title={'카테고리'} subTitle={`${place.placeCategoryMain} > ${place.placeCategorySub}`} marginTop={-10} />
                      <TextSub title={'관리자 장소 메모'} subTitle={place.placeAdminComment} marginTop={-10} />

                      <label
                        onClick={() => {
                          window.open(`/place/${place.placeId}`, '_blank')
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        장소 보기
                      </label>
                    </div>
                  </div>

                  <div style={{paddingTop: '15px'}}>
                    <table style={{fontSize: '15px', width: '100%'}}>
                      <colgroup>
                        <col style={{width: '10%'}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>썸네일 이미지</td>
                          <td>
                            <img src={ImagePath.get(place.advPlaceMainImagePath, '100x100')} alt='' style={{width: '100px', height: '100px', objectFit: 'cover', borderRadius: 10}} />
                          </td>
                        </tr>
                        <tr>
                          <td>썸네일 변경일</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>광고 일정</td>
                          <td>
                            {place.schedules.map((sc: any, index: number) => {
                              return (
                                <>
                                  <span key={index.toString()}>{sc.schedule_display}</span>
                                  <br />
                                </>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>결제 금액</td>
                          <td>{place.advPrice.toLocaleString()} (부가세 포함)</td>
                        </tr>
                        <tr>
                          <td>결제일 </td>
                          <td>{place.advPaidTime}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>취소 일정</td>
                          <td>
                            {place.cancelSchedules.map((sc: any, index: number) => {
                              return (
                                <>
                                  <span key={index.toString()}>{sc.schedule_display}</span>
                                  <br />
                                </>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>취소 금액</td>
                          <td>{place.advCancelPriceDisplay}</td>
                        </tr>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>취소일</td>
                          <td>
                            {place.advCancelledTime.map((ct: any, index: number) => {
                              return (
                                <>
                                  <span key={index.toString()}>{ct.adv_cancelled_time}</span>
                                  <br />
                                </>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>승인 마감일</td>
                          <td>{place.advAdminApprovedDeadlineDate}</td>
                        </tr>
                        <tr>
                          <td>승인 시간</td>
                          <td>{place.advApprovedTime}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{paddingTop: 20, display: 'flex'}}>
                    <label
                      onClick={() => {
                        window.open(`/advertise/category/${place.advPlaceAdvertiseGroupId}/place`)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      자세히 보기
                    </label>
                    {place.advStatus === 'W' && (
                      <>
                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            // putApproved('A', place.advPlaceAdvertiseGroupId);
                            approved(place)
                          }}
                          className='btn btn-sm btn-primary'
                        >
                          관리자 승인
                        </label>

                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            // putApproved('R', place.advPlaceAdvertiseGroupId);
                            reject(place)
                          }}
                          className='btn btn-sm btn-danger'
                        >
                          관리자 거절
                        </label>
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {places.length > 0 && (
          <div style={{marginTop: 20}}>
            <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
          </div>
        )}
      </div>

      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div style={{padding: 20, width: '100%'}}>
            <select
              className='form-select form-select-solid'
              name='type'
              value={selectSchedule}
              onChange={(e) => {
                setSelectSchedule(e.target.value)
              }}
            >
              <option key={''} value={''}>
                무료로 등록할 광고 일정을 선택해주세요.
              </option>
              {schedule.map((item: any, index: number) => {
                return (
                  <option key={index} value={index}>
                    {item.week_status_display}. {item.schedule_display}
                  </option>
                )
              })}
            </select>
          </div>
          <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
            <label
              onClick={() => {
                let advStart = `${schedule[selectSchedule].adv_start}`
                let advEnd = `${schedule[selectSchedule].adv_end}`
                if (advStart !== '' && advEnd !== '') {
                  window.location.href = `/advertise/category/${advStart}/${advEnd}/place/add`
                }
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-primary'
            >
              확인
            </label>
          </div>
        </Modal>
      )}
    </>
  )
}
