import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from "axios";
import Pagination from "react-js-pagination";
import {TextSub} from '../../components/TextSub'
import classNames from 'classnames'
// @ts-ignore
import WindowOpener from 'react-window-opener'
import {toast} from 'react-toastify'

export function PayPointWithdraw() {

  const [searchValues, setSearchValues] = useState({
    status: '',
    currentPage: 0,
    refresh: 0,
    search: '',
  })

  const [status, setStatus] = useState('')

  const [loading, setLoading] = useState(false)
  const [points, setPoints] = useState<any>([])
  const [pay0, setPay0] = useState<any>(0)
  const [pay1, setPay1] = useState<any>(0)
  const [pay99, setPay99] = useState<any>(0)

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)


  useEffect(() => {
    getPoint();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getPoint(tabStatus, 1)
  }

  const handlePageChange = (page: number) => {
    getPoint(status, page)
  }

  const getPoint = (status = '', page = 1) => {

    setPoints([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    axios.get(`${process.env.REACT_APP_API2_URL}am/pay/point/withdraw?page=${page}&status=${status}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      console.log(result.points.data)
      if (error !== '') {
      } else {
        setPoints(result.points.data)
        setPay0(result.pay0)
        setPay1(result.pay1)
        setPay99(result.pay99)

        setCurrentPage(result.points.current_page)
        setPerPage(result.points.per_page)
        setTotal(result.points.total)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const delay = (pointId = 0) => {

    console.log("pointId : " + pointId)
    if (window.confirm('추후 정산 처리하시겠습니까?')) {
      axios.post(`${process.env.REACT_APP_API2_URL}am/pay/point/withdraw/${pointId}/delay`).then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      }).catch((error) => {
        console.log(error)
        setLoading(false)
      })
    }
  }

  const paid = (payId = 0) => {
    console.log("payId : " + payId)
    if (window.confirm('지불 처리하시겠습니까?')) {
      axios.put(`${process.env.REACT_APP_API2_URL}am/pay/${payId}/point/withdraw/paid`).then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      }).catch((error) => {
        console.log(error)
        setLoading(false)
      })
    }
  }

  const childResponse = (err: any, res: any) => {
    if (err) {
      console.log(err, 'err')
      // setstate(res)
    } else {
      console.log(res)
      window.location.reload()
      setSearchValues({
        ...searchValues,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>포인트</PageTitle>
    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <label onClick={() => {
            onTab('')
          }} className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}>모두보기</label>

          <label onClick={() => {
            onTab('0')
          }} className={classNames('btn', {'btn-dark': status === '0'}, {'btn-btn-white': status !== '0'})}>지불 정보 입력
            ({pay0.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('1')
          }} className={classNames('btn', {'btn-dark': status === '1'}, {'btn-btn-white': status !== '1'})}>지불 대기
            ({pay1.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('2')
          }} className={classNames('btn', {'btn-dark': status === '2'}, {'btn-btn-white': status !== '2'})}>지불 완료</label>

          <label onClick={() => {
            onTab('99')
          }} className={classNames('btn', {'btn-dark': status === '99'}, {'btn-btn-white': status !== '99'})}>추후 정산
            ({pay99.toLocaleString('en')})</label>
        </div>
      </div>
      {
        loading ?
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className="indicator-progress" style={{display: 'block'}}>Loading...<span
              className="spinner-border spinner-border-sm align-middle ms-2" /></span>
          </div>
          :
          <div style={{width: '100%'}}>
            {points !== null &&
              points.map((point: any, index: number) => {
                return (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}
                       key={index.toString()}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <h2>
                        {point.id}. [{point.created_at}]
                      </h2>
                    </div>
                    <div style={{marginTop: 5, borderBottom: '1px solid #EEE'}} />
                    <div style={{marginTop: 10, marginLeft: 10}}>
                      <TextSub title={'사용자 이름'} subTitle={`${point.name}`} marginTop={0} />
                      <TextSub title={'사용자 번호'} subTitle={`${point.user_id}`} marginTop={0}
                               popUrl={`/user/${point.user_id}`} />
                      <TextSub title={'출금요청포인트'} subTitle={`${Math.abs(point.point).toLocaleString("en")} 원`} marginTop={0} />
                    </div>
                    {point.account !== undefined &&
                      <>
                        <div style={{marginTop: 5, borderBottom: '1px solid #EEE'}} />
                        <h4 style={{marginTop: 10, marginLeft: 10}}>입금정보</h4>
                        <div style={{marginTop: 10, marginLeft: 10, color: 'red'}}>
                          <TextSub title={'예금주'} subTitle={`${point.account.bank_owner}`} marginTop={0}
                                   color={`${point.account.bank_owner !== point.name ? 'red' : ''}`} />
                          <TextSub title={'은행명'} subTitle={`${point.account.bank_name}`} marginTop={0}
                                   color={`${point.account.bank_owner !== point.name ? 'red' : ''}`} />
                          <TextSub title={'계좌번호'} subTitle={`${point.account.bank_account}`} marginTop={0}
                                   color={`${point.account.bank_owner !== point.name ? 'red' : ''}`} />
                        </div>
                      </>
                    }
                    <div style={{marginTop: 5, borderBottom: '1px solid #EEE'}} />
                    <h4 style={{marginTop: 10, marginLeft: 10}}>지불정보</h4>
                    { point.payPayId > 0 &&
                      <>
                        <div style={{marginTop: 10, marginLeft: 10, color: 'red'}}>
                          <TextSub title={'지급액'} subTitle={`${point.payPay}`} marginTop={0} />
                          <TextSub title={'지급일'} subTitle={`${point.payPayDay}`} marginTop={0} />
                          <TextSub title={'예금주'} subTitle={`${point.payBankOwner}`} marginTop={0} />
                          <TextSub title={'주민번호'} subTitle={`${point.payJumin !== '' ? 'O' : 'X'}`} marginTop={0} />
                          <TextSub title={'은행명'} subTitle={`${point.payBankName}`} marginTop={0} />
                          <TextSub title={'계좌번호'} subTitle={`${point.payBankAccount}`} marginTop={0} />
                        </div>
                        {point.payStatus === 99 &&
                          <div style={{ padding: 10 }} >
                            <WindowOpener url={`/pay/point/withdraw/${point.payPayId}/edit`} width={900} height={1200} bridge={childResponse}>
                              <div className="btn btn-secondary">수정</div>
                            </WindowOpener>
                          </div>

                        }
                      </>
                    }
                    {point.payPayId === 0 &&
                      <>
                        <h1 style={{marginTop: 10, marginLeft: 10, color: 'red'}}>지불정보없음</h1>
                        <div style={{paddingTop: 20, display: 'flex'}}>
                          <WindowOpener url={`/pay/point/withdraw/${point.id}/create`} width={900} height={1200} bridge={childResponse}>
                            <div className="btn btn-secondary">입력</div>
                          </WindowOpener>
                        </div>
                      </>
                    }
                    <div style={{paddingTop: 20, display: 'flex'}}>
                      { point.payStatus === 1 &&
                        <>
                          <WindowOpener url={`/pay/point/withdraw/${point.payPayId}/edit`} width={900} height={1200} bridge={childResponse}>
                            <div className="btn btn-secondary">수정하기</div>
                          </WindowOpener>
                          <label onClick={() => {
                            paid(point.payPayId)
                          }} className="btn btn-sm btn-outline-primary"
                                 style={{marginLeft: 10, marginRight: 10, fontSize: 15, border: '0.1px solid #009ef7'}}>지불하기</label>
                        </>
                      }
                      {point.payStatus !== 2 && point.payStatus !== 99 &&
                        <label onClick={() => {
                          delay(point.id)
                          }} className="btn btn-sm btn-outline-danger" style={{fontSize: 15, border: '0.1px solid red'}}>추후정산</label>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
      }

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
  </>
}

