import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import classNames from "classnames";
import axios from "axios";
import {InfoBox} from "../components/InfoBox";
import Highlighter from "react-highlight-words";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";

export function GuestPortfolioGrey() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('');

  const [status, setStatus] = useState('');

  const [guestPortfolioGreyKeywords, setGuestPortfolioGreyKeywords] = useState<any>(null)

  const [countGuestFeedGrey, setCountGuestFeedGrey] = useState(0);
  const [countGuestFeedGreyDefault, setCountGuestFeedGreyDefault] = useState(0);

  const [countGuestPortfolioGreyGrey, setCountGuestPortfolioGreyGrey] = useState(0);
  const [countGuestPortfolioGreyGreyDefault, setCountGuestPortfolioGreyGreyDefault] = useState(0);

  useEffect(() => {
    getGuestFeedGrey()
    return () => {
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getGuestFeedGrey(status, page)
  }

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getGuestFeedGrey(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getGuestFeedGrey('', 1, search)
  }

  const getGuestFeedGrey = (status = '', page = 1, search = '') => {
    setLoading(true)
    axios
        .get(`${process.env.REACT_APP_API2_URL}am/guest/feed/grey?keyword=${search}&grey_type=${status}&page=${page}`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            const greys = result.data.greys
            setItems(greys)

            setGuestPortfolioGreyKeywords(result.data.guest_portfolio_grey_keywords)

            setCountGuestFeedGrey(result.data.count_guest_feed_grey);
            setCountGuestFeedGreyDefault(result.data.count_guest_feed_grey);

            setCountGuestPortfolioGreyGrey(result.data.count_guest_feed_grey);
            setCountGuestPortfolioGreyGreyDefault(result.data.count_guest_feed_grey);

            setCurrentPage(result.current_page)
            setPerPage(result.per_page)
            setTotal(result.total)
            window.scrollTo(0, 0)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
  }

  const skip = (item: any) => {
    console.log({grey_id: item.grey_id})
    axios
        .put(`${process.env.REACT_APP_API2_URL}am/feed/grey/${item.grey_id}/skip`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temps = [...items]
            temps.splice(temps.indexOf(item), 1, item)
            item.grey_status = 0
            setItems(temps)
          }
        }).catch((error) => {
      console.log(error)
    })
  }

  const hide = (item: any) => {
    console.log({grey_id: item.grey_id})
    axios
        .put(`${process.env.REACT_APP_API2_URL}am/feed/grey/${item.grey_id}/hide`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temps = [...items]
            temps.splice(temps.indexOf(item), 1, item)
            item.grey_status = 1
            setItems(temps)
          }
        }).catch((error) => {
      console.log(error)
    })
  }

  const show = (item: any) => {
    console.log({grey_id: item.grey_id})
    axios
        .put(`${process.env.REACT_APP_API2_URL}am/feed/grey/${item.grey_id}/show`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temps = [...items]
            temps.splice(temps.indexOf(item), 1, item)
            item.grey_status = -1
            setItems(temps)
          }
        }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>게스트 피드_그레이</PageTitle>

    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

      <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        <label onClick={() => {
          onTab('')
        }} className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}>모두보기 ({countGuestFeedGrey.toLocaleString('en')} <span style={{color: "red"}}>/ {countGuestFeedGreyDefault.toLocaleString('en')}</span>)</label>

        <label onClick={() => {
          onTab('guest_portfolio_feed')
        }} className={classNames('btn', {'btn-dark': status === 'guest_portfolio_feed'}, {'btn-btn-white': status !== 'guest_portfolio_feed'})}>포트폴리오 ({countGuestPortfolioGreyGrey.toLocaleString('en')} <span style={{color: "red"}}>/ {countGuestPortfolioGreyGreyDefault.toLocaleString('en')}</span>)</label>

      </div>

      <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <input type='text' className='form-control' placeholder='게스트 이름, 게스트 닉네임, 포트폴리오 번호, 포트폴리오 제목' value={search} style={{flex: 1}}
               onChange={(e) => {
                 setSearch(e.target.value)
               }}
               onKeyPress={(e) => {
                 if (e.key === 'Enter') {
                   onSearch()
                 }
               }}
        />
        <label onClick={() => {
          onSearch()
        }} style={{width: 100}} className='btn btn-dark'>검색</label>
      </div>
      {
        loading ?
            <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
              {
                items.map((item: any) => {
                  return <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={item.grey_id.toString()}>

                    <h1>
                      {item.grey_type === 'guest_portfolio_feed' ? '게스트 피드_포트폴리오' : ''}
                    </h1>

                    <InfoBox item={item} />
                    {
                        item.grey_type === 'guest_portfolio_feed' &&
                        <div>
                          <div className='separator my-10' />

                          <h2>제목</h2>
                          <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                            <Highlighter searchWords={guestPortfolioGreyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.title ?? ''}/>
                          </p>

                          <div className='separator my-10' />

                          <h2>본문</h2>
                          <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                            <Highlighter searchWords={guestPortfolioGreyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.content ?? ''}/>
                          </p>

                          <div className='separator my-10' />

                          <h2>태그</h2>
                          <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                            <Highlighter searchWords={guestPortfolioGreyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.tag ?? ''}/>
                          </p>
                        </div>
                    }

                    {
                        item.grey_status < 0 &&
                        <div>
                          <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div className={'row'}>
                              <div style={{padding: 4, width: 'fit-content'}}>
                                <label style={{border: '1px solid #FF4B3E', backgroundColor: '#FF4B3E'}} onClick={() => {
                                  hide(item)
                                }} className='btn btn-outline-light'>게시물 숨김</label>
                              </div>
                              <div style={{padding: 4, width: 'fit-content'}}>
                                <label style={{border: '1px solid #6AD559'}} onClick={() => {
                                  skip(item)
                                }} className='btn btn-outline-success'>건너뛰기</label>
                              </div>
                            </div>
                          </div>
                        </div>
                    }

                    {
                        item.grey_status === 0 &&
                        <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <label style={{border: '1px solid #007BFF', borderRadius: 8, padding: '8px 16px'}} className='btn-outline-primary'>
                            {item.grey_status === 0 ? '건너뛰기 처리 완료' : ''}
                          </label>
                        </div>
                    }

                    {
                        item.grey_status > 0 &&
                        <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <div className={'row'}>
                            <div style={{padding: 4, width: 'fit-content'}}>
                              <label style={{border: '1px solid #3C82FA', backgroundColor: '#3C82FA'}} onClick={() => {
                                show(item)
                              }} className='btn btn-outline-light'>게시물 노출</label>
                            </div>
                          </div>
                        </div>
                    }

                  </div>
                })
              }

              <div style={{padding: 20}}>
                <Pagination activePage={currentPage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            prevPageText={'‹'}
                            nextPageText={'›'}
                            onChange={handlePageChange} />
              </div>

            </div>
      }

    </div>
  </>
}

