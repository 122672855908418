import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

export function AdvertiseNetworkPlusPlaceAdd() {

  const {id} = useParams<{id: string}>()
  const [schedule, setSchedule] = useState<any>(null)
  const [hostId, setHostId] = useState('')
  const [placeId, setPlaceId] = useState('')
  const [week, setWeek] = useState(1)
  const [weekEtc, setWeekEtc] = useState('')
  const [priceChecked, setPriceChecked] = useState(0)
  const [price, setPrice] = useState(0)
  const [priceEtc, setPriceEtc] = useState('')

  useEffect(() => {
    getSchedule();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSchedule = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/${id}/schedule`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setSchedule(result)
      }
    }).catch((error) => {
      console.log(error)
    })

  }

  const add = (advPlaceStatus = '') => {
    if(hostId === '' || Number(hostId) < 1 ){
      toast.error('호스트 ID를 확인해 주세요.');
      return;
    }

    if(placeId === '' || Number(placeId) < 1 ){
      toast.error('장소 ID를 확인해 주세요.');
      return;
    }

    if(week === 5 && (weekEtc === '' || Number(weekEtc) > 100) ){
      toast.error('광고 게쟤 기간 주 수를 확인해주세요.');
      return;
    }

    if(priceChecked === 2 && (priceEtc === '' || Number(priceEtc) > 999999999) ){
      toast.error('광고비 단가를 확인해주세요.');
      return;
    }

    console.log("week1" + week)
    let inputWeek = week;
    let inputPrice = price;
    if(week === 5){
      //console.log("week2" + week)
      //setWeek(Number(weekEtc));
      inputWeek = Number(weekEtc);
    }

    if(priceChecked === 2){
      //setPrice(Number(priceEtc));
      inputPrice = Number(priceEtc);
    }

    console.log(id)
    console.log(hostId)
    console.log(placeId)
    console.log("week : " + inputWeek)
    console.log("price" + inputPrice)
    console.log(schedule.id);

    let str = '등록 하시겠습니까?';

    if('R' === advPlaceStatus){
      str = '승인 거절 하시겠습니까?';
    }


    if (window.confirm(str)) {
      axios.post(`${process.env.REACT_APP_API2_URL}am/adv/network/`+id+`/place/add`, {
        hostId,
        placeId,
        week: inputWeek,
        price: inputPrice,
        advPlaceStatus,
        scheduleId: schedule.id,
        advType: 'NPLUS'
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/advertise/network/plus/place`
        }
      }).catch((error) => {
        console.log(error)
      })
    }

  }

  return <>
    <PageTitle breadcrumbs={[]}>네크워크 광고 PLUS</PageTitle>
    <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
      {
          schedule !== null &&
          <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
              <div style={{marginTop: 10, paddingBottom: 10,}}>
                <div>
                  <table style={{fontSize: '15px', width: '100%', lineHeight: 2}}>
                    <colgroup>
                      <col style={{ width: '14%' }}/>
                      <col style={{ width: 'auto' }}/>
                    </colgroup>
                    <tbody>
                      <tr style={{fontSize: '15px'}}>
                        <td>광고 일정 ID</td>
                        <td>{schedule.id}</td>
                      </tr>
                      <tr style={{fontSize: '15px'}}>
                        <td>호스트 번호</td>
                        <td>
                          <input type='text' className='form-control' placeholder='호스트 ID를 입력하세요.' maxLength={15} value={hostId}
                                 onChange={(e) => {setHostId(e.target.value)}}
                          />
                        </td>
                      </tr>
                      <tr style={{fontSize: '15px'}}>
                        <td>장소 번호</td>
                        <td>
                          <input type='text' className='form-control' placeholder='장소 ID를 입력하세요.' maxLength={15} value={placeId}
                                 onChange={(e) => {
                                   setPlaceId(e.target.value)
                                 }}
                          />
                        </td>
                      </tr>
                      <tr style={{fontSize: '15px'}}>
                        <td style={{verticalAlign: 'top'}}>광고 게재 기간</td>
                        <td>
                          <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                            <input className='form-check-input' type='radio' value='2' name='week' id='weekRadio2'
                                   onChange={(e) => {
                                     if(e.target.checked){
                                       setWeek(2);
                                       setWeekEtc('');
                                     }
                                   }}
                            />
                            <label className='form-check-label' htmlFor='weekRadio2'>
                              {schedule.advertiseWeekStartDate2Display}  (2주)
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                            <input className='form-check-input' type='radio' value='4' name='week' id='weekRadio4'
                                   onChange={(e) => {
                                     if(e.target.checked){
                                       setWeek(4);
                                       setWeekEtc('');
                                     }
                                   }}
                            />
                            <label className='form-check-label' htmlFor='weekRadio4'>
                              {schedule.advertiseWeekStartDate4Display}  (4주)
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                            <input className='form-check-input' type='radio' value='4' name='week' id='weekRadio5'
                                   onChange={(e) => {
                                     if(e.target.checked){
                                       setWeek(5);
                                       setWeekEtc('');
                                     }
                                   }}
                            />
                            <label className='form-check-label' htmlFor='weekRadio5'>
                              <span className='form-check-label fw-bold text-muted' style={{marginRight: '10px'}}>직접 입력</span>
                            </label>
                            <input type='text' className='form-control' placeholder='광고 시작일로부터 광고를 게재할 총 주 수를 입력하세요. ex) 10' maxLength={5} style={{width: '90%'}}  value={weekEtc}
                                   onChange={(e) => {
                                     if(week === 5){
                                       setWeekEtc(e.target.value)
                                     }
                                   }}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr style={{fontSize: '15px'}}>
                        <td style={{verticalAlign: 'top'}}>광고비 단가<br/>(1주/부가세 제외)</td>
                        <td>
                          <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                            <input className='form-check-input' type='radio' value='150000' name='price' id='priceRadio1'
                                   onChange={(e) => {
                                     if(e.target.checked){
                                       setPriceChecked(1)
                                       setPrice(150000)
                                       setPriceEtc('')
                                     }
                                   }}
                            />
                            <label className='form-check-label' htmlFor='priceRadio1'>
                              150,000
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                            <input className='form-check-input' type='radio' value={price} name='price' id='priceRadio2'
                                   onChange={(e) => {
                                    if(e.target.checked){
                                      setPriceChecked(2)
                                      setPriceEtc('')
                                      setPrice(0)
                                    }
                                  }}
                            />
                            <label className='form-check-label' htmlFor='priceRadio2'>
                              <span className='form-check-label fw-bold text-muted' style={{marginRight: '10px'}}>직접 입력</span>
                            </label>
                            <input type='text' className='form-control' placeholder='ex) 200000' maxLength={10} style={{width: '90%'}}  value={priceEtc}
                                   onChange={(e) => {
                                     if(priceChecked === 2){
                                       setPriceEtc(e.target.value)
                                     }
                                   }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
                <label onClick={() => {
                  setWeek(Number(weekEtc));
                  add('PW');
                }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>등록하기</label>
                <label onClick={() => {
                  add('R');
                }} style={{marginLeft: 10}} className='btn btn-sm btn-danger'>관리자 승인 거절</label>
              </div>
            </div>
          </div>
      }
    </div>
  </>
}

