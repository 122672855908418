import React from 'react'
import {Button} from 'react-bootstrap-v5'

export default class MainCategoryPlacesCard extends React.Component {
    render() {
        return (
            <div
                key={this.props.place.id}
                style={{
                    margin: 7.9,
                    border: '1px solid rgb(220,220,220)',
                    borderRadius: 10,
                    width: 284,
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        borderRadius: '8px 8px 0 0',
                        overflow: 'hidden',
                        width: 284,
                    }}
                >
                    <img src={this.props.place.image_url} alt='' style={{width: '100%', height: 220, objectFit: 'cover'}} />
                </div>

                <div style={{padding: 10}}>
                    <p style={{fontSize: 16, width: 200}}>
                        {this.props.place.id}. {this.props.place.title}
                    </p>
                </div>

                <div
                    style={{
                        padding: 9,
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                    }}
                >
                    <Button variant='light' style={{marginRight: 8}} onClick={() => window.open(`${process.env.REACT_APP_WEB_URL}place/${this.props.place.id}`)}>
                        보기
                    </Button>
                    {this.props.showImages && (
                        <Button variant='primary' style={{marginRight: 8}} onClick={() => this.props.showImages()}>
                            이미지 보기
                        </Button>
                    )}
                    {this.props.category && this.props.removePlace && (
                        <Button variant='danger' style={{marginRight: 8}} onClick={() => this.props.removePlace(this.props.category, this.props.place)}>
                            삭제
                        </Button>
                    )}
                    {this.props.category === undefined && this.props.removePlace && (
                        <Button variant='danger' style={{marginRight: 8}} onClick={() => this.props.removePlace(this.props.place)}>
                            삭제
                        </Button>
                    )}
                </div>
            </div>
        )
    }
}
