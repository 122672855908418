import React from 'react'
import { Button, Carousel, Form } from 'react-bootstrap'
import axios from 'axios'

export default class MagazineImages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      info: props.info,
      selectedFile: null,
    }
  }

  handleFileInput(e) {
    this.setState({
      selectedFile: e.target.files[0],
    })
  }

  handlePost() {
    const formData = new FormData()
    formData.append('file', this.state.selectedFile)

    return axios
      .post(`${process.env.REACT_APP_API_URL}image/magazine`, formData)
      .then(({ data: { error, result } }) => {
        console.log(error, result)
        if (error === '') {
          this.state.info.images.push({
            image_id: result.image_id,
            image_url: result.image_url,
          })
          this.forceUpdate()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onDelete(image) {
    if (window.confirm('삭제하시겠습니다?')) {
      this.state.info.images.splice(this.state.info.images.indexOf(image), 1)
      this.forceUpdate()
    }
  }

  render() {
    const { info } = this.state
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ ...info.wrapStyle }}>
          <Carousel
            // activeIndex={index}
            // direction={direction}
            // onSelect={this.handleSelect}
            style={{ height: '100%', overflow: 'hidden' }}>
            {info.images.map((image) => {
              return (
                <Carousel.Item
                  style={{ width: '100%', height: info.wrapStyle.height }}>
                  <div style={{ width: '100%', height: '100%' }}>
                    <img
                      className="d-block"
                      src={image.image_url}
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '100%',
                        margin: '0 auto',
                      }}
                      alt="First slide"
                    />
                  </div>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
        <div>
          <Form.Control
            type="text"
            placeholder="출처를 입력하세요"
            // aria-describedby='inputGroupPrepend'
            value={info.source}
            onChange={(e) => {
              info.source = e.target.value
              this.forceUpdate()
            }}
          />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 10,
          }}>
          {info.images.map((image) => {
            return (
              <div style={{ width: 99, height: 132, marginRight: 10 }}>
                <img
                  className="d-block w-100"
                  src={image.image_url}
                  style={{ objectFit: 'cover', width: 99, height: 99 }}
                  alt="First slide"
                />
                <div
                  style={{
                    width: 100,
                    height: 33,
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p style={{ margin: 0 }}>{'<'}</p>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p style={{ margin: 0 }}>{'>'}</p>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'red',
                    }}
                    onClick={() => this.onDelete(image)}>
                    <p style={{ color: 'white', margin: 0 }}>X</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div style={{ marginTop: 10 }}>
          <input
            type="file"
            name="file"
            onChange={(e) => this.handleFileInput(e)}
          />
          <Button type="button" onClick={() => this.handlePost()}>
            업로드
          </Button>
        </div>

        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 160,
            top: -50,
          }}>
          <Button
            variant="success"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onUp(this.props.info)}>
            ▲
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 112,
            top: -50,
          }}>
          <Button
            variant="success"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onDown(this.props.info)}>
            ▼
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 64,
            top: -50,
          }}>
          <Button
            variant="danger"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onDelete(this.props.info)}>
            X
          </Button>
        </div>
      </div>
    )
  }
}
