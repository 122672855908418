import React, {useEffect, useState} from 'react'
// @ts-ignore
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../../components/TextSub'
import {useParams} from 'react-router-dom'
import moment from 'moment/moment'
import Modal from '../../../components/Modal'
import {toast} from 'react-toastify'
import classNames from 'classnames'
import SwitchButton from '../../../../components/SwitchButton'

export function AdvertiseMainBannerDetail() {
  const {id} = useParams<{id: string}>()

  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [selectCancelSchedule, setSelectCancelSchedule] = useState(0)

  const [mainBannerAdv, setMainBannerAdv] = useState<any>('')
  const [log, setLog] = useState(0)

  useEffect(() => {
    getNetworkPlace()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getNetworkPlace = () => {
    setMainBannerAdv('')
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/MB/main/banner/group/${id}`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          console.log(result.advPrice)
          setMainBannerAdv(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const putAdvPaid = () => {
    if (window.confirm('광고 결제 완료처리를 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/main/banner/group/${id}/status/update`, {advType: 'MB', advStatus: 'PF'})
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, mainBannerAdv)
            temp.advStatus = 'PF'
            temp.advPaidTime = result.advPaidTime
            temp.advStatusDisplay = '결제 완료'
            setMainBannerAdv(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const refundAdv = () => {
    console.log(mainBannerAdv.advMainBannerAdvertiseGroupId)
    if (mainBannerAdv.advMainBannerAdvertiseGroupId <= 0) {
      toast.error('환불 시 필수 값 체크!')
      return false
    }
    if (window.confirm('환불 처리를 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/${mainBannerAdv.advMainBannerAdvertiseGroupId}/main/banner/MB/refund`)
        .then(({data: {error, result}}) => {
          console.log(result)
          console.log(result.result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, mainBannerAdv)
            temp.advStatus = 'R'
            temp.advRefundTime = result.result.advRefundTime
            temp.refundWeekDate = result.result.refundWeekDate
            temp.advStatusDisplay = '환불 완료'
            setMainBannerAdv(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const cancelAdv = (id = 0) => {
    console.log(id)
    console.log(mainBannerAdv.advMainBannerAdvertiseGroupId)
    if (id <= 0 || mainBannerAdv.advMainBannerAdvertiseGroupId <= 0) {
      toast.error('취소 시 필수 값 체크!')
      return false
    }
    if (window.confirm('광고 취소 처리를 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/${id}/${mainBannerAdv.advMainBannerAdvertiseGroupId}/main/banner/MB/cancel`)
        .then(({data: {error, result}}) => {
          console.log(result)
          console.log(result.result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, mainBannerAdv)
            temp.advStatus = 'C'
            temp.advCancelledTime = result.result.advCancelledTime
            temp.cancelWeekDate = result.result.cancelWeekDate
            temp.cancelSchedules = result.result.cancelSchedules
            temp.cancelSumPrice = result.result.cancelSumPrice
            temp.refundWeekDate = result.result.refundWeekDate
            temp.advStatusDisplay = '취소 완료'
            setMainBannerAdv(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>메인 배너 광고</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {mainBannerAdv !== '' && (
              <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <h2>
                    {mainBannerAdv.advMainBannerAdvertiseGroupId}. [{mainBannerAdv.advStatusDisplay}]
                  </h2>
                </div>

                <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <img src={mainBannerAdv.userImageUrl} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
                    </div>

                    <div style={{marginLeft: 10}}>
                      {mainBannerAdv.userBlack === 1 && <p style={{color: 'red'}}>{mainBannerAdv.userBlackComment}</p>}

                      <h1>{mainBannerAdv.userId}. 게스트</h1>
                      <TextSub title={'닉네임 (이름)'} subTitle={`${mainBannerAdv.userName} (${mainBannerAdv.userRealName})`} marginTop={0} />
                      <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(mainBannerAdv.userBirth))).asYears())}세`} marginTop={-10} />
                      <TextSub title={'전화번호'} subTitle={mainBannerAdv.userPhone} marginTop={-10} />
                      <TextSub title={'이메일'} subTitle={mainBannerAdv.userEmail} marginTop={-10} />
                      <TextSub
                        title={'가입일'}
                        subTitle={`${mainBannerAdv.userCreatedAt}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(mainBannerAdv.userCreatedAt)).asDays()} 일전`}
                        marginTop={-10}
                      />
                      <TextSub
                        title={'마지막 활성 시간'}
                        subTitle={`${moment(mainBannerAdv.userLastLoginedAt).format('MM-DD')}, ${Math.floor(
                          (moment.duration(moment().diff(moment(mainBannerAdv.userLastLoginedAt, 'YYYY-MM-DD'))).asDays() * 100) / 100
                        )} 일전`}
                        marginTop={-10}
                      />
                      {mainBannerAdv.memo !== undefined && mainBannerAdv.memo.length > 0 && <TextSub title={'메모'} subTitle={mainBannerAdv.memo} marginTop={-10} />}
                    </div>
                  </div>

                  <div>
                    <SwitchButton id={mainBannerAdv.userId}>Switch</SwitchButton>
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        window.open(`/user/${mainBannerAdv.userId}`)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      자세히 보기
                    </label>
                  </div>
                </div>

                <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                  <div style={{margin: 10, paddingBottom: 10}}>
                    <table style={{fontSize: '15px', width: '100%', lineHeight: 2}}>
                      <colgroup>
                        <col style={{width: 120}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>광고 일정</td>
                          <td>{mainBannerAdv.advSchedule}</td>
                        </tr>
                        <tr>
                          <td>결제 금액</td>
                          <td>{mainBannerAdv.advPrice.toLocaleString()}원 (부가세 포함)</td>
                        </tr>
                        <tr>
                          <td>결제일</td>
                          <td>
                            {mainBannerAdv.advPaidTime === '0' && (
                              <label
                                onClick={() => {
                                  putAdvPaid()
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                결제 완료 처리
                              </label>
                            )}
                            {mainBannerAdv.advPaidTime !== '0' && mainBannerAdv.advPaidTime}
                          </td>
                        </tr>
                        {null !== mainBannerAdv.cancelWeekDate && undefined !== mainBannerAdv.cancelWeekDate && mainBannerAdv.cancelWeekDate.length > 0 && (
                          <>
                            <tr>
                              <td>
                                <br />
                              </td>
                            </tr>
                            <tr style={{verticalAlign: 'top'}}>
                              <td>취소일</td>
                              <td>
                                {'0' !== mainBannerAdv.advCancelledTime && (
                                  <>
                                    {mainBannerAdv.advCancelledTime}
                                    <br />
                                  </>
                                )}
                                {mainBannerAdv.advPaidTime !== '0' && (
                                  <label
                                    onClick={() => {
                                      setModalVisible(true)
                                    }}
                                    className='btn btn-sm btn-danger'
                                  >
                                    취소하기
                                  </label>
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                        {null !== mainBannerAdv.cancelSchedules && undefined !== mainBannerAdv.cancelSchedules && mainBannerAdv.cancelSchedules.length > 0 && (
                          <>
                            <tr style={{verticalAlign: 'top'}}>
                              <td>취소 일정</td>
                              <td>
                                {mainBannerAdv.cancelSchedules.map((item: any) => {
                                  return (
                                    <>
                                      {item.schedule_display} <br />
                                    </>
                                  )
                                })}
                              </td>
                            </tr>
                          </>
                        )}
                        {0 !== mainBannerAdv.cancelSumPrice && (
                          <>
                            <tr>
                              <td>취소 금액</td>
                              <td>{mainBannerAdv.cancelSumPrice.toLocaleString()}원 (부가세 포함)</td>
                            </tr>
                          </>
                        )}
                        {
                          <>
                            <tr>
                              <td>환불일</td>
                              <td>
                                {mainBannerAdv.advRefundTime !== '0' && mainBannerAdv.advRefundTime}
                                {null !== mainBannerAdv.refundWeekDate && undefined !== mainBannerAdv.refundWeekDate && mainBannerAdv.refundWeekDate.length > 0 && (
                                  <>
                                    <br />
                                    <label
                                      onClick={() => {
                                        refundAdv()
                                      }}
                                      className='btn btn-sm btn-primary'
                                    >
                                      환불 완료 처리
                                    </label>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {mainBannerAdv !== null && (
              <div>
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 0 ? 'active' : '')} onClick={() => setLog(0)}>
                      광고 진행 로그 ({mainBannerAdv.mainBannerAdvertiseHistories !== undefined ? mainBannerAdv.mainBannerAdvertiseHistories.length : 0})
                    </label>
                  </li>
                </ul>
                <div>
                  {log === 0 && (
                    <div>
                      {mainBannerAdv.mainBannerAdvertiseHistories !== undefined &&
                        mainBannerAdv.mainBannerAdvertiseHistories.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_0_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h3
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <p dangerouslySetInnerHTML={{__html: history.content}} />
                              </h3>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div style={{padding: 20, width: '100%'}}>
            {null !== mainBannerAdv.cancelWeekDate &&
              undefined !== mainBannerAdv.cancelWeekDate &&
              mainBannerAdv.cancelWeekDate.map((item: any) => {
                return (
                  <>
                    <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                      <input
                        className='form-check-input'
                        type='radio'
                        value={item.id}
                        name='week'
                        id={`weekRadio` + item.id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectCancelSchedule(item.id)
                          }
                        }}
                      />
                      <label className='form-check-label' htmlFor={`weekRadio` + item.id}>
                        {item.schedule}
                      </label>
                    </div>
                  </>
                )
              })}
          </div>
          <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
            <label
              onClick={() => {
                let schedule = `${selectCancelSchedule}`
                if (schedule !== '') {
                  cancelAdv(Number(schedule))
                  setModalVisible(false)
                }
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-danger'
            >
              취소하기
            </label>
          </div>
        </Modal>
      )}
    </>
  )
}
