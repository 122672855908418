import React, {useEffect, useState} from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../../components/TextSub'
import {useParams} from 'react-router-dom'
import moment from 'moment/moment'
import classNames from 'classnames'
import Modal from '../../../components/Modal'
import ImagePath from '../../../../commons/ImagePath'
import SwitchButton from '../../../../components/SwitchButton'

export function AdvertiseNetworkPlaceDetail() {
  const {id} = useParams<{id: string}>()

  const [loading, setLoading] = useState(false)

  const [place, setPlace] = useState<any>('')

  const [log, setLog] = useState(0)

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    getNetworkPlace()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleItemFileInput = (e: any) => {
    if (place.advStatus === 'PW') {
      alert('결제 대기 중입니다.')
      return false
    }

    const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            console.log(path)
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  // main_vue.completeImage(file, response.data.result)

                  let imageId = result.id
                  let imagePath = result.image_path

                  axios
                    .put(`${process.env.REACT_APP_API2_URL}am/adv/network/N/group/${id}/place/item/upload`, {
                      itemImageId: result.id,
                      itemImagePath: result.image_path,
                    })
                    .then(({data: {error, result}}) => {
                      console.log(error)
                      console.log(result)
                      console.log(result.data)

                      const temp = Object.assign({}, place)
                      temp.advStatus = 'IF'
                      temp.advNetworkItemRegDate = result
                      temp.advNetworkItemImageId = imageId
                      temp.advNetworkItemImagePath = imagePath
                      temp.advStatusDisplay = '소재 등록 완료'
                      setPlace(temp)
                    })
                    .catch((error) => {
                      console.log(error)
                      // main_vue.failImage(file)
                    })
                } else {
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        // main_vue.failImage(file)
      }
    })
  }

  const handleItemFileInputChange = (e: any) => {
    if (place.advStatus === 'C' || place.advStatus === 'CW') {
      alert('결제 취소되었습니다.')
      return false
    }

    if (place.advStatus === 'E') {
      alert('광고 종료 중입니다.')
      return false
    }

    if (place.advNetworkItemImageId !== 0 && !window.confirm('광고 소재 이미지를 변경하시겠습니까?')) {
      return false
    }

    const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
          })
          .then((response) => {
            console.log(path)
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  // main_vue.completeImage(file, response.data.result)

                  let imageId = result.id
                  let imagePath = result.image_path

                  axios
                    .put(`${process.env.REACT_APP_API2_URL}am/adv/network/N/group/${id}/place/item/change`, {
                      itemImageId: result.id,
                      itemImagePath: result.image_path,
                    })
                    .then(({data: {error, result}}) => {
                      console.log(error)
                      console.log(result)
                      console.log(result.data)

                      const temp = Object.assign({}, place)
                      temp.advNetworkItemRegDate = result
                      temp.advNetworkItemImageId = imageId
                      temp.advNetworkItemImagePath = imagePath
                      setPlace(temp)
                    })
                    .catch((error) => {
                      console.log(error)
                      // main_vue.failImage(file)
                    })
                } else {
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        // main_vue.failImage(file)
      }
    })
  }

  const itemImageDelete = () => {
    if (window.confirm('광고 소재를 삭제하시겠습니까?')) {
      setLoading(true)
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/network/N/group/${id}/place/item/delete`)
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, place)
            temp.advStatus = 'PF'
            temp.advNetworkItemImageId = 0
            temp.advNetworkItemImagePath = ''
            temp.advStatusDisplay = '결제 완료'
            setPlace(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const getNetworkPlace = () => {
    setPlace('')
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/network/N/group/${id}/place`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          console.log(result.advPrice)
          setPlace(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const putInfoSendMail = () => {
    if (window.confirm('안내 알림톡을 발송 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/network/N/group/${id}/place/info/mail/send`)
        .then(({data: {error, time, result}}) => {
          console.log(time)
          console.log(result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, place)
            temp.advStatus = 'NF'
            temp.advNetworkSendMailId = result.advNetworkSendMailId
            temp.advNetworkInfoSendDate = result.advNetworkInfoSendDate
            temp.advStatusDisplay = '시작 전 안내 완료'
            setPlace(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const putAdvSettingComplete = () => {
    if (window.confirm('광고 세팅 완료처리를 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/network/N/group/${id}/place/setting`)
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, place)
            temp.advStatus = 'SF'
            temp.advNetworkSettingDate = result.advNetworkSettingDate
            temp.advStatusDisplay = '광고 세팅 완료'
            setPlace(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const downloadImage = async () => {
    const imageUrl = ImagePath.getS3(place.advNetworkItemImagePath)
    const res = await fetch(imageUrl)
    const contentType = res.headers.get('Content-Type') ?? 'png'
    const raw = await res.blob()
    const blob = window.URL.createObjectURL(raw)
    const anchor = document.createElement('a')
    anchor.style.display = 'none'
    anchor.href = blob
    anchor.download = `네트워크_광고_ID${place.advNetworkScheduleId}_P${place.placeId}.${contentType.split('/').pop()}`
    document.body.appendChild(anchor)
    anchor.click()
    window.URL.revokeObjectURL(blob)
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>네크워크 광고</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {place !== '' && (
              <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <h2>
                    {place.advPlaceAdvertiseGroupId}. [{place.advStatusDisplay}]
                  </h2>
                </div>

                <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                  <div style={{width: 100, height: 100, position: 'relative'}}>
                    <img src={ImagePath.get(place.placeMainImagePath, '100x100')} alt='' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                    <div
                      style={{
                        position: 'absolute',
                        width: 30,
                        height: 30,
                        borderTopLeftRadius: 8,
                        bottom: 0,
                        right: 0,
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <h4 style={{margin: 0}}>{place.placeGrade}</h4>
                    </div>
                  </div>

                  <div style={{marginLeft: 10}}>
                    <LinesEllipsis text={`${place.placeId}. ${place.placeTitle}`} maxLine='2' ellipsis='...' trimRight basedOn='letters' style={{fontSize: 20, fontWeight: 'bold'}} />

                    <TextSub title={'장소 금액'} subTitle={`${place.placePriceGuestVat}`} marginTop={0} />
                    <TextSub title={'촬영 건수'} subTitle={`${place.placeShootCount} (${place.placeBookingCount})`} marginTop={-10} />
                    <TextSub title={'카테고리'} subTitle={`${place.placeCategoryMain} > ${place.placeCategorySub}`} marginTop={-10} />
                    <TextSub title={'관리자 장소 메모'} subTitle={place.placeAdminComment} marginTop={-10} />

                    <label
                      onClick={() => {
                        window.open(`/place/${place.placeId}`, '_blank')
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      장소 보기
                    </label>
                  </div>
                </div>

                <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                  <div>
                    <div style={{width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <img src={place.hostImageUrl} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
                    </div>

                    <div style={{marginLeft: 10}}>
                      {place.hostBlack === 1 && <p style={{color: 'red'}}>{place.hostBlackComment}</p>}

                      <h1>{place.userId}. 호스트</h1>
                      <TextSub title={'닉네임 (이름)'} subTitle={`${place.hostName} (${place.hostRealName})`} marginTop={0} />
                      <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(place.hostBirth))).asYears())}세`} marginTop={-10} />
                      <TextSub title={'전화번호'} subTitle={place.hostPhone} marginTop={-10} />
                      <TextSub title={'이메일'} subTitle={place.hostEmail} marginTop={-10} />

                      {place.hostStatistic !== undefined && place.hostStatistic !== null && (
                        <>
                          <TextSub title={'총 촬영 횟수'} subTitle={`${place.hostStatistic.host_booking_completed_count} / ${place.hostStatistic.host_booking_completed_add_count}`} marginTop={-10} />
                          <TextSub
                            title={'예약 승인율'}
                            subTitle={`${
                              place.hostStatistic.host_proceeding_booking > 0 && place.hostStatistic.host_booking_count > 0
                                ? Math.floor((place.hostStatistic.host_proceeding_booking / place.hostStatistic.host_booking_count) * 100)
                                : 0
                            }% (${place.hostStatistic.host_proceeding_booking} / ${place.hostStatistic.host_booking_count})`}
                            marginTop={-10}
                          />
                          <TextSub
                            title={'촬영 진행률'}
                            subTitle={`${
                              place.hostStatistic.host_booking_completed_count > 0 && place.hostStatistic.host_proceeding_booking > 0
                                ? Math.floor((place.hostStatistic.host_booking_completed_count / place.hostStatistic.host_proceeding_booking) * 100)
                                : 0
                            }% (${place.hostStatistic.host_booking_completed_count} / ${place.hostStatistic.host_proceeding_booking})`}
                            marginTop={-10}
                          />
                          <TextSub title={'총 소득금액'} subTitle={`${place.hostStatistic.booking_host_price.toLocaleString('en')}`} marginTop={-10} />
                        </>
                      )}

                      <TextSub
                        title={'가입일'}
                        subTitle={`${place.hostCreatedAt}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(place.hostCreatedAt)).asDays()} 일전`}
                        marginTop={-10}
                      />
                      <TextSub
                        title={'마지막 활성 시간'}
                        subTitle={`${moment(place.hostLastLoginedAt).format('MM-DD')}, ${Math.floor(
                          (moment.duration(moment().diff(moment(place.hostLastLoginedAt, 'YYYY-MM-DD'))).asDays() * 100) / 100
                        )} 일전`}
                        marginTop={-10}
                      />
                      <TextSub title={'패널티 히스토리'} subTitle={place.hostBan} marginTop={-10} />
                      {place.hostMemo !== undefined && place.hostMemo.length > 0 && <TextSub title={'메모'} subTitle={place.hostMemo} marginTop={-10} />}
                    </div>
                  </div>

                  <div>
                    <SwitchButton id={place.userId}>Switch</SwitchButton>
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        window.open(`/user/${place.userId}`)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      자세히 보기
                    </label>
                  </div>
                </div>

                <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                  <div style={{margin: 10, paddingBottom: 10}}>
                    <table style={{fontSize: '15px', width: '100%'}}>
                      <colgroup>
                        <col style={{width: 120}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>광고 일정 ID</td>
                          <td>{place.advNetworkScheduleId}</td>
                        </tr>
                        <tr>
                          <td>광고 일정</td>
                          <td>{place.advSchedule}</td>
                        </tr>
                        <tr>
                          <td>결제 금액</td>
                          <td>{place.advPrice.toLocaleString()}원 (부가세 포함)</td>
                        </tr>
                        <tr>
                          <td>결제 마감일</td>
                          <td>{place.advertisePaymentDeadlineDate} 24:00:00</td>
                        </tr>
                        <tr>
                          <td>결제일</td>
                          <td>{place.advPaidTime}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>소재 등록 마감일</td>
                          <td>{place.itemRegDeadlineDate}</td>
                        </tr>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>광고 소재</td>
                          <td>
                            {place.advNetworkItemImageId === 0 && (
                              <div style={{marginTop: 10}}>
                                <input
                                  type='file'
                                  name='file'
                                  onChange={(e) => {
                                    handleItemFileInput(e)
                                  }}
                                />
                              </div>
                            )}
                            {place.advNetworkItemImageId !== 0 && (
                              <>
                                <div style={{marginTop: 10}}>
                                  <input
                                    type='file'
                                    name='file'
                                    onChange={(e) => {
                                      handleItemFileInputChange(e)
                                    }}
                                  />
                                </div>
                                {place.advNetworkItemImagePath}
                                {(place.advStatus === 'IF' || place.advStatus === 'SF') && (
                                  <>
                                    <label
                                      style={{marginLeft: 10}}
                                      onClick={() => {
                                        itemImageDelete()
                                      }}
                                      className='btn btn-sm btn-danger'
                                    >
                                      삭제
                                    </label>
                                  </>
                                )}
                                <br />
                                <img
                                  src={ImagePath.get(place.advNetworkItemImagePath, '550x364')}
                                  alt='광고 소재'
                                  style={{
                                    width: 280,
                                    height: 200,
                                    objectFit: 'cover',
                                    borderRadius: 10,
                                  }}
                                />
                                <br />
                                <br />
                                <label
                                  onClick={() => {
                                    downloadImage()
                                  }}
                                  className='btn btn-sm btn-primary'
                                >
                                  다운로드
                                </label>
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>소재 등록일</td>
                          <td>{place.advNetworkItemImageId !== 0 && place.advNetworkItemRegDate}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>광고 세팅 마감일</td>
                          <td>{place.advSettingDeadlineDate}</td>
                        </tr>
                        <tr>
                          <td>광고 세팅 완료일</td>
                          <td>{place.advNetworkSettingDate !== '' && place.advNetworkSettingDate}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>안내 마감일</td>
                          <td>{place.infoMailSendDeadlineDate}</td>
                        </tr>
                        <tr>
                          <td>안내 완료일</td>
                          <td>{place.advNetworkInfoSendDate !== '' && place.advNetworkInfoSendDate}</td>
                        </tr>
                        <tr>
                          <td>메인 노출 여부</td>
                          <td>{place.advNetworkMainShow === 1 ? '노출' : '미노출'}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        {place.advNetworkCancelScheduleDisplay !== null && place.advNetworkCancelScheduleDisplay !== '' && (
                          <>
                            <tr>
                              <td>취소 일정</td>
                              <td>{place.advNetworkCancelScheduleDisplay}</td>
                            </tr>
                            <tr>
                              <td>취소 금액</td>
                              <td>{place.advNetworkCancelSchedulePriceDisplay}</td>
                            </tr>
                            <tr>
                              <td style={{verticalAlign: 'top'}}>취소일</td>
                              <td>
                                {place.advCancelledTime.map((ct: any, index: number) => {
                                  return (
                                    <>
                                      <span key={index.toString()}>{ct.adv_cancelled_time}</span>
                                      <br />
                                    </>
                                  )
                                })}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {place.advNetworkSendMailId === 0 && place.advNetworkSettingDate === '' && place.advStatus === 'IF' && (
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          putAdvSettingComplete()
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        광고 세팅 완료 처리
                      </label>
                    )}
                    {place.advStatus === 'SF' && (
                      <label
                        onClick={() => {
                          putInfoSendMail()
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        안내 발송
                      </label>
                    )}
                  </div>
                </div>
                {place.kcp !== undefined && place.kcp !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - KCP</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td style={{verticalAlign: 'top'}}>결제 타입</td>
                            <td>
                              {place.kcp.use_pay_method === '001000000000'
                                ? '가상 계좌'
                                : place.kcp.use_pay_method === '100000000000'
                                ? '카드 결제'
                                : place.kcp.use_pay_method === '200000000000'
                                ? '간편 결제'
                                : place.kcp.use_pay_method === '300000000000'
                                ? '수기 결제'
                                : place.kcp.use_pay_method}
                              <br />
                              {place.kcp.use_pay_method === '001000000000' && (
                                <>
                                  {place.kcp.bank_name}
                                  <br />
                                  {place.kcp.account}
                                  <br />
                                  {place.kcp.tno}
                                </>
                              )}
                              {place.kcp.use_pay_method === '100000000000' && (
                                <>
                                  {place.kcp.card_name}
                                  <br />
                                  {place.kcp.cash_no}
                                  <br />
                                  '일반결제'
                                </>
                              )}
                              {place.kcp.use_pay_method === '200000000000' && place.kcp.card_name}
                            </td>
                          </tr>
                          <tr>
                            <td>결제 금액</td>
                            <td>{Number.parseInt(place.kcp.amount).toLocaleString('en')}</td>
                          </tr>
                          <tr>
                            <td>결제일</td>
                            <td>{place.kcp.updated_at}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {place.tossPayment !== undefined && place.tossPayment !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - TOSS</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td style={{verticalAlign: 'top'}}>결제 타입</td>
                            <td>
                              {place.tossPayment.method}
                              <br />
                              {place.tossPayment.method === '가상계좌' && (
                                <>
                                  {place.tossPayment.virtualAccountBankName}
                                  <br />
                                  {place.tossPayment.virtualAccountAccountNumber}
                                  <br />
                                  {place.tossPayment.virtualAccountCustomerName}
                                  <br />
                                </>
                              )}
                              {place.tossPayment.cardIssuerName}
                            </td>
                          </tr>
                          <tr>
                            <td>결제 금액</td>
                            <td>{Number.parseInt(place.tossPayment.totalAmount).toLocaleString('en')}</td>
                          </tr>
                          <tr>
                            <td>결제일</td>
                            <td>{place.tossPayment.requestedAt}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {place.naverPayPayments !== undefined && place.naverPayPayments !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - 네이버페이</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td style={{verticalAlign: 'top'}}>결제 타입</td>
                            <td>
                              {place.naverPayPayments.primaryPayMeans}
                              {place.naverPayPayments.primaryPayMeans === '신용카드' && (
                                <>
                                  <br />
                                  {place.naverPayPayments.cardCorpCode}
                                </>
                              )}
                              {place.naverPayPayments.primaryPayMeans === '계좌이체' && (
                                <>
                                  <br />
                                  {place.naverPayPayments.bankCorpCode}
                                  <br />
                                  {place.naverPayPayments.bankAccountNo}
                                </>
                              )}
                              {place.naverPayPayments.primaryPayMeans === '포인트/머니 결제' && (
                                <>
                                  <br />
                                  {place.naverPayPayments.npointPayAmount} 포인트/머니
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>결제 금액</td>
                            <td>{Number.parseInt(place.naverPayPayments.totalPayAmount).toLocaleString('en')}</td>
                          </tr>
                          <tr>
                            <td>결제일</td>
                            <td>{place.naverPayPayments.admissionYmdt}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {place.kakaopayPayments !== undefined && place.kakaopayPayments !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - 카카오 페이</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>결제 타입</td>
                          <td>
                            {place.kakaopayPayments.payment_method_type}
                            {place.kakaopayPayments.payment_method_type === 'CARD' && (
                              <>
                                <br />
                                {place.kakaopayPayments.s_card_corp_name}
                              </>
                            )}
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>결제 금액</td>
                          <td>{Number.parseInt(place.kakaopayPayments.total).toLocaleString('en')}</td>
                        </tr>
                        <tr>
                          <td>결제일</td>
                          <td>{place.kakaopayPayments.k_created_at}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
            {place !== null && (
              <div>
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 0 ? 'active' : '')} onClick={() => setLog(0)}>
                      광고 진행 로그 ({place.placeAdvertiseHistories !== undefined ? place.placeAdvertiseHistories.length : 0})
                    </label>
                  </li>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 1 ? 'active' : '')} onClick={() => setLog(1)}>
                      알림톡 로그 ({place.alimTalkLogs !== undefined ? place.alimTalkLogs.length : 0})
                    </label>
                  </li>
                </ul>
                <div>
                  {log === 0 && (
                    <div>
                      {place.placeAdvertiseHistories !== undefined &&
                        place.placeAdvertiseHistories.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_0_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h3
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <p dangerouslySetInnerHTML={{__html: history.content}} />
                              </h3>
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {log === 1 && (
                    <div>
                      {place.alimTalkLogs !== undefined &&
                        place.alimTalkLogs.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_1_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h4>{history.phone}</h4>
                              <br />
                              <h3
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <p dangerouslySetInnerHTML={{__html: history.message}} />
                              </h3>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div style={{padding: 20, width: '100%'}}>
            <p dangerouslySetInnerHTML={{__html: place.sendMails.content}} />
          </div>
        </Modal>
      )}
    </>
  )
}
