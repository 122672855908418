import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {isMobile} from 'react-device-detect'

export function MarketingHourplaceShortsEditor() {
    const {marketingHourplaceShortsId} = useParams<{marketingHourplaceShortsId: string}>()
    const [item, setItem] = useState<any>('')


    useEffect(() => {
      if (Number(marketingHourplaceShortsId) > 0) {
        getMarketingHourplaceShorts()
      }
      return () => {
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getMarketingHourplaceShorts = () => {
      axios
        .get(`${process.env.REACT_APP_API2_URL}am/marketing/hourplace/shorts/${marketingHourplaceShortsId}`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            setItem(result)
            window.scrollTo(0, 0)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const upsert = () => {
      if(item.url.trim === '') {
        alert('링크를 확인해주세요.');
        return;
      }

      console.log(item)

      axios.post(`${process.env.REACT_APP_API2_URL}am/marketing/hourplace/shorts`, {
          marketing_hourplace_shorts_id: marketingHourplaceShortsId,
          url: item.url,
          order: item.order,
          show: item.show
        }
      ).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          alert(error)
        } else {
          if (Number(marketingHourplaceShortsId) > 0) {
            //window.location.reload();
            alert('수정되었습니다.');
          } else {
            alert('등록되었습니다.');
            window.location.href = '/marketing/hourplace/shorts/'+result.id+'/editor'
          }
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }

    return (
      <>
        <PageTitle breadcrumbs={[]}>아워플레이스 쇼츠</PageTitle>
        <div style={{padding: '0px 20px', borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                기본 설정
              </h2>
              <div className="separator my-2" />
              <TextSub2 title={'유투브 쇼츠 링크'} subTitle={'쇼츠 링크의 id를 입력해주세요.'} />
              <input type="text" className="form-control" value={item.url}
                     onChange={(e) => {
                       const temp = Object.assign({}, item)
                       temp.url = e.target.value
                       setItem(temp)
                     }}
              />
              <div className="separator my-5" />
              <TextSub2 title={'순서'} subTitle={''} />
              <input type="number" className="form-control" max={99} value={item.order}
                     onChange={(e) => {
                       const temp = Object.assign({}, item)
                       temp.order = e.target.value
                       setItem(temp)
                     }}
              />

              <div className="separator my-2" />
              <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center'}}>
                  <label style={{marginRight: 20, fontWeight: 500, fontSize: 15, color: '#1B1D1F'}}>
                    쇼츠 노출
                    <br /><br />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="layout-builder[layout][header][fixed][desktop]"
                      checked={item.show === 1}
                      onChange={(event) => {
                        const temp = Object.assign({}, item)
                        if (event.target.checked) {
                          temp.show = 1
                        } else {
                          temp.show = 0
                        }
                        setItem(temp)
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 10}}>
            <label onClick={() => {
              upsert()
            }} className="btn btn-primary">
              저장하기
            </label>
          </div>
        </div>
      </>
    )
}
