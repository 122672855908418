import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import Highlighter from 'react-highlight-words'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {InfoBox} from '../components/InfoBox'

export function UserPlaceFeedGrey() {
    const [items, setItems] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)

    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState('')

    const [status, setStatus] = useState('')

    const [hostPlaceFeedGreyKeywords, setHostPlaceFeedGreyKeywords] = useState<any>(null)
    const [hostPlaceFeedbackGreyKeywords, setHostPlaceFeedbackGreyKeywords] = useState<any>(null)
    const [hostPlaceWatchGreyKeywords, setHostPlaceWatchGreyKeywords] = useState<any>(null)

    const [countFeedGrey, setCountFeedGrey] = useState(0)
    const [countFeedGreyDefault, setCountFeedGreyDefault] = useState(0)

    const [countUserPlaceFeedGrey, setCountUserPlaceFeedGrey] = useState(0)
    const [countUserPlaceFeedGreyDefault, setCountUserPlaceFeedGreyDefault] = useState(0)

    const [countUserPlaceFeedbackGrey, setCountUserPlaceFeedbackGrey] = useState(0)
    const [countUserPlaceFeedbackGreyDefault, setCountUserPlaceFeedbackGreyDefault] = useState(0)

    const [countUserPlaceWatchGrey, setCountUserPlaceWatchGrey] = useState(0)
    const [countUserPlaceWatchGreyDefault, setCountUserPlaceWatchGreyDefault] = useState(0)

    useEffect(() => {
        getHosts()
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page: number) => {
        getHosts(status, page)
    }

    const onTab = (tabStatus: string) => {
        setStatus(tabStatus)
        getHosts(tabStatus, 1)
    }

    const onSearch = () => {
        setStatus('')
        getHosts('', 1, search)
    }

    const getHosts = (status = '', page = 1, search = '') => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/feed/grey?keyword=${search}&grey_type=${status}&page=${page}`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    const greys = result.data.greys
                    setItems(greys)

                    setHostPlaceFeedGreyKeywords(result.data.host_place_feed_grey_keywords)
                    setHostPlaceFeedbackGreyKeywords(result.data.host_place_feedback_grey_keywords)
                    setHostPlaceWatchGreyKeywords(result.data.host_place_watch_grey_keywords)

                    setCountFeedGrey(result.data.count_feed_grey)
                    setCountFeedGreyDefault(result.data.count_feed_grey_default)

                    setCountUserPlaceFeedGrey(result.data.count_user_place_feed_grey)
                    setCountUserPlaceFeedGreyDefault(result.data.count_user_place_feed_grey_default)

                    setCountUserPlaceFeedbackGrey(result.data.count_user_place_feedback_grey)
                    setCountUserPlaceFeedbackGreyDefault(result.data.count_user_place_feedback_grey_default)

                    setCountUserPlaceWatchGrey(result.data.count_user_place_watch_grey)
                    setCountUserPlaceWatchGreyDefault(result.data.count_user_place_watch_grey_default)

                    setCurrentPage(result.current_page)
                    setPerPage(result.per_page)
                    setTotal(result.total)
                    window.scrollTo(0, 0)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    const skip = (item: any) => {
        console.log({grey_id: item.grey_id})
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/feed/grey/${item.grey_id}/skip`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    const temps = [...items]
                    temps.splice(temps.indexOf(item), 1, item)
                    item.grey_status = 0
                    setItems(temps)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const hide = (item: any) => {
        console.log({grey_id: item.grey_id})
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/feed/grey/${item.grey_id}/hide`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    const temps = [...items]
                    temps.splice(temps.indexOf(item), 1, item)
                    item.grey_status = 1
                    setItems(temps)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const show = (item: any) => {
        console.log({grey_id: item.grey_id})
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/feed/grey/${item.grey_id}/show`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    const temps = [...items]
                    temps.splice(temps.indexOf(item), 1, item)
                    item.grey_status = -1
                    setItems(temps)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>장소 피드_그레이</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <label
                        onClick={() => {
                            onTab('')
                        }}
                        className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}
                    >
                        모두보기 ({countFeedGrey.toLocaleString('en')} <span style={{color: 'red'}}>/ {countFeedGreyDefault.toLocaleString('en')}</span>)
                    </label>

                    <label
                        onClick={() => {
                            onTab('user_place_feed')
                        }}
                        className={classNames('btn', {'btn-dark': status === 'user_place_feed'}, {'btn-btn-white': status !== 'user_place_feed'})}
                    >
                        장소 소식 ({countUserPlaceFeedGrey.toLocaleString('en')} <span style={{color: 'red'}}>/ {countUserPlaceFeedGreyDefault.toLocaleString('en')}</span>)
                    </label>

                    <label
                        onClick={() => {
                            onTab('user_place_feedback')
                        }}
                        className={classNames('btn', {'btn-dark': status === 'user_place_feedback'}, {'btn-btn-white': status !== 'user_place_feedback'})}
                    >
                        장소 후기 ({countUserPlaceFeedbackGrey.toLocaleString('en')} <span style={{color: 'red'}}>/ {countUserPlaceFeedbackGreyDefault.toLocaleString('en')}</span>)
                    </label>

                    <label
                        onClick={() => {
                            onTab('user_place_watch')
                        }}
                        className={classNames('btn', {'btn-dark': status === 'user_place_watch'}, {'btn-btn-white': status !== 'user_place_watch'})}
                    >
                        콘첸츠 속 장소 ({countUserPlaceFeedGrey.toLocaleString('en')} <span style={{color: 'red'}}>/ {countUserPlaceFeedGreyDefault.toLocaleString('en')}</span>)
                    </label>
                </div>

                <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='장소 번호, 장소명, 게스트 이름, 호스트 이름'
                        value={search}
                        style={{flex: 1}}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onSearch()
                            }
                        }}
                    />
                    <label
                        onClick={() => {
                            onSearch()
                        }}
                        style={{width: 100}}
                        className='btn btn-dark'
                    >
                        검색
                    </label>
                </div>

                {loading ? (
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Loading...
                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                    </div>
                ) : (
                    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                        {items.map((item: any, index: number) => {
                            return (
                                <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={item.grey_id.toString()}>
                                    <h1>
                                        {item.grey_type === 'user_place_feed' ? '장소 피드_장소 소식' : ''}
                                        {item.grey_type === 'user_place_feedback' ? '장소 피드_장소 후기' : ''}
                                        {item.grey_type === 'user_place_watch' ? '장소 피드_콘텐츠 속 장소' : ''}
                                    </h1>

                                    <InfoBox item={item} />
                                    {item.grey_type === 'user_place_feed' && (
                                        <div>
                                            <div className='separator my-10' />

                                            <h2>본문</h2>
                                            <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                                                <Highlighter
                                                    searchWords={hostPlaceFeedGreyKeywords}
                                                    autoEscape
                                                    highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                                    textToHighlight={item.content ?? ''}
                                                />
                                            </p>

                                            <div className='separator my-10' />

                                            <h2>태그</h2>
                                            <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                                                <Highlighter
                                                    searchWords={hostPlaceFeedGreyKeywords}
                                                    autoEscape
                                                    highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                                    textToHighlight={item.tag ?? ''}
                                                />
                                            </p>
                                        </div>
                                    )}

                                    {item.grey_type === 'user_place_feedback' && (
                                        <div>
                                            <div className='separator my-10' />

                                            <h2>본문</h2>
                                            <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                                                <Highlighter
                                                    searchWords={hostPlaceFeedbackGreyKeywords}
                                                    autoEscape
                                                    highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                                    textToHighlight={item.content ?? ''}
                                                />
                                            </p>
                                        </div>
                                    )}

                                    {item.grey_type === 'user_place_watch' && (
                                        <div>
                                            <div className='separator my-10' />

                                            <h2>본문</h2>
                                            <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                                                <Highlighter
                                                    searchWords={hostPlaceWatchGreyKeywords}
                                                    autoEscape
                                                    highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                                    textToHighlight={item.content ?? ''}
                                                />
                                            </p>
                                        </div>
                                    )}

                                    {item.grey_status < 0 && (
                                        <div>
                                            <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                <div className={'row'}>
                                                    <div style={{padding: 4, width: 'fit-content'}}>
                                                        <label
                                                            style={{border: '1px solid #FF4B3E', backgroundColor: '#FF4B3E'}}
                                                            onClick={() => {
                                                                hide(item)
                                                            }}
                                                            className='btn btn-outline-light'
                                                        >
                                                            게시물 숨김
                                                        </label>
                                                    </div>
                                                    <div style={{padding: 4, width: 'fit-content'}}>
                                                        <label
                                                            style={{border: '1px solid #6AD559'}}
                                                            onClick={() => {
                                                                skip(item)
                                                            }}
                                                            className='btn btn-outline-success'
                                                        >
                                                            건너뛰기
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {item.grey_status === 0 && (
                                        <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <label style={{border: '1px solid #007BFF', borderRadius: 8, padding: '8px 16px'}} className='btn-outline-primary'>
                                                {item.grey_status === 0 ? '건너뛰기 처리 완료' : ''}
                                            </label>
                                        </div>
                                    )}

                                    {item.grey_status > 0 && (
                                        <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <div className={'row'}>
                                                <div style={{padding: 4, width: 'fit-content'}}>
                                                    <label
                                                        style={{border: '1px solid #3C82FA', backgroundColor: '#3C82FA'}}
                                                        onClick={() => {
                                                            show(item)
                                                        }}
                                                        className='btn btn-outline-light'
                                                    >
                                                        게시물 노출
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}

                        <div style={{padding: 20}}>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={10}
                                prevPageText={'‹'}
                                nextPageText={'›'}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
