import React from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import {TextSub} from './TextSub'
import SwitchButton from '../../components/SwitchButton'

type Props = {
  place: any
  isButton?: boolean
}

const PlaceItem: React.FC<Props> = ({place, isButton = false}) => {
  return (
    <div
      style={{
        marginTop: 16,
        padding: 20,
        width: '100%',
        borderRadius: 10,
        border: '1px solid #DDD',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <h2>장소 번호 : {place.id}</h2>
        <div
          style={{
            marginLeft: 10,
          }}
        >
          <label
            onClick={() => {
              window.open(`/place/${place.id}`)
            }}
            className='btn btn-sm btn-outline-primary'
          >
            상세보기
          </label>
        </div>
      </div>

      <div
        style={{
          marginTop: 10,
          paddingBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid #EEE',
        }}
      >
        <div
          style={{
            width: 150,
            height: 150,
            position: 'relative',
          }}
        >
          <img
            src={place.image_url}
            alt=''
            style={{
              width: 150,
              height: 150,
              objectFit: 'cover',
              borderRadius: 10,
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: 30,
              height: 30,
              borderTopLeftRadius: 8,
              bottom: 0,
              right: 0,
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h4
              style={{
                margin: 0,
              }}
            >
              {place.grade}
            </h4>
          </div>
        </div>

        <div
          style={{
            marginLeft: 10,
          }}
        >
          <LinesEllipsis
            text={`${place.title}`}
            maxLine='2'
            ellipsis='...'
            trimRight
            basedOn='letters'
            style={{
              fontSize: 20,
              fontWeight: 'bold',
            }}
          />

          <TextSub
            title={'상태'}
            subTitle={
              place.status === 'publish'
                ? '게시 중'
                : place.status === 'hide' && place.inactive === 0
                ? '숨김'
                : place.status === 'hide' && place.inactive === 1
                ? '미활동 숨김'
                : place.status === 'unpublish'
                ? '관리자 숨김'
                : place.status === 'leave'
                ? '탈퇴'
                : place.status === 'temporary'
                ? '작성중'
                : place.status === 'trash'
                ? '삭제'
                : place.status
            }
          />
          <TextSub
            title={'금액'}
            subTitle={place.price_type === 0 ? place.price_guest_vat.toLocaleString('en') : `${place.price_min.toLocaleString('en')} ~ ${place.price_max.toLocaleString('en')}`}
            marginTop={1}
          />
          <TextSub title={'결제 방식'} subTitle={place.payments_type === 0 ? '승인 결제' : '바로 결제'} marginTop={1} />
          <TextSub title={'촬영 건수'} subTitle={`${place.shoot_count.toLocaleString('en')} (${place.booking_count.toLocaleString('en')})`} marginTop={1} />
          <TextSub title={'관리자 메모'} subTitle={place.admin_comment} marginTop={0} />
        </div>
      </div>

      {place.host !== undefined && place.host !== null && (
        <div
          style={{
            marginTop: 10,
            paddingBottom: 10,
            borderBottom: '1px solid #EEE',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                width: 150,
                height: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={place.host.image_url}
                alt=''
                style={{
                  width: 100,
                  height: 100,
                  objectFit: 'cover',
                  borderRadius: 100,
                }}
              />
            </div>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <h2>{place.host.user_id}. 호스트</h2>
              <TextSub title={'전화번호'} subTitle={place.host.phone} marginTop={0} />
              <TextSub title={'닉네임'} subTitle={`${place.host.name} (${place.host.real_name})`} marginTop={-10} />

              {/*{*/}
              {/*  place.host.host_statistic !== undefined && place.host.host_statistic !== null &&*/}
              {/*  <>*/}
              {/*    <TextSub title={'총 촬영 횟수'} subTitle={`${place.host.host_statistic.host_booking_completed_count} / ${place.host.host_statistic.host_booking_completed_add_count}`} marginTop={-10} />*/}
              {/*    <TextSub title={'예약 승인율'}*/}
              {/*             subTitle={`${place.host.host_statistic.host_proceeding_booking > 0 && place.host.host_statistic.host_booking_count > 0 ? Math.floor(place.host.host_statistic.host_proceeding_booking / place.host.host_statistic.host_booking_count * 100) : 0}% (${place.host.host_statistic.host_proceeding_booking} / ${place.host.host_statistic.host_booking_count})`}*/}
              {/*             marginTop={-10} />*/}
              {/*    <TextSub title={'촬영 진행률'}*/}
              {/*             subTitle={`${place.host.host_statistic.host_booking_completed_count > 0 && place.host.host_statistic.host_proceeding_booking > 0 ? Math.floor(place.host.host_statistic.host_booking_completed_count / place.host.host_statistic.host_proceeding_booking * 100) : 0}% (${place.host.host_statistic.host_booking_completed_count} / ${place.host.host_statistic.host_proceeding_booking})`}*/}
              {/*             marginTop={-10} />*/}
              {/*  </>*/}
              {/*}*/}

              {/*{*/}
              {/*  place.host.host_memo !== undefined && place.host.host_memo.length > 0 &&*/}
              {/*  <TextSub title={'메모'} subTitle={place.host.host_memo} marginTop={-10} />*/}
              {/*}*/}
            </div>
          </div>
          <div>
            <SwitchButton id={place.host.user_id}>Switch</SwitchButton>
            <label
              style={{marginLeft: 10}}
              onClick={() => {
                window.open(`/user/${place.host.user_id}`)
              }}
              className='btn btn-sm btn-primary'
            >
              자세히 보기
            </label>
          </div>
        </div>
      )}

      {isButton && (
        <div style={{marginTop: 20}}>
          <label
            onClick={() => {
              window.open(`/place/${place.id}`)
            }}
            className='btn btn-primary'
          >
            보기
          </label>

          <label
            onClick={() => {
              window.open(`/place/${place.id}/edit`)
            }}
            style={{marginLeft: 10}}
            className='btn btn-secondary'
          >
            수정
          </label>

          <label
            onClick={() => {
              window.open(`/place/${place.id}/booking`)
            }}
            style={{marginLeft: 10}}
            className='btn btn-primary'
          >
            예약 생성
          </label>
        </div>
      )}
    </div>
  )
}

export {PlaceItem}
