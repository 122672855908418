import {ApiResponse} from './Response'
import axios from 'axios'
import {toast} from 'react-toastify'

export function apiGetGreyKeywords(): Promise<ApiResponse> {

  return axios.get(`${process.env.REACT_APP_API2_URL}am/setting/grey/keywords`).then((res): Promise<ApiResponse> => {
    if (res.data.error === '') {
      return res.data
    } else if (res.data.error !== '') {
      toast.error(res.data.error)
    }
    throw new Error('Server Error')
  }).catch(error => {
    throw error
  })
}
