import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'

export function AdvertiseRefund() {
  const {adv_cancel_group_id} = useParams<{adv_cancel_group_id: string}>()
  const [refundBankUserName, setRefundBankUserName] = useState('')
  const [refundBankName, setRefundBankName] = useState('')
  const [refundBankAccount, setRefundBankAccount] = useState('')

  useEffect(() => {
    getAdvCancelPlaces()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getAdvCancelPlaces = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/${adv_cancel_group_id}/refund`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        console.log(error)
        if (error !== '') {
          alert(error)
          window.location.replace('/advertise/cancel')
        } else {
          console.log(result)
          setRefundBankUserName(result.refund_bank_user_name)
          setRefundBankName(result.refund_bank_name)
          setRefundBankAccount(result.refund_bank_account)
          // this.forceUpdate()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const putRefundInfo = () => {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/adv/${adv_cancel_group_id}/refund`, {refundBankUserName, refundBankName, refundBankAccount})
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
          alert(error)
          window.location.replace('/advertise/cancel')
        } else {
          alert('저장되었습니다.')
          // this.forceUpdate()
          window.history.back()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>환불 계좌 정보</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{width: '100%'}}>
          <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
            <div style={{paddingTop: '15px'}}>
              <table style={{fontSize: '15px', width: '100%'}}>
                <colgroup>
                  <col style={{width: '10%'}} />
                  <col style={{width: 'auto'}} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>결제 수단</td>
                    <td>가상계좌</td>
                  </tr>
                  <tr>
                    <td>예금주</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='예금주 명을 입력하세요.'
                        maxLength={15}
                        value={refundBankUserName}
                        onChange={(e) => {
                          setRefundBankUserName(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>은행명</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='은행명을 입력하세요.'
                        maxLength={15}
                        value={refundBankName}
                        onChange={(e) => {
                          setRefundBankName(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>계좌번호</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='환불 받을 계좌번호를 입력하세요.'
                        maxLength={20}
                        value={refundBankAccount}
                        onChange={(e) => {
                          setRefundBankAccount(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
          <label
            onClick={() => {
              putRefundInfo()
            }}
            style={{marginTop: 10}}
            className='btn btn-sm btn-primary'
          >
            저장하기
          </label>
        </div>
      </div>
    </>
  )
}
