import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {isMobile} from 'react-device-detect'
import moment from 'moment'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import DatePicker, {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'

export function PlaceCountHistoryH() {
  const [place, setPlace] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<Date | null>(new Date())

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    registerLocale('ko', ko)
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSpPlaceCountHistory = useCallback(() => {
    setPlace([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/h/place/count/history?date=${moment(date).format('YYYYMM')}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlace(result)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getSpPlaceCountHistory()
  }, [getSpPlaceCountHistory, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [
      {header: '일자', key: 'id', width: 10},
      {header: '가정집', key: 'id1', width: 10},
      {header: '자연광스튜디오', key: 'id2', width: 10},
      {header: '호리존스튜디오', key: 'id3', width: 10},
      {header: '컨셉스튜디오', key: 'id4', width: 10},
      {header: '셀프스튜디오', key: 'id5', width: 10},
      {header: '연습실', key: 'id6', width: 10},
      {header: '파티룸', key: 'id7', width: 10},
      {header: '상업공간', key: 'id8', width: 10},
      {header: '특수공간', key: 'id9', width: 10},
      {header: '야외・대형', key: 'id10', width: 10}
    ]

    place.forEach((item: any) => {
      worksheet.addRow({
        id: item.day,
        id1: item.c1,
        id2: item.c2,
        id3: item.c3,
        id4: item.c4,
        id5: item.c5,
        id6: item.c6,
        id7: item.c7,
        id8: item.c8,
        id9: item.c9,
        id10: item.c10
      })
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `자사_장소_개수_${moment(date).format('YYYY-MM')}.xlsx`)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>자사 일자별 장소 개수</PageTitle>
        <div style={{
          padding: isMobile ? 4 : 20,
          borderRadius: isMobile ? 4 : 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
          <div className="tab-content w-100" id="myTabContent">
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} >
              <div style={{ marginRight: 20 }} >
                <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM' showMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
              </div>

              <label
                onClick={() => {
                  download()
                }}
                className='btn btn-sm btn-secondary'
              >
                다운로드
              </label>
            </div>
            <div className="table-responsive">
              <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
                <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
                <tr>
                  <th scope="col" className="min-w-30px"></th>
                  <th scope="col" className="min-w-100px">일자</th>
                  <th scope="col" className="min-w-100px">가정집</th>
                  <th scope="col" className="min-w-100px">자연광스튜디오</th>
                  <th scope="col" className="min-w-100px">호리존스튜디오</th>
                  <th scope="col" className="min-w-100px">컨셉스튜디오</th>
                  <th scope="col" className="min-w-100px">셀프스튜디오</th>
                  <th scope="col" className="min-w-100px">연습실</th>
                  <th scope="col" className="min-w-100px">파티룸</th>
                  <th scope="col" className="min-w-100px">상업공간</th>
                  <th scope="col" className="min-w-100px">특수공간</th>
                  <th scope="col" className="min-w-100px">야외・대형</th>
                </tr>
                </thead>
                <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
                {
                  loading ?
                    <tr style={{height: 98}}>
                      <td colSpan={44}>
                        <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                        </div>
                      </td>
                    </tr>
                    :
                    place !== null && place.map((item: any, index: number) => {
                      return (
                        <tr key={`${index}`} style={{height: 98}}>
                          <td></td>
                          <td>{item.day}</td>
                          <td>{item.c1.toLocaleString('en')}</td>
                          <td>{item.c2.toLocaleString('en')}</td>
                          <td>{item.c3.toLocaleString('en')}</td>
                          <td>{item.c4.toLocaleString('en')}</td>
                          <td>{item.c5.toLocaleString('en')}</td>
                          <td>{item.c6.toLocaleString('en')}</td>
                          <td>{item.c7.toLocaleString('en')}</td>
                          <td>{item.c8.toLocaleString('en')}</td>
                          <td>{item.c9.toLocaleString('en')}</td>
                          <td>{item.c10.toLocaleString('en')}</td>
                        </tr>
                      )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </>
  )
}
