import {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'

const SwitchButton = ({id = 0, children = ''}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getSwitch = () => {
      axios
        .get(`${process.env.REACT_APP_API2_URL}user/${id}/switch/uuid`)
        .then(({data: {error, result}}) => {
          if (error === '') {
            window.open(`${process.env.REACT_APP_BETA_WEB_URL}switch/${result.id}/${result.uuid}?url=/`)
            console.log(result)
          } else {
            toast.error(error)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
      // console.log(`${process.env.REACT_APP_BETA_WEB_URL}am/switch`)
    }

    if (loading) {
      getSwitch()
    }
  }, [loading, id])

  return (
    <button
      className='btn btn-sm btn-primary'
      type='button'
      disabled={loading}
      onClick={() => {
        setLoading(true)
      }}
    >
      {loading ? '실행중...' : children}
    </button>
  )
}

export default SwitchButton
