import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {toast} from 'react-toastify'

export function MarketingBtoBGuestBannerEditor() {
  const {id} = useParams<{id: string}>()
  const [info, setInfo] = useState<any>([])

  useEffect(() => {
    if (Number(id) > 0) {
      getBToBBanners()
    }
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getBToBBanners = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/btob/banner/detail/${id}`)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          setInfo(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const update = () => {
    console.log(info)
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/btob/banner/${id}`, {
        active: info.active,
        type: 'G',
        adv_id: info.adv_id,
        search_image_url: info.search_image_url,
        image_url: info.image_url,
        m_image_url: info.m_image_url,
        link_url: info.link_url,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          if (Number(id) === 0) {
            window.location.href = `/marketing/btob/banner/guest`
          } else {
            window.location.reload()
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleFileInputSearchImage = async (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    try {
      const {
        data: {error, result},
      } = await axios.post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      console.log(error, result)
      if (error === '') {
        console.log(result)
        const temp = Object.assign({}, info)
        temp.search_image_url = result.image_url
        setInfo(temp)
      }
    } catch (error_1) {
      console.log(error_1)
    }
  }


  const handleFileInputMainImage = async (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    try {
      const {
        data: {error, result},
      } = await axios.post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      console.log(error, result)
      if (error === '') {
        console.log(result)
        const temp = Object.assign({}, info)
        temp.image_url = result.image_url
        setInfo(temp)
      }
    } catch (error_1) {
      console.log(error_1)
    }
  }

  const handleFileInputMobileMainImage = async (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    console.log(formData.get('file'))
    try {
      const {
        data: {error, result},
      } = await axios.post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      console.log(error, result)
      if (error === '') {
        const temp = Object.assign({}, info)
        temp.m_image_url = result.image_url
        setInfo(temp)
      } else {
        toast.error(error)
      }
    } catch (error_1) {
      console.log(error_1)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>게스트향 배너</PageTitle>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>광고 연결하기</h1>
            </div>
            <div className='card-body'>
              <TextSub2 title={'광고 ID'} subTitle={''} />
              <input
                type='number'
                className='form-control'
                value={info.adv_id}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.adv_id = e.target.value
                  setInfo(temp)
                }}
              />
            </div>
          </div>
        </div>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>배너 옵션</h1>
            </div>
            <div className='card-body'>
              <TextSub2 title={'노출'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.active}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.active = event.target.checked ? 1 : 0
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'PC/Tablet 검색 모달 이미지'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control mb-5'
                placeholder=''
                value={info.search_image_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.search_image_url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
              <input type='file' name='file' onChange={(e) => handleFileInputSearchImage(e)} />

              <div style={{position: 'relative', width: '100%', aspectRatio: `${701 / 122}`, marginTop: 20}}>
                {info.search_image_url !== '' && <img src={info.search_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />}
              </div>

              <div className='separator my-10' />
              <TextSub2 title={'PC/Tablet 예약 내역 이미지'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control mb-5'
                placeholder=''
                value={info.image_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.image_url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
              <input type='file' name='file' onChange={(e) => handleFileInputMainImage(e)} />

              <div style={{position: 'relative', width: '100%', aspectRatio: `${701 / 195}`, marginTop: 20}}>
                {info.image_url !== '' && <img src={info.image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />}
              </div>

              <div className='separator my-10' />
              <TextSub2 title={'Mobile/APP 메인 이미지'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control mb-5'
                placeholder=''
                value={info.m_image_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.m_image_url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
              <input type='file' name='file' onChange={(e) => handleFileInputMobileMainImage(e)} />

              <div style={{position: 'relative', width: '50%', aspectRatio: `${335 / 104}`, marginTop: 20}}>
                {info.m_image_url !== '' && <img src={info.m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />}
              </div>

              <div className='separator my-10' />
              <TextSub2 title={'링크'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.link_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.link_url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
            </div>
          </div>

          <div
            style={{
              width: '100%',
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: 10,
              }}
            >
              <label
                onClick={() => {
                  update()
                }}
                className='btn btn-primary'
              >
                {id !== '0' ? '수정하기' : '저장하기'}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
