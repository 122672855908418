import React from 'react'
import {Button, Dropdown, Modal, Tab, Tabs} from 'react-bootstrap'
import axios from 'axios'
import MainCategoryPlacesCard from '../../components/MainCategoryPlacesCard'
import {PageTitle} from '../../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import ImagePath from '../../../commons/ImagePath'
import Dropzone from 'react-dropzone'

export default class MarketingCurationEditor extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.curation_id = props.match.params.curation_id
    this.state = {
      key: '',
      main_categories: [],
      modalShow: false,
      modalPlaceImageShow: false,
      place: null,
      category: 0,
      categoryInfo: null,
    }

    this.getCuration()
  }

  // 큐레이션 정보 가져오기
  getCuration = () => {
    const {curation_id} = this
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/curation/category/${curation_id}`)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          this.setState({
            categoryInfo: result,
          })
          this.getPlaces(curation_id)
          // this.forceUpdate()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateCategoryOrder = () => {
    const order = prompt('정렬값을 입력하세요.')
    const {categoryInfo} = this.state
    if (categoryInfo.order !== order) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
          order,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            this.getCuration()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updateCategoryShow = (category, show) => {
    if (category.show !== show) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
          show,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            category.show = result.show
            this.forceUpdate()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updateCategoryTitle = () => {
    const title = prompt('제목을 입력하세요.')
    if (title === null) {
      return false
    }
    const {categoryInfo} = this.state
    if (categoryInfo.title !== title) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
          title,
        })
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            this.getCuration()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updateCategorySubTitle = () => {
    const title = prompt('부제목을 입력하세요.')
    const {categoryInfo} = this.state
    if (categoryInfo.sub_title !== title) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
          sub_title: title,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            this.getCuration()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updateCategoryComment = () => {
    const title = prompt('comment 입력하세요.')
    const {categoryInfo} = this.state
    if (categoryInfo.comment !== title) {
      if (title.length > 50) {
        alert('50자 이하로 입력해 주세요')
        return
      }
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
          comment: title,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            this.getCuration()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updateCategorySubComment = () => {
    const title = prompt('sub_comment 입력하세요.')
    const {categoryInfo} = this.state
    if (categoryInfo.comment !== title) {
      if (title.length > 100) {
        alert('100자 이하로 입력해 주세요')
        return
      }
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
          sub_comment: title,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            this.getCuration()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updateSubCategoryOrder = (category, sub_category) => {
    const order = prompt('정렬값을 입력하세요.')
    if (sub_category.order !== order) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${sub_category.id}`, {order})
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            sub_category.order = result.order
            if (category.subs.length > 1) {
              category.subs.splice(category.subs.indexOf(sub_category), 1)
              let i = 0
              category.subs.forEach((value, index) => {
                if (value.order < sub_category.order) {
                  i = index + 1
                }
              })
              category.subs.splice(i, 0, sub_category)
            }
            console.log(category.subs)
            this.forceUpdate()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  addSubCategory = (category) => {
    const title = prompt(`${category.title}의 서브 카테고리 제목을 입력하세요.`)
    if (title.trim() !== '') {
      axios
        .post(`${process.env.REACT_APP_API_URL}main/category`, {
          title,
          main_category_id: category.id,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            category.subs.splice(0, 0, result)
            this.forceUpdate()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  addPlace = (place) => {
    console.log('add', place)
    axios
      .post(`${process.env.REACT_APP_API_URL}main/category/${this.state.categoryInfo.id}/add`, {place_id: place.id})
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          this.getPlaces(this.state.categoryInfo.id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  addPlaceIds = (category) => {
    const place_ids = prompt('장소들 아이디를 입력하세요. ex) 1234,123,23456,235')
    if (place_ids !== null && place_ids.trim() !== '') {
      console.log('add', place_ids)
      axios
        .post(`${process.env.REACT_APP_API_URL}main/category/${category.id}/add`, {place_ids})
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            this.getPlaces(category.id)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  removePlace = (category, place) => {
    if (window.confirm(`${place.id}. ${place.title} 장소를 삭제하시겠습니까?`)) {
      console.log('delete', place)
      axios
        .delete(`${process.env.REACT_APP_API_URL}main/category/${category.id}/place/${place.id}`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            category.places.splice(category.places.indexOf(place), 1)
            this.forceUpdate()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  updatePlace = (place, image_id) => {
    console.log('update', {place, image_id})
    axios
      .put(`${process.env.REACT_APP_API_URL}main/category/${place.main_category_id}/place/${place.id}`, {image_id})
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          place.image_id = result.image_id
          place.image_url = result.image_url
          this.setState({
            modalPlaceImageShow: false,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getPlaces(category_id) {
    const {categoryInfo} = this.state
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/curation/category/${category_id}/places`)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          this.setState({
            categoryInfo: {
              ...categoryInfo,
              places: result,
            },
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateCategoryBackgroundColor = (category) => {
    const title = prompt('배경색을 입력하세요.(#123456)')
    if (category.background_color !== title) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
          background_color: title,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            category.background_color = result.background_color
            this.forceUpdate()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  addMobileImage = () => {
    this.fileMobile.click()
  }

  handleFileMobile = async (e) => {
    const {categoryInfo} = this.state
    const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  console.log(result)
                  axios
                    .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
                      m_image_id: result.id,
                      m_image_path: result.image_path,
                    })
                    .then(({data: {error, result}}) => {
                      console.log(result)
                      this.getCuration()
                      if (error !== '') {
                      } else {
                        // this.getCuration()
                        // this.setState({
                        //   categoryInfo: {
                        //     ...categoryInfo,
                        //     m_image_id: result.id,
                        //     m_image_path: result.image_path,
                        //   },
                        // })
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })

                  // main_vue.completeImage(file, response.data.result)
                } else {
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        // main_vue.failImage(file)
      }
    })
  }

  addPCImage = () => {
    this.filePC.click()
  }

  handleFilePC = async (e) => {
    const {categoryInfo} = this.state
    const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  console.log(result)
                  axios
                    .put(`${process.env.REACT_APP_API2_URL}am/main/category/${categoryInfo.id}`, {
                      w_image_id: result.id,
                      w_image_path: result.image_path,
                    })
                    .then(({data: {error, result}}) => {
                      console.log(result)
                      this.getCuration()
                      if (error !== '') {
                      } else {
                        // this.getCuration()
                        // this.setState({
                        //   categoryInfo: {
                        //     ...categoryInfo,
                        //     m_image_id: result.id,
                        //     m_image_path: result.image_path,
                        //   },
                        // })
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })

                  // main_vue.completeImage(file, response.data.result)
                } else {
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        // main_vue.failImage(file)
      }
    })
  }

  addImage = () => {
    this.file.click()
  }

  handleFileInput = async (e) => {
    const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  console.log(result)
                  axios
                    .put(`${process.env.REACT_APP_API_URL}admin/main/category/${this.state.categoryInfo.id}`, {
                      image_id: result.id,
                      image_path: result.image_path,
                    })
                    .then(({data: {error, result}}) => {
                      console.log(result)
                      if (error !== '') {
                      } else {
                        this.getCuration()
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })

                  // main_vue.completeImage(file, response.data.result)
                } else {
                  console.log(error)
                  // main_vue.failImage(file)
                }
              })
              .catch((error) => {
                console.log(error)
                // main_vue.failImage(file)
              })
          })
          .catch((error) => {
            console.log(error)
            // main_vue.failImage(file)
          })
      } else {
        // main_vue.failImage(file)
      }
    })
  }

  handleItemFileInput = async (file, place) => {
    // const file = e.target.files[0]
    axios.get(`${process.env.REACT_APP_API2_URL}upload/admin_image`).then(({data: {error, result}}) => {
      if (error === '') {
        const {path, url} = result
        axios
          .put(url, file, {
            headers: {
              'Content-Type': file?.type,
            },
          })
          .then((response) => {
            console.log(path)
            axios
              .post(`${process.env.REACT_APP_API2_URL}upload`, {
                path,
                type: 'admin_image',
              })
              .then(({data: {error, result}}) => {
                if (error === '') {
                  let image_id = result.id
                  axios
                    .put(`${process.env.REACT_APP_API_URL}main/category/${place.main_category_id}/place/${place.id}`, {image_id})
                    .then(({data: {error, result}}) => {
                      console.log(error, result)
                      if (error !== '') {
                      } else {
                        place.image_id = result.image_id
                        place.image_url = result.image_url
                        this.setState({
                          modalPlaceImageShow: false,
                        })
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                } else {
                  console.log(error)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        console.log(error)
      }
    })
  }

  render() {
    const {categoryInfo} = this.state
    const modalClose = () => this.setState({modalShow: false})
    const modalPlaceImageClose = () => this.setState({modalPlaceImageShow: false})
    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <PageTitle breadcrumbs={[]}>큐레이션 상세</PageTitle>
        <div className='row'>
          <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
            <PlacesModal show={this.state.modalShow} category={this.state.categoryInfo} onAdd={this.addPlace} onHide={modalClose} />

            <PlaceImagesModal
              show={this.state.modalPlaceImageShow}
              place={this.state.place}
              updatePlace={this.updatePlace}
              onHide={modalPlaceImageClose}
              imageUpload={(e) => this.handleItemFileInput(e, this.state.place)}
            />

            {categoryInfo && (
              <div style={{padding: 20, position: 'relative'}}>
                <div style={{width: '100%'}}>
                  <div style={{marginTop: 20}}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{marginLeft: 20}}>
                        <Dropdown>
                          <Dropdown.Toggle variant='secondary' id='dropdown-basic' style={{fontSize: 24}}>
                            {categoryInfo.title}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {categoryInfo.subs.length === 0 && (
                              <Dropdown.Item
                                href='#'
                                onClick={() =>
                                  this.setState({
                                    // category,
                                    modalShow: true,
                                  })
                                }
                              >
                                장소 추가
                              </Dropdown.Item>
                            )}
                            {categoryInfo.subs.length === 0 && (
                              <Dropdown.Item href='#' onClick={() => this.addPlaceIds(categoryInfo)}>
                                장소들 추가
                              </Dropdown.Item>
                            )}
                            {categoryInfo.subs.length === 0 && (
                              <Dropdown.Item href='#' onClick={() => this.addImage(categoryInfo)}>
                                이미지 추가
                              </Dropdown.Item>
                            )}
                            {categoryInfo.subs.length === 0 && (
                              <Dropdown.Item href='#' onClick={() => this.updateCategoryBackgroundColor(categoryInfo)}>
                                배경색 변경
                              </Dropdown.Item>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item href='#' onClick={() => this.addSubCategory(categoryInfo)}>
                              서브 카테고리 추가
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <br />
                    <div
                      style={{
                        margin: 4,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                    >
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_WEB_URL}promotion/${categoryInfo.id}`)
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        {categoryInfo.title} 웹에서 보기
                      </label>
                    </div>
                    <p style={{marginTop: 10, fontSize: 20}}>
                      순서 : {categoryInfo.order}{' '}
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          this.updateCategoryOrder()
                        }}
                        className='btn btn-sm btn-secondary'
                      >
                        변경
                      </label>
                    </p>
                    <p style={{marginTop: 4, fontSize: 20}}>장소 : {categoryInfo.count}</p>
                    <p style={{marginTop: 4, fontSize: 20}}>
                      노출 : {categoryInfo.show}{' '}
                      {categoryInfo.show === 1 && (
                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            this.updateCategoryShow(categoryInfo, 0)
                          }}
                          className='btn btn-sm btn-secondary'
                        >
                          숨기기
                        </label>
                      )}
                      {categoryInfo.show === 0 && (
                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            this.updateCategoryShow(categoryInfo, 1)
                          }}
                          className='btn btn-sm btn-secondary'
                        >
                          노출
                        </label>
                      )}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 40,
                  }}
                >
                  <p style={{marginTop: 16, fontSize: 16}}>
                    title : {categoryInfo.title}{' '}
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        this.updateCategoryTitle()
                      }}
                      className='btn btn-sm btn-secondary'
                    >
                      변경
                    </label>
                  </p>
                  <p style={{marginTop: 4, fontSize: 16}}>
                    sub_title : {categoryInfo.sub_title}{' '}
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        this.updateCategorySubTitle()
                      }}
                      className='btn btn-sm btn-secondary'
                    >
                      변경
                    </label>
                  </p>
                  <p style={{marginTop: 4, fontSize: 16}}>
                    comment : {categoryInfo.comment}{' '}
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        this.updateCategoryComment()
                      }}
                      className='btn btn-sm btn-secondary'
                    >
                      변경
                    </label>
                  </p>
                  <p style={{marginTop: 4, marginBottom: 30, fontSize: 16}}>
                    sub_comment : {categoryInfo.sub_comment}{' '}
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        this.updateCategorySubComment()
                      }}
                      className='btn btn-sm btn-secondary'
                    >
                      변경
                    </label>
                  </p>

                  <div className={'border'} style={{padding: 10}}>
                    <h2>메인 화면 이미지</h2>
                    <br />
                    <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                      <div style={{flex: 3}}>
                        <h3>
                          Mobile{' '}
                          <label
                            style={{marginLeft: 10}}
                            onClick={() => {
                              this.addMobileImage()
                            }}
                            className='btn btn-sm btn-secondary'
                          >
                            업로드
                          </label>
                          <div
                            style={{
                              visibility: 'hidden',
                            }}
                          >
                            <input
                              type='file'
                              name='file'
                              ref={(input) => {
                                this.fileMobile = input
                              }}
                              onChange={(e) => this.handleFileMobile(e)}
                            />
                          </div>
                        </h3>
                        <div style={{position: 'relative', width: '100%', aspectRatio: `${77.75 / 132}`, marginTop: 20}}>
                          <img src={categoryInfo.m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                        </div>
                      </div>

                      <div style={{flex: 7}}>
                        <h3>
                          PC{' '}
                          <label
                            style={{marginLeft: 10}}
                            onClick={() => {
                              this.addPCImage()
                            }}
                            className='btn btn-sm btn-secondary'
                          >
                            업로드
                          </label>
                          <div
                            style={{
                              visibility: 'hidden',
                            }}
                          >
                            <input
                              type='file'
                              name='file'
                              ref={(input) => {
                                this.filePC = input
                              }}
                              onChange={(e) => this.handleFilePC(e)}
                            />
                          </div>
                        </h3>
                        <div style={{position: 'relative', width: '100%', aspectRatio: `${275 / 80}`, marginTop: 20}}>
                          <img src={categoryInfo.w_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />

                  <div className={'border'} style={{padding: 10}}>
                    <h2>
                      상세 화면 이미지{' '}
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          this.addImage()
                        }}
                        className='btn btn-sm btn-secondary'
                      >
                        업로드
                      </label>
                      <div
                        style={{
                          visibility: 'hidden',
                        }}
                      >
                        <input
                          type='file'
                          name='file'
                          ref={(input) => {
                            this.file = input
                          }}
                          onChange={(e) => this.handleFileInput(e)}
                        />
                      </div>
                    </h2>
                    <br />

                    <h3>Mobile</h3>
                    <div
                      style={{
                        width: 375,
                        marginBottom: 30,
                        border: '1px solid #DDD',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 44,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 500,
                            fontSize: 16,
                            color: '#000',
                          }}
                        >
                          {categoryInfo.title}
                        </p>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          paddingTop: 30,
                          paddingBottom: 20,
                          paddingRight: 20,
                          paddingLeft: 20,
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            fontSize: 24,
                            color: '#1B1D1F',
                          }}
                        >
                          {categoryInfo.comment}
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginTop: 6,
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#454B50',
                          }}
                        >
                          {categoryInfo.sub_comment}
                        </p>
                      </div>
                      {categoryInfo.image_path !== '' && (
                        <img
                          src={ImagePath.get(categoryInfo.image_path, '2000x2000', 'inside')}
                          style={{
                            width: 375,
                            height: 250,
                            objectFit: 'cover',
                          }}
                          alt=''
                        />
                      )}
                    </div>
                    <h3>PC</h3>
                    <div
                      style={{
                        width: '100%',
                        height: 300,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        border: '1px solid #DDD',
                      }}
                    >
                      {categoryInfo.image_path !== '' && (
                        <img
                          src={ImagePath.get(categoryInfo.image_path, '2000x2000', 'inside')}
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            zIndex: 0,
                          }}
                          alt=''
                        />
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          width: 500,
                          height: '100%',
                          backgroundColor: categoryInfo.background_color,
                        }}
                      />
                      <div
                        style={{
                          paddingRight: 30,
                          paddingLeft: 30,
                          zIndex: 1,
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            fontSize: 32,
                            color: '#FFF',
                          }}
                        >
                          {categoryInfo.comment}
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginTop: 6,
                            fontWeight: 400,
                            fontSize: 18,
                            color: '#FFF',
                          }}
                        >
                          {categoryInfo.sub_comment}
                        </p>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <h2>장소 리스트</h2>
                  <div
                    className={'border'}
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {categoryInfo.places.map((place) => {
                      return (
                        <MainCategoryPlacesCard
                          key={place.id.toString()}
                          category={categoryInfo}
                          place={place}
                          removePlace={this.removePlace}
                          showImages={() => {
                            this.setState({
                              place,
                              modalPlaceImageShow: true,
                            })
                          }}
                        />
                      )
                    })}
                  </div>
                </div>

                <br />

                {categoryInfo.subs.length > 0 && (
                  <div className={'border'} style={{padding: 10}}>
                    <h2>서브 카테고리</h2>

                    {categoryInfo.subs.map((category, index) => {
                      return (
                        <div className='col-12' key={index.toString()}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {category.id}. {category.title}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <div>
                                <p style={{fontSize: 20}}>{`순서 : ${category.order}, 장소 : ${category.count}개, 노출 : ${category.show === 1 ? 'Y' : 'N'}`}</p>

                                <div className={'border'} style={{padding: 10}}>
                                  <h2>메인 화면 이미지</h2>
                                  <br />
                                  <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                                    <div style={{flex: 3}}>
                                      <h3>Mobile</h3>
                                      <div style={{position: 'relative', width: '100%', aspectRatio: `${77.75 / 132}`, marginTop: 20}}>
                                        <img src={category.m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                                      </div>
                                    </div>

                                    <div style={{flex: 7}}>
                                      <h3>PC</h3>
                                      <div style={{position: 'relative', width: '100%', aspectRatio: `${275 / 80}`, marginTop: 20}}>
                                        <img src={category.w_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <br />
                                <br />

                                <div className={'border'} style={{padding: 10}}>
                                  <h2>상세 화면 이미지</h2>
                                  <br />

                                  <h3>Mobile</h3>
                                  <div
                                    style={{
                                      width: 375,
                                      marginBottom: 30,
                                      border: '1px solid #DDD',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        height: 44,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontWeight: 500,
                                          fontSize: 16,
                                          color: '#000',
                                        }}
                                      >
                                        {category.title}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: '100%',
                                        paddingTop: 30,
                                        paddingBottom: 20,
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontWeight: 700,
                                          fontSize: 24,
                                          color: '#1B1D1F',
                                        }}
                                      >
                                        {category.comment}
                                      </p>
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          marginTop: 6,
                                          fontWeight: 400,
                                          fontSize: 16,
                                          color: '#454B50',
                                        }}
                                      >
                                        {category.sub_comment}
                                      </p>
                                    </div>
                                    {category.image_path !== '' && (
                                      <img
                                        src={ImagePath.get(category.image_path, '2000x2000', 'inside')}
                                        style={{
                                          width: 375,
                                          height: 250,
                                          objectFit: 'cover',
                                        }}
                                        alt=''
                                      />
                                    )}
                                  </div>
                                  <h3>PC</h3>
                                  <div
                                    style={{
                                      width: '100%',
                                      height: 300,
                                      position: 'relative',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      border: '1px solid #DDD',
                                    }}
                                  >
                                    {category.image_path !== '' && (
                                      <img
                                        src={ImagePath.get(category.image_path, '2000x2000', 'inside')}
                                        style={{
                                          position: 'absolute',
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover',
                                          zIndex: 0,
                                        }}
                                        alt=''
                                      />
                                    )}
                                    <div
                                      style={{
                                        position: 'absolute',
                                        width: 500,
                                        height: '100%',
                                        backgroundColor: category.background_color,
                                      }}
                                    />
                                    <div
                                      style={{
                                        paddingRight: 30,
                                        paddingLeft: 30,
                                        zIndex: 1,
                                      }}
                                    >
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontWeight: 700,
                                          fontSize: 32,
                                          color: '#FFF',
                                        }}
                                      >
                                        {category.comment}
                                      </p>
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          marginTop: 6,
                                          fontWeight: 400,
                                          fontSize: 18,
                                          color: '#FFF',
                                        }}
                                      >
                                        {category.sub_comment}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-footer'>
                              <label
                                onClick={() => {
                                  window.open(`${process.env.REACT_APP_WEB_URL}promotion/${category.id}`)
                                }}
                                className='btn btn-primary'
                              >
                                보기
                              </label>
                              <label
                                style={{marginLeft: 10}}
                                onClick={() => {
                                  window.location.href = `/marketing/curation/${category.id}`
                                }}
                                className='btn btn-primary'
                              >
                                수정
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class PlacesModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      keyword: '',
      place: null,
      places: [],
      currentPage: 0,
      perPage: 0,
      total: 0,
    }
  }

  handlePageChange(page) {
    this.getPlaces(page)
  }

  getPlaces(page = 1) {
    console.log(this.state.keyword)
    axios
      .post(`${process.env.REACT_APP_API_URL}search/place`, {
        page,
        keyword: this.state.keyword,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          this.setState({
            place: result,
            places: result.data,
            currentPage: result.current_page,
            perPage: result.per_page,
            total: result.total,
          })
          window.scrollTo(0, 0)
          // this.forceUpdate()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>장소 리스트</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <input
              type='text'
              className='form-control'
              placeholder='장소 검색어'
              value={this.state.keyword}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                this.setState({
                  keyword: e.target.value.toLowerCase().trim(),
                })
              }}
            />
            <label
              onClick={() => {
                this.getPlaces(1)
              }}
              style={{width: 100}}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>
          <div className={'row'}>
            {this.state.places.map((item, index) => {
              return (
                <div className='col-lg-3' key={index.toString()}>
                  <div className='card card-custom card-stretch shadow mb-5'>
                    <div className='card-header'>
                      <h1 className='card-title'>
                        {item.id}. {item.title}
                      </h1>
                    </div>
                    <div className='card-body'>
                      <img className='rounded' src={item.image_url} style={{height: 120, width: '100%'}} alt='' />
                      <h3>{item.price.toLocaleString('en')}</h3>
                    </div>
                    <div className='card-footer'>
                      <label
                        onClick={() => {
                          if (window.confirm(`${item.id}. ${item.title} 장소를 추가하시겠습니까?`)) {
                            this.props.onAdd(item)
                          }
                        }}
                        className='btn btn-primary'
                      >
                        추가
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <Pagination
            activePage={this.state.currentPage}
            itemsCountPerPage={this.state.perPage}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={10}
            prevPageText={'‹'}
            nextPageText={'›'}
            onChange={this.handlePageChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

class PlaceImagesModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      uploading: false,
    }
  }

  render() {
    const {uploading} = this.state
    return (
      <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>장소 이미지 리스트</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 300,
                height: 100,
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  console.log(acceptedFiles)
                  this.props.imageUpload(acceptedFiles[0], this.props.place)
                }}
              >
                {({getRootProps, getInputProps}) => (
                  <section
                    className='container'
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#DDD',
                      borderRadius: 4,
                      cursor: 'pointer',
                      padding: 0,
                    }}
                  >
                    <div
                      {...getRootProps({className: 'dropzone'})}
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <input {...getInputProps()} />
                      <p style={{margin: 0}}>이미지를 드래그 하여 넣거나 클릭하세요.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {uploading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                  }}
                >
                  <span className='spinner-border ' />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: 40,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {this.props.place !== null &&
              this.props.place.images.map((image) => {
                return (
                  <div style={{position: 'relative', margin: 10}} key={image.id}>
                    <img src={image.image_url} alt='' style={{width: 220, height: 220, objectFit: 'cover'}} />
                    <Button size='sm' style={{position: 'absolute', right: 4, bottom: 4}} variant='primary' onClick={() => this.props.updatePlace(this.props.place, image.id)}>
                      메인 이미지로 변경
                    </Button>
                  </div>
                )
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
