import React from 'react'
import {isMobile} from 'react-device-detect'

type Props = {
  title: string
  subTitle: string
  color?: string
  popUrl?: string
  marginTop?: number
}

const TextSub: React.FC<Props> = ({title, subTitle, marginTop = 16, color = '', popUrl = ''}) => {
  return <div style={{
    marginTop,
    width: '100%',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'flex-start',
  }}>
    <p style={{
      minWidth: 156,
      fontWeight: 500,
      fontSize: 15,
      color: '#666970',
      whiteSpace: 'pre-wrap',
    }}>
      {title}
    </p>
    <p style={{
      fontWeight: 500,
      fontSize: 15,
      color: color !== '' ? color : '#1B1D1F',
      whiteSpace: 'pre-wrap',
    }}>
      {subTitle}
    </p>
    {
      popUrl !== '' &&
      <p style={{
        minWidth: 40,
        paddingLeft: 10,
        fontWeight: 500,
        fontSize: 12,
        color: '#007BFF',
        cursor: 'pointer',
      }}
         onClick={() => {
           window.open(popUrl)
         }}
      >
        보기
      </p>
    }

  </div>
}

export {TextSub}
