import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import CloseButton from './CloseButton'
import Portal from './Portal'

function Modal2 ({
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  children,
}) {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = (e) => {
    if (onClose) {
      onClose(e)
    }
  }

  useEffect(() => {
    document.body.style.cssText = `position: fixed; width: 100%; top: -${window.scrollY}px; --kt-toolbar-height: 55px; --kt-toolbar-height-tablet-and-mobile: 55px;`
    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = `position: ""; top: ""; --kt-toolbar-height: 55px; --kt-toolbar-height-tablet-and-mobile: 55px;`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  return (
    <Portal elementId='root'>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex={-1}
        visible={visible}
      >
        <ModalInner tabIndex={0} className='modal-inner'>
          {closable && <label className='modal-close' style={{
            position: 'absolute', top: 10, right: 10, cursor: 'pointer'
          }} onClick={close}>
            <i className='bi bi-x fs-2x' />
          </label>}
          {children}
        </ModalInner>
      </ModalWrapper>
    </Portal>
  )
}

Modal2.defaultProps = {
  visible: false,
  closable: true,
  maskClosable: true,
}

Modal2.propTypes = {
  visible: PropTypes.bool,
}

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  max-width: 1000px;
  width: 80vw;
  height: 80vh;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 20px;
`

export default Modal2
