import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import classNames from 'classnames'
import axios from 'axios'
import moment from 'moment'
import {TextSub} from '../../../components/TextSub'
// @ts-ignore
import WindowOpener from 'react-window-opener'
import {toast} from 'react-toastify'
import copy from 'copy-to-clipboard'

export function PayHostCash() {
  const [searchValues, setSearchValues] = useState({
    status: '',
    currentPage: 0,
    refresh: 0,
    search: '',
  })

  const [pays, setPays] = useState<any>([])
  const [cashW0, setCashW0] = useState(0)
  const [cashW1, setCashW1] = useState(0)
  const [cashV, setCashV] = useState(0)
  const [cashE, setCashE] = useState(0)
  const [cashP, setCashP] = useState(0)
  const [cashD, setCashD] = useState(0)
  const [cashC, setCashC] = useState(0)
  const [cashS, setCashS] = useState(0)

  const [total, setTotal] = useState(0)

  const [search1, setSearch1] = useState('')
  const [search2, setSearch2] = useState('')

  const [paidPayHostLoading, setPaidPayHostLoading] = useState(false)

  const [loading, setLoading] = useState(false)

  const [bottom, setBottom] = useState(false)
  const [moreLoading, setMoreLoading] = useState(false)

  const [paidLoading, setPaidLoading] = useState(false)

  const [tempPay, setTempPay] = useState<any | null>(null)
  const [cashChangeLoading, setCashChangeLoading] = useState(false)

  useEffect(() => {
    onTab('')
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setSearchValues({
      ...searchValues,
      status: tabStatus,
      currentPage: 0,
      refresh: searchValues.refresh + 1,
    })
  }

  const onSearch1 = () => {
    if (search1 !== '') {
      setSearchValues({
        ...searchValues,
        search: `cashId=${search1}`,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    } else {
      setSearchValues({
        ...searchValues,
        search: '',
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  const onSearch2 = () => {
    if (search2 !== '') {
      setSearchValues({
        ...searchValues,
        search: `hostUserId=${search2}`,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    } else {
      setSearchValues({
        ...searchValues,
        search: '',
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  useEffect(() => {
    if (bottom) more()
  }, [bottom]) // eslint-disable-line react-hooks/exhaustive-deps

  const more = () => {
    if (pays.length < total) {
      setSearchValues({
        ...searchValues,
        currentPage: searchValues.currentPage + 1,
      })
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    const searchPage = searchValues.currentPage + 1
    searchPage < 2 ? setLoading(true) : setMoreLoading(true)
    const url =
      searchValues.search === ''
        ? `${process.env.REACT_APP_API2_URL}am/pay/cash/host?status=${searchValues.status}&page=${searchPage}&search=${searchValues.search}`
        : `${process.env.REACT_APP_API2_URL}am/pay/cash/host/search?status=${searchValues.status}&page=${searchPage}&${searchValues.search}`
    axios
      .get(url, {cancelToken: source.token})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setCashW0(result.cashW0)
          setCashW1(result.cashW1)
          setCashV(result.cashV)
          setCashE(result.cashE)
          setCashP(result.cashP)
          setCashD(result.cashD)
          setCashC(result.cashC)
          setCashS(result.cashS)
          const lists = result.pays.data
          lists.forEach((pay: any) => {
            pay.checked = false
          })
          if (result.pays.current_page === 1) {
            setPays(lists)
          } else {
            setPays([...pays, ...lists])
          }
          setTotal(result.pays.total)
        }
        setBottom(false)
        setLoading(false)
        setMoreLoading(false)
      })
      .catch((error) => {
        console.log(error)
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          setBottom(false)
          setLoading(false)
          setMoreLoading(false)
        }
      })
    return () => source.cancel(`${searchValues.status} ${searchValues.currentPage} ${searchValues.search} ${searchValues.refresh}`)
  }, [searchValues])

  useEffect(() => {
    const source = axios.CancelToken.source()
    const url =
      searchValues.search === ''
        ? `${process.env.REACT_APP_API2_URL}am/pay/cash/host?status=${searchValues.status}&page=1&search=${searchValues.search}`
        : `${process.env.REACT_APP_API2_URL}am/pay/cash/host/search?status=${searchValues.status}&page=1&${searchValues.search}`
    if (searchValues.currentPage === 0) {
      axios
        .get(url, {cancelToken: source.token})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            setCashW0(result.cashW0)
            setCashW1(result.cashW1)
            setCashV(result.cashV)
            setCashE(result.cashE)
            setCashP(result.cashP)
            setCashD(result.cashD)
            setCashC(result.cashC)
            setCashS(result.cashS)
            setTotal(result.pays.total)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    return () => source.cancel(`${searchValues.status} ${searchValues.search} ${searchValues.refresh}`)
  }, [searchValues])

  const paidPayHost = (pay: any) => {
    if (paidPayHostLoading === false) {
      if (window.confirm('입금 처리 하시겠습니까?')) {
        setPaidPayHostLoading(true)
        axios
          .post(`${process.env.REACT_APP_API2_URL}am/deposit/cash`, {cash_id: pay.id})
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
              toast.error(error)
            }

            const items = [...pays]
            items.splice(pays.indexOf(pay), 1)
            setPays(items)

            setSearchValues({
              ...searchValues,
              currentPage: 0,
              refresh: searchValues.refresh + 1,
            })

            setPaidPayHostLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setPaidPayHostLoading(false)
          })
      }
    }
  }

  const delayPayHost = (pay: any) => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/pay/cash/${pay.id}/delay`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          /*
          const items = [...pays]
          if (pay.account !== undefined) {
            result.account = pay.account
          }
          result.type_text = pay.type_text
          result.id = pay.id
          if (pay.account_no !== undefined) {
            result.account_no = pay.account_no
            result.account_name = pay.account_name
          }
          items.splice(pays.indexOf(pay), 1, result)
          setPays(items)
           */
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const waitPayHost = (pay: any) => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/pay/cash/${pay.id}/wait`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          /*
          const items = [...pays]
          if (pay.account !== undefined) {
            result.account = pay.account
          }
          result.type_text = pay.type_text
          result.id = pay.id
          if (pay.account_no !== undefined) {
            result.account_no = pay.account_no
            result.account_name = pay.account_name
          }
          items.splice(pays.indexOf(pay), 1, result)
          setPays(items)
          */
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const cancelPayHost = (pay: any) => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/pay/cash/${pay.id}/cancel`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (tempPay && cashChangeLoading) {
      if (window.confirm('캐시 적립 처리하시겠습니까?')) {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/pay/booking/${tempPay.id}/cash/update`)
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
              toast.error(error)
            } else {
              const items = [...pays]
              items.splice(pays.indexOf(tempPay), 1)
              setPays(items)
              toast.info('처리하였습니다.')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            setCashChangeLoading(false)
          })
      } else {
        setCashChangeLoading(false)
      }
    }
  }, [cashChangeLoading])
  const cashChange = (pay: any) => {
    setTempPay(pay)
    setCashChangeLoading(true)
  }

  const childResponse = (err: any, res: any) => {
    if (err) {
      console.log(err, 'err')
      // setstate(res)
    } else {
      console.log(res)
      setSearchValues({
        ...searchValues,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  const onCopy = (pay: any) => {
    let text = ''

    if (pay.pay_type === 'A') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

입금 전, 원천세(3.3%) 신고를 위하여 

1) 신분증 사본과 
2) 계좌사본을 
아워플레이스에 업로드 부탁 드립니다

*업로드후 톡으로 회신주시면 빠른 입금 진행이 가능합니다
*경로 : 앱 > 대시보드 > 수입 관리 > 계좌정보

hourplace.co.kr`
    } else if (pay.pay_type === 'B' || pay.pay_type === 'C' || pay.pay_type === 'J' || pay.pay_type === 'H-1') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 세금계산서 발급 부탁드립니다

1) 정산번호 : ${pay.id}
- 품목에 정산번호를 꼭 넣어 주세요
2) 정산 금액
- 공급가액 : ${(Number(pay.pay) - Math.floor(Number(pay.pay) / 11)).toLocaleString('en')}원
- 부가세 : ${Math.floor(Number(pay.pay) / 11).toLocaleString('en')}원
- 총액 : ${Number(pay.pay).toLocaleString('en')}원
3) 사업자등록번호 : 494-81-00558
4) 메일 주소 : team@munch-factory.com

빠른 처리를 위해 위 이메일 입력을 꼭 부탁드리며, 발급후 톡으로 회신주시면 빠른 입금 진행이 가능합니다`
    } else if (pay.pay_type === 'E') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 세금계산서 발급 부탁드립니다

1) 예약번호 : ${pay.id}
- 품목에 예약번호를 꼭 넣어 주세요
2) 예약일 :${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
- 계산서 작성일자는 오늘 날짜로 진행 부탁드립니다
3) 호스트 발급 금액 : ${Number(pay.pay).toLocaleString('en')}원(VAT포함)
4) 아워플레이스 발급 금액 : ${(Number(pay.pay) - Number(pay.pay)).toLocaleString('en')}(VAT포함)
5) 실제 입금되는 금액 : ${Number(pay.pay).toLocaleString('en')}원(VAT포함)
6) 사업자등록번호 : 494-81-00558
7) 메일 주소 : team@munch-factory.com
빠른 처리를 위해 위 이메일 입력을 꼭 부탁드리며, 발급후 톡으로 회신주시면 빠른 입금 진행이 가능합니다`
    } else if (pay.pay_type === 'F' || pay.pay_type === 'ㅗ-2') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 현금영수증 발급 부탁드립니다

1) 정산번호 : ${pay.id}
2) 정산 금액
- 공급가액 : ${(Number(pay.pay) - Math.floor(Number(pay.pay) / 11)).toLocaleString('en')}원
- 부가세 : ${Math.floor(Number(pay.pay) / 11).toLocaleString('en')}원
- 총액 : ${Number(pay.pay).toLocaleString('en')}원
3) 사업자등록번호 : 494-81-00558
4) 지출증빙으로 부탁드립니다

빠른 처리를 위해 발급후 꼭 톡으로 현금영수증 캡쳐본 회신 부탁드립니다`
    } else if (pay.pay_type === 'G' || pay.pay_type === 'I') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 현금영수증 발급 부탁드립니다

1) 정산번호 : ${pay.id}
2) 정산 금액
- 공급가액 : ${Number(pay.pay).toLocaleString('en')}원
- 부가세 : 0원
- 총액 : ${Number(pay.pay).toLocaleString('en')}원
3) 사업자등록번호 : 494-81-00558
4) 지출증빙으로 부탁드립니다

빠른 처리를 위해 발급후 꼭 톡으로 현금영수증 캡쳐본 회신 부탁드립니다`
    }
    if (text !== '') {
      copy(text)
      toast('복사하였습니다.')
    }
  }

  const updateMemoPay = (pay: any) => {
    if (window.confirm('입력 하시겠습니까?')) {
      const memo = window.prompt('코멘트를 적어주세요.', '')
      console.log('memo : ' + memo)
      if (memo === null) {
        return false
      }
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/pay/host/memo/cash/${pay.id}`, {memo})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            const items = [...pays]
            pay.memo_pay = memo
            items.splice(pays.indexOf(pay), 1, pay)
            setPays(items)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const cashTaxCancel = (pay: any) => {
    if (window.confirm('세금계산서 발행을 취소할까요?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/pay/cash/${pay.id}/tax/cancel`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const listenScrollEvent = () => {
    if ((document.getElementById('root')?.offsetHeight ?? 0) - window.scrollY <= 2000) {
      setBottom(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  const isCheckedPrice = () => {
    const checked = pays.filter((pay: any) => pay.checked)
    let total = 0
    checked.forEach((pay: any) => {
      total += Number(pay.pay)
    })
    return total.toLocaleString('en')
  }

  const isChecked = () => {
    return pays.filter((pay: any) => pay.checked).length
  }

  const paidPayHostFromChecked = () => {
    if (!paidLoading) {
      setPaidLoading(true)
      if (window.confirm(`${isChecked()}건 ${isCheckedPrice()}원 입금 처리 하시겠습니까?`)) {
        const checkedPays = pays.filter((pay: any) => pay.checked)
        paidPayHosts(checkedPays)
      } else {
        setPaidLoading(false)
      }
    }
  }

  const paidPayHosts = (checkedPays: any) => {
    console.log(`pays ${checkedPays}`)
    const res = checkedPays.map((pay: any) => pay.id)

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/multi/deposit/cash`, {cash_ids: res})
      .then(({data: {error, result}}) => {
        console.log(`am/multi/deposit/cash ${error}`)
        console.log(`am/multi/deposit/cash ${result}`)

        if (error !== '') {
          toast.error(error)
        }

        const items = [...pays]
        checkedPays.forEach((pay: any) => {
          items.some((item: any) => {
            if (item.id === pay.id) {
              items.splice(items.indexOf(item), 1)
              return true
            }
            return false
          })
        })
        setPays(items)

        setPaidLoading(false)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error)
        setPaidLoading(false)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>호스트 정산(캐시 출금)</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white'}}>
        <div style={{width: 'max-content'}}>
          <label
            onClick={() => {
              onTab('')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === ''}, {'btn-btn-white': searchValues.status !== ''})}
          >
            모두보기
          </label>

          <label
            onClick={() => {
              onTab('W0')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'W0'}, {'btn-btn-white': searchValues.status !== 'W0'})}
          >
            정산정보입력-인증서X ({cashW0})
          </label>

          <label
            onClick={() => {
              onTab('W1')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'W1'}, {'btn-btn-white': searchValues.status !== 'W1'})}
          >
            정산정보입력-인증서O ({cashW1})
          </label>

          <label
            onClick={() => {
              onTab('V')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'V'}, {'btn-btn-white': searchValues.status !== 'V'})}
          >
            정산대기 ({cashV})
          </label>

          <label
            onClick={() => {
              onTab('E')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'E'}, {'btn-btn-white': searchValues.status !== 'E'})}
            style={{color: 'red'}}
          >
            정산오류 ({cashE})
          </label>

          <label
            onClick={() => {
              onTab('P')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'P'}, {'btn-btn-white': searchValues.status !== 'P'})}
          >
            정산중 ({cashP})
          </label>

          <label
            onClick={() => {
              onTab('S')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'S'}, {'btn-btn-white': searchValues.status !== 'S'})}
          >
            정산완료 ({cashS})
          </label>

          <label
            onClick={() => {
              onTab('D')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'D'}, {'btn-btn-white': searchValues.status !== 'D'})}
          >
            보류 ({cashD})
          </label>

          <label
            onClick={() => {
              onTab('C')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === 'C'}, {'btn-btn-white': searchValues.status !== 'C'})}
          >
            정산취소 ({cashC})
          </label>
        </div>

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginTop: 20, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='정산 번호'
              value={search1}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                setSearch1(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch1()
                }
              }}
            />
            <label
              className='btn btn-dark'
              style={{width: 100}}
              onClick={() => {
                onSearch1()
              }}
            >
              검색
            </label>
          </div>
          <div style={{width: 40}}></div>
          <div style={{marginTop: 20, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='호스트 사용자 번호'
              value={search2}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                setSearch2(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch2()
                }
              }}
            />
            <label
              className='btn btn-dark'
              style={{width: 100}}
              onClick={() => {
                onSearch2()
              }}
            >
              검색
            </label>
          </div>
        </div>
      </div>

      {loading ? (
        <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <span className='indicator-progress' style={{display: 'block'}}>
            Loading...
            <span className='spinner-border spinner-border-sm align-middle ms-2' />
          </span>
        </div>
      ) : (
        <div
          style={{
            marginTop: 24,
            borderRadius: 20,
          }}
        >
          <div className='tab-content' id='myTabContent'>
            {pays.map((pay: any, index: number) => {
              return (
                <div
                  key={index.toString()}
                  style={{
                    marginBottom: 24,
                    padding: 40,
                    borderRadius: 20,
                    backgroundColor:
                      pay.status !== 'S' &&
                      (pay.last_pays === undefined ||
                        pay.last_pays === null ||
                        pay.account === null ||
                        pay.bank_account !== pay.account.bank_account ||
                        pay.last_pays.bank_account !== pay.account.bank_account ||
                        pay.last_pays.bank_name !== pay.account.bank_name ||
                        pay.last_pays.bank_owner !== pay.account.bank_owner)
                        ? pay.account !== null && pay.bank_account !== '' && pay.bank_account !== pay.account.bank_account
                          ? 'rgba(0,255,0,0.4)'
                          : '#FCD1D1'
                        : 'white',
                    position: 'relative',
                  }}
                >
                  <h1 style={{marginBottom: 32}}>
                    {pay.status === 'W' ? '호스트 정산 정보 입력' : ''}
                    {pay.status === 'V' ? '정산대기' : ''}
                    {pay.status === 'P' ? '정산중' : ''}
                    {pay.status === 'D' ? '보류' : ''}
                    {pay.status === 'C' ? '정산취소' : ''}
                    {pay.status === 'S' ? '정산완료' : ''}
                  </h1>

                  {(pay.status === 'V' || pay.status === 'P') && pay.bank_transfer_id === 0 && (
                    <div style={{position: 'absolute', top: 40, right: 40}}>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='layout-builder[layout][header][fixed][desktop]'
                        checked={pay.checked}
                        onChange={(event) => {
                          if (!paidLoading) {
                            const temps = [...pays]
                            temps.splice(temps.indexOf(pay), 1, pay)
                            pay.checked = event.target.checked
                            setPays(temps)
                          }
                        }}
                      />
                    </div>
                  )}

                  <TextSub title={'정산번호'} subTitle={pay.id} />

                  <TextSub title={'정산 요청일'} subTitle={moment(pay.created_at, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} />

                  <TextSub title={'정산 예정일'} subTitle={moment(pay.created_at, 'YYYYMMDDHHmmss').add(1, 'days').format('YYYY-MM-DD')} />

                  <TextSub title={'정산 요청 금액\n(공급가액)'} subTitle={pay.price.toLocaleString('en')} />

                  <TextSub title={'개인 정산 금액'} subTitle={pay.individual_price.toLocaleString('en')} />

                  <TextSub title={'사업자 정산 금액\n(부가세 있음)'} subTitle={pay.company_price_tax.toLocaleString('en')} />

                  <TextSub title={'사업자 정산 금액\n(부가세 없음)'} subTitle={pay.price.toLocaleString('en')} />

                  <TextSub title={'호스트'} subTitle={pay.user_id + '. ' + pay.real_name} popUrl={`/user/${pay.user_id}`} />
                  <TextSub title={'팝빌 연동 회원 ID'} subTitle={pay.popbill_id} />
                  <TextSub title={'사업자등록번호'} subTitle={pay.corp_num} />
                  <TextSub title={'인증서 등록 여부'} subTitle={pay.is_cert_validation === 0 ? '미등록' : '등록'} />
                  {pay.is_cert_validation === 1 && pay.cert_reg_dt !== null && pay.cert_reg_dt !== '' && pay.cert_expire_dt !== null && pay.cert_expire_dt !== '' && (
                    <>
                      <TextSub title={'인증서 등록 시간'} subTitle={moment(pay.cert_reg_dt, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} />
                      <TextSub title={'인증서 만료 시간'} subTitle={moment(pay.cert_expire_dt, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} />
                    </>
                  )}

                  <div className='separator my-10' />

                  <h3 style={{marginTop: 30, marginBottom: 2}}>호스트 입금 정보</h3>

                  <TextSub title={'정산 유형'} subTitle={pay.type_text} />

                  <TextSub title={'예금주'} subTitle={pay.account ? pay.account.bank_owner : '미입력'} />

                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'정산 메모'} subTitle={pay.memo_pay} color={'red'} />
                    </div>
                    {pay.id > 0 && (
                      <button
                        className='btn btn-sm btn-dark'
                        style={{marginLeft: 20}}
                        onClick={() => {
                          updateMemoPay(pay)
                        }}
                      >
                        입력하기
                      </button>
                    )}
                  </div>

                  <TextSub title={'은행명'} subTitle={pay.account ? pay.account.bank_name : '미입력'} />

                  <TextSub title={'계좌번호'} subTitle={pay.account ? pay.account.bank_account : '미입력'} />

                  {pay.account_no !== undefined && pay.account_no !== null && <TextSub title={'계좌주인증'} subTitle={`${pay.account_name} : ${pay.account_no}`} />}

                  <div className='separator my-10' />

                  <h3 style={{marginTop: 30, marginBottom: 2}}>호스트 지불 정보</h3>

                  {pay.status !== 'W' ? (
                    <>
                      <TextSub title={'지급액'} subTitle={Number(pay.pay).toLocaleString('en')} />
                      <TextSub title={'지급일'} subTitle={pay.pay_day} />
                      <TextSub title={'타입'} subTitle={pay.type_text} />
                      <TextSub title={'예금주'} subTitle={pay.bank_owner} />
                      <TextSub title={'주민번호'} subTitle={pay.jumin ? 'O' : 'X'} />
                      <TextSub title={'은행명'} subTitle={pay.bank_name} />
                      <TextSub title={'계좌번호'} subTitle={pay.bank_account} />

                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          {(pay.cash_receipt_tax_id !== 0 || pay.cash_receipt_cash_id !== 0 || pay.hometax_tax_id !== 0) && (
                            <>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <div>
                                  <TextSub title={'발급번호'} subTitle={pay.confirm_num} />
                                </div>
                                {pay.cash_receipt_tax_id !== 0 && (
                                  <button
                                    className='btn btn-sm btn-dark'
                                    style={{marginLeft: 20}}
                                    onClick={() => {
                                      cashTaxCancel(pay)
                                    }}
                                  >
                                    발행취소
                                  </button>
                                )}
                              </div>
                            </>
                          )}

                          {pay.cash_receipt_tax_id === 0 && pay.cash_receipt_cash_id === 0 && pay.hometax_tax_id === 0 && pay.memo2 !== '' && (
                            <TextSub title={'발급번호(수동입력)'} subTitle={pay.memo2} />
                          )}

                          {pay.error_message !== null && pay.error_message !== '' && <TextSub title={'발급메시지'} subTitle={pay.error_message} />}
                        </div>
                      </div>

                      {pay.bank_transfer_id !== 0 && (
                        <div
                          style={{
                            marginTop: 16,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>거래상태</p>
                          {pay.bank_transfer_id > 0 && pay.status_code === '1' && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: 'rgba(36, 111, 248, 0.1)',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#007BFF',
                                  marginBottom: 0,
                                }}
                              >
                                송금완료
                              </p>
                            </div>
                          )}
                          {pay.bank_transfer_id > 0 && (pay.status_code === '0' || pay.status_code === '6') && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: 'rgba(26, 162, 0, 0.1)',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#1aa200',
                                  marginBottom: 0,
                                }}
                              >
                                처리중
                              </p>
                            </div>
                          )}
                          {pay.bank_transfer_id > 0 && pay.status_code === '3' && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: '#FFF1F0',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#FF4B3E',
                                  marginBottom: 0,
                                }}
                              >
                                송금실패 (송금불능) (관리자에게 문의하세요)
                              </p>
                            </div>
                          )}
                          {pay.bank_transfer_id === -1 && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: '#FFF1F0',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#FF4B3E',
                                  marginBottom: 0,
                                }}
                              >
                                이체 시도 중 오류 (관리자에게 문의하세요)
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <p
                      style={{
                        marginTop: 16,
                        fontWeight: 500,
                        fontSize: 15,
                        color: '#C9CDD2',
                      }}
                    >
                      정산 정보 없음
                    </p>
                  )}

                  <div
                    style={{
                      width: '100%',
                      marginTop: 20,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {(pay.status === 'W' || pay.status === 'V' || pay.status === 'P' || pay.status === 'E') && (
                        <div style={{padding: 10}}>
                          <label
                            className='btn btn-bg-light btn-color-danger'
                            onClick={() => {
                              if (window.confirm('보류 처리하시겠습니까?')) {
                                delayPayHost(pay)
                              }
                            }}
                          >
                            보류
                          </label>
                        </div>
                      )}

                      {pay.status === 'W' && (
                        <div style={{padding: 10}}>
                          <WindowOpener url={`/pay/cash/${pay.id}/edit`} width={900} height={1200} bridge={childResponse}>
                            <div className='btn btn-secondary'>입력하기</div>
                          </WindowOpener>
                        </div>
                      )}
                      {pay.status === 'D' && (
                        <div style={{padding: 10}}>
                          <label
                            className='btn btn-bg-light btn-color-danger'
                            onClick={() => {
                              if (window.confirm('정산대기로변경 처리하시겠습니까?')) {
                                waitPayHost(pay)
                              }
                            }}
                          >
                            정산대기로 변경
                          </label>
                        </div>
                      )}

                      {pay.status === 'D' && (
                        <div style={{padding: 10}}>
                          <label
                            className='btn btn-bg-light btn-color-danger'
                            onClick={() => {
                              if (window.confirm('정산취소 처리하시겠습니까?')) {
                                cancelPayHost(pay)
                              }
                            }}
                          >
                            정산취소
                          </label>
                        </div>
                      )}
                      {(pay.status === 'V' || pay.status === 'P' || pay.status === 'D') && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <WindowOpener url={`/pay/cash/${pay.id}/edit`} width={900} height={1200} bridge={childResponse}>
                            <div className='btn btn-secondary'>수정하기</div>
                          </WindowOpener>
                        </div>
                      )}
                      {pay.status === 'V' && pay.bank_transfer_id === 0 && (
                        <div style={{padding: 10}}>
                          <button className='btn btn-primary' onClick={() => paidPayHost(pay)} disabled={paidPayHostLoading}>
                            {!paidPayHostLoading && <span className='indicator-label'>입금하기</span>}
                            {paidPayHostLoading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                입금하기
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                              </span>
                            )}
                          </button>
                        </div>
                      )}
                      {(pay.status === 'V' || pay.status === 'P' || pay.status === 'D') && (
                        <div style={{padding: 10}}>
                          <button
                            className='btn btn-secondary'
                            onClick={() => {
                              onCopy(pay)
                            }}
                          >
                            안내문구복사
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}

            {moreLoading && (
              <div
                style={{
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className='indicator-progress' style={{display: 'block'}}>
                  Loading...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {isChecked() > 0 && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: 100,
            backgroundColor: '#26282B',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 290,
            paddingRight: 30,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p
                style={{
                  width: 136,
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                총 이체 금액
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                {isCheckedPrice()}원
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p
                style={{
                  width: 136,
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                총 이체 건수
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                {isChecked()}건
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {paidLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 160,
                  height: 52,
                  backgroundColor: '#007BFF',
                  borderRadius: 8,
                }}
              >
                <span className='indicator-progress' style={{display: 'block', color: 'white'}}>
                  이체중...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 160,
                  height: 52,
                  cursor: 'pointer',
                  backgroundColor: '#007BFF',
                  borderRadius: 8,
                }}
                onClick={() => {
                  paidPayHostFromChecked()
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    fontSize: 18,
                    color: 'white',
                  }}
                >
                  이체하기
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
