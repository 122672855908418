import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'

export function AdvertiseMainBannerFormAdd() {
  const [id, setId] = useState(0)
  const [googleFormUrl, setGoogleFormUrl] = useState('')
  const [googleUserIdCode, setGoogleUserIdCode] = useState('')
  const [googlePhoneCode, setGooglePhoneCode] = useState('')
  const [googleEmailCode, setGoogleEmailCode] = useState('')

  useEffect(() => {
    getGoogleForm()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getGoogleForm = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/main/banner/form`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          if (null !== result && undefined !== result) {
            setId(result.id)
            setGoogleFormUrl(result.google_form_url)
            setGoogleUserIdCode(result.google_user_id_code)
            setGooglePhoneCode(result.google_phone_code)
            setGoogleEmailCode(result.google_email_code)
          }
          // window.location.href = `/booking/place/${result}`
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const add = () => {
    console.log(googleFormUrl)
    if (window.confirm('메인 배너 구글폼 등록 하시겠습니까?')) {
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/adv/main/banner/form/${id}`, {
          googleFormUrl,
          googleUserIdCode,
          googlePhoneCode,
          googleEmailCode,
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            setId(result.id)
            setGoogleFormUrl(result.google_form_url)
            setGoogleUserIdCode(result.google_user_id_code)
            setGooglePhoneCode(result.google_phone_code)
            setGoogleEmailCode(result.google_email_code)
            toast.info('등록 완료 되었습니다.')
            // window.location.href = `/booking/place/${result}`
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>메인 배너 광고 신청서</PageTitle>
      <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
        <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
            <div style={{marginTop: 10, paddingBottom: 10}}>
              <div>
                <table style={{fontSize: '15px', width: '100%'}}>
                  <colgroup>
                    <col style={{width: '14%'}} />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글폼 URL</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder=''
                          value={googleFormUrl}
                          onChange={(e) => {
                            setGoogleFormUrl(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 회원 ID Code</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder=''
                          value={googleUserIdCode}
                          onChange={(e) => {
                            setGoogleUserIdCode(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 Phone Code</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder=''
                          value={googlePhoneCode}
                          onChange={(e) => {
                            setGooglePhoneCode(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>구글 Email Code</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder=''
                          value={googleEmailCode}
                          onChange={(e) => {
                            setGoogleEmailCode(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <label
                onClick={() => {
                  add()
                }}
                style={{marginLeft: 10}}
                className='btn btn-sm btn-primary'
              >
                저장하기
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
