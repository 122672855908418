import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import Modal from '../../components/Modal'
import {Button} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'

export function MarketingCoupon() {
  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [modalVisible, setModalVisible] = useState(false)
  const [modalVisibleCodeCoupon, setModalVisibleCodeCoupon] = useState(false)

  const [title, setTitle] = useState('')
  const [couponCodeName, setCouponCodeName] = useState('')
  const [type, setType] = useState('A')
  const [discountPercent, setDiscountPercent] = useState(0)
  const [maxDiscountPrice, setMaxDiscountPrice] = useState(0)
  const [minPrice, setMinPrice] = useState(0)
  const [expireDate, setExpireDate] = useState('')
  const [couponRegDate, setCouponRegDate] = useState('')
  const [maxUseCouponCount, setMaxUseCouponCount] = useState(0)
  const [placeCategoryType, setPlaceCategoryType] = useState('')

  useEffect(() => {
    getCoupons()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getCoupons(page)
  }

  const getCoupons = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/coupon?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const createCoupon = () => {
    if (type.length === 0 || ('A' !== type && 'B' !== type && 'C' !== type)) {
      toast.error('쿠폰 타입을 확인해주세요.')
      return false
    }

    if (title.length === 0) {
      toast.error('쿠폰 제목을 확인해주세요.')
      return false
    }

    if (maxDiscountPrice < 0) {
      toast.error('최대 할인 금액을 확인해주세요.')
      return false
    }

    if (maxDiscountPrice % 10000 !== 0) {
      toast.error('최대 할인 금액을 만원 단위로 설정해주세요.')
      return false
    }

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/coupon`, {
        title,
        type,
        discount_percent: discountPercent,
        expire_date: expireDate,
        max_discount_price: maxDiscountPrice,
        min_price: minPrice,
        place_category_type: placeCategoryType,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          window.location.href = '/marketing/coupon'
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const createCodeCoupon = () => {
    if (title.length === 0) {
      toast.error('쿠폰 제목을 확인해주세요.')
      return false
    }

    if (type.length === 0 || ('A' !== type && 'B' !== type && 'C' !== type)) {
      toast.error('쿠폰 타입을 확인해주세요.')
      return false
    }

    if (couponCodeName.length === 0) {
      toast.error('쿠폰 코드명을 확인해주세요.')
      return false
    }

    if (discountPercent <= 0) {
      toast.error('할인율을 확인해주세요.')
      return false
    }

    if (maxDiscountPrice < 0) {
      toast.error('최대 할인 금액을 확인해주세요.')
      return false
    }

    if (maxDiscountPrice % 10000 !== 0) {
      toast.error('최대 할인 금액을 만원 단위로 설정해주세요.')
      return false
    }

    if (minPrice < 0) {
      toast.error('최소 결제 금액을 확인해주세요.')
      return false
    }

    if (maxUseCouponCount <= 0) {
      toast.error('최대 발행 횟수를 확인해주세요.')
      return false
    }

    if (expireDate === '' || expireDate.length > 8) {
      toast.error('사용기한을 확인해주세요.')
      return false
    }

    if (couponRegDate === '') {
      setCouponRegDate(expireDate)
    }

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/coupon/code`, {
        title,
        type,
        couponCodeName,
        discountPercent,
        maxDiscountPrice,
        minPrice,
        expireDate,
        maxUseCouponCount,
        couponRegDate,
        place_category_type: placeCategoryType,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          window.location.href = '/marketing/coupon'
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>쿠폰</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
          <label
            className={'btn btn-primary'}
            style={{marginRight: '30px'}}
            onClick={() => {
              setTitle('')
              setType('A')
              setDiscountPercent(0)
              setMaxDiscountPrice(0)
              setMinPrice(0)
              setExpireDate('')
              setModalVisibleCodeCoupon(true)
            }}
          >
            쿠폰코드 만들기
          </label>

          <label
            className={'btn btn-primary'}
            onClick={() => {
              setTitle('')
              setType('A')
              setDiscountPercent(0)
              setMaxDiscountPrice(0)
              setMinPrice(0)
              setExpireDate('')
              setModalVisible(true)
            }}
          >
            쿠폰 만들기
          </label>
        </div>

        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='table-responsive'>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
                <tr className='fw-bold fs-7 text-gray-1000 border-bottom-2 border-gray-200'>
                  <th className='min-w-100px'>ID</th>
                  <th className='min-w-100px'>제목</th>
                  <th className='min-w-100px'>쿠폰코드</th>
                  <th className='min-w-100px'>타입</th>
                  <th className='min-w-100px'>할인율</th>
                  <th className='min-w-100px'>최대 할인 금액</th>
                  <th className='min-w-100px'>최소 결제 금액</th>
                  <th className='min-w-100px'>사용 기한</th>
                  <th className='min-w-100px'>발행 횟수</th>
                  <th className='min-w-100px'>사용 횟수</th>
                  <th className='min-w-100px'>총 결제 금액</th>
                  <th className='min-w-100px'>총 할인 금액</th>
                  <th className='min-w-100px'>적용 카테고리</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item: any, index: number) => {
                  return [
                    <tr key={`${index}`}>
                      <td>{item.id}</td>
                      <td>{item.title}</td>
                      <td>{item.coupon_code_name}</td>
                      <td>{item.type}</td>
                      <td>{item.discount_percent}%</td>
                      <td>{item.max_discount_price.toLocaleString('en')}</td>
                      <td>{item.min_price.toLocaleString('en')}</td>
                      <td>{item.expire_date}</td>
                      <td>{item.count.toLocaleString('en')}</td>
                      <td>{item.used_count.toLocaleString('en')}</td>
                      <td>{item.total_price.toLocaleString('en')}</td>
                      <td>{item.total_discount_price.toLocaleString('en')}</td>
                      <td>{item.place_category_type}</td>
                    </tr>,
                    <tr key={`_${index}`}>
                      <td colSpan={9}>
                        <label
                          onClick={() => {
                            window.open(`/marketing/coupon/${item.id}`)
                          }}
                          className='btn btn-primary'
                        >
                          보기
                        </label>
                      </td>
                    </tr>,
                  ]
                })}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
      </div>

      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div>
            <div className='row'>
              <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>타입</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='타입을 입력해 주세요.'
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                  />
                </div>
                <p style={{paddingTop: 6, paddingLeft: 120}}>A: 기본, B: 앱에서만 사용 가능, C: 팀에서만 사용 가능</p>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>제목</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='제목을 입력해 주세요.'
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>적용 카테고리</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='사용 가능한 장소 카테고리를 선택해 주세요.'
                    value={placeCategoryType}
                    onChange={(e) => {
                      setPlaceCategoryType(e.target.value)
                    }}
                  />
                </div>
                <p style={{paddingTop: 6, paddingLeft: 120}}>
                  공란: 전체, 1: 가정집, 9: 자연광스튜디오, 10:호리존스튜디오, 11:컨셉스튜디오, 12:셀프스튜디오, 13: 연습실, 14: 파티룸, 15: 상업공간, 16: 특수공간, 17: 야외대형공간
                </p>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>할인율(%)</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='할인율을 입력해 주세요.'
                    value={discountPercent}
                    onChange={(e) => {
                      setDiscountPercent(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>최대 할인 금액</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='최대 할인 금액을 입력해 주세요'
                    value={maxDiscountPrice}
                    onChange={(e) => {
                      setMaxDiscountPrice(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>최소 결제 금액</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='최소 결제 금액을 입력해 주세요'
                    value={minPrice}
                    onChange={(e) => {
                      setMinPrice(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>사용기한</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='20990101 또는 입력이 없으면 발급일로부터 31일'
                    value={expireDate}
                    onChange={(e) => {
                      setExpireDate(e.target.value)
                    }}
                  />
                </div>

                <div style={{marginTop: 40}}>
                  <Button variant='primary' onClick={() => createCoupon()}>
                    저장
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {modalVisibleCodeCoupon && (
        <Modal
          visible={modalVisibleCodeCoupon}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisibleCodeCoupon(false)
          }}
          className={''}
        >
          <div>
            <div className='row'>
              <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>타입</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='타입을 입력해 주세요.'
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                  />
                </div>

                <p style={{paddingTop: 6, paddingLeft: 120}}>A: 기본, B: 앱에서만 사용 가능, C: 팀에서만 사용 가능</p>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>제목</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='제목을 입력해 주세요.'
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>코드명</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='코드명을 입력해주세요. (영문, 숫자)'
                    value={couponCodeName}
                    onChange={(e) => {
                      setCouponCodeName(e.target.value)
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>적용 카테고리</p>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='사용 가능한 장소 카테고리를 선택해 주세요.'
                    value={placeCategoryType}
                    onChange={(e) => {
                      setPlaceCategoryType(e.target.value)
                    }}
                  />
                </div>
                <p style={{paddingTop: 6, paddingLeft: 120}}>
                  공란: 전체, 1: 가정집, 9: 자연광스튜디오, 10:호리존스튜디오, 11:컨셉스튜디오, 12:셀프스튜디오, 13: 연습실, 14: 파티룸, 15: 상업공간, 16: 특수공간, 17: 야외대형공간
                </p>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>할인율(%)</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='할인율을 입력해 주세요.'
                    value={discountPercent}
                    onChange={(e) => {
                      setDiscountPercent(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>최대 할인 금액</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='최대 할인 금액을 입력해 주세요'
                    value={maxDiscountPrice}
                    onChange={(e) => {
                      setMaxDiscountPrice(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>최소 결제 금액</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='최소 결제 금액을 입력해 주세요'
                    value={minPrice}
                    onChange={(e) => {
                      setMinPrice(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>최대 발행 횟수</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='최대 발행 횟수를 입력해주세요.'
                    value={maxUseCouponCount}
                    onChange={(e) => {
                      setMaxUseCouponCount(parseInt(e.target.value))
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>등록 기한</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='ex) 20990101 또는 입력이 없으면 사용 기한과 동일'
                    value={couponRegDate}
                    onChange={(e) => {
                      setCouponRegDate(e.target.value)
                    }}
                  />
                </div>

                <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>사용기한</p>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='ex) 20990101'
                    value={expireDate}
                    onChange={(e) => {
                      setExpireDate(e.target.value)
                    }}
                  />
                </div>

                <div style={{marginTop: 40}}>
                  <Button variant='primary' onClick={() => createCodeCoupon()}>
                    저장
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
