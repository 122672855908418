import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'

export function SettingAgree() {
  const [items, setItems] = useState<any>([])

  const [loading] = useState(false)

  useEffect(() => {
    getAgree()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getAgree = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/setting/agree`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>약관</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label
          className={'btn btn-primary'}
          onClick={() => {
            window.location.href = `/setting/agree/add`
          }}
        >
          약관 추가하기
        </label>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          {loading ? (
            <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </div>
          ) : (
            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
              {items.map((item: any, index: number) => {
                return (
                  <div className='col-12' key={`agree_${index}`}>
                    <div className='card card-custom card-stretch shadow mb-5' style={{borderRadius: 15}}>
                      <div className='card-header'>
                        <h1 className='card-title'>{item.id}</h1>
                      </div>

                      <div className='card-body'>
                        <h4>약관 타이틀 : {item.title}</h4>
                      </div>

                      <div className='card-footer'>
                        <label
                          style={{marginLeft: 10}}
                          onClick={() => {
                            window.location.href = `/setting/agree/${item.id}/edit`
                          }}
                          className='btn btn-primary'
                        >
                          수정
                        </label>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
