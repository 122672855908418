import React, {useState} from 'react'
import moment from 'moment'
import axios from 'axios'
import {TextSub} from './TextSub'
import {toast} from 'react-toastify'
import Modal from './Modal'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import SwitchButton from '../../components/SwitchButton'

type Props = {
  user: any
  edit?: boolean
}

const setBlack = (user: any) => {
  if (window.confirm('블랙 하시겠습니까?')) {
    const blackComment = prompt('코멘트를 적어주세요.', '')

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/black`, {black: 1, blackComment: blackComment})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const setWhite = (user: any) => {
  if (window.confirm('화이트 하시겠습니까?')) {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/black`, {black: 0, blackComment: ''})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const updateName = (user: any) => {
  if (window.confirm('닉네임을 수정 하시겠습니까?')) {
    const name = prompt('닉네임을 적어주세요.', user.name)
    if (name === null) {
      return false
    }

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/name`, {name: name})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const setBan = (user: any, step = 0) => {
  if ((user.ban_comment !== '' && step > 0) || step === 0) {
    console.log(user.id)
    console.log({ban_step: step, ban_comment: user.ban_comment})
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/ban`, {ban_step: step, ban_comment: user.ban_comment})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  } else {
    toast.error('패널티 사유를 적어주세요.')
  }
}

const setBanCancel = (user: any) => {
  if (window.confirm(`패널티 4차 적용을 해제하시겠습니까?`)) {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/ban/cancel`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const setMemo = (user: any) => {
  console.log(user.id)
  console.log(user.memo)
  axios
    .post(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/memo`, {memo: user.memo})
    .then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

const setUserPoint = (user: any, pointType = '', point = 0, pointComment = '', pointExpire = '') => {
  if (window.confirm('포인트를 지급하시겠습니까?')) {
    console.log(user.id)
    console.log('pointType : ' + pointType)
    console.log('point : ' + point)
    console.log('pointComment : ' + pointComment)
    console.log('pointExpire : ' + pointExpire)

    if (pointType !== 'H' && pointType !== 'M' && pointType !== 'U' && pointType !== 'E' && pointType !== 'B' && pointType !== 'T' && pointType !== 'I') {
      toast('포인트 충전 타입을 확인하세요')
      return false
    }

    if (pointExpire.trim() !== '' && pointExpire.trim().length !== 8) {
      toast('소멸 예정일을 확인하세요')
      return false
    }

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/charge`, {type: pointType, point: point, comment: pointComment, expire: pointExpire})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const changeGrade = (user: any, grade: string) => {
  if (window.confirm(`${grade} 처리 하시겠습니까?`)) {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/grade`, {grade})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const pointUpdate = (user: any) => {
  if (window.confirm(`포인트 갱신 하시겠습니까?`)) {
    axios
      .get(`${process.env.REACT_APP_API2_URL}user/${user.id}/update/point`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const UserItem: React.FC<Props> = ({user, edit = false}) => {
  const [items, setItems] = useState<any>([])
  const [banStep, setBanStep] = useState(0)
  const [banModal, setBanModal] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMemoVisible, setModalMemoVisible] = useState(false)
  const [modalPointVisible, setModalPointVisible] = useState(false)

  const [point, setPoint] = useState(0)
  const [pointComment, setPointComment] = useState('')
  const [pointExpire, setPointExpire] = useState('')

  const loginUser: any = useSelector<RootState>(({auth}) => auth.user)

  const [pointType, setPointType] = useState((loginUser.admin >> 10) % 2 !== 1 ? 'T' : '')

  const autoGradeUpdate = (user: any) => {
    if (window.confirm(`사용자 자동 등급 갱신 하시겠습니까?`)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/auto/grade/update`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            //window.location.reload()
            const temps = [...items]
            temps.splice(temps.indexOf(user), 1, user)
            user.grade = result.grade
            user.guest_grade_booking_proceeding_count = result.guest_grade_booking_proceeding_count
            user.guest_grade_booking_completed_count = result.guest_grade_booking_completed_count
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const isPayCashUpdate = (user: any) => {
    if (window.confirm(`사용자 캐시 정산 여부 변경 하시겠습니까?`)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/cash/pay/${user.is_pay_cash}`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            //window.location.reload()
            const temps = [...items]
            temps.splice(temps.indexOf(user), 1, user)
            user.is_pay_cash = result.is_pay_cash
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const isSuperHostShowUpdate = (user: any) => {
    if (window.confirm(`최고의 호스트 노출 여부 변경 하시겠습니까?`)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/user/${user.id}/super/host/show/${user.super_host_show}`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            //window.location.reload()
            const temps = [...items]
            temps.splice(temps.indexOf(user), 1, user)
            user.super_host_show = result.super_host_show
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
      <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
        <div>
          <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
            <img src={user.image_url} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
            <div
              style={{
                position: 'absolute',
                width: 30,
                height: 30,
                borderTopLeftRadius: 8,
                bottom: 25,
                right: 25,
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h4 style={{margin: 0}}>{user.grade}</h4>
            </div>
          </div>

          <div style={{marginBottom: 10, display: 'flex', flexDirection: 'row'}}>
            <button
              onClick={() => {
                changeGrade(user, 'S')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              S
            </button>
            <button
              onClick={() => {
                changeGrade(user, 'A')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              A
            </button>
            <button
              onClick={() => {
                changeGrade(user, 'B')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              B
            </button>
            <button
              onClick={() => {
                changeGrade(user, 'C')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              C
            </button>
            <button
              onClick={() => {
                changeGrade(user, 'N')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              N
            </button>
            <button
              onClick={() => {
                changeGrade(user, 'F')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              F
            </button>
            <button
              onClick={() => {
                changeGrade(user, '')
              }}
              style={{fontSize: 14, marginRight: 4}}
              className='btn btn-sm btn-secondary'
            >
              수동등급삭제
            </button>
          </div>

          <div style={{marginLeft: 10}}>
            {user.leave === 1 && <p style={{color: 'red'}}>탈퇴 [{user.leave_comment}]</p>}

            {user.black === 1 && <p style={{color: 'red'}}>블랙 [{user.black_comment}]</p>}

            <h1>{user.id}. 게스트</h1>

            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div>
                <TextSub title={'닉네임 (이름)'} subTitle={`${user.name} (${user.real_name})`} marginTop={-4} />
              </div>
              {user.bet > -1 && (
                <>
                  <b style={{marginTop: -15, fontSize: 15, fontWeight: 500}}> &nbsp;/ ( </b>
                  <b style={{marginTop: -15, fontSize: 15, fontWeight: 500}}>{user.bet},</b>
                  <span
                    style={{
                      marginTop: -15,
                      marginBottom: 0,
                      marginRight: 6,
                      marginLeft: 6,
                      width: 16,
                      height: 16,
                      borderRadius: 8,
                      cursor: 'pointer',
                      backgroundColor: user.bet_last === 100 ? '#18e318' : user.bet_last === 50 ? '#e0e01b' : '#d21616',
                    }}
                  ></span>
                  <b style={{marginTop: -15, fontSize: 15, fontWeight: 500}}>)</b>
                </>
              )}
              {edit && (
                <button
                  onClick={() => {
                    updateName(user)
                  }}
                  style={{marginLeft: 8, marginTop: -15}}
                  className='btn btn-sm btn-secondary'
                >
                  닉네임 수정
                </button>
              )}
            </div>

            <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(user.birth))).asYears())}세`} marginTop={-10} />
            <TextSub title={'전화번호'} subTitle={user.phone} marginTop={-10} />
            <TextSub title={'이메일'} subTitle={user.email} marginTop={-10} />
            <TextSub title={'자동등급'} subTitle={`${user.grade} - 예약건(${user.guest_grade_booking_proceeding_count}), 촬영건(${user.guest_grade_booking_completed_count})`} marginTop={-10} />
            <TextSub title={'수동등급'} subTitle={user.priority_grade} marginTop={-10} />
            <TextSub title={'추가정보'} subTitle={user.company} marginTop={-10} />
            {!edit && user.guest_social_media !== undefined && user.guest_social_media !== null && (
              <>
                <TextSub title={'소셜미디어'} subTitle={user.guest_social_media.instagram_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_social_media.facebook_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_social_media.youtube_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_social_media.tiktok_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_social_media.twitter_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_social_media.naver_blog_url} marginTop={-10} />
              </>
            )}
            {edit && user.guest_career.socialMedia !== undefined && user.guest_career.socialMedia !== null && (
              <>
                <TextSub title={'소셜미디어'} subTitle={user.guest_career.socialMedia.instagram_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_career.socialMedia.facebook_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_career.socialMedia.youtube_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_career.socialMedia.tiktok_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_career.socialMedia.twitter_url} marginTop={-10} />
                <TextSub title={''} subTitle={user.guest_career.socialMedia.naver_blog_url} marginTop={-10} />
              </>
            )}
            {user.statistic !== undefined && user.statistic !== null && (
              <>
                <TextSub title={'인원 평균'} subTitle={user.statistic.people_avg} marginTop={-10} />
                <TextSub title={'총 결제금액'} subTitle={user.statistic.booking_guest_price.toLocaleString('en')} marginTop={-10} />
                <TextSub title={'평균 별점'} subTitle={user.statistic.booking_guest_rating} marginTop={-10} />
                <TextSub
                  title={'촬영 진행률'}
                  subTitle={`${
                    user.statistic.guest_booking_completed_count > 0 && user.statistic.guest_proceeding_booking > 0
                      ? Math.floor((user.statistic.guest_booking_completed_count / user.statistic.guest_proceeding_booking) * 100)
                      : 0
                  }% (${user.statistic.guest_booking_completed_count} / ${user.statistic.guest_proceeding_booking})`}
                  marginTop={-10}
                />
              </>
            )}
            <TextSub title={'포인트'} subTitle={user.point.toLocaleString('en')} marginTop={-4} />
            <TextSub
              title={'가입일'}
              subTitle={`${moment(user.created_at).format('YYYY-MM-DD')}, ${Math.floor(
                moment.duration(moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(user.created_at).format('YYYY-MM-DD HH:mm:ss'))).asDays()
              )} 일전`}
              marginTop={-10}
            />
            <TextSub
              title={'마지막 활성 시간'}
              subTitle={`${moment(user.last_logined_at).format('MM-DD')}, ${Math.floor(
                moment.duration(moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(user.last_logined_at).format('YYYY-MM-DD HH:mm:ss'))).asDays()
              )} 일전`}
              marginTop={-10}
            />
            <TextSub title={'패널티 히스토리'} subTitle={user.user_ban} marginTop={-10} />
            <TextSub title={'간편결제'} subTitle={user.is_auto_payment_card} marginTop={-10} />
            {user.memo !== undefined && user.memo !== null && user.memo.length > 0 && <TextSub title={'메모'} subTitle={user.memo} marginTop={-4} />}
          </div>
        </div>
      </div>

      {(user.permission === 1 || user.permission === 3) && (
        <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
          <div>
            <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
              <img src={user.host_image_url} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
            </div>

            <div style={{marginLeft: 10}}>
              <h1>{user.id}. 호스트</h1>

              <TextSub title={'닉네임 (이름)'} subTitle={`${user.host_name} (${user.real_name})`} marginTop={0} />
              <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(user.birth))).asYears())}세`} marginTop={-10} />
              <TextSub title={'전화번호'} subTitle={user.phone} marginTop={-10} />
              <TextSub title={'이메일'} subTitle={user.email} marginTop={-10} />
              {!edit && user.host_social_media !== undefined && user.host_social_media !== null && (
                <>
                  <TextSub title={'소셜미디어'} subTitle={user.host_social_media.instagram_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_social_media.facebook_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_social_media.youtube_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_social_media.tiktok_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_social_media.twitter_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_social_media.naver_blog_url} marginTop={-10} />
                </>
              )}
              {edit && user.host_career.socialMedia !== undefined && user.host_career.socialMedia !== null && (
                <>
                  <TextSub title={'소셜미디어'} subTitle={user.host_career.socialMedia.instagram_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_career.socialMedia.facebook_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_career.socialMedia.youtube_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_career.socialMedia.tiktok_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_career.socialMedia.twitter_url} marginTop={-10} />
                  <TextSub title={''} subTitle={user.host_career.socialMedia.naver_blog_url} marginTop={-10} />
                </>
              )}
              <br />
              <TextSub title={'캐시 정산 여부'} subTitle={user.is_pay_cash === 1 ? 'Y' : 'N'} marginTop={-4} />
              <TextSub title={'정산 가능 캐시'} subTitle={user.cash_pay_price !== '' ? user.cash_pay_price.toLocaleString('en') : 0} marginTop={-4} />

              {user.account !== undefined && user.account !== null && (
                <>
                  <TextSub title={'정산 유형'} subTitle={user.account.type === 'individual' ? '개인' : '사업자'} marginTop={-4} />
                  <TextSub title={'정산'} subTitle={`${user.account.bank_name} | ${user.account.bank_account} | ${user.account.bank_owner}`} marginTop={-4} />
                  {user.account.type === 'company' && user.account.company_url !== '' && <TextSub title={'파일'} subTitle={'사업자 인증 파일'} popUrl={user.account.company_url} marginTop={-4} />}
                  {user.account.type === 'individual' && user.account.bank_owner_url !== '' && (
                    <TextSub title={'파일'} subTitle={'개인 인증 파일'} popUrl={user.account.bank_owner_url} marginTop={-4} />
                  )}
                  {user.account.bank_account_url !== '' && <TextSub title={'파일'} subTitle={'통장 인증 파일'} popUrl={user.account.bank_account_url} marginTop={-4} />}
                </>
              )}

              {user.statistic !== null && (
                <>
                  <TextSub title={'총 촬영 횟수'} subTitle={`${user.statistic.host_booking_completed_count} / ${user.statistic.host_booking_completed_add_count}`} marginTop={-4} />
                  <TextSub
                    title={'예약 승인율'}
                    subTitle={`${
                      user.statistic.host_proceeding_booking > 0 && user.statistic.host_booking_count > 0
                        ? Math.floor((user.statistic.host_proceeding_booking / user.statistic.host_booking_count) * 100)
                        : 0
                    }% (${user.statistic.host_proceeding_booking} / ${user.statistic.host_booking_count})`}
                    marginTop={-4}
                  />
                  <TextSub
                    title={'촬영 진행률'}
                    subTitle={`${
                      user.statistic.host_booking_completed_count > 0 && user.statistic.host_proceeding_booking > 0
                        ? Math.floor((user.statistic.host_booking_completed_count / user.statistic.host_proceeding_booking) * 100)
                        : 0
                    }% (${user.statistic.host_booking_completed_count} / ${user.statistic.host_proceeding_booking})`}
                    marginTop={-4}
                  />
                  <TextSub title={'총 소득금액'} subTitle={`${user.statistic.booking_host_price.toLocaleString('en')}`} marginTop={-10} />
                </>
              )}
              <TextSub
                title={'가입일'}
                subTitle={`${moment(user.created_at).format('YYYY-MM-DD')}, ${Math.floor(
                  moment.duration(moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(user.created_at).format('YYYY-MM-DD HH:mm:ss'))).asDays()
                )} 일전`}
                marginTop={-10}
              />
              <TextSub
                title={'마지막 활성 시간'}
                subTitle={`${moment(user.last_logined_at).format('MM-DD')}, ${Math.floor(
                  moment.duration(moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(user.last_logined_at).format('YYYY-MM-DD HH:mm:ss'))).asDays()
                )} 일전`}
                marginTop={-10}
              />
              <TextSub title={'패널티 히스토리'} subTitle={user.user_ban} marginTop={-10} />
              <TextSub title={'최고의 호스트'} subTitle={user.super_host === 1 ? '최고의 호스트' : '일반 호스트'} marginTop={-10} />
              <TextSub title={'최고의 호스트 노출 여부'} subTitle={user.super_host_show === 1 ? '노출' : '미노출'} marginTop={-10} />
            </div>
          </div>
        </div>
      )}

      <div style={{marginTop: 20}}>
        <SwitchButton id={user.id}>Switch</SwitchButton>
        {edit === false && (
          <label
            style={{marginLeft: 10}}
            onClick={() => {
              window.open(`/user/${user.id}`)
            }}
            className='btn btn-sm btn-primary'
          >
            자세히 보기
          </label>
        )}
        <label
          style={{marginLeft: 10}}
          onClick={() => {
            setModalMemoVisible(true)
          }}
          className='btn btn-sm btn-primary'
        >
          메모하기
        </label>

        {user.black === 0 && (
          <label
            style={{marginLeft: 10}}
            onClick={() => {
              setBlack(user)
            }}
            className='btn btn-sm btn-danger'
          >
            블랙
          </label>
        )}

        {user.black === 1 && (
          <label
            style={{marginLeft: 10}}
            onClick={() => {
              setWhite(user)
            }}
            className='btn btn-sm btn-light'
          >
            화이트
          </label>
        )}

        <label
          style={{marginLeft: 10}}
          onClick={() => {
            window.open(`/user/tracking/${user.id}`)
          }}
          className='btn btn-sm btn-primary'
        >
          타임라인
        </label>
        <label
          style={{marginLeft: 10}}
          onClick={() => {
            setModalVisible(true)
          }}
          className='btn btn-sm btn-danger'
        >
          패널티
        </label>
        <label
          style={{marginLeft: 10}}
          onClick={() => {
            setModalPointVisible(true)
          }}
          className='btn btn-sm btn-primary'
        >
          포인트충전하기
        </label>
        <label
          style={{marginLeft: 10}}
          onClick={() => {
            pointUpdate(user)
          }}
          className='btn btn-sm btn-primary'
        >
          포인트갱신하기
        </label>
        <label
          style={{marginLeft: 10}}
          onClick={() => {
            autoGradeUpdate(user)
          }}
          className='btn btn-sm btn-primary'
        >
          자동등급갱신
        </label>
        {user.is_pay_cash === 1 && (
          <label
            style={{marginLeft: 10}}
            onClick={() => {
              user.is_pay_cash = 0
              isPayCashUpdate(user)
            }}
            className='btn btn-sm btn-danger'
          >
            캐시 정산 제외
          </label>
        )}
        {user.is_pay_cash === 0 && (
          <label
            style={{marginLeft: 10}}
            onClick={() => {
              user.is_pay_cash = 1
              isPayCashUpdate(user)
            }}
            className='btn btn-sm btn-danger'
          >
            캐시 정산하기
          </label>
        )}
        {(user.permission === 1 || user.permission === 3) && (
          <label
            style={{marginLeft: 10}}
            onClick={() => {
              user.super_host_show = user.super_host_show === 0 ? 1 : 0
              isSuperHostShowUpdate(user)
            }}
            className='btn btn-sm btn-danger'
          >
            {user.super_host_show === 0 ? '최고의호스트노출' : '최고의호스트미노출'}
          </label>
        )}
      </div>

      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div>
            <h2>패널티 적용</h2>
            <div className='separator my-10' />
            <div style={{marginTop: 26}}>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='1'
                    id={`check_1_${user.id}`}
                    checked={user.ban_type === '1'}
                    onChange={(e: any) => {
                      const temps = [...items]
                      temps.splice(temps.indexOf(user), 1, user)
                      user.ban_comment = '개인정보 공유 및 요구'
                      user.ban_type = e.target.value
                      setItems(temps)
                    }}
                  />
                  <label className='form-check-label' htmlFor={`check_1_${user.id}`}>
                    개인정보 공유 및 요구
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='2'
                    id={`check_2_${user.id}`}
                    checked={user.ban_type === '2'}
                    onChange={(e: any) => {
                      const temps = [...items]
                      temps.splice(temps.indexOf(user), 1, user)
                      user.ban_comment = '금액 협의 및 직거래 유도'
                      user.ban_type = e.target.value
                      setItems(temps)
                    }}
                  />
                  <label className='form-check-label' htmlFor={`check_2_${user.id}`}>
                    금액 협의 및 직거래 유도
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='3'
                    id={`check_3_${user.id}`}
                    checked={user.ban_type === '3'}
                    onChange={(e: any) => {
                      const temps = [...items]
                      temps.splice(temps.indexOf(user), 1, user)
                      user.ban_comment = '비방글 작성'
                      user.ban_type = e.target.value
                      setItems(temps)
                    }}
                  />
                  <label className='form-check-label' htmlFor={`check_3_${user.id}`}>
                    비방글 작성
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='0'
                    id={`check_0_${user.id}`}
                    checked={user.ban_type === '0'}
                    onChange={(e: any) => {
                      const temps = [...items]
                      temps.splice(temps.indexOf(user), 1, user)
                      user.ban_comment = ''
                      user.ban_type = e.target.value
                      setItems(temps)
                    }}
                  />
                  <label className='form-check-label' htmlFor={`check_0_${user.id}`}>
                    직접 입력
                  </label>
                </div>
                {user.ban_type === '0' && (
                  <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                    <input
                      type='text'
                      name='ban_type'
                      onChange={(e: any) => {
                        const temps = [...items]
                        temps.splice(temps.indexOf(user), 1, user)
                        user.ban_comment = e.target.value
                        setItems(temps)
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className={'row'}>
                {user.user_ban_step < 2 && (
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      onClick={() => {
                        if (user.ban_comment !== undefined) {
                          setBanStep(user.user_ban_step + 1)
                          setModalVisible(false)
                          setBanModal(true)
                        } else {
                          toast.error('패널티 사유를 적어주세요.')
                        }
                      }}
                      className='btn btn-primary'
                    >
                      패널티 적용
                    </label>
                  </div>
                )}
                {(user.user_ban_step < 3 || ((user.user_ban_step === 3 || user.user_ban_step === 4) && user.is_user_ban === 1)) && (
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      style={{border: '1px solid #F00'}}
                      onClick={() => {
                        if (user.ban_comment !== undefined) {
                          setBanStep(3)
                          setModalVisible(false)
                          setBanModal(true)
                        } else {
                          toast.error('패널티 사유를 적어주세요.')
                        }
                      }}
                      className='btn btn-outline-danger'
                    >
                      3차 패널티 바로 적용
                    </label>
                  </div>
                )}
                {user.user_ban_step === 3 && user.is_user_ban === 0 && (
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label style={{border: '1px solid #F00'}} className='btn btn-outline-danger'>
                      3차 패널티 적용 중
                    </label>
                  </div>
                )}
                {(user.user_ban_step !== 4 || (user.user_ban_step === 4 && user.is_user_ban === 1)) && (
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      style={{border: '1px solid #000', backgroundColor: '#000'}}
                      onClick={() => {
                        console.log(user.ban_type)
                        console.log(user.ban_comment)
                        if (user.ban_comment !== undefined) {
                          setBanStep(4)
                          setModalVisible(false)
                          setBanModal(true)
                        } else {
                          toast.error('패널티 사유를 적어주세요.')
                        }
                      }}
                      className='btn btn-outline-danger'
                    >
                      4차 패널티 바로 적용
                    </label>
                  </div>
                )}
                {user.user_ban_step === 4 && user.is_user_ban === 0 && (
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      style={{border: '1px solid #000', backgroundColor: '#000'}}
                      className='btn btn-outline-danger'
                      onClick={() => {
                        setBanCancel(user)
                        setModalVisible(false)
                      }}
                    >
                      4차 패널티 해제
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {banModal && (
        <Modal
          visible={banModal}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setBanModal(false)
          }}
          className={''}
        >
          <div>
            <h2>
              {banStep === 1 ? '1단계 경고 처리가 됩니다.' : ''}
              {banStep === 2 ? '24시간 이용정지 처리가 됩니다.' : ''}
              {banStep === 3 ? '7일 이용정지 처리가 됩니다.' : ''}
              {banStep === 4 ? '이용정지 처리가 됩니다. 관리자가 해제 처리 이 후 이용 가능합니다.' : ''}
            </h2>
            <div className='separator my-4' />
            <div style={{width: '100%', textAlign: 'right'}}>
              <label
                style={{margin: 4}}
                onClick={() => {
                  setBanModal(false)
                  setModalVisible(false)
                }}
                className={'btn btn-secondary'}
              >
                취소
              </label>
              <label
                style={{margin: 4}}
                onClick={() => {
                  setBan(user, banStep)
                  setBanModal(false)
                  setModalVisible(false)
                }}
                className={'btn btn-danger'}
              >
                패널티 적용
              </label>
            </div>
          </div>
        </Modal>
      )}

      {modalMemoVisible && (
        <Modal
          visible={modalMemoVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalMemoVisible(false)
          }}
          className={''}
        >
          <div>
            <div style={{marginTop: 26}}>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <textarea
                  rows={10}
                  cols={90}
                  defaultValue={user.memo}
                  onChange={(e) => {
                    user.memo = e.target.value
                  }}
                ></textarea>
              </div>

              <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className={'row'}>
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      onClick={() => {
                        setMemo(user)
                        setModalMemoVisible(false)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      등록
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {modalPointVisible && (
        <Modal
          visible={modalPointVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalPointVisible(false)
          }}
          className={''}
        >
          <div>
            <div style={{marginTop: 26}}>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <table style={{fontSize: '15px', width: '100%'}}>
                  <colgroup>
                    <col className='col1' />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>타 입</td>
                      <td>
                        <input
                          type='text'
                          style={{width: '100%'}}
                          disabled={(loginUser.admin >> 10) % 2 !== 1}
                          defaultValue={pointType}
                          onChange={(e) => {
                            setPointType(e.target.value)
                          }}
                        />
                        <span style={{fontSize: '13px', width: '100%'}}>T 테스트, H 임의 충전, M 마케팅 충전, I 추천 적립, B 직접 입금, U 사용, E 포인트 회수</span>
                      </td>
                    </tr>
                    <tr>
                      <td>포 인 트</td>
                      <td>
                        <input
                          type='number'
                          style={{width: '100%'}}
                          onChange={(e) => {
                            setPoint(parseInt(e.target.value))
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>설 명</td>
                      <td>
                        <input
                          type='text'
                          style={{width: '100%'}}
                          onChange={(e) => {
                            setPointComment(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>기 한</td>
                      <td>
                        <input
                          type='text'
                          style={{width: '100%'}}
                          placeholder='ex) 20220103'
                          onChange={(e) => {
                            setPointExpire(e.target.value)
                          }}
                        />
                        <span style={{fontSize: '13px', width: '100%'}}>소멸 예정일 ex) 20220103</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className={'row'}>
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      onClick={() => {
                        setUserPoint(user, pointType, point, pointComment, pointExpire)
                        setModalPointVisible(false)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      충전하기
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export {UserItem}
