import React from 'react'
import {
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap'
import axios from 'axios'

export default class MagazineImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      info: props.info,
    }
  }

  render() {
    const modalClose = () => this.setState({ modalShow: false })
    const { info } = this.state
    return (
      <div style={{ ...info.wrapStyle }}>
        <EditorModal
          show={this.state.modalShow}
          info={info}
          onHide={modalClose}
          forceUpdate={() => {
            this.forceUpdate()
            this.props.onChange(info)
          }}
        />
        <Image
          src={info.image_url}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
        <p
          style={{
            width: '100%',
            position: 'absolute',
            textAlign: 'center',
            color: '#72787F',
            fontSize: 14,
            fontWeight: 500,
            zIndex: 1,
          }}>
          {info.source}
        </p>
        <div
          style={{
            position: 'absolute',
            width: 60,
            height: 44,
            right: 0,
            top: -50,
          }}>
          <Button
            variant="primary"
            style={{ width: 60, height: 44 }}
            onClick={() => this.setState({ info, modalShow: true })}>
            편집
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 160,
            top: -50,
          }}>
          <Button
            variant="success"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onUp(this.props.info)}>
            ▲
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 112,
            top: -50,
          }}>
          <Button
            variant="success"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onDown(this.props.info)}>
            ▼
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 64,
            top: -50,
          }}>
          <Button
            variant="danger"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onDelete(this.props.info)}>
            X
          </Button>
        </div>
      </div>
    )
  }
}

class EditorModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      info: props.info,
      selectedFile: null,
    }
  }

  handleFileInput(e) {
    this.setState({
      selectedFile: e.target.files[0],
    })
  }

  handlePost() {
    const formData = new FormData()
    formData.append('file', this.state.selectedFile)

    return axios
      .post(`${process.env.REACT_APP_API_URL}image/magazine`, formData)
      .then(({ data: { error, result } }) => {
        console.log(error, result)
        if (error === '') {
          this.props.info.image_id = result.image_id
          this.props.info.image_url = result.image_url
          this.props.forceUpdate()
          this.props.onHide()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { info } = this.state
    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <Form
            noValidate
            validated={false}
            onSubmit={(e) => {
              e.preventDefault()
              return false
            }}>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>text</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    placeholder="text"
                    aria-describedby="inputGroupPrepend"
                    value={info.image_url}
                    onChange={(e) => {
                      info.image_url = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>

              <input
                type="file"
                name="file"
                onChange={(e) => this.handleFileInput(e)}
              />
              <Button type="button" onClick={() => this.handlePost()}>
                업로드
              </Button>
            </Form.Group>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>height</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="number"
                    placeholder="400"
                    aria-describedby="inputGroupPrepend"
                    value={info.wrapStyle.height}
                    onChange={(e) => {
                      info.wrapStyle.height = parseInt(e.target.value)
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>margin</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    placeholder="0px 0px 0px 0px"
                    aria-describedby="inputGroupPrepend"
                    value={info.wrapStyle.margin}
                    onChange={(e) => {
                      info.wrapStyle.margin = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>출처</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    placeholder="출처를 입력하세요"
                    aria-describedby="inputGroupPrepend"
                    value={info.source}
                    onChange={(e) => {
                      info.source = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
