import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import classNames from 'classnames'
import Modal from '../components/Modal'
import {toast} from 'react-toastify'
import {TextSub} from '../components/TextSub'

export function QnA() {
    const [status, setStatus] = useState('not')
    const [loading, setLoading] = useState(false)

    const [items, setItems] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)

    const [qnaCount, setQnaCount] = useState(0)
    const [qnaUnreadCount, setQnaUnreadCount] = useState(0)
    const [qnaDirectCount, setQnaDirectCount] = useState(0)
    const [qnaPlaceCount, setQnaPlaceCount] = useState(0)
    const [qnaShootCount, setQnaShootCount] = useState(0)
    const [qnaReportPlaceCount, setQnaReportPlaceCount] = useState(0)
    const [qnaReportUserCount, setQnaReportUserCount] = useState(0)
    const [qnaReportFeedbackCount, setQnaReportFeedbackCount] = useState(0)
    const [qnaReportFeedbackAnswerCount, setQnaReportFeedbackAnswerCount] = useState(0)
    const [qnaReportPortfolioCount, setQnaReportPortfolioCount] = useState(0)
    const [qnaPlaceTipOffCount, setQnaPlaceTipOffCount] = useState(0)
    const [qnaReportPlaceQnaCount, setQnaReportPlaceQnaCount] = useState(0)
    const [qnaReportMessageCount, setQnaReportMessageCount] = useState(0)
    const [qnaReportPlaceFeedCount, setQnaReportPlaceFeedCount] = useState(0)
    const [qnaReportProfileGuestCount, setQnaReportProfileGuestCount] = useState(0)
    const [qnaReportProfileHostCount, setQnaReportProfileHostCount] = useState(0)

    const [modalVisible, setModalVisible] = useState(false)
    const [item, setItem] = useState<any>(null)
    const [content, setContent] = useState('')

    const [answerModalVisible, setAnswerModalVisible] = useState(false)
    const [codes, setCodes] = useState('')

    useEffect(() => {
        getUsers(status)

        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page: number) => {
        getUsers(status, page)
    }

    const onTab = (tabStatus: string) => {
        setStatus(tabStatus)
        getUsers(tabStatus, 1)
    }

    const getUsers = (status = '', page = 1) => {
        setLoading(true)
        setItems([])
        let url = ''
        if (status === 'not') {
            url = `${process.env.REACT_APP_API2_URL}am/qnas?status=0&page=${page}`
        } else {
            url = `${process.env.REACT_APP_API2_URL}am/qnas?type=${status}&page=${page}`
        }
        axios
            .get(url)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    setItems(result.qnas.data)
                    setCurrentPage(result.qnas.current_page)
                    setPerPage(result.qnas.per_page)
                    setTotal(result.qnas.total)

                    setQnaCount(result.total_count)
                    setQnaUnreadCount(result.count_unread)
                    setQnaDirectCount(result.count_direct)
                    setQnaPlaceCount(result.count_place)
                    setQnaShootCount(result.count_shoot)
                    setQnaReportPlaceCount(result.count_report_place)
                    setQnaReportUserCount(result.count_report_user)
                    setQnaReportFeedbackCount(result.count_report_feedback)
                    setQnaReportPortfolioCount(result.count_report_portfolio)
                    setQnaReportFeedbackAnswerCount(result.count_report_feedback_answer)
                    setQnaPlaceTipOffCount(result.count_place_tip_off)
                    setQnaReportPlaceQnaCount(result.count_report_place_qna)
                    setQnaReportMessageCount(result.count_report_message)
                    setQnaReportPlaceFeedCount(result.count_report_place_feed)
                    setQnaReportProfileGuestCount(result.count_report_profile_guest)
                    setQnaReportProfileHostCount(result.count_report_profile_host)

                    window.scrollTo(0, 0)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    const getAnswer = (item: any) => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/qnas/${item.id}/answer/view`)
            .then(({data: {error, result}}) => {
                console.log(error)
                // console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    setCodes(result)
                    setAnswerModalVisible(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getPreview = (item: any) => {
        axios
            .post(`${process.env.REACT_APP_API2_URL}am/qnas/${item.id}/answer/preview`, {message: content})
            .then(({data: {error, result}}) => {
                console.log(error)
                // console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    setCodes(result)
                    setAnswerModalVisible(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const sendAnswer = () => {
        if (window.confirm('답변을 보내시겠습니까?')) {
            axios
                .post(`${process.env.REACT_APP_API2_URL}am/qnas/${item.id}/answer`, {message: content})
                .then(({data: {error, result}}) => {
                    console.log(error)
                    console.log(result)
                    if (error !== '') {
                    } else {
                        const temps = [...items]
                        temps.splice(items.indexOf(item), 1, item)
                        item.status = 1
                        item.send_mail_info_id = result.send_mail_info_id
                        item.send_mail_id = result.send_mail_id
                        setItems(temps)
                        setModalVisible(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const setRead = (qna: any) => {
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/qnas/${qna.id}/read`, {status: 1})
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    const temps = [...items]
                    temps.splice(items.indexOf(qna), 1, qna)
                    qna.status = 1
                    setItems(temps)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>문의</PageTitle>

            <div
                style={{
                    padding: 20,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        padding: 20,
                        borderRadius: 20,
                        backgroundColor: 'white',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <label
                            onClick={() => {
                                onTab('')
                            }}
                            className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}
                        >
                            모두보기 ({qnaCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('not')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'not'}, {'btn-btn-white': status !== 'not'})}
                        >
                            안읽음 ({qnaUnreadCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('direct')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'direct'}, {'btn-btn-white': status !== 'direct'})}
                        >
                            1:1 고객문의 ({qnaDirectCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_place')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_place'}, {'btn-btn-white': status !== 'report_place'})}
                        >
                            장소 ({qnaReportPlaceCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_place_qna')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_place_qna'}, {'btn-btn-white': status !== 'report_place_qna'})}
                        >
                            장소 문의 ({qnaReportPlaceQnaCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_message')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_message'}, {'btn-btn-white': status !== 'report_message'})}
                        >
                            메시지 ({qnaReportMessageCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_user')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_user'}, {'btn-btn-white': status !== 'report_user'})}
                        >
                            사용자 ({qnaReportUserCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_feedback')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_feedback'}, {'btn-btn-white': status !== 'report_feedback'})}
                        >
                            후기 ({qnaReportFeedbackCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_feedback_answer')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_feedback_answer'}, {'btn-btn-white': status !== 'report_feedback_answer'})}
                        >
                            후기 답변 ({qnaReportFeedbackAnswerCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('place')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'place'}, {'btn-btn-white': status !== 'place'})}
                        >
                            촬영 장소 요청 ({qnaPlaceCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('shoot')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'shoot'}, {'btn-btn-white': status !== 'shoot'})}
                        >
                            무료 사진 신청 ({qnaShootCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('place_tip_off')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'place_tip_off'}, {'btn-btn-white': status !== 'place_tip_off'})}
                        >
                            장소 제보 ({qnaPlaceTipOffCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_portfolio')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_portfolio'}, {'btn-btn-white': status !== 'report_portfolio'})}
                        >
                            포트 폴리오 ({qnaReportPortfolioCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_place_feed')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_place_feed'}, {'btn-btn-white': status !== 'report_place_feed'})}
                        >
                            장소 피드 신고 ({qnaReportPlaceFeedCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_profile_guest')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_profile_guest'}, {'btn-btn-white': status !== 'report_profile_guest'})}
                        >
                            프로필 게스트 신고 ({qnaReportProfileGuestCount.toLocaleString('en')})
                        </label>

                        <label
                            onClick={() => {
                                onTab('report_profile_host')
                            }}
                            className={classNames('btn', {'btn-dark': status === 'report_profile_host'}, {'btn-btn-white': status !== 'report_profile_host'})}
                        >
                            프로필 호스트 신고 ({qnaReportProfileHostCount.toLocaleString('en')})
                        </label>
                    </div>

                    {loading ? (
                        <div
                            style={{
                                width: '100%',
                                height: 400,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Loading...
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        </div>
                    ) : (
                        <div
                            style={{
                                padding: 20,
                                borderRadius: 20,
                                backgroundColor: 'white',
                                width: '100%',
                            }}
                        >
                            {items.map((item: any, index: number) => {
                                return (
                                    <div
                                        style={{
                                            marginTop: 30,
                                            width: '100%',
                                        }}
                                        key={item.id.toString()}
                                    >
                                        <div className='col-12'>
                                            <div className='card card-custom card-stretch shadow mb-5'>
                                                <div className='card-header'>
                                                    <h1 className='card-title'>
                                                        {item.type === 'direct' ? '1:1 고객문의' : ''}
                                                        {item.type === 'place' ? '촬영장소요청' : ''}
                                                        {item.type === 'shoot' ? '무료사진신청' : ''}
                                                        {item.type === 'report_message' ? '메시지 신고' : ''}
                                                        {item.type === 'report_place' ? '장소 신고' : ''}
                                                        {item.type === 'report_place_qna' ? '장소 문의 신고' : ''}
                                                        {item.type === 'report_feedback' ? '후기 신고' : ''}
                                                        {item.type === 'report_feedback_answer' ? '후기 답변 신고' : ''}
                                                        {item.type === 'report_user' ? '사용자 신고' : ''}
                                                        {item.type === 'place_tip_off' ? '장소 제보하기' : ''}
                                                        {item.type === 'report_portfolio' ? '포트폴리오 신고' : ''}
                                                        {item.type === 'report_place_feed' ? '장소 피드 신고' : ''}
                                                        {item.type === 'report_profile_guest' ? '프로필 게스트 신고' : ''}
                                                        {item.type === 'report_profile_host' ? '프로필 호스트 신고' : ''}
                                                    </h1>
                                                </div>
                                                <div className='card-body'>
                                                    <TextSub title={'작성일'} subTitle={item.created_at} />
                                                    <TextSub
                                                        title={'사용자'}
                                                        subTitle={`${item.user_id} (${item.user_real_name} | 만 ${Math.floor(
                                                            moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(item.user_birth))).asYears()
                                                        )}세 | ${item.user_email})`}
                                                    />
                                                    <TextSub title={'내용'} subTitle={item.content} />
                                                    {item.type === 'shoot' && (
                                                        <>
                                                            <TextSub title={'장소명'} subTitle={item.title} />
                                                            <TextSub title={'장소'} subTitle={item.ref} popUrl={`/place/${item.ref}`} />
                                                        </>
                                                    )}
                                                    {item.type === 'report_place' && (
                                                        <>
                                                            <TextSub title={'장소'} subTitle={item.ref} popUrl={`/place/${item.ref}`} />
                                                        </>
                                                    )}
                                                    {item.type === 'report_place_qna' && item.place_qna !== undefined && (
                                                        <>
                                                            <TextSub title={'장소'} subTitle={item.place_qna.place_id} popUrl={`/place/${item.place_qna.place_id}`} />
                                                            <TextSub title={'질문'} subTitle={item.place_qna.content} />
                                                            {item.place_qna.content !== item.place_qna.o_content && <TextSub title={'질문 원본'} subTitle={item.place_qna.o_content} />}
                                                        </>
                                                    )}
                                                    {item.type === 'report_user' && (
                                                        <>
                                                            <TextSub title={'신고 대상자'} subTitle={item.ref} popUrl={`/user/${item.ref}`} />
                                                        </>
                                                    )}
                                                    {item.type === 'report_feedback' && (
                                                        <>
                                                            {item.feedback !== undefined && item.feedback !== null && (
                                                                <TextSub title={'장소'} subTitle={item.feedback.place_id} popUrl={`/place/${item.feedback.place_id}`} />
                                                            )}
                                                            <TextSub title={'후기'} subTitle={item.ref} />
                                                            <TextSub title={'내용'} subTitle={item.feedback ? item.feedback.comment : '삭제됨'} />
                                                            {item.feedback_images !== undefined && item.feedback_images !== null && (
                                                                <>
                                                                    <h5>후기 사진</h5>

                                                                    {item.feedback_images.map((image: any, imageIndex: number) => {
                                                                        return (
                                                                            <img
                                                                                src={image.image_url}
                                                                                style={{padding: 8, width: 200, height: 200, objectFit: 'cover'}}
                                                                                key={`${item.id}_${imageIndex}`}
                                                                                alt={''}
                                                                            />
                                                                        )
                                                                    })}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {item.type === 'report_feedback_answer' && (
                                                        <>
                                                            {item.feedback !== undefined && item.feedback !== null && (
                                                                <TextSub title={'장소'} subTitle={item.feedback.place_id} popUrl={`/place/${item.feedback.place_id}`} />
                                                            )}
                                                            <TextSub title={'후기'} subTitle={item.ref} />
                                                            <TextSub title={'내용'} subTitle={item.feedback ? item.feedback.comment : '삭제됨'} />
                                                        </>
                                                    )}
                                                    {item.type === 'place_tip_off' && (
                                                        <>
                                                            <h5>장소</h5>
                                                            <p>
                                                                <img src={item.image_url} style={{padding: 8, width: 200, height: 200, objectFit: 'cover'}} alt={''} />
                                                                <br />
                                                                <TextSub title={'장소'} subTitle={item.ref} popUrl={`/place/${item.ref}`} />
                                                            </p>
                                                        </>
                                                    )}
                                                    {item.type === 'place' && (
                                                        <>
                                                            <TextSub title={'예약번호'} subTitle={item.ref} popUrl={`/booking/place/${item.ref}`} />
                                                        </>
                                                    )}
                                                    {item.type === 'report_portfolio' && (
                                                        <>
                                                            <TextSub title={'게시물'} subTitle={'포트폴리오'} popUrl={`${process.env.REACT_APP_WEB_URL}portfolio/${item.ref}`} />
                                                            <TextSub
                                                                title={'게시물 작성자'}
                                                                subTitle={`${item.feedUser.id} (${item.feedUser.real_name} | 만 ${Math.floor(
                                                                    moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(item.feedUser.birth))).asYears()
                                                                )}세 | ${item.feedUser.email})`}
                                                                popUrl={`/user/${item.feedUser.id}`}
                                                            />
                                                        </>
                                                    )}
                                                    {item.type === 'report_place_feed' && (
                                                        <>
                                                            <TextSub title={'게시물'} subTitle={'장소 사진, 영상'} popUrl={`/feed/${item.ref}`} />
                                                            {null !== item.feedUser && undefined !== item.feedUser && (
                                                                <TextSub
                                                                    title={'게시물 작성자'}
                                                                    subTitle={`${item.feedUser.id} (${item.feedUser.real_name} | 만 ${Math.floor(
                                                                        moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(item.feedUser.birth))).asYears()
                                                                    )}세 | ${item.feedUser.email})`}
                                                                    popUrl={`/user/${item.feedUser.id}`}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                    {(item.type === 'report_profile_guest' || item.type === 'report_profile_host') && null !== item.feedUser && undefined !== item.feedUser && (
                                                        <TextSub
                                                            title={'신고 대상자'}
                                                            subTitle={`${item.feedUser.id} (${item.feedUser.real_name} | 만 ${Math.floor(
                                                                moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(item.feedUser.birth))).asYears()
                                                            )}세 | ${item.feedUser.email})`}
                                                            popUrl={`/user/${item.feedUser.id}`}
                                                        />
                                                    )}
                                                </div>
                                                <div className='card-footer'>
                                                    {item.status === 0 && (
                                                        <label
                                                            onClick={() => {
                                                                setRead(item)
                                                            }}
                                                            className='btn btn-primary'
                                                        >
                                                            읽음
                                                        </label>
                                                    )}
                                                    {item.send_mail_info_id === 0 && item.send_mail_id === 0 ? (
                                                        <label
                                                            style={{marginLeft: 10}}
                                                            onClick={() => {
                                                                setItem(item)
                                                                setContent('')
                                                                setModalVisible(true)
                                                            }}
                                                            className='btn btn-primary'
                                                        >
                                                            답변하기
                                                        </label>
                                                    ) : (
                                                        <label
                                                            style={{marginLeft: 10}}
                                                            onClick={() => {
                                                                getAnswer(item)
                                                            }}
                                                            className='btn btn-secondary'
                                                        >
                                                            답변보기
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div style={{padding: 20}}>
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={10}
                                    prevPageText={'‹'}
                                    nextPageText={'›'}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    closable={true}
                    maskClosable={true}
                    onClose={() => {
                        setModalVisible(false)
                    }}
                    className={''}
                >
                    <div>
                        <div className='row'>
                            <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
                                <textarea
                                    style={{
                                        width: '100%',
                                        height: 200,
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                    }}
                                    value={content}
                                    onChange={(e) => {
                                        setContent(e.target.value)
                                    }}
                                />

                                <div style={{marginTop: 40}}>
                                    <label
                                        style={{marginLeft: 10}}
                                        onClick={() => {
                                            sendAnswer()
                                        }}
                                        className='btn btn-primary'
                                    >
                                        보내기
                                    </label>
                                    <label
                                        style={{marginLeft: 10}}
                                        onClick={() => {
                                            getPreview(item)
                                        }}
                                        className='btn btn-secondary'
                                    >
                                        미리보기
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            {answerModalVisible && (
                <Modal
                    visible={answerModalVisible}
                    closable={true}
                    maskClosable={true}
                    onClose={() => {
                        setAnswerModalVisible(false)
                    }}
                    className={''}
                >
                    <div>
                        <div className='row'>
                            <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
                                <div dangerouslySetInnerHTML={{__html: codes}}></div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}
