import React, {forwardRef, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from "axios";
import moment from 'moment'
import {TextSub} from '../../components/TextSub'
import {useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'

export function PayPointWithdrawEdit() {

  const {payId} = useParams<{payId: string}>()

  const [date, setDate] = useState<Date | null>(new Date())

  const [point, setPoint] = useState<any>({})
  const [user, setUser] = useState<any>({})
  const [account, setAccount] = useState<any>({})
  const [accounts, setAccounts] = useState<any>([])

  const [bankOwner, setBankOwner] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankAccount, setBankAccount] = useState('')
  const [jumin, setJumin] = useState('')
  const [pay, setPay] = useState(0)

  const [storeLoading, setStoreLoading] = useState(false)
  let loading = false

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    getPayInfo();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPayInfo = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/pay/${payId}/point/withdraw/edit`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setPoint(result.point)
        setUser(result.user)
        setAccount(result.account)
        setBankOwner(result.pay.bank_owner)
        setBankName(result.pay.bank_name)
        setBankAccount(result.pay.bank_account)
        //setPayDay(result.pay.pay_day)
        setDate(result.pay.pay_day === '' ? new Date() : moment(result.pay.pay_day).toDate())
        setPay(result.pay.pay)
        setAccounts(result.accounts)
        setJumin(result.jumin)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const downloadImage = async (url: string) => {
    if (url.indexOf('.pdf') > 0) {
      window.open(url)
    } else if (url.indexOf('s3.ap-northeast-2.amazonaws.com') > -1) {
      const res = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
      })
      const raw = await res.blob()
      const blob = window.URL.createObjectURL(raw)
      const anchor = document.createElement('a')
      anchor.style.display = 'none'
      anchor.href = blob
      anchor.download = `${point.user_id}_${moment().format('YYYYMMDDHHmmss')}.png`
      document.body.appendChild(anchor)
      anchor.click()
      window.URL.revokeObjectURL(blob)
    } else {
      window.open(url)
    }
  }

  const storePay = () => {
    if (loading) return

    loading = true
    setStoreLoading(true);

    console.log("pointId : " + point.id)
    console.log("pay : " + pay)
    console.log("payDay : " + moment(date).format('YYYYMMDD'))
    console.log("bankOwner : " + bankOwner)
    console.log("jumin : " + jumin)
    console.log("bankName : " + bankName)
    console.log("bankAccount : " + bankAccount)

    axios.put(`${process.env.REACT_APP_API2_URL}am/pay/${payId}/point/withdraw/edit`
      , {
        pointId: point.id
        , pay
        , payDay: moment(date).format('YYYYMMDD')
        , bankOwner
        , jumin
        , bankName
        , bankAccount
      }
    ).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        alert(error)
      } else {
        alert("수정완료되었습니다.")
        window.opener.onSuccess("")
        window.close()
      }
      loading =false;
      setStoreLoading(false)
    }).catch((error) => {
      console.log(error)
      loading =false;
      setStoreLoading(false)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>포인트 지불 처리</PageTitle>
    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white'}}>
      <TextSub title={'포인트 번호'} subTitle={point.id} />
      <div className="separator my-10" />
      <div style={{backgroundColor: `${account.bank_owner !== point.name ? 'pink' : ''}`}}>
        <TextSub title={'본인인증 이름'} subTitle={user.real_name} />
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          예금주
        </p>
        <input type="text" className="form-control" placeholder="예금주 이름을 입력해 주세요" value={bankOwner}
               onChange={(e) => {
                 setBankOwner(e.target.value)
               }}
        />
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          주민번호
        </p>
        <input type="text" className="form-control" placeholder="주민번호를 입력해 주세요" value={jumin}
               onChange={(e) => {
                 setJumin(e.target.value)
               }}
        />
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          은행명
        </p>
        <input type="text" className="form-control" placeholder="은행명을 입력해 주세요" value={bankName}
               onChange={(e) => {
                 setBankName(e.target.value)
               }}
        />
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          계좌번호
        </p>
        <input type="text" className="form-control" placeholder="은행명을 입력해 주세요" value={bankAccount}
               onChange={(e) => {
                 setBankAccount(e.target.value)
               }}
        />
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          통장 사본
        </p>
        {account.bank_account_url !== '' && (
          <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}></p>
            <div style={{width: 200, height: 150, cursor: 'pointer'}}
                 onClick={() => {
                   downloadImage(account.bank_account_url)
                 }}
            >
              {account.bank_account_url !== undefined && account.bank_account_url.indexOf('.pdf') > 0 ? (
                <img src={'https://s3.hourplace.co.kr/web/images/icon/conti_pdf.svg'} alt={''}
                     style={{width: 84, height: 105, objectFit: 'contain'}}
                />
              ) : (
                <img src={account.bank_account_url} alt={''} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          신분증 사본
        </p>
        {account.bank_owner_url !== '' && (
          <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}></p>
            <div style={{width: 200, height: 150, cursor: 'pointer'}}
                 onClick={() => {
                   downloadImage(account.bank_owner_url)
                 }}
            >
              {account.bank_owner_url !== undefined && account.bank_owner_url.indexOf('.pdf') > 0 ? (
                <img src={'https://s3.hourplace.co.kr/web/images/icon/conti_pdf.svg'} alt={''}
                     style={{width: 84, height: 105, objectFit: 'contain'}}
                />
              ) : (
                <img src={account.bank_owner_url} alt={''} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="separator my-10" />
      <TextSub title={'출금요청 금액'} subTitle={`${Math.abs(point.point).toLocaleString('en')} 원`} />
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          지급일
        </p>
        <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM-dd' showFullMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
      </div>
      <div className="separator my-10" />
      <div style={{marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{width: 156, fontWeight: 500, fontSize: 15, color: '#9EA4AA'}}>
          지불금액
        </p>
        <input type="number" className="form-control" placeholder="지불금액을 입력해 주세요" value={pay}
               onChange={(e) => {
                 setPay(Number(e.target.value))
               }}
        />
      </div>

      <div
        style={{
          width: '100%',
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <button className="btn btn-primary" onClick={() => storePay()} disabled={storeLoading}>
            {!storeLoading && <span className="indicator-label">수정</span>}
            {storeLoading && (
              <span className="indicator-progress" style={{display: 'block'}}>
                    수정
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
            )}
          </button>
        </div>
      </div>

    </div>
    {accounts.length > 0 && (
      <div
        style={{
          marginTop: 10,
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
        }}
      >
        <h3
          style={{
            marginBottom: 20,
          }}
        >
          계좌 정보 변경 내역
        </h3>

        {accounts.map((item: any, index: number) => {
          return (
            <p key={index.toString()} style={{fontSize: 15, color: '#1B1D1F'}}>
            {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')} {item.bank_owner} {item.bank_name} {item.bank_account}
            </p>
          )
        })}
      </div>
    )}
  </>
}

