import React from 'react'
import {Button, Card, Form, InputGroup, Modal} from 'react-bootstrap'
import axios from 'axios'
import {toast} from 'react-toastify'
import {PageTitle} from '../../../../../_metronic/layout/core'
import ImagePath from '../../../../commons/ImagePath'
// import { Checkbox, Notice } from '../../../../_metronic/_partials/controls'

export default class RecommendCategoryEditor extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            recommendcategory_id: props.match.params.recommendcategory_id,
            category_main_id: 0,
            category_sub_id: 0,
            main_category_id: 0,
            image_id: 0,
            image_path: 'dev/temp/images/magazine/2021/05/12/9a771558-47be-3c88-99a7-ab73c6aea28c.jpg',
            order: 0,
            mobile_title: '',
            app_title: '',
            pc_title: '',
            show_pc: 1,
            show_mobile: 1,
            show_app: 1,
            detail_image_id: 0,
            detail_image_path: '/dev/temp/images/magazine/2021/05/12/9a771558-47be-3c88-99a7-ab73c6aea28c.jpg',
            title: '',
            sub_title: '',
            background_color: '',
            uploadIndex: 0,
            showCategoryModal: false,
            showCurationModal: false,
        }

        if (this.state.recommendcategory_id > 0) {
            this.getRecommendCategory()
        }
    }

    getRecommendCategory() {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/recommend/category/${this.state.recommendcategory_id}`)
            .then(({data: {error, result}}) => {
                console.log(error, result)
                if (error !== '') {
                } else {
                    this.setState({...result})
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    onSave = () => {
        axios
            .post(`${process.env.REACT_APP_API2_URL}am/recommend/category/${this.state.recommendcategory_id}`, {...this.state})
            .then(({data: {error, result}}) => {
                console.log(result)
                if (error !== '') {
                    toast.error(error, {
                        autoClose: 3000,
                    })
                } else if (this.state.recommendcategory_id > 0) {
                    toast.success('저장하였습니다.', {
                        autoClose: 3000,
                    })
                } else {
                    window.location.href = `/marketing/recommend/category/${result.id}/editor`
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    handleFileInput = async (e) => {
        const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file.type,
                        },
                    })
                    .then((response) => {
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'image',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    if (this.state.uploadIndex === 0) {
                                        this.setState({
                                            image_id: result.id,
                                            image_path: result.image_path,
                                        })
                                    } else {
                                        this.setState({
                                            detail_image_id: result.id,
                                            detail_image_path: result.image_path,
                                        })
                                    }
                                    console.log(result)
                                    // main_vue.completeImage(file, response.data.result)
                                } else {
                                    console.log(error)
                                    // main_vue.failImage(file)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                // main_vue.failImage(file)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        // main_vue.failImage(file)
                    })
            } else {
                // main_vue.failImage(file)
            }
        })
    }

    addMainCategory = (category) => {
        console.log('add', category)
        this.setState({
            category_main_id: category.id,
            category_main_title: category.title,
            image_id: category.image_id ?? 0,
            image_path: category.image_path ?? '',
            category_sub_id: 0,
            main_category_id: 0,
            showCategoryModal: false,
        })
    }

    addSubCategory = (category) => {
        console.log('add', category)
        this.setState({
            category_sub_id: category.id,
            category_sub_title: category.title,
            image_id: category.image_id ?? 0,
            image_path: category.image_path ?? '',
            category_main_id: category.category_main_id,
            main_category_id: 0,
            showCategoryModal: false,
        })
    }

    addCuration = (curation) => {
        console.log('add', curation)
        this.setState({
            main_category_id: curation.id,
            main_category_title: curation.title,
            image_id: curation.image_id,
            image_path: curation.image_path,
            category_main_id: 0,
            category_sub_id: 0,
            showCurationModal: false,
        })
    }

    render() {
        const closeCategoryModal = () => this.setState({showCategoryModal: false})
        const closeCurationModal = () => this.setState({showCurationModal: false})
        return (
            <>
                <PageTitle breadcrumbs={[]}>추천 카테고리 에디터</PageTitle>

                <CategoryModal show={this.state.showCategoryModal} onAddMain={this.addMainCategory} onAddSub={this.addSubCategory} onHide={closeCategoryModal} />
                <CurationModal show={this.state.showCurationModal} onAdd={this.addCuration} onHide={closeCurationModal} />
                <div
                    style={{
                        visibility: 'hidden',
                    }}
                >
                    <input
                        type='file'
                        name='file'
                        ref={(input) => {
                            this.file = input
                        }}
                        onChange={(e) => this.handleFileInput(e)}
                    />
                </div>
                <div style={{padding: 40, backgroundColor: '#FFF', borderRadius: 8}}>
                    <div style={{marginTop: 40}}>
                        <h3>
                            {this.state.category_main_id === 0 && this.state.category_sub_id === 0 && this.state.main_category_id === 0 && '선택해 주세요'}
                            {this.state.category_main_id > 0 && this.state.category_sub_id === 0 && '카테고리 메인'}
                            {this.state.category_sub_id > 0 && '카테고리 서브'}
                            {this.state.main_category_id > 0 && '큐레이션'}
                            {' - '}
                            {this.state.category_main_id > 0 && this.state.category_sub_id === 0 && this.state.category_main_title}
                            {this.state.category_sub_id > 0 && this.state.category_sub_title}
                            {this.state.main_category_id > 0 && this.state.main_category_title}
                        </h3>
                    </div>
                    {this.state.recommendcategory_id < 1 && (
                        <div className='row'>
                            <Button
                                className='btn btn-outline-secondary'
                                onClick={() => {
                                    this.setState({
                                        showCategoryModal: true,
                                    })
                                }}
                            >
                                카테고리 추가
                            </Button>
                            <p>&nbsp;</p>
                            <Button
                                className='btn btn-outline-secondary'
                                onClick={() => {
                                    this.setState({
                                        showCurationModal: true,
                                    })
                                }}
                            >
                                큐레이션 추가
                            </Button>
                        </div>
                    )}
                    <div style={{marginTop: 40}}>
                        <h1>카드</h1>
                        <div
                            style={{
                                width: '100%',
                                height: 2,
                                backgroundColor: 'black',
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 20,
                            }}
                        >
                            <div
                                style={{
                                    width: 100,
                                }}
                            >
                                <h3>노출</h3>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='layout-builder[layout][header][fixed][desktop]'
                                        checked={this.state.show_app === 1}
                                        onChange={(event) => {
                                            this.setState({
                                                show_app: event.target.checked ? 1 : 0,
                                            })
                                        }}
                                    />

                                    <h3
                                        style={{
                                            margin: 0,
                                            marginLeft: 10,
                                        }}
                                    >
                                        APP
                                    </h3>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='layout-builder[layout][header][fixed][desktop]'
                                        checked={this.state.show_mobile === 1}
                                        onChange={(event) => {
                                            this.setState({
                                                show_mobile: event.target.checked ? 1 : 0,
                                            })
                                        }}
                                    />

                                    <h3
                                        style={{
                                            margin: 0,
                                            marginLeft: 10,
                                        }}
                                    >
                                        MOBILE
                                    </h3>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='layout-builder[layout][header][fixed][desktop]'
                                        checked={this.state.show_pc === 1}
                                        onChange={(event) => {
                                            this.setState({
                                                show_pc: event.target.checked ? 1 : 0,
                                            })
                                        }}
                                    />

                                    <h3
                                        style={{
                                            margin: 0,
                                            marginLeft: 10,
                                        }}
                                    >
                                        PC
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: 20,
                                width: '100%',
                                height: 1,
                                backgroundColor: '#CCC',
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 20,
                            }}
                        >
                            <div
                                style={{
                                    width: 100,
                                }}
                            >
                                <h3>제목</h3>
                            </div>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 120,
                                        }}
                                    >
                                        <h3>APP</h3>
                                    </div>
                                    <InputGroup className='pr-4'>
                                        <Form.Control
                                            type='text'
                                            placeholder='APP 제목을 입력해 주세요.'
                                            aria-describedby='inputGroupPrepend'
                                            style={{
                                                fontSize: 14,
                                            }}
                                            value={this.state.app_title}
                                            onChange={(e) => {
                                                this.setState({
                                                    app_title: e.target.value,
                                                })
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                                <div
                                    style={{
                                        marginTop: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 120,
                                        }}
                                    >
                                        <h3>MOBILE</h3>
                                    </div>
                                    <InputGroup className='pr-4'>
                                        <Form.Control
                                            type='text'
                                            placeholder='MOBILE 제목을 입력해 주세요.'
                                            aria-describedby='inputGroupPrepend'
                                            style={{
                                                fontSize: 14,
                                            }}
                                            value={this.state.mobile_title}
                                            onChange={(e) => {
                                                this.setState({
                                                    mobile_title: e.target.value,
                                                })
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                                <div
                                    style={{
                                        marginTop: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 120,
                                        }}
                                    >
                                        <h3>PC</h3>
                                    </div>
                                    <InputGroup className='pr-4'>
                                        <Form.Control
                                            type='text'
                                            placeholder='PC 제목을 입력해 주세요.'
                                            aria-describedby='inputGroupPrepend'
                                            style={{
                                                fontSize: 14,
                                            }}
                                            value={this.state.pc_title}
                                            onChange={(e) => {
                                                this.setState({
                                                    pc_title: e.target.value,
                                                })
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: 20,
                                width: '100%',
                                height: 1,
                                backgroundColor: '#CCC',
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 20,
                            }}
                        >
                            <div
                                style={{
                                    width: 100,
                                }}
                            >
                                <h3>순서</h3>
                            </div>
                            <InputGroup className='pr-4'>
                                <Form.Control
                                    type='number'
                                    placeholder='순서를 입력해 주세요.'
                                    aria-describedby='inputGroupPrepend'
                                    style={{
                                        fontSize: 14,
                                    }}
                                    value={this.state.order}
                                    onChange={(e) => {
                                        this.setState({
                                            order: e.target.value,
                                        })
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div
                            style={{
                                marginTop: 20,
                                width: '100%',
                                height: 1,
                                backgroundColor: '#CCC',
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 20,
                            }}
                        >
                            <div
                                style={{
                                    width: 100,
                                }}
                            >
                                <h3>썸네일</h3>
                            </div>
                            <div>
                                {this.state.image_path !== '' && (
                                    <img
                                        src={ImagePath.get(this.state.image_path, '150x150')}
                                        style={{
                                            width: 54,
                                            height: 54,
                                            borderRadius: 12,
                                            objectFit: 'cover',
                                        }}
                                        alt=''
                                    />
                                )}
                                <Button
                                    className='btn btn-outline-secondary'
                                    style={{
                                        marginLeft: 20,
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            uploadIndex: 0,
                                        })
                                        this.file.click()
                                    }}
                                >
                                    이미지 업로드
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 40,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            className='btn btn-outline-primary'
                            onClick={() => {
                                this.onSave()
                            }}
                        >
                            저장
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

class CategoryModal extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            categories: [],
        }
        this.getCategories()
    }

    getCategories() {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/category`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    this.setState({
                        categories: result,
                    })
                    // this.forceUpdate()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>카테고리 리스트</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
                        <div className='row'>
                            {this.state.categories.map((category, index) => {
                                return (
                                    <div
                                        key={index.toString()}
                                        className='col-lg-3'
                                        style={{
                                            padding: 10,
                                        }}
                                    >
                                        <Card>
                                            <Card.Header>
                                                <h2>{category.title}</h2>
                                                <Button
                                                    className='btn btn-outline-primary'
                                                    onClick={() => {
                                                        this.props.onAddMain(category)
                                                    }}
                                                >
                                                    선택
                                                </Button>
                                            </Card.Header>
                                            <Card.Body>
                                                {category.subs.map((sub, subIndex) => {
                                                    return (
                                                        <div key={subIndex.toString()}>
                                                            <h3>{sub.title}</h3>
                                                            <Button
                                                                className='btn btn-outline-primary'
                                                                onClick={() => {
                                                                    this.props.onAddSub(sub)
                                                                }}
                                                            >
                                                                선택
                                                            </Button>
                                                            <br />
                                                        </div>
                                                    )
                                                })}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class CurationModal extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            categories: [],
        }
        this.getCurations()
    }

    getCurations() {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/main/category`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    this.setState({
                        categories: result,
                    })
                    // this.forceUpdate()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>카테고리 리스트</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row col-12' style={{padding: 10, backgroundColor: '#FFF', borderRadius: 10}}>
                        <div className='row'>
                            {this.state.categories.map((category, index) => {
                                return (
                                    <div
                                        key={index.toString()}
                                        className='col-lg-4'
                                        style={{
                                            padding: 5,
                                        }}
                                    >
                                        <Card>
                                            <Card.Header>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <table style={{fontSize: '15px', width: '100%'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td>{category.title}</td>
                                                                <td>
                                                                    {category.subs.length === 0 && (
                                                                        <Button
                                                                            className='btn btn-sm btn-outline-primary'
                                                                            onClick={() => {
                                                                                this.props.onAdd(category)
                                                                            }}
                                                                        >
                                                                            선택
                                                                        </Button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                {category.subs.map((sub, subIndex) => {
                                                    return (
                                                        <div key={subIndex.toString()} style={{marginTop: 4, display: 'flex'}}>
                                                            <table style={{fontSize: '15px', width: '100%'}}>
                                                                <colgroup>
                                                                    <col style={{width: '50%'}} />
                                                                    <col style={{width: 'auto'}} />
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{sub.title}</td>
                                                                        <td>
                                                                            <Button
                                                                                className='btn btn-outline-primary'
                                                                                onClick={() => {
                                                                                    this.props.onAdd(sub)
                                                                                }}
                                                                            >
                                                                                선택
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <br />
                                                        </div>
                                                    )
                                                })}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
