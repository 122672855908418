import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import moment from 'moment'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {isMobile} from 'react-device-detect'
import {BookingPlaceItem} from '../../components/BookingPlaceItem'

export function BookingPlaceDetail() {
  const {booking_id} = useParams<{booking_id: string}>()

  const [bookingInfo, setBookingInfo] = useState<any>(null)

  const [log, setLog] = useState(6)

  const [adminNotesComment, setAdminNotesComment] = useState('채널 & 인/아웃 :\n고객 유형:\n문의 내용:\n답변 내용:\n참고사항:')

  useEffect(() => {
    getBookingInfo2()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getBookingInfo2 = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setBookingInfo(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const setBet = (bet: number) => {
    if (window.confirm('신호등 분류 처리하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`, {bet})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temp = Object.assign({}, bookingInfo)
            temp.booking.bet = bet
            setBookingInfo(temp)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const setApproved = () => {
    if (window.confirm('관리자 승인 처리하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`, {status: 'approved'})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temp = Object.assign({}, bookingInfo)
            temp.booking.status = 'approved'
            temp.booking.status_display = '호스트 승인대기'
            setBookingInfo(temp)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const setProceeding = () => {
    if (window.confirm('호스트 승인 처리하시겠습니까?')) {
      const password = window.prompt('비밀번호를 적어주세요.', '')
      if (password === 'hour1234$$') {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`, {status: 'proceeding'})
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              const temp = Object.assign({}, bookingInfo)
              temp.booking.status = 'proceeding'
              temp.booking.status_display = '승인완료'
              setBookingInfo(temp)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const refund = (item: any) => {
    if (window.confirm('환불 완료 처리하시겠습니까?')) {
      const password = window.prompt('비밀번호를 적어주세요.', '')
      if (password === 'hour1234$$') {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/booking/refund/${item.booking.refund_id}/complete`)
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              const temp = Object.assign({}, bookingInfo)
              temp.booking.is_refund = 1
              setBookingInfo(temp)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const getPopbillViewUrl = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/popbill/view/url`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          console.log(result)
          window.open(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const taxinvoice = () => {
    if (window.confirm('세금계산서 선발행을 하시겠습니까?')) {
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/taxinvoice`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, bookingInfo)
            if (null != temp.kcp) {
              temp.kcp.popbill_type = 'A'
            }

            if (null != temp.tossPayment) {
              temp.tossPayment.popbill_type = 'A'
            }
            setBookingInfo(temp)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const adminNoteInsert = () => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/admin/note`, {comment: adminNotesComment})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          setBookingInfo(result.result)
          setAdminNotesComment('채널 & 인/아웃 : \n고객 유형: \n문의 내용: \n답변 내용: \n참고사항:')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const cashToPay = () => {
    if (window.confirm('해당 예약건을 예외 정산건으로 전환하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/cash/pay`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            setBookingInfo(result.result)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const payToCash = () => {
    if (window.confirm('호스트 정산 금액을 캐시로 전환하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/pay/cash`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            setBookingInfo(result.result)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>예약 상세</PageTitle>

      <div
        style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {bookingInfo !== null && (
          <div
            style={{
              width: '100%',
            }}
          >
            <h1>{bookingInfo.booking.status_display}</h1>
            <BookingPlaceItem booking={bookingInfo.booking} place={bookingInfo.place} detailButton={false} />
          </div>
        )}

        <div
          style={{
            padding: isMobile ? 0 : 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='col-12'>
            <div className='card card-custom card-stretch shadow mb-5'>
              <div className='card-header'>{bookingInfo !== null && <h1 className='card-title'>예약 번호 : {bookingInfo.booking.id}</h1>}</div>
              <div
                className='card-body'
                style={{
                  padding: isMobile ? 0 : 20,
                }}
              >
                {bookingInfo !== null && (
                  <div>
                    {/*<BookingPlaceItem booking={bookingInfo.booking} status={''} />*/}

                    <div className='card card-custom card-stretch shadow mb-5'>
                      <div className='card-body'>
                        <TextSub title={'예약 번호'} subTitle={bookingInfo.booking.id} />
                        {bookingInfo.booking.genre_tag !== '' && <TextSub title={'촬영 목적'} subTitle={bookingInfo.booking.genre} />}
                        <TextSub title={'촬영 종류(장르)'} subTitle={bookingInfo.booking.genre_tag !== '' ? bookingInfo.booking.genre_tag : bookingInfo.booking.genre} />
                        <TextSub title={'촬영 내용(제품)'} subTitle={bookingInfo.booking.content} />
                        <TextSub title={'기본 인원'} subTitle={`${bookingInfo.booking.place_people} 명`} />
                        <TextSub title={'촬영 인원'} subTitle={`${bookingInfo.booking.people} 명`} />
                        {bookingInfo.booking.parking_count >= 0 && <TextSub title={'차량 대수'} subTitle={`${bookingInfo.booking.parking_count}대`} />}
                        {bookingInfo.booking.parking !== '' && <TextSub title={'(구)차량 대수'} subTitle={bookingInfo.booking.parking} />}
                        {bookingInfo.booking.detail_equipment !== '' && <TextSub title={'큰 장비/차량'} subTitle={bookingInfo.booking.detail_equipment} />}
                        {bookingInfo.booking.equipment !== '' && <TextSub title={'(구)큰 장비/차량'} subTitle={bookingInfo.booking.equipment} />}
                        <TextSub
                          title={'촬영 스케줄'}
                          subTitle={`${moment(bookingInfo.booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(bookingInfo.booking.end, 'YYYYMMDDHHmmss').format(
                            'YYYY-MM-DD HH:mm'
                          )}`}
                        />
                        <TextSub title={'촬영 시간'} subTitle={bookingInfo.booking.hour} />
                        {bookingInfo.booking.detail_place !== '' && <TextSub title={'(구)촬영 세부 장소'} subTitle={bookingInfo.booking.detail_place} />}
                        {bookingInfo.booking.detail_people !== '' && <TextSub title={'(구)인원 상세'} subTitle={bookingInfo.booking.detail_people} />}
                        {bookingInfo.booking.price_extra === 0 && <TextSub title={'할증 정보'} subTitle={'기본 할증'} />}
                        {bookingInfo.booking.price_extra > 0 && <TextSub title={'할증 정보'} subTitle={'인당 ' + bookingInfo.booking.price_extra + '원'} />}
                      </div>
                    </div>

                    <div className='card card-custom card-stretch shadow mb-5'>
                      <div className='card-body'>
                        {bookingInfo.booking.booking_id > 0 && <TextSub title={'추가 결제'} subTitle={bookingInfo.booking.additional_content} />}
                        <TextSub title={'게스트 결제 금액\n(부가세 포함)'} subTitle={bookingInfo.booking.price_guest_vat.toLocaleString('en')} />
                        <TextSub title={'쿠폰 사용\n(부가세 포함)'} subTitle={bookingInfo.booking.price_coupon.toLocaleString('en')} />
                        <TextSub title={'포인트 사용\n(부가세 포함)'} subTitle={bookingInfo.booking.price_point.toLocaleString('en')} />
                        <TextSub
                          title={'실 결제 금액\n(부가세 포함)'}
                          subTitle={(bookingInfo.booking.price_guest_vat - bookingInfo.booking.price_point - bookingInfo.booking.price_coupon).toLocaleString('en')}
                        />

                        <TextSub title={'호스트 수익 금액'} subTitle={bookingInfo.booking.price_host.toLocaleString('en')} />
                        <TextSub
                          title={'호스트 개인 정산\n(원천징수)'}
                          subTitle={`${bookingInfo.booking.price_host_tax_last.toLocaleString('en')} (${bookingInfo.booking.price_host_tax.toLocaleString('en')})`}
                        />
                        <TextSub
                          title={'호스트 사업자 정산\n(부가세)'}
                          subTitle={`${bookingInfo.booking.price_host_vat_last.toLocaleString('en')} (${bookingInfo.booking.price_host_vat.toLocaleString('en')})`}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {bookingInfo !== null && (
                  <div>
                    {bookingInfo.booking.guest_booking_display_price !== undefined && bookingInfo.booking.booking_id > 0 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>게스트 금액 표기</h2>
                          <br />
                          <TextSub title={'총 금액 합계'} subTitle={bookingInfo.booking.guest_booking_display_price.booking_total_price} />
                        </div>
                      </div>
                    )}

                    {bookingInfo.booking.guest_booking_display_price !== undefined && bookingInfo.booking.booking_id === 0 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>게스트 금액 표기</h2>
                          <br />
                          <TextSub title={'총 금액 합계'} subTitle={bookingInfo.booking.guest_booking_display_price.booking_total_price} />
                          {bookingInfo.booking.price_type === 1 ? (
                            <TextSub title={`총 ${bookingInfo.booking.guest_booking_display_price.booking_hour}시간`} subTitle={bookingInfo.booking.guest_booking_display_price.booking_price} />
                          ) : (
                            <TextSub
                              title={`${bookingInfo.booking.guest_booking_display_price.booking_day_price}원 x ${bookingInfo.booking.guest_booking_display_price.booking_day_hour}시간`}
                              subTitle={bookingInfo.booking.guest_booking_display_price.booking_day_total_price}
                            />
                          )}

                          {bookingInfo.booking.price_type === 1 && bookingInfo.booking.guest_booking_display_price.booking_weekday_hour > 0 && (
                            <TextSub
                              title={`평일 ${bookingInfo.booking.guest_booking_display_price.booking_weekday_price}원 x ${bookingInfo.booking.guest_booking_display_price.booking_weekday_hour}시간`}
                              subTitle={bookingInfo.booking.guest_booking_display_price.booking_weekday_total_price}
                            />
                          )}

                          {bookingInfo.booking.price_type === 1 && bookingInfo.booking.guest_booking_display_price.booking_weekend_hour > 0 && (
                            <TextSub
                              title={`주말 ${bookingInfo.booking.guest_booking_display_price.booking_weekend_price}원 x ${bookingInfo.booking.guest_booking_display_price.booking_weekend_hour}시간`}
                              subTitle={bookingInfo.booking.guest_booking_display_price.booking_weekend_total_price}
                            />
                          )}

                          {bookingInfo.booking.guest_booking_display_price.booking_additional_price !== '0' && (
                            <TextSub title={'총인원'} subTitle={`추가 ${bookingInfo.booking.guest_booking_display_price.booking_additional_price}원`} />
                          )}

                          {bookingInfo.booking.guest_booking_display_price.booking_info_additional_text !== '' && (
                            <TextSub
                              title={bookingInfo.booking.guest_booking_display_price.booking_info_text}
                              subTitle={bookingInfo.booking.guest_booking_display_price.booking_info_additional_text}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {bookingInfo.booking.guest_booking_display_price !== undefined && bookingInfo.booking.booking_id > 0 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>호스트 금액 표기</h2>
                          <br />
                          <TextSub title={'총 금액 합계'} subTitle={bookingInfo.booking.price_host.toLocaleString('en')} />
                        </div>
                      </div>
                    )}

                    {bookingInfo.booking.host_booking_display_price !== undefined && bookingInfo.booking.booking_id === 0 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>호스트 금액 표기</h2>
                          <br />
                          <TextSub title={'총 금액 합계'} subTitle={bookingInfo.booking.host_booking_display_price.booking_total_price} />
                          {bookingInfo.booking.price_type === 1 ? (
                            <TextSub title={`총 ${bookingInfo.booking.host_booking_display_price.booking_hour}시간`} subTitle={bookingInfo.booking.host_booking_display_price.booking_price} />
                          ) : (
                            <TextSub
                              title={`${bookingInfo.booking.host_booking_display_price.booking_day_price}원 x ${bookingInfo.booking.host_booking_display_price.booking_day_hour}시간`}
                              subTitle={bookingInfo.booking.host_booking_display_price.booking_day_total_price}
                            />
                          )}

                          {bookingInfo.booking.price_type === 1 && bookingInfo.booking.host_booking_display_price.booking_weekday_hour > 0 && (
                            <TextSub
                              title={`평일 ${bookingInfo.booking.host_booking_display_price.booking_weekday_price}원 x ${bookingInfo.booking.host_booking_display_price.booking_weekday_hour}시간`}
                              subTitle={bookingInfo.booking.host_booking_display_price.booking_weekday_total_price}
                            />
                          )}

                          {bookingInfo.booking.price_type === 1 && bookingInfo.booking.host_booking_display_price.booking_weekend_hour > 0 && (
                            <TextSub
                              title={`주말 ${bookingInfo.booking.host_booking_display_price.booking_weekend_price}원 x ${bookingInfo.booking.host_booking_display_price.booking_weekend_hour}시간`}
                              subTitle={bookingInfo.booking.host_booking_display_price.booking_weekend_total_price}
                            />
                          )}

                          {bookingInfo.booking.host_booking_display_price.booking_additional_price !== '0' && (
                            <TextSub title={'총인원'} subTitle={`추가 ${bookingInfo.booking.host_booking_display_price.booking_additional_price}원`} />
                          )}

                          {bookingInfo.booking.host_booking_display_price.booking_info_additional_text !== '' && (
                            <TextSub title={bookingInfo.booking.host_booking_display_price.booking_info_text} subTitle={bookingInfo.booking.host_booking_display_price.booking_info_additional_text} />
                          )}
                        </div>
                      </div>
                    )}

                    {bookingInfo.kcp !== undefined && bookingInfo.kcp !== null && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>결제 정보 - KCP</h2>
                          <br />
                          <TextSub
                            title={'결제 타입'}
                            subTitle={
                              bookingInfo.kcp.use_pay_method === '001000000000'
                                ? '가상 계좌'
                                : bookingInfo.kcp.use_pay_method === '100000000000'
                                ? '카드 결제'
                                : bookingInfo.kcp.use_pay_method === '200000000000'
                                ? '간편 결제'
                                : bookingInfo.kcp.use_pay_method === '300000000000'
                                ? '수기 결제'
                                : bookingInfo.kcp.use_pay_method
                            }
                          />
                          {bookingInfo.kcp.use_pay_method === '001000000000' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.kcp.bank_name} />
                              <TextSub title={''} subTitle={bookingInfo.kcp.account} />
                              <TextSub title={''} subTitle={bookingInfo.kcp.tno} />
                            </>
                          )}
                          {bookingInfo.kcp.use_pay_method === '100000000000' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.kcp.card_name} />
                              <TextSub title={''} subTitle={bookingInfo.kcp.cash_no} />
                              <TextSub title={''} subTitle={'일반결제'} />
                            </>
                          )}
                          {bookingInfo.kcp.use_pay_method === '200000000000' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.kcp.card_name} />
                              <TextSub title={''} subTitle={'간편결제'} />
                            </>
                          )}
                          {bookingInfo.kcp.use_pay_method === '300000000000' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.kcp.card_name} />
                              <TextSub title={''} subTitle={'수기결제'} />
                            </>
                          )}
                          {bookingInfo.booking.team_kcp_info !== undefined ? <TextSub title={''} subTitle={'팀 리더 결제'} /> : ''}
                          <TextSub title={'결제 금액'} subTitle={Number.parseInt(bookingInfo.kcp.amount).toLocaleString('en')} />
                          {bookingInfo.kcp.use_pay_method === '001000000000' && <TextSub title={'결제일'} subTitle={bookingInfo.kcp.created_at} />}
                          {bookingInfo.kcp.use_pay_method !== '001000000000' && (
                            <TextSub title={'결제일'} subTitle={`${moment(bookingInfo.kcp.app_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}`} />
                          )}
                          {bookingInfo.kcp.use_pay_method === '001000000000' && null !== bookingInfo.kcp.tx_tm && bookingInfo.kcp.tx_tm !== undefined && (
                            <>
                              <TextSub
                                title={'가상계좌 입금 일자'}
                                subTitle={`${bookingInfo.kcp.tx_tm.substring(0, 4)}-${bookingInfo.kcp.tx_tm.substring(4, 6)}-${bookingInfo.kcp.tx_tm.substring(6, 8)} ${bookingInfo.kcp.tx_tm.substring(
                                  8,
                                  10
                                )}:${bookingInfo.kcp.tx_tm.substring(10, 12)}:${bookingInfo.kcp.tx_tm.substring(12, 14)}`}
                              />
                            </>
                          )}
                          {bookingInfo.kcp.use_pay_method === '001000000000' &&
                            bookingInfo.kcp.tx_cd === 'TX00' &&
                            (bookingInfo.kcp.popbill_type === 'A' || bookingInfo.kcp.popbill_type === 'B' || bookingInfo.kcp.popbill_type === 'C') &&
                            bookingInfo.booking.pop_bill_status_display === '' && (
                              <>
                                <TextSub title={'결제 증빙'} subTitle={bookingInfo.booking.tax_bill_type_display} />
                                <label
                                  onClick={() => {
                                    getPopbillViewUrl()
                                  }}
                                  style={{marginLeft: 0}}
                                  className='btn btn-primary'
                                >
                                  {bookingInfo.booking.tax_bill_type_display}
                                </label>
                              </>
                            )}
                          {bookingInfo.kcp.use_pay_method === '001000000000' &&
                            bookingInfo.kcp.tx_cd === 'TX00' &&
                            bookingInfo.kcp.popbill_type === '' &&
                            bookingInfo.booking.pop_bill_status_display !== '' && (
                              <>
                                <TextSub title={'결제 증빙'} subTitle={bookingInfo.booking.pop_bill_status_display} />
                              </>
                            )}
                          {bookingInfo.booking.status !== 'cancelled' &&
                            bookingInfo.kcp.use_pay_method === '001000000000' &&
                            bookingInfo.kcp.tx_cd === '' &&
                            bookingInfo.kcp.popbill_type === '' &&
                            bookingInfo.booking.tax_bill_type_display === '세금계산서' && (
                              <>
                                <TextSub title={'결제 증빙'} subTitle={bookingInfo.booking.tax_bill_type_display} />
                                <label
                                  onClick={() => {
                                    taxinvoice()
                                  }}
                                  style={{marginLeft: 0}}
                                  className='btn btn-primary'
                                >
                                  세금계산서 선발행
                                </label>
                              </>
                            )}
                          {bookingInfo.kcp.use_pay_method === '001000000000' &&
                            bookingInfo.kcp.tx_cd === '' &&
                            bookingInfo.kcp.popbill_type === 'A' &&
                            bookingInfo.booking.pop_bill_status_display === '' && (
                              <>
                                <TextSub title={'사업자번호'} subTitle={bookingInfo.booking.pop_bill_invoicee_corp_num} />
                                <TextSub title={'이메일'} subTitle={bookingInfo.booking.pop_bill_invoicee_email1} />
                                <TextSub title={'결제 증빙'} subTitle={'세금계산서 선발행 완료'} />
                              </>
                            )}
                          {bookingInfo.kcp.use_pay_method === '001000000000' &&
                            bookingInfo.kcp.tx_cd === '' &&
                            bookingInfo.kcp.popbill_type === 'A' &&
                            bookingInfo.booking.pop_bill_status_display !== '' && (
                              <>
                                <TextSub title={'사업자번호'} subTitle={bookingInfo.booking.pop_bill_invoicee_corp_num} />
                                <TextSub title={'이메일'} subTitle={bookingInfo.booking.pop_bill_invoicee_email1} />
                                <TextSub title={'결제 증빙'} subTitle={'세금계산서 선발행 취소'} />
                              </>
                            )}
                        </div>
                      </div>
                    )}
                    {bookingInfo.tossPayment !== undefined && bookingInfo.tossPayment !== null && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>결제 정보 - TOSS</h2>
                          <br />
                          <TextSub
                            title={'결제 타입'}
                            subTitle={
                              bookingInfo.tossPayment.method === '가상계좌'
                                ? '가상 계좌'
                                : bookingInfo.tossPayment.method === '카드' && bookingInfo.tossPayment.type === 'NORMAL'
                                ? '카드 결제'
                                : bookingInfo.tossPayment.method === '카드' && bookingInfo.tossPayment.type === 'BILLING'
                                ? '간편 결제'
                                : bookingInfo.tossPayment.method === '300000000000'
                                ? '수기 결제'
                                : bookingInfo.tossPayment.method
                            }
                          />
                          {bookingInfo.tossPayment.method === '가상계좌' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.tossPayment.virtualAccountBankName} />
                              <TextSub title={''} subTitle={bookingInfo.tossPayment.virtualAccountAccountNumber} />
                              <TextSub title={''} subTitle={bookingInfo.tossPayment.virtualAccountCustomerName} />
                            </>
                          )}
                          {bookingInfo.tossPayment.method === '카드' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.tossPayment.cardIssuerName} />
                              {bookingInfo.tossPayment.type === 'NORMAL' && <TextSub title={''} subTitle={'일반결제'} />}
                              {bookingInfo.tossPayment.type === 'BILLING' && <TextSub title={''} subTitle={'간편결제'} />}
                            </>
                          )}
                          {bookingInfo.tossPayment.method === '300000000000' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.tossPayment.cardIssuerCode} />
                              <TextSub title={''} subTitle={'수기결제'} />
                            </>
                          )}
                          {bookingInfo.booking.team_toss_info !== undefined ? <TextSub title={''} subTitle={'팀 리더 결제'} /> : ''}
                          <TextSub title={'결제 금액'} subTitle={bookingInfo.tossPayment.totalAmount.toLocaleString('en')} />
                          <TextSub title={'결제일'} subTitle={bookingInfo.tossPayment.requestedAt} />
                          {bookingInfo.tossPayment.method === '가상계좌' && bookingInfo.tossPayment.status === 'DONE' && (
                            <>
                              <TextSub title={'가상계좌 입금 일자'} subTitle={`${bookingInfo.tossPayment.approvedAt}`} />
                            </>
                          )}
                          {bookingInfo.tossPayment.method === '계좌이체' && bookingInfo.tossPayment.status === 'DONE' && (
                            <>
                              <TextSub title={'계좌이체은행명'} subTitle={`${bookingInfo.tossPayment.transferBankCode}`} />
                            </>
                          )}
                          {(bookingInfo.tossPayment.method === '가상계좌' || bookingInfo.tossPayment.method === '계좌이체') &&
                            bookingInfo.tossPayment.status === 'DONE' &&
                            (bookingInfo.tossPayment.popbill_type === 'A' || bookingInfo.tossPayment.popbill_type === 'B' || bookingInfo.tossPayment.popbill_type === 'C') &&
                            bookingInfo.booking.pop_bill_status_display === '' && (
                              <>
                                <TextSub title={'결제 증빙'} subTitle={bookingInfo.booking.tax_bill_type_display} />
                                <label
                                  onClick={() => {
                                    getPopbillViewUrl()
                                  }}
                                  style={{marginLeft: 0}}
                                  className='btn btn-primary'
                                >
                                  {bookingInfo.booking.tax_bill_type_display}
                                </label>
                              </>
                            )}
                          {(bookingInfo.tossPayment.method === '가상계좌' || bookingInfo.tossPayment.method === '계좌이체') &&
                            bookingInfo.tossPayment.status === 'DONE' &&
                            bookingInfo.tossPayment.popbill_type === '' &&
                            bookingInfo.booking.pop_bill_status_display !== '' && (
                              <>
                                <TextSub title={'결제 증빙'} subTitle={bookingInfo.booking.pop_bill_status_display} />
                              </>
                            )}
                          {bookingInfo.booking.status !== 'cancelled' &&
                            bookingInfo.tossPayment.method === '가상계좌' &&
                            bookingInfo.tossPayment.status === 'WAITING_FOR_DEPOSIT' &&
                            bookingInfo.tossPayment.popbill_type === '' &&
                            bookingInfo.booking.tax_bill_type_display === '세금계산서' && (
                              <>
                                <TextSub title={'결제 증빙'} subTitle={bookingInfo.booking.tax_bill_type_display} />
                                <label
                                  onClick={() => {
                                    taxinvoice()
                                  }}
                                  style={{marginLeft: 0}}
                                  className='btn btn-primary'
                                >
                                  세금계산서 선발행
                                </label>
                              </>
                            )}
                          {bookingInfo.tossPayment.method === '가상계좌' &&
                            bookingInfo.tossPayment.status === 'WAITING_FOR_DEPOSIT' &&
                            bookingInfo.tossPayment.popbill_type === 'A' &&
                            bookingInfo.booking.pop_bill_status_display === '' && (
                              <>
                                <TextSub title={'사업자번호'} subTitle={bookingInfo.booking.pop_bill_invoicee_corp_num} />
                                <TextSub title={'이메일'} subTitle={bookingInfo.booking.pop_bill_invoicee_email1} />
                                <TextSub title={'결제 증빙'} subTitle={'세금계산서 선발행 완료'} />
                              </>
                            )}
                          {bookingInfo.tossPayment.method === '가상계좌' &&
                            bookingInfo.tossPayment.status === 'WAITING_FOR_DEPOSIT' &&
                            bookingInfo.tossPayment.popbill_type === 'A' &&
                            bookingInfo.booking.pop_bill_status_display !== '' && (
                              <>
                                <TextSub title={'사업자번호'} subTitle={bookingInfo.booking.pop_bill_invoicee_corp_num} />
                                <TextSub title={'이메일'} subTitle={bookingInfo.booking.pop_bill_invoicee_email1} />
                                <TextSub title={'결제 증빙'} subTitle={'세금계산서 선발행 취소'} />
                              </>
                            )}
                        </div>
                      </div>
                    )}
                    {bookingInfo.naverPayPayments !== undefined && bookingInfo.naverPayPayments !== null && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>결제 정보 - 네이버페이</h2>
                          <br />
                          <TextSub title={'결제 타입'} subTitle={bookingInfo.naverPayPayments.primaryPayMeans} />
                          {bookingInfo.naverPayPayments.primaryPayMeans === '신용카드' && <TextSub title={''} subTitle={bookingInfo.naverPayPayments.cardCorpCode} />}
                          {bookingInfo.naverPayPayments.primaryPayMeans === '계좌이체' && (
                            <>
                              <TextSub title={''} subTitle={bookingInfo.naverPayPayments.bankCorpCode} />
                              <TextSub title={''} subTitle={bookingInfo.naverPayPayments.bankAccountNo} />
                            </>
                          )}
                          {bookingInfo.naverPayPayments.primaryPayMeans === '포인트/머니 결제' && <TextSub title={''} subTitle={bookingInfo.naverPayPayments.npointPayAmount} />}
                          <TextSub title={'결제 금액'} subTitle={bookingInfo.naverPayPayments.totalPayAmount.toLocaleString('en')} />
                          <TextSub title={'결제일'} subTitle={bookingInfo.naverPayPayments.admissionYmdt} />
                        </div>
                      </div>
                    )}

                    {bookingInfo.kakaopayPayments !== undefined && bookingInfo.kakaopayPayments !== null && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>결제 정보 - 카카오페이</h2>
                          <br />
                          <TextSub title={'결제 타입'} subTitle={bookingInfo.kakaopayPayments.payment_method_type === 'CARD' ? '카드결제' : '카카오머니결제'} />
                          {bookingInfo.kakaopayPayments.payment_method_type === 'CARD' && <TextSub title={''} subTitle={bookingInfo.kakaopayPayments.s_card_corp_name} />}
                          <TextSub title={'결제 금액'} subTitle={bookingInfo.kakaopayPayments.total.toLocaleString('en')} />
                          <TextSub title={'결제일'} subTitle={bookingInfo.kakaopayPayments.k_approved_at} />
                        </div>
                      </div>
                    )}

                    {bookingInfo !== undefined && bookingInfo.booking.status === 'host_paid' && bookingInfo.pay !== null && bookingInfo.pay !== undefined && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>지불 정보</h2>
                          <br />
                          <TextSub title={'개인/사업자'} subTitle={bookingInfo.pay.type} />
                          <TextSub title={'예금주'} subTitle={bookingInfo.pay.bank_owner} />
                          <TextSub title={'은행명'} subTitle={bookingInfo.pay.bank_name} />
                          <TextSub title={'계좌번호'} subTitle={bookingInfo.pay.bank_account} />
                          <TextSub title={'지불일'} subTitle={bookingInfo.pay.pay_day} />
                          <TextSub title={'지불 금액'} subTitle={bookingInfo.pay.pay.toLocaleString('en')} />
                        </div>
                      </div>
                    )}

                    {bookingInfo !== undefined && (bookingInfo.booking.status === 'completed' || bookingInfo.booking.status === 'host_paid') && bookingInfo.booking.is_pay_cash === 1 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>호스트 정산 정보</h2>
                          <br />
                          <TextSub title={'캐시 정산 여부'} subTitle={'Y'} />
                          <TextSub title={'정산 가능 캐시'} subTitle={bookingInfo.booking.cash_pay_price.toLocaleString('en')} />
                          <label
                            style={{marginLeft: 0}}
                            className='btn btn-primary'
                            onClick={() => {
                              cashToPay()
                            }}
                          >
                            캐시 정산 제외
                          </label>
                        </div>
                      </div>
                    )}

                    {bookingInfo !== undefined && bookingInfo.booking.status === 'completed' && bookingInfo.booking.is_pay_cash === 0 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>호스트 정산 정보</h2>
                          <br />
                          <TextSub title={'캐시 정산 여부'} subTitle={'N'} />
                          <TextSub title={'정산 가능 캐시'} subTitle={bookingInfo.booking.cash_pay_price.toLocaleString('en')} />
                          <label
                            style={{marginLeft: 0}}
                            className='btn btn-primary'
                            onClick={() => {
                              payToCash()
                            }}
                          >
                            캐시로 적립
                          </label>
                        </div>
                      </div>
                    )}

                    { (bookingInfo.booking.status === 'cancelled' || ( bookingInfo.booking.status === 'pending_cancelled' && bookingInfo.booking.payments_type === 2 )) && bookingInfo.booking.refund_id !== undefined && bookingInfo.booking.refund_id > 0 && (
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                          <h2>환불 계좌 정보</h2>
                          <br />
                          <TextSub title={'은 행 명'} subTitle={bookingInfo.booking.refund_bank_name} />
                          <TextSub title={'예 금 주'} subTitle={bookingInfo.booking.refund_bank_user_name} />
                          <TextSub title={'계좌 정보'} subTitle={bookingInfo.booking.refund_bank_account} />
                          <TextSub title={'계좌 정보 등록 시간'} subTitle={bookingInfo.booking.refund_created_at} />
                          <TextSub title={'계좌 정보 수정 시간'} subTitle={bookingInfo.booking.refund_updated_at} />
                          {bookingInfo.booking.is_refund === 0 && (
                            <label
                              onClick={() => {
                                window.location.href = `/booking/place/${bookingInfo.booking.id}/edit/refund`
                              }}
                              style={{marginRight: 10}}
                              className='btn btn-sm btn-primary'
                            >
                              환불 계좌 정보 수정
                            </label>
                          )}
                          {bookingInfo.booking.is_refund === 0 && (
                            <label
                              onClick={() => {
                                refund(bookingInfo)
                              }}
                              style={{marginLeft: 10}}
                              className='btn btn-sm btn-primary'
                            >
                              환불 완료 처리
                            </label>
                          )}
                          {bookingInfo.booking.is_refund === 1 && <TextSub title={'환불 완료 시간'} subTitle={bookingInfo.booking.refund_at} />}
                          {bookingInfo.booking.is_refund === 1 && <label className='btn btn-sm btn-success'>환불 완료</label>}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {bookingInfo !== null && (
                <div className='card-footer'>
                  {bookingInfo.booking.bet < 0 && (
                    <div
                      className={'row'}
                      style={{
                        width: 180,
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        onClick={() => {
                          setBet(100)
                        }}
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          backgroundColor: '#1aa200',
                        }}
                      ></div>

                      <div
                        onClick={() => {
                          setBet(50)
                        }}
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          backgroundColor: '#FFA600',
                        }}
                      ></div>

                      <div
                        onClick={() => {
                          setBet(0)
                        }}
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          backgroundColor: '#ff0015',
                        }}
                      ></div>
                    </div>
                  )}

                  {bookingInfo.booking.bet > -1 && (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      {bookingInfo.booking.bet === 100 && (
                        <div
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            backgroundColor: '#1aa200',
                          }}
                        ></div>
                      )}

                      {bookingInfo.booking.bet === 50 && (
                        <div
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            backgroundColor: '#FFA600',
                          }}
                        ></div>
                      )}

                      {bookingInfo.booking.bet === 0 && (
                        <div
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            backgroundColor: '#ff0015',
                          }}
                        ></div>
                      )}

                      {bookingInfo.booking.status === 'pending' && (
                        <div>
                          <label
                            onClick={() => {
                              setApproved()
                            }}
                            style={{marginLeft: 10}}
                            className='btn btn-warning'
                          >
                            관리자 승인
                          </label>
                        </div>
                      )}

                      {( ( bookingInfo.booking.status === 'approved' && bookingInfo.booking.payments_type !== 2 )
                        || ( bookingInfo.booking.status === 'approved' && bookingInfo.booking.payments_type === 2 && bookingInfo.booking.paid_time > 0 ) ) && (
                        <div>
                          <label
                            onClick={() => {
                              setProceeding()
                            }}
                            style={{marginLeft: 10}}
                            className='btn btn-danger'
                          >
                            호스트 승인
                          </label>
                        </div>
                      )}

                      <label
                        onClick={() => {
                          window.location.href = `/booking/message/${bookingInfo.booking.id}`
                        }}
                        style={{marginLeft: 20}}
                        className='btn btn-primary'
                      >
                        메시지 보기
                      </label>

                      {/*@if($booking->status == 'proceeding')*/}
                      {/*<button type="button"*/}
                      {/*        class="btn btn-outline-secondary guest_paid"*/}
                      {/*        id="{{ $booking->id }}">*/}
                      {/*  결제 완료 처리*/}
                      {/*</button>*/}
                      {/*&nbsp;&nbsp;*/}
                      {/*@endif*/}
                      {/*@if($booking->status != 'completed' && $booking->status != 'cancelled' && $booking->status != 'pending_cancelled')*/}
                      {/*&nbsp;&nbsp;*/}
                      {/*<button type="button"*/}
                      {/*        class="btn btn-outline-danger"*/}
                      {/*        onclick="location.href='/booking/{{ $booking->id }}/cancel'">*/}
                      {/*  취소*/}
                      {/*</button>*/}
                      {/*@endif*/}
                      {/*@if($booking->status == 'approved')*/}
                      {/*&nbsp;&nbsp;*/}
                      {/*<button type="button"*/}
                      {/*        class="btn btn-outline-danger proceeding"*/}
                      {/*        id="{{ $booking->id }}">*/}
                      {/*  호스트 승인*/}
                      {/*</button>*/}
                      {/*@endif*/}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {bookingInfo !== null && (
            <div>
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 6 ? 'active' : '')} onClick={() => setLog(6)}>
                    관리자 노트 ({bookingInfo.booking.admin_notes !== undefined ? bookingInfo.booking.admin_notes.length.toLocaleString('en') : 0})
                  </label>
                </li>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 0 ? 'active' : '')} onClick={() => setLog(0)}>
                    예약 로그 ({bookingInfo.booking_histories !== undefined ? bookingInfo.booking_histories.length : 0})
                  </label>
                </li>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 1 ? 'active' : '')} onClick={() => setLog(1)}>
                    알림톡 로그 ({bookingInfo.alimtalk_logs !== undefined ? bookingInfo.alimtalk_logs.length : 0})
                  </label>
                </li>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 2 ? 'active' : '')} onClick={() => setLog(2)}>
                    환불 계좌 로그 ({bookingInfo.booking_refund_histories !== undefined ? bookingInfo.booking_refund_histories.length : 0})
                  </label>
                </li>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 3 ? 'active' : '')} onClick={() => setLog(3)}>
                    세금 계산서 발행 로그 ({bookingInfo.booking_taxinvoice_histories !== undefined ? bookingInfo.booking_taxinvoice_histories.length : 0})
                  </label>
                </li>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 4 ? 'active' : '')} onClick={() => setLog(4)}>
                    현금 영수증 발행 로그 ({bookingInfo.booking_cashbill_histories !== undefined ? bookingInfo.booking_cashbill_histories.length : 0})
                  </label>
                </li>
                <li className='nav-item'>
                  <label className={classNames('nav-link', log === 5 ? 'active' : '')} onClick={() => setLog(5)}>
                    관리자 예약 시간 변경 로그 ({bookingInfo.booking_time_histories !== undefined ? bookingInfo.booking_time_histories.length : 0})
                  </label>
                </li>
              </ul>

              <div>
                {log === 6 && (
                  <div>
                    <div style={{marginBottom: 30}}>
                      <textarea
                        className='form-control'
                        rows={5}
                        placeholder='태그 (구분자 ,)'
                        value={adminNotesComment}
                        onChange={(e) => {
                          setAdminNotesComment(e.target.value)
                        }}
                      />
                      <label
                        style={{marginTop: 20}}
                        onClick={() => {
                          adminNoteInsert()
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        등록
                      </label>
                    </div>
                    {bookingInfo.booking.admin_notes !== undefined &&
                      bookingInfo.booking.admin_notes.map((item: any, index: number) => {
                        return (
                          <div className='col-12' key={`1_${index}`}>
                            <div className='card card-custom card-stretch shadow mb-5'>
                              <div className='card-body'>
                                <table style={{fontSize: '15px', width: '100%'}}>
                                  <colgroup>
                                    <col className='col1' />
                                    <col style={{width: 'auto'}} />
                                  </colgroup>
                                  <tbody>
                                    <tr>
                                      <td style={{fontWeight: 'bold'}}>작성일</td>
                                      <td>{item.created_at}</td>
                                    </tr>
                                    <tr>
                                      <td style={{fontWeight: 'bold'}}>작성자 이름</td>
                                      <td>{item.admin_user_name}</td>
                                    </tr>
                                    <tr style={{height: 15}}>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td style={{fontWeight: 'bold', verticalAlign: 'top'}}>내용</td>
                                      <td>
                                        <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.comment}</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )}
                {log === 0 && (
                  <div>
                    {bookingInfo.booking_histories !== undefined &&
                      bookingInfo.booking_histories.map((history: any, index: number) => {
                        return (
                          <div className='shadow mb-5 mt-5 rounded p-5' key={`log_0_${index}`}>
                            <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                            <br />
                            <h3
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              <p dangerouslySetInnerHTML={{__html: history.content}} />
                            </h3>
                          </div>
                        )
                      })}
                  </div>
                )}
                {log === 1 && (
                  <div>
                    {bookingInfo.alimtalk_logs !== undefined &&
                      bookingInfo.alimtalk_logs.map((history: any, index: number) => {
                        return (
                          <div className='shadow mb-5 mt-5 rounded p-5' key={`log_1_${index}`}>
                            <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                            <br />
                            <h4>{history.phone}</h4>
                            <br />
                            <h3
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              <p dangerouslySetInnerHTML={{__html: history.message}} />
                            </h3>
                          </div>
                        )
                      })}
                  </div>
                )}
                {log === 2 && (
                  <div>
                    {bookingInfo.booking_refund_histories !== undefined &&
                      bookingInfo.booking_refund_histories.map((history: any, index: number) => {
                        return (
                          <div className='shadow mb-5 mt-5 rounded p-5' key={`log_2_${index}`}>
                            <h4>{moment(history.refund_created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                            <br />
                            <TextSub title={'은행명'} subTitle={history.refund_bank_name} />
                            <TextSub title={'계좌주'} subTitle={history.refund_bank_user_name} />
                            <TextSub title={'계좌번호'} subTitle={history.refund_bank_account} />
                          </div>
                        )
                      })}
                  </div>
                )}
                {log === 3 && (
                  <div>
                    {bookingInfo.booking_taxinvoice_histories !== undefined &&
                      bookingInfo.booking_taxinvoice_histories.map((history: any, index: number) => {
                        return (
                          <div className='shadow mb-5 mt-5 rounded p-5' key={`log_3_${index}`}>
                            <h4>{moment(history.updated_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                            <br />
                            <TextSub title={'상태'} subTitle={history.status === 'register' ? '발행' : '수정발행'} />
                            <TextSub title={'국세청 승인번호'} subTitle={history.ntsconfirmNum} />
                            <TextSub title={'사업자등록번호'} subTitle={history.invoiceeCorpNum} />
                            <TextSub title={'이메일'} subTitle={history.invoiceeEmail1} />
                            <TextSub title={'발행형태'} subTitle={history.issueType} />
                            <TextSub title={'과세형태'} subTitle={history.taxType} />
                            <TextSub title={'과금방향'} subTitle={history.chargeDirection} />
                            <TextSub title={'작성일자'} subTitle={history.writeDate} />
                            <TextSub title={'영수/청구'} subTitle={history.purposeType} />
                            <TextSub title={'공급가액 합계'} subTitle={history.supplyCostTotal} />
                            <TextSub title={'세액 합계'} subTitle={history.taxTotal} />
                            <TextSub title={'합계금액'} subTitle={history.totalAmount} />
                            <TextSub title={'수정 사유코드'} subTitle={history.modifyCode} />
                            <TextSub title={'원본 세금계산서'} subTitle={history.orgNTSConfirmNum} />
                            <TextSub title={'발행 결과 코드'} subTitle={history.resCode} />
                            <TextSub title={'발행 결과 메시지'} subTitle={history.resMessage} />
                            <TextSub title={'취소 결과 코드'} subTitle={history.cancelResCode} />
                            <TextSub title={'취소 결과 메시지'} subTitle={history.cancelResMessage} />
                          </div>
                        )
                      })}
                  </div>
                )}
                {log === 4 && (
                  <div>
                    {bookingInfo.booking_cashbill_histories !== undefined &&
                      bookingInfo.booking_cashbill_histories.map((history: any, index: number) => {
                        return (
                          <div className='shadow mb-5 mt-5 rounded p-5' key={`log_4_${index}`}>
                            <h4>{moment(history.updated_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                            <br />
                            <TextSub title={'문서번호'} subTitle={history.mgtKey} />
                            <TextSub title={'국세청 승인번호'} subTitle={history.confirmNum} />
                            <TextSub title={'국세청 거래일자'} subTitle={history.tradeDate} />
                            <TextSub title={'문서형태'} subTitle={history.tradeType} />
                            <TextSub title={'거래구분'} subTitle={history.tradeUsage} />
                            <TextSub title={'거래유형'} subTitle={history.tradeOpt} />
                            <TextSub title={'과세형태'} subTitle={history.taxationType} />문
                            <TextSub title={'공급가액'} subTitle={history.supplyCost} />
                            <TextSub title={'세액 합계'} subTitle={history.tax} />
                            <TextSub title={'합계금액'} subTitle={history.totalAmount} />
                            <TextSub title={'구매자(고객) 성명'} subTitle={history.customerName} />
                            <TextSub title={'주문번호'} subTitle={history.orderNumber} />
                            <TextSub title={'휴대폰'} subTitle={history.hp} />
                            <TextSub title={'발행 결과 코드'} subTitle={history.resCode} />
                            <TextSub title={'발행 결과 메시지'} subTitle={history.resMessage} />
                            <TextSub title={'취소 결과 코드'} subTitle={history.cancelResCode} />
                            <TextSub title={'취소 결과 메시지'} subTitle={history.cancelResMessage} />
                          </div>
                        )
                      })}
                  </div>
                )}
                {log === 5 && (
                  <div>
                    {bookingInfo.booking_time_histories !== undefined &&
                      bookingInfo.booking_time_histories.map((history: any, index: number) => {
                        return (
                          <div className='shadow mb-5 mt-5 rounded p-5' key={`log_0_${index}`}>
                            <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                            <br />
                            <h3
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              <p dangerouslySetInnerHTML={{__html: history.content}} />
                            </h3>
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
