import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'

import 'react-datepicker/dist/react-datepicker.css'
import classNames from 'classnames'
import Modal from '../../components/Modal'
import {toast} from 'react-toastify'

export function CreativeTag() {
    const [tagName, setTagName] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    const [tagIdM, setTagIdM] = useState('')
    const [tagNameM, setTagNameM] = useState('')
    const [imageUrlM, setImageUrlM] = useState('')

    const [modalVisible, setModalVisible] = useState(false)
    const [modalVisibleTitle, setModalVisibleTitle] = useState(false)
    const [genreTag, setGenreTag] = useState<any>([])
    const [showType, setShowType] = useState('')
    const [adminCreativeRecommend, setAdminCreativeRecommend] = useState('')

    useEffect(() => {
        getCreativeTags()
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getCreativeTags = () => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/marketing/creative/tags`)
            .then(({data: {error, result}}) => {
                if (error !== '') {
                    window.alert(error)
                } else {
                    console.log(result)
                    setGenreTag(result.creativeTags)
                    setShowType(result.adminCreativeShowType)
                    setAdminCreativeRecommend(result.adminCreativeRecommendTags)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const checkTagName = (tagName = '') => {
        setTagName(tagName)
    }

    const checkImageUrl = (imageUrl = '') => {
        setImageUrl(imageUrl)
    }

    const addCreativeTags = () => {
        if (tagName.trim().length < 1) {
            return false
        }

        if (imageUrl.trim().length < 1) {
            return false
        }

        axios
            .post(`${process.env.REACT_APP_API2_URL}am/marketing/creative/tags`, {tag_name: tagName, image_url: imageUrl, show_type: showType})
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    getCreativeTags()
                }
            })
            .catch((error) => {
                console.log(error)
            })

        setTagName('')
        setImageUrl('')
        setModalVisible(false)
    }

    const updateOrder = (tags = '') => {
        console.log(tags)
        console.log(showType)
        const tagArr = tags.trim().split(',')
        const newArray: String[] = []
        let isPass = true

        if (showType === '1' && tagArr.length !== 10) {
            alert('등록할 키워드 개수를 확인해주세요.')
            return false
        }

        if (showType === '1') {
            tagArr.forEach((ele) => {
                if (!newArray.includes(ele)) {
                    newArray.push(ele)
                } else {
                    alert('중복되는 장르태그가 있습니다.[' + ele + ']')
                    isPass = false
                }
            })
            console.log(tagArr)
            console.log(newArray)
        }

        if (!isPass) {
            return false
        }

        axios
            .put(`${process.env.REACT_APP_API2_URL}am/marketing/creative/tags/order/update`, {adminCreativeShowType: showType, adminCreativeRecommendTags: tags})
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    toast.error('변경되었습니다.')
                    getCreativeTags()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const updateTagImageUrl = () => {
        console.log('tagId : ' + tagIdM)
        console.log('imageUrlM : ' + imageUrlM)
        if (window.confirm('아이콘 이미지를 변경하시겠습니까?')) {
            if (imageUrlM.trim().length < 1) {
                alert('아이콘 이미지를 확인해주세요.')
                return false
            }

            axios
                .put(`${process.env.REACT_APP_API2_URL}am/marketing/creative/tags/image/update`, {id: tagIdM, image_url: imageUrlM})
                .then(({data: {error, result}}) => {
                    console.log(error)
                    console.log(result)
                    if (error !== '') {
                        toast.error(error)
                    } else {
                        setTagIdM('')
                        setTagNameM('')
                        setImageUrlM('')
                        getCreativeTags()
                        alert('타이틀이 변경되었습니다.')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            setModalVisibleTitle(false)
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>크리에이티브 분야</PageTitle>

            <div
                style={{
                    padding: 20,
                    borderRadius: 20,
                    backgroundColor: 'white',
                }}
            >
                <div
                    style={{
                        marginTop: 24,
                        marginLeft: 24,
                        borderRadius: 20,
                    }}
                >
                    <div className='tab-content' id='myTabContent'>
                        <h1>전체 리스트</h1>
                        <h5 style={{fontWeight: 'lighter'}}>태그를 클릭하여 이미지 경로값을 변경할 수 있습니다.</h5>

                        {genreTag.map((item: any) => {
                            return (
                                <button
                                    type='button'
                                    className='btn btn-white'
                                    style={{border: '1px solid #DDD', marginTop: '5px', marginRight: '10px'}}
                                    key={item.id}
                                    onClick={() => {
                                        setTagIdM(item.id)
                                        setTagNameM(item.tag_name)
                                        setImageUrlM(item.image_url)
                                        setModalVisibleTitle(true)
                                    }}
                                >
                                    {item.id}_{item.tag_name}
                                </button>
                            )
                        })}
                        <div style={{marginTop: '20px'}}>
                            <label
                                onClick={() => {
                                    setModalVisible(true)
                                }}
                                className='btn btn-primary'
                            >
                                추가하기
                            </label>
                        </div>
                    </div>

                    <div className='separator my-10' />

                    <div className='tab-content' id='myTabContent'>
                        <div
                            style={{
                                marginTop: 30,
                                width: '100%',
                            }}
                        >
                            <h1>인기 분야 리스트 (10개)</h1>
                            <h1 style={{marginTop: 20, marginBottom: 20}}>노출 방식</h1>
                            <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    value='1'
                                    name='recommend'
                                    id='recommend01'
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setShowType(e.target.value)
                                        }
                                    }}
                                    checked={showType === '1'}
                                />
                                <label className='form-check-label' htmlFor='recommend01'>
                                    관리자 추천 인기 분야
                                </label>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    value='2'
                                    name='recommend'
                                    id='recommend02'
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setShowType(e.target.value)
                                        }
                                    }}
                                    style={{marginLeft: 20}}
                                    checked={showType === '2'}
                                />
                                <label className='form-check-label' htmlFor='recommend02'>
                                    인기순 추천
                                </label>
                            </div>
                        </div>

                        <h1 style={{marginTop: '20px'}}>관리자 추천 인기 분야 (10개_순서 고정)</h1>
                        <textarea
                            className='form-control'
                            rows={3}
                            value={adminCreativeRecommend}
                            style={{width: '100%', border: '1px solid #DDD', resize: 'none'}}
                            onChange={(e) => {
                                setAdminCreativeRecommend(e.target.value)
                            }}
                        ></textarea>
                        <div style={{marginTop: '15px', marginBottom: '20px'}}>
                            <label
                                onClick={() => {
                                    updateOrder(adminCreativeRecommend)
                                }}
                                className='btn btn-primary'
                            >
                                수정하기
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    closable={true}
                    maskClosable={true}
                    onClose={() => {
                        setModalVisible(false)
                    }}
                    className={''}
                >
                    <div>
                        <div
                            style={{
                                marginTop: 16,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <h2>크리에이티브 분야</h2>
                            <br />
                        </div>
                        <p style={{width: '100%', marginTop: 20, marginBottom: 10}}>태그명</p>
                        <div
                            style={{
                                marginTop: 16,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                placeholder='추가할 장르태그를 입력해주세요.'
                                maxLength={18}
                                onChange={(e) => {
                                    checkTagName(e.target.value)
                                }}
                            />
                        </div>

                        <p style={{width: '100%', marginTop: 20, marginBottom: 10}}>이미지 경로</p>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                placeholder='아이콘 이미지 경로를 입력해주세요.'
                                onChange={(e) => {
                                    checkImageUrl(e.target.value)
                                }}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: 5,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{marginTop: '20px'}}>
                                <label
                                    onClick={() => {
                                        addCreativeTags()
                                    }}
                                    className={classNames('btn', {'btn-secondary': tagName.trim().length === 0}, {'btn-primary': tagName.trim().length !== 0})}
                                >
                                    등록하기
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {modalVisibleTitle && (
                <Modal
                    visible={modalVisibleTitle}
                    closable={true}
                    maskClosable={true}
                    onClose={() => {
                        setModalVisibleTitle(false)
                    }}
                    className={''}
                >
                    <div>
                        <div
                            style={{
                                marginTop: 16,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <h2>크리에이티브 분야</h2>
                            <br />
                        </div>
                        <p style={{width: '100%', marginTop: 20, marginBottom: 10}}>태그명</p>
                        <div
                            style={{
                                marginTop: 16,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                placeholder='추가할 장르태그를 입력해주세요.'
                                maxLength={18}
                                value={tagNameM}
                                readOnly={true}
                                style={{backgroundColor: '#eff2f5'}}
                            />
                        </div>

                        <p style={{width: '100%', marginTop: 20, marginBottom: 10}}>이미지 경로</p>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                placeholder='아이콘 이미지 경로를 입력해주세요.'
                                value={imageUrlM}
                                onChange={(e) => {
                                    setImageUrlM(e.target.value)
                                }}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: 5,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{marginTop: '20px'}}>
                                <label
                                    onClick={() => {
                                        updateTagImageUrl()
                                    }}
                                    className={classNames(
                                        'btn',
                                        {'btn-secondary': tagNameM.trim().length === 0 || imageUrlM.trim().length === 0},
                                        {'btn-primary': tagNameM.trim().length !== 0 && imageUrlM.trim().length !== 0}
                                    )}
                                >
                                    등록하기
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}
