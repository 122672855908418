import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import moment from 'moment'
import {toast} from 'react-toastify'
import DatePicker from 'react-datepicker'
import {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export function PayHostReport6() {
  const [date, setDate] = useState<Date | null>(new Date())

  const [items, setItems] = useState([])

  const [loading, setLoading] = useState(false)

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    registerLocale('ko', ko)

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getItems = useCallback(() => {
    setItems([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/pay/host/report2?date=${moment(date).format('YYYY-MM')}&type=2`)
      //.get(`${process.env.REACT_APP_API_URL}admin/pay/host/report2?date=${moment(date).format('YYYY-MM')}`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          toast.error(error)
        } else {
          console.log(result)
          setItems(result)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getItems()
  }, [getItems, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [
      {header: '예약번호', key: 'id', width: 10},
      {header: '예약일', key: 'id1', width: 10},
      {header: '결제일', key: 'id2', width: 10},
      {header: '취소일', key: 'id3', width: 10},
      {header: '환불계좌', key: 'id4', width: 10},
      {header: '환불일', key: 'id5', width: 10},
      {header: '결제방법', key: 'id6', width: 10},
      {header: '승인번호', key: 'id7', width: 10},
      {header: '상호', key: 'id37', width: 10},
      {header: '세금계산서', key: 'id38', width: 10},
      {header: '현금영수증', key: 'id8', width: 10},
      {header: '촬영일', key: 'id9', width: 10},
      {header: '게스트', key: 'id10', width: 10},
      {header: '이메일', key: 'id11', width: 10},
      {header: '호스트', key: 'id12', width: 10},
      {header: '이메일', key: 'id13', width: 10},
      {header: '결제 금액', key: 'id14', width: 10},
      {header: '부가세', key: 'id15', width: 10},
      {header: '쿠폰', key: 'id16', width: 10},
      {header: '포인트', key: 'id17', width: 10},
      {header: '게스트 수수료', key: 'id18', width: 10},
      {header: '호스트 원가', key: 'id19', width: 10},
      {header: '호스트 수수료', key: 'id20', width: 10},
      {header: '호스트 수익', key: 'id21', width: 10},
      {header: '호스트 세금', key: 'id22', width: 10},
      {header: '호스트 최종 지급액', key: 'id23', width: 10},
      {header: '촬영시간', key: 'id24', width: 10},
      {header: '호스트 계좌번호', key: 'id25', width: 10},
      {header: '호스트 은행', key: 'id26', width: 10},
      {header: '예금주', key: 'id27', width: 10},
      {header: '비고', key: 'id28', width: 10},
      {header: '승인번호', key: 'id36', width: 10},
      {header: '닉네임', key: 'id29', width: 10},
      {header: '장소번호', key: 'id30', width: 10},
      {header: '장소명', key: 'id31', width: 10},
      {header: '개인/사업자', key: 'id32', width: 10},
      {header: '지급일', key: 'id33', width: 10},
      {header: '지급 여부', key: 'id34', width: 10},
      {header: '예약번호', key: 'id35', width: 10},
      {header: '캐시정산', key: 'id39', width: 10},
    ]

    items.forEach((item: any) => {
      worksheet.addRow({
        id: item.id,
        id1: item.create_time,
        id2: item.paid_time,
        id3: item.cancelled_time,
        id4: '',
        id5: '',
        id6: item.method,
        id7: item.taxinvoice_nts,
        id37: item.taxinvoice_company_name,
        id38: item.taxinvoice,
        id8: item.cash,
        id9: item.start,
        id10: item.guest_id,
        id11: item.guest_email,
        id12: item.user_id,
        id13: item.host_email,
        id14: item.price_guest.toLocaleString('en'),
        id15: (item.price_guest_vat - item.price_guest).toLocaleString('en'),
        id16: item.coupon_use.toLocaleString('en'),
        id17: item.point_use.toLocaleString('en'),
        id18: item.guest_fee === 0 ? 0 : item.price_guest_fee.toLocaleString('en'),
        id19: (item.price_host * (100 / (100 - item.host_fee))).toLocaleString('en'),
        id20: item.price_host_fee.toLocaleString('en'),
        id21: item.price_host.toLocaleString('en'),
        id22: item.tax.toLocaleString('en'),
        id23: Number(item.total).toLocaleString('en'),
        id24: item.hour,
        id25: item.bank_account,
        id26: item.bank_name,
        id27: item.bank_owner,
        id28: item.is_pass_money === 1 ? '파손' : '',
        id29: item.host_name,
        id30: item.place_id,
        id31: item.place_title,
        id32: item.type_text,
        id33: item.pay_day,
        id34: item.pay_id > 0 && item.pay_status === 2 && item.pay > 0 ? 'O' : '-',
        id35: item.id,
        id36: '',
        id39: item.is_pay_cash === 1 ? 'Y' : 'N',
      })
      if (item.status === 'cancelled') {
        worksheet.addRow({
          id: item.id,
          id1: item.create_time,
          id2: item.paid_time,
          id3: item.cancelled_time,
          id4: '',
          id5: '',
          id6: item.method,
          id7: item.taxinvoice_nts,
          id37: item.taxinvoice_company_name,
          id38: item.taxinvoice,
          id8: item.cash,
          id9: item.start,
          id10: item.guest_id,
          id11: item.guest_email,
          id12: item.user_id,
          id13: item.host_email,
          id14: (-item.price_guest).toLocaleString('en'),
          id15: (-(item.price_guest_vat - item.price_guest)).toLocaleString('en'),
          id16: (-item.coupon_use).toLocaleString('en'),
          id17: (-item.point_use).toLocaleString('en'),
          id18: item.guest_fee === 0 ? 0 : (-item.price_guest_fee).toLocaleString('en'),
          id19: (-(item.price_host * (100 / (100 - item.host_fee)))).toLocaleString('en'),
          id20: (-item.price_host_fee).toLocaleString('en'),
          id21: (-item.price_host).toLocaleString('en'),
          id22: (-item.tax).toLocaleString('en'),
          id23: (-Number(item.total)).toLocaleString('en'),
          id24: -item.hour,
          id25: item.bank_account,
          id26: item.bank_name,
          id27: item.bank_owner,
          id28: item.is_pass_money === 1 ? '파손' : '',
          id29: item.host_name,
          id30: item.place_id,
          id31: item.place_title,
          id32: item.type_text,
          id33: item.pay_day,
          id34: item.pay_id > 0 && item.pay_status === 2 && item.pay > 0 ? 'O' : '-',
          id35: item.id,
          id36: '',
          id39: item.is_pay_cash === 1 ? 'Y' : 'N',
        })
      }
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `예약_${moment(date).format('YYYY-MM')}.xlsx`)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>정산 결산표(예약) NEW 촬영일기준</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: 20,
            }}
          >
            <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM' showMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
          </div>
          <label
            onClick={() => {
              download()
            }}
            className='btn btn-sm btn-secondary'
          >
            다운로드
          </label>
        </div>

        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          {loading ? (
            <div
              style={{
                width: '100%',
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </div>
          ) : (
            <div className='tab-content' id='myTabContent'>
              <div className='table-responsive'>
                <table className='table table-striped gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                      <th className='min-w-100px'>예약번호</th>
                      <th className='min-w-100px'>예약일</th>
                      <th className='min-w-100px'>결제일</th>
                      <th className='min-w-100px'>취소일</th>
                      <th className='min-w-100px'>환불계좌</th>
                      <th className='min-w-100px'>환불일</th>
                      <th className='min-w-100px'>결제방법</th>
                      <th className='min-w-100px'>승인번호</th>
                      <th className='min-w-100px'>상호</th>
                      <th className='min-w-100px'>세금계산서</th>
                      <th className='min-w-100px'>현금영수증</th>
                      <th className='min-w-100px'>촬영일</th>
                      <th className='min-w-100px'>게스트</th>
                      <th className='min-w-100px'>이메일</th>
                      <th className='min-w-100px'>호스트</th>
                      <th className='min-w-100px'>이메일</th>
                      <th className='min-w-100px'>결제 금액</th>
                      <th className='min-w-100px'>부가세</th>
                      <th className='min-w-100px'>쿠폰</th>
                      <th className='min-w-100px'>포인트</th>
                      <th className='min-w-100px'>게스트 수수료</th>
                      <th className='min-w-100px'>호스트 원가</th>
                      <th className='min-w-100px'>호스트 수수료</th>
                      <th className='min-w-100px'>호스트 수익</th>
                      <th className='min-w-100px'>호스트 세금</th>
                      <th className='min-w-100px'>호스트 최종 지급액</th>
                      <th className='min-w-100px'>촬영시간</th>
                      <th className='min-w-100px'>호스트 계좌번호</th>
                      <th className='min-w-100px'>호스트 은행</th>
                      <th className='min-w-100px'>예금주</th>
                      <th className='min-w-100px'>비고</th>
                      <th className='min-w-100px'>승인번호</th>
                      <th className='min-w-100px'>닉네임</th>
                      <th className='min-w-100px'>장소번호</th>
                      <th className='min-w-100px'>장소명</th>
                      <th className='min-w-100px'>개인/사업자</th>
                      <th className='min-w-100px'>지급일</th>
                      <th className='min-w-100px'>지급 여부</th>
                      <th className='min-w-100px'>예약번호</th>
                      <th className='min-w-100px'>캐시정산</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item: any, index: number) => {
                      return [
                        <tr key={`${index}`}>
                          <td>{item.id}</td>
                          <td>{item.create_time}</td>
                          <td>{item.paid_time}</td>
                          <td>{item.cancelled_time}</td>
                          <td />
                          <td />
                          <td>{item.method}</td>
                          <td>{item.taxinvoice_nts}</td>
                          <td>{item.taxinvoice_company_name}</td>
                          <td>{item.taxinvoice}</td>
                          <td>{item.cash}</td>
                          <td>{item.start}</td>
                          <td>{item.guest_id}</td>
                          <td>{item.guest_email}</td>
                          <td>{item.user_id}</td>
                          <td>{item.host_email}</td>
                          <td>{item.price_guest.toLocaleString('en')}</td>
                          <td>{(item.price_guest_vat - item.price_guest).toLocaleString('en')}</td>
                          <td>{item.coupon_use.toLocaleString('en')}</td>
                          <td>{item.point_use.toLocaleString('en')}</td>
                          <td>{item.guest_fee === 0 ? 0 : item.price_guest_fee.toLocaleString('en')}</td>
                          <td>{(item.price_host * (100 / (100 - item.host_fee))).toLocaleString('en')}</td>
                          <td>{item.price_host_fee.toLocaleString('en')}</td>
                          <td>{item.price_host.toLocaleString('en')}</td>
                          <td>{item.tax.toLocaleString('en')}</td>
                          <td>{Number(item.total).toLocaleString('en')}</td>
                          <td>{item.hour}</td>
                          <td>{item.bank_account}</td>
                          <td>{item.bank_name}</td>
                          <td>{item.bank_owner}</td>
                          <td>{item.is_pass_money === 1 ? '파손' : ''}</td>
                          <td />
                          <td>{item.host_name}</td>
                          <td>{item.place_id}</td>
                          <td>{item.place_title}</td>
                          <td>{item.type_text}</td>
                          <td>{item.pay_day}</td>
                          <td>{item.pay_id > 0 && item.pay_status === 2 && item.pay > 0 ? 'O' : '-'}</td>
                          <td>{item.id}</td>
                          <td>{item.is_pay_cash === 1 ? 'Y' : 'N'}</td>
                        </tr>,
                      ]
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
