import React from 'react'
import {TextSub} from '../../components/TextSub'

type Props = {
  item: any
}

const InfoBox: React.FC<Props> = ({item}) => {

  return <div style={{marginTop: 40, width: '100%'}}>
    {
        item.host_id !== undefined && item.host_id > 0 &&
        <TextSub marginTop={0} title={'호스트'} subTitle={`${item.host_id}. ${item.host_name}`} popUrl={`/user/${item.host_id}`} />
    }
    {
        item.guest_id !== undefined && item.guest_id > 0 &&
        <TextSub marginTop={0} title={'게스트'} subTitle={`${item.guest_id}. ${item.guest_name}`} popUrl={`/user/${item.guest_id}`} />
    }
    {
      item.created_at !== undefined && item.created_at !== null &&
      <TextSub marginTop={0} title={'일시'} subTitle={`${item.created_at}`} />
    }
    <TextSub marginTop={0} title={'보낸사람'} subTitle={`${item.grey_user_id === item.guest_id ? '게스트' : '호스트'}`} />
  </div>

}

export {InfoBox}
