import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import {TextSub2} from '../../components/TextSub2'

export function FeedMonitoringSetting() {
    const [guestPortfolioGrey, setGuestPortfolioGrey] = useState<any>(null)

    const [hostPlaceFeedGrey, setHostPlaceFeedGrey] = useState<any>(null)
    const [hostPlaceFeedbackGrey, setHostPlaceFeedbackGrey] = useState<any>(null)
    const [hostPlaceWatchGrey, setHostPlaceWatchGrey] = useState<any>(null)

    useEffect(() => {
        getSetting()

        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getSetting = () => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/setting`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    setGuestPortfolioGrey(result.guest_portfolio_grey)

                    setHostPlaceFeedGrey(result.host_place_feed_grey)
                    setHostPlaceFeedbackGrey(result.host_place_feedback_grey)
                    setHostPlaceWatchGrey(result.host_place_watch_grey)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const updateSetting = (setting: any) => {
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/setting/${setting.key}`, {
                value: setting.value,
            })
            .then(({data: {error, result}}) => {
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    toast('변경되었습니다.')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>피드 모니터링 그레이 설정</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                    <h1 className='card-title'>장소 피드 그레이 키워드 설정</h1>

                    <TextSub2 title={'장소 소식 게시물 키워드'} subTitle={''} />
                    {hostPlaceFeedGrey !== null && (
                        <div>
                            <textarea
                                className='form-control'
                                rows={2}
                                placeholder=''
                                value={hostPlaceFeedGrey.value}
                                onChange={(e) => {
                                    const temp = Object.assign({}, hostPlaceFeedGrey)
                                    temp.value = e.target.value
                                    setHostPlaceFeedGrey(temp)
                                }}
                            />
                        </div>
                    )}
                    <div style={{padding: 10}}>
                        <label
                            onClick={() => {
                                updateSetting(hostPlaceFeedGrey)
                            }}
                            className='btn btn-primary'
                        >
                            수정하기
                        </label>
                    </div>

                    <div className='separator my-10' />
                    <TextSub2 title={'장소 후기 게시물 키워드'} subTitle={''} />
                    {hostPlaceFeedbackGrey !== null && (
                        <div>
                            <textarea
                                className='form-control'
                                rows={2}
                                placeholder=''
                                value={hostPlaceFeedbackGrey.value}
                                onChange={(e) => {
                                    const temp = Object.assign({}, hostPlaceFeedbackGrey)
                                    temp.value = e.target.value
                                    setHostPlaceFeedbackGrey(temp)
                                }}
                            />
                        </div>
                    )}
                    <div style={{padding: 10}}>
                        <label
                            onClick={() => {
                                updateSetting(hostPlaceFeedbackGrey)
                            }}
                            className='btn btn-primary'
                        >
                            수정하기
                        </label>
                    </div>

                    <div className='separator my-10' />
                    <TextSub2 title={'콘텐츠 속 장소 키워드'} subTitle={''} />
                    {hostPlaceWatchGrey !== null && (
                        <div>
                            <textarea
                                className='form-control'
                                rows={2}
                                placeholder=''
                                value={hostPlaceWatchGrey.value}
                                onChange={(e) => {
                                    const temp = Object.assign({}, hostPlaceWatchGrey)
                                    temp.value = e.target.value
                                    setHostPlaceWatchGrey(temp)
                                }}
                            />
                        </div>
                    )}
                    <div style={{padding: 10}}>
                        <label
                            onClick={() => {
                                updateSetting(hostPlaceWatchGrey)
                            }}
                            className='btn btn-primary'
                        >
                            수정하기
                        </label>
                    </div>
                </div>

                <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                    <div className='separator my-10' />
                    <h1 className='card-title'>게스트 피드 그레이 키워드 설정</h1>

                    <TextSub2 title={'포트폴리오 키워드'} subTitle={''} />
                    {guestPortfolioGrey !== null && (
                        <div>
                            <textarea
                                className='form-control'
                                rows={2}
                                placeholder=''
                                value={guestPortfolioGrey.value}
                                onChange={(e) => {
                                    const temp = Object.assign({}, guestPortfolioGrey)
                                    temp.value = e.target.value
                                    setGuestPortfolioGrey(temp)
                                }}
                            />
                        </div>
                    )}
                    <div style={{padding: 10}}>
                        <label
                            onClick={() => {
                                updateSetting(guestPortfolioGrey)
                            }}
                            className='btn btn-primary'
                        >
                            수정하기
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}
