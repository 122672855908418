import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {toast} from 'react-toastify'
import {TextSub} from '../../components/TextSub'
import {TextSub3} from '../../components/TextSub3'
import Modal from '../../components/Modal'
import {apiGetGreyKeywords} from '../../../apis/Grey'

export function QnAPlace() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const [greyKeywords, setGreyKeywords] = useState<[]>([])

  const [item, setItem] = useState<any>(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModal2, setDeleteModal2] = useState(false)

  const [search, setSearch] = useState('')

  useEffect(() => {

    getGreyKeywords()
    getPlaceQnas()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getPlaceQnas(page, search)
  }

  const onSearch = () => {
    getPlaceQnas(1, search)
  }

  const getPlaceQnas = (page = 1, search = '') => {
    setItems([])
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API2_URL}am/place/qnas?page=${page}&search=${search}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setItems(result.data)
        setCurrentPage(result.current_page)
        setPerPage(result.per_page)
        setTotal(result.total)
        window.scrollTo(0, 0)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const getGreyKeywords = () => {
    apiGetGreyKeywords().then(({error, result}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setGreyKeywords(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const deleteQna = (item: any) => {
    console.log({'user_id': item.place_qna_user_id, 'place_id': item.place_qna_place_id, 'place_qna_id': item.place_qna_id, 'blind': 1})
    axios
      .delete(`${process.env.REACT_APP_API2_URL}am/place/qna/blind`,
        {data: {'user_id': item.place_qna_user_id, 'place_id': item.place_qna_place_id, 'place_qna_id': item.place_qna_id, 'blind': 1}})
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          const temps = [...items]
          temps.splice(temps.indexOf(item), 1, item)
          item.place_qna_content = '관리자에 의해 숨김 처리되었습니다.'
          item.place_qna_blind = 1
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteQna2 = (item: any) => {
    console.log({'user_id': item.answer.user_id, 'place_id': item.place_qna_place_id, 'place_qna_id': item.answer.id, 'blind': 1})
    axios
      .delete(`${process.env.REACT_APP_API2_URL}am/place/qna/blind`,
        {data: {'user_id': item.answer.user_id, 'place_id': item.place_qna_place_id, 'place_qna_id': item.answer.id, 'blind': 1}})
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          const temps = [...items]
          temps.splice(temps.indexOf(item), 1, item)
          item.answer.content = '관리자에 의해 숨김 처리되었습니다.'
          item.answer.blind = 1
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>장소 문의</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        marginTop: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <input
          type='text'
          className='form-control'
          placeholder='장소 번호, 장소명, 게스트 번호, 게스트 닉네임, 게스트 실명, 호스트 번호, 호스트 실명'
          value={search}
          style={{
            flex: 1,
          }}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSearch()
            }
          }}
        />
        <label onClick={() => {
          onSearch()
        }}
               style={{
                 width: 100,
               }}
               className='btn btn-dark'>검색</label>
      </div>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        {
          loading ?
            <div style={{
              width: '100%',
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div>
              {
                items.map((item: any, index: number) => {
                  return (
                    <div className='col-12' key={`message_${index}`}>
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-header'>
                          <h1 className='card-title'>
                            {item.place_qna_id}. {item.place_qna_created_at} {item.place_qna_created_at !== item.place_qna_updated_at ? `업데이트 : ${item.place_qna_updated_at}` : ''}
                          </h1>
                        </div>
                        <div className='card-body'>
                          <TextSub title={'장소번호'} subTitle={item.place_qna_place_id} popUrl={`/place/${item.place_qna_place_id}`} />
                          <TextSub title={'장소제목'} subTitle={item.title} />

                          <TextSub title={'게스트'} subTitle={`${item.place_qna_user_id}. ${item.user_real_name} [${item.user_name}]`} popUrl={`/user/${item.place_qna_user_id}`} />
                          <TextSub3 title={'질문'} subTitle={item.place_qna_content} keywords={greyKeywords} />
                          {
                            item.place_qna_delete === 0 && item.place_qna_blind === 0 &&
                            <p style={{
                              minWidth: 40,
                              paddingLeft: 10,
                              fontWeight: 500,
                              fontSize: 12,
                              color: '#F00',
                              cursor: 'pointer',
                            }}
                               onClick={() => {
                                 setItem(item)
                                 setDeleteModal(true)
                               }}
                            >
                              질문 숨김
                            </p>
                          }
                          {
                            item.place_qna_content !== item.place_qna_o_content &&
                            <TextSub3 title={'질문 원본'} subTitle={item.place_qna_o_content} keywords={greyKeywords} />
                          }

                          {
                            item.answer !== undefined && item.answer !== null && item.answer.content !== undefined &&
                            <>
                              <TextSub title={'호스트'} subTitle={`${item.answer.user_id}. ${item.answer.user_real_name} [${item.answer.user_name}]`} popUrl={`/user/${item.answer.user_id}`} />
                              <TextSub3 title={'답변'} subTitle={item.answer.content} keywords={greyKeywords} />
                              {
                                item.answer.delete === 0 && item.answer.blind === 0 &&
                                <p style={{
                                  minWidth: 40,
                                  paddingLeft: 10,
                                  fontWeight: 500,
                                  fontSize: 12,
                                  color: '#F00',
                                  cursor: 'pointer',
                                }}
                                   onClick={() => {
                                     setItem(item)
                                     setDeleteModal2(true)
                                   }}
                                >
                                  답변 숨김
                                </p>
                              }
                              {
                                item.answer.content !== item.answer.o_content &&
                                <TextSub3 title={'답변 원본'} subTitle={item.answer.o_content} keywords={greyKeywords} />
                              }
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <div style={{padding: 20}}>
                <Pagination activePage={currentPage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            prevPageText={'‹'}
                            nextPageText={'›'}
                            onChange={handlePageChange} />
              </div>
            </div>
        }
      </div>

    </div>
    {
      deleteModal &&
      <Modal
        visible={deleteModal}
        closable={true}
        maskClosable={true}
        onClose={() => {
          setDeleteModal(false)
        }} className={''}>
        <div>
          <h2>
            질문을 숨김 처리하시겠습니까?
          </h2>
          <div className='separator my-4' />
          <div style={{width: '100%', textAlign: 'right'}}>
            <label style={{margin: 4}} onClick={() => {
              setDeleteModal(false)
            }} className={'btn btn-secondary'}>취소</label>
            <label style={{margin: 4}} onClick={() => {
              deleteQna(item)
              setDeleteModal(false)
            }} className={'btn btn-danger'}>삭제</label>
          </div>

        </div>
      </Modal>
    }

    {
      deleteModal2 &&
      <Modal
        visible={deleteModal2}
        closable={true}
        maskClosable={true}
        onClose={() => {
          setDeleteModal2(false)
        }} className={''}>
        <div>
          <h2>
            답변을 숨김 처리하시겠습니까?
          </h2>
          <div className='separator my-4' />
          <div style={{width: '100%', textAlign: 'right'}}>
            <label style={{margin: 4}} onClick={() => {
              setDeleteModal2(false)
            }} className={'btn btn-secondary'}>취소</label>
            <label style={{margin: 4}} onClick={() => {
              deleteQna2(item)
              setDeleteModal2(false)
            }} className={'btn btn-danger'}>삭제</label>
          </div>

        </div>
      </Modal>
    }
  </>
}

