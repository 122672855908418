import React, {useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'

export function CategoryAdvertiseCancel() {
  const [id, setId] = useState('')
  const [placeId, setPlaceId] = useState('')
  const [userId, setUserId] = useState('')

  const cancel = () => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/category/adv/cancel`, {id: id, place_id: placeId, user_id: userId})
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        console.log(error)
        if (error !== '') {
          alert(error)
        } else {
          console.log(result)
          alert('취소 처리 완료')
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
        alert(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>카테고리 광고 취소</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div style={{marginTop: 10, paddingBottom: 10}}>
              <div>
                <table style={{fontSize: '15px', width: '100%'}}>
                  <colgroup>
                    <col style={{width: '14%'}} />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr style={{fontSize: '15px'}}>
                      <td>아이디</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 1000'
                          maxLength={18}
                          value={id}
                          onChange={(e) => {
                            setId(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>장소번호</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 26296'
                          maxLength={18}
                          value={placeId}
                          onChange={(e) => {
                            setPlaceId(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>사용자번호</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 3040'
                          maxLength={18}
                          value={userId}
                          onChange={(e) => {
                            setUserId(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <label
                onClick={() => {
                  cancel()
                }}
                style={{marginLeft: 10}}
                className='btn btn-sm btn-primary'
              >
                취소하기
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
