import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import {
  Button,
} from 'react-bootstrap'
import {toast} from 'react-toastify'
import moment from 'moment'

export function BookingPlaceEditRefund() {

  const {booking_id} = useParams<{booking_id: string}>()
  const [bookingInfo, setBookingInfo] = useState<any>(null)

  const [banks, setBanks] = useState([])

  const [bankCode, setBankCode] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankUserName, setBankUserName] = useState('')
  const [bankAccount, setBankAccount] = useState('')

  useEffect(() => {

    getBankList()
    getBookingInfo()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getBankList = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}booking/refund/banks`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        // toast.error(error)
      } else {
        setBanks(result)
        // window.location.href = `/booking/place/${booking_id}`
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const update = () => {
    if (window.confirm('환불 정보를 수정 하시겠습니까?')) {
      axios.put(`${process.env.REACT_APP_API2_URL}am/booking/refund/${bookingInfo.booking.refund_id}`, {
        'refund_bank_code': bankCode,
        'refund_bank_name': bankName,
        'refund_bank_account': bankAccount,
        'refund_bank_user_name': bankUserName,
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/booking/place/${booking_id}`
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  const getBookingInfo = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setBookingInfo(result)
        setBankCode(result.booking.refund_bank_code)
        setBankName(result.booking.refund_bank_name)
        setBankUserName(result.booking.refund_bank_user_name)
        setBankAccount(result.booking.refund_bank_account)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>환불 정보 수정</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='col-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              {
                bookingInfo !== null &&
                <h1 className='card-title'>
                  예약 번호 : {bookingInfo.booking.id}
                </h1>
              }
            </div>
            <div className='card-body'>
              {
                bookingInfo !== null &&
                <>
                  <TextSub title={'장소 번호'} subTitle={bookingInfo.place.id} />
                  <TextSub title={'장소 제목'} subTitle={bookingInfo.place.title} />
                  <TextSub title={'촬영 스케줄'} subTitle={`${moment(bookingInfo.booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(bookingInfo.booking.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}`} />
                </>
              }

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'은행명'} subTitle={''} />
                </div>
                <select
                  className='form-control'
                  name='type'
                  onChange={(e) => {
                    setBankCode(e.target.value)
                    banks.some((bank: any) => {
                      if (bank.bank_code === e.target.value) {
                        setBankName(bank.bank_name)
                        return true
                      }
                      return false
                    })
                  }}
                  value={bankCode}
                >
                  {banks.map((bank: any) => {
                    return <option value={bank.bank_code}>{bank.bank_name}</option>
                  })}
                </select>
              </div>

              {/*<div style={{*/}
              {/*  width: '100%',*/}
              {/*  display: 'flex',*/}
              {/*  flexDirection: 'row',*/}
              {/*  alignItems: 'center',*/}
              {/*}}>*/}
              {/*  <div>*/}
              {/*    <TextSub title={'은행명'} subTitle={''} />*/}
              {/*  </div>*/}
              {/*  <input*/}
              {/*    type='text'*/}
              {/*    className='form-control'*/}
              {/*    placeholder={''}*/}
              {/*    value={bankName}*/}
              {/*    onChange={(e) => {*/}
              {/*      setBankName(e.target.value)*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</div>*/}

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'예금주'} subTitle={''} />
                </div>
                <input
                  type='text'
                  className='form-control'
                  placeholder={''}
                  value={bankUserName}
                  onChange={(e) => {
                    setBankUserName(e.target.value)
                  }}
                />
              </div>

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'계좌 정보'} subTitle={''} />
                </div>
                <input
                  type='text'
                  className='form-control'
                  placeholder={''}
                  value={bankAccount}
                  onChange={(e) => {
                    setBankAccount(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                variant='danger'
                onClick={() => {
                  update()
                }}>
                수정 하기
              </Button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </>
}

