import React from 'react'
import {Button, Dropdown, Modal, Tab, Tabs} from 'react-bootstrap'
import axios from 'axios'
import MainCategoryPlacesCard from '../../components/MainCategoryPlacesCard'
import {PageTitle} from '../../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import ImagePath from '../../../commons/ImagePath'
import Dropzone from 'react-dropzone'

export default class MainCategory extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            key: '',
            main_categories: [],
            modalShow: false,
            modalPlaceImageShow: false,
            place: null,
            category: 0,
            categoryInfo: null,
        }

        this.getMainCategory()
    }

    getMainCategory = () => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/curation/category`)
            .then(({data: {error, result}}) => {
                console.log(result)
                if (error !== '') {
                } else {
                    this.setState({
                        main_categories: result,
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    addCategory = () => {
        const title = prompt('제목을 입력하세요.')
        if (title !== undefined && title !== null && title.trim() !== '') {
            axios
                .post(`${process.env.REACT_APP_API_URL}main/category`, {title})
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        this.state.main_categories.splice(0, 0, result)
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategoryOrder = (category) => {
        const order = prompt('정렬값을 입력하세요.')
        if (category.order !== order) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    order,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.order = result.order
                        if (this.state.main_categories.length > 1) {
                            this.state.main_categories.splice(this.state.main_categories.indexOf(category), 1)
                            let i = 0
                            this.state.main_categories.forEach((value, index) => {
                                if (value.order < category.order) {
                                    i = index + 1
                                }
                            })
                            this.state.main_categories.splice(i, 0, category)
                        }
                        console.log(this.state.main_categories)
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategoryShow = (category, show) => {
        if (category.show !== show) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    show,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.show = result.show
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategoryTitle = (category) => {
        const title = prompt('제목을 입력하세요.')
        if (title === null) {
            return false
        }
        if (category.title !== title) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    title,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.title = result.title
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategorySubTitle = (category) => {
        const title = prompt('부제목을 입력하세요.')
        if (category.sub_title !== title) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    sub_title: title,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.sub_title = result.sub_title
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategoryComment = (category) => {
        const title = prompt('comment 입력하세요.')
        if (category.comment !== title) {
            if (title.length > 50) {
                alert('50자 이하로 입력해 주세요')
                return
            }
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    comment: title,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.comment = result.comment
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategorySubComment = (category) => {
        const title = prompt('sub_comment 입력하세요.')
        if (category.comment !== title) {
            if (title.length > 100) {
                alert('100자 이하로 입력해 주세요')
                return
            }
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    sub_comment: title,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.sub_comment = result.sub_comment
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateSubCategoryOrder = (category, sub_category) => {
        const order = prompt('정렬값을 입력하세요.')
        if (sub_category.order !== order) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${sub_category.id}`, {order})
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        sub_category.order = result.order
                        if (category.subs.length > 1) {
                            category.subs.splice(category.subs.indexOf(sub_category), 1)
                            let i = 0
                            category.subs.forEach((value, index) => {
                                if (value.order < sub_category.order) {
                                    i = index + 1
                                }
                            })
                            category.subs.splice(i, 0, sub_category)
                        }
                        console.log(category.subs)
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    addSubCategory = (category) => {
        const title = prompt(`${category.title}의 서브 카테고리 제목을 입력하세요.`)
        if (title.trim() !== '') {
            axios
                .post(`${process.env.REACT_APP_API_URL}main/category`, {
                    title,
                    main_category_id: category.id,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.subs.splice(0, 0, result)
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    addPlace = (place) => {
        console.log('add', place)
        axios
            .post(`${process.env.REACT_APP_API_URL}main/category/${this.state.category.id}/add`, {place_id: place.id})
            .then(({data: {error, result}}) => {
                console.log(error, result)
                if (error !== '') {
                } else {
                    this.getPlaces(this.state.category.id)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    addPlaceIds = (category) => {
        const place_ids = prompt('장소들 아이디를 입력하세요. ex) 1234,123,23456,235')
        if (place_ids !== null && place_ids.trim() !== '') {
            console.log('add', place_ids)
            axios
                .post(`${process.env.REACT_APP_API_URL}main/category/${category.id}/add`, {place_ids})
                .then(({data: {error, result}}) => {
                    console.log(error, result)
                    if (error !== '') {
                    } else {
                        this.getPlaces(category.id)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    removePlace = (category, place) => {
        if (window.confirm(`${place.id}. ${place.title} 장소를 삭제하시겠습니까?`)) {
            console.log('delete', place)
            axios
                .delete(`${process.env.REACT_APP_API_URL}main/category/${category.id}/place/${place.id}`)
                .then(({data: {error, result}}) => {
                    console.log(error, result)
                    if (error !== '') {
                    } else {
                        category.places.splice(category.places.indexOf(place), 1)
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updatePlace = (place, image_id) => {
        console.log('update', {place, image_id})
        axios
            .put(`${process.env.REACT_APP_API_URL}main/category/${place.main_category_id}/place/${place.id}`, {image_id})
            .then(({data: {error, result}}) => {
                console.log(error, result)
                if (error !== '') {
                } else {
                    place.image_id = result.image_id
                    place.image_url = result.image_url
                    this.setState({
                        modalPlaceImageShow: false,
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getPlaces(category_id) {
        category_id = parseInt(category_id)
        console.log(category_id)
        let category = null
        this.state.main_categories.forEach((value) => {
            if (value.subs.length > 0) {
                value.subs.forEach((item) => {
                    if (item.id === category_id) {
                        category = item
                    }
                })
            } else if (value.id === category_id) {
                category = value
            }
        })

        if (category !== null) {
            axios
                .get(`${process.env.REACT_APP_API2_URL}am/curation/category/${category.id}/places`)
                .then(({data: {error, result}}) => {
                    console.log(error, result)
                    if (error !== '') {
                    } else {
                        category.places = result
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    updateCategoryBackgroundColor = (category) => {
        const title = prompt('배경색을 입력하세요.(#123456)')
        if (category.background_color !== title) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/main/category/${category.id}`, {
                    background_color: title,
                })
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        category.background_color = result.background_color
                        this.forceUpdate()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    addImage = (category) => {
        console.log(category)
        this.setState({
            categoryInfo: category,
        })
        this.file.click()
    }

    handleFileInput = async (e) => {
        const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file?.type,
                        },
                    })
                    .then((response) => {
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'image',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    console.log(result)
                                    axios
                                        .put(`${process.env.REACT_APP_API_URL}admin/main/category/${this.state.categoryInfo.id}`, {
                                            image_id: result.id,
                                            image_path: result.image_path,
                                        })
                                        .then(({data: {error, result}}) => {
                                            console.log(result)
                                            if (error !== '') {
                                            } else {
                                                this.state.categoryInfo.image_id = result.id
                                                this.state.categoryInfo.image_path = result.image_path
                                                this.forceUpdate()
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })

                                    // main_vue.completeImage(file, response.data.result)
                                } else {
                                    console.log(error)
                                    // main_vue.failImage(file)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                // main_vue.failImage(file)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        // main_vue.failImage(file)
                    })
            } else {
                // main_vue.failImage(file)
            }
        })
    }

    handleItemFileInput = async (file, place) => {
        // const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/admin_image`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file?.type,
                        },
                    })
                    .then((response) => {
                        console.log(path)
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'admin_image',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    let image_id = result.id
                                    axios
                                        .put(`${process.env.REACT_APP_API_URL}main/category/${place.main_category_id}/place/${place.id}`, {image_id})
                                        .then(({data: {error, result}}) => {
                                            console.log(error, result)
                                            if (error !== '') {
                                            } else {
                                                place.image_id = result.image_id
                                                place.image_url = result.image_url
                                                this.setState({
                                                    modalPlaceImageShow: false,
                                                })
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                } else {
                                    console.log(error)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                console.log(error)
            }
        })
    }

    render() {
        const modalClose = () => this.setState({modalShow: false})
        const modalPlaceImageClose = () => this.setState({modalPlaceImageShow: false})
        return (
            <div
                style={{
                    position: 'relative',
                }}
            >
                <PageTitle breadcrumbs={[]}>메인 화면 카테고리</PageTitle>
                <Button
                    className='btn btn-sm btn-outline-secondary'
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                    onClick={() => this.addCategory()}
                >
                    카테고리 추가
                </Button>
                <div className='row'>
                    <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
                        <div
                            style={{
                                visibility: 'hidden',
                            }}
                        >
                            <input
                                type='file'
                                name='file'
                                ref={(input) => {
                                    this.file = input
                                }}
                                onChange={(e) => this.handleFileInput(e)}
                            />
                        </div>
                        <PlacesModal show={this.state.modalShow} category={this.state.category} onAdd={this.addPlace} onHide={modalClose} />

                        <PlaceImagesModal
                            show={this.state.modalPlaceImageShow}
                            place={this.state.place}
                            updatePlace={this.updatePlace}
                            onHide={modalPlaceImageClose}
                            imageUpload={(e) => this.handleItemFileInput(e, this.state.place)}
                        />

                        <Tabs
                            id='controlled-tab-example'
                            activeKey={this.state.key}
                            onSelect={(key) => {
                                this.setState({key})
                                this.getPlaces(key)
                            }}
                        >
                            {this.state.main_categories.map((category) => {
                                return (
                                    <Tab key={category.id.toString()} eventKey={category.id} title={category.title}>
                                        <div style={{padding: 20, position: 'relative'}}>
                                            <div style={{width: '100%'}}>
                                                <div style={{marginTop: 20}}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <div style={{marginLeft: 20}}>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant='secondary' id='dropdown-basic' style={{fontSize: 24}}>
                                                                    {category.title}
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    {category.subs.length === 0 && (
                                                                        <Dropdown.Item
                                                                            href='#'
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    category,
                                                                                    modalShow: true,
                                                                                })
                                                                            }
                                                                        >
                                                                            장소 추가
                                                                        </Dropdown.Item>
                                                                    )}
                                                                    {category.subs.length === 0 && (
                                                                        <Dropdown.Item href='#' onClick={() => this.addPlaceIds(category)}>
                                                                            장소들 추가
                                                                        </Dropdown.Item>
                                                                    )}
                                                                    {category.subs.length === 0 && (
                                                                        <Dropdown.Item href='#' onClick={() => this.addImage(category)}>
                                                                            이미지 추가
                                                                        </Dropdown.Item>
                                                                    )}
                                                                    {category.subs.length === 0 && (
                                                                        <Dropdown.Item href='#' onClick={() => this.updateCategoryBackgroundColor(category)}>
                                                                            배경색 변경
                                                                        </Dropdown.Item>
                                                                    )}
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item href='#' onClick={() => this.updateCategoryTitle(category)}>
                                                                        title 변경
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href='#' onClick={() => this.updateCategorySubTitle(category)}>
                                                                        sub_title 변경
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href='#' onClick={() => this.updateCategoryComment(category)}>
                                                                        comment 변경
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href='#' onClick={() => this.updateCategorySubComment(category)}>
                                                                        sub_comment 변경
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item href='#' onClick={() => this.updateCategoryOrder(category)}>
                                                                        순서 변경
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href='#' onClick={() => this.addSubCategory(category)}>
                                                                        서브 카테고리 추가
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    {category.show === 1 && (
                                                                        <Dropdown.Item href='#' onClick={() => this.updateCategoryShow(category, 0)}>
                                                                            숨기기
                                                                        </Dropdown.Item>
                                                                    )}
                                                                    {category.show === 0 && (
                                                                        <Dropdown.Item href='#' onClick={() => this.updateCategoryShow(category, 1)}>
                                                                            노출
                                                                        </Dropdown.Item>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div
                                                        style={{
                                                            margin: 4,
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                        }}
                                                    >
                                                        <label
                                                            style={{marginLeft: 10}}
                                                            onClick={() => {
                                                                window.open(`${process.env.REACT_APP_WEB_URL}promotion/${category.id}`)
                                                            }}
                                                            className='btn btn-sm btn-primary'
                                                        >
                                                            {category.title} 웹에서 보기
                                                        </label>
                                                    </div>
                                                    <p style={{fontSize: 20}}>{`순서 : ${category.order}, 장소 : ${category.count}, 노출 : ${category.show}`}</p>
                                                </div>
                                            </div>

                                            {category.subs.length === 0 && (
                                                <div
                                                    style={{
                                                        marginTop: 40,
                                                    }}
                                                >
                                                    <p style={{marginTop: 16, fontSize: 16}}>title : {category.title}</p>
                                                    <p style={{marginTop: 4, fontSize: 16}}>sub_title : {category.sub_title}</p>
                                                    <p style={{marginTop: 4, fontSize: 16}}>comment : {category.comment}</p>
                                                    <p style={{marginTop: 4, marginBottom: 30, fontSize: 16}}>sub_comment : {category.sub_comment}</p>

                                                    <p>모바일</p>
                                                    <div
                                                        style={{
                                                            width: 375,
                                                            marginBottom: 30,
                                                            border: '1px solid #DDD',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: 44,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    fontWeight: 500,
                                                                    fontSize: 16,
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                {category.title}
                                                            </p>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                paddingTop: 30,
                                                                paddingBottom: 20,
                                                                paddingRight: 20,
                                                                paddingLeft: 20,
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    fontWeight: 700,
                                                                    fontSize: 24,
                                                                    color: '#1B1D1F',
                                                                }}
                                                            >
                                                                {category.comment}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    marginTop: 6,
                                                                    fontWeight: 400,
                                                                    fontSize: 16,
                                                                    color: '#454B50',
                                                                }}
                                                            >
                                                                {category.sub_comment}
                                                            </p>
                                                        </div>
                                                        {category.image_path !== '' && (
                                                            <img
                                                                src={ImagePath.get(category.image_path, '2000x2000', 'inside')}
                                                                style={{
                                                                    width: 375,
                                                                    height: 250,
                                                                    objectFit: 'cover',
                                                                }}
                                                                alt=''
                                                            />
                                                        )}
                                                    </div>
                                                    <p>PC</p>
                                                    <p style={{marginTop: 4, fontSize: 16}}>배경색 : {category.background_color}</p>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: 300,
                                                            position: 'relative',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            border: '1px solid #DDD',
                                                        }}
                                                    >
                                                        {category.image_path !== '' && (
                                                            <img
                                                                src={ImagePath.get(category.image_path, '2000x2000', 'inside')}
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover',
                                                                    zIndex: 0,
                                                                }}
                                                                alt=''
                                                            />
                                                        )}
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: 500,
                                                                height: '100%',
                                                                backgroundColor: category.background_color,
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                paddingRight: 30,
                                                                paddingLeft: 30,
                                                                zIndex: 1,
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    fontWeight: 700,
                                                                    fontSize: 32,
                                                                    color: '#FFF',
                                                                }}
                                                            >
                                                                {category.comment}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    marginTop: 6,
                                                                    fontWeight: 400,
                                                                    fontSize: 18,
                                                                    color: '#FFF',
                                                                }}
                                                            >
                                                                {category.sub_comment}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop: 20,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                        }}
                                                    >
                                                        {category.places.map((place) => {
                                                            return (
                                                                <MainCategoryPlacesCard
                                                                    key={place.id.toString()}
                                                                    category={category}
                                                                    place={place}
                                                                    removePlace={this.removePlace}
                                                                    showImages={() => {
                                                                        this.setState({
                                                                            place,
                                                                            modalPlaceImageShow: true,
                                                                        })
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                            {category.subs.length > 0 && (
                                                <div>
                                                    <h4>서브 카테고리</h4>
                                                    <Tabs
                                                        id='controlled-tab-example'
                                                        activeKey={category.key}
                                                        onSelect={(key) => {
                                                            category.key = key
                                                            this.getPlaces(key)
                                                        }}
                                                    >
                                                        {category.subs.map((sub_category) => {
                                                            return (
                                                                <Tab key={sub_category.id.toString()} eventKey={sub_category.id} title={sub_category.title}>
                                                                    <div style={{width: '100%', padding: 20, position: 'relative'}}>
                                                                        <div style={{marginTop: 20}}>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            >
                                                                                <div style={{marginLeft: 20}}>
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle
                                                                                            variant='secondary'
                                                                                            id='dropdown-basic'
                                                                                            style={{
                                                                                                fontSize: 24,
                                                                                            }}
                                                                                        >
                                                                                            {sub_category.title}
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item
                                                                                                href='#'
                                                                                                onClick={() =>
                                                                                                    this.setState({
                                                                                                        category: sub_category,
                                                                                                        modalShow: true,
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                장소 추가
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.addPlaceIds(sub_category)}>
                                                                                                장소들 추가
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.addImage(sub_category)}>
                                                                                                이미지 추가
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.updateCategoryBackgroundColor(sub_category)}>
                                                                                                배경색 변경
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Divider />
                                                                                            <Dropdown.Item href='#' onClick={() => this.updateCategoryTitle(sub_category)}>
                                                                                                title 변경
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.updateCategorySubTitle(sub_category)}>
                                                                                                sub_title 변경
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.updateCategoryComment(sub_category)}>
                                                                                                comment 변경
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.updateCategorySubComment(sub_category)}>
                                                                                                sub_comment 변경
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href='#' onClick={() => this.updateCategoryOrder(sub_category)}>
                                                                                                순서 변경
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Divider />
                                                                                            {sub_category.show === 1 && (
                                                                                                <Dropdown.Item href='#' onClick={() => this.updateCategoryShow(sub_category, 0)}>
                                                                                                    숨기기
                                                                                                </Dropdown.Item>
                                                                                            )}
                                                                                            {sub_category.show === 0 && (
                                                                                                <Dropdown.Item href='#' onClick={() => this.updateCategoryShow(sub_category, 1)}>
                                                                                                    노출
                                                                                                </Dropdown.Item>
                                                                                            )}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    margin: 4,
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    right: 0,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{marginLeft: 10}}
                                                                                    onClick={() => {
                                                                                        window.open(`${process.env.REACT_APP_WEB_URL}promotion/${sub_category.id}`)
                                                                                    }}
                                                                                    className='btn btn-sm btn-primary'
                                                                                >
                                                                                    {sub_category.title} 웹에서 보기
                                                                                </label>
                                                                            </div>
                                                                            <p
                                                                                style={{
                                                                                    fontSize: 20,
                                                                                }}
                                                                            >
                                                                                {`순서 : ${sub_category.order}, 장소 : ${sub_category.count}, 노출 : ${sub_category.show}`}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <p style={{marginTop: 16, fontSize: 16}}>title : {sub_category.title}</p>
                                                                        <p style={{marginTop: 4, fontSize: 16}}>sub_title : {sub_category.sub_title}</p>
                                                                        <p style={{marginTop: 4, fontSize: 16}}>comment : {sub_category.comment}</p>
                                                                        <p style={{marginTop: 4, marginBottom: 30, fontSize: 16}}>sub_comment : {sub_category.sub_comment}</p>

                                                                        <p>모바일</p>
                                                                        <div
                                                                            style={{
                                                                                width: 375,
                                                                                marginBottom: 30,
                                                                                border: '1px solid #DDD',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: 44,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        padding: 0,
                                                                                        margin: 0,
                                                                                        fontWeight: 500,
                                                                                        fontSize: 16,
                                                                                        color: '#000',
                                                                                    }}
                                                                                >
                                                                                    {sub_category.title}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    width: '100%',
                                                                                    paddingTop: 30,
                                                                                    paddingBottom: 20,
                                                                                    paddingRight: 20,
                                                                                    paddingLeft: 20,
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        padding: 0,
                                                                                        margin: 0,
                                                                                        fontWeight: 700,
                                                                                        fontSize: 24,
                                                                                        color: '#1B1D1F',
                                                                                    }}
                                                                                >
                                                                                    {sub_category.comment}
                                                                                </p>
                                                                                <p
                                                                                    style={{
                                                                                        padding: 0,
                                                                                        margin: 0,
                                                                                        marginTop: 6,
                                                                                        fontWeight: 400,
                                                                                        fontSize: 16,
                                                                                        color: '#454B50',
                                                                                    }}
                                                                                >
                                                                                    {sub_category.sub_comment}
                                                                                </p>
                                                                            </div>
                                                                            {sub_category.image_path !== '' && (
                                                                                <img
                                                                                    src={ImagePath.get(sub_category.image_path, '2000x2000', 'inside')}
                                                                                    style={{
                                                                                        width: 375,
                                                                                        height: 250,
                                                                                        objectFit: 'cover',
                                                                                    }}
                                                                                    alt=''
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <p>PC</p>
                                                                        <p style={{marginTop: 4, fontSize: 16}}>배경색 : {sub_category.background_color}</p>
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                height: 300,
                                                                                position: 'relative',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                                border: '1px solid #DDD',
                                                                            }}
                                                                        >
                                                                            {sub_category.image_path !== '' && (
                                                                                <img
                                                                                    src={ImagePath.get(sub_category.image_path, '2000x2000', 'inside')}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                        objectFit: 'cover',
                                                                                        zIndex: 0,
                                                                                    }}
                                                                                    alt=''
                                                                                />
                                                                            )}
                                                                            <div
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    width: 500,
                                                                                    height: '100%',
                                                                                    backgroundColor: sub_category.background_color,
                                                                                }}
                                                                            />
                                                                            <div
                                                                                style={{
                                                                                    paddingRight: 30,
                                                                                    paddingLeft: 30,
                                                                                    zIndex: 1,
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        padding: 0,
                                                                                        margin: 0,
                                                                                        fontWeight: 700,
                                                                                        fontSize: 32,
                                                                                        color: '#FFF',
                                                                                    }}
                                                                                >
                                                                                    {sub_category.comment}
                                                                                </p>
                                                                                <p
                                                                                    style={{
                                                                                        padding: 0,
                                                                                        margin: 0,
                                                                                        marginTop: 6,
                                                                                        fontWeight: 400,
                                                                                        fontSize: 18,
                                                                                        color: '#FFF',
                                                                                    }}
                                                                                >
                                                                                    {sub_category.sub_comment}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            marginTop: 40,
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            flexWrap: 'wrap',
                                                                        }}
                                                                    >
                                                                        {sub_category.places.map((place) => {
                                                                            return (
                                                                                <MainCategoryPlacesCard
                                                                                    key={place.id.toString()}
                                                                                    category={sub_category}
                                                                                    place={place}
                                                                                    removePlace={this.removePlace}
                                                                                    showImages={() => {
                                                                                        this.setState({
                                                                                            place,
                                                                                            modalPlaceImageShow: true,
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Tab>
                                                            )
                                                        })}
                                                    </Tabs>
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

class PlacesModal extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            keyword: '',
            place: null,
            places: [],
            currentPage: 0,
            perPage: 0,
            total: 0,
        }
    }

    handlePageChange(page) {
        this.getPlaces(page)
    }

    getPlaces(page = 1) {
        console.log(this.state.keyword)
        axios
            .post(`${process.env.REACT_APP_API_URL}search/place`, {
                page,
                keyword: this.state.keyword,
            })
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    this.setState({
                        place: result,
                        places: result.data,
                        currentPage: result.current_page,
                        perPage: result.per_page,
                        total: result.total,
                    })
                    window.scrollTo(0, 0)
                    // this.forceUpdate()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>장소 리스트</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            type='text'
                            className='form-control'
                            placeholder='장소 검색어'
                            value={this.state.keyword}
                            style={{
                                flex: 1,
                            }}
                            onChange={(e) => {
                                this.setState({
                                    keyword: e.target.value.toLowerCase().trim(),
                                })
                            }}
                        />
                        <label
                            onClick={() => {
                                this.getPlaces(1)
                            }}
                            style={{width: 100}}
                            className='btn btn-dark'
                        >
                            검색
                        </label>
                    </div>
                    <div className={'row'}>
                        {this.state.places.map((item, index) => {
                            return (
                                <div className='col-lg-3' key={index.toString()}>
                                    <div className='card card-custom card-stretch shadow mb-5'>
                                        <div className='card-header'>
                                            <h1 className='card-title'>
                                                {item.id}. {item.title}
                                            </h1>
                                        </div>
                                        <div className='card-body'>
                                            <img className='rounded' src={item.image_url} style={{height: 120, width: '100%'}} alt='' />
                                            <h3>{item.price.toLocaleString('en')}</h3>
                                        </div>
                                        <div className='card-footer'>
                                            <label
                                                onClick={() => {
                                                    if (window.confirm(`${item.id}. ${item.title} 장소를 추가하시겠습니까?`)) {
                                                        this.props.onAdd(item)
                                                    }
                                                }}
                                                className='btn btn-primary'
                                            >
                                                추가
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Pagination
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={10}
                        prevPageText={'‹'}
                        nextPageText={'›'}
                        onChange={this.handlePageChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class PlaceImagesModal extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            uploading: false,
        }
    }

    render() {
        const {uploading} = this.state
        return (
            <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>장소 이미지 리스트</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                width: 300,
                                height: 100,
                            }}
                        >
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    console.log(acceptedFiles)
                                    this.props.imageUpload(acceptedFiles[0], this.props.place)
                                }}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section
                                        className='container'
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#DDD',
                                            borderRadius: 4,
                                            cursor: 'pointer',
                                            padding: 0,
                                        }}
                                    >
                                        <div
                                            {...getRootProps({className: 'dropzone'})}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{margin: 0}}>이미지를 드래그 하여 넣거나 클릭하세요.</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {uploading && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255,255,255,0.8)',
                                    }}
                                >
                                    <span className='spinner-border ' />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 40,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {this.props.place !== null &&
                            this.props.place.images.map((image) => {
                                return (
                                    <div style={{position: 'relative', margin: 10}} key={image.id}>
                                        <img src={image.image_url} alt='' style={{width: 220, height: 220, objectFit: 'cover'}} />
                                        <Button size='sm' style={{position: 'absolute', right: 4, bottom: 4}} variant='primary' onClick={() => this.props.updatePlace(this.props.place, image.id)}>
                                            메인 이미지로 변경
                                        </Button>
                                    </div>
                                )
                            })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
