import React from 'react'
import {isMobile} from 'react-device-detect'

type Props = {
  title: string
  subTitle: string
}

const TextSub2: React.FC<Props> = ({title, subTitle}) => {
  return <div style={{
    marginTop: 16,
    width: '100%',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'flex-start' : 'center',
  }}>
    <p style={{
      marginRight: 20,
      fontWeight: 500,
      fontSize: 15,
      color: '#1B1D1F',
    }}>
      {title}
    </p>
    <p style={{
      fontWeight: 500,
      fontSize: 15,
      color: '#9EA4AA',
    }}>
      {subTitle}
    </p>
  </div>
}

export {TextSub2}
