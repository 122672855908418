import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {useParams} from 'react-router-dom'
import classNames from 'classnames'
import {UserItem} from '../../components/UserItem'
import {BookingPlaceItem} from '../../components/BookingPlaceItem'

export function UserGroupDetail() {

  const {group_id} = useParams<{group_id: string}>()

  const [group, setGroup] = useState<any>(null)

  const [items, setItems] = useState<any>([])
  const [users, setUsers] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [status, setStatus] = useState('1')

  useEffect(() => {

    getGroup()
    getUsers()
    getBookings()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getGroup = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/user/group/${group_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setGroup(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/user/group/${group_id}/users`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setUsers(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const handlePageChange = (page: number) => {
    getBookings(page)
  }

  const getBookings = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/user/group/${group_id}/bookings?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>그룹</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      {
        group !== null &&
        <div>
          <h1>{group.name} ({group.genre})</h1>
          <p>{group.total_price.toLocaleString('en')}</p>
        </div>
      }

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
          <li className='nav-item'>
            <label
              className={classNames('nav-link', status === '1' ? 'active' : '')}
              onClick={() => setStatus('1')}
            >
              예약 ({total.toLocaleString('en')})
            </label>
          </li>
          <li className='nav-item'>
            <label
              className={classNames('nav-link', status === '2' ? 'active' : '')}
              onClick={() => setStatus('2')}
            >
              사용자 ({Number(users.length).toLocaleString('en')})
            </label>
          </li>
        </ul>

        {
          status === '1' &&
          <div>

            {
              items.map((item: any, index: number) => {
                return (<BookingPlaceItem key={`booking_${index}`} booking={item} />)
              })
            }

            <Pagination activePage={currentPage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        prevPageText={'‹'}
                        nextPageText={'›'}
                        onChange={handlePageChange} />
          </div>
        }
        {
          status === '2' &&
          <div>

            {
              users.map((item: any, index: number) => {
                return (<UserItem key={`user_${index}`} user={item} />)
              })
            }

          </div>
        }

      </div>
    </div>
  </>
}

