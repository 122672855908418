import React, {useEffect, useState} from 'react'
// @ts-ignore
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../../components/TextSub'
import {useParams} from 'react-router-dom'
import moment from 'moment/moment'
import {toast} from 'react-toastify'
import classNames from 'classnames'
import SwitchButton from '../../../../components/SwitchButton'

export function AdvertiseBToBGuestBannerDetail() {
  const {id} = useParams<{id: string}>()

  const [loading, setLoading] = useState(false)

  const [bToBBannerAdv, setBToBBannerAdv] = useState<any>('')
  const [log, setLog] = useState(0)

  useEffect(() => {
    getBToBBanner()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getBToBBanner = () => {
    setBToBBannerAdv('')
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/G/btob/banner/${id}`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          console.log(result.advPrice)
          setBToBBannerAdv(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const cancelAdv = () => {
    console.log(bToBBannerAdv.id)
    if (bToBBannerAdv.id <= 0) {
      toast.error('취소 시 필수 값 체크!')
      return false
    }
    if (window.confirm('취소 처리를 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/btob/banner/${id}/cancel`)
        .then(({data: {error, result}}) => {
          console.log(result)
          console.log(result.result)
          if (error !== '') {
          } else {
            const temp = Object.assign({}, bToBBannerAdv)
            temp.advStatus = 'C'
            temp.advCancel = moment(new Date()).format('YYYYMMDD')
            temp.advStatusDisplay = '광고 취소'
            setBToBBannerAdv(temp)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>게스트향 배너 광고</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {bToBBannerAdv !== '' && (
              <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <h2>
                    {bToBBannerAdv.id}. [{bToBBannerAdv.advStatusDisplay}]
                  </h2>
                </div>

                <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div
                      style={{
                        width: 150,
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={bToBBannerAdv.userImageUrl} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
                    </div>

                    <div style={{marginLeft: 10}}>
                      {bToBBannerAdv.userBlack === 1 && <p style={{color: 'red'}}>{bToBBannerAdv.userBlackComment}</p>}

                      <h1>{bToBBannerAdv.userId}. 게스트</h1>
                      <TextSub title={'닉네임 (이름)'} subTitle={`${bToBBannerAdv.userName} (${bToBBannerAdv.userRealName})`} marginTop={0} />
                      <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(bToBBannerAdv.userBirth))).asYears())}세`} marginTop={-10} />
                      <TextSub title={'전화번호'} subTitle={bToBBannerAdv.userPhone} marginTop={-10} />
                      <TextSub title={'이메일'} subTitle={bToBBannerAdv.userEmail} marginTop={-10} />
                      <TextSub
                        title={'가입일'}
                        subTitle={`${bToBBannerAdv.userCreatedAt}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(bToBBannerAdv.userCreatedAt)).asDays()} 일전`}
                        marginTop={-10}
                      />
                      <TextSub
                        title={'마지막 활성 시간'}
                        subTitle={`${moment(bToBBannerAdv.userLastLoginedAt).format('MM-DD')}, ${Math.floor(
                          (moment.duration(moment().diff(moment(bToBBannerAdv.userLastLoginedAt, 'YYYY-MM-DD'))).asDays() * 100) / 100
                        )} 일전`}
                        marginTop={-10}
                      />
                      {bToBBannerAdv.memo !== undefined && bToBBannerAdv.memo.length > 0 && <TextSub title={'메모'} subTitle={bToBBannerAdv.memo} marginTop={-10} />}
                    </div>
                  </div>

                  <div>
                    <SwitchButton id={bToBBannerAdv.userId}>Switch</SwitchButton>
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        window.open(`/user/${bToBBannerAdv.userId}`)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      자세히 보기
                    </label>
                  </div>
                </div>

                <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                  <div style={{margin: 10, paddingBottom: 10}}>
                    <table style={{fontSize: '15px', width: '100%', lineHeight: 2}}>
                      <colgroup>
                        <col style={{width: 120}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>광고 일정</td>
                          <td>{bToBBannerAdv.advSchedule}</td>
                        </tr>
                        <tr>
                          <td>금액</td>
                          <td>{bToBBannerAdv.advPrice.toLocaleString()}원</td>
                        </tr>
                        <tr>
                          <td>취소일</td>
                          <td>{bToBBannerAdv.advCancel}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <label
                    onClick={() => {
                      window.location.href = `/advertise/btob/banner/guest/editor/${id}`
                    }}
                    style={{marginLeft: 10}}
                    className='btn btn-sm btn-primary'
                  >
                    수정하기
                  </label>
                  {bToBBannerAdv.advStatus === 'W' && (
                    <label
                      onClick={() => {
                        cancelAdv()
                      }}
                      style={{marginLeft: 10}}
                      className='btn btn-sm btn-danger'
                    >
                      취소하기
                    </label>
                  )}
                </div>
              </div>
            )}
            {bToBBannerAdv !== null && (
              <div>
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 0 ? 'active' : '')} onClick={() => setLog(0)}>
                      광고 진행 로그 ({bToBBannerAdv.btoBBannerAdvertiseHistories !== undefined ? bToBBannerAdv.btoBBannerAdvertiseHistories.length : 0})
                    </label>
                  </li>
                </ul>
                <div>
                  {log === 0 && (
                    <div>
                      {bToBBannerAdv.btoBBannerAdvertiseHistories !== undefined &&
                        bToBBannerAdv.btoBBannerAdvertiseHistories.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_0_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h3
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <p dangerouslySetInnerHTML={{__html: history.content}} />
                              </h3>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
