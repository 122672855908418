import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {toast} from 'react-toastify'
import ImagePath from '../../../commons/ImagePath'
import moment from 'moment/moment'

export function UserPlaceFeedList() {
    const [items, setItems] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)

    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState('')

    const [status, setStatus] = useState('')

    useEffect(() => {
        getUserPlaceFeed()
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page: number) => {
        getUserPlaceFeed(status, page)
    }

    const onSearch = () => {
        setStatus('')
        getUserPlaceFeed('', 1, search)
    }

    const getUserPlaceFeed = (status = '', page = 1, search = '') => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/feed/place?keyword=${search}&page=${page}`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    setItems(result.data)

                    setCurrentPage(result.current_page)
                    setPerPage(result.per_page)
                    setTotal(result.total)
                    window.scrollTo(0, 0)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    const skip = (item: any) => {
        console.log({id: item.id})
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/feed/${item.id}/skip`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    const temps = [...items]
                    temps.splice(temps.indexOf(item), 1, item)
                    item.check = 1
                    setItems(temps)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const hideAndShow = (item: any, status = '') => {
        console.log({id: item.id, status: status})
        axios
            .put(`${process.env.REACT_APP_API2_URL}am/feed/${item.id}/status/${status}`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    const temps = [...items]
                    temps.splice(temps.indexOf(item), 1, item)
                    item.status = status
                    if (status === 'P') {
                        item.check = 0
                    } else if (status === 'H') {
                        item.check = 2
                    }

                    setItems(temps)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>장소 피드_장소 소식</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className='card card-custom card-stretch shadow mb-5' style={{marginTop: 20, width: '97%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='게시물 번호, 회원 번호, 회원 이름, 장소 번호, 장소명'
                        value={search}
                        style={{flex: 1}}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onSearch()
                            }
                        }}
                    />
                    <label
                        onClick={() => {
                            onSearch()
                        }}
                        style={{width: 100}}
                        className='btn btn-dark'
                    >
                        검색
                    </label>
                </div>

                {loading ? (
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Loading...
                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                    </div>
                ) : (
                    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                        {items.map((item: any, index: number) => {
                            return (
                                <div className='col-12' key={index.toString()}>
                                    <div className='card card-custom card-stretch shadow mb-5'>
                                        <div className='card-header'>
                                            <h1 className='card-title'>{item.id}.</h1>
                                        </div>

                                        <div className='card-header'>
                                            <div style={{width: 54, height: 73, top: 19, bottom: 98, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <img src={item.host_image_url} alt='' style={{width: 54, height: 53, objectFit: 'cover', borderRadius: 100}} />
                                            </div>
                                            <table style={{fontSize: '15px', width: '100%', marginBottom: 25}}>
                                                <colgroup>
                                                    <col className='col1' />
                                                    <col style={{width: 'auto'}} />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} style={{fontWeight: 'bold', fontSize: 20, lineHeight: 2}}>
                                                            {item.user_id}. 호스트
                                                            <span
                                                                style={{minWidth: 40, paddingLeft: 5, fontWeight: 500, fontSize: 20, color: '#007BFF', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    window.open(`/user/${item.user_id}`)
                                                                }}
                                                            >
                                                                보기
                                                            </span>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>닉네임 (이름)</td>
                                                        <td style={{fontWeight: 500}}>
                                                            {item.host_name} ({item.user_real_name})
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>가입일</td>
                                                        <td style={{fontWeight: 500}}>{`${moment(item.user_created_at).format('YYYY-MM-DD')}, ${Math.floor(
                                                            moment.duration(moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(item.user_created_at).format('YYYY-MM-DD HH:mm:ss'))).asDays()
                                                        )} 일전`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>마지막 활성 시간</td>
                                                        <td style={{fontWeight: 500}}>{`${moment(item.user_last_logined_at).format('MM-DD')}, ${Math.floor(
                                                            moment
                                                                .duration(moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(item.user_last_logined_at).format('YYYY-MM-DD HH:mm:ss')))
                                                                .asDays()
                                                        )} 일전`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>패널티 히스토리</td>
                                                        <td style={{fontWeight: 500}}>{item.user_ban}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='card-body'>
                                            <table style={{fontSize: '15px', width: '100%'}}>
                                                <colgroup>
                                                    <col className='col1' />
                                                    <col style={{width: 'auto'}} />
                                                </colgroup>
                                                <tbody style={{lineHeight: 2.5}}>
                                                    <tr>
                                                        <td>게시물 등록일</td>
                                                        <td style={{fontWeight: 500}}>{item.created_at}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>게시물 상태</td>
                                                        <td style={{fontWeight: 500}}>{item.status === 'P' ? '게시 중' : item.status === 'H' ? '숨김' : '삭제'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>게시물 타입</td>
                                                        <td style={{fontWeight: 500}}>{item.place_feed_type === 'I' ? '이미지' : item.place_feed_type === 'V' ? '영상' : '유튜브'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>본문 내용</td>
                                                        <td style={{fontWeight: 500}}>{item.content}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>태그</td>
                                                        <td style={{fontWeight: 500}}>{item.tag}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>장소번호</td>
                                                        <td style={{fontWeight: 500}}>
                                                            {item.place_id}
                                                            <span
                                                                style={{minWidth: 40, paddingLeft: 5, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    window.open(`/place/${item.place_id}`)
                                                                }}
                                                            >
                                                                보기
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {item.place_feed_type === 'I' &&
                                                null !== item.image &&
                                                item.image.map((image: any) => {
                                                    return (
                                                        <>
                                                            {null !== image && null !== image.image_path && (
                                                                <img
                                                                    src={ImagePath.get(image.image_path, '2000x2000', 'inside')}
                                                                    style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}}
                                                                    alt=''
                                                                    key={image.id.toString()}
                                                                />
                                                            )}
                                                        </>
                                                    )
                                                })}
                                            {item.place_feed_type === 'Y' && <img src={item.youtube.image_maxres} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' />}
                                            {item.place_feed_type === 'V' && (
                                                <img src={ImagePath.get(item.video.thumbnail_path, '2000x2000', 'inside')} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' />
                                            )}
                                        </div>

                                        <div className='card-footer'>
                                            {item.check === 0 && (
                                                <div className={'row'}>
                                                    {item.status === 'P' && (
                                                        <div style={{padding: 4, width: 'fit-content'}}>
                                                            <label
                                                                style={{border: '1px solid #FF4B3E', backgroundColor: '#FF4B3E'}}
                                                                onClick={() => {
                                                                    hideAndShow(item, 'H')
                                                                }}
                                                                className='btn btn-outline-light'
                                                            >
                                                                게시물 숨김
                                                            </label>
                                                        </div>
                                                    )}
                                                    {item.status === 'H' && (
                                                        <div style={{padding: 4, width: 'fit-content'}}>
                                                            <label
                                                                style={{border: '1px solid #3C82FA', backgroundColor: '#3C82FA'}}
                                                                onClick={() => {
                                                                    hideAndShow(item, 'P')
                                                                }}
                                                                className='btn btn-outline-light'
                                                            >
                                                                게시물 노출
                                                            </label>
                                                        </div>
                                                    )}
                                                    <div style={{padding: 4, width: 'fit-content'}}>
                                                        <label
                                                            style={{border: '1px solid #6AD559'}}
                                                            onClick={() => {
                                                                skip(item)
                                                            }}
                                                            className='btn btn-outline-success'
                                                        >
                                                            건너뛰기
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {item.check === 1 && (
                                                <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                    <label style={{border: '1px solid #007BFF', borderRadius: 8, padding: '8px 16px'}} className='btn-outline-primary'>
                                                        {item.check === 1 ? '건너뛰기 처리 완료' : ''}
                                                    </label>
                                                </div>
                                            )}
                                            {item.check === 2 && (
                                                <div className={'row'}>
                                                    {item.status === 'P' && (
                                                        <div style={{padding: 4, width: 'fit-content'}}>
                                                            <label
                                                                style={{border: '1px solid #FF4B3E', backgroundColor: '#FF4B3E'}}
                                                                onClick={() => {
                                                                    hideAndShow(item, 'H')
                                                                }}
                                                                className='btn btn-outline-light'
                                                            >
                                                                게시물 숨김
                                                            </label>
                                                        </div>
                                                    )}
                                                    {item.status === 'H' && (
                                                        <div style={{padding: 4, width: 'fit-content'}}>
                                                            <label
                                                                style={{border: '1px solid #3C82FA', backgroundColor: '#3C82FA'}}
                                                                onClick={() => {
                                                                    hideAndShow(item, 'P')
                                                                }}
                                                                className='btn btn-outline-light'
                                                            >
                                                                게시물 노출
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <div style={{padding: 20}}>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={10}
                                prevPageText={'‹'}
                                nextPageText={'›'}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
