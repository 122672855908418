import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import classNames from 'classnames'
import Modal from '../../components/Modal'

export function BookingProject() {

  const [status, setStatus] = useState('not')
  const [search, setSearch] = useState('')

  const [items, setItems] = useState<any>([])
  const [item, setItem] = useState<any>(null)

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [projectNotCount, setProjectNotCount] = useState(0)
  const [projectCount, setProjectCount] = useState(0)

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {

    getProjects(status, 1)

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getProjects(tabStatus, 1)
  }

  const onSearch = () => {
    getProjects(status, 1)
  }

  const handlePageChange = (page: number) => {
    getProjects(status, page)
  }

  const getProjects = (status = '', page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/project?page=${page}&type=${status}&keyword=${search}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.projects.data)
          setCurrentPage(result.projects.current_page)
          setPerPage(result.projects.per_page)
          setTotal(result.projects.total)
          setProjectCount(result.project_count)
          setProjectNotCount(result.project_not_count)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editProject = (item: any) => {
    setItem(item)
    getProject(item)
  }

  const getProject = (item: any) => {
    console.log(item)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/project/${item.id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          item.result = result
          item.result.typeGenreTitle = ''
          item.result.typeGuestTitle = ''
          item.result.typeShootTitle = ''
          setModalVisible(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onSave = () => {
    console.log(item.result)
    if (Number(item.result.project.project_type_guest_id) > 0) {
      axios
        .put(`${process.env.REACT_APP_API_URL}admin/booking/project/${item.id}`, {
          project_type_guest_id: item.result.project.project_type_guest_id,
          project_type_shoot_id: item.result.project.project_type_shoot_id,
          project_type_genre_id: item.result.project.project_type_shoot_id,
          type_guest: item.result.typeGuestTitle,
          type_shoot: item.result.typeShootTitle,
          type_genre: item.result.typeGenreTitle,
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            window.alert(error)
          } else {
            setModalVisible(false)
            if (status === 'not') {
              const temps = [...items]
              temps.splice(items.indexOf(item), 1)
              setItems(temps)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 분류</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}>
          <label onClick={() => {
            onTab('not')
          }} className={classNames('btn', {'btn-dark': status === 'not'}, {'btn-btn-white': status !== 'not'})}>미분류 ({projectNotCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('')
          }} className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}>모두보기 ({projectCount.toLocaleString('en')})</label>

        </div>
        <div style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='프로젝트 검색을 해봅시다. (프로젝트 번호, 프로젝트 내용, 예약자 이름)'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{
            width: 100,
          }} className='btn btn-dark'>검색</label>
        </div>

        <div style={{
          marginTop: 20,
        }}>
          {items.map((item: any, index: number) => {
            return (
              <div className='col-12' key={index.toString()}>
                <div className='card card-custom card-stretch shadow mb-5'>
                  <div className='card-header'>
                    <h1 className='card-title'>
                      {item.id}
                    </h1>
                  </div>
                  <div className='card-body'>
                    <h5>게스트</h5>
                    <p>{item.guest_name}</p>
                    <h5>장르</h5>
                    <p>{item.genre}</p>
                    <h5>내용</h5>
                    <p style={{
                      whiteSpace: 'pre-wrap',
                    }}>{item.content}</p>
                    <h5>분류</h5>
                    <p>
                      {item.project_type_guest_name} * {item.project_type_shoot_name} * {item.project_type_genre_name}
                    </p>

                  </div>
                  <div className='card-footer'>
                    <label onClick={() => {
                      editProject(item)
                    }} className='btn btn-primary'>수정</label>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
    {
      modalVisible &&
      <Modal
        visible={modalVisible}
        closable={true}
        maskClosable={true}
        onClose={() => {
          setModalVisible(false)
        }} className={''}>
        <div>
          <h2>
            {item.id}
          </h2>
          <div className='separator my-10' />
          {item.result.user !== null && (
            <>
              <h5>소속</h5>
              <p>{item.result.user.company}</p>
              <h5>직급</h5>
              <p>{item.result.user.position}</p>
              <h5>그룹</h5>
              <p>{item.result.user.group_name}</p>
            </>
          )}
          <div className='separator my-10' />
          <>
            <h5>장르</h5>
            <p style={{
              whiteSpace: 'pre-wrap',
            }}>{item.genre}</p>
            <h5>내용</h5>
            <p style={{
              whiteSpace: 'pre-wrap',
            }}>{item.content}</p>
          </>
          <div className='separator my-10' />
          <h5>게스트 분류</h5>
          <div>
            <select
              className='form-control'
              name='type'
              onChange={(e) => {
                const temp = {...item}
                temp.result.project.project_type_guest_id = e.target.value
                setItem(temp)
              }}
              value={item.result.project.project_type_guest_id}
            >
              <option value={0}>없음</option>
              {item.result.type_guests.map((genre: any) => {
                return <option value={genre.id}>{genre.name}</option>
              })}
              <option value={9999}>기타</option>
            </select>
            {item.result.project.project_type_guest_id === '9999' && (
              <div style={{
                marginTop: 10,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='추가할 게스트 분류를 입력해 주세요.'
                  value={item.result.typeGuestTitle}
                  onChange={(e) => {
                    const temp = {...item}
                    temp.result.typeGuestTitle = e.target.value
                    setItem(temp)
                  }}
                />
              </div>
            )}
            {item.result.auto.guest.map((guest: any, index: number) => {
              return (
                <label key={`guest_${index}`} style={{margin: 4}} onClick={() => {
                  const temp = {...item}
                  temp.result.project.project_type_guest_id = guest.project_type_guest_id
                  setItem(temp)
                }} className={'btn btn-secondary'}>{guest.name}</label>
              )
            })}
          </div>
          <div className='separator my-10' />
          <h5>촬영 분류</h5>
          <div>
            <select
              className='form-control'
              name='type'
              onChange={(e) => {
                const temp = {...item}
                temp.result.project.project_type_shoot_id = e.target.value
                setItem(temp)
              }}
              value={item.result.project.project_type_shoot_id}
            >
              <option value={0}>없음</option>
              {item.result.type_shoots.map((shoot: any) => {
                return <option value={shoot.id}>{shoot.name}</option>
              })}
              <option value={9999}>기타</option>
            </select>
            {item.result.project.project_type_shoot_id === '9999' && (
              <div style={{
                marginTop: 10,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='추가할 촬영 분류를 입력해 주세요.'
                  value={item.result.typeShootTitle}
                  onChange={(e) => {
                    const temp = {...item}
                    temp.result.typeShootTitle = e.target.value
                    setItem(temp)
                  }}
                />
              </div>
            )}
            {item.result.auto.shoot.map((shoot: any, index: number) => {
              return (
                <label key={`shoot_${index}`} style={{margin: 4}} onClick={() => {
                  const temp = {...item}
                  temp.result.project.project_type_shoot_id = shoot.project_type_shoot_id
                  setItem(temp)
                }} className={'btn btn-secondary'}>{shoot.name}</label>
              )
            })}
          </div>
          <div className='separator my-10' />
          <h5>장르 분류</h5>
          <div>
            <select
              className='form-control'
              name='type'
              onChange={(e) => {
                const temp = {...item}
                temp.result.project.project_type_genre_id = e.target.value
                setItem(temp)
              }}
              value={item.result.project.project_type_genre_id}
            >
              <option value={0}>없음</option>
              {item.result.type_genres.map((genre: any) => {
                return <option value={genre.id}>{genre.name}</option>
              })}
              <option value={9999}>기타</option>
            </select>
            {item.result.project.project_type_genre_id === '9999' && (
              <div style={{
                marginTop: 10,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='추가할 장르 분류를 입력해 주세요.'
                  value={item.result.typeGenreTitle}
                  onChange={(e) => {
                    const temp = {...item}
                    temp.result.typeGenreTitle = e.target.value
                    setItem(temp)
                  }}
                />
              </div>
            )}
            {item.result.auto.genre.map((genre: any, index: number) => {
              return (
                <label key={`genre_${index}`} style={{margin: 4}} onClick={() => {
                  const temp = {...item}
                  temp.result.project.project_type_genre_id = genre.project_type_genre_id
                  setItem(temp)
                }} className={'btn btn-secondary'}>{genre.name}</label>
              )
            })}
          </div>

          {Number(item.result.project.project_type_guest_id) > 0 && (
            <>
              <div className='separator my-10' />
              <label style={{margin: 4}} onClick={() => {
                onSave()
              }} className={'btn btn-secondary'}>저장</label>
            </>
          )}

        </div>
      </Modal>
    }
  </>
}

