import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByFirebaseToken, getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {initializeApp} from 'firebase/app'
import {getAuth, signOut, signInWithCustomToken} from 'firebase/auth'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const firebaseConfig = JSON.parse(`${process.env.REACT_APP_FIREBASE_CONFIG}`)

const AuthInit: FC<PropsFromRedux> = (props) => {
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const apiToken = useSelector<RootState>(({auth}) => ({
    apiToken: auth.apiToken,
  }), shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: {error, result}} = await getUserByToken()
          console.log('requestUser', error, result)
          if (error !== '' || result.admin === 0) {
            await signOut(auth)
            dispatch(props.logout())
          } else {
            dispatch(props.fulfillUser(result))
            await requestFirebaseToken()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (apiToken) {
      requestUser().then()
    } else {
      signOut(auth).then()
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  const requestFirebaseToken = async () => {
    console.log('requestFirebaseToken currentUser', auth.currentUser)
    if (auth.currentUser === null) {
      const {data: {error, result}} = await getUserByFirebaseToken()
      console.log('getUserByFirebaseToken', error, result)
      signInWithCustomToken(auth, result).then((userCredential) => {
        const user = userCredential.user
        console.log(user)
        requestFirebasePermission()
      }).catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(`${errorCode} : ${errorMessage}`)
      })
    } else {
      await requestFirebasePermission()
    }
  }

  const requestFirebasePermission = async () => {
    if (auth.currentUser) {
      auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        console.log('idTokenResult', idTokenResult.claims)
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
