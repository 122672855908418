import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {isMobile} from 'react-device-detect'

export function PlaceFilterRegions() {

  const [items, setItems] = useState<any>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRegions()
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRegions = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/filter/regions`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>필터 지역 관리</PageTitle>
    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }}>
      <div className="tab-content w-100" id="myTabContent">
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
            <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
            <tr>
              <th scope="col" className="min-w-30px"></th>
              <th scope="col" className="min-w-100px">id</th>
              <th scope="col" className="min-w-100px">지역명</th>
              <th scope="col" className="min-w-100px">주소 검색 index1</th>
              <th scope="col" className="min-w-100px">위도</th>
              <th scope="col" className="min-w-100px">경도</th>
              <th scope="col" className="min-w-30px"></th>
            </tr>
            </thead>
            <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
            {
              loading ?
                <tr style={{height: 98}}>
                  <td colSpan={44}>
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                    </div>
                  </td>
                </tr>
                :
                items !== null && items.map((item: any, index: number) => {
                  return (
                    <tr key={`${index}`} style={{height: 98}}>
                      <td></td>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.address_index1}</td>
                      <td>{item.latitude}</td>
                      <td>{item.longitude}</td>
                      <td></td>
                    </tr>
                  )
                })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>
}

