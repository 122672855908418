import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from "axios";
import Modal from "../../../components/Modal";
import Pagination from "react-js-pagination";
import ImagePath from "../../../../commons/ImagePath";

export function AdvertiseNetworkPlaceIndexV2() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [search, setSearch] = useState('')

  const [places, setPlaces] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [modalVisible, setModalVisible] = useState(false)
  const [schedule, setSchedule] = useState<any>([])
  const [selectSchedule, setSelectSchedule] = useState<any>([])

  const [advTotalCount, setAdvTotalCount] = useState(0)
  const [advPwCount, setAdvPwCount] = useState(0)
  const [advPfCount, setAdvPfCount] = useState(0)
  const [advIfCount, setAdvIfCount] = useState(0)
  const [advNfCount, setAdvNfCount] = useState(0)
  const [advSfCount, setAdvSfCount] = useState(0)
  const [advICount, setAdvICount] = useState(0)
  const [advECount, setAdvECount] = useState(0)
  const [advCCount, setAdvCCount] = useState(0)

  useEffect(() => {
    getStartSchedule();
    getNetworkPlaces();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getNetworkPlaces(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getNetworkPlaces('', 1)
  }

  const handlePageChange = (page: number) => {
    getNetworkPlaces(status, page)
  }

  const getStartSchedule = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/start/schedule`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setSchedule(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getNetworkPlaces = (status = '', page = 1) => {
    setPlaces([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    console.log(searchFilter)

    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/N/place?page=${page}&status=${status}&search=${search}&searchFilter=${searchFilter}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setPlaces(result.advertise.data);
        setCurrentPage(result.advertise.current_page);
        setPerPage(result.advertise.per_page);
        setTotal(result.advertise.total);
        window.scrollTo(0, 0)
        // this.forceUpdate()
        getAdvCount();
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const getAdvCount = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/N/place/count`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        // this.forceUpdate()
        setAdvTotalCount(result.advTotalCount);
        setAdvPwCount(result.advPwCount);
        setAdvPfCount(result.advPfCount);
        setAdvIfCount(result.advIfCount);
        setAdvNfCount(result.advNfCount);
        setAdvSfCount(result.advSfCount);
        setAdvICount(result.advICount);
        setAdvECount(result.advECount);
        setAdvCCount(result.advCCount);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>네크워크 광고</PageTitle>
    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <label className={'btn btn-primary'} onClick={() => {
        setModalVisible(true)
      }}>
        광고 등록하기
      </label>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <select className='form-control' name='type' value={status} onChange={(e) => {onTab(e.target.value)}}>
          <option value={''}>모두보기 ({advTotalCount})</option>
          <option value={'PW'}>결제 대기 ({advPwCount})</option>
          <option value={'PF'}>결제 완료 ({advPfCount})</option>
          <option value={'IF'}>소재 등록 완료 ({advIfCount})</option>
          <option value={'SF'}>광고 세팅 완료 ({advSfCount})</option>
          <option value={'NF'}>시작 전 안내 완료 ({advNfCount})</option>
          <option value={'I'}>광고 중 ({advICount})</option>
          <option value={'E'}>광고 종료 ({advECount})</option>
          <option value={'C'}>광고 취소 ({advCCount})</option>
        </select>
        <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <select className='form-control' name='searchFilter' value={searchFilter} onChange={(e) => {setSearchFilter(e.target.value)}} style={{width:'100px'}}>
            <option value={''}>전체</option>
            <option value={'1'}>광고 일정 ID</option>
          </select>
          <input type='text' className='form-control' placeholder='광고 ID, 호스트 ID, 호스트 닉네임, 호스트 이름, 이메일, 휴대폰 번호, 장소 번호, 장소명' value={search} style={{marginLeft: 10, flex: 1, width:'70%'}}
              onChange={(e) => {setSearch(e.target.value)}}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
          />
          <label onClick={() => {onSearch()}} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>
      </div>

      <div className='tab-content w-100' id='myTabContent'>
        <div className="table-responsive">
          <table className="table table-striped text-center">
            <thead>
            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
              <th className="min-w-100px">광고 번호</th>
              <th className="min-w-200px">진행 상태</th>
              <th className="min-w-100px">광고 일정 ID</th>
              <th className="min-w-100px">장소 번호</th>
              <th className="min-w-100px">장소 썸네일</th>
              <th className="min-w-100px">호스트 번호</th>
              <th className="min-w-100px">광고 신청일</th>
              <th className="min-w-150px">광고 세팅 마감일</th>
              <th className="min-w-100px">광고 시작일</th>
              <th className="min-w-100px">광고 종료일</th>
              <th className="min-w-100px">광고 게재 기간</th>
              <th className="min-w-200px">결제 금액(VAT 포함)</th>
            </tr>
            </thead>

            <tbody className="align-middle">
            {
              loading ?
                <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                </div>
                :
                places.map((place: any, index: number) => {
                  return (
                    <tr key={`${index}`}>
                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer'}}
                           onClick={() => {
                             window.open(`/advertise/network/${place.advPlaceAdvertiseGroupId}/place`, '_blank')
                           }}
                        >
                          {place.advPlaceAdvertiseGroupId}
                        </p>
                      </td>
                      <td>{place.advStatusDisplay}</td>
                      <td>{place.advNetworkScheduleId}</td>
                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer'}}
                           onClick={() => {
                             window.open(`/place/${place.placeId}`, '_blank')
                           }}
                        >
                          {place.placeId}
                        </p>
                      </td>
                      <td>
                        <div style={{width: 54, height: 54, position: 'relative'}}>
                          <img src={ImagePath.get(place.placeMainImagePath, '54x54')} alt="" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                          <div style={{
                            position: 'absolute',
                            width: 15,
                            height: 15,
                            borderTopLeftRadius: 8,
                            bottom: 0,
                            right: 0,
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                            <h6 style={{margin: 0}}>
                              {place.placeGrade}
                            </h6>
                          </div>
                        </div>
                      </td>

                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer'}}
                           onClick={() => {
                             window.open(`/user/${place.userId}`, '_blank')
                           }}
                        >
                          {place.userId}
                        </p>
                      </td>

                      <td>{place.createdAt}</td>
                      <td>{place.advSettingDeadlineDate}</td>
                      <td>{place.advertiseStartDate}</td>
                      <td>{place.advertiseEndDate}</td>
                      <td>{place.advertiseWeek}</td>
                      <td>{place.advPrice.toLocaleString()}</td>

                    </tr>
                  )
                })

            }
            </tbody>

          </table>
        </div>
      </div>

      {
        places.length > 0 &&
        <div style={{marginTop: 20}}>
          <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
        </div>
      }

    </div>

    {
      modalVisible &&
      <Modal visible={modalVisible} closable={true} maskClosable={true} onClose={() => {
        setModalVisible(false)
      }} className={''}>
        <div style={{padding: 20, width: '100%'}}>
          <select
            className="form-select form-select-solid"
            name="type"
            value={selectSchedule}
            onChange={(e) => {
                  setSelectSchedule(e.target.value)
                }}
            >
              <option key={''} value={''}>광고 게재 시작일을 선택해주세요.</option>
              {
                schedule.map((item: any) => {
                  return (
                      <option key={item.id} value={item.id}>{item.id}. {item.advertise_start_date}</option>
                  )})
              }
            </select>
          </div>
          <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
            <label onClick={() => {
              let schedule = `${selectSchedule}`;
              if(schedule !== ''){
                window.location.href = `/advertise/network/${selectSchedule}/place/add`
              }
            }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>확인</label>
          </div>
        </Modal>
    }
  </>
}

