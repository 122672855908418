export default class Constants {
  static bankList = [
    '경남은행',
    '광주은행',
    '교보증권',
    '국민은행',
    '기업은행',
    '농협은행',
    '대구은행',
    '대신증권',
    '도이치은행',
    '메리츠종합금융증권',
    '미래에셋대우',
    '바로투자증권',
    '부국증권',
    '부산은행',
    '비엔피파리바은행',
    '산림조합',
    '산업은행',
    '삼성증권',
    '새마을금고',
    '수협중앙회',
    '신영증권',
    '신한금융투자',
    '신한은행',
    '신협',
    '우리은행',
    '우체국',
    '유안타증권',
    '유진투자증권',
    '이베스트투자증권',
    '상호저축은행',
    '전북은행',
    '제이피모간체이스은행',
    '제주은행',
    '중국건설은행',
    '중국공상은행',
    '지역농축협',
    '카카오뱅크',
    '카카오페이증권',
    '케이뱅크',
    '케이프투자증권',
    '키움증권',
    '토스뱅크',
    '토스증권',
    '펀드온라인코리아',
    '하나금융투자',
    '하나은행',
    '하이투자증권',
    '한국씨티은행',
    '한국투자증권',
    '한국포스증권',
    '한화투자증권',
    '현대차증권',
    'BOA은행',
    'DB금융투자',
    'HSBC은행',
    'KB증권',
    'KTB투자증권',
    'LIG투자증권',
    'NH투자증권',
    'SC제일은행',
    'SK증권',
  ]
}
