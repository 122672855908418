import React, {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import ImagePath from '../../../commons/ImagePath'

export function MarketingCuration() {
  const [mainCategories, setMainCategories] = useState([])
  const [categoryId, setCategoryId] = useState(0)
  const [category, setCategory] = useState<any>(null)
  const [places, setPlaces] = useState([])

  useEffect(() => {
    getMainCategory()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMainCategory = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}main/category?admin=true`)
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
        } else {
          setMainCategories(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (category !== null) {
      setPlaces([])
      setCategoryId(category.id)
    }
  }, [category])

  const getPlaces = useCallback(() => {
    let category: any = null
    mainCategories.forEach((value: any) => {
      if (value.subs.length > 0) {
        value.subs.forEach((item: any) => {
          if (item.id === categoryId) {
            category = item
          }
        })
      } else if (value.id === categoryId) {
        category = value
      }
    })

    if (category !== null) {
      axios
        .get(`${process.env.REACT_APP_API_URL}main/category/${category.id}?admin=true`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            setPlaces(result)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [mainCategories, categoryId])

  useEffect(() => {
    if (categoryId > 0) {
      getPlaces()
    }
  }, [getPlaces, categoryId])

  return (
    <>
      <PageTitle breadcrumbs={[]}>큐레이션</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label
          className={'btn btn-primary'}
          onClick={() => {
            const title = prompt('제목을 입력하세요.')
            if (title !== undefined && title !== null && title.trim() !== '') {
              axios
                .post(`${process.env.REACT_APP_API_URL}main/category`, {title})
                .then(({data: {error, result}}) => {
                  console.log(result)
                  if (error !== '') {
                  } else {
                    window.location.href = `/marketing/curation/${result.id}`
                  }
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          }}
        >
          큐레이션 만들기
        </label>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div>
            {mainCategories.map((category: any, index: number) => {
              return (
                <div className='col-12' key={index.toString()}>
                  <div className='card card-custom card-stretch shadow mb-5'>
                    <div className='card-header'>
                      <h1 className='card-title'>
                        {category.id}. {category.title}
                      </h1>
                    </div>
                    <div className='card-body'>
                      <div>
                        <p style={{fontSize: 20}}>{`순서 : ${category.order}, 장소 : ${category.count}개, 노출 : ${category.show === 1 ? 'Y' : 'N'}`}</p>

                        <div className={'border'} style={{padding: 10}}>
                          <h2>메인 화면 이미지</h2>
                          <br />
                          <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <div style={{flex: 3}}>
                              <h3>Mobile</h3>
                              <div style={{position: 'relative', width: '100%', aspectRatio: `${77.75 / 132}`, marginTop: 20}}>
                                <img src={category.m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                              </div>
                            </div>

                            <div style={{flex: 7}}>
                              <h3>PC</h3>
                              <div style={{position: 'relative', width: '100%', aspectRatio: `${275 / 80}`, marginTop: 20}}>
                                <img src={category.w_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <br />
                        <br />

                        <div className={'border'} style={{padding: 10}}>
                          <h2>상세 화면 이미지</h2>
                          <br />

                          <h3>Mobile</h3>
                          <div
                            style={{
                              width: 375,
                              marginBottom: 30,
                              border: '1px solid #DDD',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: 44,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontWeight: 500,
                                  fontSize: 16,
                                  color: '#000',
                                }}
                              >
                                {category.title}
                              </p>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                paddingTop: 30,
                                paddingBottom: 20,
                                paddingRight: 20,
                                paddingLeft: 20,
                              }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontWeight: 700,
                                  fontSize: 24,
                                  color: '#1B1D1F',
                                }}
                              >
                                {category.comment}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  marginTop: 6,
                                  fontWeight: 400,
                                  fontSize: 16,
                                  color: '#454B50',
                                }}
                              >
                                {category.sub_comment}
                              </p>
                            </div>
                            {category.image_path !== '' && (
                              <img
                                src={ImagePath.get(category.image_path, '2000x2000', 'inside')}
                                style={{
                                  width: 375,
                                  height: 250,
                                  objectFit: 'cover',
                                }}
                                alt=''
                              />
                            )}
                          </div>
                          <h3>PC</h3>
                          <div
                            style={{
                              width: '100%',
                              height: 300,
                              position: 'relative',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              border: '1px solid #DDD',
                            }}
                          >
                            {category.image_path !== '' && (
                              <img
                                src={ImagePath.get(category.image_path, '2000x2000', 'inside')}
                                style={{
                                  position: 'absolute',
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  zIndex: 0,
                                }}
                                alt=''
                              />
                            )}
                            <div
                              style={{
                                position: 'absolute',
                                width: 500,
                                height: '100%',
                                backgroundColor: category.background_color,
                              }}
                            />
                            <div
                              style={{
                                paddingRight: 30,
                                paddingLeft: 30,
                                zIndex: 1,
                              }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontWeight: 700,
                                  fontSize: 32,
                                  color: '#FFF',
                                }}
                              >
                                {category.comment}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  marginTop: 6,
                                  fontWeight: 400,
                                  fontSize: 18,
                                  color: '#FFF',
                                }}
                              >
                                {category.sub_comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <label
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_WEB_URL}promotion/${category.id}`)
                        }}
                        className='btn btn-primary'
                      >
                        보기
                      </label>
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          window.location.href = `/marketing/curation/${category.id}`
                        }}
                        className='btn btn-primary'
                      >
                        수정
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
