import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import {TextSub2} from '../components/TextSub2'

export function Setting() {

  const [android, setAndroid] = useState<any>(null)
  const [androidLast, setAndroidLast] = useState<any>(null)
  const [ios, setIos] = useState<any>(null)
  const [iosLast, setIosLast] = useState<any>(null)

  useEffect(() => {

    getSetting()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSetting = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/setting`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setAndroid(result.android)
        setAndroidLast(result.android_last)
        setIos(result.ios)
        setIosLast(result.ios_last)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const updateSetting = (setting: any) => {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/setting/${setting.key}`, {
        value: setting.value,
      })
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          toast('변경되었습니다.')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>설정</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <h1 className='card-title'>
          앱 버전
        </h1>

        <div className='separator my-10' />
        <TextSub2 title={'아이폰 마지막 버전코드'} subTitle={''} />

        {
          iosLast !== null &&
          <div>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={iosLast.value}
              onChange={(e) => {
                const temp = Object.assign({}, iosLast)
                temp.value = e.target.value
                setIosLast(temp)
              }}
            />
          </div>
        }
        <div style={{
          padding: 10,
        }}>
          <label onClick={() => {
            updateSetting(iosLast)
          }} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'아이폰 강제 업데이트 버전코드'} subTitle={''} />

        {
          ios !== null &&
          <div>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={ios.value}
              onChange={(e) => {
                const temp = Object.assign({}, ios)
                temp.value = e.target.value
                setIos(temp)
              }}
            />
          </div>
        }

        <div style={{
          padding: 10,
        }}>
          <label onClick={() => {
            updateSetting(ios)
          }} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'안드로이드 마지막 버전코드'} subTitle={''} />

        {
          androidLast !== null &&
          <div>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={androidLast.value}
              onChange={(e) => {
                const temp = Object.assign({}, androidLast)
                temp.value = e.target.value
                setAndroidLast(temp)
              }}
            />
          </div>
        }

        <div style={{
          padding: 10,
        }}>
          <label onClick={() => {
            updateSetting(androidLast)
          }} className='btn btn-primary'>수정하기</label>
        </div>

        <div className='separator my-10' />
        <TextSub2 title={'안드로이드 강제 업데이트 버전코드'} subTitle={''} />

        {
          android !== null &&
          <div>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={android.value}
              onChange={(e) => {
                const temp = Object.assign({}, android)
                temp.value = e.target.value
                setAndroid(temp)
              }}
            />
          </div>
        }

        <div style={{
          padding: 10,
        }}>
          <label onClick={() => {
            updateSetting(android)
          }} className='btn btn-primary'>수정하기</label>
        </div>

      </div>

    </div>
  </>
}

