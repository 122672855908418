import React, {useEffect, useState} from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {isMobile} from 'react-device-detect'
import {BookingPlaceListItem} from "../../components/BookingPlaceListItem";
import ImagePath from '../../../commons/ImagePath'
import moment from 'moment/moment'

export function BookingPlaceNew() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [bookings, setBookings] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [bookingAdditionalCount, setBookingAdditionalCount] = useState(0)
  const [bookingApprovedCount, setBookingApprovedCount] = useState(0)
  const [bookingAutoApprovedCount, setBookingAutoApprovedCount] = useState(0)
  const [bookingCancelledCount, setBookingCancelledCount] = useState(0)
  const [bookingCompletedCount, setBookingCompletedCount] = useState(0)
  const [bookingCount, setBookingCount] = useState(0)
  const [bookingGtagCount, setBookingGtagCount] = useState(0)
  const [bookingHostPaidCount, setBookingHostPaidCount] = useState(0)
  const [bookingManualApprovedCount, setBookingManualApprovedCount] = useState(0)
  const [bookingPaidCount, setBookingPaidCount] = useState(0)
  const [bookingPendingCount, setBookingPendingCount] = useState(0)
  const [bookingProceedingCount, setBookingProceedingCount] = useState(0)
  const [bookingRefundCount, setBookingRefundCount] = useState(0)
  const [bookingTeamShareCount, setBookingTeamShareCount] = useState(0)
  const [bookingTeamProceedingCount, setBookingTeamProceedingCount] = useState(0)

  useEffect(() => {
    getBookings(status, 1)
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getBookings(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getBookings('', 1)
  }

  const handlePageChange = (page: number) => {
    getBookings(status, page)
  }

  const getBookingsCount = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/bookings/count`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setBookingAdditionalCount(result.booking_additional_count)
        setBookingApprovedCount(result.booking_approved_count)
        setBookingAutoApprovedCount(result.booking_auto_approved_count)
        setBookingCancelledCount(result.bookingCancelledCount)
        setBookingCompletedCount(result.bookingCompletedCount)
        //setBookingCount(result.booking_count)
        setBookingGtagCount(result.booking_gtag_count)
        setBookingHostPaidCount(result.booking_host_paid_count)
        setBookingManualApprovedCount(result.booking_manual_approved_count)
        setBookingPaidCount(result.booking_paid_count)
        setBookingPendingCount(result.booking_pending_count)
        setBookingProceedingCount(result.booking_proceeding_count)
        setBookingRefundCount(result.booking_refund_count)
        setBookingTeamShareCount(result.booking_team_share_count)
        setBookingTeamProceedingCount(result.booking_team_proceeding_count)

        //window.scrollTo(0, 0)
        // this.forceUpdate()
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getBookings = (status = '', page = 1) => {
    setBookings([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)
    axios.get(`${process.env.REACT_APP_API2_URL}am/bookings?page=${page}&status=${status}&search=${search}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setBookings(result.bookings.data)
        setCurrentPage(result.bookings.current_page)
        setPerPage(result.bookings.per_page)
        setTotal(result.bookings.total)
        setBookingCount(result.booking_count)

        /*
        setBookingAdditionalCount(result.booking_additional_count)
        setBookingApprovedCount(result.booking_approved_count)
        setBookingAutoApprovedCount(result.booking_auto_approved_count)
        setBookingCancelledCount(result.bookingCancelledCount)
        setBookingCompletedCount(result.bookingCompletedCount)
        setBookingGtagCount(result.booking_gtag_count)
        setBookingHostPaidCount(result.booking_host_paid_count)
        setBookingManualApprovedCount(result.booking_manual_approved_count)
        setBookingPaidCount(result.booking_paid_count)
        setBookingPendingCount(result.booking_pending_count)
        setBookingProceedingCount(result.booking_proceeding_count)
        setBookingRefundCount(result.booking_refund_count)
        setBookingTeamShareCount(result.booking_team_share_count)
        setBookingTeamProceedingCount(result.booking_team_proceeding_count)
         */
        if(page === 1 && status === '' && search === ''){
          getBookingsCount();
        }
        window.scrollTo(0, 0)
        // this.forceUpdate()
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 장소</PageTitle>

    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '200%', marginLeft: '-600px', marginBottom: '20px'}}>

      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <select className="form-control" name="type" style={{width: '300px', marginRight: '20px'}}
          onChange={(e) => {
            onTab(e.target.value)
          }}
          value={status}
        >
          <option value={''}>모두보기 ({bookingCount.toLocaleString('en')})</option>
          <option value={'pending'}>관리자 승인 대기 ({bookingPendingCount.toLocaleString('en')})</option>
          <option value={'auto_approved'}>관리자 자동 승인 ({bookingAutoApprovedCount.toLocaleString('en')})</option>
          <option value={'manual_approved'}>관리자 수동 승인 ({bookingManualApprovedCount.toLocaleString('en')})</option>
          <option value={'approved'}>호스트 승인대기 ({bookingApprovedCount.toLocaleString('en')})</option>
          <option value={'proceeding'}>결제대기 ({bookingProceedingCount.toLocaleString('en')})</option>
          <option value={'proceeding_1'}>결제대기 승인시간정렬 ({bookingProceedingCount.toLocaleString('en')})</option>
          <option value={'additional'}>추가결제대기 ({bookingAdditionalCount.toLocaleString('en')})</option>
          <option value={'paid'}>촬영대기 ({bookingPaidCount.toLocaleString('en')})</option>
          <option value={'completed'}>촬영 완료 ({bookingCompletedCount.toLocaleString('en')})</option>
          <option value={'host_paid'}>호스트 입금완료 ({bookingHostPaidCount.toLocaleString('en')})</option>
          <option value={'cancelled'}>취소 ({bookingCancelledCount.toLocaleString('en')})</option>
          <option value={'refund'}>환불 ({bookingRefundCount.toLocaleString('en')})</option>
          <option value={'gtag'}>결제 트래킹 ({bookingGtagCount.toLocaleString('en')})</option>
          <option value={'team_share'}>팀 공유 ({bookingTeamShareCount.toLocaleString('en')})</option>
          <option value={'team_proceeding'}>팀 결제 대기 ({bookingTeamProceedingCount.toLocaleString('en')})</option>
        </select>

        <input
          type="text"
          className="form-control"
          placeholder="예약 번호, 장소 번호, 장소명, 예약자 이름"
          value={search}
          style={{
            flex: 1,
            width: '600px',
            marginRight: '20px'
          }}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSearch()
            }
          }}
        />
        <label onClick={() => { onSearch() }} style={{ width: 100 }} className="btn btn-dark">검색</label>
      </div>
    </div>
    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '200%',
      marginLeft: '-600px',
    }}>
      <div className="tab-content w-100" id="myTabContent">
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
            <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
            <tr>
              <th scope="col" className="min-w-30px"></th>
              <th scope="col" className="min-w-100px">예약 번호</th>
              <th scope="col" className="min-w-100px">팀 공유</th>
              <th scope="col" className="min-w-150px">추가 청구(본예약번호)</th>
              <th scope="col" className="min-w-300px">예약 상태</th>
              <th scope="col" className="min-w-100px">예약 승인</th>
              <th scope="col" className="min-w-100px">환불 여부</th>
              <th scope="col" className="min-w-100px">미성년 여부</th>
              <th scope="col" className="min-w-100px">광고 여부</th>
              <th scope="col" className="min-w-100px">장소 번호</th>
              <th scope="col" className="min-w-100px">장소 이미지</th>
              <th scope="col" className="min-w-500px">장소 제목</th>
              <th scope="col" className="min-w-300px">카테 고리</th>
              <th scope="col" className="min-w-200px">결제 방식</th>
              <th scope="col" className="min-w-300px">관리자 장소 메모</th>
              <th scope="col" className="min-w-100px">결제 금액</th>
              <th scope="col" className="min-w-100px">쿠폰 사용</th>
              <th scope="col" className="min-w-100px">포인트 사용</th>
              <th scope="col" className="min-w-100px">실 결제 금액</th>
              <th scope="col" className="min-w-150px">예약 생성 시간</th>
              <th scope="col" className="min-w-150px">관리자 승인 시간</th>
              <th scope="col" className="min-w-150px">호스트 승인 시간</th>
              <th scope="col" className="min-w-400px">촬영 시간</th>
              <th scope="col" className="min-w-100px">호스트 번호</th>
              <th scope="col" className="min-w-100px">호스트 블랙</th>
              <th scope="col" className="min-w-100px">호스트 이미지</th>
              <th scope="col" className="min-w-100px">호스트 이름</th>
              <th scope="col" className="min-w-400px">호스트 닉네임</th>
              <th scope="col" className="min-w-100px">호스트 나이</th>
              <th scope="col" className="min-w-150px">호스트 전화번호</th>
              <th scope="col" className="min-w-300px">호스트 이메일</th>
              <th scope="col" className="min-w-200px">호스트 마지막 활동 시간</th>
              <th scope="col" className="min-w-100px">게스트 번호</th>
              <th scope="col" className="min-w-100px">게스트 블랙</th>
              <th scope="col" className="min-w-100px">게스트 이미지</th>
              <th scope="col" className="min-w-100px">게스트 이름</th>
              <th scope="col" className="min-w-400px">게스트 닉네임</th>
              <th scope="col" className="min-w-100px">게스트 나이</th>
              <th scope="col" className="min-w-150px">게스트 전화번호</th>
              <th scope="col" className="min-w-300px">게스트 이메일</th>
              <th scope="col" className="min-w-300px">게스트 추가정보</th>
              <th scope="col" className="min-w-200px">게스트 마지막 활동 시간</th>
              <th scope="col" className="min-w-200px">게스트 총 결제 금액</th>
              <th scope="col" className="min-w-200px">게스트 촬영 진행률</th>
            </tr>
            </thead>
            <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
            {
              loading ?
                <tr style={{height: 98}}>
                  <td colSpan={44}>
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                    </div>
                  </td>
                </tr>
                :
                bookings.map((booking: any, index: number) => {
                  //return <BookingPlaceItem key={booking.id.toString()} booking={booking} status={status} />
                  //return <BookingPlaceListItem key={booking.id.toString()} booking={booking} status={status} />
                  return (
                    <tr key={`${index}`} style={{height: 98}}>
                      <td></td>
                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                           onClick={() => {
                             window.open(`/booking/place/${booking.id}`, '_blank')
                           }}
                        >
                          {booking.id}
                        </p>
                      </td>
                      <td>{booking.team_booking_info !== undefined ? <b style={{color: 'red'}}>팀 공유</b> : '-'}</td>
                      <td>{booking.booking_id > 0 ? `추가청구(${booking.booking_id})` : '-'}</td>
                      <td>{booking.status_display}</td>
                      <td>{booking.auto_approved === 1 ? '자동 승인' : '수동 승인'}</td>
                      <td>{booking.is_refund === 1 ? '환불 완료' : '-'}</td>
                      <td>{booking.is_minor === 1 ? '미성년 예약' : '-'}</td>
                      <td>{booking.is_advertise === 1 ? '광고 중' : '-'}</td>
                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                           onClick={() => {
                             window.open(`/place/${booking.place_id}`, '_blank')
                           }}
                        >
                          {booking.place_id}
                        </p>
                      </td>
                      <td>
                        <div style={{width: 80, height: 80, position: 'relative'}}>
                          <img src={ImagePath.get(booking.place_image_url, '80x80')} alt="" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                          <div style={{
                            position: 'absolute',
                            width: 20,
                            height: 20,
                            borderTopLeftRadius: 8,
                            bottom: 0,
                            right: 0,
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                            <h6 style={{margin: 0}}>
                              {booking.place_grade}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>{booking.place_title}</td>
                      <td>{`${booking.place_category_main} > ${booking.place_category_main}`}</td>
                      <td>{booking.payments_type === 0 ? '승인 결제(나중결제)' : booking.payments_type === 1 ? '바로 결제' : '승인 결제(미리결제)'}</td>
                      <td>{booking.place_admin_comment}</td>
                      <td>
                        {status === 'refund' && booking.amount > 0 ? booking.amount.toLocaleString('en') : status === 'refund' && booking.toss_total_amount > 0 ? (booking.toss_total_amount.toLocaleString('en')) : booking.price_guest_vat.toLocaleString('en')}
                      </td>
                      <td>{booking.price_coupon > 0 ? booking.price_coupon.toLocaleString('en') : 0}</td>
                      <td>{booking.price_point > 0 ? booking.price_point.toLocaleString('en') : 0}</td>
                      <td>{(booking.price_guest_vat - booking.price_point - booking.price_coupon).toLocaleString('en')}</td>
                      <td>{moment(booking.created_at).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{booking.approved_time > 0 ? moment.unix(booking.approved_time).format('YYYY-MM-DD HH:mm') : 0}</td>
                      <td>{booking.proceeding_time > 0 ? moment.unix(booking.proceeding_time).format('YYYY-MM-DD HH:mm') : 0}</td>
                      <td>{`${moment(booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(booking.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} (${booking.hour} 시간)`}</td>
                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                           onClick={() => {
                             window.open(`/user/${booking.user_id}`, '_blank')
                           }}
                        >
                          {booking.user_id}
                        </p>
                      </td>
                      <td>{booking.user_black === 1 ? <p style={{color: 'red'}}>{booking.host_black_comment}</p> : '-'}</td>
                      <td>
                        <div style={{width: 80, height: 80, position: 'relative'}}>
                          <img src={booking.host_image_url} alt="" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                        </div>
                      </td>
                      <td>{booking.host_real_name}</td>
                      <td><LinesEllipsis text={`${booking.host_name}`} maxLine="1" ellipsis="........." trimRight basedOn="letters" /></td>
                      <td>{`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(booking.host_birth))).asYears())}세`}</td>
                      <td>{booking.host_phone}</td>
                      <td>{booking.host_email}</td>
                      <td>
                        {`${moment(booking.host_last_logined_at).format('MM-DD')}, ${Math.floor(
                          (moment.duration(moment().diff(moment(booking.host_last_logined_at, 'YYYY-MM-DD'))).asDays() * 100) / 100)} 일전`}
                      </td>
                      <td>
                        <p style={{minWidth: 40, fontWeight: 500, fontSize: 15, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                           onClick={() => {
                             window.open(`/user/${booking.guest_id}`, '_blank')
                           }}
                        >
                          {booking.guest_id}
                        </p>
                      </td>
                      <td>{booking.guest_black === 1 ? <p style={{color: 'red'}}>{booking.guest_black_comment}</p> : '-'}</td>
                      <td>
                        <div style={{width: 80, height: 80, position: 'relative'}}>
                          <img src={booking.guest_image_url} alt="게스트 이미지" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                          <div
                            style={{
                              position: 'absolute',
                              width: 20,
                              height: 20,
                              borderTopLeftRadius: 8,
                              bottom: 0,
                              right: 0,
                              backgroundColor: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <h4 style={{margin: 0}}>{booking.guest_grade}</h4>
                          </div>
                        </div>
                      </td>
                      <td>{booking.guest_real_name}</td>
                      <td><LinesEllipsis text={`${booking.guest_name}`} maxLine="1" ellipsis="........." trimRight basedOn="letters" /></td>
                      <td>{`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(booking.guest_birth))).asYears())}세`}</td>
                      <td>{booking.guest_phone}</td>
                      <td>{booking.guest_email}</td>
                      <td>{booking.guest_company}</td>
                      <td>
                        {`${moment(booking.guest_last_logined_at).format('MM-DD')}, ${Math.floor(
                          (moment.duration(moment().diff(moment(booking.guest_last_logined_at, 'YYYY-MM-DD'))).asDays() * 100) / 100)} 일전`}
                      </td>
                      <td>{booking.guest_statistic !== undefined && booking.guest_statistic !== null && (booking.guest_statistic.booking_guest_price.toLocaleString('en'))}</td>
                      <td>{booking.guest_statistic !== undefined && booking.guest_statistic !== null && (`${
                        booking.guest_statistic.guest_booking_completed_count > 0 && booking.guest_statistic.guest_proceeding_booking > 0
                          ? Math.floor((booking.guest_statistic.guest_booking_completed_count / booking.guest_statistic.guest_proceeding_booking) * 100)
                          : 0
                      }% (${booking.guest_statistic.guest_booking_completed_count} / ${booking.guest_statistic.guest_proceeding_booking})`)}</td>
                    </tr>
                  )
                })
            }
            </tbody>
          </table>
        </div>
      </div>
      {bookings.length > 0 && (
        <div style={{marginTop: 20}}>
          <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
        </div>
      )}
    </div>
  </>
}

