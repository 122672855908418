import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {isMobile} from 'react-device-detect'
import {PlaceItem} from '../components/PlaceItem'
import {toast} from 'react-toastify'

export function Place() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [placeCount, setPlaceCount] = useState(0)
  const [temporaryCount, setTemporaryCount] = useState(0)
  const [hideCount, setHideCount] = useState(0)
  const [unpublishCount, setUnpublishCount] = useState(0)
  const [superplaceCount, setSuperplaceCount] = useState(0)
  const [recommendedCount, setRecommendedCount] = useState(0)
  const [leaveCount, setLeaveCount] = useState(0)
  const [trashCount, setTrashCount] = useState(0)
  const [noindexCount, setNoindexCount] = useState(0)
  const [noCategoryCount, setNoCategoryCount] = useState(0)
  const [gradeSCount, setGradeSCount] = useState(0)
  const [gradeACount, setGradeACount] = useState(0)
  const [gradeBCount, setGradeBCount] = useState(0)
  const [gradeCCount, setGradeCCount] = useState(0)
  const [gradeNCount, setGradeNCount] = useState(0)
  const [gradeFCount, setGradeFCount] = useState(0)
  const [onlyCount, setOnlyCount] = useState(0)

  const [categoryMains, setCategoryMains] = useState<any>([])
  const [categorySubs, setCategorySubs] = useState<any>([])
  const [categoryMain, setCategoryMain] = useState(0)
  const [categorySub, setCategorySub] = useState(0)

  useEffect(() => {
    getPlaces(status, 1)
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getPlaces(tabStatus, 1, categoryMain, categorySub)
  }

  const onFilter = (categoryMain: number, categorySub: number) => {
    setCategoryMain(categoryMain)
    setCategorySub(categorySub)
    getPlaces(status, 1, categoryMain, categorySub)
  }

  const onSearch = () => {
    getPlaces(status, 1, categoryMain, categorySub)
  }

  const handlePageChange = (page: number) => {
    getPlaces(status, page, categoryMain, categorySub)
  }

  const getPlaces = (status = '', page = 1, categoryMain = 0, categorySub = 0) => {
    setItems([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)
    axios.get(`${process.env.REACT_APP_API2_URL}am/places?page=${page}&status=${status}&search=${search}&categoryMain=${categoryMain}&categorySub=${categorySub}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setItems(result.places.data)
        setCurrentPage(result.places.current_page)
        setPerPage(result.places.per_page)
        setTotal(result.places.total)

        setPlaceCount(result.place_count)
        setTemporaryCount(result.temporary_count)
        setHideCount(result.hide_count)
        setUnpublishCount(result.unpublish_count)
        setSuperplaceCount(result.superplace_count)
        setRecommendedCount(result.recommended_count)
        setLeaveCount(result.leave_count)
        setTrashCount(result.trash_count)
        setNoindexCount(result.noindex_count)
        setNoCategoryCount(result.no_category_count)
        setGradeSCount(result.grade_s_count)
        setGradeACount(result.grade_a_count)
        setGradeBCount(result.grade_b_count)
        setGradeCCount(result.grade_c_count)
        setGradeNCount(result.grade_n_count)
        setGradeFCount(result.grade_f_count)
        setOnlyCount(result.only_count)

        setCategoryMains(result.category_mains)
        setCategorySubs(result.category_subs)

        window.scrollTo(0, 0)
        // this.forceUpdate()
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
    //getCount()
  }

  /*
  const getCount = () => {
    axios.get(`${process.env.REACT_APP_API_URL}admin/place/count`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setPlaceCount(result.place_count)
        setTemporaryCount(result.temporary_count)
        setHideCount(result.hide_count)
        setUnpublishCount(result.unpublish_count)
        setSuperplaceCount(result.superplace_count)
        setRecommendedCount(result.recommended_count)
        setLeaveCount(result.leave_count)
        setTrashCount(result.trash_count)
        setNoindexCount(result.noindex_count)
        setNoCategoryCount(result.no_category_count)
        setGradeSCount(result.grade_s_count)
        setGradeACount(result.grade_a_count)
        setGradeBCount(result.grade_b_count)
        setGradeCCount(result.grade_c_count)
        setGradeNCount(result.grade_n_count)
        setGradeFCount(result.grade_f_count)
      }
    }).catch((error) => {
      console.log(error)
    })
  }
   */

  return <>
    <PageTitle breadcrumbs={[]}>장소 리스트</PageTitle>

    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>
        <select
          className="form-control"
          name="type"
          onChange={(e) => {
            onTab(e.target.value)
          }}
          value={status}
        >
          <option value={''}>모두보기 ({placeCount.toLocaleString('en')})</option>
          <option value={'temporary'}>임시저장 ({temporaryCount.toLocaleString('en')})</option>
          <option value={'hide'}>숨김 ({hideCount.toLocaleString('en')})</option>
          <option value={'unpublish'}>게시 취소 ({unpublishCount.toLocaleString('en')})</option>
          <option value={'registered'}>등록순 ({placeCount.toLocaleString('en')})</option>
          <option value={'superplace'}>슈퍼플레이스 ({superplaceCount.toLocaleString('en')})</option>
          <option value={'recommended'}>추천됨 ({recommendedCount.toLocaleString('en')})</option>
          <option value={'leave'}>탈퇴함 ({leaveCount.toLocaleString('en')})</option>
          <option value={'trash'}>삭제함 ({trashCount.toLocaleString('en')})</option>
          <option value={'noindex'}>구글 거부 ({noindexCount.toLocaleString('en')})</option>
          <option value={'no_category'}>카테고리 미지정 ({noCategoryCount.toLocaleString('en')})</option>
          <option value={'pay'}>매출순 ({placeCount.toLocaleString('en')})</option>
          <option value={'grade_s'}>S ({gradeSCount.toLocaleString('en')})</option>
          <option value={'grade_a'}>A ({gradeACount.toLocaleString('en')})</option>
          <option value={'grade_b'}>B ({gradeBCount.toLocaleString('en')})</option>
          <option value={'grade_c'}>C ({gradeCCount.toLocaleString('en')})</option>
          <option value={'grade_n'}>N ({gradeNCount.toLocaleString('en')})</option>
          <option value={'grade_f'}>F ({gradeFCount.toLocaleString('en')})</option>
          <option value={'only'}>아워플레이스 Only ({onlyCount.toLocaleString('en')})</option>
        </select>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>

          <select
            className="form-control w-50"
            name="type"
            onChange={(e) => {
              onFilter(Number(e.target.value), 0)
            }}
            value={categoryMain}
          >
            <option value={0}>메인카테고리</option>
            {
              categoryMains.map((item: any, index: number) => {
                return <option value={item.id} key={index}>{item.title}({item.count})</option>
              })
            }
          </select>

          <select
            className="form-control w-50"
            name="type"
            onChange={(e) => {
              onFilter(categoryMain, Number(e.target.value))
            }}
            value={categorySub}
          >
            <option value={0}>서브카테고리</option>
            {
              categorySubs.map((item: any, index: number) => {
                return <option value={item.id} key={index}>{item.title}({item.count})</option>
              })
            }
          </select>
        </div>

        <div style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type="text"
            className="form-control"
            placeholder="장소 검색을 해봅시다. (장소번호, 장소명, 주소, 카테고리, 태그)"
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{
            width: 100,
          }} className="btn btn-dark">검색</label>
        </div>
      </div>

      {
        loading ?
          <div style={{
            width: '100%',
            height: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <span className="indicator-progress" style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
          </div>
          :
          <div style={{
            width: '100%',
          }}>
            {
              items.map((item: any, index: number) => {
                return <PlaceItem key={`${index}`} place={item} isButton={true} />
              })
            }
          </div>
      }

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />
    </div>
  </>
}

