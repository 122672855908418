import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'

export function PlaceCategory() {

  const [items, setItems] = useState<any>([])

  useEffect(() => {

    getCategory()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCategory = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/category`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>장소 카테고리</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='row g-5'>
          {items.map((item: any, index: number) => {
            return (
              <div className='col-lg-4' key={index.toString()}>
                <div className='card card-custom card-stretch shadow mb-5'>
                  <div className="card-header">
                    <h1 className="card-title">
                      {item.title}({item.count.toLocaleString('en')})
                      <br />
                      {item.min.toLocaleString('en')}{' '}
                      {item.avg.toLocaleString('en')}{' '}
                      {item.max.toLocaleString('en')}
                    </h1>
                  </div>
                  <div className="card-body">
                    {item.subs.map((sub: any, subIndex: number) => {
                      return (
                        <div key={`sub_${subIndex}`}>
                          <h3>
                            {sub.title}({sub.count.toLocaleString('en')})
                          </h3>
                          <h5>
                            {sub.min.toLocaleString('en')}{' '}
                            {sub.avg.toLocaleString('en')}{' '}
                            {sub.max.toLocaleString('en')}
                          </h5>
                          <br />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </>
}

