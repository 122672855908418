import React from 'react'
import {isMobile} from 'react-device-detect'
import Highlighter from 'react-highlight-words'

type Props = {
  title: string
  subTitle: string
  keywords: []
}

const TextSub3: React.FC<Props> = ({title, subTitle, keywords}) => {
  return <div style={{
    marginTop: 16,
    width: '100%',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'flex-start',
  }}>
    <p style={{
      minWidth: 156,
      fontWeight: 500,
      fontSize: 15,
      color: '#9EA4AA',
      whiteSpace: 'pre-wrap',
    }}>
      {title}
    </p>
    <p style={{
      fontWeight: 500,
      fontSize: 15,
      color: '#1B1D1F',
      whiteSpace: 'pre-wrap',
    }}>
      <Highlighter
        searchWords={keywords}
        autoEscape
        highlightStyle={{
          backgroundColor: 'red',
          color: 'white',
        }}
        textToHighlight={subTitle ?? ''}
      />
    </p>

  </div>
}

export {TextSub3}
