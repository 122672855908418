import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'

export function AdvertiseBToBHostBannerEditor() {
  const {id} = useParams<{id: string}>()
  const [loading, setLoading] = useState(false)
  const [bToBBannerAdv, setBToBBannerAdv] = useState<any>('')

  useEffect(() => {
    getBToBBanner()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getBToBBanner = () => {
    setBToBBannerAdv('')
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/H/btob/banner/${id}`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          console.log(result.advPrice)
          setBToBBannerAdv(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const modify = () => {
    if (bToBBannerAdv.advStart === '') {
      toast.error('광고 시작일을 확인해주세요.')
      return
    }

    if (bToBBannerAdv.advEnd === '') {
      toast.error('광고 종료일을 확인해주세요.')
      return
    }

    if (bToBBannerAdv.advPrice === '') {
      toast.error('금액을 확인해주세요.')
      return
    }

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/adv/btob/banner/${id}/modify`, {
        advStart: bToBBannerAdv.advStart,
        advEnd: bToBBannerAdv.advEnd,
        advPrice: bToBBannerAdv.advPrice,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/advertise/btob/banner/host/${id}`
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>메인 배너 광고</PageTitle>
      <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
        <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
            <div style={{marginTop: 10, paddingBottom: 10}}>
              <div>
                <table style={{fontSize: '15px', width: '100%', lineHeight: 2}}>
                  <colgroup>
                    <col style={{width: '14%'}} />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr style={{fontSize: '15px'}}>
                      <td>사용자 번호</td>
                      <td>
                        <input type='text' className='form-control' placeholder='호스트 ID를 입력하세요.' maxLength={15} value={bToBBannerAdv.userId} disabled={true} />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>광고 시작일</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 20990101'
                          maxLength={8}
                          value={bToBBannerAdv.advStart}
                          onChange={(e) => {
                            const temp = Object.assign({}, bToBBannerAdv)
                            temp.advStart = e.target.value
                            setBToBBannerAdv(temp)
                          }}
                          disabled={bToBBannerAdv.advStatus !== 'W'}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>광고 종료일</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='ex) 20990101'
                          maxLength={8}
                          value={bToBBannerAdv.advEnd}
                          onChange={(e) => {
                            const temp = Object.assign({}, bToBBannerAdv)
                            temp.advEnd = e.target.value
                            setBToBBannerAdv(temp)
                          }}
                          disabled={bToBBannerAdv.advStatus !== 'W' && bToBBannerAdv.advStatus !== 'I'}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>금액</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          maxLength={10}
                          value={bToBBannerAdv.advPrice}
                          onChange={(e) => {
                            const temp = Object.assign({}, bToBBannerAdv)
                            temp.advPrice = e.target.value
                            setBToBBannerAdv(temp)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <label
                onClick={() => {
                  modify()
                }}
                style={{marginLeft: 10}}
                className='btn btn-sm btn-primary'
              >
                수정하기
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
