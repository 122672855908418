import axios from 'axios'

export const LOGIN_URL = process.env.REACT_APP_API2_URL + 'user/login'
export const GET_USER_BY_ACCESSTOKEN_URL = process.env.REACT_APP_API2_URL + 'user/check'
export const GET_USER_BY_FIREBASE_TOKEN = process.env.REACT_APP_API2_URL + 'firebase/custom/token'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

export function getUserByToken() {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getUserByFirebaseToken() {
  return axios.get(GET_USER_BY_FIREBASE_TOKEN)
}
