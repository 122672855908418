import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {toast} from 'react-toastify'

export function MediaVideoPlace() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getVideos()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getVideos(page)
  }

  const getVideos = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/video/place?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          result.data.forEach((video: any) => {
            video.image_url = ''
            if (video.image_maxres !== '') video.image_url = video.image_maxres
            else if (video.image_standard !== '')
              video.image_url = video.image_standard
            else if (video.image_high !== '') video.image_url = video.image_high
            else if (video.image_medium !== '')
              video.image_url = video.image_medium
            else video.image_url = video.image_default
            video.profile_image_url = ''
            if (video.profile_image_high !== '')
              video.profile_image_url = video.profile_image_high
            else if (video.profile_image_medium !== '')
              video.profile_image_url = video.profile_image_medium
            else video.profile_image_url = video.profile_image_default
          })
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const update = (video: any) => {
    axios.put(`${process.env.REACT_APP_API2_URL}video/${video.id}/info/update`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        toast('업데이트 요청하였습니다.')
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>장소 영상</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='row g-5'>
          {items.map((item: any, index: number) => {
            return (
              <div className='col-lg-3' style={{
                position: 'relative',
              }} key={index.toString()}>
                <img
                  src={item.image_url}
                  style={{width: '100%', height: 280, objectFit: 'cover'}}
                  alt=''
                />
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                }}>
                  <div className={'row'}>
                    <div style={{padding: 4, width: 'fit-content'}}>
                      <label onClick={() => {
                        window.open(`${process.env.REACT_APP_WEB_URL}place/${item.place_id}`)
                      }} className='btn btn-sm btn-secondary'>장소</label>
                    </div>
                    <div style={{padding: 4, width: 'fit-content'}}>
                      <label onClick={() => {
                        window.open(`https://www.youtube.com/watch?v=${item.url}`)
                      }} className='btn btn-sm btn-secondary'>영상</label>
                    </div>
                    <div style={{padding: 4, width: 'fit-content'}}>
                      <label onClick={() => {
                        update(item)
                      }} className='btn btn-sm btn-secondary'>업데이트</label>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
  </>
}

