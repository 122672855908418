import React from 'react'
import axios from 'axios'
import { Table, Tab, Tabs } from 'react-bootstrap'
import {PageTitle} from '../../../../../_metronic/layout/core'

export default class StatisticsProjectType extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      genres: [],
      genre_keys: [],
      data: [],
    }
    this.getBookingDay()
  }

  getBookingDay() {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/statistics/project/type`)
      .then(({ data: { error, result } }) => {
        console.log(error)
        console.log(result)
        this.setState({
          data: result,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <>
        <PageTitle breadcrumbs={[]}>프로젝트 분류</PageTitle>

        <div className="row">
          <div
            className="col-12"
            style={{ padding: 20, backgroundColor: '#FFF', borderRadius: 10 }}>
            {this.state.data.length > 0 && (
              <Tabs
                defaultActiveKey="게스트"
                transition={false}
                id="noanim-tab-example">
                <Tab eventKey="게스트" title="게스트" style={{ padding: 20 }}>
                  <Tabs
                    defaultActiveKey={this.state.data[0].date}
                    transition={false}
                    id="noanim-tab-example">
                    {this.state.data.map((data) => {
                      return (
                        <Tab
                          eventKey={data.date}
                          title={data.date === '0' ? '전체' : data.date}
                          style={{ padding: 20 }}>
                          <h3>{data.date === '0' ? '전체' : data.date}</h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Count</th>
                                <th>Percent</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.value.guest.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.count}</td>
                                    <td>{item.percent}%</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Tab>
                      )
                    })}
                  </Tabs>
                </Tab>
                <Tab eventKey="촬영" title="촬영" style={{ padding: 20 }}>
                  <Tabs
                    defaultActiveKey={this.state.data[0].date}
                    transition={false}
                    id="noanim-tab-example">
                    {this.state.data.map((data) => {
                      return (
                        <Tab
                          eventKey={data.date}
                          title={data.date === '0' ? '전체' : data.date}
                          style={{ padding: 20 }}>
                          <h3>{data.date === '0' ? '전체' : data.date}</h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Count</th>
                                <th>Percent</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.value.shoot.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.count}</td>
                                    <td>{item.percent}%</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Tab>
                      )
                    })}
                  </Tabs>
                </Tab>
                <Tab eventKey="장르" title="장르" style={{ padding: 20 }}>
                  <Tabs
                    defaultActiveKey={this.state.data[0].date}
                    transition={false}
                    id="noanim-tab-example">
                    {this.state.data.map((data) => {
                      return (
                        <Tab
                          eventKey={data.date}
                          title={data.date === '0' ? '전체' : data.date}
                          style={{ padding: 20 }}>
                          <h3>{data.date === '0' ? '전체' : data.date}</h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Count</th>
                                <th>Percent</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.value.genre.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.count}</td>
                                    <td>{item.percent}%</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Tab>
                      )
                    })}
                  </Tabs>
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </>
    )
  }
}
