import React from 'react'
import {TextSub} from './TextSub'

type Props = {
  booking: any
  status?: string
  detailButton?: boolean
}

const BookingRefundItem: React.FC<Props> = ({booking, status = '', detailButton = true}) => {

  const item = booking

  return <div style={{
    marginTop: 16,
    padding: 20,
    width: '100%',
    borderRadius: 10,
    border: '1px solid #DDD',
  }}>
    <div>
      <h2>
        [
        {item.is_refund === 0 ? (item.refund_id > 0 ? '환불 대기' : '미입력') : ''}
        {item.is_refund === 1 ? '환불 완료' : ''}
        ]
      </h2>
      <br />
      <label onClick={() => {
        window.open(`/booking/place/${item.id}`)
      }} className='btn btn-primary'>예약 번호 : {item.id}</label>
    </div>

    <TextSub title={'회원이름'} subTitle={item.user_real_name} />
    <TextSub title={'금액'} subTitle={item.kcp_amount === 0 ? item.price_guest_vat.toLocaleString('en') : item.kcp_amount.toLocaleString('en') } marginTop={-10} />
    <TextSub title={'예약취소일'} subTitle={item.cancelled_time} marginTop={-10} />

    {
      item.refund_created_at !== null &&
      <>
        <TextSub title={'예금주'} subTitle={item.refund_bank_user_name} marginTop={-3} />
        <TextSub title={'은행명'} subTitle={item.refund_bank_name} marginTop={-10} />
        <TextSub title={'계좌번호'} subTitle={item.refund_bank_account} marginTop={-10} />
        <TextSub title={'입력일'} subTitle={item.refund_created_at} marginTop={-10} />
        <TextSub title={'업데이트일'} subTitle={item.refund_updated_at} marginTop={-10} />
        <TextSub title={'환불 완료일'} subTitle={item.refund_at} marginTop={-10} />
      </>
    }

  </div>
}

export {BookingRefundItem}
