import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {PageTitle} from '../../../_metronic/layout/core'
import {Widget001, Widget002} from '../components/Widget'
import {isMobile} from 'react-device-detect'

export function Dashboard() {

  const [data, setData] = useState<any>(null)
  const [revenue, setRevenue] = useState<any>(null)
  const [point, setPoint] = useState<any>(null)
  const [qna, setQna] = useState<any>(null)
  const [booking, setBooking] = useState<any>(null)
  const [booking2, setBooking2] = useState<any>(null)
  const [feedback, setFeedback] = useState<any>(null)
  const [feedbackImage, setFeedbackImage] = useState<any>(null)
  const [video, setVideo] = useState<any>(null)
  const [user, setUser] = useState<any>(null)
  const [place, setPlace] = useState<any>(null)

  useEffect(() => {
    getBooking()
    getPoint()
    getQnA()
    getUser()
    getPlace()
    getDashboard()
    getFeedback()
    getFeedbackImage()
    getVideo()
    getRevenue()
    getBooking2()
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getDashboard = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/dashboard`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setData(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getRevenue = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/revenue/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setRevenue(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getBooking = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setBooking(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getBooking2 = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/statistics2`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setBooking2(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getPoint = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/point/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setPoint(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getQnA = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/qna/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setQna(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getFeedback = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/feedback/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setFeedback(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getFeedbackImage = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/feedback/image/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setFeedbackImage(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getVideo = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/video/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setVideo(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getUser = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/user/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setUser(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getPlace = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/place/statistics`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setPlace(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div style={{
        padding: isMobile ? 0 : 20,
        borderRadius: 20,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>

        <div style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          width: '100%',
        }}>

          <div className='row'>
            <div
              className='row col-xl-12'
              style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              {booking !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>장소 예약</h3>
                  <div className='row'>
                    <Widget001 title={'관리자 승인대기'} count={booking.booking_count_pending} onClick={() => window.location.href = '/booking/place'} />
                    <Widget001 title={'호스트 승인대기'} count={booking.booking_count_approved} onClick={() => window.location.href = '/booking/place'} />
                    <Widget001 title={'결제 대기'} count={booking.booking_count_proceeding} onClick={() => window.location.href = '/booking/place'} />
                    <Widget001 title={'추가 결제대기'} count={booking.booking_count_additional} onClick={() => window.location.href = '/booking/place'} />
                    <Widget001 title={'결제 완료'} count={booking.booking_count_paid} onClick={() => window.location.href = '/booking/place'} />
                    <Widget001 title={'호스트 지불 대기'} count={booking.booking_count_completed} onClick={() => window.location.href = '/booking/place'} />
                    <Widget001 title={'환불 요청'} count={booking.booking_count_refund} onClick={() => window.location.href = '/booking/refund'} />
                  </div>
                </div>
              )}

              {point !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>포인트</h3>
                  <div className='row'>
                    <Widget001 title={'출금 요청'} count={point.point_withdraw} />
                    <Widget001 title={'사용'} count={point.point_u} />
                    <Widget001 title={'예약 적립'} count={point.point_sb} />
                    <Widget001 title={'추천 적립'} count={point.point_i} />
                    <Widget001 title={'입금 적립'} count={point.point_b} />
                    <Widget001 title={'임의 적립'} count={point.point_h} />
                    <Widget001 title={'마케팅 충전'} count={point.point_m} />
                  </div>
                </div>
              )}

              {qna !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>문의 현황</h3>
                  <div className='row'>
                    <Widget001 title={'1:1 고객 문의'} count={qna.qna_direct_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'무료사진신청'} count={qna.qna_shoot_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'촬영장소요청'} count={qna.qna_place_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'장소신고'} count={qna.qna_report_place_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'사용자신고'} count={qna.qna_report_user_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'후기신고'} count={qna.qna_report_feedback_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'포트폴리오'} count={qna.qna_report_portfolio_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'장소피드'} count={qna.qna_report_place_feed_count} onClick={() => window.location.href = '/qna'} />
                    <Widget001 title={'장소제보하기'} count={qna.qna_place_tip_off_count} onClick={() => window.location.href = '/qna'} />
                  </div>
                </div>
              )}

              {booking2 !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>예약 건수 현황 {Number(booking2.booking_count_all).toLocaleString('en')}</h3>
                  <div className='row'>
                    <Widget001 title={booking2.bookings_next_month_date} count={booking2.bookings_next_month_count} />
                    <Widget001 title={booking2.bookings_date} count={booking2.bookings_count} />
                    <Widget001 title={booking2.bookings_pre_month_date} count={booking2.bookings_pre_month_count} />
                  </div>
                  <h3>촬영 시간 현황 {Number(booking2.booking_time_all).toLocaleString('en')}</h3>
                  <div className='row'>
                    <Widget001 title={booking2.bookings_time_next_month_date} count={booking2.bookings_time_next_month_count} />
                    <Widget001 title={booking2.bookings_time_date} count={booking2.bookings_time_count} />
                    <Widget001 title={booking2.bookings_time_pre_month_date} count={booking2.bookings_time_pre_month_count} />
                  </div>
                </div>
              )}

              {revenue !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>공간 매출 {Number(revenue.payment_all).toLocaleString('en')}</h3>
                  <div className='row'>
                    <Widget002 title={revenue.guest_next_month_date} text={`${Number(revenue.guest_next_month_count).toLocaleString('en')}${'\n'}(${Number(revenue.guest_next_month_pre_count).toLocaleString('en')})`} />
                    <Widget002 title={revenue.guest_date} text={`${Number(revenue.guest_count).toLocaleString('en')}${'\n'}(${Number(revenue.guest_pre_count).toLocaleString('en')})`} />
                    <Widget001 title={revenue.guest_pre_month_date} count={revenue.guest_pre_month_count} />
                  </div>
                  <h3>공간 수수료 {Number(revenue.fee_all).toLocaleString('en')}</h3>
                  <div className='row'>
                    <Widget001 title={revenue.fee_next_month_date} count={revenue.fee_next_month_count} />
                    <Widget001 title={revenue.fee_date} count={revenue.fee_count} />
                    <Widget001 title={revenue.fee_pre_month_date} count={revenue.fee_pre_month_count} />
                  </div>
                </div>
              )}

              {place !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>장소 ({place.total.toLocaleString("en")})</h3>
                  <div className='row'>
                    <Widget001 title={'게시 수'} count={place.count} onClick={() => window.location.href = '/place'} />
                    <Widget001 title={'숨김 수'} count={place.hide} onClick={() => window.location.href = '/place'} />
                    <Widget001 title={'게시 중지 수'} count={place.unpublish} onClick={() => window.location.href = '/place'} />
                    <Widget001 title={'삭제 수'} count={place.trash} onClick={() => window.location.href = '/place'} />
                    <Widget001 title={'탈퇴 수'} count={place.leave} onClick={() => window.location.href = '/place'} />
                    <Widget001 title={'임시저장 수'} count={place.temporary} onClick={() => window.location.href = '/place'} />
                  </div>
                </div>
              )}

              {user !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>사용자</h3>
                  <div className='row'>
                    <Widget001 title={'사용자 수'} count={user.count} onClick={() => window.location.href = '/user/list'} />
                    <Widget001 title={'게스트 수'} count={user.guest} onClick={() => window.location.href = '/user/list'} />
                    <Widget001 title={'호스트 수'} count={user.host} onClick={() => window.location.href = '/user/list'} />
                  </div>
                </div>
              )}

              {video !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>Watch</h3>
                  <div className='row'>
                    <Widget001 title={'영상 수'} count={video.count} />
                  </div>
                </div>
              )}
              {feedback !== null && feedbackImage !== null && (
                <div className='gutter-b col-xl-12'>
                  <h3>후기</h3>
                  <div className='row'>
                    <Widget001 title={'후기 수'} count={feedback.count} />
                    <Widget001 title={'후기 이미지 수'} count={feedbackImage.count} />
                    <Widget001 title={'후기 숨긴 이미지 수'} count={feedbackImage.hide} />
                  </div>
                </div>
              )}
              {data !== null && (
                <div className='gutter-b col-xl-12 '>
                  <h3>050 현황</h3>
                  <div className='row'>
                    <Widget002 title={'050 호스트 할당'} text={`${Number(data.safety_number_h_use).toLocaleString('en')}/${Number(data.safety_number_h).toLocaleString('en')}`} />
                    <Widget002 title={'050 게스트 할당'} text={`${Number(data.safety_number_g_use).toLocaleString('en')}/${Number(data.safety_number_g).toLocaleString('en')}`} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
