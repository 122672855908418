import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../components/TextSub'
import {Button} from 'react-bootstrap'
import {toast} from 'react-toastify'

export function PlaceBooking() {
  const {place_id} = useParams<{place_id: string}>()
  const [place, setPlace] = useState<any>(null)

  const [guestId, setGuestId] = useState(0)
  const [priceHost, setPriceHost] = useState(0)
  const [hostFee, setHostFee] = useState('')
  const [priceGuestVat, setPriceGuestVat] = useState(0)
  const [guestFee, setGuestFee] = useState('')

  const [bookingStart, setBookingStart] = useState('')
  const [bookingEnd, setBookingEnd] = useState('')
  const [bookingPeople, setBookingPeople] = useState(0)
  const [bookingParkingCount, setBookingParkingCount] = useState(0)
  const [bookingContent, setBookingContent] = useState('')

  const [genre, setGenre] = useState<any>(null)
  const [genreIndex, setGenreIndex] = useState(0)
  const [genreName, setGenreName] = useState('')
  const [genreTagName, setGenreTagName] = useState('')

  const [isCoupon, setIsCoupon] = useState(1)
  const [isPoint, setIsPoint] = useState(1)

  useEffect(() => {
    getPlace()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPlace = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlace(result)
          setGuestId(0)
          setPriceHost(0)
          setPriceGuestVat(0)
          setHostFee(result.host_fee)
          setGuestFee(result.guest_fee)

          getGenre()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getGenre = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}booking/genre`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setGenre(result)

          setGenreIndex(0)
          setGenreName(result[0].genre_name)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const store = () => {
    console.log(place_id)
    console.log(guestId)
    console.log(priceHost)
    console.log(hostFee)
    console.log(priceGuestVat)
    console.log(guestFee)
    console.log(bookingStart)
    console.log(bookingEnd)
    console.log(genreName)
    console.log(genreTagName)
    console.log(bookingPeople)
    console.log(bookingParkingCount)
    console.log(bookingContent)
    console.log(isCoupon)
    console.log(isPoint)

    if (guestId < 1) {
      toast.error('게스트 정보를 확인해 주세요.')
      return
    }

    if (priceHost < 1) {
      toast.error('호스트 정산 금액을 확인해 주세요.')
      return
    }

    if (priceGuestVat < 1) {
      toast.error('게스트 결제금액을 확인해 주세요.')
      return
    }

    if (bookingStart.length !== 14) {
      toast.error('시작 시간을 확인해 주세요.')
      return
    }

    if (bookingEnd.length !== 14) {
      toast.error('종료 시간을 확인해 주세요.')
      return
    }

    if (genreName === '') {
      toast.error('촬영 종류(장르)을 확인해 주세요.')
      return
    }

    if (genreTagName === '') {
      toast.error('촬영 종류(태그)을 확인해 주세요.')
      return
    }

    if (bookingPeople < 1) {
      toast.error('촬영 인원을 확인해 주세요.')
      return
    }

    if (bookingContent === '') {
      toast.error('촬영 내용을 확인해 주세요.')
      return
    }

    if (window.confirm('예약 생성 하시겠습니까?')) {
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/place/booking`, {
          booking_place: place_id,
          booking_guest_id: guestId,
          booking_price_guest_vat: priceGuestVat,
          booking_guest_fee: guestFee,
          booking_price_host: priceHost,
          booking_host_fee: hostFee,
          booking_start: bookingStart,
          booking_end: bookingEnd,
          booking_people: bookingPeople,
          booking_parking_count: bookingParkingCount,
          booking_content: bookingContent,
          booking_genre: genreName,
          booking_genre_tag: genreTagName,
          booking_is_coupon: isCoupon,
          booking_is_point: isPoint,
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.href = `/booking/place/${result}`
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>예약 수동 생성하기</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='col-12'>
            <div className='card card-custom card-stretch shadow mb-5'>
              <div className='card-header'>{place !== null && <h1 className='card-title'>장소 번호 : {place.id}</h1>}</div>
              <div className='card-body'>
                {place !== null && (
                  <>
                    <TextSub title={'장소 번호'} subTitle={place.id} />
                    <TextSub title={'장소 제목'} subTitle={place.title} />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'게스트 정보'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={guestId}
                        onChange={(e) => {
                          setGuestId(Number(e.target.value))
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        {1 === place.is_studio ? (
                          <TextSub title={'호스트 정산 금액\n(수수료 미적용, 부가세 포함)'} subTitle={''} />
                        ) : (
                          <TextSub title={'호스트 정산 금액\n(수수료 미적용)'} subTitle={''} />
                        )}
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={priceHost}
                        onChange={(e) => {
                          setPriceHost(Number(e.target.value))
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'호스트 수수료'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={hostFee}
                        onChange={(e) => {
                          setHostFee(e.target.value)
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'게스트 결제 금액\n부가세 포함'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={priceGuestVat}
                        onChange={(e) => {
                          setPriceGuestVat(Number(e.target.value))
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'게스트 수수료'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={guestFee}
                        onChange={(e) => {
                          setGuestFee(e.target.value)
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'시작시간'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={'예시) 20221030000000 14자리 입력'}
                        value={bookingStart}
                        onChange={(e) => {
                          setBookingStart(e.target.value)
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'종료시간'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={'예시) 20221030010000 14자리 입력'}
                        value={bookingEnd}
                        onChange={(e) => {
                          setBookingEnd(e.target.value)
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'촬영 종류(장르)'} subTitle={''} />
                      </div>
                      <select
                        className='form-control'
                        name='type'
                        onChange={(e) => {
                          const temp = e.target.value
                          setGenreIndex(Number(temp))
                          setGenreName(genre[temp].genre_name)
                        }}
                        value={genreIndex}
                      >
                        {genre != null &&
                          genre.map((temp: any, index: number) => {
                            return (
                              <option value={index} key={`${index}`}>
                                {temp.genre_name}
                              </option>
                            )
                          })}
                      </select>
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'촬영 종류(태그)'} subTitle={''} />
                      </div>
                      <select
                        className='form-control'
                        name='type'
                        onChange={(e) => {
                          const temp = e.target.value
                          setGenreTagName(temp)
                        }}
                        value={genreTagName}
                      >
                        <option value={''}></option>
                        {genre != null &&
                          genre[genreIndex] != null &&
                          genre[genreIndex].recommend_low_tags.map((temp: any, index: number) => {
                            return (
                              <option value={temp.tag_name} key={`${index}`}>
                                {temp.tag_name}
                              </option>
                            )
                          })}
                        {genre != null &&
                          genre[genreIndex] != null &&
                          genre[genreIndex].recommend_high_tags.map((temp: any, index: number) => {
                            return (
                              <option value={temp.tag_name} key={`${index}`}>
                                {temp.tag_name}
                              </option>
                            )
                          })}
                      </select>
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'촬영 인원'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={bookingPeople}
                        onChange={(e) => {
                          setBookingPeople(Number(e.target.value))
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'차량 대수'} subTitle={''} />
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={''}
                        value={bookingParkingCount}
                        onChange={(e) => {
                          setBookingParkingCount(Number(e.target.value))
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'촬영 내용(제품)'} subTitle={''} />
                      </div>
                      <textarea
                        className='form-control'
                        rows={10}
                        placeholder='촬영 내용을 입력해주세요.'
                        value={bookingContent}
                        onChange={(e) => {
                          setBookingContent(e.target.value)
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'결제 시 쿠폰 사용 가능'} subTitle={''} />
                      </div>
                      <div className='switch switch-icon'>
                        <div className='form-check form-check-custom form-check-solid form-switch mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={isCoupon === 1}
                            value={isCoupon}
                            onChange={(e) => {
                              setIsCoupon(Number(e.target.checked ? 1 : 0))
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'결제 시 포인트 사용 가능'} subTitle={''} />
                      </div>
                      <div className='switch switch-icon'>
                        <div className='form-check form-check-custom form-check-solid form-switch mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={isPoint === 1}
                            value={isCoupon}
                            onChange={(e) => {
                              setIsPoint(Number(e.target.checked ? 1 : 0))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  variant='danger'
                  onClick={() => {
                    store()
                  }}
                >
                  예약 생성 하기
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
