import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import axios from 'axios'
import {PageTitle} from '../../../../../../_metronic/layout/core'

export default class StatisticsBookingPaymentUser extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      birth: [],
      data: [],
      data1: [],
      data2: [],
    }

    for (let i = 1936; i < 2005; i++) {
      this.state.birth.push(i)
    }

    this.getBookingDay()
  }

  getBookingDay() {
    axios
      .get(
        `${process.env.REACT_APP_API2_URL}am/statistics/booking/payment/user`,
      )
      .then(({ data: { error, result } }) => {
        console.log(error)
        console.log(result)
        this.setState({
          data: result.all,
          data1: result.gender_0,
          data2: result.gender_1,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <>
        <PageTitle breadcrumbs={[]}>결제한 사용자 나이별</PageTitle>

        <div className="row">
          <div
            className="col-12"
            style={{
              padding: 20,
              backgroundColor: '#FFF',
              borderRadius: 10,
              height: 1000,
            }}>
            <p>모든 인원</p>
            <ResponsiveBar
              data={this.state.data}
              keys={this.state.birth}
              indexBy="day"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              valueFormat={{ format: '', enabled: false }}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'fries',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'sandwich',
                  },
                  id: 'lines',
                },
              ]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              // axisBottom={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'country',
              //   legendPosition: 'middle',
              //   legendOffset: 32,
              // }}
              // axisLeft={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'food',
              //   legendPosition: 'middle',
              //   legendOffset: -40,
              // }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div
            className="col-12"
            style={{
              padding: 20,
              backgroundColor: '#FFF',
              borderRadius: 10,
              height: 1000,
            }}>
            <p>여성 인원</p>
            <ResponsiveBar
              data={this.state.data1}
              keys={this.state.birth}
              indexBy="day"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              valueFormat={{ format: '', enabled: false }}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'fries',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'sandwich',
                  },
                  id: 'lines',
                },
              ]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              // axisBottom={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'country',
              //   legendPosition: 'middle',
              //   legendOffset: 32,
              // }}
              // axisLeft={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'food',
              //   legendPosition: 'middle',
              //   legendOffset: -40,
              // }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div
            className="col-12"
            style={{
              padding: 20,
              backgroundColor: '#FFF',
              borderRadius: 10,
              height: 1000,
            }}>
            <p>남성 인원</p>
            <ResponsiveBar
              data={this.state.data2}
              keys={this.state.birth}
              indexBy="day"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              valueFormat={{ format: '', enabled: false }}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'fries',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'sandwich',
                  },
                  id: 'lines',
                },
              ]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              // axisBottom={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'country',
              //   legendPosition: 'middle',
              //   legendOffset: 32,
              // }}
              // axisLeft={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'food',
              //   legendPosition: 'middle',
              //   legendOffset: -40,
              // }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </>
    )
  }
}
