import React from 'react'
import { ResponsiveCalendar } from '@nivo/calendar'
import moment from 'moment'
import axios from 'axios'
import {PageTitle} from '../../../../../_metronic/layout/core'

export default class StatisticsBookingDay extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: [],
    }
    this.getBookingDay()
  }

  getBookingDay() {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/statistics/booking/day`)
      .then(({ data: { error, result } }) => {
        console.log(error)
        console.log(result)
        const data = []
        result.forEach((item) => {
          item.day = item.date
          item.value = item.value.count
          if (item.value > 0) {
            data.push(item)
          }
        })
        this.setState({
          data,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const months = [
      '1월',
      '2월',
      '3월',
      '4월',
      '5월',
      '6월',
      '7월',
      '8월',
      '9월',
      '10월',
      '11월',
      '12월',
    ]

    return (
      <>
        <PageTitle breadcrumbs={[]}>예약</PageTitle>

        <div className="row">
          <div
            className="col-12"
            style={{
              padding: 20,
              backgroundColor: '#FFF',
              borderRadius: 10,
              height: 1000,
            }}>
            <ResponsiveCalendar
              data={this.state.data}
              from="2020-01-01"
              to={moment(new Date()).add(3, 'months').format('YYYY-MM-DD')}
              emptyColor="#eeeeee"
              colors={[
                'rgba(255,0,0,0.05)',
                'rgba(255,0,0,0.1)',
                'rgba(255,0,0,0.15)',
                'rgba(255,0,0,0.2)',
                'rgba(255,0,0,0.25)',
                'rgba(255,0,0,0.3)',
                'rgba(255,0,0,0.35)',
                'rgba(255,0,0,0.4)',
                'rgba(255,0,0,0.45)',
                'rgba(255,0,0,0.5)',
                'rgba(255,0,0,0.55)',
                'rgba(255,0,0,0.6)',
                'rgba(255,0,0,0.8)',
                'rgba(255,0,0,1)',
              ]}
              minValue="0"
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              direction="vertical"
              monthLegend={(year, month) => months[month]}
              yearSpacing={40}
              yearLegendPosition="after"
              yearLegendOffset={30}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left',
                },
              ]}
            />
          </div>
        </div>
      </>
    )
  }
}
