import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {isMobile} from 'react-device-detect'
import {toast} from 'react-toastify'
import {TextSub} from '../components/TextSub'
import {Button} from 'react-bootstrap'

export function PlaceSearchTag() {

  const [loading, setLoading] = useState(false)

  const [searchTag, setSearchTag] = useState('')
  const [placeList, setPlaceList] = useState('')

  useEffect(() => {

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const tagAdd = () => {
    setLoading(true)
    axios.put(`${process.env.REACT_APP_API2_URL}am/place/search/tag`, {
      search_tag: searchTag,
      place_ids: placeList,
    }).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const tagDelete = () => {
    setLoading(true)
    axios.put(`${process.env.REACT_APP_API2_URL}am/place/search/tag/delete`, {
      search_tag: searchTag,
      place_ids: placeList,
    }).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>장소 검색 관리자 태그</PageTitle>

    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <TextSub title={'관리자 태그'} subTitle={''} />
      <textarea
        className='form-control'
        rows={10}
        placeholder='태그 (구분자 ,)'
        value={searchTag}
        onChange={(e) => {
          setSearchTag(e.target.value)
        }}
      />

      <TextSub title={'장소 리스트'} subTitle={''} />
      <textarea
        className='form-control'
        rows={10}
        placeholder='장소 아이디 (구분자 ,)'
        value={placeList}
        onChange={(e) => {
          setPlaceList(e.target.value)
        }}
      />

      <div style={{
        marginTop: 20,
      }}>
        {
          loading ?
            <div style={{
              width: '100%',
              height: 42,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div style={{
              width: '100%',
            }}>
              <Button
                variant='primary'
                onClick={() => {
                  tagAdd()
                }}>
                추가하기
              </Button>
              <Button
                style={{
                  marginLeft: 10,
                }}
                variant='danger'
                onClick={() => {
                  tagDelete()
                }}>
                삭제하기
              </Button>
            </div>
        }
      </div>

    </div>
  </>
}

