import React, {useEffect, useState} from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../../components/TextSub'
import {useParams} from 'react-router-dom'
import moment from 'moment/moment'
import classNames from 'classnames'
import ImagePath from '../../../../commons/ImagePath'
import SwitchButton from '../../../../components/SwitchButton'

export function AdvertiseCategoryPlaceDetail() {
  const {id} = useParams<{id: string}>()

  const [loading, setLoading] = useState(false)

  const [place, setPlace] = useState<any>('')

  const [log, setLog] = useState(0)

  useEffect(() => {
    getCategoryPlace()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryPlace = () => {
    setPlace('')
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/category/group/${id}/place`)
      .then(({data: {error, time, result}}) => {
        console.log(error)
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          console.log(result.advPrice)
          setPlace(result)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const putApproved = (status = '') => {
    if (loading) return

    setLoading(true)

    if (status === '') {
      alert('승인/거절 상태를 확인해주세요.')
      setLoading(false)
      return
    }
    let str = '관리자 승인 처리하시겠습니까?'
    if ('R' === status) {
      str = '관리자 거절 처리하시겠습니까?'
    }

    if (window.confirm(str)) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/adv/category/group/${id}/place/approved`, {advStatus: status})
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            getCategoryPlace()
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>광고 상세</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {place !== '' && (
              <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <h2>
                    {place.advPlaceAdvertiseGroupId}. [{place.advStatusDisplay}]
                  </h2>
                </div>

                <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                  <div style={{width: 100, height: 100, position: 'relative'}}>
                    <img src={ImagePath.get(place.placeMainImagePath, '100x100')} alt='' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                    <div
                      style={{
                        position: 'absolute',
                        width: 30,
                        height: 30,
                        borderTopLeftRadius: 8,
                        bottom: 0,
                        right: 0,
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <h4 style={{margin: 0}}>{place.placeGrade}</h4>
                    </div>
                  </div>

                  <div style={{marginLeft: 10}}>
                    <LinesEllipsis text={`${place.placeId}. ${place.placeTitle}`} maxLine='2' ellipsis='...' trimRight basedOn='letters' style={{fontSize: 20, fontWeight: 'bold'}} />

                    <TextSub title={'장소 금액'} subTitle={`${place.placePriceGuestVat}`} marginTop={0} />
                    <TextSub title={'촬영 건수'} subTitle={`${place.placeShootCount} (${place.placeBookingCount})`} marginTop={-10} />
                    <TextSub title={'카테고리'} subTitle={`${place.placeCategoryMain} > ${place.placeCategorySub}`} marginTop={-10} />
                    <TextSub title={'관리자 장소 메모'} subTitle={place.placeAdminComment} marginTop={-10} />

                    <label
                      onClick={() => {
                        window.open(`/place/${place.placeId}`, '_blank')
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      장소 보기
                    </label>
                  </div>
                </div>

                <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                  <div>
                    <div style={{width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <img src={place.hostImageUrl} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
                    </div>

                    <div style={{marginLeft: 10}}>
                      {place.hostBlack === 1 && <p style={{color: 'red'}}>{place.hostBlackComment}</p>}

                      <h1>{place.userId}. 호스트</h1>
                      <TextSub title={'닉네임 (이름)'} subTitle={`${place.hostName} (${place.hostRealName})`} marginTop={0} />
                      <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(place.hostBirth))).asYears())}세`} marginTop={-10} />
                      <TextSub title={'전화번호'} subTitle={place.hostPhone} marginTop={-10} />
                      <TextSub title={'이메일'} subTitle={place.hostEmail} marginTop={-10} />

                      {place.hostStatistic !== undefined && place.hostStatistic !== null && (
                        <>
                          <TextSub title={'총 촬영 횟수'} subTitle={`${place.hostStatistic.host_booking_completed_count} / ${place.hostStatistic.host_booking_completed_add_count}`} marginTop={-10} />
                          <TextSub
                            title={'예약 승인율'}
                            subTitle={`${
                              place.hostStatistic.host_proceeding_booking > 0 && place.hostStatistic.host_booking_count > 0
                                ? Math.floor((place.hostStatistic.host_proceeding_booking / place.hostStatistic.host_booking_count) * 100)
                                : 0
                            }% (${place.hostStatistic.host_proceeding_booking} / ${place.hostStatistic.host_booking_count})`}
                            marginTop={-10}
                          />
                          <TextSub
                            title={'촬영 진행률'}
                            subTitle={`${
                              place.hostStatistic.host_booking_completed_count > 0 && place.hostStatistic.host_proceeding_booking > 0
                                ? Math.floor((place.hostStatistic.host_booking_completed_count / place.hostStatistic.host_proceeding_booking) * 100)
                                : 0
                            }% (${place.hostStatistic.host_booking_completed_count} / ${place.hostStatistic.host_proceeding_booking})`}
                            marginTop={-10}
                          />
                          <TextSub title={'총 소득금액'} subTitle={`${place.hostStatistic.booking_host_price.toLocaleString('en')}`} marginTop={-10} />
                        </>
                      )}

                      <TextSub
                        title={'가입일'}
                        subTitle={`${place.hostCreatedAt}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(place.hostCreatedAt)).asDays()} 일전`}
                        marginTop={-10}
                      />
                      <TextSub
                        title={'마지막 활성 시간'}
                        subTitle={`${moment(place.hostLastLoginedAt).format('MM-DD')}, ${Math.floor(
                          (moment.duration(moment().diff(moment(place.hostLastLoginedAt, 'YYYY-MM-DD'))).asDays() * 100) / 100
                        )} 일전`}
                        marginTop={-10}
                      />
                      <TextSub title={'패널티 히스토리'} subTitle={place.hostBan} marginTop={-10} />
                      {place.hostMemo !== undefined && place.hostMemo.length > 0 && <TextSub title={'메모'} subTitle={place.hostMemo} marginTop={-10} />}
                    </div>
                  </div>

                  <div>
                    <SwitchButton id={place.userId}>Switch</SwitchButton>
                    <label
                      style={{marginLeft: 10}}
                      onClick={() => {
                        window.open(`/user/${place.userId}`)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      자세히 보기
                    </label>
                  </div>
                </div>

                <div style={{margin: 10, paddingBottom: 10}}>
                  <table style={{fontSize: '15px', width: '100%'}}>
                    <colgroup>
                      <col style={{width: '10%'}} />
                      <col style={{width: 'auto'}} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td style={{verticalAlign: 'top'}}>썸네일 이미지</td>
                        <td>
                          <img src={ImagePath.get(place.advPlaceMainImagePath, '100x100')} alt='' style={{width: '100px', height: '100px', objectFit: 'cover', borderRadius: 10}} />
                        </td>
                      </tr>
                      <tr>
                        <td>썸네일 변경일</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'top'}}>광고 일정</td>
                        <td>
                          {place.schedules.map((sc: any, index: number) => {
                            return (
                              <>
                                <span key={index.toString()}>{sc.schedule_display}</span>
                                <br />
                              </>
                            )
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>결제 금액</td>
                        <td>{place.advPrice.toLocaleString()} (부가세 포함)</td>
                      </tr>
                      <tr>
                        <td>결제일 </td>
                        <td>{place.advPaidTime}</td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'top'}}>취소 일정</td>
                        <td>
                          {place.cancelSchedules.map((sc: any, index: number) => {
                            return (
                              <>
                                <span key={index.toString()}>{sc.schedule_display}</span>
                                <br />
                              </>
                            )
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>취소 금액</td>
                        <td>{place.advCancelPriceDisplay}</td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'top'}}>취소일</td>
                        <td>
                          {place.advCancelledTime.map((ct: any, index: number) => {
                            return (
                              <>
                                <span key={index.toString()}>{ct.adv_cancelled_time}</span>
                                <br />
                              </>
                            )
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>승인 마감일</td>
                        <td>{place.advAdminApprovedDeadlineDate}</td>
                      </tr>
                      <tr>
                        <td>승인 시간</td>
                        <td>{place.advApprovedTime}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {place.advStatus === 'W' && (
                  <>
                    <div style={{paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          putApproved('A')
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        관리자 승인
                      </label>

                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          putApproved('R')
                        }}
                        className='btn btn-sm btn-danger'
                      >
                        관리자 거절
                      </label>
                    </div>
                  </>
                )}
                {place.kcp !== undefined && place.kcp !== null && (
                  <div style={{margin: 10}}>
                    <h4>결제 정보 - KCP</h4>
                    <table style={{fontSize: '15px', width: '100%'}}>
                      <colgroup>
                        <col style={{width: '10%'}} />
                        <col style={{width: 'auto'}} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>결제 타입</td>
                          <td>
                            {place.kcp.use_pay_method === '001000000000'
                              ? '가상 계좌'
                              : place.kcp.use_pay_method === '100000000000'
                              ? '카드 결제'
                              : place.kcp.use_pay_method === '200000000000'
                              ? '간편 결제'
                              : place.kcp.use_pay_method === '300000000000'
                              ? '수기 결제'
                              : place.kcp.use_pay_method}
                            <br />
                            {place.kcp.use_pay_method === '001000000000' && (
                              <>
                                {place.kcp.bank_name}
                                {place.kcp.account}
                                {place.kcp.tno}
                              </>
                            )}
                            {place.kcp.use_pay_method === '100000000000' && (
                              <>
                                {place.kcp.card_name}
                                {place.kcp.cash_no}
                                '일반결제'
                              </>
                            )}
                            {place.kcp.use_pay_method === '200000000000' && place.kcp.card_name}
                          </td>
                        </tr>
                        <tr>
                          <td>결제 금액</td>
                          <td>{Number.parseInt(place.kcp.amount).toLocaleString('en')}</td>
                        </tr>
                        <tr>
                          <td>결제일</td>
                          <td>{place.kcp.updated_at}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {place.tossPayment !== undefined && place.tossPayment !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - TOSS</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td style={{verticalAlign: 'top'}}>결제 타입</td>
                            <td>
                              {place.tossPayment.method}
                              <br />
                              {place.tossPayment.method === '가상계좌' && (
                                <>
                                  {place.tossPayment.virtualAccountBankName}
                                  <br />
                                  {place.tossPayment.virtualAccountAccountNumber}
                                  <br />
                                  {place.tossPayment.virtualAccountCustomerName}
                                  <br />
                                </>
                              )}
                              {place.tossPayment.cardIssuerName}
                            </td>
                          </tr>
                          <tr>
                            <td>결제 금액</td>
                            <td>{Number.parseInt(place.tossPayment.totalAmount).toLocaleString('en')}</td>
                          </tr>
                          <tr>
                            <td>결제일</td>
                            <td>{place.tossPayment.requestedAt}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {place.naverPayPayments !== undefined && place.naverPayPayments !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - 네이버페이</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td style={{verticalAlign: 'top'}}>결제 타입</td>
                            <td>
                              {place.naverPayPayments.primaryPayMeans}
                              {place.naverPayPayments.primaryPayMeans === '신용카드' && (
                                <>
                                  <br />
                                  {place.naverPayPayments.cardCorpCode}
                                </>
                              )}
                              {place.naverPayPayments.primaryPayMeans === '계좌이체' && (
                                <>
                                  <br />
                                  {place.naverPayPayments.bankCorpCode}
                                  <br />
                                  {place.naverPayPayments.bankAccountNo}
                                </>
                              )}
                              {place.naverPayPayments.primaryPayMeans === '포인트/머니 결제' && (
                                <>
                                  <br />
                                  {place.naverPayPayments.npointPayAmount} 포인트/머니
                                </>
                              )}
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>결제 금액</td>
                            <td>{Number.parseInt(place.naverPayPayments.totalPayAmount).toLocaleString('en')}</td>
                          </tr>
                          <tr>
                            <td>결제일</td>
                            <td>{place.naverPayPayments.admissionYmdt}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {place.kakaopayPayments !== undefined && place.kakaopayPayments !== null && (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
                    <div style={{margin: 10}}>
                      <h4>결제 정보 - 카카오 페이</h4>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                        <tr>
                          <td style={{verticalAlign: 'top'}}>결제 타입</td>
                          <td>
                            {place.kakaopayPayments.payment_method_type}
                            {place.kakaopayPayments.payment_method_type === 'CARD' && (
                              <>
                                <br />
                                {place.kakaopayPayments.s_card_corp_name}
                              </>
                            )}
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>결제 금액</td>
                          <td>{Number.parseInt(place.kakaopayPayments.total).toLocaleString('en')}</td>
                        </tr>
                        <tr>
                          <td>결제일</td>
                          <td>{place.kakaopayPayments.k_created_at}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
            {place !== null && (
              <div>
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 0 ? 'active' : '')} onClick={() => setLog(0)}>
                      광고 진행 로그 ({place.placeAdvertiseHistories !== undefined ? place.placeAdvertiseHistories.length : 0})
                    </label>
                  </li>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 1 ? 'active' : '')} onClick={() => setLog(1)}>
                      알림톡 로그 ({place.alimTalkLogs !== undefined ? place.alimTalkLogs.length : 0})
                    </label>
                  </li>
                  <li className='nav-item'>
                    <label className={classNames('nav-link', log === 2 ? 'active' : '')} onClick={() => setLog(2)}>
                      썸네일 검토 로그 ({place.placeAdvertiseImageChanges !== undefined ? place.placeAdvertiseImageChanges.length : 0})
                    </label>
                  </li>
                </ul>
                <div>
                  {log === 0 && (
                    <div>
                      {place.placeAdvertiseHistories !== undefined &&
                        place.placeAdvertiseHistories.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_0_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h3
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <p dangerouslySetInnerHTML={{__html: history.content}} />
                              </h3>
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {log === 1 && (
                    <div>
                      {place.alimTalkLogs !== undefined &&
                        place.alimTalkLogs.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_1_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h4>{history.phone}</h4>
                              <br />
                              <h3
                                style={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <p dangerouslySetInnerHTML={{__html: history.message}} />
                              </h3>
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {log === 2 && (
                    <div>
                      {place.placeAdvertiseImageChanges !== undefined &&
                        place.placeAdvertiseImageChanges.map((history: any, index: number) => {
                          return (
                            <div className='shadow mb-5 mt-5 rounded p-5' key={`log_2_${index}`}>
                              <h4>{moment(history.created_at).format('YYYY-MM-DD HH:mm:ss')}</h4>
                              <br />
                              <h4>{history.status === 'W' ? '승인 대기중' : history.status === 'A' ? '승인' : '거절'}</h4>
                              <br />
                              {history.status === 'A' && (
                                <>
                                  <h4>승인 일자 : history.approvedDate</h4>
                                  <br />
                                </>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
