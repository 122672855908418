import React from 'react'
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap'
import axios from 'axios'

import MagazineTitle from './components/MagazineTitle'
import MagazineText from './components/MagazineText'
import MagazineImage from './components/MagazineImage'
import MagazineYoutube from './components/MagazineYoutube'
import MagazineImages from './components/MagazineImages'
import MainCategoryPlacesCard from '../../components/MainCategoryPlacesCard'
import {PageTitle} from '../../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'

export default class MagazineEditor extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      magazine_id: props.match.params.magazine_id,
      editor: '',
      place_title: '',
      contents: [],
      places: [],
      selectedFile: null,
      modalShow: false,
    }

    this.contents_sample = [
      {
        type: 'cover',
        image_id: 0,
        image_url:
          'https://s3.hourplace.co.kr/dev/temp/images/magazine/2021/05/12/9a771558-47be-3c88-99a7-ab73c6aea28c.jpg',
        background_color: '#1b1d1f',
        category: 'people',
        title: {
          wrapStyle: {position: 'relative', margin: '0px 0px 0px 0px'},
          textStyle: {
            color: '#FFF',
            fontSize: 40,
            fontWeight: 'bold',
            margin: '0px 0px 0px 0px',
          },
          text: '귀 기울이게 되는 독백 : title',
        },
        sub_title: {
          wrapStyle: {position: 'relative', margin: '16px 0px 0px 0px'},
          textStyle: {
            color: '#FFF',
            fontSize: 18,
            fontWeight: 500,
            margin: '0px 0px 0px 0px',
          },
          text: '임승 원의 독백 : sub_title',
        },
        date: {
          wrapStyle: {position: 'relative', margin: '13px 0px 0px 0px'},
          textStyle: {
            color: '#FFF',
            fontSize: 14,
            fontWeight: 500,
            margin: '0px 0px 0px 0px',
          },
          text: '2021.03.09 : date',
        },
      },
    ]

    if (this.state.magazine_id > 0) {
      this.getMagazine()
    } else {
      this.state.contents = this.contents_sample
    }
  }

  getMagazine () {
    axios.get(`${process.env.REACT_APP_API2_URL}am/magazine/${this.state.magazine_id}`).then(({data: {error, result}}) => {
      console.log(error, result)
      if (error !== '') {
      } else {
        result.magazine.contents.forEach((item) => {
          if (item.type === 'image' || item.type === 'images') {
            if (item.source === undefined) {
              item.source = ''
            }
          }
        })
        this.setState({
          editor: result.magazine.editor,
          place_title: result.magazine.place_title,
          contents: result.magazine.contents,
          places: result.places,
        })
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  onDelete = (info) => {
    if (window.confirm('삭제하시겠습니까?')) {
      console.log(this.state.contents.indexOf(info))
      this.state.contents.splice(this.state.contents.indexOf(info), 1)
      this.forceUpdate()
    }
  }

  onUp = (info) => {
    const index = this.state.contents.indexOf(info)
    console.log(index)
    if (index > 1) {
      this.state.contents.splice(index, 1)
      this.state.contents.splice(index - 1, 0, info)
      this.forceUpdate()
    }
  }

  onDown = (info) => {
    const index = this.state.contents.indexOf(info)
    console.log(index)
    if (index < this.state.contents.length) {
      this.state.contents.splice(index, 1)
      this.state.contents.splice(index + 1, 0, info)
      this.forceUpdate()
    }
  }

  onSave () {
    console.log(this.state.contents)
    axios.put(
      `${process.env.REACT_APP_API_URL}magazine/${this.state.magazine_id}`,
      {
        place_title: this.state.place_title,
        editor: this.state.editor,
        contents: this.state.contents,
        places: this.state.places,
      },
    ).then(({data: {error, result}}) => {
      console.log(result)
      if (error !== '') {
      } else {
        window.location.href = `/marketing/magazine/${result.id}/editor`
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  handleFileInput (e) {
    this.setState({
      selectedFile: e.target.files[0],
    })
  }

  handlePost () {
    const formData = new FormData()
    formData.append('file', this.state.selectedFile)

    return axios.post(`${process.env.REACT_APP_API_URL}image/magazine`, formData).then(({data: {error, result}}) => {
      console.log(error, result)
      if (error === '') {
        this.setState(({contents}) => ({
          contents: [
            {
              ...contents[0],
              image_id: result.image_id,
              image_url: result.image_url,
            },
            ...contents.slice(1),
          ],
        }))
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  addPlace = (place) => {
    console.log('add', place)
    this.state.places.splice(0, 0, place)
    this.forceUpdate()
  }

  removePlace = (place) => {
    if (
      window.confirm(`${place.id}. ${place.title} 장소를 삭제하시겠습니까?`)
    ) {
      console.log('delete', place)
      this.state.places.splice(this.state.places.indexOf(place), 1)
      this.forceUpdate()
    }
  }

  render () {
    const {contents} = this.state
    const modalClose = () => this.setState({modalShow: false})
    return (
      <>
        <PageTitle breadcrumbs={[]}>매거진 에디터</PageTitle>

        <PlacesModal
          show={this.state.modalShow}
          onAdd={this.addPlace}
          onHide={modalClose}
        />

        <div
          className='row'
          style={{padding: 40, backgroundColor: '#FFF', borderRadius: 8}}>
          <div>
            <Form.Group>
              <Form.Label>에디터 선택</Form.Label>
              <Form.Control
                as='select'
                value={this.state.editor}
                onChange={(e) => {
                  this.setState({
                    editor: e.target.value,
                  })
                }}>
                <option value=''>없음</option>
                <option value='ksy'>김시연</option>
                <option value='kjs'>고준석</option>
              </Form.Control>
            </Form.Group>
          </div>

          {contents.map((item, index) => {
            if (item.type === 'cover') {
              return (
                <div
                  className='col-12'
                  style={{padding: 0, backgroundColor: '#FFF'}}>
                  <div>
                    <Form.Group>
                      <Form.Label>카테고리 선택</Form.Label>
                      <Form.Control
                        as='select'
                        defaultValue={item.category}
                        onChange={(e) => {
                          item.category = e.target.value
                        }}>
                        <option>people</option>
                        <option>shoot</option>
                        <option>interest</option>
                        <option>interior</option>
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: 400,
                    }}>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: 300,
                        backgroundColor: item.background_color,
                      }}>
                      <Form
                        noValidate
                        validated={false}
                        onSubmit={(e) => {
                          e.preventDefault()
                          return false
                        }}>
                        <Form.Group
                          as={Col}
                          controlId='validationCustomUsername'>
                          <Form.Label>text</Form.Label>
                          <Form.Group as={Row}>
                            <InputGroup className='pr-4'>
                              <Form.Control
                                type='text'
                                placeholder='배경색을 입력해 주세요.'
                                aria-describedby='inputGroupPrepend'
                                value={item.background_color}
                                onChange={(e) => {
                                  item.background_color = e.target.value
                                  this.forceUpdate()
                                }}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Form.Group>
                      </Form>
                    </div>
                    <div
                      style={{
                        width: 'calc(100% - 300px)',
                        height: '100%',
                        marginLeft: 300,
                      }}>
                      <img
                        src={item.image_url}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        alt=''
                      />
                    </div>

                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        margin: '0 0 30px 30px',
                      }}>
                      <textarea
                        style={{
                          width: 500,
                          height: 200,
                          color: '#FFF',
                          backgroundColor: 'transparent',
                          fontSize: 40,
                          fontWeight: 'bold',
                        }}
                        value={item.title.text}
                        onChange={(e) => {
                          item.title.text = e.target.value
                          this.forceUpdate()
                        }}
                      />
                      <MagazineTitle
                        info={item.sub_title}
                        onChange={(sub_title) => (item.sub_title = sub_title)}
                      />
                      <MagazineTitle
                        info={item.date}
                        onChange={(date) => (item.date = date)}
                      />
                    </div>
                  </div>
                  <div style={{marginTop: 10}}>
                    <input
                      type='file'
                      name='file'
                      onChange={(e) => this.handleFileInput(e)}
                    />
                    <Button type='button' onClick={() => this.handlePost()}>
                      업로드
                    </Button>
                  </div>
                </div>
              )
            }
            if (item.type === 'image') {
              return (
                <div
                  className='col-12'
                  style={{padding: 0, backgroundColor: '#FFF'}}
                  key={index}>
                  <MagazineImage
                    info={item}
                    onDelete={this.onDelete}
                    onUp={this.onUp}
                    onDown={this.onDown}
                    onChange={(change_item) => (item = change_item)}
                  />
                </div>
              )
            }
            if (item.type === 'images') {
              return (
                <div
                  className='col-12'
                  style={{padding: 0, backgroundColor: '#FFF'}}
                  key={index}>
                  <MagazineImages
                    info={item}
                    onDelete={this.onDelete}
                    onUp={this.onUp}
                    onDown={this.onDown}
                    onChange={(change_item) => (item = change_item)}
                  />
                </div>
              )
            }
            if (item.type === 'youtube') {
              return (
                <div
                  className='col-12'
                  style={{padding: 0, backgroundColor: '#FFF'}}
                  key={index}>
                  <MagazineYoutube
                    info={item}
                    onDelete={this.onDelete}
                    onUp={this.onUp}
                    onDown={this.onDown}
                    onChange={(change_item) => (item = change_item)}
                  />
                </div>
              )
            }
            if (item.type === 'text') {
              return (
                <div
                  className='col-12'
                  style={{padding: 0, backgroundColor: '#FFF'}}
                  key={index}>
                  <MagazineText
                    info={item}
                    onDelete={this.onDelete}
                    onUp={this.onUp}
                    onDown={this.onDown}
                    onChange={(change_item) => (item = change_item)}
                  />
                </div>
              )
            }
            return <></>
          })}
        </div>

        <div
          className='row'
          style={{padding: 40, backgroundColor: '#FFF', borderRadius: 8}}>
          <div>
            <span className='pr-4'>
              <Button
                variant='success'
                onClick={() => {
                  this.state.contents.push({
                    type: 'text',
                    wrapStyle: {
                      position: 'relative',
                      margin: '80px 0px 0px 0px',
                    },
                    title: {
                      wrapStyle: {
                        position: 'relative',
                        margin: '13px 0px 0px 0px',
                      },
                      textStyle: {
                        color: '#000',
                        fontSize: 20,
                        fontWeight: 'bold',
                        margin: '0px 0px 0px 0px',
                      },
                      text: 'title',
                    },
                    sub_title: {
                      wrapStyle: {
                        position: 'relative',
                        margin: '13px 0px 0px 0px',
                      },
                      textStyle: {
                        color: '#000',
                        fontSize: 32,
                        fontWeight: 'bold',
                        margin: '0px 0px 0px 0px',
                      },
                      text: 'sub_title',
                    },
                    content: {
                      wrapStyle: {
                        position: 'relative',
                        margin: '13px 0px 0px 0px',
                      },
                      textStyle: {
                        color: '#000',
                        fontSize: 16,
                        fontWeight: 'normal',
                        margin: '0px 0px 0px 0px',
                      },
                      text: 'content',
                    },
                  })
                  this.forceUpdate()
                }}>
                Text Add
              </Button>
            </span>
            <span className='pr-4'>
              <Button
                variant='success'
                onClick={() => {
                  this.state.contents.push({
                    type: 'image',
                    image_id: 0,
                    image_url:
                      'https://s3.hourplace.co.kr/dev/temp/images/magazine/2021/05/12/9a771558-47be-3c88-99a7-ab73c6aea28c.jpg',
                    wrapStyle: {
                      position: 'relative',
                      margin: '100px 0px 0px 0px',
                      height: 600,
                    },
                  })
                  this.forceUpdate()
                }}>
                Image Add
              </Button>
            </span>
            <span className='pr-4'>
              <Button
                variant='success'
                onClick={() => {
                  this.state.contents.push({
                    type: 'images',
                    images: [
                      {
                        image_id: 0,
                        image_url:
                          'https://s3.hourplace.co.kr/dev/temp/images/magazine/2021/05/12/9a771558-47be-3c88-99a7-ab73c6aea28c.jpg',
                      },
                      {
                        image_id: 0,
                        image_url:
                          'https://s3.hourplace.co.kr/dev/temp/images/magazine/2021/05/12/9a771558-47be-3c88-99a7-ab73c6aea28c.jpg',
                      },
                    ],
                    wrapStyle: {
                      position: 'relative',
                      margin: '100px 0px 0px 0px',
                      height: 600,
                    },
                  })
                  this.forceUpdate()
                }}>
                Images Add
              </Button>
            </span>
            <span className='pr-4'>
              <Button
                variant='success'
                onClick={() => {
                  this.state.contents.push({
                    type: 'youtube',
                    url: 'https://www.youtube.com/embed/uTAUH7lDQlY?start=306',
                    wrapStyle: {
                      position: 'relative',
                      margin: '100px 0px 0px 0px',
                      height: 600,
                    },
                  })
                  this.forceUpdate()
                }}>
                Youtube Add
              </Button>
            </span>
          </div>
        </div>

        <div
          style={{
            backgroundColor: '#FFF',
            padding: 20,
            borderRadius: 20,
            marginTop: 20,
          }}>
          <Form.Control
            type='text'
            placeholder='추천장소 제목을 입력해 주세요.'
            aria-describedby='inputGroupPrepend'
            value={this.state.place_title}
            onChange={(e) => {
              this.setState({
                place_title: e.target.value,
              })
            }}
          />
          <div
            style={{
              marginTop: 40,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}>
            {this.state.places.map((place) => {
              return (
                <MainCategoryPlacesCard
                  place={place}
                  removePlace={this.removePlace}
                />
              )
            })}
          </div>
          <span className='pr-4'>
            <Button
              variant='success'
              onClick={() => {
                this.setState({modalShow: true})
              }}>
              장소 추가
            </Button>
          </span>
        </div>

        <div style={{marginTop: 40}}>
          <Button variant='primary' onClick={() => this.onSave()}>
            저장
          </Button>
        </div>
      </>
    )
  }
}

class PlacesModal extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      keyword: '',
      place: null,
      places: [],
      currentPage: 0,
      perPage: 0,
      total: 0,
    }
  }

  handlePageChange (page) {
    this.getPlaces(page)
  }

  getPlaces (page = 1) {
    console.log(this.state.keyword)
    axios.post(`${process.env.REACT_APP_API_URL}search/place`, {
      page,
      keyword: this.state.keyword,
    }).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        this.setState({
          place: result,
          places: result.data,
          currentPage: result.current_page,
          perPage: result.per_page,
          total: result.total,
        })
        window.scrollTo(0, 0)
        // this.forceUpdate()
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  render () {
    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            장소 리스트
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <input
              type='text'
              className='form-control'
              placeholder='장소 검색어'
              value={this.state.keyword}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                this.setState({
                  keyword: e.target.value.toLowerCase().trim(),
                })
              }}
            />
            <label onClick={() => {
              this.getPlaces(1)
            }} style={{width: 100}} className='btn btn-dark'>검색</label>
          </div>
          <div className={'row'}>

            {this.state.places.map((item, index) => {
              return (
                <div className='col-lg-3' key={index.toString()}>
                  <div className='card card-custom card-stretch shadow mb-5'>
                    <div className='card-header'>
                      <h1 className='card-title'>
                        {item.id}. {item.title}
                      </h1>
                    </div>
                    <div className='card-body'>
                      <img
                        className='rounded'
                        src={item.image_url}
                        style={{height: 120, width: '100%'}}
                        alt=''
                      />
                      <h3>
                        {item.price.toLocaleString('en')}
                      </h3>
                    </div>
                    <div className='card-footer'>
                      <label onClick={() => {
                        if (
                          window.confirm(
                            `${item.id}. ${item.title} 장소를 추가하시겠습니까?`,
                          )
                        ) {
                          this.props.onAdd(item)
                        }
                      }} className='btn btn-primary'>추가</label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <Pagination activePage={this.state.currentPage}
                      itemsCountPerPage={this.state.perPage}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={10}
                      prevPageText={'‹'}
                      nextPageText={'›'}
                      onChange={this.handlePageChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
