import React, { useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import Pagination from 'react-js-pagination'

export function PopBillMemberList() {

  const [items, setItems] = useState([])

  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getItems(1)
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getItems(page)
  }

  const onSearch = () => {
    getItems(1)
  }

  const getItems = (page = 1) => {
    setItems([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/pay/popbill/member?page=${page}&search=${search}`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          toast.error(error)
        } else {
          console.log(result)
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const popBillDelete = (userId = 0, corpNum = '') => {
    if (window.confirm('팝빌 연동 삭제 하시겠습니까?')) {
    setLoading(true)
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/pay/popbill/member`, {'user_id': userId, 'corp_num' : corpNum})
      .then(({data: {error, result}}) => {
        if (error !== '') {
          toast.error(error)
        } else {
          alert('삭제처리되었습니다.')
          getItems(1)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
      }
  }

  return (
    <>
    <PageTitle breadcrumbs={[]}>팝빌 연동 멤버 리스트</PageTitle>

    <div
      style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
      }}
    >
      <div style={{
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <input
          type='text'
          className='form-control'
          placeholder='사업자 번호, 회원 번호'
          value={search}
          style={{
            flex: 1,
          }}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSearch()
            }
          }}
        />
        <label onClick={() => {
          onSearch()
        }} style={{
          width: 100,
        }} className='btn btn-dark'>검색</label>
      </div>

    <div
      style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            height: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
        </div>
      ) : (
        <div className='tab-content' id='myTabContent'>
          <div className='table-responsive'>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th className='min-w-100px'>사용자 번호</th>
                <th className='min-w-100px'>index</th>
                <th className='min-w-150px'>팝빌아이디</th>
                <th className='min-w-100px'>사업자번호</th>
                <th className='min-w-150px'>대표자 성명</th>
                <th className='min-w-250px'>회사명</th>
                <th className='min-w-300px'>사업장 주소</th>
                <th className='min-w-100px'>업태</th>
                <th className='min-w-100px'>종목</th>
                <th className='min-w-100px'>담당자 성명</th>
                <th className='min-w-250px'>담당자 이메일</th>
                <th className='min-w-100px'>담당자 휴대폰</th>
                <th className='min-w-100px'>담당자 등록 일자</th>
                <th className='min-w-100px'>담당자 권한</th>
                <th className='min-w-100px'>관리자 여부</th>
                <th className='min-w-100px'>계정 상태</th>
                <th className='min-w-100px'>삭제 여부</th>
                <th className='min-w-150px'>사업자 과세 유형</th>
                <th className='min-w-150px'>과세 유형 전환일자</th>
                <th className='min-w-100px'>휴페업 상태</th>
                <th className='min-w-100px'>휴페업일자</th>
                <th className='min-w-300px'>국세청 확인 일자</th>
                <th className='min-w-300px'>인증서 유효성 확인 여부</th>
                <th className='min-w-300px'>팝빌 서버 인증서 등록 시간</th>
                <th className='min-w-300px'>팝빌 인증서버에 등록된 인증서의 만료 일시</th>
                <th className='min-w-200px'>종사업장 식별번호</th>
                <th className='min-w-100px'>생성일자</th>
                <th className='min-w-100px'>수정일자</th>
                <th className='min-w-100px'>삭제일자</th>
                <th className='min-w-100px'>삭제</th>
              </tr>
              </thead>
              <tbody>
              {items.map((item: any, index: number) => {
                return [
                  <tr key={`${index}`}>
                    <td>{item.user_id}</td>
                    <td>{item.id}</td>
                    <td>{item.popbill_id}</td>
                    <td>{item.corp_num}</td>
                    <td>{item.ceo_name}</td>
                    <td>{item.corp_name}</td>
                    <td>{item.addr}</td>
                    <td>{item.biz_type}</td>
                    <td>{item.biz_class}</td>
                    <td>{item.contact_name}</td>
                    <td>{item.contact_email}</td>
                    <td>{item.contact_tel}</td>
                    <td>{item.contact_regDT}</td>
                    <td>{item.contact_searchRole}</td>
                    <td>{item.contact_mgrYN}</td>
                    <td>{item.contact_state}</td>
                    <td>{item.delete}</td>
                    <td>{item.tax_type === '10' ? '일반과세' : (item.tax_type === '20' ? '면세과세' : (item.tax_type === '30' ? '간이과세' : (item.tax_type === '31' ? '간이과세세금' : (item.tax_type === '40' ? '비영리' : item.tax_type))))}{`(${item.tax_type})`}</td>
                    <td>{item.tax_date}</td>
                    <td>{item.corp_state}</td>
                    <td>{item.corp_state_date}</td>
                    <td>{item.corp_check_date}</td>
                    <td>{item.is_cert_validation}</td>
                    <td>{item.cert_reg_dt}</td>
                    <td>{item.cert_expire_dt}</td>
                    <td>{item.tax_reg_id}</td>
                    <td>{item.created_at}</td>
                    <td>{item.updated_at}</td>
                    <td>{item.deleted_at}</td>
                    <td>
                      {item.delete === 0 &&
                      <label onClick={() => {
                        popBillDelete(item.user_id, item.corp_num)
                      }}
                      className='btn btn-sm btn-danger'
                       >
                      삭제
                      </label>
                      }
                    </td>
                  </tr>,
                ]
              })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
    {
      items.length > 0 &&
      <div style={{marginTop: 20}}>
        <Pagination activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={10}
                    prevPageText={'‹'}
                    nextPageText={'›'}
                    onChange={handlePageChange} />
      </div>
    }
    </div>
</>
)
}
