import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'

export function PayBankRemit() {

  const [remitLoading, setRemitLoading] = useState(false)
  const [bankCodes, setBankCodes] = useState([])
  const [bankCode, setBankCode] = useState('BK03')
  const [bankAccount, setBankAccount] = useState('85501518804014')
  const [price, setPrice] = useState(0)

  useEffect(() => {

    getBankCodes()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getBankCodes = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/pay/bank/codes`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setBankCodes(result)
      }
      setRemitLoading(false)
    }).catch((error) => {
      console.log(error)
      setRemitLoading(false)
    })
  }

  const goRemit = () => {
    if (window.confirm('송금 하시겠습니까?')) {
      setRemitLoading(true)
      axios.post(`${process.env.REACT_APP_API2_URL}kcp/remit`, {pay_id: 0, type: 'direct', va_mny: price, va_bankcode: bankCode, va_account: bankAccount, va_name: '아워플레이스'}).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = '/pay/bank'
        }
        setRemitLoading(false)
      }).catch((error) => {
        console.log(error)
        setRemitLoading(false)
      })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>송금</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div style={{
          marginTop: 16,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <p style={{
            width: 156,
            fontWeight: 500,
            fontSize: 15,
            color: '#9EA4AA',
          }}>
            금액
          </p>
          <input
            type='number'
            className='form-control'
            placeholder=''
            value={price}
            onChange={(e) => {
              setPrice(Number(e.target.value))
            }}
          />
        </div>

        <div style={{
          marginTop: 16,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <p style={{
            width: 156,
            fontWeight: 500,
            fontSize: 15,
            color: '#9EA4AA',
          }}>
            은행
          </p>
          <select
            className='form-select form-select-solid'
            name='type'
            value={bankCode}
            onChange={(e) =>
              setBankCode(e.target.value)
            }
          >
            <option value=''>없음</option>
            {
              bankCodes.map((bank: any) => {
                return <option value={bank.code}>{bank.name}</option>
              })
            }
          </select>
        </div>

        <div style={{
          marginTop: 16,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <p style={{
            width: 156,
            fontWeight: 500,
            fontSize: 15,
            color: '#9EA4AA',
          }}>
            계좌번호
          </p>
          <input
            type='text'
            className='form-control'
            placeholder=''
            value={bankAccount}
            onChange={(e) => {
              setBankAccount(e.target.value)
            }}
          />
        </div>

        <div style={{
          marginTop: 30,
          padding: 10,
        }}>
          <button className='btn btn-primary' onClick={() => goRemit()} disabled={remitLoading}>
            {!remitLoading && <span className='indicator-label'>송금하기</span>}
            {remitLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>송금하기<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            )}
          </button>
        </div>

      </div>

    </div>
  </>
}

