import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'

import 'react-datepicker/dist/react-datepicker.css'
import classNames from 'classnames'
import Modal from '../../components/Modal'
import {toast} from 'react-toastify'

export function BookingGenreTag() {
  const [tagName, setTagName] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [modalVisibleTitle, setModalVisibleTitle] = useState(false)
  const [modalVisibleOrder, setModalVisibleOrder] = useState(false)
  const [genreTag, setGenreTag] = useState<any>([])
  const [genre, setGenre] = useState<any>([])

  const [genreId, setGenreId] = useState(0)
  const [genreName, setGenreName] = useState('')
  const [genreOrder, setGenreOrder] = useState(0)

  useEffect(() => {
    getGenreTags()
    getGenre()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getGenreTags = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/genre/tags`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          window.alert(error)
        } else {
          console.log(result)
          setGenreTag(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getGenre = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/genre`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          window.alert(error)
        } else {
          console.log(result)
          setGenre(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const checkTagName = (tagName = '') => {
    setTagName(tagName)
  }

  const addGenreTags = () => {
    if (tagName.trim().length < 1) {
      return false
    }
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/booking/genre/tags`, {tagName: tagName})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          getGenreTags()
        }
      })
      .catch((error) => {
        console.log(error)
      })

    setTagName('')
    setModalVisible(false)
  }

  const updateGenreHighRank = (id = 0, keyword = '', keyword2 = '') => {
    const keywordArr = keyword.trim().split(',')
    const keyword2Arr = keyword2.trim().split(',')
    const newArray: String[] = []
    let isPass = true

    if (keywordArr.length !== 6) {
      alert('등록할 키워드 개수를 확인해주세요.')
      return false
    }

    keywordArr.forEach((ele) => {
      if (!newArray.includes(ele)) {
        newArray.push(ele)
      } else {
        alert('중복되는 장르태그가 있습니다.[' + ele + ']')
        isPass = false
      }
    })

    if (!isPass) {
      return false
    }

    if (keywordArr.filter((item) => keyword2Arr.includes(item)).length > 0) {
      alert('중복되는 장르태그가 있습니다.[' + keywordArr.filter((item) => keyword2Arr.includes(item)) + ']')
      return false
    }

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/booking/genre/${id}/high`, {keyword: keyword})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          toast.error('변경되었습니다.')
          getGenreTags()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateGenreLowRank = (id = 0, keyword = '', keyword2 = '', order = 0) => {
    const keywordArr = keyword.trim().split(',')
    const keyword2Arr = keyword2.trim().split(',')
    const newArray: String[] = []
    let isPass = true

    console.log(keywordArr)
    console.log(keyword2Arr)

    let checkLength = 9

    if (order === 99) {
      checkLength = 14
    }

    if (keywordArr.length > checkLength || 1 > keywordArr.length || (1 === keywordArr.length && '' === keywordArr[0])) {
      alert('등록할 키워드 개수를 확인해주세요.')
      return false
    }

    keywordArr.forEach((ele) => {
      if (!newArray.includes(ele)) {
        newArray.push(ele)
      } else {
        alert('중복되는 장르태그가 있습니다.[' + ele + ']')
        isPass = false
      }
    })

    if (!isPass) {
      return false
    }

    if (keywordArr.filter((item) => keyword2Arr.includes(item)).length > 0) {
      alert('중복되는 장르태그가 있습니다.[' + keywordArr.filter((item) => keyword2Arr.includes(item)) + ']')
      return false
    }

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/booking/genre/${id}/low`, {keyword: keyword})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          toast.error('변경되었습니다.')
          getGenreTags()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateProjectKeyword = (keyword = '') => {
    if (window.confirm('제안 키워드 삭제 처리하시겠습니까?')) {
      if (keyword.trim().length < 1) {
        alert('삭제할 제안 키워드를 확인해주세요.')
        return false
      }

      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/genre/keyword/delete?keyword=${keyword}`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            getGenre()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const updateGenreName = () => {
    console.log('genreId : ' + genreId)
    console.log('genreName : ' + genreName)
    if (window.confirm('장르 타이틀을 변경하시겠습니까?')) {
      if (genreName.trim().length < 1) {
        alert('장르 타이틀을 확인해주세요.')
        return false
      }

      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/genre/name/${genreId}`, {title: genreName})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            getGenre()
            alert('타이틀이 변경되었습니다.')
          }
        })
        .catch((error) => {
          console.log(error)
        })
      setModalVisibleTitle(false)
    }
  }

  const updateGenreShow = (id = 0, show = 0) => {
    console.log('genreId : ' + id)
    console.log('genreShow : ' + show)
    if (window.confirm('장르 노출여부를 변경하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/genre/show/${id}`, {show: show})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            getGenre()
            alert('노출여부가 변경되었습니다.')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const updateGenreOrder = () => {
    console.log('genreId : ' + genreId)
    console.log('genreOrder : ' + genreOrder)
    if (window.confirm('장르 정렬값을 변경하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/booking/genre/order/${genreId}`, {order: genreOrder})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            getGenre()
            alert('정렬값이 변경되었습니다.')
          }
        })
        .catch((error) => {
          console.log(error)
        })
      setModalVisibleOrder(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>장르 태그</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            marginTop: 24,
            marginLeft: 24,
            borderRadius: 20,
          }}
        >
          <div className='tab-content' id='myTabContent'>
            <h1>장르태그 리스트</h1>

            {genreTag.map((item: any) => {
              return (
                <button type='button' className='btn btn-white' style={{border: '1px solid #DDD', marginTop: '5px', marginRight: '10px'}} key={item.id}>
                  {item.id}_{item.tag_name}
                </button>
              )
            })}
            <div style={{marginTop: '20px'}}>
              <label
                onClick={() => {
                  setModalVisible(true)
                }}
                className='btn btn-primary'
              >
                추가하기
              </label>
            </div>
          </div>

          <div className='separator my-10' />

          <div className='tab-content' id='myTabContent'>
            {genre.map((item: any) => {
              return (
                <div
                  style={{
                    marginTop: 30,
                    width: '100%',
                  }}
                  key={item.id.toString()}
                >
                  <h1>
                    {item.order}_{item.genre_name}
                  </h1>
                  <h3 style={{marginTop: '10px'}}>
                    순서: {item.order}, 노출: {item.show}
                  </h3>
                  <div style={{marginTop: '15px', marginBottom: '20px'}}>
                    <label
                      style={{marginRight: '20px'}}
                      onClick={() => {
                        setGenreId(item.id)
                        setGenreName(item.genre_name)
                        setModalVisibleTitle(true)
                      }}
                      className='btn btn-primary'
                    >
                      title 변경
                    </label>
                    <label
                      style={{marginRight: '20px'}}
                      onClick={() => {
                        setGenreId(item.id)
                        setGenreOrder(item.order)
                        setModalVisibleOrder(true)
                      }}
                      className='btn btn-primary'
                    >
                      순서 변경
                    </label>
                    {item.show === 0 && (
                      <label
                        onClick={() => {
                          updateGenreShow(item.id, 1)
                        }}
                        className='btn btn-primary'
                      >
                        노출
                      </label>
                    )}
                    {item.show === 1 && (
                      <label
                        onClick={() => {
                          updateGenreShow(item.id, 0)
                        }}
                        className='btn btn-primary'
                      >
                        숨기기
                      </label>
                    )}
                  </div>
                  {item.order !== 99 && (
                    <>
                      <h4 style={{marginTop: '20px'}}>추천 키워드 (상위 6개_순서 고정)</h4>
                      <textarea
                        className='form-control'
                        rows={3}
                        style={{width: '100%', border: '1px solid #DDD', resize: 'none'}}
                        defaultValue={item.recommend_keyword_high_rank}
                        onChange={(e) => {
                          item.recommend_keyword_high_rank = e.target.value
                        }}
                      ></textarea>
                      <div style={{marginTop: '15px', marginBottom: '30px'}}>
                        <label
                          onClick={() => {
                            updateGenreHighRank(item.id, item.recommend_keyword_high_rank, item.recommend_keyword_low_rank)
                          }}
                          className='btn btn-primary'
                        >
                          수정하기
                        </label>
                      </div>

                      <h4 style={{marginTop: '20px'}}>추천 키워드 (하위 9개_순서 랜덤)</h4>
                      <textarea
                        className='form-control'
                        rows={3}
                        style={{width: '100%', border: '1px solid #DDD', resize: 'none'}}
                        defaultValue={item.recommend_keyword_low_rank}
                        onChange={(e) => {
                          item.recommend_keyword_low_rank = e.target.value
                        }}
                      ></textarea>
                      <div style={{marginTop: '15px', marginBottom: '20px'}}>
                        <label
                          onClick={() => {
                            updateGenreLowRank(item.id, item.recommend_keyword_low_rank, item.recommend_keyword_high_rank, item.order)
                          }}
                          className='btn btn-primary'
                        >
                          수정하기
                        </label>
                      </div>

                      <h4 style={{marginTop: '20px'}}>제안 키워드</h4>
                      {item.genreKeywords.map((item: any) => {
                        return (
                          <button type='button' className='btn btn-light-dark' style={{border: '1px solid #DDD', marginTop: '5px', marginRight: '10px'}} key={item.id}>
                            {item.keyword_name} ({item.keyword_count})
                            <i
                              className='bi bi-x-lg'
                              style={{marginLeft: 10}}
                              key={item.id}
                              onClick={() => {
                                updateProjectKeyword(item.keyword_name)
                              }}
                            ></i>
                          </button>
                        )
                      })}
                      <div className='separator my-10' />
                    </>
                  )}

                  {item.order === 99 && (
                    <>
                      <h4 style={{marginTop: '20px'}}>추천 키워드 (하위 14개_순서 랜덤)</h4>
                      <textarea
                        className='form-control'
                        rows={3}
                        style={{width: '100%', border: '1px solid #DDD', resize: 'none'}}
                        defaultValue={item.recommend_keyword_low_rank}
                        onChange={(e) => {
                          item.recommend_keyword_low_rank = e.target.value
                        }}
                      ></textarea>
                      <div style={{marginTop: '15px', marginBottom: '20px'}}>
                        <label
                          onClick={() => {
                            updateGenreLowRank(item.id, item.recommend_keyword_low_rank, item.recommend_keyword_high_rank, item.order)
                          }}
                          className='btn btn-primary'
                        >
                          수정하기
                        </label>
                      </div>

                      <h4 style={{marginTop: '20px'}}>제안 키워드</h4>
                      {item.genreKeywords.map((item: any) => {
                        return (
                          <button type='button' className='btn btn-light-dark' style={{border: '1px solid #DDD', marginTop: '5px', marginRight: '10px'}} key={item.id}>
                            {item.keyword_name} ({item.keyword_count})
                            <i
                              className='bi bi-x-lg'
                              style={{marginLeft: 10}}
                              key={item.id}
                              onClick={() => {
                                updateProjectKeyword(item.keyword_name)
                              }}
                            ></i>
                          </button>
                        )
                      })}
                      <div className='separator my-10' />
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div>
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h2>장르태그 추가하기</h2>
              <br />
            </div>
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <input
                type='text'
                className='form-control'
                placeholder='추가할 장르태그를 입력해주세요.'
                onChange={(e) => {
                  checkTagName(e.target.value)
                }}
              />
            </div>
            <div
              style={{
                marginTop: 5,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{marginTop: '20px'}}>
                <label
                  onClick={() => {
                    addGenreTags()
                  }}
                  className={classNames('btn', {'btn-secondary': tagName.trim().length === 0}, {'btn-primary': tagName.trim().length !== 0})}
                >
                  추가하기
                </label>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modalVisibleTitle && (
        <Modal
          visible={modalVisibleTitle}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisibleTitle(false)
          }}
          className={''}
        >
          <div>
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h2>제목을 입력하세요.</h2>
              <br />
            </div>
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={genreName}
                onChange={(e) => {
                  setGenreName(e.target.value)
                }}
                maxLength={18}
              />
            </div>
            <div
              style={{
                marginTop: 5,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{marginTop: '20px'}}>
                <label
                  style={{marginRight: '10px'}}
                  onClick={() => {
                    setGenreId(0)
                    setGenreName('')
                    setModalVisibleTitle(false)
                  }}
                  className={classNames('btn', 'btn-secondary')}
                >
                  취소
                </label>
                <label
                  onClick={() => {
                    updateGenreName()
                  }}
                  className={classNames('btn', 'btn-primary')}
                >
                  확인
                </label>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modalVisibleOrder && (
        <Modal
          visible={modalVisibleOrder}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisibleOrder(false)
          }}
          className={''}
        >
          <div>
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h2>정렬값을 입력하세요.</h2>
              <br />
            </div>
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={genreOrder}
                onChange={(e) => {
                  setGenreOrder(Number(e.target.value))
                }}
              />
            </div>
            <div
              style={{
                marginTop: 5,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{marginTop: '20px'}}>
                <label
                  style={{marginRight: '10px'}}
                  onClick={() => {
                    setGenreId(0)
                    setGenreOrder(0)
                    setModalVisibleOrder(false)
                  }}
                  className={classNames('btn', 'btn-secondary')}
                >
                  취소
                </label>
                <label
                  onClick={() => {
                    updateGenreOrder()
                  }}
                  className={classNames('btn', 'btn-primary')}
                >
                  확인
                </label>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
