import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import moment from 'moment'
import {toast} from 'react-toastify'
import DatePicker from 'react-datepicker'
import {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export function PayHostReport4() {
  const [date, setDate] = useState<Date | null>(new Date())

  const [items, setItems] = useState<any>([])

  const [loading, setLoading] = useState(false)

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    registerLocale('ko', ko)

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getItems = useCallback(() => {
    setItems([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/pay/report/company?date=${moment(date).format('YYYY-MM')}`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          toast.error(error)
        } else {
          console.log(result)
          setItems([...result.cash.map((v: any) => ({...v, cash: true})), ...result.pay.map((v: any) => ({...v, cash: false}))])
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getItems()
  }, [getItems, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [
      {header: '캐시정산번호', key: 'id', width: 10},
      {header: '예약번호', key: 'id1', width: 10},
      {header: '거래일시', key: 'id2', width: 10},
      {header: '출금', key: 'id3', width: 10},
      {header: '계좌', key: 'id4', width: 10},
      {header: '은행', key: 'id5', width: 10},
      {header: '예금주명', key: 'id6', width: 10},
      {header: '정산유형', key: 'id7', width: 10},
      {header: '발급번호', key: 'id8', width: 10},
    ]

    items.forEach((item: any) => {
      worksheet.addRow({
        id: item.cash ? item.id : '',
        id1: item.booking_id > 0 ? item.booking_id : '',
        id2: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        id3: item.pay.toLocaleString('en'),
        id4: item.bank_account,
        id5: item.bank_name,
        id6: item.bank_owner,
        id7: item.type_text,
        id8: item.confirm_num,
      })
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `정산결산표(사업자)_${moment(date).format('YYYY-MM')}.xlsx`)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>지불 결산표(사업자)</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: 20,
            }}
          >
            <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM' showMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
          </div>
          <label
            onClick={() => {
              download()
            }}
            className='btn btn-sm btn-secondary'
          >
            다운로드
          </label>
        </div>

        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          {loading ? (
            <div
              style={{
                width: '100%',
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </div>
          ) : (
            <div className='tab-content' id='myTabContent'>
              <div className='table-responsive'>
                <table className='table table-striped gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                      <th className='min-w-100px'>캐시정산번호</th>
                      <th className='min-w-100px'>예약번호</th>
                      <th className='min-w-100px'>거래일시</th>
                      <th className='min-w-100px'>출금</th>
                      <th className='min-w-100px'>계좌</th>
                      <th className='min-w-100px'>은행</th>
                      <th className='min-w-100px'>예금주명</th>
                      <th className='min-w-100px'>정산유형</th>
                      <th className='min-w-100px'>발급번호</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item: any, index: number) => {
                      return [
                        <tr key={`${index}`}>
                          <td>{item.cash ? item.id : ''}</td>
                          <td>{item.booking_id > 0 ? item.booking_id : ''}</td>
                          <td>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>{item.pay.toLocaleString('en')}</td>
                          <td>{item.bank_account}</td>
                          <td>{item.bank_name}</td>
                          <td>{item.bank_owner}</td>
                          <td>{item.type_text}</td>
                          <td>{item.confirm_num}</td>
                        </tr>,
                      ]
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
