import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {PageTitle} from '../../../_metronic/layout/core'
import {Widget001} from '../components/Widget'
import {isMobile} from 'react-device-detect'

export function AdvertiseDashboard() {
  const [dashboard, setDashBoard] = useState<any>(null)

  useEffect(() => {
    getDashboard()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getDashboard = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/dashboard`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setDashBoard(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>광고 현황</PageTitle>
      <div style={{padding: isMobile ? 0 : 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: isMobile ? 0 : 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='row'>
            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>유상 광고 건수</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'총 광고 수'} count={dashboard.advCountAdvertiseBuyPriceCount} />
                      <Widget001 title={'카테고리 광고 수'} count={dashboard.advCountCategoryAdvertiseBuyPriceCount} />
                      <Widget001 title={'네트워크 광고 수'} count={dashboard.advCountNetworkAdvertiseBuyPriceCount} />
                      <Widget001 title={'네트워크 Plus 광고 수'} count={dashboard.advCountNetworkAdvertiseBuyPriceCountPlus} />
                      <Widget001 title={'네트워크 Pro 광고 수'} count={dashboard.advCountNetworkAdvertiseBuyPriceCountPro} />
                      <Widget001 title={'메인배너 광고 수'} count={dashboard.advCountMainBannerAdvertiseBuyPriceCount} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>무상 광고 건수</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'총 광고 수'} count={dashboard.advCountAdvertiseBuyPriceCountFree} />
                      <Widget001 title={'카테고리 광고 수'} count={dashboard.advCountCategoryAdvertiseBuyPriceCountFree} />
                      <Widget001 title={'네트워크 광고 수'} count={dashboard.advCountNetworkAdvertiseBuyPriceCountFree} />
                      <Widget001 title={'네트워크 Plus 광고 수'} count={dashboard.advCountNetworkAdvertiseBuyPriceCountPlusFree} />
                      <Widget001 title={'네트워크 Pro 광고 수'} count={dashboard.advCountNetworkAdvertiseBuyPriceCountProFree} />
                      <Widget001 title={'메인배너 광고 수'} count={dashboard.advCountMainBannerAdvertiseBuyPriceCountFree} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>광고 진행 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'진행 중인 광고 수'} count={dashboard.advAdvertiseIngCount} />
                      <Widget001 title={'진행 중인 카테고리 광고 수'} count={dashboard.advAdvertiseCategoryIngCount} />
                      <Widget001 title={'진행 중인 네트워크 광고 수'} count={dashboard.advAdvertiseNetworkIngCount} />
                      <Widget001 title={'진행 중인 네트워크 Plus 광고 수'} count={dashboard.advAdvertiseNetworkPlusIngCount} />
                      <Widget001 title={'진행 중인 네트워크 Pro 광고 수'} count={dashboard.advAdvertiseNetworkProIngCount} />
                      <Widget001 title={'진행 중인 메인배너 광고 수'} count={dashboard.advMainBannerAdvertiseIngCount} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>광고 결제 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'광고 구매 한 총 금액'} count={dashboard.advSumAdvertiseBuyPriceCount} />
                      <Widget001 title={'카테고리 광고 구매 한 총 금액'} count={dashboard.advSumCategoryAdvertiseBuyPriceCount} />
                      <Widget001 title={'네트워크 광고 구매 한 총 금액'} count={dashboard.advSumNetworkAdvertiseBuyPriceCount} />
                      <Widget001 title={'네트워크 광고 Plus 구매 한 총 금액'} count={dashboard.advSumNetworkAdvertiseBuyPriceCountPlus} />
                      <Widget001 title={'네트워크 광고 Pro 구매 한 총 금액'} count={dashboard.advSumNetworkAdvertiseBuyPriceCountPro} />
                      <Widget001 title={'메인배너 광고 구매 한 총 금액'} count={dashboard.advSumMainBannerAdvertiseBuyPriceCount} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>카테고리 상단 광고 구매 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <div style={{cursor: 'default'}}>
                        <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                          <table style={{fontSize: '15px', width: '100%'}}>
                            <colgroup>
                              <col style={{width: 120}} />
                              <col style={{width: 120}} />
                              <col style={{width: 100}} />
                              <col style={{width: 250}} />
                              <col style={{width: 100}} />
                              <col style={{width: 250}} />
                            </colgroup>
                            <tbody>
                              <tr>
                                <th>주차</th>
                                <th>카테고리</th>
                                <th style={{paddingRight: 20, textAlign: 'right'}}>신청 구좌 수</th>
                                <th style={{paddingRight: 20, textAlign: 'right'}}>예약 금액(VAT포함)</th>
                                <th style={{paddingRight: 20, textAlign: 'right'}}>결제 구좌 수</th>
                                <th style={{paddingRight: 20, textAlign: 'right'}}>결제 금액(VAT포함)</th>
                              </tr>
                              {dashboard?.weekBuyAdvertiseCategory.map((category: any) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{category.adv_start}</td>
                                      <td>{category.category_main}</td>
                                      <td style={{paddingRight: 20, textAlign: 'right'}}>{category.count}</td>
                                      <td
                                        style={{
                                          paddingRight: 20,
                                          textAlign: 'right',
                                        }}
                                      >
                                        {category.adv_price_sum.toLocaleString('en')}
                                      </td>
                                      <td
                                        style={{
                                          paddingRight: 20,
                                          textAlign: 'right',
                                        }}
                                      >
                                        {category.adv_paid_count}
                                      </td>
                                      <td
                                        style={{
                                          paddingRight: 20,
                                          textAlign: 'right',
                                        }}
                                      >
                                        {category.adv_paid_price_sum.toLocaleString('en')}
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>네트워크 광고 구매 현황</h3>
                {null != dashboard && (
                  <>
                    <div style={{cursor: 'default'}}>
                      <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{width: 120}} />
                            <col style={{width: 120}} />
                            <col style={{width: 100}} />
                            <col style={{width: 250}} />
                            <col style={{width: 100}} />
                            <col style={{width: 250}} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>주차</th>
                              <th>카테고리</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>신청 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>예약 금액(VAT포함)</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 금액(VAT포함)</th>
                            </tr>
                            {dashboard?.weekBuyAdvertiseNetwork.map((network: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{network.adv_start}</td>
                                    <td>{network.category_main}</td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{network.count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {network.adv_price_sum.toLocaleString('en')}
                                    </td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{network.adv_paid_count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {network.adv_paid_price_sum.toLocaleString('en')}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>네트워크 광고 Plus 구매 현황</h3>
                {null != dashboard && (
                  <>
                    <div style={{cursor: 'default'}}>
                      <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{width: 120}} />
                            <col style={{width: 120}} />
                            <col style={{width: 100}} />
                            <col style={{width: 250}} />
                            <col style={{width: 100}} />
                            <col style={{width: 250}} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>주차</th>
                              <th>카테고리</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>신청 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>예약 금액(VAT포함)</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 금액(VAT포함)</th>
                            </tr>
                            {dashboard?.weekBuyAdvertiseNetworkPlus.map((network: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{network.adv_start}</td>
                                    <td>{network.category_main}</td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{network.count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {network.adv_price_sum.toLocaleString('en')}
                                    </td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{network.adv_paid_count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {network.adv_paid_price_sum.toLocaleString('en')}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>네트워크 광고 Pro 구매 현황</h3>
                {null != dashboard && (
                  <>
                    <div style={{cursor: 'default'}}>
                      <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{width: 120}} />
                            <col style={{width: 120}} />
                            <col style={{width: 100}} />
                            <col style={{width: 250}} />
                            <col style={{width: 100}} />
                            <col style={{width: 250}} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>주차</th>
                              <th>카테고리</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>신청 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>예약 금액(VAT포함)</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 금액(VAT포함)</th>
                            </tr>
                            {dashboard?.weekBuyAdvertiseNetworkPro.map((network: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{network.adv_start}</td>
                                    <td>{network.category_main}</td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{network.count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {network.adv_price_sum.toLocaleString('en')}
                                    </td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{network.adv_paid_count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {network.adv_paid_price_sum.toLocaleString('en')}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>메인배너 광고 구매 현황</h3>
                {null != dashboard && (
                  <>
                    <div style={{cursor: 'default'}}>
                      <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{width: 200}} />
                            <col style={{width: 200}} />
                            <col style={{width: 200}} />
                            <col style={{width: 200}} />
                            <col style={{width: 200}} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>주차</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>신청 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>예약 금액(VAT포함)</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 구좌 수</th>
                              <th style={{paddingRight: 20, textAlign: 'right'}}>결제 금액(VAT포함)</th>
                            </tr>
                            {dashboard?.weekBuyAdvertiseMainBanner.map((mainBanner: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{mainBanner.adv_start}</td>
                                    <td style={{paddingRight: 20, textAlign: 'right'}}>{mainBanner.count}</td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {mainBanner.adv_price_sum.toLocaleString('en')}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {mainBanner.adv_paid_count}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: 20,
                                        textAlign: 'right',
                                      }}
                                    >
                                      {mainBanner.adv_paid_price_sum.toLocaleString('en')}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
