import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from "axios";
import {toast} from "react-toastify";

export function AdvertiseNetworkScheduleAdd() {

  const [registerStartDate, setRegisterStartDate] = useState('')
  const [advertiseStartDate, setAdvertiseStartDate] = useState('')
  const [advertiseStartWeekNotification, setAdvertiseStartWeekNotification] = useState('')
  const [advertiseRegisterDeadlineNotification, setAdvertiseRegisterDeadlineNotification] = useState('')
  const [googleFormUrl, setGoogleFormUrl] = useState('')
  const [googleUserIdCode, setGoogleUserIdCode] = useState('')
  const [googlePhoneCode, setGooglePhoneCode] = useState('')
  const [googleEmailCode, setGoogleEmailCode] = useState('')
  const [googlePlaceCode, setGooglePlaceCode] = useState('')

  useEffect(() => {

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const add = () => {
    console.log(registerStartDate)
    console.log(advertiseStartDate)
    console.log(advertiseStartWeekNotification)
    console.log(advertiseRegisterDeadlineNotification)
    console.log(googleFormUrl)
    console.log(googleUserIdCode)
    console.log(googlePhoneCode)
    console.log(googleEmailCode)
    if (registerStartDate.length === 8 && advertiseStartDate.length === 8) {
      if (window.confirm('네크워크 광고 일정 등록 하시겠습니까?')) {
        axios.post(`${process.env.REACT_APP_API2_URL}am/adv/network/schedule/add`, {
          registerStartDate, advertiseStartDate, advertiseStartWeekNotification, advertiseRegisterDeadlineNotification, googleFormUrl, googleUserIdCode, googlePhoneCode, googleEmailCode, googlePlaceCode
        }).then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error);
          } else {
            toast.info("등록 완료 되었습니다.");
           // window.location.href = `/booking/place/${result}`
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    } else {
      toast.error('정보를 확인해 주세요.')
    }

  }

  return <>
    <PageTitle breadcrumbs={[]}>네크워크 광고 일정</PageTitle>
    <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
      <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
          <div style={{marginTop: 10, paddingBottom: 10,}}>
            <div>
              <table style={{fontSize: '15px', width: '100%'}}>
                <colgroup>
                  <col style={{ width: '14%' }}/>
                  <col style={{ width: 'auto' }}/>
                </colgroup>
                <tbody>
                  <tr style={{fontSize: '15px'}}>
                    <td>접수 시작일</td>
                    <td>
                      <input type='text' className='form-control' placeholder='ex) 20990101' maxLength={8} value={registerStartDate} onChange={(e) => {setRegisterStartDate(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>광고 게재 시작일</td>
                    <td>
                      <input type='text' className='form-control' placeholder='ex) 20990101' maxLength={8} value={advertiseStartDate} onChange={(e) => {setAdvertiseStartDate(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>광고 시작 주차 공지</td>
                    <td>
                      <input type='text' className='form-control' placeholder='안내 페이지에 노출할 텍스트를 입력하세요. ex) 11/16(수) 주차' value={advertiseStartWeekNotification} onChange={(e) => {setAdvertiseStartWeekNotification(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>접수 마감일 공지</td>
                    <td>
                      <input type='text' className='form-control' placeholder='안내 페이지에 노출할 텍스트를 입력하세요. ex) 11/9(수) 17시' value={advertiseRegisterDeadlineNotification} onChange={(e) => {setAdvertiseRegisterDeadlineNotification(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>구글폼 URL</td>
                    <td>
                      <input type='text' className='form-control' placeholder='' value={googleFormUrl} onChange={(e) => {setGoogleFormUrl(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>구글 회원 ID Code</td>
                    <td>
                      <input type='text' className='form-control' placeholder='' value={googleUserIdCode} onChange={(e) => {setGoogleUserIdCode(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>구글 Phone Code</td>
                    <td>
                      <input type='text' className='form-control' placeholder='' value={googlePhoneCode} onChange={(e) => {setGooglePhoneCode(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>구글 Email Code</td>
                    <td>
                      <input type='text' className='form-control' placeholder='' value={googleEmailCode} onChange={(e) => {setGoogleEmailCode(e.target.value)}}/>
                    </td>
                  </tr>
                  <tr style={{fontSize: '15px'}}>
                    <td>구글 Place Code</td>
                    <td>
                      <input type='text' className='form-control' placeholder='' value={googlePlaceCode} onChange={(e) => {setGooglePlaceCode(e.target.value)}}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
            <label onClick={() => {
              add()
            }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>저장하기</label>
          </div>
        </div>
      </div>
    </div>
  </>
}

