import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {TextSub} from '../../components/TextSub'
import {apiGetAlimtalk} from '../../../apis/Alimtalk'

export function SettingAlimtalk() {

  const [items, setItems] = useState<any>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {

    getAlimtalk()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getAlimtalk = () => {
    setItems([])
    setLoading(true)

    apiGetAlimtalk().then(({error, result}) => {
      console.log(result)
      if (error === '') {
        setItems(result)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>알림톡</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <label className={'btn btn-primary'} onClick={() => {
        window.location.href = `/setting/alimtalk/editor`
      }}>
        템플릿 등록 하기
      </label>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        {
          loading ?
            <div style={{
              width: '100%',
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div>
              {
                items.map((item: any, index: number) => {
                  return (
                    <div className='col-12' key={`alimtalk_${index}`}>
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-header'>
                          <h1 className='card-title'>
                            {item.id}. {item.comment}
                          </h1>
                        </div>
                        <div className='card-body'>
                          {item.title !== '' &&
                            <>
                              <TextSub title={'강조타이틀'} subTitle={item.title} />
                            </>
                          }
                          <h4>{item.code} [{item.type}]</h4>
                          <br />
                          <h4 style={{whiteSpace: 'pre-wrap', border: '1px solid #CCC', padding: 20, width: 'fit-content'}}>
                            {item.template}
                          </h4>

                          {
                            item.button_title !== '' &&
                            <>
                              <h3>버튼1</h3>
                              <TextSub title={'타이틀'} subTitle={item.button_title} />
                              <TextSub title={'모바일'} subTitle={item.button_mobile} />
                              <TextSub title={'아이폰'} subTitle={item.button_ios} />
                              <TextSub title={'안드로이드'} subTitle={item.button_android} />
                            </>
                          }

                          {
                            item.button2_title !== '' &&
                            <>
                              <h3>버튼2</h3>
                              <TextSub title={'타이틀'} subTitle={item.button2_title} />
                              <TextSub title={'모바일'} subTitle={item.button2_mobile} />
                              <TextSub title={'아이폰'} subTitle={item.button2_ios} />
                              <TextSub title={'안드로이드'} subTitle={item.button2_android} />
                            </>
                          }
                        </div>
                        {/*<div className='card-footer'>*/}
                        {/*  <label onClick={() => {*/}
                        {/*    window.open(`/booking/place/${item.booking_id}`)*/}
                        {/*  }} className='btn btn-primary'>예약 : {item.booking_id}</label>*/}
                        {/*  <label onClick={() => {*/}
                        {/*    window.open(`/booking/message/${item.booking_id}`)*/}
                        {/*  }} style={{marginLeft: 20}} className='btn btn-primary'>메시지 보기</label>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>

    </div>
  </>
}

