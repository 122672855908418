import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import moment from 'moment/moment'

export function AdvertiseCategoryIsFreeAdd() {
  const {adv_start} = useParams<{adv_start: string}>()
  const {adv_end} = useParams<{adv_end: string}>()
  const [hostId, setHostId] = useState('')
  const [placeId, setPlaceId] = useState('')

  useEffect(() => {
    //getSchedule()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const add = () => {
    if (hostId === '' || Number(hostId) < 1) {
      toast.error('호스트 ID를 확인해 주세요.')
      return
    }

    if (placeId === '' || Number(placeId) < 1) {
      toast.error('장소 ID를 확인해 주세요.')
      return
    }

    if (adv_start === '' || adv_end === '') {
      toast.error('광고 게쟤 기간을 확인해주세요.')
      return
    }

    console.log(hostId)
    console.log(placeId)
    console.log(adv_start)
    console.log(adv_end)

    let str = '등록 하시겠습니까?'

    if (window.confirm(str)) {
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/category/ate/add`, {
          user_id: hostId,
          place_id: placeId,
          adv_start: adv_start,
          adv_end: adv_end,
        })
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.href = `/advertise/category/place`
            //window.close()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>카테고리 상단 광고 무료 등록</PageTitle>
      <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
        <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div style={{marginTop: 10, padding: 10, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
            <div style={{marginTop: 10, paddingBottom: 10}}>
              <div>
                <table style={{fontSize: '15px', width: '100%', lineHeight: 2}}>
                  <colgroup>
                    <col style={{width: '14%'}} />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr style={{fontSize: '15px'}}>
                      <td>무료 광고 일정</td>
                      <td>
                        {moment(adv_start, 'YYYYMMDD').format('YYYY-MM-DD')} ~ {moment(adv_end, 'YYYYMMDD').format('YYYY-MM-DD')}
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>호스트 번호</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='호스트 ID를 입력하세요.'
                          maxLength={15}
                          value={hostId}
                          onChange={(e) => {
                            setHostId(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{fontSize: '15px'}}>
                      <td>장소 번호</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='장소 ID를 입력하세요.'
                          maxLength={15}
                          value={placeId}
                          onChange={(e) => {
                            setPlaceId(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <label
                onClick={() => {
                  add()
                }}
                style={{marginLeft: 10}}
                className='btn btn-sm btn-primary'
              >
                등록하기
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
