import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import {Button} from 'react-bootstrap'
import {toast} from 'react-toastify'
import moment from 'moment'

export function BookingPlaceTeamPayment() {
  const {booking_id} = useParams<{booking_id: string}>()
  const {team_id} = useParams<{team_id: string}>()
  const [bookingInfo, setBookingInfo] = useState<any>(null)

  const [cardNo, setCardNo] = useState('')
  const [cardExpiry, setCardExpiry] = useState('')
  const [cardTaxno, setCardTaxno] = useState('')

  useEffect(() => {
    getBookingInfo()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const payment = () => {
    if (cardNo.length === 0 || cardExpiry.length === 0 || cardTaxno.length === 0) {
      toast.error('필수 입력값을 입력해주세요.')
      return false
    }

    if (window.confirm('팀 수기 결제 완료 처리하시겠습니까?')) {
      console.log(team_id)
      const password = window.prompt('비밀번호를 적어주세요.', '')
      if (password === 'hour1234$$') {
        axios
          .post(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/team/${team_id}/payment/toss`, {
            cardNo: cardNo,
            cardExpiry: cardExpiry,
            cardTaxno: cardTaxno,
            // "cardPwd": cardPwd,
          })
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              window.location.href = `/booking/place/${booking_id}`
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const getBookingInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          setBookingInfo(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>팀 수기 결제 처리</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='col-12'>
            <div className='card card-custom card-stretch shadow mb-5'>
              <div className='card-header'>{bookingInfo !== null && <h1 className='card-title'>예약 번호 : {bookingInfo.booking.id}</h1>}</div>
              <div className='card-body'>
                {bookingInfo !== null && (
                  <>
                    <TextSub title={'장소 번호'} subTitle={bookingInfo.place.id} />
                    <TextSub title={'장소 제목'} subTitle={bookingInfo.place.title} />
                    <TextSub
                      title={'촬영 스케줄'}
                      subTitle={`${moment(bookingInfo.booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(bookingInfo.booking.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}`}
                    />
                    <TextSub title={'결제 금액'} subTitle={bookingInfo.booking.price_guest_vat} />
                  </>
                )}

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div>
                    <TextSub title={'카드번호'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={'카드 번호를 입력하세요'}
                    onChange={(e) => {
                      setCardNo(e.target.value)
                    }}
                  />
                </div>

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div>
                    <TextSub title={'유효기간'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={'MM/YY'}
                    onChange={(e) => {
                      setCardExpiry(e.target.value)
                    }}
                  />
                </div>

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div>
                    <TextSub title={'생년월일 6 자리\n사업자번호 10자리'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={'생년월일 또는 사업자번호'}
                    onChange={(e) => {
                      setCardTaxno(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  variant='danger'
                  onClick={() => {
                    payment()
                  }}
                >
                  수기 결제 처리
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
