import React from 'react'
import {Button} from 'react-bootstrap'
import axios from 'axios'
import {PageTitle} from '../../../../../_metronic/layout/core'
import ImagePath from '../../../../commons/ImagePath'

export default class RecommendCategory extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      recommendCategories: [],
    }
    this.getRecommendCategories()
  }

  getRecommendCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/recommend/category`)
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
        } else {
          this.setState({
            recommendCategories: result,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <>
        <PageTitle breadcrumbs={[]}>홈 프로모션</PageTitle>

        <div className='row'>
          <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
            <div>
              <Button className='btn btn-outline-secondary' href='/marketing/recommend/category/0/editor'>
                추가
              </Button>
              <br />
              <br />
              <div className={'border p-4'}>
                <div>
                  <div className={'p-5'} style={{}}>
                    <h4>APP</h4>
                    <div
                      style={{
                        width: 320,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                      }}
                    >
                      {this.state.recommendCategories
                        .filter((recommendCategory) => recommendCategory.show_app === 1 && recommendCategory.main_category_id > 0)
                        .slice(0, 4)
                        .map((recommendCategory, index) => {
                          return (
                            <div
                              key={index.toString()}
                              style={{
                                aspectRatio: `${77.75 / 132}`,
                                flex: 1,
                                borderRadius: 8,
                                overflow: 'hidden',
                              }}
                            >
                              <img src={recommendCategory.main_category_m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                              {/*<h1 style={{marginTop: -20}}>{recommendCategory.main_category_id}</h1>*/}
                            </div>
                          )
                        })}
                    </div>
                  </div>

                  <div className={'p-5'} style={{}}>
                    <h4>MOBILE</h4>
                    <div
                      style={{
                        width: 320,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                      }}
                    >
                      {this.state.recommendCategories
                        .filter((recommendCategory) => recommendCategory.show_mobile === 1 && recommendCategory.main_category_id > 0)
                        .slice(0, 4)
                        .map((recommendCategory, index) => {
                          return (
                            <div
                              key={index.toString()}
                              style={{
                                aspectRatio: `${77.75 / 132}`,
                                flex: 1,
                                borderRadius: 8,
                                overflow: 'hidden',
                              }}
                            >
                              <img src={recommendCategory.main_category_m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                            </div>
                          )
                        })}
                    </div>
                  </div>

                  <div className={'p-5'} style={{}}>
                    <h4>PC</h4>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 8,
                      }}
                    >
                      {this.state.recommendCategories
                        .filter((recommendCategory) => recommendCategory.show_pc === 1 && recommendCategory.main_category_id > 0)
                        .slice(0, 4)
                        .map((recommendCategory, index) => {
                          return (
                            <div
                              key={index.toString()}
                              style={{
                                aspectRatio: `${275 / 80}`,
                                flex: 1,
                                borderRadius: 8,
                                overflow: 'hidden',
                              }}
                            >
                              <img src={recommendCategory.main_category_w_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {this.state.recommendCategories.map((recommendCategory, index) => {
                  return (
                    <div className='col-6' key={index.toString()}>
                      <div className='card card-custom card-stretch shadow m-4'>
                        <div className='card-header'>
                          <h1 className='card-title'>
                            {recommendCategory.category_main_id > 0 && `${recommendCategory.category_main_id}. 카테고리 메인`}
                            {recommendCategory.category_sub_id > 0 && `${recommendCategory.category_sub_id}. 카테고리 서브`}
                            {recommendCategory.main_category_id > 0 && `${recommendCategory.main_category_id}. 큐레이션`}
                            {' - '}
                            {recommendCategory.category_main_id > 0 && recommendCategory.category_main_title}
                            {recommendCategory.category_sub_id > 0 && recommendCategory.category_sub_title}
                            {recommendCategory.main_category_id > 0 && recommendCategory.main_category_title}
                          </h1>
                        </div>
                        <div className='card-body'>
                          <h3>카드</h3>
                          <h5>
                            순서 : {recommendCategory.order}
                            <br />
                            노출 : APP - {recommendCategory.show_app}, PC - {recommendCategory.show_pc}, MOBILE - {recommendCategory.show_mobile}
                            <br />
                            제목 : APP - {recommendCategory.app_title}, PC - {recommendCategory.pc_title}, MOBILE - {recommendCategory.mobile_title}
                            <br />
                            썸네일 :{' '}
                            {recommendCategory.image_path !== '' && (
                              <img
                                src={ImagePath.get(recommendCategory.image_path, '150x150')}
                                style={{
                                  width: 54,
                                  height: 54,
                                  borderRadius: 12,
                                  objectFit: 'cover',
                                }}
                                alt=''
                              />
                            )}
                            {recommendCategory.main_category_id > 0 && (
                              <div>
                                <br />
                                <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                                  <div style={{flex: 3}}>
                                    <h3>Mobile</h3>
                                    <div style={{position: 'relative', width: '100%', aspectRatio: `${77.75 / 132}`, marginTop: 20}}>
                                      <img src={recommendCategory.main_category_m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                                    </div>
                                  </div>

                                  <div style={{flex: 7}}>
                                    <h3>PC</h3>
                                    <div style={{position: 'relative', width: '100%', aspectRatio: `${275 / 80}`, marginTop: 20}}>
                                      <img src={recommendCategory.main_category_w_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </h5>
                          <br />
                          {/* <h3>상세</h3> */}
                          {/* <h5> */}
                          {/*  배경이미지 : {recommendCategory.detail_image_path} */}
                          {/*  {recommendCategory.detail_image_path !== '' && ( */}
                          {/*    <img */}
                          {/*      src={`//img.hourplace.co.kr/${recommendCategory.detail_image_path}?s=1000x1000&t=inside&q=80`} */}
                          {/*      style={{ */}
                          {/*        width: 200, */}
                          {/*        height: 200, */}
                          {/*        objectFit: 'contain', */}
                          {/*      }} */}
                          {/*      alt="" */}
                          {/*    /> */}
                          {/*  )} */}
                          {/*  <br /> */}
                          {/*  배경색 : {recommendCategory.background_color} */}
                          {/*  <div */}
                          {/*    style={{ */}
                          {/*      width: 30, */}
                          {/*      height: 30, */}
                          {/*      backgroundColor: */}
                          {/*        recommendCategory.background_color, */}
                          {/*    }} */}
                          {/*  /> */}
                          {/*  {recommendCategory.detail_image_path !== '' && ( */}
                          {/*    <img */}
                          {/*      src={`//img.hourplace.co.kr/${recommendCategory.detail_image_path}?s=500x500&t=cover&q=80`} */}
                          {/*      style={{ */}
                          {/*        width: 200, */}
                          {/*        height: 200, */}
                          {/*        objectFit: 'contain', */}
                          {/*      }} */}
                          {/*      alt="" */}
                          {/*    /> */}
                          {/*  )} */}
                          {/*  <br /> */}
                          {/*  제목 : {recommendCategory.title} */}
                          {/*  <br /> */}
                          {/*  설명 : {recommendCategory.sub_title} */}
                          {/* </h5> */}
                        </div>
                        <div className='card-footer'>
                          <Button className='btn btn-outline-secondary' href={`/marketing/recommend/category/${recommendCategory.id}/editor`}>
                            수정
                          </Button>
                          {recommendCategory.main_category_id > 0 && (
                            <Button className='btn btn-outline-secondary' style={{marginLeft: 10}} href={`/marketing/curation/${recommendCategory.main_category_id}`}>
                              큐레이션 수정
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
