import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import classNames from 'classnames'
import axios from 'axios'
import moment from 'moment'
import {TextSub} from '../../components/TextSub'
// @ts-ignore
import WindowOpener from 'react-window-opener'
import {toast} from 'react-toastify'
import copy from 'copy-to-clipboard'

export function PayHost() {
  const [searchValues, setSearchValues] = useState({
    status: '',
    currentPage: 0,
    refresh: 0,
    search: '',
  })

  const [pays, setPays] = useState<any>([])
  const [pay0, setPay0] = useState(0)
  const [pay1, setPay1] = useState(0)
  const [pay19, setPay19] = useState(0)
  const [pay12, setPay12] = useState(0)
  const [pay2, setPay2] = useState(0)
  const [pay99, setPay99] = useState(0)

  const [total, setTotal] = useState(0)

  const [search1, setSearch1] = useState('')
  const [search2, setSearch2] = useState('')

  const [paidPayHostLoading, setPaidPayHostLoading] = useState(false)

  const [loading, setLoading] = useState(false)

  const [bottom, setBottom] = useState(false)
  const [moreLoading, setMoreLoading] = useState(false)

  const [paidLoading, setPaidLoading] = useState(false)

  const [tempPay, setTempPay] = useState<any | null>(null)
  const [cashChangeLoading, setCashChangeLoading] = useState(false)

  useEffect(() => {
    onTab('0')
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setSearchValues({
      ...searchValues,
      status: tabStatus,
      currentPage: 0,
      refresh: searchValues.refresh + 1,
    })
  }

  const onSearch1 = () => {
    if (search1 !== '') {
      setSearchValues({
        ...searchValues,
        search: `bookingId=${search1}`,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    } else {
      setSearchValues({
        ...searchValues,
        search: '',
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  const onSearch2 = () => {
    if (search2 !== '') {
      setSearchValues({
        ...searchValues,
        search: `hostUserId=${search2}`,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    } else {
      setSearchValues({
        ...searchValues,
        search: '',
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  useEffect(() => {
    if (bottom) more()
  }, [bottom]) // eslint-disable-line react-hooks/exhaustive-deps

  const more = () => {
    if (pays.length < total) {
      setSearchValues({
        ...searchValues,
        currentPage: searchValues.currentPage + 1,
      })
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    const searchPage = searchValues.currentPage + 1
    searchPage < 2 ? setLoading(true) : setMoreLoading(true)
    const url =
      searchValues.search === ''
        ? `${process.env.REACT_APP_API2_URL}am/pay/host?status=${searchValues.status}&page=${searchPage}&search=${searchValues.search}`
        : `${process.env.REACT_APP_API2_URL}am/pay/host/search?status=${searchValues.status}&page=${searchPage}&${searchValues.search}`
    axios
      .get(url, {cancelToken: source.token})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPay0(result.pay0)
          setPay1(result.pay1)
          setPay19(result.pay19)
          setPay12(result.pay12)
          setPay2(result.pay2)
          setPay99(result.pay99)
          const lists = result.pays.data
          lists.forEach((pay: any) => {
            pay.pay_status = parseInt(pay.pay_status)
            pay.status_code = parseInt(pay.status_code)
            pay.checked = false
          })
          if (result.pays.current_page === 1) {
            setPays(lists)
          } else {
            setPays([...pays, ...lists])
          }
          setTotal(result.pays.total)
        }
        setBottom(false)
        setLoading(false)
        setMoreLoading(false)
      })
      .catch((error) => {
        console.log(error)
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          setBottom(false)
          setLoading(false)
          setMoreLoading(false)
        }
      })
    return () => source.cancel(`${searchValues.status} ${searchValues.currentPage} ${searchValues.search} ${searchValues.refresh}`)
  }, [searchValues])

  useEffect(() => {
    const source = axios.CancelToken.source()
    const url =
      searchValues.search === ''
        ? `${process.env.REACT_APP_API2_URL}am/pay/host?status=${searchValues.status}&page=1&search=${searchValues.search}`
        : `${process.env.REACT_APP_API2_URL}am/pay/host/search?status=${searchValues.status}&page=1&${searchValues.search}`
    if (searchValues.currentPage === 0) {
      axios
        .get(url, {cancelToken: source.token})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            setPay0(result.pay0)
            setPay1(result.pay1)
            setPay19(result.pay19)
            setPay12(result.pay12)
            setPay2(result.pay2)
            setPay99(result.pay99)
            setTotal(result.pays.total)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    return () => source.cancel(`${searchValues.status} ${searchValues.search} ${searchValues.refresh}`)
  }, [searchValues])

  const paidPayHost = (pay: any) => {
    if (paidPayHostLoading === false) {
      if (window.confirm('입금 처리 하시겠습니까?')) {
        setPaidPayHostLoading(true)
        axios
          .post(`${process.env.REACT_APP_API2_URL}am/deposit`, {pay_id: pay.pay_id})
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
              toast.error(error)
            }

            const items = [...pays]
            items.splice(pays.indexOf(pay), 1)
            setPays(items)

            setSearchValues({
              ...searchValues,
              currentPage: 0,
              refresh: searchValues.refresh + 1,
            })

            setPaidPayHostLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setPaidPayHostLoading(false)
          })
      }
    }
  }

  const delayPayHost = (pay: any) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}admin/pay/booking/${pay.id}/delay`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          const items = [...pays]
          if (pay.account !== undefined) {
            result.account = pay.account
          }
          result.type_text = pay.type_text
          result.pay_id = pay.pay_id
          if (pay.account_no !== undefined) {
            result.account_no = pay.account_no
            result.account_name = pay.account_name
          }
          items.splice(pays.indexOf(pay), 1, result)
          setPays(items)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (tempPay && cashChangeLoading) {
      if (window.confirm('캐시 적립 처리하시겠습니까?')) {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/pay/booking/${tempPay.id}/cash/update`)
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
              toast.error(error)
            } else {
              const items = [...pays]
              items.splice(pays.indexOf(tempPay), 1)
              setPays(items)
              toast.info('처리하였습니다.')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            setCashChangeLoading(false)
          })
      } else {
        setCashChangeLoading(false)
      }
    }
  }, [cashChangeLoading])
  const cashChange = (pay: any) => {
    setTempPay(pay)
    setCashChangeLoading(true)
  }

  const childResponse = (err: any, res: any) => {
    if (err) {
      console.log(err, 'err')
      // setstate(res)
    } else {
      console.log(res)
      setSearchValues({
        ...searchValues,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  const onCopy = (pay: any) => {
    let text = ''

    if (pay.type2 === 'A') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

입금 전, 원천세(3.3%) 신고를 위하여 

1) 신분증 사본과 
2) 계좌사본을 
아워플레이스에 업로드 부탁 드립니다

*업로드후 톡으로 회신주시면 빠른 입금 진행이 가능합니다
*경로 : 앱 > 대시보드 > 수입 관리 > 계좌정보

hourplace.co.kr`
    } else if (pay.type2 === 'B' || pay.type2 === 'C') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 세금계산서 발급 부탁드립니다

1) 예약번호 : ${pay.id}
- 품목에 예약번호를 꼭 넣어 주세요
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
- 계산서 작성일자는 오늘 날짜로 진행 부탁드립니다
3) 정산 금액
- 공급가액 : ${(Number(pay.pay_pay) - Math.floor(Number(pay.pay_pay) / 11)).toLocaleString('en')}원
- 부가세 : ${Math.floor(Number(pay.pay_pay) / 11).toLocaleString('en')}원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 메일 주소 : team@munch-factory.com

빠른 처리를 위해 위 이메일 입력을 꼭 부탁드리며, 발급후 톡으로 회신주시면 빠른 입금 진행이 가능합니다`
    } else if (pay.type2 === 'D') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 전자계산서 발급 부탁드립니다

1) 예약번호 : ${pay.id}
- 품목에 예약번호를 꼭 넣어 주세요
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
- 계산서 작성일자는 오늘 날짜로 진행 부탁드립니다
3) 정산 금액
- 공급가액 : ${Number(pay.pay_pay).toLocaleString('en')}원
- 부가세 : 0원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 메일 주소 : team@munch-factory.com

빠른 처리를 위해 위 이메일 입력을 꼭 부탁드리며, 발급후 톡으로 회신주시면 빠른 입금 진행이 가능합니다`
    } else if (pay.type2 === 'E') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 세금계산서 발급 부탁드립니다

1) 예약번호 : ${pay.id}
- 품목에 예약번호를 꼭 넣어 주세요
2) 예약일 :${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
- 계산서 작성일자는 오늘 날짜로 진행 부탁드립니다
3) 호스트 발급 금액 : ${Number(pay.price_guest_vat).toLocaleString('en')}원(VAT포함)
4) 아워플레이스 발급 금액 : ${(Number(pay.price_guest_vat) - Number(pay.pay_pay)).toLocaleString('en')}(VAT포함)
5) 실제 입금되는 금액 : ${Number(pay.pay_pay).toLocaleString('en')}원(VAT포함)
6) 사업자등록번호 : 494-81-00558
7) 메일 주소 : team@munch-factory.com
빠른 처리를 위해 위 이메일 입력을 꼭 부탁드리며, 발급후 톡으로 회신주시면 빠른 입금 진행이 가능합니다`
    } else if (pay.type2 === 'F') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 현금영수증 발급 부탁드립니다

1) 예약번호 : ${pay.id}
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
3) 정산 금액
- 공급가액 : ${(Number(pay.pay_pay) - Math.floor(Number(pay.pay_pay) / 11)).toLocaleString('en')}원
- 부가세 : ${Math.floor(Number(pay.pay_pay) / 11).toLocaleString('en')}원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 지출증빙으로 부탁드립니다

빠른 처리를 위해 발급후 꼭 톡으로 현금영수증 캡쳐본 회신 부탁드립니다`
    } else if (pay.type2 === 'G') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 현금영수증 발급 부탁드립니다

1) 예약번호 : ${pay.id}
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
3) 정산 금액
- 공급가액 : ${Number(pay.pay_pay).toLocaleString('en')}원
- 부가세 : 0원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 지출증빙으로 부탁드립니다

빠른 처리를 위해 발급후 꼭 톡으로 현금영수증 캡쳐본 회신 부탁드립니다`
    } else if (pay.type2 === 'H') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 현금영수증 발급 부탁드립니다

1) 예약번호 : ${pay.id}
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
3) 정산 금액
- 공급가액 : ${(Number(pay.pay_pay) - Math.floor(Number(pay.pay_pay) / 11)).toLocaleString('en')}원
- 부가세 : ${Math.floor(Number(pay.pay_pay) / 11).toLocaleString('en')}원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 지출증빙으로 부탁드립니다

빠른 처리를 위해 발급후 꼭 톡으로 현금영수증 캡쳐본 회신 부탁드립니다`
    } else if (pay.type2 === 'I') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 현금영수증 발급 부탁드립니다

1) 예약번호 : ${pay.id}
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
3) 정산 금액
- 공급가액 : ${Number(pay.pay_pay).toLocaleString('en')}원
- 부가세 : 0원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 지출증빙으로 부탁드립니다

빠른 처리를 위해 발급후 꼭 톡으로 현금영수증 캡쳐본 회신 부탁드립니다`
    } else if (pay.type2 === 'J') {
      text = `호스트님 안녕하세요 😀
아워플레이스입니다

아래 금액으로 세금계산서 발급 부탁드립니다

1) 예약번호 : ${pay.id}
- 품목에 예약번호를 꼭 넣어 주세요
2) 예약일 : ${moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY년 MM월 DD일')}
- 계산서 작성일자는 오늘 날짜로 진행 부탁드립니다
3) 정산 금액
- 공급가액 : ${(Number(pay.pay_pay) - Math.floor(Number(pay.pay_pay) / 11)).toLocaleString('en')}원
- 부가세 : ${Math.floor(Number(pay.pay_pay) / 11).toLocaleString('en')}원
- 총액 : ${Number(pay.pay_pay).toLocaleString('en')}원
4) 사업자등록번호 : 494-81-00558
5) 메일 주소 : team@munch-factory.com

빠른 처리를 위해 위 이메일 입력을 꼭 부탁드리며, 발급후 톡으로 회신주시면 빠른 입금 진행이 가능합니다`
    }
    if (text !== '') {
      copy(text)
      toast('복사하였습니다.')
    }
  }

  const updateMemoPay = (pay: any) => {
    if (window.confirm('입력 하시겠습니까?')) {
      const memo = window.prompt('코멘트를 적어주세요.', '')
      console.log('memo : ' + memo)
      if (memo === null) {
        return false
      }
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/pay/host/memo/pay/${pay.pay_id}`, {memo})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            const items = [...pays]
            pay.memo_pay = memo
            items.splice(pays.indexOf(pay), 1, pay)
            setPays(items)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const updatePayMemo = (pay: any) => {
    if (window.confirm('발급번호를 입력 하시겠습니까?')) {
      const memo = window.prompt('발급번호를 적어주세요.', '')
      console.log('memo : ' + memo)
      if (memo === null) {
        return false
      }
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/pay/host/memo2/${pay.pay_id}`, {memo})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            const items = [...pays]
            pay.pay_memo2 = memo
            items.splice(pays.indexOf(pay), 1, pay)
            setPays(items)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const listenScrollEvent = () => {
    if ((document.getElementById('root')?.offsetHeight ?? 0) - window.scrollY <= 2000) {
      setBottom(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  const isCheckedPrice = () => {
    const checked = pays.filter((pay: any) => pay.checked)
    let total = 0
    checked.forEach((pay: any) => {
      total += Number(pay.pay_pay)
    })
    return total.toLocaleString('en')
  }

  const isChecked = () => {
    return pays.filter((pay: any) => pay.checked).length
  }

  const paidPayHostFromChecked = () => {
    if (!paidLoading) {
      setPaidLoading(true)
      if (window.confirm(`${isChecked()}건 ${isCheckedPrice()}원 입금 처리 하시겠습니까?`)) {
        const checkedPays = pays.filter((pay: any) => pay.checked)
        paidPayHosts(checkedPays)
      } else {
        setPaidLoading(false)
      }
    }
  }

  const paidPayHosts = (checkedPays: any) => {
    console.log(`pays ${checkedPays}`)
    const res = checkedPays.map((pay: any) => pay.pay_id)

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/multi/deposit`, {pay_ids: res})
      .then(({data: {error, result}}) => {
        console.log(`am/multi/deposit ${error}`)
        console.log(`am/multi/deposit ${result}`)

        if (error !== '') {
          toast.error(error)
        }

        const items = [...pays]
        checkedPays.forEach((pay: any) => {
          items.some((item: any) => {
            if (item.id === pay.id) {
              items.splice(items.indexOf(item), 1)
              return true
            }
            return false
          })
        })
        setPays(items)

        setPaidLoading(false)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error)
        setPaidLoading(false)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>호스트 정산</PageTitle>
      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            width: 'max-content',
          }}
        >
          <label
            onClick={() => {
              onTab('')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === ''}, {'btn-btn-white': searchValues.status !== ''})}
          >
            모두보기
          </label>

          <label
            onClick={() => {
              onTab('0')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === '0'}, {'btn-btn-white': searchValues.status !== '0'})}
          >
            정산정보입력 ({pay0})
          </label>

          <label
            onClick={() => {
              onTab('1')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === '1'}, {'btn-btn-white': searchValues.status !== '1'})}
          >
            정산대기 ({pay1})
          </label>

          <label
            onClick={() => {
              onTab('19')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === '19'}, {'btn-btn-white': searchValues.status !== '19'})}
          >
            정산확인 ({pay19})
          </label>

          <label
            onClick={() => {
              onTab('12')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === '12'}, {'btn-btn-white': searchValues.status !== '12'})}
          >
            정산중 ({pay12})
          </label>

          <label
            onClick={() => {
              onTab('2')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === '2'}, {'btn-btn-white': searchValues.status !== '2'})}
          >
            정산완료 ({pay2})
          </label>

          <label
            onClick={() => {
              onTab('99')
            }}
            className={classNames('btn', {'btn-dark': searchValues.status === '99'}, {'btn-btn-white': searchValues.status !== '99'})}
          >
            추후정산 ({pay99})
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              marginTop: 20,
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <input
              type='text'
              className='form-control'
              placeholder='예약 번호'
              value={search1}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                setSearch1(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch1()
                }
              }}
            />
            <label
              onClick={() => {
                onSearch1()
              }}
              style={{
                width: 100,
              }}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>
          <div
            style={{
              width: 40,
            }}
          ></div>
          <div
            style={{
              marginTop: 20,
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <input
              type='text'
              className='form-control'
              placeholder='호스트 사용자 번호'
              value={search2}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                setSearch2(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch2()
                }
              }}
            />
            <label
              onClick={() => {
                onSearch2()
              }}
              style={{
                width: 100,
              }}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            width: '100%',
            height: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span className='indicator-progress' style={{display: 'block'}}>
            Loading...
            <span className='spinner-border spinner-border-sm align-middle ms-2' />
          </span>
        </div>
      ) : (
        <div
          style={{
            marginTop: 24,
            borderRadius: 20,
          }}
        >
          <div className='tab-content' id='myTabContent'>
            {pays.map((pay: any, index: number) => {
              return (
                <div
                  key={index.toString()}
                  style={{
                    marginBottom: 24,
                    padding: 40,
                    borderRadius: 20,
                    backgroundColor:
                      pay.pay_status !== 2 &&
                      (pay.last_pays === undefined ||
                        pay.last_pays === null ||
                        pay.account === null ||
                        pay.pay_bank_account !== pay.account.bank_account ||
                        pay.last_pays.pay_bank_account !== pay.account.bank_account ||
                        pay.last_pays.pay_bank_name !== pay.account.bank_name ||
                        pay.last_pays.pay_bank_owner !== pay.account.bank_owner)
                        ? pay.account !== null && pay.pay_bank_account !== '' && pay.pay_bank_account !== pay.account.bank_account
                          ? 'rgba(0,255,0,0.4)'
                          : 'rgba(255,0,0,0.4)'
                        : 'white',
                    position: 'relative',
                  }}
                >
                  <h1
                    style={{
                      marginBottom: 32,
                    }}
                  >
                    {pay.pay_status === 0 ? '호스트 정산 정보 입력' : ''}
                    {pay.pay_status === 1 ? (pay.pay_bank_transfer_id !== 0 ? '정산중' : '정산대기') : ''}
                    {pay.pay_status === 2 ? '정산완료' : ''}
                    {pay.pay_status === 99 ? '추후정산' : ''}
                    {pay.is_pass_money === 1 && pay.pay_status !== 2 ? '[파손 비용 청구]' : ''}
                  </h1>

                  {pay.pay_status === 1 && pay.pay_bank_transfer_id === 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 40,
                        right: 40,
                      }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='layout-builder[layout][header][fixed][desktop]'
                        checked={pay.checked}
                        onChange={(event) => {
                          if (!paidLoading) {
                            const temps = [...pays]
                            temps.splice(temps.indexOf(pay), 1, pay)
                            pay.checked = event.target.checked
                            setPays(temps)
                          }
                        }}
                      />
                    </div>
                  )}

                  <TextSub title={'예약번호'} subTitle={pay.id} popUrl={`/booking/place/${pay.id}`} />

                  <TextSub title={'촬영일'} subTitle={moment(pay.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} />

                  <TextSub title={'정산 예정일'} subTitle={moment(pay.start, 'YYYYMMDDHHmmss').add(7, 'days').format('YYYY-MM-DD')} />

                  <TextSub title={'게스트 결제 금액'} subTitle={pay.price_guest_vat.toLocaleString('en')} />

                  <TextSub title={'개인 정산 금액'} subTitle={pay.price_host.toLocaleString('en')} />

                  <TextSub title={'사업자 정산 금액'} subTitle={pay.price_host_vat_last.toLocaleString('en')} />

                  <TextSub title={'장소'} subTitle={pay.place_id + '. ' + pay.place_title} popUrl={`/place/${pay.place_id}`} />

                  <TextSub title={'장소 유형'} subTitle={pay.category} />

                  <TextSub title={'호스트'} subTitle={pay.user_id + '. ' + pay.user_name} popUrl={`/user/${pay.user_id}`} />

                  <div className='separator my-10' />

                  <h3
                    style={{
                      marginTop: 30,
                      marginBottom: 2,
                    }}
                  >
                    호스트 입금 정보
                  </h3>

                  <TextSub title={'정산 유형'} subTitle={pay.type_text} />

                  <TextSub title={'예금주'} subTitle={pay.account ? pay.account.bank_owner : '미입력'} />

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <TextSub title={'정산 메모'} subTitle={pay.memo_pay} color={'red'} />
                    </div>
                    {pay.pay_id > 0 && (
                      <button
                        onClick={() => {
                          updateMemoPay(pay)
                        }}
                        style={{marginLeft: 20}}
                        className='btn btn-sm btn-dark'
                      >
                        입력하기
                      </button>
                    )}
                  </div>

                  <TextSub title={'은행명'} subTitle={pay.account ? pay.account.bank_name : '미입력'} />

                  <TextSub title={'계좌번호'} subTitle={pay.account ? pay.account.bank_account : '미입력'} />

                  {pay.account_no !== undefined && pay.account_no !== null && <TextSub title={'계좌주인증'} subTitle={`${pay.account_name} : ${pay.account_no}`} />}

                  <div className='separator my-10' />

                  <h3
                    style={{
                      marginTop: 30,
                      marginBottom: 2,
                    }}
                  >
                    호스트 지불 정보
                  </h3>

                  {pay.pay_id > 0 ? (
                    <>
                      <TextSub title={'지급액'} subTitle={Number(pay.pay_pay).toLocaleString('en')} />
                      <TextSub title={'지급일'} subTitle={pay.pay_pay_day} />
                      <TextSub title={'타입'} subTitle={pay.type_text} />
                      <TextSub title={'예금주'} subTitle={pay.pay_bank_owner} />
                      <TextSub title={'주민번호'} subTitle={pay.pay_jumin ? 'O' : 'X'} />
                      <TextSub title={'은행명'} subTitle={pay.pay_bank_name} />
                      <TextSub title={'계좌번호'} subTitle={pay.pay_bank_account} />

                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <TextSub title={'발급번호'} subTitle={pay.pay_memo2} color={'red'} />
                        </div>
                        {pay.pay_id > 0 && (
                          <button
                            onClick={() => {
                              updatePayMemo(pay)
                            }}
                            style={{marginLeft: 20}}
                            className='btn btn-sm btn-dark'
                          >
                            입력하기
                          </button>
                        )}
                      </div>

                      {pay.pay_bank_transfer_id !== 0 && (
                        <div
                          style={{
                            marginTop: 16,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              width: 156,
                              fontWeight: 500,
                              fontSize: 15,
                              color: '#9EA4AA',
                            }}
                          >
                            거래상태
                          </p>
                          {pay.pay_bank_transfer_id > 0 && pay.status_code === 1 && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: 'rgba(36, 111, 248, 0.1)',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#007BFF',
                                  marginBottom: 0,
                                }}
                              >
                                송금완료
                              </p>
                            </div>
                          )}
                          {pay.pay_bank_transfer_id > 0 && (pay.status_code === 0 || pay.status_code === 6) && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: 'rgba(26, 162, 0, 0.1)',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#1aa200',
                                  marginBottom: 0,
                                }}
                              >
                                처리중
                              </p>
                            </div>
                          )}
                          {pay.pay_bank_transfer_id > 0 && pay.status_code === 3 && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: '#FFF1F0',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#FF4B3E',
                                  marginBottom: 0,
                                }}
                              >
                                송금실패 (송금불능) (관리자에게 문의하세요)
                              </p>
                            </div>
                          )}
                          {pay.pay_bank_transfer_id === -1 && (
                            <div
                              style={{
                                height: 34,
                                paddingLeft: 20,
                                paddingRight: 20,
                                backgroundColor: '#FFF1F0',
                                borderRadius: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#FF4B3E',
                                  marginBottom: 0,
                                }}
                              >
                                이체 시도 중 오류 (관리자에게 문의하세요)
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <p
                      style={{
                        marginTop: 16,
                        fontWeight: 500,
                        fontSize: 15,
                        color: '#C9CDD2',
                      }}
                    >
                      정산 정보 없음
                    </p>
                  )}

                  <div
                    style={{
                      width: '100%',
                      marginTop: 20,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {pay.pay_status !== 2 && pay.pay_status !== 99 && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <label
                            onClick={() => {
                              if (window.confirm('추후 정산 처리하시겠습니까?')) {
                                delayPayHost(pay)
                              }
                            }}
                            className='btn btn-bg-light btn-color-danger'
                          >
                            추후정산
                          </label>
                        </div>
                      )}
                      {pay.pay_id < 1 && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <WindowOpener url={`/pay/booking/${pay.id}/edit`} width={900} height={1200} bridge={childResponse}>
                            <div className='btn btn-secondary'>입력하기</div>
                          </WindowOpener>
                        </div>
                      )}
                      {(pay.pay_status === 99 || pay.pay_status === 1) && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <WindowOpener url={`/pay/booking/${pay.id}/edit`} width={900} height={1200} bridge={childResponse}>
                            <div className='btn btn-secondary'>수정하기</div>
                          </WindowOpener>
                        </div>
                      )}
                      {pay.pay_status === 1 && pay.pay_bank_transfer_id === 0 && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <button className='btn btn-primary' onClick={() => paidPayHost(pay)} disabled={paidPayHostLoading}>
                            {!paidPayHostLoading && <span className='indicator-label'>입금하기</span>}
                            {paidPayHostLoading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                입금하기
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                              </span>
                            )}
                          </button>
                        </div>
                      )}
                      {(pay.pay_status === 1 || pay.pay_status === 2) && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <button
                            onClick={() => {
                              onCopy(pay)
                            }}
                            className='btn btn-secondary'
                            disabled={pay.type2 === '' || pay.type2 === 'K'}
                          >
                            안내문구복사
                          </button>
                        </div>
                      )}
                      {pay.pay_status !== 2 && (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <label
                            onClick={() => {
                              cashChange(pay)
                            }}
                            className='btn btn-bg-light btn-color-danger'
                          >
                            캐시적립
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}

            {moreLoading && (
              <div
                style={{
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className='indicator-progress' style={{display: 'block'}}>
                  Loading...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {isChecked() > 0 && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: 100,
            backgroundColor: '#26282B',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 290,
            paddingRight: 30,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p
                style={{
                  width: 136,
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                총 이체 금액
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                {isCheckedPrice()}원
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p
                style={{
                  width: 136,
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                총 이체 건수
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 800,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                {isChecked()}건
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {paidLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 160,
                  height: 52,
                  backgroundColor: '#007BFF',
                  borderRadius: 8,
                }}
              >
                <span className='indicator-progress' style={{display: 'block', color: 'white'}}>
                  이체중...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 160,
                  height: 52,
                  cursor: 'pointer',
                  backgroundColor: '#007BFF',
                  borderRadius: 8,
                }}
                onClick={() => {
                  paidPayHostFromChecked()
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    fontSize: 18,
                    color: 'white',
                  }}
                >
                  이체하기
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
