import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {BookingRefundItem} from '../../components/BookingRefundItem'
import {isMobile} from 'react-device-detect'

export function BookingRefund() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [bookings, setBookings] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [bookingStatusAll, setBookingStatusAll] = useState(0)
  const [bookingStatus0, setBookingStatus0] = useState(0)
  const [bookingStatus1, setBookingStatus1] = useState(0)
  const [bookingStatus2, setBookingStatus2] = useState(0)

  useEffect(() => {
    getBookings(status, 1)
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getBookings(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getBookings('', 1)
  }

  const handlePageChange = (page: number) => {
    getBookings(status, page)
  }

  const getBookings = (status = '', page = 1) => {
    setBookings([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/refunds?page=${page}&status=${status}&search=${search}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setBookings(result.bookings.data)
        setCurrentPage(result.bookings.current_page)
        setPerPage(result.bookings.per_page)
        setTotal(result.bookings.total)

        setBookingStatusAll(result.count_refund)
        setBookingStatus0(result.count_refund_0)
        setBookingStatus1(result.count_refund_1)
        setBookingStatus2(result.count_refund_2)

        window.scrollTo(0, 0)
        // this.forceUpdate()
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>환불</PageTitle>

    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>
        <select
          className='form-control'
          name='type'
          onChange={(e) => {
            onTab(e.target.value)
          }}
          value={status}
        >
          <option value={''}>모두보기 ({bookingStatusAll.toLocaleString('en')})</option>
          <option value={'0'}>환불 대기 ({bookingStatus0.toLocaleString('en')})</option>
          <option value={'1'}>미입력 ({bookingStatus1.toLocaleString('en')})</option>
          <option value={'2'}>환불 완료 ({bookingStatus2.toLocaleString('en')})</option>
        </select>

        <div style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='예약번호, 게스트 번호, 게스트 이름'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{
            width: 100,
          }} className='btn btn-dark'>검색</label>
        </div>
      </div>

      {
        loading ?
          <div style={{
            width: '100%',
            height: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
          </div>
          :
          <div style={{
            width: '100%',
          }}>
            {
              bookings.map((booking: any, index: number) => {
                return <BookingRefundItem key={booking.id.toString()} booking={booking} status={status} />
              })
            }
          </div>
      }

      {
        bookings.length > 0 &&
        <div style={{
          marginTop: 20,
        }}>
          <Pagination activePage={currentPage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={10}
                      prevPageText={'‹'}
                      nextPageText={'›'}
                      onChange={handlePageChange} />
        </div>
      }
    </div>
  </>
}

