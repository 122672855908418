import React, {useEffect, useState} from 'react'
// @ts-ignore
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../../components/TextSub'
import Pagination from 'react-js-pagination'
import moment from 'moment'
import Modal from '../../../components/Modal'
import {toast} from 'react-toastify'

export function AdvertiseBToBGuestBannerIndex() {
  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [modalVisible, setModalVisible] = useState(false)

  const [advBToBBanner, setAdvBToBBanner] = useState<any>([])
  const [item, setItem] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [advTotalCount, setAdvTotalCount] = useState(0)
  const [advWCount, setAdvWCount] = useState(0)
  const [advICount, setAdvICount] = useState(0)
  const [advECount, setAdvECount] = useState(0)
  const [advCCount, setAdvCCount] = useState(0)

  useEffect(() => {
    getBToBBanner()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getBToBBanner(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getBToBBanner('', 1)
  }

  const handlePageChange = (page: number) => {
    getBToBBanner(status, page)
  }

  const getBToBBanner = (status = '', page = 1) => {
    setAdvBToBBanner([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    axios
      .get(`${process.env.REACT_APP_API2_URL}am/adv/btob/banner/G?&page=${page}&status=${status}&search=${search}`)
      .then(({data: {error, time, result}}) => {
        console.log(result)
        if (error !== '') {
        } else {
          setAdvBToBBanner(result.advertise.data)
          setCurrentPage(result.advertise.current_page)
          setPerPage(result.advertise.per_page)
          setTotal(result.advertise.total)
          setAdvTotalCount(result.advTotalCount)
          setAdvWCount(result.advWCount)
          setAdvICount(result.advICount)
          setAdvECount(result.advECount)
          setAdvCCount(result.advCCount)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const putMemo = (item: any) => {
    console.log(item)
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/adv/btob/banner/${item.id}/memo`, {memo: item.memo})
      .then(({data: {error, time, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          const temps = [...advBToBBanner]
          const index = advBToBBanner.map((place: any) => place.id).indexOf(item.id)
          temps.splice(index, 1, item)
          setAdvBToBBanner(temps)
          setModalVisible(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>게스트향 배너 광고</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label
          className={'btn btn-primary'}
          onClick={() => {
            window.location.href = `/advertise/btob/banner/guest/add`
          }}
        >
          광고 등록하기
        </label>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <select
            className='form-control'
            name='type'
            value={status}
            onChange={(e) => {
              onTab(e.target.value)
            }}
          >
            <option value={''}>모두보기 ({advTotalCount})</option>
            <option value={'W'}>광고 대기중 ({advWCount})</option>
            <option value={'I'}>광고 진행중 ({advICount})</option>
            <option value={'E'}>광고 종료 ({advECount})</option>
            <option value={'C'}>광고 취소 ({advCCount})</option>
          </select>
          <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='광고 ID, 유저 ID, 유저 닉네임, 유저 이름, 이메일, 휴대폰 번호'
              value={search}
              style={{flex: 1, width: '70%'}}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
            />
            <label
              onClick={() => {
                onSearch()
              }}
              style={{width: 100}}
              className='btn btn-dark'
            >
              검색
            </label>
          </div>
        </div>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            {null !== advBToBBanner &&
              undefined !== advBToBBanner &&
              advBToBBanner.map((banner: any, index: number) => {
                return (
                  <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={index.toString()}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <h2>
                        {banner.id}. [{banner.advStatusDisplay}]
                      </h2>
                    </div>

                    <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={banner.userImageUrl} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
                        </div>

                        <div style={{marginLeft: 10}}>
                          {banner.userBlack === 1 && <p style={{color: 'red'}}>{banner.userBlackComment}</p>}
                          <TextSub title={'닉네임 (이름)'} subTitle={`${banner.userName} (${banner.userRealName})`} marginTop={0} />
                          <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(banner.userBirth))).asYears())}세`} marginTop={-10} />
                          <TextSub title={'전화번호'} subTitle={banner.userPhone} marginTop={-10} />
                          <TextSub title={'이메일'} subTitle={banner.userEmail} marginTop={-10} />

                          <TextSub
                            title={'가입일'}
                            subTitle={`${banner.userCreatedAt}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(banner.userCreatedAt)).asDays()} 일전`}
                            marginTop={-10}
                          />
                          <TextSub
                            title={'마지막 활성 시간'}
                            subTitle={`${moment(banner.userLastLoginedAt).format('MM-DD')}, ${Math.floor(
                              (moment.duration(moment().diff(moment(banner.userLastLoginedAt, 'YYYY-MM-DD'))).asDays() * 100) / 100
                            )} 일전`}
                            marginTop={-10}
                          />
                          <TextSub title={'메모'} subTitle={banner.memo} marginTop={-10} />
                        </div>
                      </div>
                      <div>
                        <label
                          onClick={() => {
                            window.open(`/user/${banner.userId}`)
                          }}
                          className='btn btn-sm btn-primary'
                        >
                          자세히 보기
                        </label>
                      </div>
                    </div>

                    <div style={{paddingTop: '15px'}}>
                      <table style={{fontSize: '15px', width: '100%'}}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>광고 일정</td>
                            <td>{banner.advSchedule}</td>
                          </tr>
                          <tr>
                            <td>금액</td>
                            <td>{banner.advPrice.toLocaleString()}</td>
                          </tr>
                          {banner.advStatus === 'C' && (
                            <tr>
                              <td>취소일</td>
                              <td>{banner.advCancel}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div style={{paddingTop: 20, display: 'flex'}}>
                      <label
                        onClick={() => {
                          window.open(`/advertise/btob/banner/guest/${banner.id}`)
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        자세히 보기
                      </label>
                      <label
                        style={{marginLeft: 15}}
                        onClick={() => {
                          setItem(banner)
                          setModalVisible(true)
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        메모하기
                      </label>
                    </div>
                  </div>
                )
              })}
          </div>
        )}

        {advBToBBanner.length > 0 && (
          <div style={{marginTop: 20}}>
            <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
          </div>
        )}
      </div>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div style={{padding: 20, width: '100%'}}>
            {
              <>
                <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                  <textarea
                    style={{width: '100%', height: 200}}
                    value={item.memo}
                    onChange={(e) => {
                      const temp = Object.assign({}, item)
                      temp.memo = e.target.value
                      setItem(temp)
                    }}
                  />
                </div>
              </>
            }
          </div>
          <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
            <label
              onClick={() => {
                putMemo(item)
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-primary'
            >
              등록
            </label>
          </div>
        </Modal>
      )}
    </>
  )
}
