import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {toast} from 'react-toastify'
import Highlighter from 'react-highlight-words'
import {apiGetGreyKeywords} from '../../../apis/Grey'

export function BookingMessage() {

  const [items, setItems] = useState<any>([])

  const [search, setSearch] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const [greyKeywords, setGreyKeywords] = useState([])

  useEffect(() => {

    getGreyKeywords()
    getMessages()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getMessages(page)
  }

  const onSearch = () => {
    getMessages(1)
  }

  const getMessages = (page = 1) => {
    setItems([])
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API2_URL}am/message?page=${page}&keyword=${search}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setItems(result.data)
        setCurrentPage(result.current_page)
        setPerPage(result.per_page)
        setTotal(result.total)
        window.scrollTo(0, 0)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const getGreyKeywords = () => {
    apiGetGreyKeywords().then(({error, result}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setGreyKeywords(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>메시지</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div style={{
          marginTop: 10,
          marginBottom: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='메시지 검색을 해봅시다. (예약번호, 장소명, 예약자 이름)'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>

        {
          loading ?
            <div style={{
              width: '100%',
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div>
              {
                items.map((item: any, index: number) => {
                  return (
                    <div className='col-12' key={`message_${index}`}>
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-header'>
                          <h1 className='card-title'>
                            {item.id}. {item.created_at} [{item.display_datetime}]
                          </h1>
                        </div>
                        <div className='card-body'>
                          <h4>{item.user_id}. {item.name} [{item.host_name}]</h4>
                          <br />
                          {
                            item.messages.map((message: any, messageIndex: number) => {
                              return (
                                <div style={{
                                  display: 'flex',
                                  justifyContent: message.user_id === item.user_id ? 'flex-end' : 'flex-start',
                                }} key={`message_${index}_${messageIndex}`}>
                                  <div className='shadow mb-5 p-5' style={{
                                    width: 'fit-content',
                                    textAlign: message.user_id === item.user_id ? 'right' : 'left',
                                  }}>
                                    <p>{message.name} [{message.host_name}]</p>
                                    {
                                      message.type === 'I' ?
                                        <a href={`${message.message}.jpg`} target={'_blank'} rel={'noopener noreferrer'}>
                                          <img src={`${message.message}.jpg`} className='img-thumbnail' style={{
                                            maxWidth: 200,
                                          }} alt={''} />
                                        </a>
                                        :
                                        <h4 style={{
                                          whiteSpace: 'pre-wrap',
                                        }}>
                                          <Highlighter
                                            searchWords={greyKeywords}
                                            autoEscape
                                            highlightStyle={{
                                              backgroundColor: 'red',
                                              color: 'white',
                                            }}
                                            textToHighlight={message.message ?? ''}
                                          />
                                        </h4>
                                    }
                                    <br />
                                    {message.created_at}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className='card-footer'>
                          <label onClick={() => {
                            window.open(`/booking/place/${item.booking_id}`)
                          }} className='btn btn-primary'>예약 : {item.booking_id}</label>
                          <label onClick={() => {
                            window.open(`/booking/message/${item.booking_id}`)
                          }} style={{marginLeft: 20}} className='btn btn-primary'>메시지 보기</label>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <div style={{padding: 20}}>
                <Pagination activePage={currentPage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            prevPageText={'‹'}
                            nextPageText={'›'}
                            onChange={handlePageChange} />
              </div>
            </div>
        }
      </div>

    </div>
  </>
}

