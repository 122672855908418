import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {isMobile} from 'react-device-detect'
import {Widget001} from '../components/Widget'

export function PlaceCount() {
  const [place, setPlace] = useState<any>(null)
  const [placeTotal, setPlaceTotal] = useState<any>(0)

  useEffect(() => {
    getSpPlaceCount()
    getSpPlaceCountTotal()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSpPlaceCount = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/sp/place/count`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlace(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSpPlaceCountTotal = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/sp/place/count/total`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlaceTotal(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>경쟁사 장소 개수</PageTitle>
      <div
        style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: isMobile ? 0 : 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='row'>
            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>스페이스 클라우드 ({placeTotal.toLocaleString('en')})</h3>
                <div className='row'>
                  {place !== null &&
                    place.map((temp: any, index: number) => {
                      return <Widget001 title={temp.categoryName} count={temp.placeCount} />
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
