import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import Constants from '../../../commons/Constants'

export function PayHostEdit() {
  const {booking_id} = useParams<{booking_id: string}>()

  const [booking, setBooking] = useState<any>(null)
  const [account, setAccount] = useState<any>(null)
  const [host, setHost] = useState<any>(null)
  const [jumin, setJumin] = useState<any>(null)
  const [accounts, setAccounts] = useState<any>([])
  const [type2, setType2] = useState('')
  const [memoPay, setMemoPay] = useState('')

  const [pay, setPay] = useState<any>(null)

  const [bankOwner, setBankOwner] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankAccount, setBankAccount] = useState('')

  const [memo, setMemo] = useState('')
  const [memo2, setMemo2] = useState('')

  const [companyId, setCompanyId] = useState(0)
  const [bankOwnerId, setBankOwnerId] = useState(0)
  const [bankAccountId, setBankAccountId] = useState(0)
  const [companyUrl, setCompanyUrl] = useState('')
  const [bankOwnerUrl, setBankOwnerUrl] = useState('')
  const [bankAccountUrl, setBankAccountUrl] = useState('')

  const [tax, setTax] = useState(0)
  const [total, setTotal] = useState(0)
  const [total2, setTotal2] = useState(0)
  const [payDay, setPayDay] = useState(moment().format('YYYY-MM-DD'))
  // const [payName, setPayName] = useState('아워플레이스')

  const [bankFile, setBankFile] = useState<File | null>(null)
  const [ownerFile, setOwnerFile] = useState<File | null>(null)
  const [companyFile, setCompanyFile] = useState<File | null>(null)

  const [checkAccountResult, setCheckAccountResult] = useState('')
  const [checkAccountLoading, setCheckAccountLoading] = useState(false)

  const [storeLoading, setStoreLoading] = useState(false)
  let loading = false

  useEffect(() => {
    getPayHost()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPayHost = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/pay/booking/${booking_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setBooking(result.booking)
          setAccounts(result.accounts)
          setJumin(result.jumin)
          setHost(result.host)
          setPay(result.pay)
          setType2(result.type2)
          setMemoPay(result.host.memo_pay)
          if (result.pay) {
            setMemo2(result.pay.memo2)
          }

          // setPayDay(moment(result.booking.start, 'YYYYMMDDHHmmss').add(7, 'days').format('YYYY-MM-DD'))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAccount = useCallback(() => {
    console.log(`${process.env.REACT_APP_API2_URL}am/account/${booking.user_id}`)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/account/${booking.user_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          if (result) {
            setAccount(result)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [booking])

  const handleChange = (e: any) => {
    setType2(e.target.value)
  }

  useEffect(() => {
    if (booking !== null) {
      setMemo(`${type2}${booking.id}`)
    }
  }, [type2, booking])

  useEffect(() => {
    if (booking !== null) {
      getAccount()
    }
  }, [getAccount, booking])

  useEffect(() => {
    if (account !== null) {
      setBankOwner(account.bank_owner)
      setBankName(account.bank_name)
      setBankAccount(account.bank_account)

      setBankOwnerId(account.bank_owner_id)
      setBankAccountId(account.bank_account_id)
      setCompanyId(account.company_id)

      setBankOwnerUrl(account.bank_owner_url)
      setBankAccountUrl(account.bank_account_url)
      setCompanyUrl(account.company_url)
    }
  }, [account])

  const refreshPrice = useCallback(() => {
    let tax1 = 0
    let total1 = 0

    if (booking !== null) {
      if (type2 === 'A') {
        //if (booking.price_host * 0.03 >= 1000) {
        if (booking.price_host * 0.03 >= 10) {
          tax1 = Math.floor((booking.price_host * 0.03) / 10) * 10 + Math.floor((Math.floor((booking.price_host * 0.03) / 10) * 10 * 0.1) / 10) * 10
        }
      } else {
        if (!(type2 === 'D' || type2 === 'G' || type2 === 'I' || type2 === 'K')) {
          tax1 = booking.price_host_vat
        }
      }

      if (type2 === 'A') {
        //if (booking.price_host * 0.03 >= 1000) {
        if (booking.price_host * 0.03 >= 10) {
          total1 = booking.price_host - tax1
        } else {
          total1 = booking.price_host
        }
      } else {
        if (type2 === 'D' || type2 === 'G' || type2 === 'I' || type2 === 'K') {
          total1 = booking.price_host
        } else {
          total1 = booking.price_host_vat_last
        }
      }
    }

    setTax(tax1)
    setTotal(total1)
  }, [booking, type2])

  useEffect(() => {
    if (pay !== null) {
      setTotal2(pay.pay)
      refreshPrice()
    }
  }, [refreshPrice, pay])

  useEffect(() => {
    refreshPrice()
  }, [refreshPrice, type2])

  const checkAccount = () => {
    setCheckAccountLoading(true)
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/account/name/check`, {
        bank_name: bankName,
        account_name: bankOwner,
        account_no: bankAccount,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
          if (result !== '') {
            setCheckAccountResult(`[${result.bank_name}](${bankName}) : [${result.account_name}](${bankOwner}) : ${result.account_no}`)
            setBankName(result.bank_name)
            setBankOwner(result.account_name)
          } else {
            setCheckAccountResult('')
          }
        } else {
          setCheckAccountResult(`${result.bank_name} : ${result.account_name} : ${result.account_no}`)
        }
        setCheckAccountLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setCheckAccountLoading(false)
      })
  }

  const storePay = () => {
    if (loading) return

    loading = true
    setStoreLoading(true)

    if (type2.length < 1) {
      alert('정산유형을 선택해 주세요.')
      loading = false
      setStoreLoading(false)
      return
    }
    if (bankOwner.length < 1) {
      alert('예금주를 입력해 주세요.')
      loading = false
      setStoreLoading(false)
      return
    }
    if (bankName.length < 1) {
      alert('은행명을 입력해 주세요.')
      loading = false
      setStoreLoading(false)
      return
    }
    if (bankAccount.length < 1) {
      alert('계좌번호를 입력해 주세요.')
      loading = false
      setStoreLoading(false)
      return
    }
    if (payDay.length !== 10) {
      alert('정산일을 입력해 주세요.')
      loading = false
      setStoreLoading(false)
      return
    }
    if (total < 1) {
      alert('최종정산금액을 입력해 주세요.')
      loading = false
      setStoreLoading(false)
      return
    }
    // if (payName.length < 1) {
    //   alert('받는분톧장인쇄내용을 입력해 주세요.')
    //   return
    // }

    axios
      .post(`${process.env.REACT_APP_API2_URL}am/pay/booking/${booking_id}`, {
        pay: total2,
        pay_day: payDay,
        type2,
        bank_owner: bankOwner,
        bank_name: bankName,
        bank_account: bankAccount,
        jumin,
        bank_account_id: bankAccountId,
        bank_owner_id: bankOwnerId,
        company_id: companyId,
        bank_account_url: bankAccountUrl,
        bank_owner_url: bankOwnerUrl,
        company_url: companyUrl,
        memo,
        memo2,
        memo_pay: memoPay,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          alert(error)
        } else {
          window.opener.onSuccess(booking_id)
          window.close()
        }
        loading = false
        setStoreLoading(false)
      })
      .catch((error) => {
        console.log(error)
        loading = false
        setStoreLoading(false)
      })
  }

  const handleBankFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setBankFile(e.target.files[0])
  }

  const handleBankPost = () => {
    if (bankFile) {
      axios
        .get(`${process.env.REACT_APP_API2_URL}upload/privacy`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error === '') {
            const {path, url} = result
            console.log(url)
            axios
              .put(url, bankFile, {
                headers: {
                  'Content-Type': bankFile.type,
                },
                onUploadProgress: (e) => {
                  //  Show progress
                  // var percentCompleted = Math.round((e.loaded * 100) / e.total);
                  // document.getElementById("uploadPercent").innerHTML = percentCompleted;
                },
              })
              .then(({data}) => {
                console.log(data)
                axios
                  .post(`${process.env.REACT_APP_API2_URL}upload`, {path, preview: 'Y'})
                  .then(({data: {error, result}}) => {
                    console.log(error, result)
                    if (error === '') {
                      setBankAccountUrl(result.image_url)
                      setBankAccountId(result.id)
                    }
                  })
                  .catch(function (error) {})
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleOwnerFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setOwnerFile(e.target.files[0])
  }

  const handleOwnerPost = () => {
    if (ownerFile) {
      axios
        .get(`${process.env.REACT_APP_API2_URL}upload/privacy`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error === '') {
            const {path, url} = result
            console.log(url)
            axios
              .put(url, ownerFile, {
                headers: {
                  'Content-Type': ownerFile.type,
                },
                onUploadProgress: (e) => {
                  //  Show progress
                  // var percentCompleted = Math.round((e.loaded * 100) / e.total);
                  // document.getElementById("uploadPercent").innerHTML = percentCompleted;
                },
              })
              .then(({data}) => {
                console.log(data)
                axios
                  .post(`${process.env.REACT_APP_API2_URL}upload`, {path, preview: 'Y'})
                  .then(({data: {error, result}}) => {
                    console.log(error, result)
                    if (error === '') {
                      setBankOwnerUrl(result.image_url)
                      setBankOwnerId(result.id)
                    }
                  })
                  .catch(function (error) {})
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleCompanyFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setCompanyFile(e.target.files[0])
  }

  const handleCompanyPost = () => {
    if (companyFile) {
      axios
        .get(`${process.env.REACT_APP_API2_URL}upload/privacy`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error === '') {
            const {path, url} = result
            console.log(url)
            axios
              .put(url, companyFile, {
                headers: {
                  'Content-Type': companyFile.type,
                },
                onUploadProgress: (e) => {
                  //  Show progress
                  // var percentCompleted = Math.round((e.loaded * 100) / e.total);
                  // document.getElementById("uploadPercent").innerHTML = percentCompleted;
                },
              })
              .then(({data}) => {
                console.log(data)
                axios
                  .post(`${process.env.REACT_APP_API2_URL}upload`, {path, preview: 'Y'})
                  .then(({data: {error, result}}) => {
                    console.log(error, result)
                    if (error === '') {
                      setCompanyUrl(result.image_url)
                      setCompanyId(result.id)
                    }
                  })
                  .catch(function (error) {})
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const downloadImage = async (url: string) => {
    if (url.indexOf('.pdf') > 0) {
      window.open(url)
    } else if (url.indexOf('s3.ap-northeast-2.amazonaws.com') > -1) {
      const res = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
      })
      const raw = await res.blob()
      const blob = window.URL.createObjectURL(raw)
      const anchor = document.createElement('a')
      anchor.style.display = 'none'
      anchor.href = blob
      anchor.download = `${booking.user_id}_${moment().format('YYYYMMDDHHmmss')}.png`
      document.body.appendChild(anchor)
      anchor.click()
      window.URL.revokeObjectURL(blob)
    } else {
      window.open(url)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>호스트 정산 처리</PageTitle>
      {booking !== null && host != null && (
        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
          }}
        >
          <TextSub title={'예약번호'} subTitle={booking.id} popUrl={`${process.env.REACT_APP_ADMIN_URL}booking/${booking.id}`} />
          <div className='separator my-10' />
          {booking.is_pass_money === 1 && (
            <>
              <TextSub title={'청구유형'} subTitle={'파손비용'} />
              <div className='separator my-10' />
            </>
          )}
          <TextSub title={'장소명'} subTitle={booking.place_title} popUrl={`${process.env.REACT_APP_ADMIN_URL}place/${booking.place_id}`} />
          <div className='separator my-10' />
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              정산유형
            </p>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 118,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  사업소득
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='A' name='type2' id='flexRadioSmA' checked={type2 === 'A'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmA'>
                      A. 일반개인
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 118,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  전자계산서
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='B' name='type2' id='flexRadioSmB' checked={type2 === 'B'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmB'>
                      B. 세금계산서 법인사업자
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='C' name='type2' id='flexRadioSmC' checked={type2 === 'C'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmC'>
                      C. 세금계산서 일반과세자
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='D' name='type2' id='flexRadioSmD' checked={type2 === 'D'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmD'>
                      D. 계산서 부가세 면세사업자
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='E' name='type2' id='flexRadioSmE' checked={type2 === 'E'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmE'>
                      E. 수수료 계산서 발급
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 118,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  현금영수증
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='F' name='type2' id='flexRadioSmF' checked={type2 === 'F'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmF'>
                      F. 현금영수증 일반과세자
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='G' name='type2' id='flexRadioSmG' checked={type2 === 'G'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmG'>
                      G. 현금영수증 간이과세자
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='H' name='type2' id='flexRadioSmH' checked={type2 === 'H'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmH'>
                      H. 현금영수증 간이과세자 (세금계산서 발급사업자)
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='I' name='type2' id='flexRadioSmI' checked={type2 === 'I'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmI'>
                      I. 현금영수증 부가세 면세사업자
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 118,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  기타
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='J' name='type2' id='flexRadioSmJ' checked={type2 === 'J'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmJ'>
                      J. 세금계산서 파손 보상
                    </label>
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <input className='form-check-input' type='radio' value='K' name='type2' id='flexRadioSmK' checked={type2 === 'K'} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='flexRadioSmK'>
                      K. 파손 보상 현금 입금
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='separator my-10' />
          <TextSub title={'본인인증이름'} subTitle={host.real_name} />
          <div className='separator my-10' />
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              예금주
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={bankOwner}
              onChange={(e) => {
                setBankOwner(e.target.value)
              }}
            />
          </div>
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              정산 메모
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={memoPay}
              style={{color: 'red'}}
              onChange={(e) => {
                setMemoPay(e.target.value)
              }}
            />
          </div>
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              주민번호
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={jumin}
              onChange={(e) => {
                setJumin(e.target.value)
              }}
            />
          </div>
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              은행명
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value)
              }}
            />
          </div>
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              공식 은행명
            </p>
            <select className='form-select form-select-solid' name='type' value={bankName} onChange={(e) => setBankName(e.target.value)}>
              <option value=''>없음</option>
              {Constants.bankList.map((bank) => {
                return <option value={bank}>{bank}</option>
              })}
            </select>
          </div>
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              계좌번호
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={bankAccount}
              onChange={(e) => {
                setBankAccount(e.target.value)
              }}
            />
          </div>

          {pay !== null && pay.account_no !== null ? (
            <TextSub title={'계좌주인증'} subTitle={`${pay.account_name} : ${pay.account_no}`} />
          ) : (
            <>{account !== null && account.account_no !== undefined && account.account_no !== null && <TextSub title={'계좌주인증'} subTitle={`${account.account_name} : ${account.account_no}`} />}</>
          )}

          <div className='separator my-10' />
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
                marginBottom: 0,
              }}
            >
              입금계좌/예금주 조회
            </p>
            <button className='btn btn-dark' onClick={() => checkAccount()} disabled={checkAccountLoading}>
              {!checkAccountLoading && <span className='indicator-label'>조회하기</span>}
              {checkAccountLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  조회하기
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
            <p
              style={{
                fontSize: 15,
                color: '#1B1D1F',
                marginLeft: 16,
                marginBottom: 0,
              }}
            >
              {checkAccountResult}
            </p>
          </div>
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              통장사본
            </p>
            <div style={{marginTop: 10}}>
              <input
                type='file'
                name='file'
                onChange={(e) => {
                  handleBankFileInput(e)
                }}
              />
              <label
                className='btn btn-dark'
                onClick={() => {
                  handleBankPost()
                }}
              >
                업로드
              </label>
            </div>
          </div>
          {bankAccountUrl !== '' && (
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  width: 156,
                  fontWeight: 500,
                  fontSize: 15,
                  color: '#9EA4AA',
                }}
              ></p>
              <div
                style={{
                  width: 200,
                  height: 150,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  downloadImage(bankAccountUrl)
                  // window.open(bankAccountUrl)
                }}
              >
                {bankAccountUrl.indexOf('.pdf') > 0 ? (
                  <img
                    src={'https://s3.hourplace.co.kr/web/images/icon/conti_pdf.svg'}
                    alt={''}
                    style={{
                      width: 84,
                      height: 105,
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <img
                    src={bankAccountUrl}
                    alt={''}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              신분증사본
            </p>
            <div style={{marginTop: 10}}>
              <input
                type='file'
                name='file'
                onChange={(e) => {
                  handleOwnerFileInput(e)
                }}
              />
              <label
                className='btn btn-dark'
                onClick={() => {
                  handleOwnerPost()
                }}
              >
                업로드
              </label>
            </div>
          </div>
          {bankOwnerUrl !== '' && (
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  width: 156,
                  fontWeight: 500,
                  fontSize: 15,
                  color: '#9EA4AA',
                }}
              ></p>
              <div
                style={{
                  width: 200,
                  height: 150,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  downloadImage(bankOwnerUrl)
                  // window.open(bankOwnerUrl)
                }}
              >
                {bankOwnerUrl.indexOf('.pdf') > 0 ? (
                  <img
                    src={'https://s3.hourplace.co.kr/web/images/icon/conti_pdf.svg'}
                    alt={''}
                    style={{
                      width: 84,
                      height: 105,
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <img
                    src={bankOwnerUrl}
                    alt={''}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              사업자등록증사본
            </p>
            <div style={{marginTop: 10}}>
              <input
                type='file'
                name='file'
                onChange={(e) => {
                  handleCompanyFileInput(e)
                }}
              />
              <label
                className='btn btn-dark'
                onClick={() => {
                  handleCompanyPost()
                }}
              >
                업로드
              </label>
            </div>
          </div>
          {companyUrl !== '' && (
            <div
              style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  width: 156,
                  fontWeight: 500,
                  fontSize: 15,
                  color: '#9EA4AA',
                }}
              ></p>
              <div
                style={{
                  width: 200,
                  height: 150,
                  backgroundColor: '#EFF3F5',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  downloadImage(companyUrl)
                  // window.open(companyUrl)
                }}
              >
                {companyUrl.indexOf('.pdf') > 0 ? (
                  <img
                    src={'https://s3.hourplace.co.kr/web/images/icon/conti_pdf.svg'}
                    alt={''}
                    style={{
                      width: 84,
                      height: 105,
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <img
                    src={companyUrl}
                    alt={''}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div className='separator my-10' />
          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              정산금액
            </p>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 156,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  예약금액
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#1B1D1F',
                  }}
                >
                  {booking.price_guest_vat.toLocaleString('en')}원
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 156,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  호스트 수익
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#1B1D1F',
                  }}
                >
                  {booking.price_host.toLocaleString('en')}원
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 156,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  호스트 세금
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#1B1D1F',
                  }}
                >
                  {tax.toLocaleString('en')}원
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 156,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                >
                  호스트 최종 정산 금액
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#1B1D1F',
                  }}
                >
                  {total.toLocaleString('en')}원
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    minWidth: 156,
                    fontWeight: 500,
                    fontSize: 15,
                    color: '#9EA4AA',
                  }}
                ></p>
                <label
                  onClick={() => {
                    setTotal2(total)
                  }}
                  className='btn btn-sm btn-secondary'
                >
                  최종정산금액 입력
                </label>
              </div>
            </div>
          </div>

          <div className='separator my-10' />

          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              정산일
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={payDay}
              onChange={(e) => {
                setPayDay(e.target.value)
              }}
            />
          </div>

          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              내통장인쇄내용
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={memo}
              onChange={(e) => {
                setMemo(e.target.value)
              }}
            />
          </div>

          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              발급번호
            </p>
            <input
              type='text'
              className='form-control'
              placeholder=''
              value={memo2}
              style={{color: 'red'}}
              onChange={(e) => {
                setMemo2(e.target.value)
              }}
            />
          </div>

          <div
            style={{
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: 156,
                fontWeight: 500,
                fontSize: 15,
                color: '#9EA4AA',
              }}
            >
              최종정산금액
            </p>
            <input
              type='number'
              className='form-control'
              placeholder=''
              value={total2}
              onChange={(e) => {
                setTotal2(Number(e.target.value))
              }}
            />
          </div>

          <div
            style={{
              width: '100%',
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: 10,
              }}
            >
              <button className='btn btn-primary' onClick={() => storePay()} disabled={storeLoading}>
                {!storeLoading && <span className='indicator-label'>저장하기</span>}
                {storeLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    저장하기
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {accounts.length > 0 && (
        <div
          style={{
            marginTop: 10,
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
          }}
        >
          <h3
            style={{
              marginBottom: 20,
            }}
          >
            계좌 정보 변경 내역
          </h3>

          {accounts.map((item: any, index: number) => {
            return (
              <p
                key={index.toString()}
                style={{
                  fontSize: 15,
                  color: '#1B1D1F',
                }}
              >
                {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')} {item.bank_owner} {item.bank_name} {item.bank_account}
              </p>
            )
          })}
        </div>
      )}
    </>
  )
}
