import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function SearchKeyword() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getKeywords()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getKeywords(page)
  }

  const getKeywords = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/search/keyword?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteKeyword = (temp: any) => {
    // const delete_ids = []
    // this.state.keywords.forEach((keyword) => {
    //   if (keyword.check === true) {
    //     delete_ids.push(keyword.id)
    //   }
    // })
    // console.log(delete_ids)
    // if (delete_ids.length > 0) {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/search/keyword/disable`, {
        ids: [temp.id],
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          const temps = [...items]
          temps.splice(temps.indexOf(temp), 1, temp)
          temp.enable = 0
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    // }
  }

  return <>
    <PageTitle breadcrumbs={[]}>검색어 리스트</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        {
          items.map((item: any, index: number) => {
            return <div style={{
              marginTop: 16,
              padding: 20,
              width: '100%',
              borderRadius: 10,
              border: '1px solid #DDD',
            }} key={item.id.toString()}>

              <h4>
                검색수 : {item.count.toLocaleString('en')}
              </h4>

              <h4>
                활성화 : {item.enable}
              </h4>

              <div className='separator my-10' />

              <h2 style={{
                whiteSpace: 'pre-wrap',
              }}>
                {decodeURIComponent(item.keyword.replace(/&amp;/g, '&'))}
              </h2>

              <div className='separator my-10' />

              <label onClick={() => {
                deleteKeyword(item)
              }} className='btn btn-danger'>제외시키기</label>

            </div>
          })
        }

      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />
    </div>
  </>
}

