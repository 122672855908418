import React from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'

export default class MagazineText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      info: props.info,
    }
  }

  render() {
    const modalClose = () => this.setState({ modalShow: false })
    const { info } = this.state
    return (
      <div style={{ ...info.wrapStyle, display: 'flex', flexDirection: 'row' }}>
        <EditorModal
          show={this.state.modalShow}
          info={info}
          onHide={modalClose}
          forceUpdate={() => {
            this.forceUpdate()
            this.props.onChange(info)
          }}
        />
        <div style={{ width: 440, padding: '0 40px 0 0' }}>
          <p style={{ ...info.title.textStyle }}>{info.title.text}</p>
          <p
            style={{ ...info.sub_title.textStyle, whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: info.sub_title.text }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <p
            style={{ ...info.content.textStyle, whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: info.content.text }}
          />
        </div>

        <div
          style={{
            position: 'absolute',
            width: 60,
            height: 44,
            right: 0,
            top: -50,
          }}>
          <Button
            variant="primary"
            style={{ width: 60, height: 44 }}
            onClick={() => this.setState({ info, modalShow: true })}>
            편집
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 160,
            top: -50,
          }}>
          <Button
            variant="success"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onUp(this.props.info)}>
            ▲
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 112,
            top: -50,
          }}>
          <Button
            variant="success"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onDown(this.props.info)}>
            ▼
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 44,
            height: 44,
            right: 64,
            top: -50,
          }}>
          <Button
            variant="danger"
            style={{ width: 44, height: 44 }}
            onClick={() => this.props.onDelete(this.props.info)}>
            X
          </Button>
        </div>
      </div>
    )
  }
}

class EditorModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      info: props.info,
    }
  }

  render() {
    const { info } = this.state
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <Form
            noValidate
            validated={false}
            onSubmit={(e) => {
              e.preventDefault()
              return false
            }}>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>text</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    placeholder="text"
                    aria-describedby="inputGroupPrepend"
                    value={info.title.text}
                    onChange={(e) => {
                      info.title.text = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>text</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows="4"
                    placeholder="text"
                    aria-describedby="inputGroupPrepend"
                    value={info.sub_title.text}
                    onChange={(e) => {
                      info.sub_title.text = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>text</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows="10"
                    placeholder="text"
                    aria-describedby="inputGroupPrepend"
                    value={info.content.text}
                    onChange={(e) => {
                      info.content.text = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
