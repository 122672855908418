import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {Button} from 'react-bootstrap'
import MainCategoryPlacesCard from '../../components/MainCategoryPlacesCard'
import Pagination from 'react-js-pagination'
import ImagePath from '../../../commons/ImagePath'
import Modal2 from '../../components/Modal2'

export function MarketingInterviewEditor() {
    const {interview_id} = useParams<{interview_id: string}>()

    const [item, setItem] = useState<any>('')

    const [modalVisible, setModalVisible] = useState(false)

    const [keyword, setKeyword] = useState('')
    const [sort, setSort] = useState('new')

    const [tempPlaces, setTempPlaces] = useState<any>('')

    const [images, setImages] = useState<any>('')

    const [places, setPlaces] = useState<any>('')
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (Number(interview_id) > 0) {
            getInterview()
        }
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getInterview = () => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/user/interview/${interview_id}`)
            .then(({data: {error, result}}) => {
                console.log(error, result)
                if (error !== '') {
                } else {
                    setItem(result)
                    setImages(result.images)
                    window.scrollTo(0, 0)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handlePageChange = (page: number) => {
        getPlaces(page)
    }

    const getPlaces = (page = 1) => {
        axios
            .post(`${process.env.REACT_APP_API2_URL}search/place`, {
                page,
                keyword,
                sort,
            })
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    setPlaces(result.data)
                    setCurrentPage(result.current_page)
                    setPerPage(result.per_page)
                    setTotal(result.total)
                    window.scrollTo(0, 0)
                    // this.forceUpdate()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const addImages = (image: any) => {
        console.log('add', image)
        const temps = [...images]
        temps.splice(0, 0, image)
        setImages(temps)
    }

    const removeImages = (image: any) => {
        console.log('delete', image)
        const temps = [...images]
        temps.splice(temps.indexOf(image), 1)
        setImages(temps)
    }

    const addPlace = (place: any) => {
        console.log('add', place)
        const temps = [...tempPlaces]
        temps.splice(0, temps.length)
        temps.splice(0, 0, place)
        setTempPlaces(temps)
    }

    const removePlace = (place: any) => {
        if (window.confirm(`${place.id}. ${place.title} 장소를 삭제하시겠습니까?`)) {
            console.log('delete', place)
            const temps = [...tempPlaces]
            temps.splice(temps.indexOf(place), 1)
            setTempPlaces(temps)
        }
    }

    const handleItemFileInput = (e: any) => {
        console.log(images.length)

        if (images.length >= 10) {
            alert('최대 10장까지 등록할 수 있어요.')
            return false
        }

        console.log(e)

        const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file?.type,
                        },
                    })
                    .then((response) => {
                        console.log(path)
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'image',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    // main_vue.completeImage(file, response.data.result)

                                    let imageId = result.id
                                    let imagePath = result.image_path

                                    console.log(imageId)
                                    console.log(imagePath)

                                    const temp = Object.assign({}, images)
                                    temp.imageId = imageId
                                    temp.imagePath = imagePath
                                    addImages(temp)
                                } else {
                                    console.log(error)
                                    // main_vue.failImage(file)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                // main_vue.failImage(file)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        // main_vue.failImage(file)
                    })
            } else {
                // main_vue.failImage(file)
            }
        })
    }

    const onSave = () => {
        if (window.confirm('아워플레이스 소식을 수정하시겠습니까?')) {
            console.log(tempPlaces)
            console.log(tempPlaces.length)

            let placeId = 0
            if (tempPlaces.length !== 0) {
                placeId = tempPlaces[0].id
            }

            axios
                .put(`${process.env.REACT_APP_API2_URL}am/user/interview/${interview_id}`, {
                    images: images,
                    content: item.content,
                    linkName: item.link_name,
                    linkUrl: item.link_url,
                    placeId: placeId,
                })
                .then(({data: {error, result}}) => {
                    console.log(error)
                    console.log(result)
                    if (error !== '') {
                        alert(error)
                    } else {
                        alert('수정되었습니다.')
                        window.location.href = `/marketing/interview`
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>아워플레이스 소식 에디터</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                    <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                            <TextSub2 title={'이미지 등록'} subTitle={''} />
                            <label
                                className='input-file-button'
                                htmlFor='input-file'
                                style={{
                                    width: '169px',
                                    height: '52px',
                                    paddingTop: '16px',
                                    paddingLeft: '34px',
                                    fontSize: '16px',
                                    lineHeight: '22px',
                                    backgroundColor: '#3C82FA',
                                    borderRadius: '8px',
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                            >
                                이미지 추가하기
                            </label>
                            <input
                                type='file'
                                id='input-file'
                                style={{display: 'none'}}
                                onChange={(e) => {
                                    handleItemFileInput(e)
                                }}
                            />
                            <div className='separator my-10' />
                            {images !== null &&
                                images !== '' &&
                                images.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <img
                                                src={ImagePath.get(item.imagePath, '550x364')}
                                                alt='picture'
                                                key={'picture_' + index}
                                                style={{width: 201, height: 201, objectFit: 'cover', paddingRight: 12, paddingBottom: 12}}
                                            />
                                            <i
                                                className='bi bi-x-lg'
                                                style={{
                                                    marginTop: 5,
                                                    marginLeft: -43,
                                                    position: 'absolute',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    width: 27,
                                                    height: 25,
                                                    backgroundColor: 'red',
                                                    textAlign: 'center',
                                                    lineHeight: '24px',
                                                    fontSize: 16,
                                                }}
                                                key={item.id}
                                                onClick={() => {
                                                    removeImages(item)
                                                }}
                                            ></i>
                                        </>
                                    )
                                })}

                            <div className='separator my-10' />
                            <TextSub2 title={'본문'} subTitle={''} />
                            <textarea
                                style={{width: '100%', height: 200}}
                                value={item.content}
                                onChange={(e) => {
                                    const temp = Object.assign({}, item)
                                    temp.content = e.target.value
                                    setItem(temp)
                                }}
                            />

                            <div className='separator my-10' />
                            <TextSub2 title={'링크'} subTitle={''} />

                            <TextSub2 title={''} subTitle={'링크명'} />
                            <input
                                type='text'
                                className='form-control'
                                placeholder=''
                                value={item.link_name}
                                onChange={(e) => {
                                    const temp = Object.assign({}, item)
                                    temp.link_name = e.target.value
                                    setItem(temp)
                                }}
                            />

                            <TextSub2 title={''} subTitle={'링크 URL'} />
                            <input
                                type='text'
                                className='form-control'
                                placeholder=''
                                value={item.link_url}
                                onChange={(e) => {
                                    const temp = Object.assign({}, item)
                                    temp.link_url = e.target.value
                                    setItem(temp)
                                }}
                            />

                            <div className='separator my-10' />
                            <TextSub2 title={'장소 연결하기'} subTitle={''} />
                            <span className='pr-4'>
                                <Button
                                    variant='success'
                                    onClick={() => {
                                        setModalVisible(true)
                                    }}
                                >
                                    장소 추가
                                </Button>
                            </span>
                            <div style={{marginTop: 40, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {tempPlaces !== null &&
                                    tempPlaces !== '' &&
                                    tempPlaces.map((item: any, index: number) => {
                                        return <MainCategoryPlacesCard place={item} removePlace={removePlace} />
                                    })}
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: 40}}>
                        <Button
                            variant='primary'
                            onClick={() => {
                                onSave()
                            }}
                        >
                            저장
                        </Button>
                    </div>
                </div>
            </div>

            {modalVisible && (
                <Modal2
                    visible={modalVisible}
                    closable={true}
                    maskClosable={true}
                    onClose={() => {
                        setModalVisible(false)
                    }}
                    className={''}
                >
                    <div style={{marginTop: 15, marginBottom: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}} className='card card-custom card-stretch shadow mb-5'>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='장소 검색어'
                            style={{flex: 1}}
                            onChange={(e) => {
                                setKeyword(e.target.value.toLowerCase().trim())
                            }}
                        />
                        <label
                            onClick={() => {
                                getPlaces()
                            }}
                            style={{width: 100}}
                            className='btn btn-dark'
                        >
                            검색
                        </label>
                    </div>
                    <div className={'row'} style={{height: '72vh', overflowY: 'auto'}}>
                        {places !== null &&
                            places !== '' &&
                            places.map((place: any, index: number) => {
                                return (
                                    <div className='col-lg-3' key={'place_' + index.toString()}>
                                        <div className='card card-custom card-stretch shadow mb-5'>
                                            <div className='card-header'>
                                                <h1 className='card-title'>
                                                    {place.id}. {place.title}
                                                </h1>
                                            </div>
                                            <div className='card-body'>
                                                <img className='rounded' src={ImagePath.get(place.main_image_path, "166.88x120")} style={{height: 120, width: '100%'}} alt='' />
                                                <h3>{place.price.toLocaleString('en')}</h3>
                                            </div>
                                            <div className='card-footer'>
                                                <label
                                                    onClick={() => {
                                                        if (window.confirm(`${place.id}. ${place.title} 장소를 추가하시겠습니까?`)) {
                                                            addPlace(place)
                                                            setModalVisible(false)
                                                        }
                                                    }}
                                                    className='btn btn-primary'
                                                >
                                                    추가
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        {places !== null && places !== '' && (
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={10}
                                prevPageText={'‹'}
                                nextPageText={'›'}
                                onChange={handlePageChange}
                            />
                        )}
                    </div>
                </Modal2>
            )}
        </>
    )
}
