import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from "axios";

export function AdvertiseNetworkScheduleIndex() {

  const [items, setItems] = useState<any>([])

  useEffect(() => {
    getNetworkSchedule();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getNetworkSchedule = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/schedule`)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            setItems(result)
            window.scrollTo(0, 0)
          }
        })
        .catch((error) => {
          console.log(error)
        })
  }

  return <>
    <PageTitle breadcrumbs={[]}>네크워크 광고 일정</PageTitle>
    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <label className={'btn btn-primary'} onClick={() => {
        window.location.href = `/advertise/network/schedule/Add`
      }}>
        추가하기
      </label>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <div>
          {
            items.map((item: any, index: number) => {
              return (
                  <div className='col-12' key={index.toString()}>
                    <div className='card card-custom card-stretch shadow mb-5'>

                      <div className='card-header'>
                        <h1 className='card-title'>
                          {item.id}. {item.statusDisplay}
                        </h1>
                      </div>

                      <div className='card-body'>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{ width: '14%' }}/>
                            <col style={{ width: 'auto' }}/>
                          </colgroup>
                          <tbody>
                            <tr style={{fontSize: '15px'}}>
                              <td>접수 시작일</td>
                              <td>{item.register_start_date}</td>
                            </tr>
                            <tr style={{fontSize: '15px'}}>
                              <td>접수 마감일</td>
                              <td>{item.advertise_register_deadline_date}</td>
                            </tr>
                            <tr style={{fontSize: '15px'}}>
                              <td>광고 게재 시작일</td>
                              <td>{item.advertise_start_date}</td>
                            </tr>
                            <tr style={{fontSize: '15px'}}>
                              <td style={{verticalAlign: 'top'}}>주차별 광고 게재 시작일</td>
                              <td>
                                {item.advertise_week_start_date_1}<br/>
                                {item.advertise_week_start_date_2}<br/>
                                {item.advertise_week_start_date_3}<br/>
                                {item.advertise_week_start_date_4}
                              </td>
                            </tr>
                            <tr style={{fontSize: '15px'}}>
                              <td>광고비 결제 마감일</td>
                              <td>{item.advertise_payment_deadline_date}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className='card-footer'>
                          <label style={{marginLeft: 10}} onClick={() => {
                              window.location.href = `/advertise/network/${item.id}/schedule`
                          }} className='btn btn-primary'>수정하기</label>
                      </div>
                    </div>
                  </div>
              )
            })
          }
        </div>
      </div>
    </div>
  </>
}

