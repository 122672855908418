import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {TextSub2} from '../../components/TextSub2'
import {toast} from "react-toastify";

export function SettingAgreeAdd() {

  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [mTitle, setMTitle] = useState('')
  const [mBody, setMBody] = useState('')

  useEffect(() => {
    return () => {
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const insert = () => {
    let str = '등록 하시겠습니까?';
    if (window.confirm(str)) {
      axios.post(`${process.env.REACT_APP_API2_URL}am/setting/agree`, {
        title, m_title: mTitle, body, m_body: mBody
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          alert("등록되었습니다.");
          window.location.href = `/setting/agree`;
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>약관 추가</PageTitle>

    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>

        <div className='card card-custom card-stretch shadow mb-5'>
          <div className='card-body'>
            <TextSub2 title={'약관 타이틀'} subTitle={'PC용'} />
            <div>
              <input type='text' className='form-control' placeholder='' value={title} maxLength={50} onChange={(e) => {
                    setTitle(e.target.value);
                  }}
              />
            </div>
            <div className='separator my-10' />
            <TextSub2 title={'약관 내용'} subTitle={'PC용'} />
            <div>
              <textarea style={{width: '100%', height: 1000}} value={body} onChange={(e) => {
                  setBody(e.target.value)
                }} />
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'모바일 약관 타이틀'} subTitle={'MOBILE 용'} />
            <div>
              <input type='text' className='form-control' placeholder='' value={mTitle} maxLength={50} onChange={(e) => {
                setMTitle(e.target.value);
              }}
              />
            </div>
            <div className='separator my-10' />
            <TextSub2 title={'모바일 약관 내용'} subTitle={'MOBILE 용'} />
            <div>
              <textarea style={{width: '100%', height: 1000}} value={mBody} onChange={(e) => {
                setMBody(e.target.value)
              }} />
            </div>
          </div>
        </div>
        <div className='card-footer' style={{display: 'flex', justifyContent: 'center', paddingTop: 35}}>
          <label onClick={() => {
            insert();
          }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>등록하기</label>
        </div>
      </div>
    </div>
  </>
}

