import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {TextSub} from '../../components/TextSub'
import {apiGetAlimtalk} from '../../../apis/Alimtalk'
import {TextSub2} from '../../components/TextSub2'
import axios from 'axios'
import {toast} from 'react-toastify'

export function SettingAlimTalkEditor() {

  const [type, setType] = useState('')
  const [code, setCode] = useState('')
  const [comment, setComment] = useState('')
  const [template, setTemplate] = useState('')
  const [buttonTitle, setButtonTitle] = useState('')
  const [button2Title, setButton2Title] = useState('')
  const [buttonMobile, setButtonMobile] = useState('')
  const [button2Mobile, setButton2Mobile] = useState('')
  const [buttonIos, setButtonIos] = useState('')
  const [button2Ios, setButton2Ios] = useState('')
  const [buttonAndroid, setButtonAndroid] = useState('')
  const [button2Android, setButton2Android] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    return () => {
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const insert = () => {

    if (window.confirm('알림톡 템플릿을 등록하시겠습니까?')) {
      axios.post(`${process.env.REACT_APP_API2_URL}am/alimtalk`, {
        "type" : type,
        "code" : code,
        "comment" : comment,
        "template" : template,
        "button_title" : buttonTitle,
        "button2_title" : button2Title,
        "button_mobile" : buttonMobile,
        "button2_mobile" : button2Mobile,
        "button_ios" : buttonIos,
        "button2_ios" : button2Ios,
        "button_android" : buttonAndroid,
        "button2_android" : button2Android,
        "title" : title,
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/setting/alimtalk`
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>알림톡 템플릿 등록</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">타입</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="type"
              value={type}
              onChange={(e) => { setType(e.target.value) }}
              required />
          </div>
          <div className="col-sm-10 offset-sm-2">
            booking_approved : 예약 가능 여부 확인 요청 안내 &gt 호스트<br />
            booking_approved_reminder: 예약 승인 요청 추가 안내 &gt 호스트<br />
            booking_proceeding : 예약 가능 안내 &gt 게스트<br />
            booking_payment_vbank : 가상계좌 발급 안내<br />
            booking_payment_request : 예약 가능 안내(4일 전) &gt 게스트<br />
            booking_payment_reminder: 예약 가능 안내(3일전/2일전/1일전) &gt 게스트<br />
            booking_payment_reminder_7day: 예약 가능 안내(7일전 ~ 1일전) &gt 게스트(수정1)<br />
            booking_paid_to_guest : 예약 확정 안내 &gt 게스트<br />
            booking_paid_to_host : 예약 확정 안내 &gt 호스트<br />
            booking_dday_m_1_to_guest : 촬영 1일전 사전 안내 &gt 게스트<br />
            booking_dday_m_1_to_host : 촬영 1일전 사전 안내 &gt 호스트<br />
            booking_feedback_to_guest : 촬영장소 평가 요청 &gt 게스트<br />
            booking_feedback_to_host : 게스트 평가 요청 &gt 호스트<br />
            booking_cancelled_guest_to_guest : 예약진행 불가 안내 게스트 취소 &gt 게스트<br />
            booking_cancelled_guest_to_host : 예약진행 불가 안내 게스트 취소 &gt 호스트<br />
            booking_cancelled_host_to_guest : 예약진행 불가 안내(호스트 사정) &gt 게스트<br />
            booking_cancelled_host_to_host : 예약진행 불가 안내(호스트 사정) &gt 호스트<br />
            booking_additional_to_guest : 호스트 추가 결제 요청 &gt 게스트<br />
            booking_additional_paid_to_host : 추가 결제 완료 안내 &gt 호스트<br />
            booking_message_to_host : 메시지 요청 &gt 호스트<br />
            booking_message_to_guest : 메시지 요청 &gt 게스트<br />
            booking_host_paid : 정산 안내 &gt 호스트<br />
            point_expire : 포인트 소멸<br />
            coupon_expire : 포인트 소멸<br />
            booking_host_reminder : 호스트 예약 확인 안내
          </div>
        </div>

        <hr />

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">설명</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="comment"
              value={comment}
              onChange={(e) => { setComment(e.target.value) }}
              required />
          </div>
          <div className="col-sm-10 offset-sm-2">
            메시지 설명
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">알림톡 코드</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="code"
              value={code}
              onChange={(e) => { setCode(e.target.value) }}
              required />
          </div>
          <div className="col-sm-10 offset-sm-2">
            hourplace_????
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">강조구분형</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="title"
              value={title}
              onChange={(e) => { setTitle(e.target.value) }}
              required />
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">템플릿</label>
          <div className="col-sm-10">
            <textarea className="form-control" name="template" placeholder="" rows={14} onChange={(e) => { setTemplate(e.target.value) }}>
              {template}
            </textarea>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼1 버튼명</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button_title"
              value={buttonTitle}
              onChange={(e) => { setButtonTitle(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼1 모바일</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button_mobile"
              value={buttonMobile}
              onChange={(e) => { setButtonMobile(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼1 아이폰</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button_ios"
              value={buttonIos}
              onChange={(e) => { setButtonIos(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼1 안드로이드</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button_android"
              value={buttonAndroid}
              onChange={(e) => { setButtonAndroid(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼2 버튼명</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button2_title"
              value={button2Title}
              onChange={(e) => { setButton2Title(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼2 모바일</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button2_mobile"
              value={button2Mobile}
              onChange={(e) => { setButton2Mobile(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼2 아이폰</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button2_ios"
              value={button2Ios}
              onChange={(e) => { setButton2Ios(e.target.value) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">버튼2 안드로이드</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="text" placeholder="" name="button2_android"
              value={button2Android}
              onChange={(e) => { setButton2Android(e.target.value) }}
            />
          </div>
        </div>

        <hr />

        <div className="col-12 text-center">
          <label className="btn btn-primary" onClick={() => { insert() }}>등록하기</label>
        </div>

      </div>

    </div>
  </>
}

