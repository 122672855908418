import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import moment from 'moment'
import {toast} from 'react-toastify'
import DatePicker from 'react-datepicker'
import {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export function PayHostReport() {

  const [date, setDate] = useState<Date | null>(new Date())

  const [items, setItems] = useState([])

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    registerLocale('ko', ko)

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getItems = useCallback(() => {
    setItems([])
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/pay/host/report?date=${moment(date).format('YYYY-MM')}`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          toast.error(error)
        } else {
          console.log(result)
          setItems(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getItems()
  }, [getItems, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [{header: '예약번호', key: 'booking_id', width: 10},
      {header: '정산번호', key: 'id', width: 10},
      {header: '거래일시', key: 'trade_seq', width: 20},
      {header: '출금', key: 'va_mny', width: 20},
      {header: '거래후 잔액', key: 'bal_amount', width: 40},
      {header: '거래 내용', key: 'memo', width: 20},
      {header: '상대계좌', key: 'bank_account', width: 30},
      {header: '상대은행', key: 'bank_name', width: 20},
      {header: '상대계좌예금주명', key: 'bank_owner', width: 20},
      {header: '정산유형', key: 'type_text', width: 30},
      {header: '발급번호', key: 'memo2', width: 30},
      {header: '정산', key: 'pay', width: 30},
    ]

    items.forEach((item: any) => {
      worksheet.addRow({
        booking_id: item.booking_id, id: item.id, trade_seq: moment(item.app_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
        pay_day: item.pay_day, va_mny: Number(item.va_mny).toLocaleString('en'), pay: Number(item.pay).toLocaleString('en'), bal_amount: Number(item.bal_amount).toLocaleString('en'),
        bank_owner: item.bank_owner, bank_name: item.bank_name, bank_account: item.bank_account, type_text: item.type_text, memo: `${item.type2}${item.booking_id}`, memo2: item.memo2,
      })
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `경남은행_${moment(date).format('YYYY-MM')}.xlsx`)
  }

  return <>
    <PageTitle breadcrumbs={[]}>정산 결산표(경남은행)</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
    }}>
      <div style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
      }}>
        <div style={{
          width: 200,
        }}>
          <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM' showMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
        </div>
        <label
          onClick={() => {
            download()
          }}
          className='btn btn-sm btn-secondary'>다운로드</label>
      </div>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='tab-content' id='myTabContent'>

          <div className='table-responsive'>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th className='min-w-100px'>예약번호</th>
                <th className='min-w-100px'>정산번호</th>
                <th className='min-w-100px'>거래일시</th>
                <th className='min-w-100px'>출금</th>
                <th className='min-w-100px'>정산</th>
                <th className='min-w-100px'>거래후 잔액</th>
                <th className='min-w-100px'>거래 내용</th>
                <th className='min-w-100px'>상대계좌</th>
                <th className='min-w-100px'>상대은행</th>
                <th className='min-w-100px'>상대계좌예금주명</th>
                <th className='min-w-100px'>정산유형</th>
                <th className='min-w-100px'>발급번호</th>
              </tr>
              </thead>
              <tbody>
              {
                items.map((item: any, index: number) => {
                  return [
                    <tr key={`${index}`}>
                      <td>{item.booking_id}</td>
                      <td>{item.id}</td>
                      <td>{moment(item.app_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{Number(item.va_mny).toLocaleString('en')}</td>
                      <td>{Number(item.pay).toLocaleString('en')}</td>
                      <td>{Number(item.bal_amount).toLocaleString('en')}</td>
                      <td>
                        {
                          item.pay_type === 'pay' && `${item.type2}${item.booking_id}`
                        }
                        {
                          item.pay_type === 'cash' && `${item.type2}${item.id}`
                        }
                        </td>
                      <td>{item.bank_account}</td>
                      <td>{item.bank_name}</td>
                      <td>{item.bank_owner}</td>
                      <td>{item.type_text}</td>
                      <td>{item.memo2}</td>
                    </tr>,
                  ]
                })
              }
              </tbody>
            </table>
          </div>

        </div>

      </div>

    </div>
  </>
}

