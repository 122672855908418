import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function PayPoint() {
  const [type, setType] = useState('')

  const [loading, setLoading] = useState(false)
  const [points, setPoints] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getPoint()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setType(tabStatus)
    getPoint(tabStatus, 1)
  }

  const handlePageChange = (page: number) => {
    getPoint(type, page)
  }

  const getPoint = (type = '', page = 1) => {
    setPoints([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    axios
      .get(`${process.env.REACT_APP_API2_URL}am/pay/point?page=${page}&type=${type}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        console.log(result.data)
        if (error !== '') {
        } else {
          setPoints(result.data)

          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>포인트</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <select
            className='form-control'
            name='type'
            value={type}
            onChange={(e) => {
              onTab(e.target.value)
            }}
          >
            <option value={''}>모두보기</option>
            <option value={'U'}>포인트 사용</option>
            <option value={'W'}>포인트 출금</option>
            <option value={'H'}>임의 충전</option>
            <option value={'M'}>마케팅 충전</option>
            <option value={'I'}>추천 적립</option>
            <option value={'B'}>입금 충전</option>
            <option value={'SB'}>예약 적립</option>
            <option value={'T'}>테스트 충전</option>
            <option value={'E'}>포인트 소멸</option>
          </select>
        </div>
        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div className='tab-content w-100' id='myTabContent'>
            <div className='table-responsive'>
              <table className='table table-striped gy-7 gs-7 text-center'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th className='min-w-150px'>포인트번호</th>
                    <th className='min-w-150px'>상태</th>
                    <th className='min-w-100px'>포인트</th>
                    <th className='min-w-80px'>예약번호</th>
                    <th className='min-w-80px'>사용자번호</th>
                    <th className='min-w-100px'>사용자이름</th>
                    <th className='min-w-100px'>소멸예정일</th>
                    <th className='min-w-250px'>comment</th>
                    <th className='min-w-100px'>결제금액</th>
                    <th className='min-w-100px'>포인트사용</th>
                    <th className='min-w-100px'>장소카테고리</th>
                    <th className='min-w-150px'>요청일자</th>
                  </tr>
                </thead>
                <tbody className=''>
                  {points.map((point: any, index: number) => {
                    return (
                      <tr key={`${index}`}>
                        <td>{point.id}</td>
                        <td>
                          {point.type === 'H' && `임의 충전 (${point.type})`}
                          {point.type === 'M' && `마케팅 충전 (${point.type})`}
                          {point.type === 'SB' && `예약 적립 (${point.type})`}
                          {point.type === 'B' && `통장 입금 충전 (${point.type})`}
                          {point.type === 'T' && `테스트 충전 (${point.type})`}
                          {point.type === 'I' && `추천 적립 (${point.type})`}
                          {point.type === 'U' && `포인트 사용 (${point.type})`}
                          {point.type === 'W' && `포인트 출금 (${point.type})`}
                          {point.type === 'E' && `포인트 소멸 (${point.type})`}
                        </td>
                        <td>{point.point.toLocaleString('en')} P</td>
                        <td>
                          {point.booking_id > 0 && (
                            <p
                              style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer'}}
                              onClick={() => {
                                window.open(`/booking/place/${point.booking_id}`)
                              }}
                            >
                              {point.booking_id}
                            </p>
                          )}
                          {point.booking_id === 0 && '-'}
                        </td>
                        <td>
                          {point.user_id > 0 && (
                            <p
                              style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer'}}
                              onClick={() => {
                                window.open(`/user/${point.user_id}`)
                              }}
                            >
                              {point.user_id}
                            </p>
                          )}
                          {point.user_id === 0 && '-'}
                        </td>
                        <td>{point.user_id > 0 && point.name}</td>
                        <td>
                          {point.expire !== '0000-00-00 00:00:00' && point.expire !== undefined && point.expire}
                          {(point.expire === '0000-00-00 00:00:00' || point.expire === undefined) && `-`}
                        </td>
                        <td>{point.comment}</td>
                        {(point.type !== 'SB' || (point.type === 'SB' && point.booking === undefined)) && (
                          <>
                            <td>0</td>
                            <td>0</td>
                            <td>-</td>
                          </>
                        )}
                        {point.type === 'SB' && point.booking !== undefined && (
                          <>
                            <td>{point.booking.price_guest_vat.toLocaleString('en')}</td>
                            <td>
                              {point.booking.used !== undefined && point.booking.used.point}
                              {point.booking.used === undefined && 0}
                            </td>
                            <td>
                              {point.place !== undefined && point.place.category_main}
                              {point.place === undefined && '-'}
                            </td>
                          </>
                        )}
                        <td>{point.date}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
      </div>
    </>
  )
}
