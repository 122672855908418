import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import axios from 'axios'
import {PageTitle} from '../../../../../_metronic/layout/core'

export default class StatisticsDeviceBooking extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: [],
    }
    this.getBookingDay()
  }

  getBookingDay() {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/statistics/device/booking`)
      .then(({ data: { error, result } }) => {
        console.log(error)
        console.log(result)
        const data = []
        result.forEach((item) => {
          item.day = item.date
          item.W = item.value.W
          item.M = item.value.M
          item.I = item.value.I
          item.A = item.value.A
          // if (item.value > 0) {
          data.push(item)
          // }
        })
        this.setState({
          data,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <>
        <PageTitle breadcrumbs={[]}>디바이스별 예약</PageTitle>

        <div className="row">
          <div
            className="col-12"
            style={{
              padding: 20,
              backgroundColor: '#FFF',
              borderRadius: 10,
              height: 400,
            }}>
            <ResponsiveBar
              data={this.state.data}
              keys={['W', 'M', 'I', 'A']}
              indexBy="day"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              valueFormat={{ format: '', enabled: false }}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'fries',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'sandwich',
                  },
                  id: 'lines',
                },
              ]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              // axisBottom={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'country',
              //   legendPosition: 'middle',
              //   legendOffset: 32,
              // }}
              // axisLeft={{
              //   tickSize: 5,
              //   tickPadding: 5,
              //   tickRotation: 0,
              //   legend: 'food',
              //   legendPosition: 'middle',
              //   legendOffset: -40,
              // }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </>
    )
  }
}
