import React, {useEffect, useState} from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import axios from "axios";
import Modal from "../../../../components/Modal";
import {TextSub} from "../../../../components/TextSub";
import Pagination from "react-js-pagination";
import ImagePath from "../../../../../commons/ImagePath";

export function AdvertiseNetworkPlusPlaceIndex() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [search, setSearch] = useState('')

  const [places, setPlaces] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [modalVisible, setModalVisible] = useState(false)
  const [schedule, setSchedule] = useState<any>([])
  const [selectSchedule, setSelectSchedule] = useState<any>([])

  const [advTotalCount, setAdvTotalCount] = useState(0)
  const [advPwCount, setAdvPwCount] = useState(0)
  const [advPfCount, setAdvPfCount] = useState(0)
  const [advIfCount, setAdvIfCount] = useState(0)
  const [advNfCount, setAdvNfCount] = useState(0)
  const [advSfCount, setAdvSfCount] = useState(0)
  const [advICount, setAdvICount] = useState(0)
  const [advECount, setAdvECount] = useState(0)
  const [advCCount, setAdvCCount] = useState(0)

  useEffect(() => {
    getStartSchedule();
    getNetworkPlaces();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getNetworkPlaces(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getNetworkPlaces('', 1)
  }

  const handlePageChange = (page: number) => {
    getNetworkPlaces(status, page)
  }

  const getStartSchedule = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/start/schedule`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setSchedule(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getNetworkPlaces = (status = '', page = 1) => {
    setPlaces([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    console.log(searchFilter)

    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/NPLUS/place?&page=${page}&status=${status}&search=${search}&searchFilter=${searchFilter}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setPlaces(result.advertise.data);
        setCurrentPage(result.advertise.current_page);
        setPerPage(result.advertise.per_page);
        setTotal(result.advertise.total);
        window.scrollTo(0, 0)
        // this.forceUpdate()
        getAdvCount();
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const getAdvCount = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/network/NPLUS/place/count`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        // this.forceUpdate()
        setAdvTotalCount(result.advTotalCount);
        setAdvPwCount(result.advPwCount);
        setAdvPfCount(result.advPfCount);
        setAdvIfCount(result.advIfCount);
        setAdvNfCount(result.advNfCount);
        setAdvSfCount(result.advSfCount);
        setAdvICount(result.advICount);
        setAdvECount(result.advECount);
        setAdvCCount(result.advCCount);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>네크워크 광고 PLUS</PageTitle>
    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <label className={'btn btn-primary'} onClick={() => {setModalVisible(true)}}>
        광고 등록하기
      </label>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <select className='form-control' name='type' value={status} onChange={(e) => {onTab(e.target.value)}}>
          <option value={''}>모두보기 ({advTotalCount})</option>
          <option value={'PW'}>결제 대기 ({advPwCount})</option>
          <option value={'PF'}>결제 완료 ({advPfCount})</option>
          <option value={'IF'}>소재 등록 완료 ({advIfCount})</option>
          <option value={'SF'}>광고 세팅 완료 ({advSfCount})</option>
          <option value={'NF'}>시작 전 안내 완료 ({advNfCount})</option>
          <option value={'I'}>광고 중 ({advICount})</option>
          <option value={'E'}>광고 종료 ({advECount})</option>
          <option value={'C'}>광고 취소 ({advCCount})</option>
        </select>
        <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <select className='form-control' name='searchFilter' value={searchFilter} onChange={(e) => {setSearchFilter(e.target.value)}} style={{width:'100px'}}>
            <option value={''}>전체</option>
            <option value={'1'}>광고 일정 ID</option>
          </select>
          <input type='text' className='form-control' placeholder='광고 ID, 호스트 ID, 호스트 닉네임, 호스트 이름, 이메일, 휴대폰 번호, 장소 번호, 장소명' value={search} style={{flex: 1, width:'70%'}}
              onChange={(e) => {setSearch(e.target.value)}}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
          />
          <label onClick={() => {onSearch()}} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>
      </div>
      {
        loading ?
            <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
          <div style={{width: '100%'}}>
            {
              places.map((place: any, index: number) => {
                return (
                    <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={index.toString()}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <h2>
                          {place.advPlaceAdvertiseGroupId}. [{place.advStatusDisplay}]
                        </h2>
                      </div>

                      <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                        <div style={{width: 100, height: 100, position: 'relative'}}>
                          <img src={ImagePath.get(place.placeMainImagePath, '100x100')} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                          <div style={{position: 'absolute', width: 30, height: 30, borderTopLeftRadius: 8, bottom: 0, right: 0, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <h4 style={{margin: 0}}>
                              {place.placeGrade}
                            </h4>
                          </div>
                        </div>

                        <div style={{marginLeft: 10}}>
                          <LinesEllipsis text={`${place.placeId}. ${place.placeTitle}`} maxLine='2' ellipsis='...' trimRight basedOn='letters' style={{fontSize: 20, fontWeight: 'bold'}}/>

                          <TextSub title={'장소 금액'} subTitle={`${place.placePriceGuestVat.toLocaleString()}`} marginTop={0} />
                          <TextSub title={'촬영 건수'} subTitle={`${place.placeShootCount.toLocaleString()} (${place.placeBookingCount.toLocaleString()})`} marginTop={-10} />
                          <TextSub title={'카테고리'} subTitle={`${place.placeCategoryMain} > ${place.placeCategorySub}`} marginTop={-10} />
                          <TextSub title={'관리자 장소 메모'} subTitle={place.placeAdminComment} marginTop={-10} />

                          <label onClick={() => {
                            window.open(`/place/${place.placeId}`, '_blank')
                          }} className='btn btn-sm btn-primary'>장소 보기</label>

                        </div>

                      </div>

                      <div style={{paddingTop: '15px'}}>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{ width: '10%' }}/>
                            <col style={{ width: 'auto' }}/>
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>광고 일정 ID</td>
                              <td>{place.advNetworkScheduleId}</td>
                            </tr>
                            <tr>
                              <td>광고 일정</td>
                              <td>{place.advSchedule}</td>
                            </tr>
                            <tr>
                              <td>결제 금액</td>
                              <td>{place.advPrice.toLocaleString()} (부가세 포함)</td>
                            </tr>
                            <tr>
                              <td>결제 마감일</td>
                              <td>{place.advertisePaymentDeadlineDate} 24:00:00</td>
                            </tr>
                            <tr>
                              <td>결제일 </td>
                              <td>{place.advPaidTime}</td>
                            </tr>
                            <tr>
                              <td><br/></td>
                            </tr>
                            <tr>
                              <td>소재 등록 마감일</td>
                              <td>{place.itemRegDeadlineDate}</td>
                            </tr>
                            <tr>
                              <td>소재 등록일</td>
                              <td>
                                {
                                    place.advNetworkItemImageId !== 0 &&
                                    place.advNetworkItemRegDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td><br/></td>
                            </tr>
                            <tr>
                              <td>광고 세팅 마감일</td>
                              <td>{place.advSettingDeadlineDate}</td>
                            </tr>
                            <tr>
                              <td>광고 세팅 완료일</td>
                              <td>
                                {
                                    place.advNetworkSettingDate !== '' &&
                                    place.advNetworkSettingDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td><br/></td>
                            </tr>
                            <tr>
                              <td>안내 마감일</td>
                              <td>{place.infoMailSendDeadlineDate}</td>
                            </tr>
                            <tr>
                              <td>안내 완료일</td>
                              <td>
                                {
                                    place.advNetworkInfoSendDate !== '' &&
                                    place.advNetworkInfoSendDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td><br/></td>
                            </tr>
                            {
                              place.advNetworkCancelScheduleDisplay !== undefined && place.advNetworkCancelScheduleDisplay !== '' &&
                              <>
                                <tr>
                                  <td>취소 일정</td>
                                  <td>{place.advNetworkCancelScheduleDisplay}</td>
                                </tr>
                                <tr>
                                  <td>취소 금액</td>
                                  <td>{place.advNetworkCancelSchedulePriceDisplay}</td>
                                </tr>
                                <tr>
                                  <td style={{verticalAlign: 'top'}}>취소일</td>
                                  <td>
                                    {
                                      place.advCancelledTime.map((ct: any, index: number) => {
                                        return(
                                            <>
                                              <span key={index.toString()}>{ct.adv_cancelled_time}</span><br/>
                                            </>
                                        )
                                      })
                                    }
                                  </td>
                                </tr>
                              </>
                            }
                          </tbody>
                        </table>
                      </div>
                      <div style={{paddingTop: 20, display: 'flex'}}>
                        <label onClick={() => {
                          window.open(`/advertise/network/plus/${place.advPlaceAdvertiseGroupId}/place`)
                        }} className='btn btn-sm btn-primary'>자세히 보기</label>
                      </div>
                    </div>
                )
              })
            }
          </div>
      }

      {
          places.length > 0 &&
          <div style={{marginTop: 20}}>
            <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
          </div>
      }

    </div>

    {
        modalVisible &&
        <Modal visible={modalVisible} closable={true} maskClosable={true} onClose={() => {
              setModalVisible(false)
            }} className={''}>
          <div style={{padding: 20, width: '100%'}}>
            <select
                className='form-select form-select-solid'
                name='type'
                value={selectSchedule}
                onChange={(e) => {
                  setSelectSchedule(e.target.value)
                }}
            >
              <option key={''} value={''}>광고 게재 시작일을 선택해주세요.</option>
              {
                schedule.map((item: any) => {
                  return (
                      <option key={item.id} value={item.id}>{item.id}. {item.advertise_start_date}</option>
                  )})
              }
            </select>
          </div>
          <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
            <label onClick={() => {
              let schedule = `${selectSchedule}`;
              if(schedule !== ''){
                window.location.href = `/advertise/network/plus/${selectSchedule}/place/add`
              }
            }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>확인</label>
          </div>
        </Modal>
    }
  </>
}

