import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import moment from 'moment'

export function MarketingInterview() {
    const [items, setItems] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getUserInterview()
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page: number) => {
        getUserInterview(page)
    }

    const getUserInterview = (page = 1) => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/user/interview?page=${page}`)
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                } else {
                    setItems(result.data)
                    setCurrentPage(result.current_page)
                    setPerPage(result.per_page)
                    setTotal(result.total)
                    window.scrollTo(0, 0)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const updateUserInterviewShow = (interview: any, show: number) => {
        if (interview.show !== show) {
            axios
                .put(`${process.env.REACT_APP_API2_URL}am/user/interview/${interview.id}/show/${show}`)
                .then(({data: {error, result}}) => {
                    console.log(result)
                    if (error !== '') {
                    } else {
                        const temps = [...items]
                        temps.splice(items.indexOf(interview), 1, interview)
                        interview.show = result.show
                        setItems(temps)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>아워플레이스 소식</PageTitle>
            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <label
                    className={'btn btn-primary'}
                    onClick={() => {
                        window.location.href = `/marketing/interview/add`
                    }}
                >
                    소식 만들기
                </label>

                <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                    <div>
                        {items.map((item: any, index: number) => {
                            return (
                                <div className='col-12' key={index.toString()}>
                                    <div className='card card-custom card-stretch shadow mb-5' style={{borderRadius: 15}}>
                                        <div className='card-header'>
                                            <h1 className='card-title'>{item.id}.</h1>
                                        </div>

                                        <div className='card-body'>
                                            <img src={item.main_image_path} alt='' style={{width: 201, height: 201, objectFit: 'cover'}} />
                                            <div className='separator my-10' />
                                            <h4>
                                                노출 여부 : {item.show === 1 ? 'Y' : 'N'}
                                                <br />
                                                <br />
                                                date : {moment(item.created_at).format('YYYY.MM.DD')}
                                            </h4>
                                        </div>

                                        <div className='card-footer'>
                                            <label
                                                onClick={() => {
                                                    window.open(`${process.env.REACT_APP_WEB_URL}feed/${item.id}/I`)
                                                }}
                                                className='btn btn-primary'
                                            >
                                                보기
                                            </label>
                                            <label
                                                style={{marginLeft: 10}}
                                                onClick={() => {
                                                    window.location.href = `/marketing/interview/${item.id}`
                                                }}
                                                className='btn btn-primary'
                                            >
                                                수정
                                            </label>
                                            {item.show === 1 ? (
                                                <label
                                                    style={{marginLeft: 10}}
                                                    onClick={() => {
                                                        updateUserInterviewShow(item, 0)
                                                    }}
                                                    className='btn btn-danger'
                                                >
                                                    숨김
                                                </label>
                                            ) : (
                                                <label
                                                    style={{marginLeft: 10}}
                                                    onClick={() => {
                                                        updateUserInterviewShow(item, 1)
                                                    }}
                                                    className='btn btn-primary'
                                                >
                                                    노출
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
            </div>
        </>
    )
}
