import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub2} from '../components/TextSub2'
import {isMobile} from 'react-device-detect'
import {toast} from 'react-toastify'
import moment from 'moment'
import 'moment/locale/ko'

export function PriceEdit() {
  const {place_id} = useParams<{place_id: string}>()

  const [place, setPlace] = useState<any>(null)

  const [editInfo, setEditInfo] = useState<any>(null)

  useEffect(() => {
    getPlace()
    getEditInfo()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPlace = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          if (result.price_type === 1) {
            result.place_diff_amount.forEach((temp: any) => {
              if (temp.day === '0,6' || temp.day === '6,0') {
                result.price_max = temp.price
              } else {
                result.price_min = temp.price
              }
            })
          }
          result.title = result.o_place_title
          const images: any[] = []
          result.images.forEach((image: any) => {
            let url = image.image_url.replace('https://s3.hourplace.co.kr/', '')
            images.push({
              ...image,
              image_url: `${process.env.REACT_APP_AWS_IMG_URL}/${url}?s=550x364&t=cover&q=80`,
            })
          })
          result.images = images
          setPlace(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEditInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/edit/info`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setEditInfo(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const update = () => {
    if (place.status === 'hide') {
      if (!window.confirm('관리자 숨김 처리 하시겠어요?')) {
        return false
      }
    } else {
      if (place.price_type === 0 && place.price < 1000) {
        toast.error('금액을 입력해 주세요.')
        return false
      }

      if (place.price_type === 1 && (place.price_min < 1000 || place.price_max < 1000)) {
        toast.error('금액을 입력해 주세요.')
        return false
      }

      if (place.price_type === 1) {
        place.place_diff_amount = [
          {
            title: '평일',
            day: '1,2,3,4,5',
            price: place.price_min,
            day_time_type_a_start: '',
            day_time_type_a_end: '',
            day_time_type_a_price: 0,
            day_time_type_b_start: '',
            day_time_type_b_end: '',
            day_time_type_b_price: 0,
            day_time_type_c_start: '',
            day_time_type_c_end: '',
            day_time_type_c_price: 0,
            is_holiday: 0,
            order: 1,
          },
          {
            title: '주말',
            day: '0,6',
            price: place.price_max,
            day_time_type_a_start: '',
            day_time_type_a_end: '',
            day_time_type_a_price: 0,
            day_time_type_b_start: '',
            day_time_type_b_end: '',
            day_time_type_b_price: 0,
            day_time_type_c_start: '',
            day_time_type_c_end: '',
            day_time_type_c_price: 0,
            is_holiday: 0,
            order: 2,
          },
        ]
      }
    }

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/place/${place_id}`, {...place})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/place/${place_id}`
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>장소 금액 수정</PageTitle>

      <div
        style={{
          padding: isMobile ? 0 : 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {place !== null && (
          <div>
            <h1>
              {place.id}. {place.title}
            </h1>
          </div>
        )}

        {place !== null && (
          <div
            style={{
              padding: isMobile ? 0 : 20,
              borderRadius: 20,
              backgroundColor: 'white',
              width: '100%',
            }}
          >
            <TextSub2 title={'요금제 타입'} subTitle={'체크시 차등'} />

            <input
              className='form-check-input'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              checked={place.price_type === 1}
              onChange={(event) => {
                const temp = Object.assign({}, place)
                temp.price_type = event.target.checked ? 1 : 0
                setPlace(temp)
              }}
            />

            {place.price_type === 0 ? (
              <div>
                <div className='separator my-10' />
                <TextSub2 title={'금액'} subTitle={''} />

                <div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    value={place.price}
                    onChange={(e) => {
                      const temp = Object.assign({}, place)
                      temp.price = e.target.value
                      setPlace(temp)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div style={{width: '50%', paddingRight: 20}}>
                  <div className='separator my-10' />
                  <TextSub2 title={'평일'} subTitle={''} />

                  <div>
                    <input
                      type='text'
                      className='form-control'
                      placeholder=''
                      value={place.price_min}
                      onChange={(e) => {
                        const temp = Object.assign({}, place)
                        temp.price_min = e.target.value
                        setPlace(temp)
                      }}
                    />
                  </div>
                </div>

                <div style={{width: '50%', paddingRight: 20}}>
                  <div className='separator my-10' />
                  <TextSub2 title={'주말'} subTitle={''} />

                  <div>
                    <input
                      type='text'
                      className='form-control'
                      placeholder=''
                      value={place.price_max}
                      onChange={(e) => {
                        const temp = Object.assign({}, place)
                        temp.price_max = e.target.value
                        setPlace(temp)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'요일별 요금표 (개정판/미정)'} subTitle={''} />

            <div>
              {place.place_price.map((day: any, index: number) => {
                return (
                  <div className={'p-4'}>
                    <h3>{moment().day(index).format('dddd')}</h3>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        gap: 10,
                      }}
                    >
                      {day.map((price: any, index: number) => {
                        return (
                          <div
                            style={{
                              justifyItems: 'center',
                            }}
                          >
                            <h4 className={'text-center'}>
                              {`${index}`.padStart(2, '0')} ~ {`${index + 1}`.padStart(2, '0')}
                            </h4>
                            <input type={'number'} defaultValue={price} min={1000} required={true} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'할증 추가 인원 금액'} subTitle={''} />

            <div>
              <select
                className='form-select form-select-solid'
                name='type'
                value={place.price_extra}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.price_extra = e.target.value
                  setPlace(temp)
                }}
              >
                <option value={0}>0</option>
                <option value={5500}>5,500</option>
                <option value={11000}>11,000</option>
              </select>
            </div>

            <div className='separator my-10' />
            <TextSub2 title={'최소 대여 시간'} subTitle={''} />

            {editInfo !== null && (
              <div>
                <select
                  className='form-select form-select-solid'
                  name='type'
                  value={place.min_hour}
                  onChange={(e) => {
                    const temp = Object.assign({}, place)
                    temp.min_hour = e.target.value
                    setPlace(temp)
                  }}
                >
                  {editInfo.times.map((time: any, index: number) => {
                    return (
                      <option key={`time_${index}`} value={time}>
                        {time}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className='separator my-10' />
            <TextSub2 title={'결제 방식'} subTitle={''} />
            <div>
              <select
                className='form-select form-select-solid'
                name='type'
                value={place.payments_type}
                onChange={(e) => {
                  const temp = Object.assign({}, place)
                  temp.payments_type = e.target.value
                  setPlace(temp)
                }}
                disabled={place.is_studio === 0}
              >
                <option key={`payments_type_0`} value={0}>
                  승인결제
                </option>
                <option key={`payments_type_1`} value={1}>
                  바로결제
                </option>
              </select>
            </div>

            <div
              style={{
                width: '100%',
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  padding: 10,
                }}
              >
                <label
                  onClick={() => {
                    update()
                  }}
                  className='btn btn-primary'
                >
                  수정하기
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
