import React, {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {isMobile} from 'react-device-detect'
import moment from 'moment'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'

export function PlaceCategoryRevenueH() {
  const [place, setPlace] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [date] = useState<Date | null>(new Date())

  useEffect(() => {
    registerLocale('ko', ko)
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSpPlaceCountHistory = useCallback(() => {
    setPlace([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/category/revenue`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlace(result)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getSpPlaceCountHistory()
  }, [getSpPlaceCountHistory, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [
      {header: '일자', key: 'id', width: 10},
      {header: '아파트', key: 'id1', width: 10},
      {header: '주택', key: 'id2', width: 10},
      {header: '빌라', key: 'id3', width: 10},
      {header: '원룸', key: 'id4', width: 10},
      {header: '한옥', key: 'id5', width: 10},
      {header: '홈오피스', key: 'id6', width: 10},
      {header: '자연광', key: 'id7', width: 10},
      {header: '호리존', key: 'id8', width: 10},
      {header: '크로마키', key: 'id9', width: 10},
      {header: 'LED', key: 'id10', width: 10},
      {header: '컨셉룸', key: 'id11', width: 10},
      {header: '독채', key: 'id12', width: 10},
      {header: '키친', key: 'id13', width: 10},
      {header: '세트장', key: 'id14', width: 10},
      {header: '무대', key: 'id15', width: 10},
      {header: '무인', key: 'id16', width: 10},
      {header: '녹음실', key: 'id17', width: 10},
      {header: '댄스연습실', key: 'id18', width: 10},
      {header: '음악연습실', key: 'id19', width: 10},
      {header: '파티룸', key: 'id20', width: 10},
      {header: '카페', key: 'id21', width: 10},
      {header: '바/술집', key: 'id22', width: 10},
      {header: '식당', key: 'id23', width: 10},
      {header: '헬스장', key: 'id24', width: 10},
      {header: '요가/필라테스', key: 'id25', width: 10},
      {header: '스포츠', key: 'id26', width: 10},
      {header: '헤어샵', key: 'id27', width: 10},
      {header: '빨래방', key: 'id28', width: 10},
      {header: '오락실/노래방', key: 'id29', width: 10},
      {header: '호텔펜션', key: 'id30', width: 10},
      {header: '클럽', key: 'id31', width: 10},
      {header: '기타 상업공간', key: 'id32', width: 10},
      {header: '사무실', key: 'id33', width: 10},
      {header: '복합문화공간', key: 'id34', width: 10},
      {header: '강의실', key: 'id35', width: 10},
      {header: '공방/작업실', key: 'id36', width: 10},
      {header: '책방', key: 'id37', width: 10},
      {header: '갤러리', key: 'id38', width: 10},
      {header: '공연장/강당', key: 'id39', width: 10},
      {header: '병원/약국', key: 'id40', width: 10},
      {header: '경찰서', key: 'id41', width: 10},
      {header: '학교', key: 'id42', width: 10},
      {header: '학원', key: 'id43', width: 10},
      {header: '웨딩', key: 'id44', width: 10},
      {header: '기타 특수공간', key: 'id45', width: 10},
      {header: '옥상', key: 'id46', width: 10},
      {header: '공장', key: 'id47', width: 10},
      {header: '정원', key: 'id48', width: 10},
      {header: '지하실', key: 'id49', width: 10},
      {header: '창고', key: 'id50', width: 10},
      {header: '빈 공간', key: 'id51', width: 10},
      {header: '캠핑장', key: 'id52', width: 10},
      {header: '공터', key: 'id53', width: 10},
      {header: '폐차장', key: 'id54', width: 10},
      {header: '기타 야외・대형', key: 'id55', width: 10}
    ]

    place.forEach((item: any) => {
      worksheet.addRow({
        id: item.month,
        id1: item.c1,
        id2: item.c2,
        id3: item.c3,
        id4: item.c4,
        id5: item.c5,
        id6: item.c54,
        id7: item.c65,
        id8: item.c66,
        id9: item.c67,
        id10: item.c68,
        id11: item.c69,
        id12: item.c70,
        id13: item.c71,
        id14: item.c72,
        id15: item.c77,
        id16: item.c73,
        id17: item.c74,
        id18: item.c75,
        id19: item.c76,
        id20: item.c78,
        id21: item.c114,
        id22: item.c115,
        id23: item.c116,
        id24: item.c117,
        id25: item.c118,
        id26: item.c119,
        id27: item.c120,
        id28: item.c121,
        id29: item.c122,
        id30: item.c123,
        id31: item.c124,
        id32: item.c125,
        id33: item.c129,
        id34: item.c130,
        id35: item.c131,
        id36: item.c132,
        id37: item.c133,
        id38: item.c134,
        id39: item.c135,
        id40: item.c136,
        id41: item.c137,
        id42: item.c138,
        id43: item.c139,
        id44: item.c140,
        id45: item.c141,
        id46: item.c144,
        id47: item.c145,
        id48: item.c146,
        id49: item.c147,
        id50: item.c148,
        id51: item.c149,
        id52: item.c150,
        id53: item.c151,
        id54: item.c152,
        id55: item.c153,
      })
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `자사_월별_장소_매출_${moment(date).format('YYYY')}.xlsx`)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>자사 일자별 장소 개수</PageTitle>
        <div style={{
          padding: isMobile ? 4 : 20,
          borderRadius: isMobile ? 4 : 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
          <div className="tab-content w-100" id="myTabContent">
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} >
              <label
                onClick={() => {
                  download()
                }}
                className='btn btn-sm btn-secondary'
              >
                다운로드
              </label>
            </div>
            <div className="table-responsive">
              <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
                <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
                <tr>
                  <th scope="col" className="min-w-30px"></th>
                  <th scope="col" className="min-w-100px">일자</th>
                  <th scope="col" className="min-w-100px">아파트</th>
                  <th scope="col" className="min-w-100px">주택</th>
                  <th scope="col" className="min-w-100px">빌라</th>
                  <th scope="col" className="min-w-100px">원룸</th>
                  <th scope="col" className="min-w-100px">한옥</th>
                  <th scope="col" className="min-w-100px">홈오피스</th>
                  <th scope="col" className="min-w-100px">자연광</th>
                  <th scope="col" className="min-w-100px">호리존</th>
                  <th scope="col" className="min-w-100px">크로마키</th>
                  <th scope="col" className="min-w-100px">LED</th>
                  <th scope="col" className="min-w-100px">컨셉룸</th>
                  <th scope="col" className="min-w-100px">독채</th>
                  <th scope="col" className="min-w-100px">키친</th>
                  <th scope="col" className="min-w-100px">세트장</th>
                  <th scope="col" className="min-w-100px">무대</th>
                  <th scope="col" className="min-w-100px">무인</th>
                  <th scope="col" className="min-w-100px">녹음실</th>
                  <th scope="col" className="min-w-100px">댄스연습실</th>
                  <th scope="col" className="min-w-100px">음악연습실</th>
                  <th scope="col" className="min-w-100px">파티룸</th>
                  <th scope="col" className="min-w-100px">카페</th>
                  <th scope="col" className="min-w-100px">바/술집</th>
                  <th scope="col" className="min-w-100px">식당</th>
                  <th scope="col" className="min-w-100px">헬스장</th>
                  <th scope="col" className="min-w-100px">요가/필라테스</th>
                  <th scope="col" className="min-w-100px">스포츠</th>
                  <th scope="col" className="min-w-100px">헤어샵</th>
                  <th scope="col" className="min-w-100px">빨래방</th>
                  <th scope="col" className="min-w-100px">오락실/노래방</th>
                  <th scope="col" className="min-w-100px">호텔펜션</th>
                  <th scope="col" className="min-w-100px">클럽</th>
                  <th scope="col" className="min-w-100px">기타 상업공간</th>
                  <th scope="col" className="min-w-100px">사무실</th>
                  <th scope="col" className="min-w-100px">복합문화공간</th>
                  <th scope="col" className="min-w-100px">강의실</th>
                  <th scope="col" className="min-w-100px">공방/작업실</th>
                  <th scope="col" className="min-w-100px">책방</th>
                  <th scope="col" className="min-w-100px">갤러리</th>
                  <th scope="col" className="min-w-100px">공연장/강당</th>
                  <th scope="col" className="min-w-100px">병원/약국</th>
                  <th scope="col" className="min-w-100px">경찰서</th>
                  <th scope="col" className="min-w-100px">학교</th>
                  <th scope="col" className="min-w-100px">학원</th>
                  <th scope="col" className="min-w-100px">웨딩</th>
                  <th scope="col" className="min-w-100px">기타 특수공간</th>
                  <th scope="col" className="min-w-100px">옥상</th>
                  <th scope="col" className="min-w-100px">공장</th>
                  <th scope="col" className="min-w-100px">정원</th>
                  <th scope="col" className="min-w-100px">지하실</th>
                  <th scope="col" className="min-w-100px">창고</th>
                  <th scope="col" className="min-w-100px">빈 공간</th>
                  <th scope="col" className="min-w-100px">캠핑장</th>
                  <th scope="col" className="min-w-100px">공터</th>
                  <th scope="col" className="min-w-100px">폐차장</th>
                  <th scope="col" className="min-w-100px">기타 야외・대형</th>
                </tr>
                </thead>
                <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
                {
                  loading ?
                    <tr style={{height: 98}}>
                      <td colSpan={44}>
                        <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                        </div>
                      </td>
                    </tr>
                    :
                    place !== null && place.map((item: any, index: number) => {
                      return (
                        <tr key={`${index}`} style={{height: 98}}>
                          <td></td>
                          <td>{item.month}</td>
                          <td>{item.c1.toLocaleString('en')}</td>
                          <td>{item.c2.toLocaleString('en')}</td>
                          <td>{item.c3.toLocaleString('en')}</td>
                          <td>{item.c4.toLocaleString('en')}</td>
                          <td>{item.c5.toLocaleString('en')}</td>
                          <td>{item.c54.toLocaleString('en')}</td>
                          <td>{item.c65.toLocaleString('en')}</td>
                          <td>{item.c66.toLocaleString('en')}</td>
                          <td>{item.c67.toLocaleString('en')}</td>
                          <td>{item.c68.toLocaleString('en')}</td>
                          <td>{item.c69.toLocaleString('en')}</td>
                          <td>{item.c70.toLocaleString('en')}</td>
                          <td>{item.c71.toLocaleString('en')}</td>
                          <td>{item.c72.toLocaleString('en')}</td>
                          <td>{item.c77.toLocaleString('en')}</td>
                          <td>{item.c73.toLocaleString('en')}</td>
                          <td>{item.c74.toLocaleString('en')}</td>
                          <td>{item.c75.toLocaleString('en')}</td>
                          <td>{item.c76.toLocaleString('en')}</td>
                          <td>{item.c78.toLocaleString('en')}</td>
                          <td>{item.c114.toLocaleString('en')}</td>
                          <td>{item.c115.toLocaleString('en')}</td>
                          <td>{item.c116.toLocaleString('en')}</td>
                          <td>{item.c117.toLocaleString('en')}</td>
                          <td>{item.c118.toLocaleString('en')}</td>
                          <td>{item.c119.toLocaleString('en')}</td>
                          <td>{item.c120.toLocaleString('en')}</td>
                          <td>{item.c121.toLocaleString('en')}</td>
                          <td>{item.c122.toLocaleString('en')}</td>
                          <td>{item.c123.toLocaleString('en')}</td>
                          <td>{item.c124.toLocaleString('en')}</td>
                          <td>{item.c125.toLocaleString('en')}</td>
                          <td>{item.c129.toLocaleString('en')}</td>
                          <td>{item.c130.toLocaleString('en')}</td>
                          <td>{item.c131.toLocaleString('en')}</td>
                          <td>{item.c132.toLocaleString('en')}</td>
                          <td>{item.c133.toLocaleString('en')}</td>
                          <td>{item.c134.toLocaleString('en')}</td>
                          <td>{item.c135.toLocaleString('en')}</td>
                          <td>{item.c136.toLocaleString('en')}</td>
                          <td>{item.c137.toLocaleString('en')}</td>
                          <td>{item.c138.toLocaleString('en')}</td>
                          <td>{item.c139.toLocaleString('en')}</td>
                          <td>{item.c140.toLocaleString('en')}</td>
                          <td>{item.c141.toLocaleString('en')}</td>
                          <td>{item.c144.toLocaleString('en')}</td>
                          <td>{item.c145.toLocaleString('en')}</td>
                          <td>{item.c146.toLocaleString('en')}</td>
                          <td>{item.c147.toLocaleString('en')}</td>
                          <td>{item.c148.toLocaleString('en')}</td>
                          <td>{item.c149.toLocaleString('en')}</td>
                          <td>{item.c150.toLocaleString('en')}</td>
                          <td>{item.c151.toLocaleString('en')}</td>
                          <td>{item.c152.toLocaleString('en')}</td>
                          <td>{item.c153.toLocaleString('en')}</td>
                        </tr>
                      )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </>
  )
}
