import React from 'react'

const Widget001: React.FC<{
  title: string
  count: number
  onClick?: any
}> = ({title, count, onClick}) => {
  return <div className='col-xl-4' onClick={() => {
    if (onClick !== undefined) {
      onClick()
    }
  }} style={{
    cursor: onClick === undefined ? 'default' : 'pointer',
  }}>
    <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
      <h4>
        {title}
      </h4>
      <h2>
        {Number(count).toLocaleString('en')}
      </h2>
    </div>
  </div>
}

const Widget002: React.FC<{
  title: string
  text: string
  onClick?: any
}> = ({title, text, onClick}) => {
  return <div className='col-xl-4' onClick={() => {
    if (onClick !== undefined) {
      onClick()
    }
  }} style={{
    cursor: onClick === undefined ? 'default' : 'pointer',
  }}>
    <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
      <h4>
        {title}
      </h4>
      <h2 style={{
        whiteSpace: 'pre-wrap',
      }}>
        {text}
      </h2>
    </div>
  </div>
}

export {Widget001, Widget002}
