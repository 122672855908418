import React, {useEffect, useState} from 'react'
// @ts-ignore
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from "axios";
import {TextSub} from "../../../components/TextSub";
import Pagination from "react-js-pagination";
import moment from "moment";
import Modal from "../../../components/Modal";
import {toast} from "react-toastify";

export function AdvertiseMainBannerIndex() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [modalVisible, setModalVisible] = useState(false);

  const [advMainBanner, setAdvMainBanner] = useState<any>([])
  const [item, setItem] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [advTotalCount, setAdvTotalCount] = useState(0)
  const [advPwCount, setAdvPwCount] = useState(0)
  const [advPfCount, setAdvPfCount] = useState(0)
  const [advICount, setAdvICount] = useState(0)
  const [advECount, setAdvECount] = useState(0)
  const [advCCount, setAdvCCount] = useState(0)
  const [advRCount, setAdvRCount] = useState(0)

  useEffect(() => {
    getNetworkPlaces();
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getNetworkPlaces(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getNetworkPlaces('', 1)
  }

  const handlePageChange = (page: number) => {
    getNetworkPlaces(status, page)
  }

  const getNetworkPlaces = (status = '', page = 1) => {
    setAdvMainBanner([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)

    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/main/banner/MB?&page=${page}&status=${status}&search=${search}`).then(({data: {error, time, result}}) => {
      console.log(result)
      if (error !== '') {
      } else {
        setAdvMainBanner(result.advertise.data);
        setCurrentPage(result.advertise.current_page);
        setPerPage(result.advertise.per_page);
        setTotal(result.advertise.total);
        window.scrollTo(0, 0)
        // this.forceUpdate()
        getAdvCount();
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const getAdvCount = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/adv/main/banner/MB/count`).then(({data: {error, time, result}}) => {
      console.log(result)
      if (error !== '') {
      } else {
        // this.forceUpdate()
        setAdvTotalCount(result.advTotalCount);
        setAdvPwCount(result.advPwCount);
        setAdvPfCount(result.advPfCount);
        setAdvICount(result.advICount);
        setAdvECount(result.advECount);
        setAdvCCount(result.advCCount);
        setAdvRCount(result.advRCount);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const putMemo = (item: any) => {
    console.log(item);
    axios.put(`${process.env.REACT_APP_API2_URL}am/adv/${item.advMainBannerAdvertiseGroupId}/main/banner/memo`, {memo: item.memo,  advType: item.advType}).then(({data: {error, time, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error);
      } else {
        const temps = [...advMainBanner];
        const index = advMainBanner.map((place: any)=>place.id).indexOf(item.id)
        temps.splice(index, 1, item);
        setAdvMainBanner(temps);
        // window.location.reload();
        setModalVisible(false);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>메인 배너 광고</PageTitle>
    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <label className={'btn btn-primary'} onClick={() => {window.location.href = `/advertise/main/banner/add`;}}>
        광고 등록하기
      </label>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
        <select className='form-control' name='type' value={status} onChange={(e) => {onTab(e.target.value)}}>
          <option value={''}>모두보기 ({advTotalCount})</option>
          <option value={'PW'}>결제 대기 ({advPwCount})</option>
          <option value={'PF'}>결제 완료 ({advPfCount})</option>
          <option value={'I'}>광고진행중 ({advICount})</option>
          <option value={'E'}>광고 종료 ({advECount})</option>
          <option value={'C'}>광고 취소 ({advCCount})</option>
          <option value={'R'}>환불 완료 ({advRCount})</option>
        </select>
        <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <input type='text' className='form-control' placeholder='광고 ID, 호스트 ID, 호스트 닉네임, 호스트 이름, 이메일, 휴대폰 번호, 장소 번호, 장소명' value={search} style={{flex: 1, width:'70%'}}
              onChange={(e) => {setSearch(e.target.value)}}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
          />
          <label onClick={() => {onSearch()}} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>
      </div>
      {
        loading ?
            <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
          <div style={{width: '100%'}}>
            {
              null !== advMainBanner && undefined !== advMainBanner &&
              advMainBanner.map((place: any, index: number) => {
                return (
                    <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}} key={index.toString()}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <h2>
                          {place.advMainBannerAdvertiseGroupId}. [{place.advStatusDisplay}]
                        </h2>
                      </div>

                      <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <div style={{width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={place.userImageUrl} alt='' style={{width: 100, height: 100, objectFit: 'cover', borderRadius: 100}} />
                          </div>

                          <div style={{marginLeft: 10}}>
                            {
                                place.userBlack === 1 &&
                                <p style={{color: 'red'}}>
                                  {place.userBlackComment}
                                </p>
                            }
                            <TextSub title={'닉네임 (이름)'} subTitle={`${place.userName} (${place.userRealName})`} marginTop={0} />
                            <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(place.userBirth))).asYears())}세`} marginTop={-10} />
                            <TextSub title={'전화번호'} subTitle={place.userPhone} marginTop={-10} />
                            <TextSub title={'이메일'} subTitle={place.userEmail} marginTop={-10} />

                            <TextSub title={'가입일'} subTitle={`${place.userCreatedAt}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(place.userCreatedAt)).asDays()} 일전`} marginTop={-10} />
                            <TextSub title={'마지막 활성 시간'} subTitle={`${moment(place.userLastLoginedAt).format('MM-DD')}, ${Math.floor((moment.duration(moment().diff(moment(place.userLastLoginedAt, 'YYYY-MM-DD'))).asDays() * 100) / 100)} 일전`} marginTop={-10} />
                            <TextSub title={'메모'} subTitle={place.memo} marginTop={-10} />
                          </div>
                        </div>
                        <div>
                          <label style={{marginLeft: 10}} onClick={() => {
                            window.open(`/user/${place.userId}`)
                          }} className='btn btn-sm btn-primary'>자세히 보기</label>
                        </div>
                      </div>

                      <div style={{paddingTop: '15px'}}>
                        <table style={{fontSize: '15px', width: '100%'}}>
                          <colgroup>
                            <col style={{ width: '10%' }}/>
                            <col style={{ width: 'auto' }}/>
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>광고 일정</td>
                              <td>{place.advSchedule}</td>
                            </tr>
                            <tr>
                              <td>결제 금액</td>
                              <td>{place.advPrice.toLocaleString()} (부가세 포함)</td>
                            </tr>
                            <tr>
                              <td>결제일 </td>
                              <td>{place.advPaidTime}</td>
                            </tr>
                            <tr>
                              <td><br/></td>
                            </tr>
                            <tr>
                              <td><br/></td>
                            </tr>
                            {
                              place.advNetworkCancelScheduleDisplay !== undefined && place.advNetworkCancelScheduleDisplay !== '' &&
                              <>
                                <tr>
                                  <td>취소 일정</td>
                                  <td>{place.advNetworkCancelScheduleDisplay}</td>
                                </tr>
                                <tr>
                                  <td>취소 금액</td>
                                  <td>{place.advNetworkCancelSchedulePriceDisplay}</td>
                                </tr>
                                <tr>
                                  <td style={{verticalAlign: 'top'}}>취소일</td>
                                  <td>
                                    {
                                      place.advCancelledTime.map((ct: any, index: number) => {
                                        return(
                                            <>
                                              <span key={index.toString()}>{ct.adv_cancelled_time}</span><br/>
                                            </>
                                        )
                                      })
                                    }
                                  </td>
                                </tr>
                              </>
                            }
                          </tbody>
                        </table>
                      </div>
                      <div style={{paddingTop: 20, display: 'flex'}}>
                        <label onClick={() => {
                          window.open(`/advertise/main/banner/${place.advMainBannerAdvertiseGroupId}`)
                        }} className='btn btn-sm btn-primary'>자세히 보기</label>
                        <label style={{marginLeft: 15}} onClick={() => {
                          //setMemo(place.memo);
                          //setMainBannerGroupId(place.advMainBannerAdvertiseGroupId);
                          setItem(place);
                          setModalVisible(true);
                        }} className='btn btn-sm btn-primary'>메모하기</label>
                      </div>
                    </div>
                )
              })
            }
          </div>
      }

      {
          advMainBanner.length > 0 &&
          <div style={{marginTop: 20}}>
            <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
          </div>
      }

    </div>
    {
        modalVisible &&
        <Modal visible={modalVisible} closable={true} maskClosable={true} onClose={() => {
          setModalVisible(false)
        }} className={''}>
          <div style={{padding: 20, width: '100%'}}>
            {
                <>
                  <div className='form-check form-check-custom form-check-solid form-check-sm' style={{marginRight: 20, paddingBottom: 10}}>
                    <textarea style={{width: '100%', height: 200}} value={item.memo}
                              onChange={(e) => {

                                const temp = Object.assign({}, item)
                                temp.memo = e.target.value;
                                setItem(temp);
                              }}
                    />
                  </div>
                </>
            }
          </div>
          <div style={{padding: 5, width: '100%', textAlign: 'center'}}>
            <label onClick={() => {
              putMemo(item);
            }} style={{marginLeft: 10}} className='btn btn-sm btn-primary'>등록</label>
          </div>
        </Modal>
    }
  </>
}

