import React from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'

export default class MagazineTitle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      info: props.info,
    }
  }

  render() {
    const modalClose = () => this.setState({ modalShow: false })
    const { info } = this.state
    return (
      <div style={{ ...info.wrapStyle }}>
        <EditorModal
          show={this.state.modalShow}
          info={info}
          onHide={modalClose}
          forceUpdate={() => {
            this.forceUpdate()
            this.props.onChange(info)
          }}
        />
        <p style={{ ...info.textStyle }}>{info.text}</p>
        <div style={{ position: 'absolute', right: -60, top: 0 }}>
          <Button
            variant="primary"
            size="sm"
            onClick={() => this.setState({ info, modalShow: true })}>
            편집
          </Button>
        </div>
      </div>
    )
  }
}

class EditorModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      info: props.info,
    }
  }

  render() {
    const { info } = this.state
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <Form
            noValidate
            validated={false}
            onSubmit={(e) => {
              e.preventDefault()
              return false
            }}>
            <Form.Group as={Col} controlId="validationCustomUsername">
              <Form.Label>text</Form.Label>
              <Form.Group as={Row}>
                <InputGroup className="pr-4">
                  <Form.Control
                    type="text"
                    placeholder="text"
                    aria-describedby="inputGroupPrepend"
                    value={info.text}
                    onChange={(e) => {
                      info.text = e.target.value
                      this.props.forceUpdate()
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
