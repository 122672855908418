import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function MarketingMagazine() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getMagazines()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getMagazines(page)
  }

  const getMagazines = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/magazine?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateMagazineShow = (magazine: any, show: number) => {
    if (magazine.show !== show) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/magazine/${magazine.id}/show`, {
          show,
        })
        .then(({data: {error, result}}) => {
          console.log(result)
          if (error !== '') {
          } else {
            const temps = [...items]
            temps.splice(items.indexOf(magazine), 1, magazine)
            magazine.show = result.show
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return <>
    <PageTitle breadcrumbs={[]}>매거진</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <label className={'btn btn-primary'} onClick={() => {
        window.location.href = `/marketing/magazine/0`
      }}>
        매거진 만들기
      </label>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div>
          {items.map((item: any, index: number) => {
            return (
              <div className='col-12' key={index.toString()}>
                <div className='card card-custom card-stretch shadow mb-5'>
                  <div className='card-header'>
                    <h1 className='card-title'>
                      {item.id}. {item.title}
                    </h1>
                  </div>
                  <div className='card-body'>
                    <img
                      src={item.image_url}
                      alt=''
                      style={{width: 400, height: 220, objectFit: 'cover'}}
                    />
                    <div className='separator my-10' />
                    <h4>
                      노출여부 : {item.show === 1 ? 'Y' : 'N'}
                      <br />
                      {item.sub_title}
                      <br />
                      {item.date}
                    </h4>
                  </div>
                  <div className='card-footer'>
                    <label onClick={() => {
                      window.open(`${process.env.REACT_APP_WEB_URL}magazine/${item.id}`)
                    }} className='btn btn-primary'>보기</label>
                    <label style={{marginLeft: 10}} onClick={() => {
                      window.location.href = `/marketing/magazine/${item.id}`
                    }} className='btn btn-primary'>수정</label>
                    {
                      item.show === 1 ?
                        <label style={{marginLeft: 10}} onClick={() => {
                          updateMagazineShow(item, 0)
                        }} className='btn btn-danger'>숨김</label>
                        :
                        <label style={{marginLeft: 10}} onClick={() => {
                          updateMagazineShow(item, 1)
                        }} className='btn btn-primary'>노출</label>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>

      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
  </>
}
