import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import classNames from 'classnames'
import {UserItem} from '../../components/UserItem'

export function UserList() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [userCount, setUserCount] = useState(0)
  const [guestCount, setGuestCount] = useState(0)
  const [hostCount, setHostCount] = useState(0)
  const [blackCount, setBlackCount] = useState(0)
  const [leaveCount, setLeaveCount] = useState(0)
  const [lastCount, setLastCount] = useState(0)
  const [gradeSCount, setGradeSCount] = useState(0)
  const [gradeACount, setGradeACount] = useState(0)
  const [gradeBCount, setGradeBCount] = useState(0)
  const [gradeCCount, setGradeCCount] = useState(0)
  const [gradeNCount, setGradeNCount] = useState(0)
  const [gradeFCount, setGradeFCount] = useState(0)
  const [superHostCount, setSuperHostCount] = useState(0)

  useEffect(() => {

    getUsers(status)

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getUsers(status, page)
  }

  const onSearch = () => {
    getUsers(status, 1)
  }

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getUsers(tabStatus, 1)
  }

  const getUsers = (status = '', page = 1) => {
    setItems([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/users?type=${status}&search=${search}&page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.users.data)
          setCurrentPage(result.users.current_page)
          setPerPage(result.users.per_page)
          setTotal(result.users.total)

          setUserCount(result.user_count)
          setGuestCount(result.guest_count)
          setHostCount(result.host_count)
          setBlackCount(result.black_count)
          setLeaveCount(result.leave_count)
          setLastCount(result.last_count)
          setGradeSCount(result.grade_s_count)
          setGradeACount(result.grade_a_count)
          setGradeBCount(result.grade_b_count)
          setGradeCCount(result.grade_c_count)
          setGradeNCount(result.grade_n_count)
          setGradeFCount(result.grade_f_count)
          setSuperHostCount(result.super_host_count)


          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>사용자</PageTitle>

    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>

        <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <label onClick={() => {
            onTab('')
          }} className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}>모두보기 ({userCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('host')
          }} className={classNames('btn', {'btn-dark': status === 'host'}, {'btn-btn-white': status !== 'host'})}>호스트 ({hostCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('guest')
          }} className={classNames('btn', {'btn-dark': status === 'guest'}, {'btn-btn-white': status !== 'guest'})}>게스트 ({guestCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('black')
          }} className={classNames('btn', {'btn-dark': status === 'black'}, {'btn-btn-white': status !== 'black'})}>블랙 ({blackCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('grade_s')
          }} className={classNames('btn', {'btn-dark': status === 'grade_s'}, {'btn-btn-white': status !== 'grade_s'})}>S ({gradeSCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('grade_a')
          }} className={classNames('btn', {'btn-dark': status === 'grade_a'}, {'btn-btn-white': status !== 'grade_a'})}>A ({gradeACount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('grade_b')
          }} className={classNames('btn', {'btn-dark': status === 'grade_b'}, {'btn-btn-white': status !== 'grade_b'})}>B ({gradeBCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('grade_c')
          }} className={classNames('btn', {'btn-dark': status === 'grade_c'}, {'btn-btn-white': status !== 'grade_c'})}>C ({gradeCCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('grade_n')
          }} className={classNames('btn', {'btn-dark': status === 'grade_n'}, {'btn-btn-white': status !== 'grade_n'})}>N ({gradeNCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('grade_f')
          }} className={classNames('btn', {'btn-dark': status === 'grade_f'}, {'btn-btn-white': status !== 'grade_f'})}>F ({gradeFCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('last')
          }} className={classNames('btn', {'btn-dark': status === 'last'}, {'btn-btn-white': status !== 'last'})}>휴면 ({lastCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('leave')
          }} className={classNames('btn', {'btn-dark': status === 'leave'}, {'btn-btn-white': status !== 'leave'})}>탈퇴 ({leaveCount.toLocaleString('en')})</label>

          <label onClick={() => {
            onTab('superhost')
          }} className={classNames('btn', {'btn-dark': status === 'superhost'}, {'btn-btn-white': status !== 'superhost'})}>최고의호스트 ({superHostCount.toLocaleString('en')})</label>

        </div>
        <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <input type="text" className="form-control" placeholder="회원번호, 닉네임, 이름, 이메일, 전화번호 (-없이)" value={search}
            style={{flex: 1}}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {onSearch()}} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>

        {
          loading ?
            <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div style={{width: '100%'}}>
              {
                items.map((item: any, index: number) => {
                  return <div style={{
                    marginTop: 30,
                    width: '100%',
                  }} key={item.id.toString()}>

                    <UserItem user={item} />

                  </div>
                })
              }
            </div>
        }


      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
  </>
}

