import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import moment from 'moment'
import {TextSub} from '../../../components/TextSub'

export function PayHostCashView() {
  const [searchValues, setSearchValues] = useState({
    status: '',
    currentPage: 0,
    refresh: 0,
    search: '',
  })

  const [pays, setPays] = useState<any>([])
  const [total, setTotal] = useState(0)

  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)

  const [bottom, setBottom] = useState(false)
  const [moreLoading, setMoreLoading] = useState(false)

  useEffect(() => {
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = () => {
    if (search !== '') {
      setSearchValues({
        ...searchValues,
        search: `${search}`,
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    } else {
      setSearchValues({
        ...searchValues,
        search: '',
        currentPage: 0,
        refresh: searchValues.refresh + 1,
      })
    }
  }

  useEffect(() => {
    if (bottom) more()
  }, [bottom]) // eslint-disable-line react-hooks/exhaustive-deps

  const more = () => {
    if (pays.length < total) {
      setSearchValues({
        ...searchValues,
        currentPage: searchValues.currentPage + 1,
      })
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    const searchPage = searchValues.currentPage + 1
    searchPage < 2 ? setLoading(true) : setMoreLoading(true)
    const url = `${process.env.REACT_APP_API2_URL}am/pay/cash/host/view?status=${searchValues.status}&page=${searchPage}&search=${searchValues.search}`
    axios
      .get(url, {cancelToken: source.token})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          const lists = result.pays.data
          lists.forEach((pay: any) => {
            pay.checked = false
          })
          if (result.pays.current_page === 1) {
            setPays(lists)
          } else {
            setPays([...pays, ...lists])
          }
          setTotal(result.pays.total)
        }
        setBottom(false)
        setLoading(false)
        setMoreLoading(false)
      })
      .catch((error) => {
        console.log(error)
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          setBottom(false)
          setLoading(false)
          setMoreLoading(false)
        }
      })
    return () => source.cancel(`${searchValues.status} ${searchValues.currentPage} ${searchValues.search} ${searchValues.refresh}`)
  }, [searchValues])

  const listenScrollEvent = () => {
    if ((document.getElementById('root')?.offsetHeight ?? 0) - window.scrollY <= 2000) {
      setBottom(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>호스트 정산(캐시 출금_조회용)</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginTop: 20, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <input
              type='text'
              className='form-control'
              placeholder='호스트 사용자 번호'
              value={search}
              style={{
                flex: 1,
              }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearch()
                }
              }}
            />
            <label
              className='btn btn-dark'
              style={{width: 100}}
              onClick={() => {
                onSearch()
              }}
            >
              검색
            </label>
          </div>
        </div>
      </div>

      {loading ? (
        <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <span className='indicator-progress' style={{display: 'block'}}>
            Loading...
            <span className='spinner-border spinner-border-sm align-middle ms-2' />
          </span>
        </div>
      ) : (
        <div
          style={{
            marginTop: 24,
            borderRadius: 20,
          }}
        >
          <div className='tab-content' id='myTabContent'>
            {pays.map((pay: any, index: number) => {
              return (
                <div
                  key={index.toString()}
                  style={{
                    marginBottom: 24,
                    padding: 40,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    position: 'relative',
                  }}
                >
                  <h1 style={{marginBottom: 32}}>
                    {pay.status === 'W' ? '호스트 정산 정보 입력' : ''}
                    {pay.status === 'V' ? '정산대기' : ''}
                    {pay.status === 'P' ? '정산중' : ''}
                    {pay.status === 'D' ? '보류' : ''}
                    {pay.status === 'C' ? '정산취소' : ''}
                    {pay.status === 'S' ? '정산완료' : ''}
                  </h1>

                  <TextSub title={'정산번호'} subTitle={pay.id} />

                  <TextSub title={'정산 요청일'} subTitle={moment(pay.created_at, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} />

                  <TextSub title={'정산 예정일'} subTitle={moment(pay.created_at, 'YYYYMMDDHHmmss').add(1, 'days').format('YYYY-MM-DD')} />

                  <TextSub title={'정산 요청 금액\n(공급가액)'} subTitle={pay.price.toLocaleString('en')} />

                  <TextSub title={'개인 정산 금액'} subTitle={pay.individual_price.toLocaleString('en')} />

                  <TextSub title={'사업자 정산 금액\n(부가세 있음)'} subTitle={pay.company_price_tax.toLocaleString('en')} />

                  <TextSub title={'사업자 정산 금액\n(부가세 없음)'} subTitle={pay.price.toLocaleString('en')} />

                  <TextSub title={'호스트'} subTitle={pay.user_id + '. ' + pay.real_name} popUrl={`/user/${pay.user_id}`} />

                  <TextSub title={'사업자 등록 번호'} subTitle={pay.corp_num} />

                  <div className='separator my-10' />

                  <h3 style={{marginTop: 30, marginBottom: 2}}>호스트 입금 정보</h3>

                  <TextSub title={'정산 유형'} subTitle={pay.type_text} />

                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'정산 메모'} subTitle={pay.memo_pay} color={'red'} />
                    </div>
                  </div>

                  <div className='separator my-10' />

                  <h3 style={{marginTop: 30, marginBottom: 2}}>호스트 지불 정보</h3>

                  {pay.status !== 'W' ? (
                    <>
                      <TextSub title={'지급액'} subTitle={Number(pay.pay).toLocaleString('en')} />
                      <TextSub title={'지급일'} subTitle={pay.pay_day} />
                      <TextSub title={'타입'} subTitle={pay.type_text} />
                    </>
                  ) : (
                    <p
                      style={{
                        marginTop: 16,
                        fontWeight: 500,
                        fontSize: 15,
                        color: '#C9CDD2',
                      }}
                    >
                      정산 정보 없음
                    </p>
                  )}
                </div>
              )
            })}

            {moreLoading && (
              <div
                style={{
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className='indicator-progress' style={{display: 'block'}}>
                  Loading...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
