import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Modal from '../../components/Modal'
import {Button} from 'react-bootstrap-v5'

export function UserGroup() {

  const [items, setItems] = useState<any>([])

  const [modalVisible, setModalVisible] = useState(false)

  const [isEdit, setIsEdit] = useState(false)
  const [group, setGroup] = useState<any>(null)

  const [name, setName] = useState('')
  const [genre, setGenre] = useState('')

  useEffect(() => {

    getGroups()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getGroups = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/user/group`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const createGroup = () => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/user/group`, {name, genre})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          window.location.href = `/user/group/${result.id}`
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateGroup = () => {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/user/group/${group.id}`, {name, genre})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          setModalVisible(false)
          getGroups()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>사용자 그룹</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <label className={'btn btn-primary'} onClick={() => {
          setName('')
          setGenre('')
          setIsEdit(false)
          setModalVisible(true)
        }}>
          그룹 만들기
        </label>
      </div>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='table-responsive'>
          <table className='table table-striped gy-7 gs-7'>
            <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              <th className='min-w-100px'>#</th>
              <th className='min-w-100px'>그룹</th>
              <th className='min-w-100px'>장르</th>
              <th className='min-w-100px'>사용자</th>
              <th className='min-w-100px'>촬영횟수</th>
              <th className='min-w-100px'>예약횟수</th>
              <th className='min-w-100px'>결제금액</th>
            </tr>
            </thead>
            <tbody>
            {
              items.map((item: any, index: number) => {
                return [
                  <tr key={`group_${index}`}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.genre}</td>
                    <td>{item.user}</td>
                    <td>{item.booking_completed}</td>
                    <td>{item.booking}</td>
                    <td>{item.total_price.toLocaleString('en')}</td>
                  </tr>
                  ,
                  <tr key={`group_${index}_footer`}>
                    <td colSpan={10}>
                      <label onClick={() => {
                        window.open(`/user/group/${item.id}`)
                      }} className='btn btn-primary'>보기</label>
                      <label onClick={() => {
                        setGroup(item)
                        setName(item.name)
                        setGenre(item.genre)
                        setIsEdit(true)
                        setModalVisible(true)
                      }} className='btn btn-secondary mx-4'>수정하기</label>
                    </td>
                  </tr>,
                ]
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>

    {
      modalVisible &&
      <Modal
        visible={modalVisible}
        closable={true}
        maskClosable={true}
        onClose={() => {
          setModalVisible(false)
        }} className={''}>
        <div>
          <div className='row'>
            <div className='col-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <p style={{
                  width: 156,
                  fontWeight: 500,
                  fontSize: 15,
                  color: '#9EA4AA',
                }}>
                  이름
                </p>
                <input
                  type='text'
                  className='form-control'
                  placeholder='이름을 입력해 주세요.'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
              </div>

              <div style={{
                marginTop: 16,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <p style={{
                  width: 156,
                  fontWeight: 500,
                  fontSize: 15,
                  color: '#9EA4AA',
                }}>
                  장르
                </p>
                <input
                  type='text'
                  className='form-control'
                  placeholder='장르를 입력해 주세요.'
                  value={genre}
                  onChange={(e) => {
                    setGenre(e.target.value)
                  }}
                />
              </div>

              {
                isEdit ?
                  <div style={{marginTop: 40}}>
                    <Button variant='primary' onClick={() => updateGroup()}>
                      수정 하기
                    </Button>
                  </div>
                  :
                  <div style={{marginTop: 40}}>
                    <Button variant='primary' onClick={() => createGroup()}>
                      등록 하기
                    </Button>
                  </div>
              }

            </div>
          </div>
        </div>
      </Modal>
    }
  </>
}

