import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {PageTitle} from '../../../_metronic/layout/core'
import {Widget001} from '../components/Widget'
import {isMobile} from 'react-device-detect'

export function TeamDashboard() {
  const [dashboard, setDashBoard] = useState<any>(null)

  useEffect(() => {
    getDashboard()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getDashboard = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/team/dashboard`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setDashBoard(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>팀 현황</PageTitle>
      <div style={{padding: isMobile ? 0 : 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: isMobile ? 0 : 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='row'>
            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>팀 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'팀 생성 현황'} count={dashboard.teamCount} />
                      <Widget001 title={'팀 사용 현황'} count={dashboard.teamActiveCount} />
                      <Widget001 title={'팀 삭제 현황'} count={dashboard.teamDeleteCount} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>팀 소속 멤버 현황</h3>
                <div className='row'>{null != dashboard && <Widget001 title={'팀 멤버 회원 수'} count={dashboard.teamMemberCount} />}</div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>팀 예약 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'팀 공유 예약 수'} count={dashboard.teamBookingCount} />
                      <Widget001 title={'팀 결제 완료 예약 수'} count={dashboard.teamBookingPaidCount} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>팀 결제 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'팀 총 결제 금액'} count={dashboard.teamAllGuestPriceVat} />
                      <Widget001 title={'팀 총 결제 수수료'} count={dashboard.teamAllPriceFee} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row col-xl-12' style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              <div className='gutter-b col-xl-12'>
                <h3>팀 포인트 현황</h3>
                <div className='row'>
                  {null != dashboard && (
                    <>
                      <Widget001 title={'팀 총 포인트'} count={dashboard.teamTotalPoint} />
                      <Widget001 title={'팀 총 사용 포인트'} count={dashboard.teamTotalUsePoint} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
