import React, {forwardRef, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export function UserTrackingDetailV2() {

  const {user_id} = useParams<{user_id: string}>()
  const [items, setItems] = useState<any>([])

  useEffect(() => {

    getTrackings()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getTrackings = (changeDate= moment(date).format('YYYYMMDD')) => {

    console.log(changeDate);

    axios
      .get(`${process.env.REACT_APP_API2_URL}am/log/actions/user/${user_id}/${changeDate}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [date, setDate] = useState<Date | null>(new Date())
  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  return <>
    <PageTitle breadcrumbs={[]}>{user_id} 사용자 트래킹</PageTitle>

    <div style={{ padding: 20, borderRadius: 20, backgroundColor: 'white' }} >
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent:'center' }} >
        <div style={{ marginRight: 20 }} >
          <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat="yyyy-MM-dd" showFullMonthYearPicker selected={date}
                      onChange={(changeDate) => {
                        setDate(changeDate)
                        getTrackings(moment(changeDate).format('YYYYMMDD'))
                      }
          } />
      </div>
    </div>


    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>

        <div style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          width: '100%',
        }}>

          <div className='table-responsive'>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th className='min-w-100px'>ID</th>
                <th className='min-w-100px'>사용자</th>
                <th className='min-w-100px'>경로</th>
                <th className='min-w-100px'>액션</th>
                <th className='min-w-100px'>type</th>
                <th className='min-w-100px'>type1</th>
                <th className='min-w-100px'>type2</th>
                <th className='min-w-100px'>index</th>
                <th className='min-w-100px'>value</th>
                <th className='min-w-200px'>시간</th>
              </tr>
              </thead>
              <tbody>
              { items !== null &&
                items.map((item: any, index: number) => {
                  return <>
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.user_id}</td>
                      <td>{item.path}</td>
                      <td>{item.action}</td>
                      <td>{item.type}</td>
                      <td>{item.type1}</td>
                      <td>{item.type2}</td>
                      <td>{item.index}</td>
                      <td>{item.value}</td>
                      <td>{item.created_at}</td>
                    </tr>
                  </>
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>
}

