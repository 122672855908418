import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {Button} from 'react-bootstrap'
import MainCategoryPlacesCard from '../../components/MainCategoryPlacesCard'
import ImagePath from '../../../commons/ImagePath'

export function MarketingPlaceCommentaryEditor() {
    const {place_commentary_id} = useParams<{place_commentary_id: string}>()

    const [item, setItem] = useState<any>('')

    const [image, setImage] = useState<any>('')

    const [mobileImage, setMobileImage] = useState<any>('')

    useEffect(() => {
        if (Number(place_commentary_id) > 0) {
            getPlaceCommentary()
        }
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getPlaceCommentary = () => {
        axios
            .get(`${process.env.REACT_APP_API2_URL}am/place/commentary/${place_commentary_id}`)
            .then(({data: {error, result}}) => {
                console.log(error, result)
                if (error !== '') {
                } else {
                    setItem(result)

                    const temp = Object.assign({}, image)
                    temp.imageId = result.pc_image_id
                    temp.imagePath = result.pc_image_path
                    addImages(temp)

                    const mobileTemp = Object.assign({}, mobileImage)
                    mobileTemp.imageId = result.mobile_image_id
                    mobileTemp.imagePath = result.mobile_image_path
                    addMobileImages(mobileTemp)

                    //setImages(result.images)
                    window.scrollTo(0, 0)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const addImages = (image: any) => {
        console.log('add', image)
        setImage(image)
    }

    const addMobileImages = (image: any) => {
        console.log('add', image)
        setMobileImage(image)
    }

    const removeImages = (image: any) => {
        console.log('delete', image)
        setImage('')
    }

    const removeMobileImages = (image: any) => {
        console.log('delete', image)
        setMobileImage('')
    }

    const handleItemFileInputMobile = (e: any) => {
        console.log(mobileImage.length)

        console.log(e)

        const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file?.type,
                        },
                    })
                    .then((response) => {
                        console.log(path)
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'image',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    // main_vue.completeImage(file, response.data.result)

                                    let imageId = result.id
                                    let imagePath = result.image_path

                                    console.log(imageId)
                                    console.log(imagePath)

                                    const temp = Object.assign({}, mobileImage)
                                    temp.imageId = imageId
                                    temp.imagePath = imagePath
                                    addMobileImages(temp)
                                } else {
                                    console.log(error)
                                    // main_vue.failImage(file)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                // main_vue.failImage(file)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        // main_vue.failImage(file)
                    })
            } else {
                // main_vue.failImage(file);
                alert('이미지 등록 실패')
            }
        })
    }

    const handleItemFileInput = (e: any) => {
        console.log(e)

        const file = e.target.files[0]
        axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
            if (error === '') {
                const {path, url} = result
                axios
                    .put(url, file, {
                        headers: {
                            'Content-Type': file?.type,
                        },
                    })
                    .then((response) => {
                        console.log(path)
                        axios
                            .post(`${process.env.REACT_APP_API2_URL}upload`, {
                                path,
                                type: 'image',
                            })
                            .then(({data: {error, result}}) => {
                                if (error === '') {
                                    // main_vue.completeImage(file, response.data.result)

                                    let imageId = result.id
                                    let imagePath = result.image_path

                                    console.log(imageId)
                                    console.log(imagePath)

                                    const temp = Object.assign({}, image)
                                    temp.imageId = imageId
                                    temp.imagePath = imagePath
                                    addImages(temp)
                                } else {
                                    console.log(error)
                                    // main_vue.failImage(file)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                // main_vue.failImage(file)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        // main_vue.failImage(file)
                    })
            } else {
                // main_vue.failImage(file)
            }
        })
    }

    const onSave = () => {
        if (window.confirm('장소 코멘터리를 수정하시겠습니까?')) {
            console.log(image)
            console.log(mobileImage)

            axios
                .put(`${process.env.REACT_APP_API2_URL}am/place/commentary/${place_commentary_id}`, {
                    image: image,
                    mobileImage: mobileImage,
                })
                .then(({data: {error, result}}) => {
                    console.log(error)
                    console.log(result)
                    if (error !== '') {
                        alert(error)
                    } else {
                        alert('수정되었습니다.')
                        window.location.href = `/marketing/place/commentary`
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>장소 코멘터리 에디터</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                    <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-body'>
                            <div style={{marginTop: 40, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {item !== null && item !== '' && item.place !== null && item.place !== '' && (
                                    <>
                                        <MainCategoryPlacesCard place={item.place} removePlace={''} />
                                    </>
                                )}
                            </div>

                            <div className='separator my-10' />
                            <h2>이미지 추가</h2>
                            <div className='row'>
                                <div className='col-6'>
                                    <TextSub2 title={'MOBILE'} subTitle={''} />
                                    {mobileImage === '' && (
                                        <>
                                            <label
                                                className='input-file-button'
                                                htmlFor='input-file'
                                                style={{
                                                    width: '169px',
                                                    height: '52px',
                                                    paddingTop: '16px',
                                                    paddingLeft: '34px',
                                                    fontSize: '16px',
                                                    lineHeight: '22px',
                                                    backgroundColor: '#3C82FA',
                                                    borderRadius: '8px',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                이미지 추가하기
                                            </label>
                                            <input
                                                type='file'
                                                id='input-file'
                                                style={{display: 'none'}}
                                                onChange={(e) => {
                                                    handleItemFileInputMobile(e)
                                                }}
                                            />
                                        </>
                                    )}

                                    {item !== '' && mobileImage !== null && mobileImage !== '' && (
                                        <>
                                            <img src={ImagePath.getS3(mobileImage.imagePath)} alt='picture' style={{width: '100%', paddingRight: 12, paddingBottom: 12}} />
                                            <i
                                                className='bi bi-x-lg'
                                                style={{
                                                    marginTop: 5,
                                                    marginLeft: -43,
                                                    position: 'absolute',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    width: 27,
                                                    height: 25,
                                                    backgroundColor: 'red',
                                                    textAlign: 'center',
                                                    lineHeight: '24px',
                                                    fontSize: 16,
                                                }}
                                                key={item.id}
                                                onClick={() => {
                                                    removeMobileImages(item)
                                                }}
                                            ></i>
                                        </>
                                    )}
                                </div>
                                <div className='col-6'>
                                    <TextSub2 title={'PC'} subTitle={''} />
                                    {image === '' && (
                                        <>
                                            <label
                                                className='input-file-button'
                                                htmlFor='input-file'
                                                style={{
                                                    width: '169px',
                                                    height: '52px',
                                                    paddingTop: '16px',
                                                    paddingLeft: '34px',
                                                    fontSize: '16px',
                                                    lineHeight: '22px',
                                                    backgroundColor: '#3C82FA',
                                                    borderRadius: '8px',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                이미지 추가하기
                                            </label>
                                            <input
                                                type='file'
                                                id='input-file'
                                                style={{display: 'none'}}
                                                onChange={(e) => {
                                                    handleItemFileInput(e)
                                                }}
                                            />
                                        </>
                                    )}

                                    {image != null && image !== '' && (
                                        <>
                                            <img src={ImagePath.getS3(image.imagePath)} alt='picture' style={{width: '100%', paddingRight: 12, paddingBottom: 12}} />
                                            <i
                                                className='bi bi-x-lg'
                                                style={{
                                                    marginTop: 5,
                                                    marginLeft: -43,
                                                    position: 'absolute',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    width: 27,
                                                    height: 25,
                                                    backgroundColor: 'red',
                                                    textAlign: 'center',
                                                    lineHeight: '24px',
                                                    fontSize: 16,
                                                }}
                                                onClick={() => {
                                                    removeImages(image)
                                                }}
                                            ></i>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: 40}}>
                        <Button
                            variant='primary'
                            onClick={() => {
                                onSave()
                            }}
                        >
                            수정
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
