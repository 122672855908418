import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import ImagePath from '../../commons/ImagePath'
import Modal from '../components/Modal'
import moment from 'moment'

export function IndexMonitoring() {
  const [loading, setLoading] = useState(false)
  const [monitoring, setMonitoring] = useState<any>([])
  const [modalVisible, setModalVisible] = useState(false)

  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    getMonitoring()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMonitoring = () => {
    setLoading(true)

    axios
      .get(`${process.env.REACT_APP_API2_URL}am/monitoring`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          setMonitoring(result)
          window.scrollTo(0, 0)
          // this.forceUpdate()
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const deleteMonitoring = (id = 0) => {
    setLoading(true)
    axios
      .delete(`${process.env.REACT_APP_API2_URL}am/monitoring/${id}`)
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          window.location.reload()
          // this.forceUpdate()
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const insertMonitoring = () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/monitoring`, {title, url})
      .then(({data: {error, time, result}}) => {
        console.log(time)
        console.log(result)
        if (error !== '') {
        } else {
          window.location.reload()
          // this.forceUpdate()
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>모니터링</PageTitle>
      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <label
          className={'btn btn-primary'}
          onClick={() => {
            setModalVisible(true)
          }}
        >
          등록하기
        </label>

        <div className='tab-content w-100' id='myTabContent'>
          <table className='table table-striped text-center'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th className='min-w-200px'>IMAGE</th>
                <th className='min-w-100px'>갱신</th>
                <th className='min-w-100px'>userId</th>
                <th className='min-w-100px'>TITLE</th>
                <th className='min-w-100px'>삭제</th>
              </tr>
            </thead>

            <tbody className='align-middle'>
              {loading ? (
                <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Loading...
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                </div>
              ) : (
                monitoring.map((item: any, index: number) => {
                  return (
                    <>
                      <tr key={`${index}`}>
                        <td style={{width: 200}}>
                          <div style={{width: 200, height: 200, overflow: 'hidden', position: 'relative'}}>
                            {item.image_url && (
                              <img
                                src={item.image_url}
                                alt=''
                                style={{width: 200, left: 0, position: 'absolute', objectFit: 'contain', cursor: 'pointer'}}
                                onClick={() => {
                                  window.open(ImagePath.get(item.image_url, '500x500'), '_blank')
                                }}
                              />
                            )}
                          </div>
                        </td>
                        <td>{Math.round(moment.duration(moment().diff(moment(item.updated_at))).asMinutes())}분전</td>
                        <td>{item.user_id}</td>
                        <td>{item.title}</td>
                        <td>
                          <label
                            className={'btn btn-danger'}
                            onClick={() => {
                              deleteMonitoring(item.id)
                            }}
                          >
                            삭제
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td style={{justifyContent: 'center'}}>URL</td>
                        <td colSpan={5} style={{maxWidth: '300px', lineBreak: 'anywhere'}}>
                          {item.url}
                        </td>
                      </tr>
                    </>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setModalVisible(false)
          }}
          className={''}
        >
          <div>
            <div style={{marginTop: 26}}>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <table style={{fontSize: '15px', width: '100%'}}>
                  <colgroup>
                    <col className='col1' />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>TITLE</td>
                      <td>
                        <input
                          type='text'
                          style={{width: '100%'}}
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>URL</td>
                      <td>
                        <input
                          type='url'
                          style={{width: '100%'}}
                          value={url}
                          onChange={(e) => {
                            setUrl(e.target.value)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className={'row'}>
                  <div style={{padding: 4, width: 'fit-content'}}>
                    <label
                      onClick={() => {
                        insertMonitoring()
                        setModalVisible(false)
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      등록
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
