import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function LogKCP() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('')

  useEffect(() => {

    getLogs()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = () => {
    getLogs()
  }

  const handlePageChange = (page: number) => {
    getLogs(page)
  }

  const getLogs = (page = 1) => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/log/kcp?page=${page}&search=${search}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>KCP 로그</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div style={{
          marginTop: 10,
          marginBottom: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='예약번호, 구매자명, 입금자명을 입력해 주세요.'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{width: 100}} className='btn btn-dark'>검색</label>
        </div>

        {
          loading ?
            <div style={{
              width: '100%',
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div>
              {
                items.map((item: any, index: number) => {
                  return (
                    <div className='col-12'>
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-header'>
                          <h1 className='card-title'>
                            {item.booking_id}. {item.type} [{item.created_at}]
                          </h1>
                        </div>
                        <div className='card-body'>
                          <p style={{whiteSpace: 'pre-wrap'}}>
                            {item.info}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <div style={{padding: 20}}>
                <Pagination activePage={currentPage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            prevPageText={'‹'}
                            nextPageText={'›'}
                            onChange={handlePageChange} />
              </div>
            </div>
        }
      </div>

    </div>
  </>
}

