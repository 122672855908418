import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import axios from 'axios'

export function MarketingBenefits() {

    const [loading, setLoading] = useState(false)

    const [items, setItems] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getMarketingBanner();
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page: number) => {
        getMarketingBanner(page);
    }

    const getMarketingBanner = (page = 1) => {
        setLoading(true)
        setCurrentPage(0)
        setPerPage(0)
        setTotal(0)

        axios.get(`${process.env.REACT_APP_API2_URL}am/marketing/benefits?page=${page}`).then(({data: {error, time, result}}) => {
            console.log(result)
            if (error !== '') {
            } else {
                setItems(result.data);
                setCurrentPage(result.current_page);
                setPerPage(result.per_page);
                setTotal(result.total);
                window.scrollTo(0, 0)
                // this.forceUpdate()
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>혜택 안내 배너</PageTitle>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 30}}>
                <label className={'btn btn-primary'} onClick={() => {
                        window.location.href = `/marketing/benefits/0/editor`
                    }}
                >
                    혜택 안내 배너 만들기
                </label>
            </div>
            <div style={{padding: '10px 0px', borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                <div className='tab-content w-100' id='myTabContent'>
                    <div className="table-responsive">
                        <table className="table table-striped" style={{marginBottom: 0}}>
                            <thead>
                            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                <th className="min-w-10px"></th>
                                <th className="min-w-50px">순서</th>
                                <th className="min-w-50px">ID</th>
                                <th className="min-w-200px">제목</th>
                                <th className="min-w-50px">메인홈 카드 노출</th>
                                <th className="min-w-50px">수정</th>
                                <th className="min-w-50px">페이지 보기</th>
                            </tr>
                            </thead>

                            <tbody className="align-middle" style={{height: '70px'}}>
                            {
                                loading ?
                                  <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                                  </div>
                                  :
                                  items.map((category: any, index: number) => {
                                      return (
                                        <tr key={`${index}`}>
                                            <td></td>
                                            <td>{category.order}</td>
                                            <td>{category.id}</td>
                                            <td>{category.pc_title}</td>
                                            <td>{category.main_show === 1 ? 'Y' : 'N'}</td>
                                            <td>
                                                <p style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                                                   onClick={() => {
                                                       window.open(`/marketing/benefits/${category.id}/editor`, '_blank')
                                                   }}
                                                >
                                                    수정
                                                </p>
                                            </td>
                                            <td>
                                                <p style={{minWidth: 40, fontWeight: 500, fontSize: 12, color: '#007BFF', cursor: 'pointer', textDecoration: 'underline'}}
                                                   onClick={() => {
                                                       if(category.main_show === 1) {
                                                           window.open(`${process.env.REACT_APP_WEB2_URL}${category.mobile_link}`)
                                                       } else {
                                                           window.open(`${process.env.REACT_APP_WEB2_URL}`)
                                                       }
                                                   }}
                                                >
                                                    보기
                                                </p>
                                            </td>
                                        </tr>
                                      )
                                  })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div style={{marginTop: 20}}>
                <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
            </div>
        </>
    )
}
