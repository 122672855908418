import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import ImagePath from '../../../../commons/ImagePath'
import {TextSub} from '../../../components/TextSub'
import classNames from 'classnames'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'

export function MediaImageFeedback() {

  const user: any = useSelector<RootState>(({auth}) => auth.user)

  const [status, setStatus] = useState('')

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [feedbackImageTotal, setFeedbackImageTotal] = useState(0)
  const [mainHideCount, setMainHideCount] = useState(0)

  useEffect(() => {
    getFeedbackImages()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getFeedbackImages(status, page)
  }

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getFeedbackImages(tabStatus, 1)
  }

  const getFeedbackImages = (status = '', page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/feedback/image?type=${status}&page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.feedback_images.data)
          setCurrentPage(result.feedback_images.current_page)
          setPerPage(result.feedback_images.per_page)
          setTotal(result.feedback_images.total)
          setFeedbackImageTotal(result.total_count)
          setMainHideCount(result.main_hide_count)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateImageHide = (temp: any, hide = 0) => {
    // const {images} = this.state
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/feedback/image/${temp.id}`, {
        hide,
      })
      .then(({data: {error, result}}) => {
        if (error !== '') {
        } else {
          const temps = [...items]
          temps.splice(temps.indexOf(temp), 1, temp)
          temp.hide = result.hide
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateImageMain = (temp: any, main = 0) => {
    // const {images} = this.state
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/feedback/image/${temp.id}`, {
        main,
      })
      .then(({data: {error, result}}) => {
        if (error !== '') {
        } else {
          const temps = [...items]
          temps.splice(temps.indexOf(temp), 1, temp)
          temp.main = result.main
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>장소 후기 이미지</PageTitle>

      <div
        style={{
          marginTop: 20,
          padding: 0,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: 10,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <label onClick={() => {
              onTab('')
            }} className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}>모두보기 ({feedbackImageTotal.toLocaleString('en')})</label>
            <label onClick={() => {
              onTab('mainHide')
            }} className={classNames('btn', {'btn-dark': status === 'mainHide'}, {'btn-btn-white': status !== 'mainHide'})}>메인 숨김 ({mainHideCount.toLocaleString('en')})</label>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {items.map((item: any, index: number) => {
              return (
                <div className="col-12 col-xl-6" key={index.toString()}>
                  <div className="card shadow m-3">
                    <div className="card-header">
                      <h1 className="card-title">{item.id}</h1>
                    </div>
                    <div className="card-body">
                      <h3>{item.created_at}</h3>

                      <img src={ImagePath.get(item.image_path, '2000x2000', 'inside')} style={{margin: 10, width: 300, height: 300, objectFit: 'contain'}} alt="" />

                      <TextSub title={'평점'} subTitle={item.rating} marginTop={2} />
                      <TextSub title={'후기 내용'} subTitle={item.comment} marginTop={2} />
                      <TextSub title={'후기 내용'} subTitle={user.email} marginTop={2} />

                      <TextSub title={'메인 노출'} subTitle={item.main === 1 ? '노출' : item.main === 0 ? '대기' : '숨김'} marginTop={20} />
                      {item.main !== 1 && (user.email === 'seokbae@munch-factory.com' || user.email === 'team+eddie@munch-factory.com' || user.email === 'team+defacto@munch-factory.com') && (
                        <label
                          style={{marginRight: 10}}
                          onClick={() => {
                            updateImageMain(item, 1)
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          메인 노출 처리
                        </label>
                      )}
                      {item.main !== -1 && (user.email === 'seokbae@munch-factory.com' || user.email === 'team+eddie@munch-factory.com' || user.email === 'team+defacto@munch-factory.com') && (
                        <label
                          onClick={() => {
                            updateImageMain(item, -1)
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          메인 숨김 처리
                        </label>
                      )}

                      <TextSub title={'후기 노출'} subTitle={item.hide === 1 ? '숨김' : '노출'} marginTop={20} />
                      {item.hide !== 1 && (
                        <label
                          onClick={() => {
                            updateImageHide(item, 1)
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          후기 숨김 처리
                        </label>
                      )}
                      {item.hide === 1 && (
                        <label
                          style={{marginRight: 10}}
                          onClick={() => {
                            updateImageHide(item, -1)
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          후기 노출 처리
                        </label>
                      )}
                    </div>
                    <div className="card-footer">
                      <label
                        onClick={() => {
                          window.open(`/place/${item.place_id}`)
                        }}
                        className="btn btn-primary"
                      >
                        장소 보기
                      </label>
                      <label
                        style={{marginLeft: 10}}
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_WEB_URL}place/${item.place_id}`)
                        }}
                        className="btn btn-primary"
                      >
                        웹에서 보기
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
      </div>
    </>
  )
}
