import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import {isMobile} from 'react-device-detect'
import {BookingPlaceListItem} from "../../components/BookingPlaceListItem";

export function BookingPlace() {

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [bookings, setBookings] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [bookingAdditionalCount, setBookingAdditionalCount] = useState(0)
  const [bookingApprovedCount, setBookingApprovedCount] = useState(0)
  const [bookingAutoApprovedCount, setBookingAutoApprovedCount] = useState(0)
  const [bookingCancelledCount, setBookingCancelledCount] = useState(0)
  const [bookingCompletedCount, setBookingCompletedCount] = useState(0)
  const [bookingCount, setBookingCount] = useState(0)
  const [bookingGtagCount, setBookingGtagCount] = useState(0)
  const [bookingHostPaidCount, setBookingHostPaidCount] = useState(0)
  const [bookingManualApprovedCount, setBookingManualApprovedCount] = useState(0)
  const [bookingPaidCount, setBookingPaidCount] = useState(0)
  const [bookingPendingCount, setBookingPendingCount] = useState(0)
  const [bookingProceedingCount, setBookingProceedingCount] = useState(0)
  const [bookingRefundCount, setBookingRefundCount] = useState(0)
  const [bookingTeamShareCount, setBookingTeamShareCount] = useState(0)
  const [bookingTeamProceedingCount, setBookingTeamProceedingCount] = useState(0)

  useEffect(() => {
    getBookings(status, 1)
    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getBookings(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getBookings('', 1)
  }

  const handlePageChange = (page: number) => {
    getBookings(status, page)
  }

  const getBookingsCount = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/bookings/count`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setBookingAdditionalCount(result.booking_additional_count)
        setBookingApprovedCount(result.booking_approved_count)
        setBookingAutoApprovedCount(result.booking_auto_approved_count)
        setBookingCancelledCount(result.bookingCancelledCount)
        setBookingCompletedCount(result.bookingCompletedCount)
        //setBookingCount(result.booking_count)
        setBookingGtagCount(result.booking_gtag_count)
        setBookingHostPaidCount(result.booking_host_paid_count)
        setBookingManualApprovedCount(result.booking_manual_approved_count)
        setBookingPaidCount(result.booking_paid_count)
        setBookingPendingCount(result.booking_pending_count)
        setBookingProceedingCount(result.booking_proceeding_count)
        setBookingRefundCount(result.booking_refund_count)
        setBookingTeamShareCount(result.booking_team_share_count)
        setBookingTeamProceedingCount(result.booking_team_proceeding_count)

        //window.scrollTo(0, 0)
        // this.forceUpdate()
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getBookings = (status = '', page = 1) => {
    setBookings([])
    setLoading(true)
    setCurrentPage(0)
    setPerPage(0)
    setTotal(0)
    axios.get(`${process.env.REACT_APP_API2_URL}am/bookings?page=${page}&status=${status}&search=${search}`).then(({data: {error, time, result}}) => {
      console.log(time)
      console.log(result)
      if (error !== '') {
      } else {
        setBookings(result.bookings.data)
        setCurrentPage(result.bookings.current_page)
        setPerPage(result.bookings.per_page)
        setTotal(result.bookings.total)
        setBookingCount(result.booking_count)

        /*
        setBookingAdditionalCount(result.booking_additional_count)
        setBookingApprovedCount(result.booking_approved_count)
        setBookingAutoApprovedCount(result.booking_auto_approved_count)
        setBookingCancelledCount(result.bookingCancelledCount)
        setBookingCompletedCount(result.bookingCompletedCount)
        setBookingGtagCount(result.booking_gtag_count)
        setBookingHostPaidCount(result.booking_host_paid_count)
        setBookingManualApprovedCount(result.booking_manual_approved_count)
        setBookingPaidCount(result.booking_paid_count)
        setBookingPendingCount(result.booking_pending_count)
        setBookingProceedingCount(result.booking_proceeding_count)
        setBookingRefundCount(result.booking_refund_count)
        setBookingTeamShareCount(result.booking_team_share_count)
        setBookingTeamProceedingCount(result.booking_team_proceeding_count)
         */
        if(page === 1 && status === '' && search === ''){
          getBookingsCount();
        }
        window.scrollTo(0, 0)
        // this.forceUpdate()
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 장소</PageTitle>

    <div style={{
      padding: isMobile ? 4 : 20,
      borderRadius: isMobile ? 4 : 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>
        <select
          className='form-control'
          name='type'
          onChange={(e) => {
            onTab(e.target.value)
          }}
          value={status}
        >
          <option value={''}>모두보기 ({bookingCount.toLocaleString('en')})</option>
          <option value={'pending'}>관리자 승인 대기 ({bookingPendingCount.toLocaleString('en')})</option>
          <option value={'auto_approved'}>관리자 자동 승인 ({bookingAutoApprovedCount.toLocaleString('en')})</option>
          <option value={'manual_approved'}>관리자 수동 승인 ({bookingManualApprovedCount.toLocaleString('en')})</option>
          <option value={'approved'}>호스트 승인대기 ({bookingApprovedCount.toLocaleString('en')})</option>
          <option value={'proceeding'}>결제대기 ({bookingProceedingCount.toLocaleString('en')})</option>
          <option value={'proceeding_1'}>결제대기 승인시간정렬 ({bookingProceedingCount.toLocaleString('en')})</option>
          <option value={'additional'}>추가결제대기 ({bookingAdditionalCount.toLocaleString('en')})</option>
          <option value={'paid'}>촬영대기 ({bookingPaidCount.toLocaleString('en')})</option>
          <option value={'completed'}>촬영 완료 ({bookingCompletedCount.toLocaleString('en')})</option>
          <option value={'host_paid'}>호스트 입금완료 ({bookingHostPaidCount.toLocaleString('en')})</option>
          <option value={'cancelled'}>취소 ({bookingCancelledCount.toLocaleString('en')})</option>
          <option value={'refund'}>환불 ({bookingRefundCount.toLocaleString('en')})</option>
          <option value={'gtag'}>결제 트래킹 ({bookingGtagCount.toLocaleString('en')})</option>
          <option value={'team_share'}>팀 공유 ({bookingTeamShareCount.toLocaleString('en')})</option>
          <option value={'team_proceeding'}>팀 결제 대기 ({bookingTeamProceedingCount.toLocaleString('en')})</option>
        </select>

        <div style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='예약 번호, 장소 번호, 장소명, 예약자 이름'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label onClick={() => {
            onSearch()
          }} style={{
            width: 100,
          }} className='btn btn-dark'>검색</label>
        </div>
      </div>

      {
        loading ?
          <div style={{
            width: '100%',
            height: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
          </div>
          :
          <div style={{
            width: '100%',
          }}>
            {
              bookings.map((booking: any, index: number) => {
                //return <BookingPlaceItem key={booking.id.toString()} booking={booking} status={status} />
                return <BookingPlaceListItem key={booking.id.toString()} booking={booking} status={status} />
              })
            }
          </div>
      }

      {
        bookings.length > 0 &&
        <div style={{marginTop: 20}}>
          <Pagination activePage={currentPage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={10}
                      prevPageText={'‹'}
                      nextPageText={'›'}
                      onChange={handlePageChange} />
        </div>
      }
    </div>
  </>
}

