import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import {
  Button,
} from 'react-bootstrap'
import {toast} from 'react-toastify'
import moment from 'moment'

export function BookingPlaceEditInfo() {

  const {booking_id} = useParams<{booking_id: string}>()
  const [bookingInfo, setBookingInfo] = useState<any>(null)

  const [genre, setGenre] = useState('')
  const [genreTag, setGenreTag] = useState('')
  const [content, setContent] = useState('')
  const [parkingCount, setParkingCount] = useState('')
  const [parking, setParking] = useState('')
  const [detailEquipment, setDetailEquipment] = useState('')
  const [equipment, setEquipment] = useState('')

  useEffect(() => {

    getBookingInfo()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const update = () => {

    if (window.confirm('예약 정보를 수정 하시겠습니까?')) {
      axios.put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/info`, {
        "genre" : genre,
        "genre_tag" : genreTag,
        "content" : content,
        "equipment" : equipment,
        "parking" : parking,
        "detail_equipment" : detailEquipment,
        "parking_count" : parkingCount,
      }).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.href = `/booking/place/${booking_id}`
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  const getBookingInfo = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setBookingInfo(result)
        setGenre(result.booking.genre)
        setGenreTag(result.booking.genre_tag)
        setContent(result.booking.content)
        setParkingCount(result.booking.parking_count)
        setParking(result.booking.parking)
        setDetailEquipment(result.booking.detail_equipment)
        setEquipment(result.booking.equipment)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 시간 수정</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='col-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              {
                bookingInfo !== null &&
                <h1 className='card-title'>
                  예약 번호 : {bookingInfo.booking.id} ({bookingInfo.booking.status_display})
                </h1>
              }
            </div>
            <div className='card-body'>
              {
                bookingInfo !== null &&
                <>
                  <h2>호스트 정보</h2>
                  <TextSub title={'호스트'} subTitle={bookingInfo.booking.host.name} />
                  <TextSub title={'장소 번호'} subTitle={bookingInfo.place.id} />
                  <TextSub title={'장소 제목'} subTitle={bookingInfo.place.title} />
                  <br/> <h2>게스트 정보</h2>
                  <TextSub title={'게스트'} subTitle={bookingInfo.booking.guest_real_name} />
                  <TextSub title={'추가정보'} subTitle={bookingInfo.booking.guest_company} />
                  <TextSub title={'연락처'} subTitle={bookingInfo.booking.guest_phone} />
                  <br/><h2>촬영 정보</h2>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'촬영 목적'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={genre} onChange={(e) => { setGenre(e.target.value) }} />
                  </div>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'촬영 종류(장르)'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={genreTag} onChange={(e) => { setGenreTag(e.target.value) }} />
                  </div>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'촬영 내용(제품)'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={content} onChange={(e) => { setContent(e.target.value) }} />
                  </div>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'차량 대수'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={parkingCount} onChange={(e) => { setParkingCount(e.target.value) }} />
                  </div>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'(구)차량 대수'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={parking} onChange={(e) => { setParking(e.target.value) }} />
                  </div>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'큰 장비/차량'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={detailEquipment} onChange={(e) => { setDetailEquipment(e.target.value) }} />
                  </div>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                      <TextSub title={'(구)큰 장비/차량'} subTitle={''} />
                    </div>
                    <input type='text' className='form-control' placeholder={''} value={equipment} onChange={(e) => { setEquipment(e.target.value) }} />
                  </div>
                  <TextSub title={'기본 인원'} subTitle={`${bookingInfo.booking.place_people} 명`} />
                  <TextSub title={'촬영 인원'} subTitle={`${bookingInfo.booking.people} 명`} />
                  <TextSub title={'촬영 스케줄'} subTitle={`${moment(bookingInfo.booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(bookingInfo.booking.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}`} />
                </>
              }
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <Button variant='danger' onClick={() => { update() }}> 수정 하기 </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

