import React, {useState} from 'react'
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis'
import moment from 'moment'
import axios from 'axios'
import {TextSub} from './TextSub'
import {toast} from 'react-toastify'
import SwitchButton from '../../components/SwitchButton'

type Props = {
  booking: any
  place?: any
  status?: string
  detailButton?: boolean
}

const BookingPlaceListItem: React.FC<Props> = ({booking, place, status = '', detailButton = true}) => {
  const [item, setItem] = useState(booking)

  const black = (guest = true) => {
    if (window.confirm('블랙 하시겠습니까?')) {
      const blackComment = window.prompt('코멘트를 적어주세요.', '')
      console.log(`${process.env.REACT_APP_API2_URL}am/user/${guest ? item.guest_id : item.user_id}/black`)
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/user/${guest ? item.guest_id : item.user_id}/black`, {black: 1, blackComment: blackComment})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            if (guest) {
              item.guest_black = result.black
              item.guest_black_comment = result.black_comment
            } else {
              item.host_black = result.black
              item.host_black_comment = result.black_comment
            }
            setItem({...item})
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const white = (guest = true) => {
    if (window.confirm('화이트 하시겠습니까?')) {
      axios
        .put(`${process.env.REACT_APP_API2_URL}am/user/${guest ? item.guest_id : item.user_id}/black`, {black: 0})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
          } else {
            if (guest) {
              item.guest_black = result.black
              item.guest_black_comment = result.black_comment
            } else {
              item.host_black = result.black
              item.host_black_comment = result.black_comment
            }
            setItem({...item})
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const paid = (item: any) => {
    if (window.confirm('게스트 결제 완료 처리하시겠습니까?')) {
      const password = window.prompt('비밀번호를 적어주세요.', '')
      if (password === 'hour1234$$') {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/booking/${item.id}`, {status: 'paid'})
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              setItem({...item, status_display: '결제완료', status: 'paid'})
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const leaderPaid = (item: any) => {
    if (window.confirm('리더 결제 완료 처리하시겠습니까?')) {
      const password = window.prompt('비밀번호를 적어주세요.', '')
      if (password === 'hour1234$$') {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/booking/${item.id}/leader/paid`, {status: 'paid'})
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              setItem({...item, status_display: '리더결제완료', status: 'paid'})
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const proceeding = (item: any) => {
    if (window.confirm('호스트 승인 처리하시겠습니까?')) {
      const password = window.prompt('비밀번호를 적어주세요..', '')
      if (password === 'hour1234$$') {
        axios
          .put(`${process.env.REACT_APP_API2_URL}am/booking/${item.id}`, {status: 'proceeding'})
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error) {
              toast.error(error)
            } else {
              if (item.payments_type === 2) {
                setItem({...item, status_display: '결제완료', status: 'paid'})
              } else {
                setItem({...item, status_display: '승인완료', status: 'proceeding'})
              }
              //setItem({...item, status_display: '승인완료', status: 'proceeding'})
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  return (
    <div style={{marginTop: 16, padding: 20, width: '100%', borderRadius: 10, border: '1px solid #DDD'}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <h2>
          예약 번호 : {item.id}
          {item.team_booking_info !== undefined ? <b style={{color: 'red'}}>[팀 공유]</b> : ''}
        </h2>
      </div>
      <h4>
        {item.booking_id > 0 ? `[ ${item.booking_id} 추가청구 ]` : ''}[ {item.status_display} ]{item.auto_approved === 1 ? '[ 자동 승인 ]' : ''}
        {item.is_refund === 1 ? '[ 환불 완료 ]' : ''}
        {item.is_minor === 1 ? '[ 미성년 예약 ]' : ''}
        {item.is_advertise === 1 ? '[ 광고 중 ]' : ''}
      </h4>

      <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
        <table style={{width: '100%'}}>
          <colgroup>
            <col width={'100px'} />
            <col width={'auto'} />
          </colgroup>
          <tbody>
            <tr>
              <td rowSpan={2}>
                <div style={{width: 100, height: 100, position: 'relative'}}>
                  <img src={item.place_image_url} alt='장소 이미지' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                  <div
                    style={{
                      position: 'absolute',
                      width: 30,
                      height: 30,
                      borderTopLeftRadius: 8,
                      bottom: 0,
                      right: 0,
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h4 style={{margin: 0}}>{item.place_grade}</h4>
                  </div>
                </div>
              </td>
              <td style={{verticalAlign: 'top', textAlign: 'left'}}>
                <div style={{marginLeft: 10}}>
                  <LinesEllipsis text={`${item.place_id}. ${item.place_title}`} maxLine='2' ellipsis='...' trimRight basedOn='letters' style={{fontSize: 20, fontWeight: 'bold'}} />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{verticalAlign: 'top', textAlign: 'left'}}>
                <div style={{marginLeft: 10, marginTop: 10}}>
                  <TextSub title={'촬영 건수'} subTitle={`${item.place_shoot_count.toLocaleString('en')} (${item.place_booking_count.toLocaleString('en')})`} marginTop={-10} />
                  <TextSub title={'카테고리'} subTitle={`${item.place_category_main} > ${item.place_category_sub}`} marginTop={-10} />
                  <TextSub title={'결제방식'} subTitle={item.payments_type === 0 ? '승인 결제(나중결제)' : item.payments_type === 1 ? '바로 결제' : '승인 결제(미리결제)'} marginTop={-10} />
                  <TextSub title={'관리자 장소 메모'} subTitle={item.place_admin_comment} marginTop={-10} />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{verticalAlign: 'top', textAlign: 'left'}}>
                <label
                  onClick={() => {
                    window.open(`/place/${item.place_id}`, '_blank')
                  }}
                  className='btn btn-sm btn-primary'
                >
                  장소 보기
                </label>

                {item.is_adv_network === 1 && (
                  <label
                    style={{marginLeft: 20}}
                    onClick={() => {
                      window.open(`/advertise/network/${item.adv_network_group_id}/place`, '_blank')
                    }}
                    className='btn btn-sm btn-primary'
                  >
                    광고 상세(네트워크)
                  </label>
                )}

                {item.is_adv_category === 1 && (
                  <label
                    style={{marginLeft: 20}}
                    onClick={() => {
                      window.open(`/advertise/category/${item.adv_category_group_id}/place`, '_blank')
                    }}
                    className='btn btn-sm btn-primary'
                  >
                    광고 상세(카테고리)
                  </label>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
        <table style={{width: '100%'}}>
          <colgroup>
            <col width={'100px'} />
            <col width={'auto'} />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div style={{width: 100, height: 100, position: 'relative'}}>
                  <img src={item.host_image_url} alt='호스트 이미지' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                </div>
              </td>
              <td style={{verticalAlign: 'top', textAlign: 'left'}}>
                <div style={{marginLeft: 10}}>
                  {item.host_black === 1 && <p style={{color: 'red'}}>{item.host_black_comment}</p>}

                  <h1>{item.user_id}. 호스트</h1>
                  <TextSub title={'닉네임 (이름)'} subTitle={`${item.host_name} (${item.host_real_name})`} marginTop={0} />

                  <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(item.host_birth))).asYears())}세`} marginTop={-10} />
                  <TextSub title={'전화번호'} subTitle={item.host_phone} marginTop={-10} />
                  <TextSub title={'이메일'} subTitle={item.host_email} marginTop={-10} />
                  <TextSub title={'가입일'} subTitle={`${item.host_created_at}, ${moment.duration(moment(moment().format('YYYY-MM-DD')).diff(item.host_created_at)).asDays()} 일전`} marginTop={-10} />
                  <TextSub
                    title={'마지막 활성 시간'}
                    subTitle={`${moment(item.host_last_logined_at).format('MM-DD')}, ${Math.floor(
                      (moment.duration(moment().diff(moment(item.host_last_logined_at, 'YYYY-MM-DD'))).asDays() * 100) / 100
                    )} 일전`}
                    marginTop={-10}
                  />
                  {item.host_memo !== undefined && item.host_memo.length > 0 && <TextSub title={'메모'} subTitle={item.host_memo} marginTop={-10} />}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{verticalAlign: 'top', textAlign: 'left'}}>
                <SwitchButton id={item.user_id}>Switch</SwitchButton>
                <label
                  style={{marginLeft: 10}}
                  onClick={() => {
                    window.open(`/user/${item.user_id}`)
                  }}
                  className='btn btn-sm btn-primary'
                >
                  자세히 보기
                </label>
                {item.host_black === 1 ? (
                  <label
                    style={{marginLeft: 10}}
                    onClick={() => {
                      white(false)
                    }}
                    className='btn btn-sm btn-secondary'
                  >
                    화이트
                  </label>
                ) : (
                  <label
                    style={{marginLeft: 10}}
                    onClick={() => {
                      black(false)
                    }}
                    className='btn btn-sm btn-danger'
                  >
                    블랙
                  </label>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{marginTop: 10, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
        <table style={{width: '100%'}}>
          <colgroup>
            <col width={'100px'} />
            <col width={'auto'} />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div style={{width: 100, height: 100, position: 'relative'}}>
                  <img src={item.guest_image_url} alt='게스트 이미지' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 10}} />
                  <div
                    style={{
                      position: 'absolute',
                      width: 30,
                      height: 30,
                      borderTopLeftRadius: 8,
                      bottom: 0,
                      right: 0,
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h4 style={{margin: 0}}>{item.guest_grade}</h4>
                  </div>
                </div>
              </td>
              <td style={{verticalAlign: 'top', textAlign: 'left'}}>
                <div style={{marginLeft: 10}}>
                  {item.guest_black === 1 && (
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      {item.guest_black_comment}
                    </p>
                  )}

                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <h1>{item.guest_id}. 게스트</h1>
                    {item.guest_bet > -1 && (
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{fontSize: 19, fontWeight: 500}}>&nbsp;/ ({item.guest_bet},</div>
                        <div
                          style={{
                            marginTop: 3,
                            marginBottom: 0,
                            marginRight: 6,
                            marginLeft: 6,
                            width: 14,
                            height: 14,
                            borderRadius: 8,
                            cursor: 'pointer',
                            backgroundColor: item.guest_bet_last === 100 ? '#18e318' : item.guest_bet_last === 50 ? '#e0e01b' : '#d21616',
                          }}
                        />
                        <div style={{fontSize: 19, fontWeight: 500}}>)</div>
                      </div>
                    )}
                  </div>

                  <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                    <div>
                      <TextSub title={'닉네임 (이름)'} subTitle={`${item.guest_name} (${item.guest_real_name})`} marginTop={0} />
                    </div>
                  </div>

                  <TextSub title={'나이'} subTitle={`만 ${Math.floor(moment.duration(moment(moment().format('YYYYMMDD')).diff(moment(item.guest_birth))).asYears())}세`} marginTop={-10} />
                  <TextSub title={'전화번호'} subTitle={item.guest_phone} marginTop={-10} />
                  <TextSub title={'이메일'} subTitle={item.guest_email} marginTop={-10} />
                  <TextSub title={'추가정보'} subTitle={item.guest_company} marginTop={-10} />
                  <TextSub
                    title={'마지막 활성 시간'}
                    subTitle={`${moment(item.guest_last_logined_at).format('MM-DD')}, ${Math.floor(
                      (moment.duration(moment().diff(moment(item.guest_last_logined_at, 'YYYY-MM-DD'))).asDays() * 100) / 100
                    )} 일전`}
                    marginTop={-10}
                  />
                  {item.guest_statistic !== undefined && item.guest_statistic !== null && (
                    <>
                      <TextSub title={'총 결제금액'} subTitle={item.guest_statistic.booking_guest_price.toLocaleString('en')} marginTop={-10} />
                      <TextSub
                        title={'촬영 진행률'}
                        subTitle={`${
                          item.guest_statistic.guest_booking_completed_count > 0 && item.guest_statistic.guest_proceeding_booking > 0
                            ? Math.floor((item.guest_statistic.guest_booking_completed_count / item.guest_statistic.guest_proceeding_booking) * 100)
                            : 0
                        }% (${item.guest_statistic.guest_booking_completed_count} / ${item.guest_statistic.guest_proceeding_booking})`}
                        marginTop={-10}
                      />
                    </>
                  )}
                  {item.guest_memo !== undefined && item.guest_memo.length > 0 && <TextSub title={'메모'} subTitle={item.guest_memo} marginTop={-10} />}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{verticalAlign: 'top', textAlign: 'left'}}>
                <SwitchButton id={item.guest_id}>Switch</SwitchButton>

                <label
                  style={{marginLeft: 10}}
                  onClick={() => {
                    window.open(`/user/${item.guest_id}`)
                  }}
                  className='btn btn-sm btn-primary'
                >
                  자세히 보기
                </label>
                {item.guest_black === 1 ? (
                  <label
                    style={{marginLeft: 10}}
                    onClick={() => {
                      white(true)
                    }}
                    className='btn btn-sm btn-secondary'
                  >
                    화이트
                  </label>
                ) : (
                  <label
                    style={{marginLeft: 10}}
                    onClick={() => {
                      black(true)
                    }}
                    className='btn btn-sm btn-danger'
                  >
                    블랙
                  </label>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{marginTop: 20, paddingBottom: 10, borderBottom: '1px solid #EEE'}}>
        {status === 'refund' && item.amount > 0 ? (
          <TextSub title={'결제금액(KCP)'} subTitle={item.amount.toLocaleString('en')} marginTop={0} />
        ) : status === 'refund' && item.toss_total_amount > 0 ? (
          <TextSub title={'결제금액(Toss)'} subTitle={item.toss_total_amount.toLocaleString('en')} marginTop={0} />
        ) : (
          <TextSub title={'결제금액'} subTitle={item.price_guest_vat.toLocaleString('en')} marginTop={0} />
        )}

        {(item.price_coupon > 0 || item.price_point > 0) && (
          <>
            <TextSub title={'쿠폰 사용'} subTitle={item.price_coupon.toLocaleString('en')} marginTop={-10} />
            <TextSub title={'포인트 사용'} subTitle={item.price_point.toLocaleString('en')} marginTop={-10} />
          </>
        )}
        <TextSub title={'실결제금액'} subTitle={(item.price_guest_vat - item.price_point - item.price_coupon).toLocaleString('en')} marginTop={-10} />

        <TextSub title={'예약 생성 시간'} subTitle={moment(item.created_at).format('YYYY-MM-DD HH:mm')} marginTop={-10} />
        {item.approved_time > 0 && <TextSub title={'관리자 승인시간'} subTitle={moment.unix(item.approved_time).format('YYYY-MM-DD HH:mm')} marginTop={-10} />}
        {item.proceeding_time > 0 && <TextSub title={'호스트 승인시간'} subTitle={moment.unix(item.proceeding_time).format('YYYY-MM-DD HH:mm')} marginTop={-10} />}
        <TextSub
          title={'촬영시간'}
          subTitle={`${moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} (${item.hour} 시간)`}
          marginTop={-10}
        />

        {item.status === 'cancelled' && (
          <div
            style={{
              marginTop: 30,
            }}
          >
            <TextSub title={'취소'} subTitle={item.cancelled} marginTop={-10} />
            {item.cancelled_host.length > 0 && <TextSub title={'호스트 사유'} subTitle={item.cancelled_host} marginTop={-10} />}
            {item.cancelled_guest.length > 0 && <TextSub title={'게스트 사유'} subTitle={item.cancelled_guest} marginTop={-10} />}
            <TextSub title={'취소 시간'} subTitle={moment.unix(item.cancelled_time).format('YYYY-MM-DD HH:mm')} marginTop={-10} />
          </div>
        )}
      </div>

      <div style={{paddingTop: 20, display: 'flex'}}>
        {detailButton && (
          <label
            onClick={() => {
              window.open(`/booking/place/${item.id}`)
            }}
            className='btn btn-sm btn-primary'
          >
            예약 상세
          </label>
        )}
        <label
          onClick={() => {
            window.open(`/booking/message/${item.id}`)
          }}
          style={{marginLeft: 10}}
          className='btn btn-sm btn-primary'
        >
          메시지 보기
        </label>

        {/*{*/}
        {/*    item.gtag === 0 && (item.status === 'paid' || item.status === 'completed' || item.status === 'host_paid') &&*/}
        {/*    <label onClick={() => {*/}
        {/*      window.open(`${process.env.REACT_APP_WEB_URL}booking/${item.id}/gtag_purchase`)*/}
        {/*    }} style={{marginLeft: 10}} className='btn btn-sm btn-success'>결제 완료 트래킹</label>*/}
        {/*}*/}
        {/*{*/}
        {/*    item.gtag === 1 && item.status === 'cancelled' &&*/}
        {/*    <label onClick={() => {*/}
        {/*      window.open(`${process.env.REACT_APP_WEB_URL}booking/${item.id}/gtag_refund`)*/}
        {/*    }} style={{marginLeft: 10}} className='btn btn-sm btn-danger'>결제 취소 트래킹</label>*/}
        {/*}*/}

        {item.status === 'proceeding' && (
          <>
            <label
              onClick={() => {
                paid(item)
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-warning'
            >
              결제 완료 처리
            </label>
            <label
              onClick={() => {
                window.location.href = `/booking/place/${item.id}/payment`
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-warning'
            >
              수기 결제 처리
            </label>
          </>
        )}
        {item.team_booking_info !== undefined && item.team_booking_info.status === 'proceeding' && (
          <>
            <label
              onClick={() => {
                leaderPaid(item)
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-warning'
            >
              팀 결제 완료 처리
            </label>
            <label
              onClick={() => {
                window.location.href = `/booking/place/${item.id}/team/${item.team_booking_info.team_id}/payment`
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-warning'
            >
              팀 수기 결제 처리
            </label>
          </>
        )}

        { ( (item.status === 'approved' && item.payments_type !== 2) || (item.status === 'approved' && item.payments_type === 2 && item.paid_time > 0) )  && (
          <label
            onClick={() => {
              proceeding(item)
            }}
            style={{marginLeft: 10}}
            className='btn btn-sm btn-warning'
          >
            호스트 승인
          </label>
        )}

        {item.booking_id === 0 && (item.status === 'completed' || item.status === 'host_paid') && (
          <label
            onClick={() => {
              window.location.href = `/booking/place/${item.id}/additional`
            }}
            style={{marginLeft: 10}}
            className='btn btn-sm btn-warning'
          >
            추가 청구 만들기
          </label>
        )}

        {(item.status === 'pending' || item.status === 'approved' || item.status === 'proceeding' || item.status === 'paid') && (
          <>
            <label
              onClick={() => {
                window.location.href = `/booking/place/${item.id}/edit/time`
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-warning'
            >
              예약 시간 수정
            </label>

            <label
              onClick={() => {
                window.location.href = `/booking/place/${item.id}/edit/info`
              }}
              style={{marginLeft: 10}}
              className='btn btn-sm btn-warning'
            >
              예약 정보 수정
            </label>
          </>
        )}

        {item.status !== 'cancelled' && item.status !== 'pending_cancelled' && item.status !== 'completed' && item.status !== 'host_paid' && (
          <label
            onClick={() => {
              window.location.href = `/booking/place/${item.id}/cancel`
            }}
            style={{marginLeft: 10}}
            className='btn btn-sm btn-danger'
          >
            취소
          </label>
        )}
      </div>
    </div>
  )
}

export {BookingPlaceListItem}
