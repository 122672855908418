import React from 'react'
import {TextSub} from './TextSub'

type Props = {
  feedback: any
  setHide: (feedback: any) => void
  setBlind: (feedback: any) => void
  deleteFeedback: (feedback: any) => void
}

const BookingFeedbackItem: React.FC<Props> = ({feedback, setHide, setBlind, deleteFeedback}) => {
  return <div style={{
    marginTop: 16,
    padding: 20,
    width: '100%',
    borderRadius: 10,
    border: '1px solid #DDD',
  }}>

    <TextSub title={'후기번호'} subTitle={feedback.id} marginTop={2} />
    <TextSub title={'예약번호'} subTitle={feedback.booking_id} marginTop={2} />
    <TextSub title={'사용자 아이디'} subTitle={feedback.user_id} marginTop={2} />
    <TextSub title={'사용자 유형'} subTitle={feedback.feedback_id > 0 ? 'host' : feedback.type} marginTop={2} />
    <TextSub title={'관리자 후기 숨김'} subTitle={feedback.blind ? 'Y' : 'N'} marginTop={2} />
    <TextSub title={'관리자 후기 삭제'} subTitle={feedback.hide ? 'Y' : 'N'} marginTop={2} />
    <TextSub title={'글쓴이 후기 삭제'} subTitle={feedback.feedback_delete ? 'Y' : 'N'} marginTop={2} />
    <TextSub title={'평점'} subTitle={feedback.rating} marginTop={2} />
    <TextSub title={feedback.feedback_id > 0 ? '후기 답글' : '후기 내용'} subTitle={feedback.comment} marginTop={2} />

    <div style={{
      display: 'flex',
      flexDirection: 'row',
    }}>
      {
        feedback.blind === 0 &&
        <label onClick={() => {
          setBlind(feedback)
        }} className='btn btn-danger'>후기 숨김</label>
      }
      {
        feedback.feedback_id === 0 && feedback.type === 'guest' && feedback.hide === 0 &&
        <label onClick={() => {
          setHide(feedback)
        }} style={{marginLeft: 10}} className='btn btn-danger'>후기 삭제</label>
      }

      <label onClick={() => {
        deleteFeedback(feedback)
      }} style={{marginLeft: 10}} className='btn btn-danger'>후기 재작성(업데이트 후 삭제 예정)</label>
    </div>

  </div>
}

export {BookingFeedbackItem}
