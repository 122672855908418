import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import {Button} from 'react-bootstrap'
import {toast} from 'react-toastify'
import moment from 'moment'

export function BookingPlaceAdditional() {
  const {booking_id} = useParams<{booking_id: string}>()
  const [bookingInfo, setBookingInfo] = useState<any>(null)

  const [priceHost, setPriceHost] = useState(0)
  const [hostFee, setHostFee] = useState('')
  const [priceGuestVat, setPriceGuestVat] = useState(0)
  const [guestFee, setGuestFee] = useState('')

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [bookingContent, setBookingContent] = useState('')
  const [isPassMoney, setIsPassMoney] = useState(0)

  useEffect(() => {
    getBookingInfo()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const additional = () => {
    if (start.length === 14 && end.length === 14 && priceHost > 0 && priceGuestVat > 0 && bookingContent !== '') {
      if (window.confirm('추가 청구 하시겠습니까?')) {
        axios
          .post(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/additional`, {
            start,
            end,
            guest_fee: guestFee,
            host_fee: hostFee,
            price_host: priceHost,
            price_guest_vat: priceGuestVat,
            booking_content: bookingContent,
            is_pass_money: isPassMoney,
          })
          .then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
              toast.error(error)
            } else {
              window.location.href = `/booking/place/${result}`
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      toast.error('정보를 확인해 주세요.')
    }
  }

  const getBookingInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          setBookingInfo(result)
          setStart(result.booking.end)
          setEnd(moment(result.booking.end, 'YYYYMMDDHHmmss').add(1, 'hours').format('YYYYMMDDHHmmss'))
          setHostFee(result.booking.host_fee)
          setGuestFee(result.booking.guest_fee)
          //if ([2, 9, 10, 11, 12, 13, 14].includes(result.booking.place_category_main_id)) {
          if (1 === result.booking.is_studio) {
            setPriceHost(result.booking.price_guest_vat / result.booking.hour)
          } else {
            setPriceHost((result.booking.price_host * (100 / (100 - result.booking.host_fee))) / result.booking.hour)
          }
          setPriceGuestVat(result.booking.price_guest_vat / result.booking.hour)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>추가 청구 만들기</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='col-12'>
            <div className='card card-custom card-stretch shadow mb-5'>
              <div className='card-header'>{bookingInfo !== null && <h1 className='card-title'>예약 번호 : {bookingInfo.booking.id}</h1>}</div>

              <div className='card-body'>
                {bookingInfo !== null && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <TextSub title={'파손 청구'} subTitle={''} />
                      </div>
                      <div className='switch switch-icon'>
                        <div className='form-check form-check-custom form-check-solid form-switch mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={bookingInfo.booking.is_pass_money}
                            onChange={(event) => {
                              const temp = Object.assign({}, bookingInfo.booking)
                              temp.is_pass_money = event.target.checked ? 1 : 0
                              setIsPassMoney(Number(temp.is_pass_money))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <TextSub title={'장소 번호'} subTitle={bookingInfo.place.id} />
                    <TextSub title={'장소 제목'} subTitle={bookingInfo.place.title} />
                    <TextSub
                      title={'촬영 스케줄'}
                      subTitle={`${moment(bookingInfo.booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(bookingInfo.booking.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}`}
                    />
                    {bookingInfo.booking.place_price_type === 0 ? (
                      <TextSub title={'장소 금액'} subTitle={bookingInfo.booking.place_price_guest_vat.toLocaleString('en')} />
                    ) : (
                      <TextSub title={'장소 금액'} subTitle={`${bookingInfo.booking.place_price_min.toLocaleString('en')} ~ ${bookingInfo.booking.place_price_max.toLocaleString('en')}`} />
                    )}
                  </>
                )}

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {bookingInfo !== null && 1 === bookingInfo.booking.is_studio ? (
                      <TextSub title={'호스트 정산 금액\n(수수료 미적용, 부가세 포함)'} subTitle={''} />
                    ) : (
                      <TextSub title={'호스트 정산 금액\n(수수료 미적용)'} subTitle={''} />
                    )}
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={''}
                    value={priceHost}
                    onChange={(e) => {
                      setPriceHost(Number(e.target.value))
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TextSub title={'호스트 수수료'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={''}
                    value={hostFee}
                    onChange={(e) => {
                      setHostFee(e.target.value)
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TextSub title={'게스트 결제 금액\n부가세 포함'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={''}
                    value={priceGuestVat}
                    onChange={(e) => {
                      setPriceGuestVat(Number(e.target.value))
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TextSub title={'게스트 수수료'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={''}
                    value={guestFee}
                    onChange={(e) => {
                      setGuestFee(e.target.value)
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TextSub title={'시작시간'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={''}
                    value={start}
                    onChange={(e) => {
                      setStart(e.target.value)
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TextSub title={'종료시간'} subTitle={''} />
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={''}
                    value={end}
                    onChange={(e) => {
                      setEnd(e.target.value)
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <TextSub title={'청구 사유'} subTitle={''} />
                  </div>
                  <textarea
                    className='form-control'
                    rows={10}
                    placeholder='청구 사유를 작성해 주세요.'
                    value={bookingContent}
                    onChange={(e) => {
                      setBookingContent(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  variant='danger'
                  onClick={() => {
                    additional()
                  }}
                >
                  추가 청구 하기
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
