import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {toast} from 'react-toastify'

export function MarketingMainBannerImageEditor() {
  const {main_banner_id} = useParams<{main_banner_id: string}>()
  const [advId, setAdvId] = useState(0)
  const [info, setInfo] = useState({
    active: false,
    sub_title_arrow: false,
    login: false,
    background_color: '#3c82fa',
    type: 'main',
    url: '',
    order: 0,
    version: 3,
    black: false,
    image_url: '',
    m_image_url: '',
    title: '',
    title_color: '#ffffff',
    sub_title: '',
    sub_title_color: '#ffffff',
    button_title: '',
    button_title_color: '#ffffff',
    button_background_color: '#195bfa',
    google_url: '',
    google_url_button_title: '',
    google_id_code: '',
    google_email_code: '',
    images: [],
  })

  useEffect(() => {
    if (Number(main_banner_id) > 0) {
      getMainBanners()
    }
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMainBanners = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/main/banner/${main_banner_id}`)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          setAdvId(result.adv_id)
          const temp = result.infoObj
          temp.images.forEach((image: any) => {
            if (image.url === undefined || image.url === null) {
              image.url = ''
            }
            if (image.width === undefined) {
              image.width = 100
            }
            if (image.padding_top === undefined) {
              image.padding_top = 0
            }
            if (image.padding_bottom === undefined) {
              image.padding_bottom = 0
            }
            if (image.padding_right === undefined) {
              image.padding_right = 0
            }
            if (image.padding_left === undefined) {
              image.padding_left = 0
            }
            if (image.login === undefined) {
              image.login = 0
            }
            if (image.google === undefined) {
              image.google = 0
            }
          })
          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const update = () => {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/main/banner/image/${main_banner_id}`, {
        advId: advId,
        info: info,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          if (Number(main_banner_id) === 0) {
            window.location.href = `/marketing/main/banner/image`
          } else {
            window.location.reload()
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFileInputMainImage = async (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    try {
      const {
        data: {error, result},
      } = await axios.post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      console.log(error, result)
      if (error === '') {
        console.log(result)
        const temp = Object.assign({}, info)
        temp.image_url = result.image_url
        setInfo(temp)
      } else {
        toast.error(error)
      }
    } catch (error_1) {
      console.log(error_1)
    }
  }

  const handleFileInputMobileMainImage = async (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    try {
      const {
        data: {error, result},
      } = await axios.post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      console.log(error, result)
      if (error === '') {
        const temp = Object.assign({}, info)
        temp.m_image_url = result.image_url
        setInfo(temp)
      } else {
        toast.error(error)
      }
    } catch (error_1) {
      console.log(error_1)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>메인 배너 (full-image)</PageTitle>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>광고 연결하기</h1>
            </div>
            <div className='card-body'>
              <TextSub2 title={'광고 ID'} subTitle={''} />
              <input
                type='number'
                className='form-control'
                placeholder='메인 베너 광고의 광고 ID 값을 입력해주세요'
                value={advId}
                onChange={(e) => {
                  setAdvId(parseInt(e.target.value))
                }}
              />
            </div>
          </div>
        </div>
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>배너 옵션</h1>
            </div>
            <div className='card-body'>
              <TextSub2 title={'노출'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.active}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.active = event.target.checked
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'PC 메인 이미지'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control mb-5'
                placeholder=''
                value={info.image_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.image_url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
              <input type='file' name='file' onChange={(e) => handleFileInputMainImage(e)} />

              <div style={{position: 'relative', width: '100%', aspectRatio: `${1160 / 360}`, marginTop: 20}}>
                {info.image_url !== '' && <img src={info.image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />}
              </div>

              <div className='separator my-10' />
              <TextSub2 title={'Mobile/APP 메인 이미지'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control mb-5'
                placeholder=''
                value={info.m_image_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.m_image_url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
              <input type='file' name='file' onChange={(e) => handleFileInputMobileMainImage(e)} />

              <div style={{position: 'relative', width: '50%', aspectRatio: `${375 / 240}`, marginTop: 20}}>
                {info.m_image_url !== '' && <img src={info.m_image_url} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />}
              </div>

              <div className='separator my-10' />
              <TextSub2 title={'링크'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.url = e.target.value
                  setInfo(temp)
                }}
                required={true}
              />
            </div>
          </div>

          <div
            style={{
              width: '100%',
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: 10,
              }}
            >
              <label
                onClick={() => {
                  update()
                }}
                className='btn btn-primary'
              >
                {main_banner_id !== '0' ? '수정하기' : '저장하기'}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
