import React from 'react'
import {TextSub} from '../../components/TextSub'

type Props = {
  item: any
}

const InfoBox: React.FC<Props> = ({item}) => {

  if (item.booking_status !== undefined) {
    switch (item.booking_status) {
      case 'pending':
        item.booking_status = '관리자 대기'
        break
      case 'pending_cancelled':
        item.booking_status = '관리자 취소'
        break
      case 'cancelled':
        item.booking_status = '취소'
        break
      case 'approved':
        item.booking_status = '승인 대기'
        break
      case 'proceeding':
        item.booking_status = '진행중'
        break
      case 'paid':
        item.booking_status = '결제 완료'
        break
      case 'completed':
        item.booking_status = '촬영 완료'
        break
      case 'host_paid':
        item.booking_status = '정산 완료'
        break
    }
  }

  return <div style={{
    marginTop: 40,
    width: '100%',
  }}>
    {
      item.booking_id !== undefined && item.booking_id > 0 &&
      <TextSub marginTop={0} title={'예약번호'} subTitle={`${item.booking_id}`} popUrl={`/booking/place/${item.booking_id}`} />
    }
    {
      item.booking_status !== undefined && item.booking_status !== '' &&
      <TextSub marginTop={0} title={'예약상태'} subTitle={`${item.booking_status}`} />
    }
    {
      item.place_id !== undefined && item.place_id > 0 &&
      <TextSub marginTop={0} title={'장소'} subTitle={`${item.place_id}. ${item.place_title}`} popUrl={`/place/${item.place_id}`} />
    }
    {
      item.host_id !== undefined && item.host_id > 0 && item.host_black_comment !== '' &&
      <TextSub marginTop={0} title={'호스트'} subTitle={`${item.host_id}. ${item.host_name} (${item.host_black_comment})`} popUrl={`/user/${item.host_id}`} />
    }
    {
        item.host_id !== undefined && item.host_id > 0 && item.host_black_comment === '' &&
        <TextSub marginTop={0} title={'호스트'} subTitle={`${item.host_id}. ${item.host_name}`} popUrl={`/user/${item.host_id}`} />
    }
    {
      item.guest_id !== undefined && item.guest_id > 0 && item.guest_black_comment !== '' &&
      <TextSub marginTop={0} title={'게스트'} subTitle={`${item.guest_id}. ${item.guest_name} (${item.guest_black_comment})`} popUrl={`/user/${item.guest_id}`} />
    }
    {
        item.guest_id !== undefined && item.guest_id > 0 && item.guest_black_comment === '' &&
        <TextSub marginTop={0} title={'게스트'} subTitle={`${item.guest_id}. ${item.guest_name}`} popUrl={`/user/${item.guest_id}`} />
    }
    {
      item.created_at !== undefined && item.created_at !== null &&
      <TextSub marginTop={0} title={'일시'} subTitle={`${item.created_at}`} />
    }
    <TextSub marginTop={0} title={'보낸사람'} subTitle={`${item.grey_user_id === item.guest_id ? '게스트' : '호스트'}`} />
    {
      item.ban_step > 0 && item.is_user_ban === 0 &&
      <h3 className={'mt-2'}>
        현재 경고 {item.ban_step}차 적용중
      </h3>
    }
  </div>
}

export {InfoBox}
