import React, {useEffect, useState} from 'react'

import {initializeApp} from 'firebase/app'
import {getDatabase, ref, onValue} from 'firebase/database'

export function Revenue() {

  const firebaseConfig = JSON.parse(`${process.env.REACT_APP_FIREBASE_CONFIG}`)

  const [revenue, setRevenue] = useState<any>(null)
  const [updateTime, setUpdateTime] = useState('')
  const app = initializeApp(firebaseConfig)
  const database = getDatabase(app)
  const starCountRef = ref(database, 'admin/revenue')

  useEffect(() => {
    return onValue(starCountRef, (snapshot) => {
      const data = snapshot.val()
      console.log(data)
      setRevenue(data)
      setUpdateTime(data.updated_at)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
      padding: 20,
    }}>

      <p style={{
        fontSize: 14,
      }}>
        update : {updateTime}
      </p>

      {
        revenue &&
        <div>
          <div>
            <p style={{
              fontSize: 20,
              margin: 0,
            }}>
              {revenue.sales_year_display}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 76,
            }}>
              {Number(revenue.sales_year).toLocaleString('en')}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 50,
            }}>
              ({Number(revenue.ad_year).toLocaleString('en')})
            </p>
          </div>
          <div style={{
            marginTop: 20,
          }}>
            <p style={{
              fontSize: 20,
              margin: 0,
            }}>
              {revenue.sales_this_month_display}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 76,
            }}>
              {Number(revenue.sales_this_month).toLocaleString('en')} ~ {Number(revenue.sales_this_pre_month).toLocaleString('en')}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 50,
            }}>
              ({Number(revenue.ad_this_month).toLocaleString('en')})
            </p>
          </div>
          <div style={{
            marginTop: 20,
          }}>
            <p style={{
              fontSize: 20,
              margin: 0,
            }}>
              {revenue.salse_today_this_month_display}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 50,
            }}>
              {Number(revenue.sales_today_other_month + revenue.sales_today_this_month).toLocaleString('en')}({Number(revenue.sales_today_this_month).toLocaleString('en')})
            </p>
          </div>
          <hr />
          <div style={{
            marginTop: 10,
          }}>
            <p style={{
              fontSize: 20,
              margin: 0,
            }}>
              {revenue.sales_next_month_display}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 76,
            }}>
              {Number(revenue.sales_next_month).toLocaleString('en')}
            </p>
            <p style={{
              width: 600,
              textAlign: 'right',
              fontSize: 50,
            }}>
              ({Number(revenue.ad_next_month).toLocaleString('en')})
            </p>
          </div>
        </div>
      }
    </div>
  )
}
