import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import Highlighter from 'react-highlight-words'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import Modal from '../../components/Modal'
import {InfoBox} from '../components/InfoBox'
import {apiGetGreyKeywords} from '../../../apis/Grey'

export function GreyGuestBookingCancel() {
  const [items, setItems] = useState<any>([])

  const [greyKeywords, setGreyKeywords] = useState([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('')

  const [status, setStatus] = useState('')

  const [countBookingCancel, setCountBookingCancel] = useState(0)
  const [countBookingCancelDefault, setCountBookingCancelDefault] = useState(0)

  const [item, setItem] = useState<any>(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [banStep, setBanStep] = useState(0)
  const [banModal, setBanModal] = useState(false)

  useEffect(() => {
    getGreyKeywords()
    getGreys()
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getGreys(status, page)
  }

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getGreys(tabStatus, 1)
  }

  const onSearch = () => {
    setStatus('')
    getGreys('', 1, search)
  }

  const getGreys = (status = '', page = 1, search = '') => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/grey?user_type=guest&keyword=${search}&grey_type=booking_cancel&page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          const greys = result.data.greys
          greys.forEach((grey: any) => {
            grey.ban_comment = ''
            grey.ban_type = '0'
          })
          setItems(result.data.greys)
          setCountBookingCancel(result.data.count_booking_cancel)
          setCountBookingCancelDefault(result.data.count_booking_cancel_default)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getGreyKeywords = () => {
    apiGetGreyKeywords()
      .then(({error, result}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setGreyKeywords(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const setBan = (item: any, step = 0) => {
    if ((item.ban_comment !== '' && step > 0) || step === 0) {
      console.log({ban_step: step, ban_comment: item.ban_comment, grey_id: item.grey_id})
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/user/ban`, {ban_step: step, ban_comment: item.ban_comment, grey_id: item.grey_id})
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temps = [...items]
            temps.splice(temps.indexOf(item), 1, item)
            item.grey_status = step
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      toast.error('패널티 사유를 적어주세요.')
    }
  }

  const deleteQna = (item: any) => {
    console.log({user_id: item.guest_id, place_id: item.place_id, place_qna_id: item.place_qna_id, blind: 1})
    axios
      .delete(`${process.env.REACT_APP_API2_URL}am/place/qna/blind`, {data: {user_id: item.guest_id, place_id: item.place_id, place_qna_id: item.place_qna_id, blind: 1}})
      .then(({data: {error, result}}) => {
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          const temps = [...items]
          temps.splice(temps.indexOf(item), 1, item)
          item.place_qna_blind = 1
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>그레이 게스트</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <label
            onClick={() => {
              onTab('booking_cancel')
            }}
            className={classNames('btn', {'btn-dark': status === 'booking_cancel'}, {'btn-btn-white': status !== 'booking_cancel'})}
          >
            예약취소 ({countBookingCancel.toLocaleString('en')} <span style={{color: 'red'}}>/ {countBookingCancelDefault.toLocaleString('en')}</span>)
          </label>
        </div>

        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <input
            type='text'
            className='form-control'
            placeholder='예약 번호, 장소 번호, 장소명, 예약자 이름, 호스트 이름'
            value={search}
            style={{
              flex: 1,
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
          <label
            onClick={() => {
              onSearch()
            }}
            style={{width: 100}}
            className='btn btn-dark'
          >
            검색
          </label>
        </div>

        {loading ? (
          <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className='indicator-progress' style={{display: 'block'}}>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          </div>
        ) : (
          <div
            style={{
              padding: 20,
              borderRadius: 20,
              backgroundColor: 'white',
              width: '100%',
            }}
            key={'gery_guest'}
          >
            {items.map((item: any, index: number) => {
              return (
                <div
                  style={{
                    marginTop: 16,
                    padding: 20,
                    width: '100%',
                    borderRadius: 10,
                    border: '1px solid #DDD',
                  }}
                  key={item.grey_id.toString() + index}
                >
                  <h1>
                    {item.grey_type === 'question' ? '게스트_장소 문의' : ''}
                    {item.grey_type === 'user_change' ? '게스트_프로필' : ''}
                    {item.grey_type === 'booking' ? '게스트_예약 신청' : ''}
                    {item.grey_type === 'message' ? '게스트_예약 문의' : ''}
                    {item.grey_type === 'booking_cancel' ? '게스트_예약 취소' : ''}
                  </h1>

                  <InfoBox item={item} />

                  {item.grey_type === 'user_change' && (
                    <div>
                      <div className='separator my-10' />
                      <h2>추가정보</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        <Highlighter searchWords={greyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.guest_company ?? ''} />
                      </p>

                      <div className='separator my-10' />

                      <h2>자기 소개</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        <Highlighter searchWords={greyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.guest_comment ?? ''} />
                      </p>

                      <div className='separator my-10' />

                      <h2>직업</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        {null !== item.user_career && undefined !== item.user_career && null !== item.user_career.job && undefined !== item.user_career.job && (
                          <Highlighter searchWords={greyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.user_career.job ?? ''} />
                        )}
                      </p>

                      <div className='separator my-10' />

                      <h2>나의 경험</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        {null !== item.experiences &&
                          undefined !== item.experiences &&
                          item.experiences.map((subItem: any, index: number) => {
                            return (
                              <>
                                <Highlighter
                                  searchWords={greyKeywords}
                                  autoEscape
                                  highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                  textToHighlight={subItem.company + '\n' ?? ''}
                                  key={'experience_company_' + index}
                                />
                                <Highlighter
                                  searchWords={greyKeywords}
                                  autoEscape
                                  highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                  textToHighlight={subItem.position + '\n' ?? ''}
                                  key={'experience_position_' + index}
                                />
                                {subItem.start} ~ {subItem.end}
                                <br />
                                <Highlighter
                                  searchWords={greyKeywords}
                                  autoEscape
                                  highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                  textToHighlight={subItem.content + '\n' ?? ''}
                                  key={'experience_content_' + index}
                                />
                              </>
                            )
                          })}
                      </p>

                      <div className='separator my-10' />

                      <h2>스킬 및 툴</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        {null !== item.user_career && undefined !== item.user_career && null !== item.user_career.skill && undefined !== item.user_career.skill && (
                          <Highlighter searchWords={greyKeywords} autoEscape highlightStyle={{backgroundColor: 'red', color: 'white'}} textToHighlight={item.user_career.skill ?? ''} />
                        )}
                      </p>

                      <div className='separator my-10' />

                      <h2>수상 및 활동</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        {null !== item.activities &&
                          undefined !== item.activities &&
                          item.activities.map((subItem: any, index: number) => {
                            return (
                              <>
                                <Highlighter
                                  searchWords={greyKeywords}
                                  autoEscape
                                  highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                  textToHighlight={subItem.name ?? ''}
                                  key={'activities_name_' + index}
                                />
                                {subItem.date}
                                <br />
                                <br />
                              </>
                            )
                          })}
                      </p>

                      <div className='separator my-10' />

                      <h2>수료 및 자격증</h2>
                      <p style={{whiteSpace: 'pre-wrap', lineHeight: 2}}>
                        {null !== item.certificates &&
                          undefined !== item.certificates &&
                          item.certificates.map((subItem: any, index: number) => {
                            return (
                              <>
                                <Highlighter
                                  searchWords={greyKeywords}
                                  autoEscape
                                  highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                  textToHighlight={subItem.name ?? ''}
                                  key={'certificates_name_' + index}
                                />
                                <br />
                                <Highlighter
                                  searchWords={greyKeywords}
                                  autoEscape
                                  highlightStyle={{backgroundColor: 'red', color: 'white'}}
                                  textToHighlight={subItem.agency ?? ''}
                                  key={'certificates_agency_' + index}
                                />
                                <br />
                                <br />
                              </>
                            )
                          })}
                      </p>
                    </div>
                  )}

                  {item.grey_type === 'question' && (
                    <div>
                      <div className='separator my-10' />

                      <h2>장소 문의</h2>

                      {item.place_qna_blind === 1 && <p style={{color: 'red'}}>관리자 삭제</p>}
                      {item.place_qna_blind === 0 && (
                        <div style={{padding: 4, width: 'fit-content'}}>
                          <label
                            style={{border: '1px solid #F00'}}
                            onClick={() => {
                              setItem(item)
                              setDeleteModal(true)
                            }}
                            className='btn btn-outline-danger'
                          >
                            메시지 삭제 처리
                          </label>
                        </div>
                      )}

                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.place_qna_content ?? ''}
                        />
                      </p>

                      {(item.place_qna_blind === 1 || item.place_qna_delete === 1) && (
                        <div>
                          <h5>원본 내용</h5>
                          <p
                            style={{
                              whiteSpace: 'pre-wrap',
                              lineHeight: 2,
                            }}
                          >
                            <Highlighter
                              searchWords={greyKeywords}
                              autoEscape
                              highlightStyle={{
                                backgroundColor: 'red',
                                color: 'white',
                              }}
                              textToHighlight={item.place_qna_OContent ?? ''}
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  )}

                  {item.grey_type === 'message' && (
                    <div>
                      <div className='separator my-10' />

                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                        }}
                      >
                        <h2>메시지</h2>
                        <p
                          style={{
                            minWidth: 40,
                            paddingLeft: 10,
                            fontWeight: 500,
                            fontSize: 12,
                            color: '#007BFF',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            window.open(`/booking/message/${item.booking_id}`)
                          }}
                        >
                          보기
                        </p>
                      </div>

                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.message_content ?? ''}
                        />
                      </p>
                    </div>
                  )}

                  {item.grey_type === 'booking' && (
                    <div>
                      <div className='separator my-10' />

                      <h2>장르</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_genre ?? ''}
                        />
                      </p>

                      <h2>내용</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_content ?? ''}
                        />
                      </p>

                      <h2>인원</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_detail_people ?? ''}
                        />
                      </p>

                      <h2>세부장소</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_detail_place ?? ''}
                        />
                      </p>

                      <h2>장비</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_equipment ?? ''}
                        />
                      </p>

                      <h2>주차</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_parking ?? ''}
                        />
                      </p>
                    </div>
                  )}

                  {item.grey_type === 'booking_cancel' && (
                    <div>
                      <div className='separator my-10' />
                      <h2>취소 사유</h2>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: 2,
                        }}
                      >
                        <Highlighter
                          searchWords={greyKeywords}
                          autoEscape
                          highlightStyle={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                          textToHighlight={item.booking_cancelled ?? ''}
                        />
                      </p>
                    </div>
                  )}

                  {item.user_bans !== undefined && item.user_bans !== null && (
                    <div>
                      <div className='separator my-10' />
                      <h2>패널티 히스토리</h2>

                      {item.user_bans.data.map((ban: any, index: number) => {
                        return (
                          <div key={`ban_${ban.id}` + index}>
                            타입 : {ban.ban_type}, 제한일 : {ban.ban_expire}, 경고 {ban.ban_step}차, 사유 : {ban.ban_comment}
                          </div>
                        )
                      })}
                    </div>
                  )}

                  {item.grey_status < 0 ? (
                    <div>
                      <div className='separator my-10' />
                      <h2>패널티 적용</h2>

                      <div
                        style={{
                          marginTop: 26,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            className='form-check form-check-custom form-check-solid form-check-sm'
                            style={{
                              marginRight: 20,
                              paddingBottom: 10,
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              value='1'
                              id={`check_1_${item.grey_id}`}
                              checked={item.ban_type === '1'}
                              onChange={(e: any) => {
                                const temps = [...items]
                                temps.splice(temps.indexOf(item), 1, item)
                                item.ban_comment = '개인정보 공유 및 요구'
                                item.ban_type = e.target.value
                                setItems(temps)
                              }}
                            />
                            <label className='form-check-label' htmlFor={`check_1_${item.grey_id}`}>
                              개인정보 공유 및 요구
                            </label>
                          </div>
                          <div
                            className='form-check form-check-custom form-check-solid form-check-sm'
                            style={{
                              marginRight: 20,
                              paddingBottom: 10,
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              value='2'
                              id={`check_2_${item.grey_id}`}
                              checked={item.ban_type === '2'}
                              onChange={(e: any) => {
                                const temps = [...items]
                                temps.splice(temps.indexOf(item), 1, item)
                                item.ban_comment = '금액 협의 및 직거래 유도'
                                item.ban_type = e.target.value
                                setItems(temps)
                              }}
                            />
                            <label className='form-check-label' htmlFor={`check_2_${item.grey_id}`}>
                              금액 협의 및 직거래 유도
                            </label>
                          </div>
                          <div
                            className='form-check form-check-custom form-check-solid form-check-sm'
                            style={{
                              marginRight: 20,
                              paddingBottom: 10,
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              value='3'
                              id={`check_3_${item.grey_id}`}
                              checked={item.ban_type === '3'}
                              onChange={(e: any) => {
                                const temps = [...items]
                                temps.splice(temps.indexOf(item), 1, item)
                                item.ban_comment = '비방글 작성'
                                item.ban_type = e.target.value
                                setItems(temps)
                              }}
                            />
                            <label className='form-check-label' htmlFor={`check_3_${item.grey_id}`}>
                              비방글 작성
                            </label>
                          </div>
                          <div
                            className='form-check form-check-custom form-check-solid form-check-sm'
                            style={{
                              marginRight: 20,
                              paddingBottom: 10,
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              value='0'
                              id={`check_0_${item.grey_id}`}
                              checked={item.ban_type === '0'}
                              onChange={(e: any) => {
                                const temps = [...items]
                                temps.splice(temps.indexOf(item), 1, item)
                                item.ban_comment = ''
                                item.ban_type = e.target.value
                                setItems(temps)
                              }}
                            />
                            <label className='form-check-label' htmlFor={`check_0_${item.grey_id}`}>
                              직접 입력
                            </label>
                          </div>
                          {item.ban_type === '0' && (
                            <div
                              className='form-check form-check-custom form-check-solid form-check-sm'
                              style={{
                                marginRight: 20,
                                paddingBottom: 10,
                              }}
                            >
                              <input
                                type='text'
                                name='ban_type'
                                onChange={(e: any) => {
                                  const temps = [...items]
                                  temps.splice(temps.indexOf(item), 1, item)
                                  item.ban_comment = e.target.value
                                  setItems(temps)
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div className={'row'}>
                          {item.ban_step < 2 && (
                            <div style={{padding: 4, width: 'fit-content'}}>
                              <label
                                onClick={() => {
                                  if (item.ban_comment !== '') {
                                    setBanStep(item.ban_step + 1)
                                    setItem(item)
                                    setBanModal(true)
                                  } else {
                                    toast.error('패널티 사유를 적어주세요.')
                                  }
                                }}
                                className='btn btn-primary'
                              >
                                패널티 적용
                              </label>
                            </div>
                          )}
                          {(item.ban_step < 3 || ((item.ban_step === 3 || item.ban_step === 4) && item.is_user_ban === 1)) && (
                            <div style={{padding: 4, width: 'fit-content'}}>
                              <label
                                style={{border: '1px solid #F00'}}
                                onClick={() => {
                                  if (item.ban_comment !== '') {
                                    setBanStep(3)
                                    setItem(item)
                                    setBanModal(true)
                                  } else {
                                    toast.error('패널티 사유를 적어주세요.')
                                  }
                                }}
                                className='btn btn-outline-danger'
                              >
                                3차 패널티 바로 적용
                              </label>
                            </div>
                          )}
                          <div style={{padding: 4, width: 'fit-content'}}>
                            <label
                              style={{border: '1px solid #6AD559'}}
                              onClick={() => {
                                setBan(item)
                              }}
                              className='btn btn-outline-success'
                            >
                              건너뛰기
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      <label style={{border: '1px solid #007BFF', borderRadius: 8, padding: '8px 16px'}} className='btn-outline-primary'>
                        {item.grey_status === 0 ? '건너뛰기 처리 완료' : ''}
                        {item.grey_status > 0 ? `${item.grey_status}단계 경고 처리 완료` : ''}
                      </label>
                    </div>
                  )}
                </div>
              )
            })}

            <div style={{padding: 20}}>
              <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
            </div>
          </div>
        )}
      </div>
      {deleteModal && (
        <Modal
          visible={deleteModal}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setDeleteModal(false)
          }}
          className={''}
        >
          <div>
            <h2>메시지를 삭제하시겠습니까?</h2>
            <div className='separator my-4' />
            <div style={{width: '100%', textAlign: 'right'}}>
              <label
                style={{margin: 4}}
                onClick={() => {
                  setDeleteModal(false)
                }}
                className={'btn btn-secondary'}
              >
                취소
              </label>
              <label
                style={{margin: 4}}
                onClick={() => {
                  deleteQna(item)
                  setDeleteModal(false)
                }}
                className={'btn btn-danger'}
              >
                삭제
              </label>
            </div>
          </div>
        </Modal>
      )}
      {banModal && (
        <Modal
          visible={banModal}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setBanModal(false)
          }}
          className={''}
        >
          <div>
            <h2>
              {banStep === 1 ? '1단계 경고 처리가 됩니다.' : ''}
              {banStep === 2 ? '24시간 이용정지 처리가 됩니다.' : ''}
              {banStep === 3 ? '7일 이용정지 처리가 됩니다.' : ''}
            </h2>
            <div className='separator my-4' />
            <div style={{width: '100%', textAlign: 'right'}}>
              <label
                style={{margin: 4}}
                onClick={() => {
                  setBanModal(false)
                }}
                className={'btn btn-secondary'}
              >
                취소
              </label>
              <label
                style={{margin: 4}}
                onClick={() => {
                  setBan(item, banStep)
                  setBanModal(false)
                }}
                className={'btn btn-danger'}
              >
                패널티 적용
              </label>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
