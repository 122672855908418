import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {TextSub} from '../../components/TextSub'
import {
  Button,
} from 'react-bootstrap'
import {toast} from 'react-toastify'
import moment from 'moment'

export function BookingPlaceEditTime() {

  const {booking_id} = useParams<{booking_id: string}>()
  const [bookingInfo, setBookingInfo] = useState<any>(null)

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')

  useEffect(() => {

    getBookingInfo()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const update = () => {
    console.log(start)
    console.log(end)
    if (start.length === 12 && end.length === 12) {
      if (window.confirm('예약 시간을 수정 하시겠습니까?')) {
        axios.put(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}/update/time`, {
          start, end,
        }).then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            window.location.href = `/booking/place/${booking_id}`
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    } else {
      toast.error('시간을 정확히 입력해주세요.')
    }
  }

  const getBookingInfo = () => {
    axios.get(`${process.env.REACT_APP_API2_URL}am/booking/${booking_id}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setBookingInfo(result)
        setStart(result.booking.start.substring(0, 12))
        setEnd(result.booking.end.substring(0, 12))
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>예약 시간 수정</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        <div className='col-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              {
                bookingInfo !== null &&
                <h1 className='card-title'>
                  예약 번호 : {bookingInfo.booking.id}
                </h1>
              }
            </div>
            <div className='card-body'>
              {
                bookingInfo !== null &&
                <>
                  <TextSub title={'장소 번호'} subTitle={bookingInfo.place.id} />
                  <TextSub title={'장소 제목'} subTitle={bookingInfo.place.title} />
                  <TextSub title={'촬영 스케줄'} subTitle={`${moment(bookingInfo.booking.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ ${moment(bookingInfo.booking.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}`} />
                </>
              }

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'시작시간'} subTitle={''} />
                </div>
                <input
                  type='text'
                  className='form-control'
                  placeholder={''}
                  value={start.substring(0, 12)}
                  onChange={(e) => {
                    setStart(e.target.value)
                  }}
                />
              </div>

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <TextSub title={'종료시간'} subTitle={''} />
                </div>
                <input
                  type='text'
                  className='form-control'
                  placeholder={''}
                  value={end.substring(0, 12)}
                  onChange={(e) => {
                    setEnd(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='card-footer' style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                variant='danger'
                onClick={() => {
                  update()
                }}>
                수정 하기
              </Button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </>
}

