import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'

export function MarketingMainBanner() {

  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getMainBanners()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page: number) => {
    getMainBanners(page)
  }

  const getMainBanners = (page = 1) => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/main/banner?page=${page}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <>
    <PageTitle breadcrumbs={[]}>메인 배너</PageTitle>

    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

      <label className={'btn btn-primary'} onClick={() => {
        window.location.href = `/marketing/main/banner/0`
      }}>
        메인 배너 만들기
      </label>

      <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>

        <div>
          {items.map((item: any, index: number) => {
            return (
              <div className='col-12' key={index.toString()}>
                <div className='card card-custom card-stretch shadow mb-5'>
                  <div className='card-header'>
                    <h1 className='card-title'>
                      {item.id}. {item.title}
                    </h1>
                  </div>
                  <div className='card-body'>
                    <img src={item.image_url} alt='' style={{width: 400, height: 220, objectFit: 'cover'}}/>
                    <div className='separator my-10' />
                    <h4>
                      순서 : {item.order}
                      <br />
                      <br />
                      노출 : {item.active === 1 ? 'Y' : 'N'}
                      <br />
                      <br />
                      {item.sub_title}
                    </h4>
                  </div>
                  <div className='card-footer'>
                    <label onClick={() => {
                      window.open(`${process.env.REACT_APP_WEB_URL}banner/${item.id}`)
                    }} className='btn btn-primary'>보기</label>
                    <label style={{marginLeft: 10}} onClick={() => {
                      window.location.href = `/marketing/main/banner/${item.id}`
                    }} className='btn btn-primary'>수정</label>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <Pagination activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  prevPageText={'‹'}
                  nextPageText={'›'}
                  onChange={handlePageChange} />

    </div>
  </>
}
