import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {UserItem} from '../components/UserItem'
import classNames from 'classnames'
import {PlaceItem} from '../components/PlaceItem'
import moment from 'moment'

export function UserDetail() {
  const [status, setStatus] = useState('19')

  const {user_id} = useParams<{user_id: string}>()
  const [user, setUser] = useState<any>(null)
  const [adminNotesComment, setAdminNotesComment] = useState('채널 & 인/아웃 : \n고객 유형: \n문의 내용: \n답변 내용: \n참고사항: ')

  useEffect(() => {
    getUser()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/user/${user_id}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          setUser(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const adminNoteInsert = () => {
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/user/${user_id}/admin/note`, {comment: adminNotesComment})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          setUser(result.result)
          setAdminNotesComment('채널 & 인/아웃 : \n고객 유형: \n문의 내용: \n답변 내용: \n참고사항: ')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const adminExternalCalendarModify = (type: any, placeId: any, url: any) => {
    console.log('type : ' + type)
    console.log('placeId : ' + placeId)
    console.log('url : ' + url)
    if (null === url) {
      alert('캘린더 URL 확인 필요!\n새로 고침 후 다시 이용해 주세요.')
      return
    }
    axios
      .post(`${process.env.REACT_APP_API2_URL}am/user/place/external/calendar/${type}`, {placeId: placeId, userId: user_id, url: url})
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          window.alert(error)
        } else {
          if ('delete' === type) {
            alert('캘린더가 삭제됐어요.')
          } else {
            alert('캘린더가 연동됐어요.')
          }
          setUser(result.result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>사용자 상세</PageTitle>

      <div
        style={{
          padding: 10,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {user !== null && (
          <div style={{borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
              <div style={{margin: 4}}>
                <label
                  style={{marginLeft: 10}}
                  onClick={() => {
                    window.location.href = `/user/${user_id}/edit`
                  }}
                  className='btn btn-sm btn-warning'
                >
                  수정하기
                </label>
              </div>
            </div>

            <UserItem user={user} edit={true} />
            <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6' style={{marginTop: 20}}>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '19' ? 'active' : '')} onClick={() => setStatus('19')}>
                  관리자 노트 ({user.admin_notes !== undefined ? user.admin_notes.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '1' ? 'active' : '')} onClick={() => setStatus('1')}>
                  작성 후기 ({user.feedbacks !== undefined ? user.feedbacks.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '3' ? 'active' : '')} onClick={() => setStatus('3')}>
                  게스트에게 받은 후기 ({user.guest_feedbacks !== undefined ? user.guest_feedbacks.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '5' ? 'active' : '')} onClick={() => setStatus('5')}>
                  호스트에게 받은 후기 ({user.host_feedbacks !== undefined ? user.host_feedbacks.length.toLocaleString('en') : 0})
                </label>
              </li>

              <li className='nav-item'>
                <label className={classNames('nav-link', status === '2' ? 'active' : '')} onClick={() => setStatus('2')}>
                  예약 내역 ({user.host_bookings !== undefined ? user.host_bookings.length.toLocaleString('en') : 0})
                </label>
              </li>

              <li className='nav-item'>
                <label className={classNames('nav-link', status === '4' ? 'active' : '')} onClick={() => setStatus('4')}>
                  신청 내역 ({user.guest_bookings !== undefined ? user.guest_bookings.length.toLocaleString('en') : 0})
                </label>
              </li>

              <li className='nav-item'>
                <label className={classNames('nav-link', status === '6' ? 'active' : '')} onClick={() => setStatus('6')}>
                  장소 ({user.places !== undefined ? user.places.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '7' ? 'active' : '')} onClick={() => setStatus('7')}>
                  관심 목록 ({user.interests !== undefined ? user.interests.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '8' ? 'active' : '')} onClick={() => setStatus('8')}>
                  포인트 내역 ({user.points !== undefined ? user.points.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '9' ? 'active' : '')} onClick={() => setStatus('9')}>
                  패널티 ({user.bans !== undefined ? user.bans.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '10' ? 'active' : '')} onClick={() => setStatus('10')}>
                  모든 예약 내역 ({user.host_all_bookings !== undefined ? user.host_all_bookings.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '11' ? 'active' : '')} onClick={() => setStatus('11')}>
                  모든 신청 내역 ({user.guest_all_bookings !== undefined ? user.guest_all_bookings.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '13' ? 'active' : '')} onClick={() => setStatus('13')}>
                  회원정보 ({user.user_change_histories !== undefined ? user.user_change_histories.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '12' ? 'active' : '')} onClick={() => setStatus('12')}>
                  간편결제 ({user.user_auto_payment_cards !== undefined ? user.user_auto_payment_cards.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '14' ? 'active' : '')} onClick={() => setStatus('14')}>
                  추천인코드
                </label>
              </li>
              <li className='nav-item'>
                <label
                  className={classNames('nav-link', status === '15' ? 'active' : '')}
                  style={{
                    color: (user?.user_completed_not_host_paid_bookings.length ?? 0) > 0 ? 'red' : 'gray',
                  }}
                  onClick={() => setStatus('15')}
                >
                  미정산 건수/금액 ({user?.user_completed_not_host_paid_bookings.length.toLocaleString('en') ?? 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '16' ? 'active' : '')} onClick={() => setStatus('16')}>
                  게스트_포트폴리오 ({user.user_guest_feeds !== undefined ? user.user_guest_feeds.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '17' ? 'active' : '')} onClick={() => setStatus('17')}>
                  호스트_장소 소식 ({user.user_place_feeds !== undefined ? user.user_place_feeds.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '18' ? 'active' : '')} onClick={() => setStatus('18')}>
                  토스간편결제 ({user.user_toss_auto_payment_cards !== undefined ? user.user_toss_auto_payment_cards.length.toLocaleString('en') : 0})
                </label>
              </li>
              <li className='nav-item'>
                <label className={classNames('nav-link', status === '20' ? 'active' : '')} onClick={() => setStatus('20')}>
                  외부 캘린더 ({user.count_place_calendar_external_info})
                </label>
              </li>
              {/*<li className='nav-item'>*/}
              {/*  <label*/}
              {/*    className={classNames('nav-link', status === '9' ? 'active' : '')}*/}
              {/*    onClick={() => setStatus('9')}*/}
              {/*  >*/}
              {/*    추후 정산 ({user.pay_99.length.toLocaleString('en')})*/}
              {/*  </label>*/}
              {/*</li>*/}
            </ul>
            <div>
              {status === '19' && (
                <div>
                  <div style={{marginBottom: 30}}>
                    <textarea
                      className='form-control'
                      rows={5}
                      placeholder=''
                      value={adminNotesComment}
                      onChange={(e) => {
                        setAdminNotesComment(e.target.value)
                      }}
                    />
                    <label
                      style={{marginTop: 20}}
                      onClick={() => {
                        adminNoteInsert()
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      등록
                    </label>
                  </div>
                  {user.admin_notes !== undefined &&
                    user.admin_notes.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`1_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>작성일</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>작성자 이름</td>
                                    <td>{item.admin_user_name}</td>
                                  </tr>
                                  <tr style={{height: 15}}>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold', verticalAlign: 'top'}}>내용</td>
                                    <td>
                                      <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.comment}</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '1' && (
                <div>
                  {user.feedbacks !== undefined &&
                    user.feedbacks.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`1_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.type === 'guest' ? '장소 후기' : '게스트 후기'}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약번호</td>
                                    <td>{item.booking_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>평점</td>
                                    <td>{item.rating} 점</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>내용</td>
                                    <td>
                                      <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.feedback_comment}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>후기 작성일</td>
                                    <td>{item.feedback_created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '2' && (
                <div>
                  {user.host_bookings !== undefined &&
                    user.host_bookings.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`2_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.id}. {item.status_display}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>금액(부가세포함)</td>
                                    <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트</td>
                                    <td>{item.price_host.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>수수료</td>
                                    <td>{item.price_fee.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 번호</td>
                                    <td>{item.guest_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 이름</td>
                                    <td>{item.guest_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약 일자</td>
                                    <td>
                                      {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ({item.hour}시간)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='card-footer'>
                              <label
                                onClick={() => {
                                  window.open(`/booking/place/${item.id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                상세보기
                              </label>
                              <label
                                style={{marginLeft: 10}}
                                onClick={() => {
                                  window.open(`/user/${item.guest_id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                게스트보기
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '3' && (
                <div>
                  {user.guest_feedbacks !== undefined &&
                    user.guest_feedbacks.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`3_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.type === 'guest' ? '장소 후기' : '게스트 후기'}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약번호</td>
                                    <td>{item.booking_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>평점</td>
                                    <td>{item.rating} 점</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>내용</td>
                                    <td style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.feedback_comment}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>후기 작성일</td>
                                    <td>{item.feedback_created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '4' && (
                <div>
                  {user.guest_bookings !== undefined &&
                    user.guest_bookings.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`4_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.id}. {item.status_display}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>금액(부가세포함)</td>
                                    <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트</td>
                                    <td>{item.price_host.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>수수료</td>
                                    <td>{item.price_fee.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 번호</td>
                                    <td>{item.user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 이름</td>
                                    <td>{item.host_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약 일자</td>
                                    <td>
                                      {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ({item.hour}시간)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='card-footer'>
                              <label
                                onClick={() => {
                                  window.open(`/booking/place/${item.id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                상세보기
                              </label>
                              <label
                                style={{marginLeft: 10}}
                                onClick={() => {
                                  window.open(`/user/${item.guest_id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                게스트보기
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '5' && (
                <div>
                  {user.host_feedbacks !== undefined &&
                    user.host_feedbacks.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`5_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.type === 'guest' ? '장소 후기' : item.feedback_id === 0 ? '게스트 후기' : '장소 후기 답글'}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약번호</td>
                                    <td>{item.booking_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  {item.feedback_id === 0 && (
                                    <>
                                      <tr>
                                        <td style={{fontWeight: 'bold'}}>평점</td>
                                        <td>{item.rating} 점</td>
                                      </tr>
                                    </>
                                  )}

                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>내용</td>
                                    <td style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.feedback_comment}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>후기 작성일</td>
                                    <td>{item.feedback_created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '6' && (
                <div>
                  {user.places !== undefined &&
                    user.places.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`6_${index}`}>
                          <PlaceItem place={item} />
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '7' && (
                <div>
                  {user.interests !== undefined &&
                    user.interests.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`7_${index}`}>
                          <PlaceItem place={item} />
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '8' && (
                <div>
                  {user.points !== undefined &&
                    user.points.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약번호</td>
                                    <td>{item.booking_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>포인트</td>
                                    <td>{item.point.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>타입</td>
                                    <td>{item.type}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>내용</td>
                                    <td style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{item.comment}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>생성일자</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '9' && (
                <div>
                  {user.bans !== undefined &&
                    user.bans.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>패널티 타입</td>
                                    <td>{item.ban_type}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>패널티 사유</td>
                                    <td>{item.ban_comment}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>패널티 적용 단계</td>
                                    <td>{item.ban_step}단계</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>패널티 적용일</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>패널티 만료일</td>
                                    <td>{item.ban_expire}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '10' && (
                <div>
                  {user.host_all_bookings !== undefined &&
                    user.host_all_bookings.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`2_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.id}. {item.status_display}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>금액(부가세포함)</td>
                                    <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트</td>
                                    <td>{item.price_host.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>수수료</td>
                                    <td>{item.price_fee.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 번호</td>
                                    <td>{item.guest_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>게스트 이름</td>
                                    <td>{item.guest_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약 일자</td>
                                    <td>
                                      {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ({item.hour}시간)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='card-footer'>
                              <label
                                onClick={() => {
                                  window.open(`/booking/place/${item.id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                상세보기
                              </label>
                              <label
                                style={{marginLeft: 10}}
                                onClick={() => {
                                  window.open(`/user/${item.guest_id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                게스트보기
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '11' && (
                <div>
                  {user.guest_all_bookings !== undefined &&
                    user.guest_all_bookings.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`4_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                {item.id}. {item.status_display}
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소번호</td>
                                    <td>{item.place_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>장소명</td>
                                    <td>{item.place_title}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>금액(부가세포함)</td>
                                    <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트</td>
                                    <td>{item.price_host.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>수수료</td>
                                    <td>{item.price_fee.toLocaleString('en')}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 번호</td>
                                    <td>{item.user_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>호스트 이름</td>
                                    <td>{item.host_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>예약 일자</td>
                                    <td>
                                      {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ~ {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} ({item.hour}시간)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='card-footer'>
                              <label
                                onClick={() => {
                                  window.open(`/booking/place/${item.id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                상세보기
                              </label>
                              <label
                                style={{marginLeft: 10}}
                                onClick={() => {
                                  window.open(`/user/${item.guest_id}`)
                                }}
                                className='btn btn-sm btn-primary'
                              >
                                게스트보기
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '12' && (
                <div>
                  {user.user_auto_payment_cards !== undefined &&
                    user.user_auto_payment_cards.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드명</td>
                                    <td>
                                      {item.card_name}
                                      {item.is_main_card === 1 && item.card_delete === 0 && <>&nbsp;(마지막 결제 카드)</>}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드정보</td>
                                    <td>{item.card_display_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드 상태</td>
                                    <td>{item.status}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드 등록 일자</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                  {item.card_delete === 1 && (
                                    <>
                                      <tr>
                                        <td style={{fontWeight: 'bold'}}>카드 삭제 일자</td>
                                        <td>{item.deleted_at}</td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '13' && (
                <div>
                  {user.user_change_histories !== undefined &&
                    user.user_change_histories.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>이메일</td>
                                    <td>{item.email}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>닉네임</td>
                                    <td>{item.name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>추가정보</td>
                                    <td>{item.company}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>휴대폰번호</td>
                                    <td>{item.phone}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>이름</td>
                                    <td>{item.real_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>생년월일</td>
                                    <td>{item.birth}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>변경일</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '14' && (
                <div>
                  {user.referee > 0 && (
                    <div className='col-12'>
                      <div className='card card-custom card-stretch shadow mb-5'>
                        <div className='card-header'>
                          <h1 className='card-title'>추천인 코드 정보 확인</h1>
                        </div>
                        <div className='card-body'>
                          <table style={{fontSize: '15px', width: '100%'}}>
                            <colgroup>
                              <col className='col1' />
                              <col style={{width: 'auto'}} />
                            </colgroup>
                            <tbody>
                              <tr>
                                <td style={{fontWeight: 'bold'}}>추천인 코드</td>
                                <td>{user.referee_code}</td>
                              </tr>
                              <tr>
                                <td style={{fontWeight: 'bold'}}>회원 번호</td>
                                <td>{user.referee}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {status === '15' && (
                <div>
                  {user.user_completed_not_host_paid_bookings.map((item: any, index: number) => {
                    return (
                      <div className='col-12' key={`15_${index}`}>
                        <div className='card card-custom card-stretch shadow mb-5'>
                          <div className='card-header'>
                            <h1 className='card-title'>
                              예약 번호 : {item.id} (예약 상태 : {item.status})
                            </h1>
                          </div>
                          <div className='card-body'>
                            <table style={{fontSize: '15px', width: '100%'}}>
                              <colgroup>
                                <col className='col-lg-2' />
                                <col style={{width: 'auto'}} />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>게스트 번호</td>
                                  <td>{item.guest_id}</td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>예약 시작일 - 종료일</td>
                                  <td>
                                    {moment(item.start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')} - {moment(item.end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>게스트 결제 금액(부가세 포함)</td>
                                  <td>{item.price_guest_vat.toLocaleString('en')}</td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>실 결제 금액(부가세 포함)</td>
                                  <td>{(item.price_guest_vat - item.price_point - item.price_coupon).toLocaleString('en')}</td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>포인트 사용</td>
                                  <td>{item.price_point.toLocaleString('en')}</td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>쿠폰 사용</td>
                                  <td>{item.price_coupon.toLocaleString('en')}</td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>호스트 수익 금액</td>
                                  <td>{item.price_host.toLocaleString('en')}</td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>호스트 개인 정산 (원천징수)</td>
                                  <td>
                                    {item.price_host_tax_last.toLocaleString('en')} ({item.price_host_tax.toLocaleString('en')})
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{fontWeight: 'bold'}}>호스트 사업자 정산 (부가세)</td>
                                  <td>
                                    {item.price_host_vat_last.toLocaleString('en')} ({item.price_host_vat.toLocaleString('en')})
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              {status === '16' && (
                <div>
                  {user.user_guest_feeds.map((item: any, index: number) => {
                    return (
                      <div className='col-12' key={`16_${index}`}>
                        <div className='card card-custom card-stretch shadow mb-5'>
                          <div className='card-header'>
                            <h1 className='card-title' style={{fontWeight: 900, fontSize: 20}}>
                              포트폴리오 ID : {item.id}
                            </h1>
                          </div>
                          <div className='card-body'>
                            <table style={{fontSize: '15px', width: '100%'}}>
                              <colgroup>
                                <col className='col-lg-2' />
                                <col style={{width: 'auto'}} />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <td>상태</td>
                                  <td>{item.status === 'P' ? '게시 중' : item.status === 'H' ? '숨김' : '삭제'}</td>
                                </tr>
                                <tr>
                                  <td>게시물 등록일</td>
                                  <td>{item.created_at}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='card-footer'>
                            <label
                              onClick={() => {
                                window.open(`${process.env.REACT_APP_WEB_URL}feed/guest?portfolio=${item.id}`)
                              }}
                              className='btn btn-sm btn-primary'
                            >
                              PC 웹에서 보기
                            </label>
                            <label
                              style={{marginLeft: 10}}
                              onClick={() => {
                                window.open(`${process.env.REACT_APP_WEB_URL}portfolio/${item.id}`)
                              }}
                              className='btn btn-sm btn-primary'
                            >
                              모바일웹에서 보기
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}

              {status === '17' && (
                <div>
                  {user.user_place_feeds.map((item: any, index: number) => {
                    return (
                      <div className='col-12' key={`17_${index}`}>
                        <div className='card card-custom card-stretch shadow mb-5'>
                          <div className='card-header'>
                            <h1 className='card-title' style={{fontWeight: 900, fontSize: 20}}>
                              장소 소식 ID : {item.id}
                              <div>
                                <label
                                  onClick={() => {
                                    window.open(`/feed/${item.id}`)
                                  }}
                                  className='btn btn-sm btn-outline-primary'
                                >
                                  상세보기
                                </label>
                              </div>
                            </h1>
                          </div>
                          <div className='card-body'>
                            <table style={{fontSize: '15px', width: '100%'}}>
                              <colgroup>
                                <col className='col-lg-2' />
                                <col style={{width: 'auto'}} />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <td>상태</td>
                                  <td>{item.status === 'P' ? '게시 중' : item.status === 'H' ? '숨김' : '삭제'}</td>
                                </tr>
                                <tr>
                                  <td>게시물 등록일</td>
                                  <td>{item.created_at}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}

              {status === '18' && (
                <div>
                  {user.user_toss_auto_payment_cards !== undefined &&
                    user.user_toss_auto_payment_cards.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>{item.id}</h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드명</td>
                                    <td>
                                      {item.cardName}
                                      {item.is_main_card === 1 && item.card_delete === 0 && <>&nbsp;(마지막 결제 카드)</>}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드정보</td>
                                    <td>{item.card_display_name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드 상태</td>
                                    <td>{item.status}</td>
                                  </tr>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>카드 등록 일자</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                  {item.card_delete === 1 && (
                                    <>
                                      <tr>
                                        <td style={{fontWeight: 'bold'}}>카드 삭제 일자</td>
                                        <td>{item.deleted_at}</td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {status === '20' && (
                <div>
                  {user.place_calendar_external_info !== undefined &&
                    user.place_calendar_external_info.map((item: any, index: number) => {
                      return (
                        <div className='col-12' key={`8_${index}`}>
                          <div className='card card-custom card-stretch shadow mb-5'>
                            <div className='card-header'>
                              <h1 className='card-title'>
                                장소 번호 : {item.id}({item.title})
                              </h1>
                            </div>
                            <div className='card-body'>
                              <table style={{fontSize: '15px', width: '100%'}}>
                                <colgroup>
                                  <col className='col1' />
                                  <col style={{width: 'auto'}} />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td style={{fontWeight: 'bold'}}>외부 캘린더 URL</td>
                                    <td>
                                      <input
                                        type='text'
                                        className='form-control'
                                        defaultValue={item.url}
                                        onChange={(e) => {
                                          item.url = e.target.value
                                          user.place_calendar_external_info.splice(index, 1, item)
                                          setUser(user)
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className='card-footer'>
                              {null === item.url && (
                                <label
                                  onClick={() => {
                                    adminExternalCalendarModify('add', item.id, item.url)
                                  }}
                                  className='btn btn-sm btn-primary'
                                >
                                  등록
                                </label>
                              )}
                              {null !== item.url && (
                                <>
                                  <label
                                    onClick={() => {
                                      adminExternalCalendarModify('modify', item.id, item.url)
                                    }}
                                    className='btn btn-sm btn-primary'
                                  >
                                    수정
                                  </label>
                                  <label
                                    onClick={() => {
                                      adminExternalCalendarModify('delete', item.id, item.url)
                                    }}
                                    className='btn btn-sm btn-primary m-lg-5'
                                  >
                                    삭제
                                  </label>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
