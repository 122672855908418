import React, {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'

import 'react-datepicker/dist/react-datepicker.css'
import {PlaceItem} from '../../components/PlaceItem'

export function BookingToday() {

  const [date, setDate] = useState<Date | null>(new Date())
  const [result, setResult] = useState<any>(null)

  useEffect(() => {
    registerLocale('ko', ko)

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPlaces = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/view/top/${moment(date).format('YYYY-MM-DD')}`)
      .then(({data: {error, result}}) => {
        if (error !== '') {
          window.alert(error)
        } else {
          console.log(result)
          setResult(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getPlaces()
  }, [getPlaces, date])

  return <>
    <PageTitle breadcrumbs={[]}>오늘의 장소</PageTitle>

    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <DatePicker locale={'ko'} dateFormat='yyyy-MM-dd' selected={date} onChange={(changeDate) => setDate(changeDate)} />

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        {
          result !== null &&
          <div>

            <h1>
              총 노출 건수 : {Number(result.total).toLocaleString('en')}건 , 예약 접수
              : {Number(result.booking_total).toLocaleString('en')}건, 예약률 :{' '}
              {result.booking_total > 0 && result.total > 0
                ? Math.round((result.booking_total / result.total) * 1000) / 10
                : 0}
              %
            </h1>

            <div
              className='row col-12'
              style={{padding: 20, backgroundColor: '#FFF', borderRadius: 10}}>
              {result.places.map((place: any, index: number) => {
                return (
                  <div key={index.toString()}>
                    <p>
                      노출 : {place.count}건, 예약 접수 : {place.booking_count}건,
                      예약률 :{' '}
                      {place.booking_count > 0 && place.count > 0
                        ? Math.round((place.booking_count / place.count) * 1000) /
                        10
                        : 0}
                      %
                    </p>
                    <PlaceItem place={place} />
                  </div>
                )
              })}
            </div>

          </div>
        }

      </div>

    </div>
  </>
}

