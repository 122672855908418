import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import {apiGetGreyKeywords} from '../../../apis/Grey'

export function BookingMessageDetail() {

  const {booking_id} = useParams<{booking_id: string}>()

  const [booking, setBooking] = useState<any>(null)
  const [items, setItems] = useState<any>([])

  const [loading, setLoading] = useState(false)

  const [greyKeywords, setGreyKeywords] = useState([])

  useEffect(() => {

    getGreyKeywords()
    getMessages()

    return () => {

    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMessages = () => {
    setItems([])
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API2_URL}am/message/${booking_id}`).then(({data: {error, result}}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
        toast.error(error)
      } else {
        setBooking(result.booking)
        setItems(result.messages)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const getGreyKeywords = () => {
    apiGetGreyKeywords().then(({error, result}) => {
      console.log(error)
      console.log(result)
      if (error !== '') {
      } else {
        setGreyKeywords(result)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return <>
    <PageTitle breadcrumbs={[]}>메시지</PageTitle>
    <div style={{
      padding: 20,
      borderRadius: 20,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>

      <div style={{
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
      }}>

        {
          loading ?
            <div style={{
              width: '100%',
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
            </div>
            :
            <div>

              {
                booking !== null &&
                <div className='mb-10'>
                  <label onClick={() => {
                    window.location.href = `/booking/place/${booking.id}`
                  }} className='btn btn-primary'>예약 : {booking.id}</label>
                </div>
              }

              {
                booking !== null &&
                items.map((message: any, messageIndex: number) => {
                  return (
                    <div style={{
                      display: 'flex',
                      justifyContent: message.user_id === booking.user_id ? 'flex-end' : 'flex-start',
                    }} key={`message_${messageIndex}`}>
                      <div className='shadow mb-5 p-5' style={{
                        width: 'fit-content',
                        textAlign: message.user_id === booking.user_id ? 'right' : 'left',
                      }}>
                        <p>{message.name} [{message.host_name}]</p>
                        {
                          message.type === 'I' ?
                            <a href={`${message.message}.jpg`} target={'_blank'} rel={'noopener noreferrer'}>
                              <img src={`${message.message}.jpg`} className='img-thumbnail' style={{
                                maxWidth: 200,
                              }} alt={''} />
                            </a>
                            :
                            <h4 style={{
                              whiteSpace: 'pre-wrap',
                            }}>
                              <Highlighter
                                searchWords={greyKeywords}
                                autoEscape
                                highlightStyle={{
                                  backgroundColor: 'red',
                                  color: 'white',
                                }}
                                textToHighlight={message.message ?? ''}
                              />
                            </h4>
                        }
                        <br />
                        {message.created_at}
                      </div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
    </div>
  </>
}

