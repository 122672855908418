import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import ImagePath from "../../../commons/ImagePath";
import {useParams} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {toast} from "react-toastify";

export function UserPlaceFeedEdit() {

    const {feed_id} = useParams<{feed_id: string}>();

    const [item, setItem] = useState<any>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserPlaceFeed();
        return () => {
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUserPlaceFeed = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API2_URL}am/feed/${feed_id}/place`).then(({data: {error, result}}) => {
            console.log(error)
            console.log(result)
            if (error !== '') {
            } else {
              setItem(result)
              window.scrollTo(0, 0)
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    const update = () => {

        if (item.status === 'D') {
            if (!window.confirm('게시물을 삭제 처리 하시겠어요?')) {
                return false
            }
        }

        if (item.status === 'H') {
            if (!window.confirm('게시물을 숨김 처리 하시겠어요?')) {
                return false
            }
        }

        axios.put(`${process.env.REACT_APP_API2_URL}am/feed/${feed_id}/place`, {...item})
            .then(({data: {error, result}}) => {
                console.log(error)
                console.log(result)
                if (error !== '') {
                    toast.error(error)
                } else {
                    alert("수정되었습니다.")
                   window.location.href = `/feed/${item.id}`
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>장소 사진, 영상 수정</PageTitle>

            <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              {
                loading ?
                    <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                    </div>
                    :
                    <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
                        <div className='col-12' >
                          <div className='card card-custom card-stretch shadow mb-5'>

                              <div className='card-header'>
                                  <h1 className='card-title'>
                                      장소 소식 ID : {item.id}
                                  </h1>
                              </div>

                              <div className='card-body'>
                                  <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start',}}>
                                      <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>상태</p>
                                  </div>
                                  <div>
                                      <select className='form-select form-select-solid' name='type' value={item.status}
                                          onChange={(e) => {
                                              const temp = Object.assign({}, item)
                                              temp.status = e.target.value
                                              setItem(temp)
                                          }}
                                      >
                                          <option key={'P'} value={'P'}>게시 중</option>
                                          <option key={'H'} value={'H'}>숨김</option>
                                          <option key={'D'} value={'D'}>삭제</option>
                                      </select>
                                  </div>

                                  <div className='separator my-10' />
                                  <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start',}}>
                                      <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>본문 내용</p>
                                  </div>
                                  <div>
                                      <textarea className='form-control' style={{width: '100%', height: 150}} value={item.content}
                                                onChange={(e) => {
                                                    const temp = Object.assign({}, item)
                                                    temp.content = e.target.value
                                                    setItem(temp)
                                                }}
                                      />
                                  </div>

                                  <div className='separator my-10' />
                                  <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start',}}>
                                      <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>태그</p>
                                      <p style={{marginLeft: 6, fontWeight: 500, fontSize: 16, color: '#979797', whiteSpace: 'pre-wrap'}}>최대 10개까지 입력 가능합니다.</p>
                                  </div>
                                  <div>
                                      <input type='text' className='form-control' placeholder='' value={item.tag}
                                             onChange={(e) => {
                                                 const temp = Object.assign({}, item)
                                                 temp.tag = e.target.value
                                                 setItem(temp)
                                             }}
                                      />
                                  </div>

                                  <div className='separator my-10' />
                                  <div style={{marginTop: 20, width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start',}}>
                                      <p style={{fontWeight: 500, fontSize: 16, color: '#000000', whiteSpace: 'pre-wrap'}}>이미지, 영상, 유튜브</p>
                                  </div>
                                  <div className='row g-5'>
                                  {
                                      item.place_feed_type === 'I' &&
                                      item.image.map((image: any) => {
                                        return (
                                            <>
                                                {
                                                    null !== image && null !== image.image_path &&
													<div className='col-lg-3' style={{position: 'relative', width: '20%'}} key={image.id.toString()}>
													    <img src={ImagePath.get(image.image_path, '2000x2000', 'inside')} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt=''/>
														<div style={{position: 'absolute', bottom: 0, right: 0}}>
															<div className={'row'}>
																<div style={{width: 'fit-content'}}>
                                                                    {
																		<label style={{backgroundColor: '#FF4B3E'}} onClick={() => {
                                                                           // updateImageHide(item, -1, index)
                                                                            if (window.confirm('이미지를 삭제하시겠습니까?')) {
                                                                                const temp = {...item}
                                                                                temp.image.splice(temp.image.indexOf(image), 1)
                                                                                setItem(temp)
                                                                            }
                                                                        }} className='btn btn-sm btn-outline-light'>삭제</label>
                                                                    }
																</div>
															</div>
														</div>
                                                    </div>
                                                }
                                            </>
                                        )
                                      })
                                  }
                                  </div>
                                  {
                                      item.place_feed_type === 'Y' &&
                                      <img src={item.youtube.image_maxres} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' />
                                  }
                                  {
                                      item.place_feed_type === 'V' &&
                                      <img src={ImagePath.get(item.video.thumbnail_path, '2000x2000', 'inside')} style={{margin: 10, width: 200, height: 200, objectFit: 'contain'}} alt='' />
                                  }
                              </div>
                          </div>
                        </div>

                        <div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{padding: 10}}>
                                <label onClick={() => {
                                    update();
                                }} className='btn btn-primary'>수정하기</label>
                            </div>
                        </div>

                    </div>
              }
            </div>
        </>
    )
}

