import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import {toast} from 'react-toastify'

export function MarketingEventEditor() {
  const {event_id} = useParams<{event_id: string}>()
  const [advId, setAdvId] = useState(0)
  const [info, setInfo] = useState({
    active: false,
    banner_show: false,
    sub_title_arrow: false,
    login: false,
    background_color: '#3c82fa',
    type: 'main',
    url: '',
    order: 0,
    version: 2,
    black: false,
    image_url: '',
    title: '',
    title_color: '#ffffff',
    sub_title: '',
    sub_title_color: '#ffffff',
    button_title: '',
    button_title_color: '#ffffff',
    button_background_color: '#195bfa',
    google_url: '',
    google_url_button_title: '',
    google_id_code: '',
    google_email_code: '',
    pc_images: [],
    images: [],
  })

  useEffect(() => {
    if (Number(event_id) > 0) {
      getEvent()
    }
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getEvent = () => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/event/${event_id}`)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error !== '') {
        } else {
          setAdvId(result.adv_id)
          const temp = result.info
          temp.images.forEach((image: any) => {
            if (image.url === undefined || image.url === null) {
              image.url = ''
            }
            if (image.width === undefined) {
              image.width = 100
            }
            if (image.padding_top === undefined) {
              image.padding_top = 0
            }
            if (image.padding_bottom === undefined) {
              image.padding_bottom = 0
            }
            if (image.padding_right === undefined) {
              image.padding_right = 0
            }
            if (image.padding_left === undefined) {
              image.padding_left = 0
            }
            if (image.login === undefined) {
              image.login = 0
            }
            if (image.google === undefined) {
              image.google = 0
            }
          })
          temp.pc_images?.forEach((image: any) => {
            if (image.url === undefined || image.url === null) {
              image.url = ''
            }
            if (image.width === undefined) {
              image.width = 100
            }
            if (image.padding_top === undefined) {
              image.padding_top = 0
            }
            if (image.padding_bottom === undefined) {
              image.padding_bottom = 0
            }
            if (image.padding_right === undefined) {
              image.padding_right = 0
            }
            if (image.padding_left === undefined) {
              image.padding_left = 0
            }
            if (image.login === undefined) {
              image.login = 0
            }
            if (image.google === undefined) {
              image.google = 0
            }
          })
          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const update = () => {
    axios
      .put(`${process.env.REACT_APP_API2_URL}am/event/${event_id}`, {
        advId: advId,
        info: info,
      })
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFileInputMainImage = (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    return axios
      .post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error === '') {
          const temp = Object.assign({}, info)
          temp.image_url = result.image_url
          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFileInputEventImage = (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    return axios
      .post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error === '') {
          const temp = Object.assign({}, info)
          console.log(temp.pc_images)
          if (temp.pc_images === null) temp.pc_images = Object([])
          temp.pc_images.push({
            // @ts-ignore
            image_url: result.image_url,
            // @ts-ignore
            width: 100,
            // @ts-ignore
            padding_top: 0,
            // @ts-ignore
            padding_bottom: 0,
            // @ts-ignore
            padding_left: 0,
            // @ts-ignore
            padding_right: 0,
            // @ts-ignore
            url: '',
            // @ts-ignore
            login: 0,
            // @ts-ignore
            google: 0,
          })

          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFileInputEventMImage = (e: any) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    return axios
      .post(`${process.env.REACT_APP_API2_URL}image/event`, formData)
      .then(({data: {error, result}}) => {
        console.log(error, result)
        if (error === '') {
          const temp = Object.assign({}, info)
          temp.images.push({
            // @ts-ignore
            image_url: result.image_url,
            // @ts-ignore
            width: 100,
            // @ts-ignore
            padding_top: 0,
            // @ts-ignore
            padding_bottom: 0,
            // @ts-ignore
            padding_left: 0,
            // @ts-ignore
            padding_right: 0,
            // @ts-ignore
            url: '',
            // @ts-ignore
            login: 0,
            // @ts-ignore
            google: 0,
          })
          setInfo(temp)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>이벤트</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>광고 연결하기</h1>
            </div>
            <div className='card-body'>
              <TextSub2 title={'광고 ID'} subTitle={''} />
              <input
                type='number'
                className='form-control'
                placeholder='메인 베너 광고의 광고 ID 값을 입력해주세요'
                value={advId}
                onChange={(e) => {
                  setAdvId(parseInt(e.target.value))
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>배너 옵션</h1>
            </div>
            <div className='card-body'>
              <div
                style={{
                  position: 'relative',
                  width: 400,
                  height: 500,
                }}
              >
                <img
                  src={info.image_url}
                  style={{
                    width: 400,
                    height: 500,
                    objectFit: 'cover',
                    position: 'absolute',
                    zIndex: 0,
                  }}
                  alt={''}
                />
                <div
                  style={{
                    position: 'absolute',
                    width: 400,
                    height: 500,
                    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))',
                    zIndex: 1,
                  }}
                />
                <div
                  style={{
                    zIndex: 2,
                    position: 'absolute',
                    width: 400,
                    height: 500,
                  }}
                >
                  <p
                    style={{
                      whiteSpace: 'pre',
                      marginTop: 30,
                      marginLeft: 20,
                      fontSize: 32,
                      fontWeight: 'bold',
                      color: info.black ? '#1B1D1F' : '#FFFFFF',
                    }}
                  >
                    {info.title}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 8,
                      marginLeft: 20,
                    }}
                  >
                    <p
                      style={{
                        whiteSpace: 'pre',
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: info.black ? '#1B1D1F' : '#FFFFFF',
                      }}
                    >
                      {info.sub_title}
                    </p>
                    {info.sub_title_arrow && (
                      <img
                        src={info.black ? '//s3.hourplace.co.kr/web/images/icon/chevron_right_b.svg' : '//s3.hourplace.co.kr/web/images/icon/chevron_right_w.svg'}
                        style={{
                          width: 16,
                          height: 16,
                          marginLeft: 4,
                        }}
                        alt={''}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className='separator my-10' />
              <TextSub2 title={'노출'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.active}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.active = event.target.checked
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'배너 노출'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.banner_show}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.banner_show = event.target.checked
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'로그인'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.login}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.login = event.target.checked
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'순서'} subTitle={''} />

              <input
                type='number'
                className='form-control'
                placeholder=''
                value={info.order}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.order = parseInt(e.target.value)
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'배경색'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.background_color}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.background_color = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'메인 이미지'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.image_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.image_url = e.target.value
                  setInfo(temp)
                }}
              />
              <input type='file' name='file' onChange={(e) => handleFileInputMainImage(e)} />

              <div className='separator my-10' />
              <TextSub2 title={'링크'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.url = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'제목'} subTitle={''} />

              <textarea
                style={{
                  width: '100%',
                  height: 100,
                }}
                value={info.title}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.title = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'제목 색'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.black}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.black = event.target.checked
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'부제목'} subTitle={''} />

              <textarea
                style={{
                  width: '100%',
                  height: 100,
                }}
                value={info.sub_title}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.sub_title = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'부제목 화살표'} subTitle={''} />

              <input
                className='form-check-input'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                checked={info.sub_title_arrow}
                onChange={(event) => {
                  const temp = Object.assign({}, info)
                  temp.sub_title_arrow = event.target.checked
                  setInfo(temp)
                }}
              />
            </div>
          </div>

          <div className='separator my-10' />

          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>이벤트 상세 (PC)</h1>
            </div>
            <div className='card-body'>
              {info.pc_images?.map((image: any) => {
                return (
                  <div
                    style={{
                      position: 'relative',
                      width: `${image.width}%`,
                      paddingTop: image.padding_top,
                      paddingBottom: image.padding_bottom,
                      paddingRight: image.padding_right,
                      paddingLeft: image.padding_left,
                      border: '1px solid #DDD',
                    }}
                  >
                    <img
                      style={{width: '100%'}}
                      src={image.image_url}
                      onClick={() => {
                        window.open(image.url)
                      }}
                      alt={''}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                        marginTop: image.padding_top,
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: 4,
                          left: 4,
                          opacity: 0.6,
                          zIndex: 1,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>width(%)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.width}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.width = parseInt(e.target.value)
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>링크</p>
                          <input
                            type='text'
                            className='form-control'
                            placeholder=''
                            value={image.url}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.url = e.target.value
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>로그인 필요</p>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='layout-builder[layout][header][fixed][desktop]'
                            checked={image.login === 1}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.login = e.target.checked ? 1 : 0
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>구글폼</p>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='layout-builder[layout][header][fixed][desktop]'
                            checked={image.google === 1}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.google = e.target.checked ? 1 : 0
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          position: 'absolute',
                          top: 92,
                          left: 4,
                          opacity: 0.6,
                          zIndex: 1,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-top(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_top}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_top = parseInt(e.target.value)
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-bottom(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_bottom}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_bottom = parseInt(e.target.value)
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-right(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_right}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_right = parseInt(e.target.value)
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-left(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_left}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.pc_images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_left = parseInt(e.target.value)
                              temp.pc_images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>
                      </div>

                      <label
                        onClick={() => {
                          if (window.confirm('삭제 하시겠습니까?')) {
                            const temp = Object.assign({}, info)
                            const temps = [...temp.pc_images]
                            // @ts-ignore
                            temps.splice(temps.indexOf(image), 1)
                            temp.pc_images = temps
                            setInfo(temp)
                          }
                        }}
                        style={{position: 'absolute', top: 4, right: 4}}
                        className='btn btn-danger'
                      >
                        삭제
                      </label>

                      <label
                        onClick={() => {
                          const temp = Object.assign({}, info)
                          const temps = [...temp.pc_images]
                          // @ts-ignore
                          const index = temps.indexOf(image)
                          temps.splice(index, 1)
                          // @ts-ignore
                          temps.splice(index - 1, 0, image)
                          temp.pc_images = temps
                          setInfo(temp)
                        }}
                        style={{position: 'absolute', bottom: 4, right: 90}}
                        className='btn btn-primary'
                      >
                        위로
                      </label>
                      <label
                        onClick={() => {
                          const temp = Object.assign({}, info)
                          const temps = [...temp.pc_images]
                          // @ts-ignore
                          const index = temps.indexOf(image)
                          temps.splice(index, 1)
                          // @ts-ignore
                          temps.splice(index - 1, 0, image)
                          temp.pc_images = temps
                          setInfo(temp)
                        }}
                        style={{position: 'absolute', bottom: 4, right: 4}}
                        className='btn btn-primary'
                      >
                        아래로
                      </label>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='card-footer'>
              <input type='file' name='file' onChange={(e) => handleFileInputEventImage(e)} />
            </div>
          </div>

          <div className='separator my-10' />

          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>이벤트 상세 (Mobile)</h1>
            </div>
            <div className='card-body'>
              {info.images.map((image: any) => {
                return (
                  <div
                    style={{
                      position: 'relative',
                      width: `${image.width}%`,
                      paddingTop: image.padding_top,
                      paddingBottom: image.padding_bottom,
                      paddingRight: image.padding_right,
                      paddingLeft: image.padding_left,
                      border: '1px solid #DDD',
                    }}
                  >
                    <img
                      style={{width: '100%'}}
                      src={image.image_url}
                      onClick={() => {
                        window.open(image.url)
                      }}
                      alt={''}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                        marginTop: image.padding_top,
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: 4,
                          left: 4,
                          opacity: 0.6,
                          zIndex: 1,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>width(%)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.width}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.width = parseInt(e.target.value)
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>링크</p>
                          <input
                            type='text'
                            className='form-control'
                            placeholder=''
                            value={image.url}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.url = e.target.value
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>로그인 필요</p>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='layout-builder[layout][header][fixed][desktop]'
                            checked={image.login === 1}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.login = e.target.checked ? 1 : 0
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>구글폼</p>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='layout-builder[layout][header][fixed][desktop]'
                            checked={image.google === 1}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.google = e.target.checked ? 1 : 0
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          position: 'absolute',
                          top: 92,
                          left: 4,
                          opacity: 0.6,
                          zIndex: 1,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-top(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_top}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_top = parseInt(e.target.value)
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-bottom(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_bottom}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_bottom = parseInt(e.target.value)
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-right(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_right}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_right = parseInt(e.target.value)
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: 4,
                            width: 150,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: 'white',
                          }}
                        >
                          <p>padding-left(px)</p>
                          <input
                            type='number'
                            className='form-control'
                            placeholder=''
                            value={image.padding_left}
                            onChange={(e) => {
                              const temp = Object.assign({}, info)

                              const temps = [...temp.images]
                              // @ts-ignore
                              temps.splice(temps.indexOf(image), 1, image)
                              image.padding_left = parseInt(e.target.value)
                              temp.images = temps

                              setInfo(temp)
                            }}
                          />
                        </div>
                      </div>

                      <label
                        onClick={() => {
                          if (window.confirm('삭제 하시겠습니까?')) {
                            const temp = Object.assign({}, info)
                            const temps = [...temp.images]
                            // @ts-ignore
                            temps.splice(temps.indexOf(image), 1)
                            temp.images = temps
                            setInfo(temp)
                          }
                        }}
                        style={{position: 'absolute', top: 4, right: 4}}
                        className='btn btn-danger'
                      >
                        삭제
                      </label>

                      <label
                        onClick={() => {
                          const temp = Object.assign({}, info)
                          const temps = [...temp.images]
                          // @ts-ignore
                          const index = temps.indexOf(image)
                          temps.splice(index, 1)
                          // @ts-ignore
                          temps.splice(index - 1, 0, image)
                          temp.images = temps
                          setInfo(temp)
                        }}
                        style={{position: 'absolute', bottom: 4, right: 90}}
                        className='btn btn-primary'
                      >
                        위로
                      </label>
                      <label
                        onClick={() => {
                          const temp = Object.assign({}, info)
                          const temps = [...temp.images]
                          // @ts-ignore
                          const index = temps.indexOf(image)
                          temps.splice(index, 1)
                          // @ts-ignore
                          temps.splice(index - 1, 0, image)
                          temp.images = temps
                          setInfo(temp)
                        }}
                        style={{position: 'absolute', bottom: 4, right: 4}}
                        className='btn btn-primary'
                      >
                        아래로
                      </label>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='card-footer'>
              <input type='file' name='file' onChange={(e) => handleFileInputEventMImage(e)} />
            </div>
          </div>

          <div className='separator my-10' />

          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header'>
              <h1 className='card-title'>구글 폼 옵션</h1>
            </div>
            <div className='card-body'>
              <TextSub2 title={'구글 URL'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.google_url}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.google_url = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'구글 URL 버튼명'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.google_url_button_title}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.google_url_button_title = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'구글 ID Code'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.google_id_code}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.google_id_code = e.target.value
                  setInfo(temp)
                }}
              />

              <div className='separator my-10' />
              <TextSub2 title={'구글 Email Code'} subTitle={''} />

              <input
                type='text'
                className='form-control'
                placeholder=''
                value={info.google_email_code}
                onChange={(e) => {
                  const temp = Object.assign({}, info)
                  temp.google_email_code = e.target.value
                  setInfo(temp)
                }}
              />
            </div>
          </div>

          <div
            style={{
              width: '100%',
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: 10,
              }}
            >
              <label
                onClick={() => {
                  update()
                }}
                className='btn btn-primary'
              >
                {event_id !== '0' ? '수정하기' : '저장하기'}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
