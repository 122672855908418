import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {isMobile} from 'react-device-detect'
import moment from 'moment'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import DatePicker, {registerLocale} from 'react-datepicker'
import ko from 'date-fns/locale/ko'

export function PlaceCountHistory() {
  const [place, setPlace] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<Date | null>(new Date())

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    // @ts-ignore
    <label className='btn btn-primary' onClick={onClick} ref={ref}>
      {value}
    </label>
  ))

  useEffect(() => {
    registerLocale('ko', ko)
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSpPlaceCountHistory = useCallback(() => {
    setPlace([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/place/sp/place/count/history?date=${moment(date).format('YYYYMM')}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setPlace(result)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [date])

  useEffect(() => {
    console.log(date)
    getSpPlaceCountHistory()
  }, [getSpPlaceCountHistory, date])

  const download = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet')

    worksheet.columns = [
      {header: '일자', key: 'id', width: 10},
      {header: '파티룸', key: 'id1', width: 10},
      {header: '연습실', key: 'id2', width: 10},
      {header: '촬영스튜디오', key: 'id3', width: 10},
      {header: '스터디룸', key: 'id4', width: 10},
      {header: '공연장', key: 'id5', width: 10},
      {header: '공유주방', key: 'id6', width: 10},
      {header: '댄스연습실', key: 'id7', width: 10},
      {header: '렌탈스튜디오', key: 'id8', width: 10},
      {header: '회의실', key: 'id9', width: 10},
      {header: '라이브방송', key: 'id10', width: 10},
      {header: '카페', key: 'id11', width: 10},
      {header: '보컬연습실', key: 'id12', width: 10},
      {header: '호리존', key: 'id13', width: 10},
      {header: '세미나실', key: 'id14', width: 10},
      {header: '컨퍼런스', key: 'id15', width: 10},
      {header: '스몰웨딩', key: 'id16', width: 10},
      {header: '악기연습실', key: 'id17', width: 10},
      {header: '실외촬영', key: 'id18', width: 10},
      {header: '강의실', key: 'id19', width: 10},
      {header: '운동시설', key: 'id20', width: 10},
      {header: '갤러리', key: 'id21', width: 10},
      {header: '녹음실', key: 'id22', width: 10},
      {header: '독립오피스', key: 'id23', width: 10},
      {header: '코워킹오피스', key: 'id24', width: 10},
      {header: '비상주서비스', key: 'id25', width: 10},
      {header: '당일캠핑', key: 'id26', width: 10},
      {header: '가정집', key: 'id27', width: 10},
    ]

    place.forEach((item: any) => {
      worksheet.addRow({
        id: item.day,
        id1: item.c1,
        id2: item.c2,
        id3: item.c3,
        id4: item.c4,
        id5: item.c5,
        id6: item.c6,
        id7: item.c7,
        id8: item.c8,
        id9: item.c9,
        id10: item.c10,
        id11: item.c11,
        id12: item.c12,
        id13: item.c13,
        id14: item.c14,
        id15: item.c15,
        id16: item.c16,
        id17: item.c17,
        id18: item.c18,
        id19: item.c19,
        id20: item.c20,
        id21: item.c21,
        id22: item.c22,
        id23: item.c23,
        id24: item.c24,
        id25: item.c25,
        id26: item.c26,
        id27: item.c27,
      })
    })

    const mimeType = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    saveAs(blob, `경쟁사_장소_개수_${moment(date).format('YYYY-MM')}.xlsx`)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>경쟁사 일자별 장소 개수</PageTitle>
        <div style={{
          padding: isMobile ? 4 : 20,
          borderRadius: isMobile ? 4 : 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
          <div className="tab-content w-100" id="myTabContent">
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} >
              <div style={{ marginRight: 20 }} >
                <DatePicker customInput={<DatePickerCustomInput />} locale={'ko'} dateFormat='yyyy-MM' showMonthYearPicker selected={date} onChange={(changeDate) => setDate(changeDate)} />
              </div>

              <label
                onClick={() => {
                  download()
                }}
                className='btn btn-sm btn-secondary'
              >
                다운로드
              </label>
            </div>
            <div className="table-responsive">
              <table className="table table-sm table-striped table-hover" style={{textAlign: 'left', backgroundColor: 'white'}}>
                <thead className="table-bordered" style={{height: 50, verticalAlign: 'middle', fontSize: 15, fontWeight: 500}}>
                <tr>
                  <th scope="col" className="min-w-30px"></th>
                  <th scope="col" className="min-w-100px">일자</th>
                  <th scope="col" className="min-w-100px">파티룸</th>
                  <th scope="col" className="min-w-100px">연습실</th>
                  <th scope="col" className="min-w-100px">촬영스튜디오</th>
                  <th scope="col" className="min-w-100px">스터디룸</th>
                  <th scope="col" className="min-w-100px">공연장</th>
                  <th scope="col" className="min-w-100px">공유주방</th>
                  <th scope="col" className="min-w-100px">댄스연습실</th>
                  <th scope="col" className="min-w-100px">렌탈스튜디오</th>
                  <th scope="col" className="min-w-100px">회의실</th>
                  <th scope="col" className="min-w-100px">라이브방송</th>
                  <th scope="col" className="min-w-100px">카페</th>
                  <th scope="col" className="min-w-100px">보컬연습실</th>
                  <th scope="col" className="min-w-100px">호리존</th>
                  <th scope="col" className="min-w-100px">세미나실</th>
                  <th scope="col" className="min-w-100px">컨퍼런스</th>
                  <th scope="col" className="min-w-100px">스몰웨딩</th>
                  <th scope="col" className="min-w-100px">악기연습실</th>
                  <th scope="col" className="min-w-100px">실외촬영</th>
                  <th scope="col" className="min-w-100px">강의실</th>
                  <th scope="col" className="min-w-100px">운동시설</th>
                  <th scope="col" className="min-w-100px">갤러리</th>
                  <th scope="col" className="min-w-100px">녹음실</th>
                  <th scope="col" className="min-w-100px">독립오피스</th>
                  <th scope="col" className="min-w-100px">코워킹오피스</th>
                  <th scope="col" className="min-w-100px">비상주서비스</th>
                  <th scope="col" className="min-w-100px">당일캠핑</th>
                  <th scope="col" className="min-w-100px">가정집</th>
                </tr>
                </thead>
                <tbody className="align-middle" style={{verticalAlign: 'middle', fontSize: 15, fontWeight: 400}}>
                {
                  loading ?
                    <tr style={{height: 98}}>
                      <td colSpan={44}>
                        <div style={{width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <span className='indicator-progress' style={{display: 'block'}}>Loading...<span className='spinner-border spinner-border-sm align-middle ms-2' /></span>
                        </div>
                      </td>
                    </tr>
                    :
                    place !== null && place.map((item: any, index: number) => {
                      return (
                        <tr key={`${index}`} style={{height: 98}}>
                          <td></td>
                          <td>{item.day}</td>
                          <td>{item.c1.toLocaleString('en')}</td>
                          <td>{item.c2.toLocaleString('en')}</td>
                          <td>{item.c3.toLocaleString('en')}</td>
                          <td>{item.c4.toLocaleString('en')}</td>
                          <td>{item.c5.toLocaleString('en')}</td>
                          <td>{item.c6.toLocaleString('en')}</td>
                          <td>{item.c7.toLocaleString('en')}</td>
                          <td>{item.c8.toLocaleString('en')}</td>
                          <td>{item.c9.toLocaleString('en')}</td>
                          <td>{item.c10.toLocaleString('en')}</td>
                          <td>{item.c11.toLocaleString('en')}</td>
                          <td>{item.c12.toLocaleString('en')}</td>
                          <td>{item.c13.toLocaleString('en')}</td>
                          <td>{item.c14.toLocaleString('en')}</td>
                          <td>{item.c15.toLocaleString('en')}</td>
                          <td>{item.c16.toLocaleString('en')}</td>
                          <td>{item.c17.toLocaleString('en')}</td>
                          <td>{item.c18.toLocaleString('en')}</td>
                          <td>{item.c19.toLocaleString('en')}</td>
                          <td>{item.c20.toLocaleString('en')}</td>
                          <td>{item.c21.toLocaleString('en')}</td>
                          <td>{item.c22.toLocaleString('en')}</td>
                          <td>{item.c23.toLocaleString('en')}</td>
                          <td>{item.c24.toLocaleString('en')}</td>
                          <td>{item.c25.toLocaleString('en')}</td>
                          <td>{item.c26.toLocaleString('en')}</td>
                          <td>{item.c27.toLocaleString('en')}</td>
                        </tr>
                      )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </>
  )
}
