import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {TextSub2} from '../../components/TextSub2'
import ImagePath from '../../../commons/ImagePath'
import {isMobile} from 'react-device-detect'

export function MarketingBenefitsEditor() {
    const {marketingBenefitsId} = useParams<{marketingBenefitsId: string}>()

    const [item, setItem] = useState<any>('')
    const [size, setSize] = useState('')


    useEffect(() => {
      if (Number(marketingBenefitsId) > 0) {
        getMarketingBenefits()
      }
      return () => {
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getMarketingBenefits = () => {
      axios
        .get(`${process.env.REACT_APP_API2_URL}am/marketing/benefits/${marketingBenefitsId}`)
        .then(({data: {error, result}}) => {
          console.log(error, result)
          if (error !== '') {
          } else {
            setItem(result)
            window.scrollTo(0, 0)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const handleItemFileInput = async (e: any) => {
      console.log(e)

      const file = e.target.files[0]
      axios.get(`${process.env.REACT_APP_API2_URL}upload/image`).then(({data: {error, result}}) => {
        if (error === '') {
          const {path, url} = result
          axios
            .put(url, file, {
              headers: {
                'Content-Type': file?.type,
              },
            })
            .then((response) => {
              console.log(response)
              console.log(path)
              axios
                .post(`${process.env.REACT_APP_API2_URL}upload`, {
                  path,
                  type: 'image',
                })
                .then(({data: {error, result}}) => {
                  if (error === '') {
                    let imageId = result.id
                    let imagePath = result.image_path

                    console.log(result)
                    console.log(imageId)
                    console.log(imagePath)

                    setSize(result.width + 'x' + result.height)

                    const temp = Object.assign({}, item)
                    temp.card_image_path = imagePath
                    setItem(temp)

                  } else {
                    console.log(error)
                    // main_vue.failImage(file)
                  }
                })
                .catch((error) => {
                  console.log(error)
                  // main_vue.failImage(file)
                })
            })
            .catch((error) => {
              console.log(error)
              // main_vue.failImage(file)
            })
        } else {
          // main_vue.failImage(file)
        }
      })
    }

    const upsert = () => {
      if(item.pc_title.trim === '' || item.mobile_title.trim === '') {
        alert('제목을 입력하세요.');
        return;
      }

      if(item.card_image_path === '') {
        alert('이미지를 등록하세요.');
        return;
      }

      if(item.pc_button_name.trim === '') {
        alert('버튼명을 입력하세요.');
        return;
      }

      console.log(item)

      axios.post(`${process.env.REACT_APP_API2_URL}am/marketing/benefits`, {
          marketing_benefits_id: marketingBenefitsId,
          pc_title: item.pc_title,
          mobile_title: item.mobile_title,
          order: item.order,
          main_show: item.main_show,
          card_image_path: item.card_image_path,
          pc_button_name: item.pc_button_name,
          pc_link: item.pc_link,
          mobile_link: item.mobile_link,
          is_web_link: item.is_web_link
        }
      ).then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          alert(error)
        } else {
          if (Number(marketingBenefitsId) > 0) {
            //window.location.reload();
            alert('수정되었습니다.');
          } else {
            alert('등록되었습니다.');
            window.location.href = '/marketing/benefits/'+result.id+'/editor'
          }
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }

    return (
      <>
        <PageTitle breadcrumbs={[]}>혜택 안내 배너</PageTitle>
        <div style={{padding: '0px 20px', borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                기본 설정
              </h2>
              <div className="separator my-2" />
              <TextSub2 title={'PC용 제목'} subTitle={'최대 20자'} />
              <textarea className="form-control" rows={2} value={item.pc_title} maxLength={20}
                        onChange={(e) => {
                          const temp = Object.assign({}, item)
                          if (e.target.value.trim().length > 20) {
                            temp.pc_title = e.target.value.trim().substring(0, 20)
                          } else {
                            temp.pc_title = e.target.value
                          }
                          setItem(temp)
                        }}
              />

              <TextSub2 title={'Mobile용 제목'} subTitle={'최대 20자'} />
              <textarea className="form-control" rows={2} value={item.mobile_title} maxLength={20}
                        onChange={(e) => {
                          const temp = Object.assign({}, item)
                          if (e.target.value.trim().length > 20) {
                            temp.mobile_title = e.target.value.trim().substring(0, 20)
                          } else {
                            temp.mobile_title = e.target.value
                          }
                          setItem(temp)
                        }}
              />

              <div className="separator my-5" />
              <TextSub2 title={'순서'} subTitle={'최대 99'} />
              <input type="number" className="form-control" max={99} value={item.order}
                     onChange={(e) => {
                       const temp = Object.assign({}, item)
                       if (parseInt(e.target.value) > 99) {
                         temp.order = 99
                       } else {
                         temp.order = e.target.value
                       }
                       setItem(temp)
                     }}
              />

              <div className="separator my-2" />
              <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center'}}>
                  <label style={{marginRight: 20, fontWeight: 500, fontSize: 15, color: '#1B1D1F'}}>
                    메인홈 카드 노출
                    <br /><br />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="layout-builder[layout][header][fixed][desktop]"
                      checked={item.main_show === 1}
                      onChange={(event) => {
                        const temp = Object.assign({}, item)
                        if (event.target.checked) {
                          temp.main_show = 1
                        } else {
                          temp.main_show = 0
                        }
                        setItem(temp)
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                메인홈 카드
              </h2>
              <div className="separator my-5" />
              <TextSub2 title={'카드 이미지'} subTitle={'입력값이 없으면 카드 노출되지 않음'} />
              <input
                type="text"
                className="form-control mb-5"
                value={item.card_image_path}
                onChange={(e) => {
                  const temp = Object.assign({}, item)
                  temp.card_image_path = e.target.value
                  setItem(temp)
                }}
                required={true}
              />
              <input type="file" name="file" onChange={(e) => handleItemFileInput(e)} />
              {item.card_image_path !== undefined && item.card_image_path !== '' &&
                <div style={{position: 'relative', width: '100%', aspectRatio: `${1160 / 360}`, marginTop: 20}}>
                  <img src={ImagePath.get(item.card_image_path, size)} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={''} />
                </div>
              }
            </div>
          </div>
        </div>

        <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                PC 버튼명
              </h2>
              <div className="separator my-2" />
              <TextSub2 title={'PC 버튼명'} subTitle={'최대 8자'} />
              <input type="text" className="form-control" value={item.pc_button_name} maxLength={8}
                     onChange={(e) => {
                       const temp = Object.assign({}, item)
                       temp.pc_button_name = e.target.value
                       setItem(temp)
                     }}
              />
            </div>
          </div>
        </div>

        <div style={{padding: '0px 20px', marginTop: 20, borderRadius: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 20, borderRadius: 20, backgroundColor: 'white', width: '100%'}}>
            <div className="card card-custom card-stretch mb-5">
              <h2 className="card-title">
                랜딩 페이지
              </h2>
              <div className="separator my-2" />
              <TextSub2 title={'PC용 랜딩 페이지 링크'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />
              <input type="text" className="form-control" value={item.pc_link}
                     onChange={(e) => {
                       const temp = Object.assign({}, item)
                       temp.pc_link = e.target.value
                       setItem(temp)
                     }}
              />

              <TextSub2 title={'Mobile용 랜딩 페이지 링크'} subTitle={'입력값이 없으면 배너 노출되지 않음'} />
              <input type="text" className="form-control" value={item.mobile_link}
                     onChange={(e) => {
                       const temp = Object.assign({}, item)
                       temp.mobile_link = e.target.value
                       setItem(temp)
                     }}
              />

              <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center'}}>
                  <label style={{marginRight: 20, fontWeight: 500, fontSize: 15, color: '#1B1D1F'}}>
                    <TextSub2 title={'웹으로 열기'} subTitle={'App 랜딩 링크가 웹으로 열림'} />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="layout-builder[layout][header][fixed][desktop]"
                      checked={item.is_web_link === 1}
                      onChange={(event) => {
                        const temp = Object.assign({}, item)
                        if (event.target.checked) {
                          temp.is_web_link = 1
                        } else {
                          temp.is_web_link = 0
                        }
                        setItem(temp)
                      }}
                    />
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{padding: 10}}>
            <label onClick={() => {
              upsert()
            }} className="btn btn-primary">
              저장하기
            </label>
          </div>
        </div>
      </>
    )
}
